import * as actionsTypes from './actionsTypes'
import axios from '../../axios/axios-apoteka'

export const allStores = (stores) => {
    return {
        type: actionsTypes.ALL_STORES,
        stores: stores

    };
}

export const fetchStoresFailed = () => {
    return {
        type: actionsTypes.FETCH_ALL_STORES_FAIlED
    };
}

export const initStores = () => {
    return dispatch => {
        const URL = '/marketplace/all/marketplaces';
        // const token = localStorage.getItem('token');
        // const tokenParam = {
        //     params: {
        //         access_token: token
        //     }
        // }
        axios.get(URL/*, tokenParam*/)
            .then(response => {
                dispatch(allStores(response.data))
                // console.log(response.data);
            })
            .catch(error => {
                console.log("Error se desio" + error);
                dispatch(fetchStoresFailed())
            })

    };
}

export const updatePictures = (updatedPictures) => {
    return {
        type: actionsTypes.UPDATE_PICTURES,
        updatedPictures: updatedPictures
    }
}

export const updateBranchMarketplacePictures = (updatedPictures) => {
    return {
        type: actionsTypes.UPDATE_BRANCH_MARKETPLACE_PICTURES,
        updatedPictures: updatedPictures
    }
}

export const openHoursModalChanged = (openHoursChanged) => {
    return {
        type: actionsTypes.OPEN_HOURS_MODAL,
        openHoursChanged: openHoursChanged
    }
}

export const storeChanged = (changedStore, changedStoreIndex) => {
    return {
        type: actionsTypes.STORE_CHANGED,
        changedStore: changedStore,
        changedStoreIndex: changedStoreIndex

    };
}

export const itemAvailabilities = (itemAvailabilities) => {
    return {
        type: actionsTypes.ITEM_AVAILABILITIES,
        itemAvailabilities: itemAvailabilities

    };
}

export const fetchItemAvailabilitiesFailed = () => {
    return {
        type: actionsTypes.FETCH_ITEM_AVAILABILITIES_FAIlED
    };
}

export const fetchItemAvailabilities = () => {
    return dispatch => {
        const URL = '/item/item_availabilities';
        axios.get(URL)
            .then(response => {
                dispatch(itemAvailabilities(response.data))
                console.log(response.data);
            })
            .catch(error => {
                console.log("Error se desio" + error);
                dispatch(fetchItemAvailabilitiesFailed())
            })

    };
}
