import React, {useEffect, useState} from 'react'
import styles from "../EditProfileModal.module.css";
import Button from "../../../UI/Buttons/Button/Button";
import authPageStyles from "../../../AuthPage/AuthPage.module.css";
import {useForm} from "react-hook-form";
import * as actions from "../../../../store/actions";
import {connect} from "react-redux";

const EditPasswordForm = (props) => {

    const [badPasswordMeg, badPasswordMsgSetter] = useState('');
    const [changePasswordSuccess, changePasswordSuccessHandler] = useState(false);
    const [countDown, countDownSetter] = useState(5)

    useEffect(()=>{
        if(changePasswordSuccess === true) {
            countDown > 0 && setTimeout(() => countDownSetter(countDown - 1), 1000);
            countDown === 0 && logout();
        }
    }, [countDown, changePasswordSuccess])

    const {register, handleSubmit, formState: {errors}, getValues, setValue} = useForm();

    const onEditPasswordSubmit = () => {
        props.onChangePassword({
            id: localStorage.getItem('id'),
            oldPassword: getValues("oldPassword"),
            newPassword: getValues('newPassword')
        });
    }

    useEffect(() => {
        if (props.changePasswordSuccess === true) {
            changePasswordSuccessHandler(true);
        }
    }, [props.changePasswordSuccess])

    useEffect(() => {
        badPasswordMsgSetter('Pogrešno unesena lozinka')
    }, [props.changePasswordWrongOldPassword])


    const logout = () => {
        changePasswordSuccessHandler(false);
        props.onLogout();
        props.onResetUserInfo(null);
        props.onResetUserProfile(null);
        props.onChangePassword(null);
    }

    return (
        <>
            {!changePasswordSuccess ?
                <form onSubmit={handleSubmit(onEditPasswordSubmit)}>
                    <input className={styles.EditInputFiled} type="password"
                           onFocus={() => {
                               badPasswordMsgSetter('');
                               props.changePasswordWrongOldPassword && setValue('oldPassword', '')
                           }}
                           placeholder="Sadašnja lozinka" {...register("oldPassword", {
                        required: "Unesite staru lozinku",
                        minLength: 8,
                        maxLength: 128,
                    })} />
                    {errors.oldPassword &&
                    <span className={authPageStyles.ErrorMessage}>
                            {errors.oldPassword.message}
                        </span>}
                    {props.changePasswordWrongOldPassword &&
                    <div className={styles.BadPasswordMsg}>{badPasswordMeg}</div>}

                    <input className={styles.EditInputFiled} type="password"
                           placeholder="Nova lozinka" {...register("newPassword", {
                        required: "Ovo polje ne može biti prazno",
                        minLength: {
                            value: 8,
                            message: "Lozinka mora imati najmanje 8 karaktera"
                        },
                        maxLength: {
                            value: 128,
                            message: "Lozinka ne može imati više od 128 karaktera"
                        },
                        pattern: {
                            value: /^(?=.*[a-zљњертжуиопшђасдфгхјклчћзџцвбнмšđžčć])(?=.*[A-ZЉЊЕРТЖУИОПШЂАСДФГХЈКЛЧЋЗЏЦВБНМŠĐŽČĆ])(?=.*\d)[a-zA-ZљњертжуиопшђасдфгхјклчћзџцвбнмšđžčćЉЊЕРТЖУИОПШЂАСДФГХЈКЛЧЋЗЏЦВБНМŠĐŽČĆ\d\w\W]{8,}$/,
                            message: "Lozinka mora da sadrži najmanje jedno veliko, jedno malo slovo i jedan broj"
                        }
                    })} />
                    {errors.password &&
                    <span className={authPageStyles.ErrorMessage}>
                            {errors.newPassword.message}
                        </span>}

                    <input className={styles.EditInputFiled} type="password"
                           placeholder="Ponovite novu lozinku" {...register("repeatedPassword", {
                        required: "Ovo polje ne moze biti prazno",
                        minLength: {
                            value: 8,
                            message: "Lozinka mora imati najmanje 8 karaktera"
                        },
                        maxLength: {
                            value: 128,
                            message: "Lozinka ne može imati više od 128 karaktera"
                        },
                        validate: {
                            notEqual: (value) => {
                                if (value !== getValues("newPassword")) return "Lozinke se ne podudaraju!"
                            }
                        }
                    })}
                    />
                    {errors.repeatedPassword && <span className={authPageStyles.ErrorMessage}>
                        {errors.repeatedPassword.message}
                    </span>}
                    {props.changePasswordFailed &&
                    <div className={styles.BadPasswordMsg}>Promena lozinke nije uspela.</div>}

                    <div className={styles.ButtonsHolder}>
                        <Button onClick={props.closeEditModal}>Otkaži</Button>

                        <Button buttontype='whiteblue' type='submit'>Sačuvaj</Button>
                    </div>
                </form>
                :
                <>
                    {changePasswordSuccess &&
                    <div style={{display:"flex", flexDirection: "column", justifyContent:"center", alignItems:"center"}}>
                        <div style={{fontWeight:"bold"}}>Uspešno ste promenili lozinku.</div>
                        <br/>
                        <div style={{margin: "2rem auto", textAlign: "center"}}>Molimo Vas da se,
                            nakon automatske odjave, prijavite ponovo
                            koristeći novu lozinku.
                        </div>
                        <div style={{fontSize:"18px"}}>{countDown}</div>
                    </div>
                    }
                    <div className={styles.BottomMessageHolder}>
                        <Button onClick={logout}>Zatvori</Button>
                    </div>
                </>
            }
        </>
    );
}


const mapStateToProps = (state) => {
    return {
        changePasswordSuccess: state.userProfile.changePasswordSuccess,
        loadingChangePassword: state.userProfile.loadingChangePassword,
        changePasswordFailed: state.userProfile.changePasswordFailed,
        changePasswordWrongOldPassword: state.userProfile.changePasswordWrongOldPassword,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onChangePassword: (changePasswordJSON) => dispatch(actions.changePassword(changePasswordJSON)),
        onLogout: () => dispatch(actions.logout()),
        onResetUserInfo: (userInfo) => dispatch(actions.userInfo(userInfo)),
        onResetUserProfile: (userProfile) => dispatch(actions.userProfile(userProfile)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditPasswordForm);
