import * as actionsTypes from '../actions/actionsTypes'

const initialState = {

    modalResData: {
        showModalRes: false,
        modalResTitle: null,
        modalResContent: null,
        modalResFooter: null,
    },

    cncModalToggle: false,
    chooseLocationModalToggle: false
}


const modalReducer = (state = initialState, action) => {
    switch (action.type) {

        case actionsTypes.SHOW_MODAL_RES:
            return {
                ...state,
                modalResData: { ...action.modalResData }
            }

        case actionsTypes.CNC_MODAL_TOGGLE:
            return {
                ...state,
                cncModalToggle: action.cncModalToggle
            }

        case actionsTypes.CHOOSE_LOCATION_MODAL_TOGGLE:
            return {
                ...state,
                chooseLocationModalToggle: action.chooseLocationModalToggle
            }
        default:
            return state;
    }
}

export default modalReducer;
