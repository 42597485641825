import React, {Fragment, useEffect, useRef} from "react";
import styles from './CartSummary.module.css';
import Button from "../../../UI/Buttons/Button/Button";
import * as actions from "../../../../store/actions";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import OrderCompletionPageTemplate from "../OrderCompletionPageTemplate/OrderCompletionPageTemplate";
import ShoppingCartList from "../../../ShopPage/ShoppingCartList/ShoppingCartList";
import {ChevronRightIcon} from "@heroicons/react/outline";
import PharmacyData from "../PharmacyData/PharmacyData";
import TotalAmount from "../TotalAmount/TotalAmount";
import Actions from "../Actions/Actions";
import {ReactComponent as TrashIcon} from "../../../../assets/icons/trash_icon.svg";
import {ReactComponent as CartIcon} from "../../../../assets/icons/cart_icon.svg";
import OrderCompletionPageTemplateMobile from "../OrderCompletionPageTemplateMobile/OrderCompletionPageTemplateMobile";
import HeaderWithTitleAndBackChevron from "../../../../componentsRes/HeaderWithTitleAndBackChevron/HeaderWithTitleAndBackChevron";
import Media from "react-media";
import {textToUrl} from "../../../GobalFunctions/GlobalFunctions";

const CartSummary = (props) => {

    const isPageLoaded= useRef(false);

    useEffect(() => {
        props.onCollectOrDeliveryChoice({collect: false, delivery: false});
    }, []);

   // po ucitavanju strane setuje ref na true kako bi se sprecila redirekcija u shop buduci da state u reduxu u trenutku ucitavanja jos nije updateovao stanje korpe na > 0
   useEffect(()=>{
           if (props.shoppingCart !== null && props.shoppingCart.items.length > 0) {
               isPageLoaded.current = true;
           }
   }, [props.shoppingCart])

    // kada se isprazni korpa, redirektuje nazad u shop
    useEffect(() => {
        if (isPageLoaded.current) {
            if (props.shoppingCart !== null && props.shoppingCart.items.length === 0 && localStorage.getItem('beforeEnteringStore') !== null) {
                props.onAllShoppingCarts(null);
                backToShop();
            }
        }
    }, [isPageLoaded, props.shoppingCart]);

    const clearShoppingCartHandler = () => {
        props.onClearShoppingCart(props.userInfo.id, props.match.params.branchMarketplaceId, props.match.params.marketplaceId);
        props.onAllShoppingCarts(null);
        localStorage.removeItem('addressSelected');
        localStorage.removeItem('selectedAddress');
        localStorage.removeItem('haveRp');
        localStorage.removeItem('deliveryMethod');
        localStorage.removeItem('totalPrice');
        localStorage.removeItem('haveDrugInCart');
    }

    const backToShop = () => {
        if (!props.selectedStore) {
            props.history.replace("/shop/" + props.match.params.branchMarketplaceId + "/" + props.match.params.marketplaceId + "/0/" + textToUrl(props.match.params.branchMarketplaceName) + '/' + textToUrl(props.match.params.marketplaceName));
        } else {
            props.history.replace("/shop/" + props.match.params.branchMarketplaceId + "/" + props.match.params.marketplaceId + "/0/" + textToUrl(props.selectedStore.branchMarketplaceResponse.name) + '/' + textToUrl(props.selectedStore.marketplaceResponse.name));
        }
    }

    const redirectToDeliveryOptions = () => {
        if (props.shoppingCart !== null) {
            localStorage.setItem('totalPrice', props.totalPrice);
            localStorage.removeItem('haveRp');
            for (let item of props.shoppingCart.items) {
                if (localStorage.getItem('haveRp') === 'true') break;
                if (item.itemResponse.itemAvailabilityCode && item.itemResponse.itemAvailabilityCode !== 'MS' && item.itemResponse.itemAvailabilityCode !== 'BR') {
                    localStorage.setItem('haveRp', 'true');
                }
            }
        }
        localStorage.removeItem('deliveryMethod');
        const url = '/orderCompletion/' + props.match.params.branchMarketplaceId + "/" + props.match.params.marketplaceId + '/delivery'
        + '/' + props.match.params.branchMarketplaceName + '/' + props.match.params.marketplaceName;
        // props.history.replace('/')
        props.history.push(url);
    }

    const isDisabled = () => {
        return props.totalPrice === 0;
    }

    return (
        <Media queries={{
            small: "(max-width: 699px)",
            large: "(min-width: 700px)"
        }}>
            {matches => (
                <Fragment>
                    {matches.small &&
                        <OrderCompletionPageTemplateMobile
                            header={<HeaderWithTitleAndBackChevron
                                icon
                                onIconClick={backToShop}
                                title="Proizvodi i količina"/>}
                            style={{width:"100vw"}}
                        >
                            <ShoppingCartList/>
                            <span style={{
                                display: "flex",
                                width: "100%",
                                justifyContent: "end",
                                fontSize: "20px",
                                fontWeight:"bold",
                                padding: "1rem",
                                boxSizing: "border-box"
                            }}>Ukupno: {(parseFloat(props.totalPrice)).toLocaleString("de-DE", {minimumFractionDigits:2, maximumFractionDigits:2})} RSD</span>
                            <Button buttontype='blue'
                                    onClick={redirectToDeliveryOptions}
                            >Dalje </Button>
                        </OrderCompletionPageTemplateMobile>
                    }
                    {matches.large &&
                        <OrderCompletionPageTemplate
                            topPart={
                                <Actions style={{justifyContent: "flex-end"}}>
                                    <Button disabled={isDisabled()}
                                            buttontype={isDisabled() ? 'disabled' : 'blue'}
                                            style={{width:"170px", height:"60px", fontSize:"20px", padding: "0 2rem"}}
                                            onClick={redirectToDeliveryOptions}
                                    >Dalje <ChevronRightIcon
                                        style={{width: "20px", marginLeft: "1rem"}}/></Button>
                                </Actions>
                            }
                            leftPart={
                                <div style={{flexGrow: "1", paddingRight: "1rem", height: "100%"}}>
                                    <div className={styles.LeftPartHeader}>
                                        <div style={{fontSize: "26px", fontWeight: "700"}}>Vaša korpa:</div>
                                        <div className={styles.CartActionsWrapper}>
                                            <div className={styles.CartSummaryContinueShoppingButtons}
                                                 onClick={clearShoppingCartHandler}>
                                                <TrashIcon style={{stroke: "#A61C34"}}/>
                                                <p style={{color: "#A61C34"}}>&nbsp;&nbsp;Isprazni korpu</p>
                                            </div>
                                            <div className={styles.CartSummaryContinueShoppingButtons} onClick={backToShop}>
                                                <CartIcon style={{stroke: "#00B2B1"}}/>
                                                <p style={{color: "#00B2B1"}}>&nbsp;&nbsp;Nastavi rezervaciju</p>
                                            </div>
                                        </div>
                                    </div>
                                    <ShoppingCartList/>
                                </div>
                            }

                            rightPart={
                                <div style={{display: "flex", flexDirection: "column", height: "100%", width: "100%"}}>
                                    <TotalAmount totalState={(+(Math.round(props.totalPrice + "e+2") + "e-2")).toFixed(2)}/>
                                    {/*<p style={{ textAlign: "justify" }}>Plaćanje se vrši u objektu prilkom preuzimanja porudžbine</p>*/}
                                    <div>
                                        <PharmacyData/>
                                    </div>
                                </div>
                            }
                        />
                    }
                </Fragment>
            )}
        </Media>
    );
}

const mapStateToProps = (state) => {
    return {
        shoppingCart: state.shopPage.shoppingCart,
        totalPrice: state.shopPage.totalShoppingCartPrice,
        userInfo: state.authPage.userInfo,
        selectedStore: state.appPage.selectedStore
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onClearShoppingCart: (userId, branchMarketplaceId, marketplaceId) => dispatch(actions.clearShoppingCartAction(userId, branchMarketplaceId, marketplaceId)),
        onAllShoppingCarts: (allShoppingCarts) => dispatch(actions.allShoppingCarts(allShoppingCarts)),
        onCollectOrDeliveryChoice: (collectOrDelivery) => dispatch(actions.collectOrDeliveryChoice(collectOrDelivery))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CartSummary));