import React from 'react'
import Button from "../../UI/Buttons/Button/Button";
import ModalWithHeader from "../../UI/Modals/ModalWithHeader/ModalWithHeader";

const StartingNewCartModal = (props) => {
    return(
        <ModalWithHeader
            onCloseClick={props.onCloseClick}
            // icon={<DocumentTextIcon style={{width: "48px", height: "48px"}}/>}
            show={props.show}
            backdropClickHandler={props.backdropClickHandler}
            buttons={
                <Button buttontype="blue"
                           onClick={props.onCloseClick}
                           style={{margin: "auto", cursor: "pointer"}}
                >Zatvori</Button>
            }>
            <div style={{padding: "1rem"}}>
                <div style={{display: "flex", justifyContent: "center"}}>
                    <h4>Prethodno započeta rezervacija je poništena. <br/>
                        Možete započeti rezervaciju u ovoj apoteci.
                    </h4>
                </div>
            </div>
        </ModalWithHeader>
    );
}

export default StartingNewCartModal;