//main page and app page actions
export const TOOLBAR_CHANGED = 'TOOLBAR_CHANGED';
export const COUNT_MARKETPLACES = 'COUNT_MARKETPLACES';

export const ALL_ARTICLES = 'ALL_ARTICLES';
export const FETCH_ALL_ARTICLES_FAIlED = 'FETCH_ALL_ARTICLES_FAIlED';
export const FETCH_ALL_ARTICLES_START = 'FETCH_ALL_ARTICLES_START';

export const ALL_STORES_NAMES = 'ALL_STORES_NAMES';
export const FETCH_ALL_STORES_NAMES_FAIlED = 'FETCH_ALL_STORES_NAMES_FAIlED';
export const FETCH_ALL_STORES_NAMES_START = 'FETCH_ALL_STORES_NAMES_START';

export const MATCHED_ITEMS_CHANGED = 'MATCHED_ITEMS_CHANGED';
export const MATCHED_STORES_CHANGED = 'MATCHED_STORES_CHANGED';
export const SEARCH_STRING_CHANGED = 'SEARCH_STRING_CHANGED';
export const SEARCH_BAR_PLACEHOLDER_CHANGED = 'SEARCH_BAR_PLACEHOLDER_CHANGED';
export const SELECTED_INDEX_CHANGED = 'SELECTED_INDEX_CHANGED';
export const SELECT_DETECTED = 'SELECT_DETECTED';
export const NUMBER_OF_MATCHED_ARTICLES_CHANGED = 'NUMBER_OF_MATCHED_ARTICLES_CHANGED';

export const FETCH_RANDOM_ADVERTISEMENT = 'FETCH_RANDOM_ADVERTISEMENT';
export const FETCH_RANDOM_ADVERTISEMENT_FAIlED = 'FETCH_RANDOM_ADVERTISEMENT_FAIlED';
export const FETCH_RANDOM_ADVERTISEMENT_START = 'FETCH_RANDOM_ADVERTISEMENT_START';

export const FETCH_ALL_STORES_FOR_ARTICLE_START = 'FETCH_ALL_STORES_FOR_ARTICLE_START';
export const ALL_STORES_FOR_ARTICLE = 'ALL_STORES_FOR_ARTICLE';
export const ALL_STORES_IN_RADIUS_FOR_ARTICLE = 'ALL_STORES_IN_RADIUS_FOR_ARTICLE';
export const SLICE_OF_STORES_FOR_ARTICLE = 'SLICE_OF_STORES_FOR_ARTICLE';
export const FETCH_ALL_STORES_FOR_ARTICLE_FAIlED = 'FETCH_ALL_STORES_FOR_ARTICLE_FAIlED';
export const FETCH_ALL_STORES_FOR_ARTICLE_SERVER_ERROR = 'FETCH_ALL_STORES_FOR_ARTICLE_SERVER_ERROR';


//for quick store select modal
export const ALL_STORES_IN_RADIUS_FOR_ARTICLE_MODAL = 'ALL_STORES_IN_RADIUS_FOR_ARTICLE_MODAL';
export const FETCH_ALL_STORES_FOR_ARTICLE_MODAL_START = 'FETCH_ALL_STORES_FOR_ARTICLE_MODAL_START';
export const FETCH_ALL_STORES_FOR_ARTICLE_MODAL_FAIlED = 'FETCH_ALL_STORES_FOR_ARTICLE_MODAL_FAIlED';
export const FETCH_ALL_STORES_FOR_ARTICLE_MODAL_SERVER_ERROR = 'FETCH_ALL_STORES_FOR_ARTICLE_MODAL_SERVER_ERROR';
export const RESET_ALL_STORES_IN_RADIUS_FOR_ARTICLE_MODAL = 'RESET_ALL_STORES_IN_RADIUS_FOR_ARTICLE_MODAL';

export const FETCH_USER_LOCATION_START = 'FETCH_USER_LOCATION_START';
export const FETCH_USER_LOCATION_ERROR = 'FETCH_USER_LOCATION_ERROR';
export const FETCH_USER_LOCATION_FINISHED = 'FETCH_USER_LOCATION_FINISHED';

export const FETCH_DETAILS_FOR_ARTICLE_START = 'FETCH_DETAILS_FOR_ARTICLE_START';
export const FETCH_DETAILS_FOR_ARTICLE = 'FETCH_DETAILS_FOR_ARTICLE';
export const FETCH_DETAILS_FOR_ARTICLE_FAILED = 'FETCH_DETAILS_FOR_ARTICLE_FAILED';

export const RESET_APP_PAGE_STORE = 'RESET_APP_PAGE_STORE';


export const FETCH_DETAILS_FOR_STORE_START = 'FETCH_DETAILS_FOR_STORE_START';
export const FETCH_DETAILS_FOR_STORE = 'FETCH_DETAILS_FOR_STORE';
export const FETCH_DETAILS_FOR_STORE_FAILED = 'FETCH_DETAILS_FOR_STORE_FAILED';

export const SELECTED_STORE_CHANGED = 'SELECTED_STORE_CHANGED';
export const LOAD_SELECTED_STORE = 'LOAD_SELECTED_STORE';

export const SEARCH_INPUT_FOCUS = 'SEARCH_INPUT_FOCUS';

export const FETCH_ZOOM_CREDENTIALS = 'FETCH_ZOOM_CREDENTIALS'
export const FETCH_ZOOM_CREDENTIALS_START = 'FETCH_ZOOM_CREDENTIALS_START'
export const FETCH_ZOOM_CREDENTIALS_FAILED = 'FETCH_ZOOM_CREDENTIALS_FAILED'

export const STORE_ITEM_MODAL='STORE_ITEM_MODAL'
export const TOGGLE_SHOP_NOT_ONLINE_MODAL = 'TOGGLE_SHOP_NOT_ONLINE_MODAL';

export const ENTERED_ADDRESS_DATA = 'ENTERED_ADDRESS_DATA'

//actions for open hours for specific store
export const FETCH_OPEN_HOURS_FOR_STORE_START = 'FETCH_OPEN_HOURS_FOR_STORE_START';
export const FETCH_OPEN_HOURS_FOR_STORE = 'FETCH_OPEN_HOURS_FOR_STORE';
export const FETCH_OPEN_HOURS_FOR_STORE_FAILED = 'FETCH_OPEN_HOURS_FOR_STORE_FAILED';

export const SHOW_GOOGLE_MAP_CHANGED = 'SHOW_GOOGLE_MAP_CHANGED';


//action types for cms
export const ALL_STORES = 'ALL_STORES';
export const FETCH_ALL_STORES_FAIlED = 'FETCH_ALL_STORES_FAIlED';
export const STORE_CHANGED = 'STORE_CHANGED';
export const UPDATE_PICTURES = 'UPDATE_PICTURES';
export const UPDATE_BRANCH_MARKETPLACE_PICTURES = 'UPDATE_BRANCH_MARKETPLACE_PICTURES';
export const OPEN_HOURS_MODAL = 'OPEN_HOURS_MODAL';
export const ITEM_AVAILABILITIES = 'ITEM_AVAILABILITIES';
export const FETCH_ITEM_AVAILABILITIES_FAIlED = 'FETCH_ITEM_AVAILABILITIES_FAIlED';

//action types for auth
export const LOGIN_USER_START = 'LOGIN_USER_START';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILED = 'LOGIN_USER_FAILED';
export const LOGIN_REFRESH_FAILED = 'LOGIN_REFRESH_FAILED';
export const LOGIN_SERVER_ERROR = 'LOGIN_SERVER_ERROR';
export const REGISTRATION_UNCONFIRMED = 'REGISTRATION_UNCONFIRMED';
export const LOGOUT_USER = 'LOGOUT_USER';
export const FORGET_PASSWORD_START = 'FORGET_PASSWORD_START';
export const FORGET_PASSWORD_SUCCESS = 'FORGET_PASSWORD_SUCCESS';
export const FORGET_PASSWORD_FAILED = 'FORGET_PASSWORD_FAILED';
export const REGISTER_USER_START = 'REGISTER_USER_START';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_FAILED = 'REGISTER_USER_FAILED';
export const REGISTRATION_SERVER_ERROR = 'REGISTRATION_SERVER_ERROR';
export const FETCH_USER_INFO = 'FETCH_USER_INFO';
export const FETCH_USER_INFO_START = 'FETCH_USER_INFO_START';
export const FETCH_USER_INFO_FAILED = 'FETCH_USER_INFO_FAILED';
export const GOOGLE_RECAPTCHA_VERIFICATION = 'GOOGLE_RECAPTCHA_VERIFICATION';
export const GOOGLE_RECAPTCHA_VERIFICATION_START = 'GOOGLE_RECAPTCHA_VERIFICATION_START';
export const GOOGLE_RECAPTCHA_VERIFICATION_FAILED = 'GOOGLE_RECAPTCHA_VERIFICATION_FAILED';

export const PP_TOS_MODAL_HANDLER = 'PP_TOS_MODAL_HANDLER';

//actions for single shop
export const ALL_SINGLE_STORE_ARTICLES = 'ALL_SINGLE_STORE_ARTICLES';
export const SLICE_OF_SINGLE_STORE_ARTICLES = 'SLICE_OF_SINGLE_STORE_ARTICLES';
export const FETCH_SINGLE_STORE_ARTICLES_FAILED = 'FETCH_SINGLE_STORE_ARTICLES_FAILED';
export const FETCH_SINGLE_STORE_ARTICLES_START = 'FETCH_SINGLE_STORE_ARTICLES_START';
export const NAME_OF_ALL_SINGLE_STORE_ARTICLES = 'NAME_OF_ALL_SINGLE_STORE_ARTICLES';
export const FETCH_NAME_OF_ALL_SINGLE_STORE_ARTICLES_FAILED = 'FETCH_NAME_OF_ALL_SINGLE_STORE_ARTICLES_FAILED';
export const FETCH_NAME_OF_ALL_SINGLE_STORE_ARTICLES_START = 'FETCH_NAME_OF_ALL_SINGLE_STORE_ARTICLES_START';
export const FETCH_SELECTED_ARTICLE = 'FETCH_SELECTED_ARTICLE';
export const DESELECT_SELECTED_ARTICLE = 'DESELECT_SELECTED_ARTICLE';
export const FETCH_SELECTED_ARTICLE_FAILED = 'FETCH_SELECTED_ARTICLE_FAILED';
export const FETCH_SELECTED_ARTICLE_START = 'FETCH_SELECTED_ARTICLE_START';

export const SORT_SHOP_ARTICLES = 'SORT_SHOP_ARTICLES'

//actions for shopping cart
export const FETCH_SHOPPING_CART = 'FETCH_SHOPPING_CART';
export const FETCH_SHOPPING_CART_FAILED = 'FETCH_SHOPPING_CART_FAILED';
export const FETCH_SHOPPING_CART_START = 'FETCH_SHOPPING_CART_START';
export const ALL_STORE_ARTICLES_FOR_ARTICLES_IDS = 'ALL_STORE_ARTICLES_FOR_ARTICLES_IDS';
export const SLICE_OF_STORE_ARTICLES_FOR_ARTICLES_IDS = 'SLICE_OF_STORE_ARTICLES_FOR_ARTICLES_IDS';
export const FETCH_STORE_ARTICLES_FOR_ARTICLES_IDS_FAILED = 'FETCH_STORE_ARTICLES_FOR_ARTICLES_IDS_FAILED';
export const FETCH_STORE_ARTICLES_FOR_ARTICLES_IDS_START = 'FETCH_STORE_ARTICLES_FOR_ARTICLES_IDS_START';
export const ADD_ITEM_TO_SHOPPING_CART = 'ADD_ITEM_TO_SHOPPING_CART';
export const ADD_ITEM_TO_SHOPPING_CART_FAILED = 'ADD_ITEM_TO_SHOPPING_CART_FAILED';
export const ADD_ITEM_TO_SHOPPING_CART_START = 'ADD_ITEM_TO_SHOPPING_CART_START';
export const REMOVE_ITEM_FROM_SHOPPING_CART = 'REMOVE_ITEM_FROM_SHOPPING_CART';
export const REMOVE_ITEM_FROM_SHOPPING_CART_FAILED = 'REMOVE_ITEM_FROM_SHOPPING_CART_FAILED';
export const REMOVE_ITEM_FROM_SHOPPING_CART_START = 'REMOVE_ITEM_FROM_SHOPPING_CART_START';
export const CLEAR_SHOPPING_CART = 'CLEAR_SHOPPING_CART';
export const CLEAR_SHOPPING_CART_FAILED = 'CLEAR_SHOPPING_CART_FAILED';
export const CLEAR_SHOPPING_CART_START = 'CLEAR_SHOPPING_CART_START';
export const CHANGE_QUANTITY_OF_SHOPPING_CART_ITEM = 'CHANGE_QUANTITY_OF_SHOPPING_CART_ITEM';
export const CHANGE_QUANTITY_OF_SHOPPING_CART_ITEM_START = 'CHANGE_QUANTITY_OF_SHOPPING_CART_ITEM_START';
export const CHANGE_QUANTITY_OF_SHOPPING_CART_ITEM_FAILED = 'CHANGE_QUANTITY_OF_SHOPPING_CART_ITEM_FAILED';
export const FETCH_ALL_SHOPPING_CARTS = 'FETCH_ALL_SHOPPING_CARTS';
export const FETCH_ALL_SHOPPING_CARTS_FAILED ='FETCH_ALL_SHOPPING_CARTS_FAILED';
export const FETCH_ALL_SHOPPING_CARTS_START = 'FETCH_ALL_SHOPPING_CARTS_START';
export const INITIATE_ADD_TO_SHOPPING_CART_ANIMATION = 'INITIATE_ADD_TO_SHOPPING_CART_ANIMATION';

export const UPLOAD_PRESCRIPTIONS_FINISHED = 'UPLOAD_PRESCRIPTIONS_FINISHED';
export const UPLOAD_PRESCRIPTIONS_FAILED = 'UPLOAD_PRESCRIPTIONS_FAILED';
export const UPLOAD_PRESCRIPTIONS_START = 'UPLOAD_PRESCRIPTIONS_START';
export const DELETE_PRESCRIPTION = 'DELETE_PRESCRIPTION';
export const DELETE_PRESCRIPTION_FAILED = 'DELETE_PRESCRIPTION_FAILED';
export const DELETE_PRESCRIPTION_START = 'DELETE_PRESCRIPTION_START';
export const DELETE_ALL_PRESCRIPTIONS = 'DELETE_ALL_PRESCRIPTIONS';
export const DELETE_ALL_PRESCRIPTIONS_FAILED = 'DELETE_ALL_PRESCRIPTIONS_FAILED';
export const DELETE_ALL_PRESCRIPTIONS_START = 'DELETE_ALL_PRESCRIPTIONS_START';

export const NUMBER_OF_ITEMS_IN_SHOPPING_CART = 'NUMBER_OF_ITEMS_IN_SHOPPING_CART';


//actions for order status
export const ORDER_ACKNOWLEDGED = 'ORDER_ACKNOWLEDGED';
export const ORDER_DENIED = 'ORDER_DENIED';
export const ORDER_CHANGED_STATUS = 'ORDER_CHANGED_STATUS'

//actions for toolbar
export const SHOW_SHOPPING_CARD_DRAWER_CHANGED = 'SHOW_SHOPPING_CARD_DRAWER_CHANGED';
export const TOOLBAR_WHITE = 'TOOLBAR_WHITE';

//actions for filter
export const GRID_LIST_TOGGLE_VIEW = 'GRID_LIST_TOGGLE_VIEW';
export const SHOP_FILTER = 'SHOP_FILTER';
export const RESET_SHOP_FILTER = 'RESET_SHOP_FILTER';
export const STORE_FILTER = 'STORE_FILTER';
export const FILTER_BREADCRUMBS = 'FILTER_BREADCRUMBS';
export const LOYALTY_FILTER = 'LOYALTY_FILTER'
export const DISCOUNT_FILTER = 'DISCOUNT_FILTER'
export const FETCH_ADV_CATEGORY_IMAGE_START = 'FETCH_ADV_CATEGORY_IMAGE_START'
export const FETCH_ADV_CATEGORY_IMAGE_FAILED = 'FETCH_ADV_CATEGORY_IMAGE_FAILED'
export const ADV_CATEGORY_IMAGE = 'ADV_CATEGORY_IMAGE';

export const FETCH_CATEGORIES_FOR_ARTICLES_START = 'FETCH_CATEGORIES_FOR_ARTICLES_START';
export const FETCH_CATEGORIES_FOR_ARTICLES_FAILED = 'FETCH_CATEGORIES_FOR_ARTICLES_FAILED';
export const FETCH_ARTICLE_CATEGORY_DETAILS = 'FETCH_ARTICLE_CATEGORY_DETAILS';

//actions for userProfile
export const USER_PROFILE = 'USER_PROFILE';
export const USER_PROFILE_FAILED = 'USER_PROFILE_FAILED';
export const USER_PROFILE_START = 'USER_PROFILE_START';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILED = 'CHANGE_PASSWORD_FAILED';
export const CHANGE_PASSWORD_WRONG_OLD_PASSWORD = 'CHANGE_PASSWORD_WRONG_OLD_PASSWORD';
export const CHANGE_PASSWORD_START = 'CHANGE_PASSWORD_START';
export const UPDATE_USER_PROFILE_SUCCESS = 'UPDATE_USER_PROFILE_SUCCESS';
export const UPDATE_USER_PROFILE_FAILED = 'UPDATE_USER_PROFILE_FAILED';
export const UPDATE_USER_PROFILE_START = 'UPDATE_USER_PROFILE_START';
export const DELETE_USER_PROFILE_SUCCESS = 'DELETE_USER_PROFILE_SUCCESS';
export const DELETE_USER_PROFILE_FAILED = 'DELETE_USER_PROFILE_FAILED';
export const DELETE_USER_PROFILE_START = 'DELETE_USER_PROFILE_START';

//actions for userProfile - user address
export const ADD_NEW_USER_ADDRESS = 'ADD_NEW_USER_ADDRESS';
export const ADD_NEW_USER_ADDRESS_FAILED = 'ADD_NEW_USER_ADDRESS_FAILED';
export const ADD_NEW_USER_ADDRESS_START = 'ADD_NEW_USER_ADDRESS_START';
export const DELETE_USER_ADDRESS = 'DELETE_USER_ADDRESS';
export const DELETE_USER_ADDRESS_FAILED = 'DELETE_USER_ADDRESS_FAILED';
export const DELETE_USER_ADDRESS_START = 'DELETE_USER_ADDRESS_START';
export const UPDATE_USER_ADDRESS = 'UPDATE_USER_ADDRESS';
export const UPDATE_USER_ADDRESS_FAILED = 'UPDATE_USER_ADDRESS_FAILED';
export const UPDATE_USER_ADDRESS_START = 'UPDATE_USER_ADDRESS_START';

//actions for userProfile - user therapies
export const USER_THERAPIES = 'USER_THERAPIES';
export const USER_THERAPIES_START = 'USER_THERAPIES_START';
export const USER_THERAPIES_FAILED = 'USER_THERAPIES_FAILED';
export const SET_SELECTED_ITEM = 'SET_SELECTED_ITEM';
export const TOGGLE_THERAPY_ARTICLE_MODAL = 'TOGGLE_THERAPY_ARTICLE_MODAL';

export const ADD_ARTICLE_TO_THERAPY = 'ADD_ARTICLE_TO_THERAPY';
export const REMOVE_ARTICLE_FROM_THERAPY = ' REMOVE_ARTICLE_FROM_THERAPY';
export const REMOVE_ALL_ARTICLES_FROM_THERAPY = 'REMOVE_ALL_ARTICLES_FROM_THERAPY';

export const NEW_THERAPY_CREATED = 'NEW_THERAPY_CREATED';
export const CREATE_NEW_THERAPY_START = 'CREATE_NEW_THERAPY_START';
export const CREATE_NEW_THERAPY_FAILED = 'CREATE_NEW_THERAPY_FAILED';

export const USER_THERAPY_DELETED = 'USER_THERAPY_DELETED';
export const DELETE_USER_THERAPY_START = 'DELETE_USER_THERAPY_START';
export const DELETE_USER_THERAPY_FAILED = 'DELETE_USER_THERAPY_FAILED';

export const ADD_ARTICLE_TO_THERAPY_FROM_INFO_LEK = 'ADD_ARTICLE_TO_THERAPY_FROM_INFO_LEK';

export const GET_NEW_THERAPY_NAME_FROM_INFO_LEK_MODAL = 'GET_NEW_THERAPY_NAME_FROM_INFO_LEK_MODAL';

export const ADD_ARTICLE_TO_THERAPY_FROM_INFO_LEK_MODAL_TOGGLE = 'ADD_ARTICLE_TO_THERAPY_FROM_INFO_LEK_MODAL_TOGGLE';

//actions from userProfile - ordering user therapy
export const STORES_FOR_USER_THERAPY = 'STORES_FOR_USER_THERAPY'

//actions from modalRes
export const SHOW_MODAL_RES = 'SHOW_MODAL_RES';
export const CNC_MODAL_TOGGLE = 'CNC_MODAL_TOGGLE';
export const CHOOSE_LOCATION_MODAL_TOGGLE = 'CHOOSE_LOCATION_MODAL_TOGGLE';

//actions for stores search
export const ALL_SEARCHED_STORES = 'ALL_SEARCHED_STORES';
export const SLICE_OF_SEARCHED_STORES = 'SLICE_OF_SEARCHED_STORES';
export const FETCH_ALL_SEARCHED_STORES_FAIlED = 'FETCH_ALL_SEARCHED_STORES_FAIlED';
export const FETCH_ALL_SEARCHED_STORES_SERVER_ERROR = 'FETCH_ALL_SEARCHED_STORES_SERVER_ERROR';
export const FETCH_ALL_SEARCHED_STORES_START = 'FETCH_ALL_SEARCHED_STORES_START';
export const ALL_SEARCHED_STORES_IN_RADIUS = 'ALL_SEARCHED_STORES_IN_RADIUS';
export const ALL_STORES_OBJECTS = 'ALL_STORES_OBJECTS';
export const SLICE_OF_ALL_STORES_OBJECTS = 'SLICE_OF_ALL_STORES_OBJECTS';
export const FETCH_ALL_STORES_OBJECTS_FAILED = 'FETCH_ALL_STORES_OBJECTS_FAILED';
export const FETCH_ALL_STORES_OBJECTS_SERVER_ERROR = 'FETCH_ALL_STORES_OBJECTS_SERVER_ERROR';
export const FETCH_ALL_STORES_OBJECTS_START = 'FETCH_ALL_STORES_OBJECTS_START';
export const ALL_STORES_IN_RADIUS = 'ALL_STORES_IN_RADIUS';
export const FETCH_ALL_STORES_FOR_MODAL = 'FETCH_ALL_STORES_FOR_MODAL'
export const FETCH_ALL_STORES_FOR_MODAL_START = 'FETCH_ALL_STORES_FOR_MODAL_START'
export const FETCH_ALL_STORES_FOR_MODAL_FAILED = 'FETCH_ALL_STORES_FOR_MODAL_FAILED'

//order completion
export const COLLECT_OR_DELIVERY_CHOICE = 'COLLECT_OR_DELIVERY_CHOICE';
export const SHOPPING_CART_CHECKED = 'SHOPPING_CART_CHECKED';
export const FINAL_CHECK_SHOPPING_CART_FAILED = 'FINAL_CHECK_SHOPPING_CART_FAILED';
export const FINAL_CHECK_SHOPPING_CART_START = 'FINAL_CHECK_SHOPPING_CART_START';


//ordersAndTransaction
export const FETCH_ALL_ORDERS = 'FETCH_ALL_ORDERS';
export const FETCH_ALL_ORDERS_FAILED = 'FETCH_ALL_ORDERS_FAILED';
export const FETCH_ALL_ORDERS_START = 'FETCH_ALL_ORDERS_START';
export const FETCH_ALL_TRANSACTIONS = 'FETCH_ALL_TRANSACTIONS';
export const FETCH_ALL_TRANSACTIONS_FAILED = 'FETCH_ALL_TRANSACTIONS_FAILED';
export const FETCH_ALL_TRANSACTIONS_START = 'FETCH_ALL_TRANSACTIONS_START';
export const FETCH_SINGLE_ORDER = 'FETCH_SINGLE_ORDER';
export const FETCH_SINGLE_ORDER_FAILED = 'FETCH_SINGLE_ORDER_FAILED';
export const FETCH_SINGLE_ORDER_START = 'FETCH_SINGLE_ORDER_START';

//actions for search articles page
export const ALL_SEARCHED_ARTICLES = 'ALL_SEARCHED_ARTICLES';
export const SLICE_OF_SEARCHED_ARTICLES = 'SLICE_OF_SEARCHED_ARTICLES';
export const FETCH_ALL_SEARCHED_ARTICLES_FAIlED = 'FETCH_ALL_SEARCHED_ARTICLES_FAIlED';
export const FETCH_ALL_SEARCHED_ARTICLES_SERVER_ERROR = 'FETCH_ALL_SEARCHED_ARTICLES_SERVER_ERROR';
export const FETCH_ALL_SEARCHED_ARTICLES_START = 'FETCH_ALL_SEARCHED_ARTICLES_START';
export const ALL_ARTICLES_OBJECTS = 'ALL_ARTICLES_OBJECTS';
export const SLICE_OF_ALL_ARTICLES_OBJECTS = 'SLICE_OF_ALL_ARTICLES_OBJECTS';
export const FETCH_ALL_ARTICLES_OBJECTS_FAIlED = 'FETCH_ALL_ARTICLES_OBJECTS_FAIlED';
export const FETCH_ALL_ARTICLES_OBJECTS_SERVER_ERROR = 'FETCH_ALL_ARTICLES_OBJECTS_SERVER_ERROR';
export const FETCH_ALL_ARTICLES_OBJECTS_START = 'FETCH_ALL_ARTICLES_OBJECTS_START';
export const RESET_ARTICLES = 'RESET_ARTICLES';

//actions for action menu
export const ACTION_MENU_STICKY = 'ACTION_MENU_STICKY';

//actions for info page
export const FETCH_DETAILS_FOR_STORE_FOR_INFO_PAGE_START = 'FETCH_DETAILS_FOR_STORE_FOR_INFO_PAGE_START';
export const FETCH_DETAILS_FOR_STORE_FOR_INFO_PAGE = 'FETCH_DETAILS_FOR_STORE_FOR_INFO_PAGE';
export const FETCH_DETAILS_FOR_STORE_FOR_INFO_PAGE_FAILED = 'FETCH_DETAILS_FOR_STORE_FOR_INFO_PAGE_FAILED';

export const FETCH_ARTICLE_DETAILS_FOR_INFO_PAGE_START = 'FETCH_ARTICLE_DETAILS_FOR_INFO_PAGE_START';
export const FETCH_ARTICLE_DETAILS_FOR_INFO_PAGE = 'FETCH_ARTICLE_DETAILS_FOR_INFO_PAGE';
export const FETCH_ARTICLE_DETAILS_FOR_INFO_PAGE_FAILED = 'FETCH_ARTICLE_DETAILS_FOR_INFO_PAGE_FAILED';

export const FETCH_ARTICLE_INFO_START = 'FETCH_ARTICLE_INFO_START';
export const FETCH_ARTICLE_INFO = 'FETCH_ARTICLE_INFO';
export const FETCH_ARTICLE_INFO_FAILED = 'FETCH_ARTICLE_INFO_FAILED';

export const FETCH_CATEGORY_RELATED_ARTICLES = 'FETCH_CATEGORY_RELATED_ARTICLES';
export const FETCH_CATEGORY_RELATED_ARTICLES_START = 'FETCH_CATEGORY_RELATED_ARTICLES_START';
export const FETCH_CATEGORY_RELATED_ARTICLES_FAILED = 'FETCH_CATEGORY_RELATED_ARTICLES_FAILED';

export const FETCH_ARTICLE_IN_STORE_START = 'FETCH_ARTICLE_IN_STORE_START';
export const FETCH_ARTICLE_IN_STORE = 'FETCH_ARTICLE_IN_STORE';
export const FETCH_ARTICLE_IN_STORE_FAILED = 'FETCH_ARTICLE_IN_STORE_FAILED';

export const FETCH_RELATED_ARTICLES_FOR_ARTICLE = 'FETCH_RELATED_ARTICLES_FOR_ARTICLE';
export const FETCH_RELATED_ARTICLES_FOR_ARTICLE_FAILED = 'FETCH_RELATED_ARTICLES_FOR_ARTICLE_FAILED';
export const FETCH_RELATED_ARTICLES_FOR_ARTICLE_START = 'FETCH_RELATED_ARTICLES_FOR_ARTICLE_START';

export const FETCH_RELATED_ARTICLES_ON_TERAPIJSKA_INDIKACIJA = 'FETCH_RELATED_ARTICLES_ON_TERAPIJSKA_INDIKACIJA';
export const FETCH_RELATED_ARTICLES_ON_TERAPIJSKA_INDIKACIJA_FAILED = 'FETCH_RELATED_ARTICLES_ON_TERAPIJSKA_INDIKACIJA_FAILED';
export const FETCH_RELATED_ARTICLES_ON_TERAPIJSKA_INDIKACIJA_START = 'FETCH_RELATED_ARTICLES_ON_TERAPIJSKA_INDIKACIJA_START';

export const CATEGORY_FOR_ARTICLE = 'CATEGORY_FOR_ARTICLE';
export const CATEGORY_FOR_ARTICLE_START = 'CATEGORY_FOR_ARTICLE_START';
export const CATEGORY_FOR_ARTICLE_FAILED = 'CATEGORY_FOR_ARTICLE_FAILED';

//actions for user search input
export const CREATE_USER_SEARCH_INPUT = 'CREATE_USER_SEARCH_INPUT';
export const CREATE_USER_SEARCH_INPUT_FAILED = 'CREATE_USER_SEARCH_INPUT_FAILED';
export const CREATE_USER_SEARCH_INPUT_START = 'CREATE_USER_SEARCH_INPUT_START';

//actions for fatching rating
export const FETCH_RATING = 'FETCH_RATING';
export const FETCH_RATING_FAIlED = 'FETCH_RATING_FAIlED';
export const FETCH_RATING_START = 'FETCH_RATING_START';

//actions for rate transaction
export const RATE_TRANSACTION = 'RATE_TRANSACTION';

//ws socket actions
export const ORDER_SOCKET_CONNECTION_CHANGED = 'ORDER_SOCKET_CONNECTION_CHANGED';
export const ORDER_SOCKET_CONNECTION_START = 'ORDER_SOCKET_CONNECTION_START';

////actions for rfzo therapy
export const FETCH_RFZO_THERAPY = 'FETCH_RFZO_THERAPY';
export const FETCH_RFZO_THERAPY_FAILED = 'FETCH_RFZO_THERAPY_FAILED';
export const FETCH_RFZO_THERAPY_START = 'FETCH_RFZO_THERAPY_START';

export const RFZO_THERAPY_ACEPTED = 'RFZO_THERAPY_ACEPTED';
export const ACCEPT_RFZO_THERAPY_FAILED = 'ACCEPT_RFZO_THERAPY_FAILED';
export const ACCEPT_RFZO_THERAPY_START = 'ACCEPT_RFZO_THERAPY_START';

export const RFZO_THERAPY_REJECTED = 'RFZO_THERAPY_REJECTED';
export const REJECT_RFZO_THERAPY_FAILED = 'REJECT_RFZO_THERAPY_FAILED';
export const REJECT_RFZO_THERAPY_START = 'REJECT_RFZO_THERAPY_START';

export const RFZO_THERAPY_CREATED = 'RFZO_THERAPY_CREATED';
export const CREATE_RFZO_THERAPY_FAILED = 'CREATE_RFZO_THERAPY_FAILED';
export const CREATE_RFZO_THERAPY_START = 'CREATE_RFZO_THERAPY_START';
export const RESET_RFZO_THERAPY_CREATED = 'RESET_RFZO_THERAPY_CREATED';

export const CHECK_RFZO_THERAPY = 'CHECK_RFZO_THERAPY';
export const CHECK_RFZO_THERAPY_FAILED = 'CHECK_RFZO_THERAPY_FAILED';
export const CHECK_RFZO_THERAPY_START = 'CHECK_RFZO_THERAPY_START'

export const RFZO_SOCKET_CONNECTION_STATUS = 'RFZO_SOCKET_CONNECTION_STATUS';
export const RFZO_SOCKET_CONNECTION_START = 'RFZO_SOCKET_CONNECTION_START'

export const RFZO_MODAL_TOGGLE = 'RFZO_MODAL_TOGGLE'

export const DELETE_EMPTY_RFZO_THERAPY_START = 'DELETE_EMPTY_RFZO_THERAPY_START'
export const DELETE_EMPTY_RFZO_THERAPY_SUCCESS = 'DELETE_EMPTY_RFZO_THERAPY_SUCCESS'
export const DELETE_EMPTY_RFZO_THERAPY_FAILED ='DELETE_EMPTY_RFZO_THERAPY_FAILED'
export const RESET_EMPTY_RFZO_THERAPY_FAILED = 'RESET_EMPTY_RFZO_THERAPY_FAILED'


export const RFZO_THERAPY_EXPIRE = 'RFZO_THERAPY_EXPIRE';
export const RFZO_THERAPY_EXPIRE_START = 'RFZO_THERAPY_EXPIRE_START';
export const RFZO_THERAPY_EXPIRE_FAILED = 'RFZO_THERAPY_EXPIRE_FAILED';

export const RFZO_THERAPY_CANCEL = 'RFZO_THERAPY_CANCEL';
export const RFZO_THERAPY_CANCEL_START = 'RFZO_THERAPY_CANCEL_START';
export const RFZO_THERAPY_CANCEL_FAILED = 'RFZO_THERAPY_CANCEL_FAILED';

export const RFZO_THERAPY_STATUS_CHANGED = 'RFZO_THERAPY_STATUS_CHANGED';
export const SET_WILL_BE_READY = 'SET_WILL_BE_READY';
export const SET_CANCEL_NOTE = 'SET_CANCEL_NOTE';