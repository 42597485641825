import React from 'react'
import styles from "../EditProfileModal.module.css";
import Button from "../../../UI/Buttons/Button/Button";
import authPageStyles from "../../../AuthPage/AuthPage.module.css";
import {useForm} from "react-hook-form";
import * as actions from "../../../../store/actions";
import {connect} from "react-redux";

const EditUserNameForm = (props) => {

    const {register, handleSubmit, formState: {errors}, getValues} = useForm();
    const onEditNameSubmit = () => {

        const values = getValues();

        props.inputStateChanged({
            ...props.inputState,
            user: {
                firstName: values.firstName,
                secondName: values.secondName,
            }
        })

        const updatedUserProfile = {
            ...props.userProfile,
            firstName: values.firstName === '' ? props.userProfile.firstName : values.firstName,
            secondName: values.secondName === '' ? props.userProfile.secondName : values.secondName,
        }
        props.closeEditModal();
        props.onUpdateUserProfile(updatedUserProfile);
    }

    return (
        <form onSubmit={handleSubmit(onEditNameSubmit)}>
            <input className={styles.EditInputFiled} type="text" placeholder="Ime"
                   defaultValue={props.inputState.user.firstName}
                   {...register("firstName", {
                       required: "Ovo polje ne moze da bude prazno",
                       minLength: {
                           value: 2,
                           message: "Ime mora sadržati najmanje 2 karaktera"
                       },
                       maxLength: {
                           value: 20,
                           message: "Ime ne može sadržati više od 20 karaktera"
                       },
                       pattern: {
                           value: /^[ a-zA-ZљњертжуиопшђасдфгхјклчћзџцвбнмšđžčćЉЊЕРТЖУИОПШЂАСДФГХЈКЛЧЋЗЏЦВБНМŠĐŽČĆ'`'\-]+$/,
                           message: "Ime nije odgovarajućeg formata"
                       },
                   })} />
            {errors.firstName &&
                <span className={authPageStyles.ErrorMessage}>{errors.firstName.message}</span>}

            <input className={styles.EditInputFiled} type="text" placeholder="Prezime"
                   defaultValue={props.inputState.user.secondName}
                   {...register("secondName", {
                       required: "Ovo polje ne može biti prazno",
                       minLength: {
                           value: 2,
                           message: "Prezime mora sadržati najmanje 2 karaktera"
                       },
                       maxLength: {
                           value: 50,
                           message: "Prezime ne može sadržati više od 50 karaktera"
                       },
                       pattern: {
                           value: /^[ a-zA-ZљњертжуиопшђасдфгхјклчћзџцвбнмšđžčćЉЊЕРТЖУИОПШЂАСДФГХЈКЛЧЋЗЏЦВБНМŠĐŽČĆ'`'\-]+$/,
                           message: "Prezime nije odgovarajućeg formata"
                       }
                   })}
            />
            {errors.secondName &&
                <span className={authPageStyles.ErrorMessage}>{errors.secondName.message}</span>}

            <div className={styles.ButtonsHolder}>
                <Button onClick={props.closeEditModal}>Otkaži</Button>
                <Button buttontype='whiteblue' type='submit'>Sačuvaj</Button>
            </div>
        </form>
    );
}

const mapStateToProps = (state) => {
    return {
        userProfile: state.userProfile.userProfile,
        userProfileError: state.userProfile.userProfileError,
        loadingUserProfile: state.userProfile.loadingUserProfile,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onUpdateUserProfile: (userProfile) => dispatch(actions.updateUserProfile(userProfile))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditUserNameForm);