import React from 'react';
import styles from './InactiveToolbar.module.css'
import apotekaLogo from "../../../assets/logo_apoteka.svg";

const InactiveToolbar = () => {
    return (
        <div className={styles.InactiveToolbarWrapper}>
            <div className={styles.InactiveToolbarContent}>
                <img src={apotekaLogo} alt={'apoteka-logo'} style={{height:"100%"}} />
            </div>
        </div>
    );
};

export default InactiveToolbar;