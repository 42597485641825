import React, {Fragment, useEffect, useRef} from "react";
import styles from './ArticleItems.module.css';
import Spinner from '../../UI/Spinner/Spinner'
import {connect} from "react-redux";
import * as actions from "../../../store/actions";
import {withRouter} from "react-router-dom";
import Pagination from './../../UI/Pagination/Pagination'
// import AdsBanner from "../AdsBanner/AdsBanner";
// import {faLongArrowAltLeft} from "@fortawesome/free-solid-svg-icons/faLongArrowAltLeft";
// import {faLongArrowAltRight} from "@fortawesome/free-solid-svg-icons/faLongArrowAltRight";
//import GridLayout from 'react-grid-layout';
//import benuReklama from '../../../assets/Benu-reklama.png'
import ArticleItem from './ArticleItem/ArticleItem'
import {selectArticle} from "../../../store/actions";
// import {redirectToError} from "../../../containers/App";
import bImage from "../../../assets/bImage.png";
import bImageMobile from "../../../assets/bImage-mobile.png";
import Wrapper from "../../UI/Wrapper/Wrapper";
import ConfigData from "../../../config.json";
import DefaultImage from "../../../assets/PictureHolder.png";
import SearchedArticleItem from "./SearchedArticleItem/SearchedArticleItem";
import Media from "react-media";
import SearchedArticleItemMobile from "./SearchedArticleItemMobile/SearchedArticleItemMobile";


const ArticleItems = (props) => {

    const isFirstRun = useRef(true);

    const addItemToShoppingCartHandler = (item) => {
        if (props.userInfo !== null) {
            props.onAddItemToShoppingCart(props.userInfo.id, props.match.params.branchMarketplaceId, props.match.params.marketplaceId, item);
        }
    }

    const onNextPageHandler = (page) => {
        document.documentElement.scrollTop = 0;

        let url = '';
        if (props.history.location.pathname.includes("/shop/")) {
            if (props.selectedArticles !== null) {
                if (props.match.params.searchedArticleId !== undefined && props.match.params.searchedArticleId.match(/^[0-9]+$/) === null) {
                    url = '/shop/' + props.match.params.branchMarketplaceId + '/' + props.match.params.marketplaceId + '/' + page + '/' + props.match.params.searchedArticleId + '/' + props.match.params.branchMarketplaceName + '/' + props.match.params.marketplaceName;
                } else {
                    url = '/shop/' + props.match.params.branchMarketplaceId + '/' + props.match.params.marketplaceId + '/' + page + '/' + props.selectedArticles.itemResponse.id + '/' + props.match.params.branchMarketplaceName + '/' + props.match.params.marketplaceName;
                }
            } else {
                if (props.match.params.searchedArticleId !== undefined && props.match.params.searchedArticleId.match(/^[0-9]+$/) === null) {
                    url = '/shop/' + props.match.params.branchMarketplaceId + '/' + props.match.params.marketplaceId + '/' + page + '/' + props.match.params.searchedArticleId + '/' + props.match.params.branchMarketplaceName + '/' + props.match.params.marketplaceName;
                } else {
                    url = '/shop/' + props.match.params.branchMarketplaceId + '/' + props.match.params.marketplaceId + '/' + page + '/' + props.match.params.branchMarketplaceName + '/' + props.match.params.marketplaceName;
                }
            }
        } else {
            url = props.match.params.searchedString !== undefined ? '/searched-articles/' + page + '/' + props.match.params.searchedString :
                '/searched-articles/' + page;
        }

        isFirstRun.current = false;
        props.history.replace(url);
    }

    //Vraca stranu (vrednost page) u url-u na 0 pri promeni filtera
    useEffect(() => {
        if (props.match.params.page !== 0 && !isFirstRun.current) {
            onNextPageHandler(0);
        }
    }, [props.shopFilter])

    let searchedArticle = <Spinner/>;
    let articleItems = <Spinner/>;
    let indexFlag = 0;
    // let quickBuy = localStorage.getItem('quickBuy');

    if (props.searchedParams !== undefined && !props.loadingSelectedArticles) {
        if (props.selectedArticlesFailed) {
            // redirectToError(props);
            articleItems = <div className={styles.SearchResultErrorMessage}>Nijedan proizvod ne odgovara parametrima pretrage.</div>;
        } else {
            if (props.searchedParams.match(/^[0-9]+$/) === null) {
                articleItems = props.selectedArticles !== null && props.selectedArticles.length !== undefined ? props.selectedArticles.map(articleItem => {

                    let quantityInCart = 0
                    if (props.shoppingCart !== null) {
                        props.shoppingCart.items.map(item => {
                            if (item.itemResponse.id === articleItem.itemResponse.id) {
                                quantityInCart = item.quantity
                            }
                        })
                    }

                    return <ArticleItem articleItem={articleItem}
                                        key={articleItem.itemResponse.id}
                                        addItemToShoppingCart={() => addItemToShoppingCartHandler(articleItem)}
                                        articlePicture={articleItem.pictures}
                                        articlePrice={articleItem.price}
                                        quantityInCart={quantityInCart}
                    />
                }) : null;
                props.onSearchBarPlaceholderChanged(props.searchedParams);
            } else {

                if (props.selectedArticles !== null && props.selectedArticles.length === undefined){

                    let quantityInCart1 = 0;
                    if (props.shoppingCart !== null) {
                        props.shoppingCart.items.map(item => {
                            if (item.itemResponse.id === props.selectedArticles.itemResponse.id) {
                                quantityInCart1 = item.quantity
                            }
                        })
                    }

                    // brza kupovina
                    // if(props.alreadyHaveShoppingCart) {
                    //     if (props.selectedArticles.itemResponse.id === +props.quickBuy) {
                    //         addItemToShoppingCartHandler(props.selectedArticles)
                    //         localStorage.removeItem('quickBuy');
                    //     }
                    // }

                    searchedArticle = (
                        <Media
                            queries={{
                                small: "(max-width: 699px)",
                                large: "(min-width: 700px)"
                            }}>
                            {matches => (
                                <Fragment>
                                    {matches.small &&

                                    <SearchedArticleItemMobile articleItem={props.selectedArticles}
                                                 addItemToShoppingCart={() => addItemToShoppingCartHandler(props.selectedArticles)}
                                                 articlePicture={props.selectedArticles.itemResponse.pictures}
                                                 articlePrice={props.selectedArticles.itemResponse.price}
                                                 quantityInCart={quantityInCart1}
                                                 searchedArticle={true}
                                                 style={{width:"100%"}}
                                    />
                                    }

                                    {matches.large &&
                                    <SearchedArticleItem
                                        article={props.selectedArticles}
                                        articlePicture={props.selectedArticles.itemResponse.pictures !== null ? process.env.REACT_APP_CDN_PATH + props.selectedArticles.itemResponse.pictures : DefaultImage}
                                        articleTitle={props.selectedArticles.itemResponse.name}
                                        articlePrice={props.selectedArticles.itemPrice}
                                        articleId={props.selectedArticles.itemResponse.id}
                                        addToCart={() => addItemToShoppingCartHandler(props.selectedArticles)}
                                        quantityInCart={quantityInCart1}
                                        // onDetailsClick={}
                                    />
                                    }
                                </Fragment>
                            )}
                        </Media>
                    );
                    props.onSearchBarPlaceholderChanged(props.selectedArticles.itemResponse.name);
                } else {
                    searchedArticle = null
                }

                if (!props.loadingArticles) {
                    if (props.articlesFailed) {
                        //TODO ovde redirekt na stranu da nema lekova sa unesenim filterom
                        articleItems = <div className={styles.SearchResultErrorMessage}>Nijedan proizvod ne odgovara parametrima pretrage.</div>;
                    } else {
                        articleItems = props.articles !== null && props.articles.map((articleItem) => {
                            let quantityInCart = 0;
                            if (props.shoppingCart !== null) {
                                props.shoppingCart.items.map(item => {
                                    if(item.itemResponse.id === articleItem.itemResponse.id) {
                                        quantityInCart = item.quantity
                                    }

                                })
                            }

                            return (
                                <ArticleItem articleItem={articleItem}
                                             key={articleItem.itemResponse.id}
                                             addItemToShoppingCart={() => addItemToShoppingCartHandler(articleItem)}
                                             articlePicture={articleItem.pictures}
                                             articlePrice={articleItem.price}
                                             quantityInCart={quantityInCart}
                                />
                            );
                        });
                        if(articleItems) {
                            articleItems.unshift(searchedArticle);
                        }
                    }
                }

                // articleItems = (props.selectedArticles !== null && props.selectedArticles.length === undefined) ? [(
                //     <div key={props.selectedArticles.itemResponse.id} style={{marginBottom: "1rem"}}>
                //         <ArticleItem articleItem={props.selectedArticles}
                //                      addItemToShoppingCart={() => addItemToShoppingCartHandler(props.selectedArticles)}
                //
                //             //showStoreDetails={() => showSelectedStoreHandler(storeItem)}
                //             //selectedStore={props.selectedStore}
                //         />
                //     </div>)] : null
            }
        }
    } else {
        if (!props.loadingArticles) {
            if (props.articlesFailed) {
                //TODO ovde redirekt na stranu da nema lekova sa unesenim filterom
                articleItems = <div className={styles.SearchResultErrorMessage}>Nijedan proizvod ne odgovara parametrima pretrage.</div>;
            } else {
                articleItems = props.articles !== null && props.articles.map((articleItem, index) => {
                    indexFlag = index;

                    let quantityInCart = 0
                    if (props.shoppingCart !== null) {
                        props.shoppingCart.items.map(item => {
                            if (item.itemResponse.id === articleItem.itemResponse.id) {
                                quantityInCart = item.quantity
                            }
                        })
                    }

                    return (
                        <ArticleItem articleItem={articleItem}
                                     key={articleItem.itemResponse.id}
                                     addItemToShoppingCart={() => addItemToShoppingCartHandler(articleItem)}
                                     articlePicture={articleItem.pictures}
                                     articlePrice={articleItem.price}
                                     quantityInCart={quantityInCart}
                        />
                    );
                });
                // props.onSearchBarPlaceholderChanged('');
            }
        }
    }

    return (
        <React.Fragment>
            {!props.isLogedin && props.history.location.pathname.includes("/shop/") ?
                <Wrapper>
                    <div style={{textAlign: "center", paddingTop: "2rem"}} onClick={props.onBlurImageClick}>
                        <Media
                            queries={{
                                small: "(max-width: 699px)",
                                large: "(min-width: 700px)"
                            }}>
                            {matches => (
                                <Fragment>
                                    {matches.small &&
                                        <img src={bImageMobile} alt="" style={{width: "100vw"}}/>
                                    }

                                    {matches.large &&
                                        <img src={bImage} alt="" style={{width: "1302px"}}/>
                                    }
                                </Fragment>
                            )}
                        </Media>
                    </div>
                </Wrapper>
                :
                <>
                    <div className={styles.ResultsContainer}>

                        {/* div sluzi samo za align */}
                        <div style={{width: "17%"}}/>

                        <div className={styles.ArticleItemsWrapper}>
                            {/*<div className={styles.ShopSelectionMenu}>*/}

                                {/*TODO napraviti CSS za list view, do tada su buttons onemoguceni */}
                                {/*<div className={styles.ListOrGridToggleButtons}>*/}
                                {/*    <button //onClick={() => props.onGridListToggleView(true)}*/}
                                {/*            className={props.gridView ? [styles.ListOrGridToggleButton, styles.ToggleButtonSelected].join(' ') : styles.ListOrGridToggleButton}>*/}
                                {/*        <BsGrid style={{fontSize: "20px"}}/></button>*/}
                                {/*    <button //onClick={() => props.onGridListToggleView(false)}*/}
                                {/*            className={!props.gridView ? [styles.ListOrGridToggleButton, styles.ToggleButtonSelected].join(' ') : styles.ListOrGridToggleButton}>*/}
                                {/*        <ListIcon style={{fontSize: "20px"}}/></button>*/}
                                {/*</div>*/}

                            {/*</div>*/}
                            {/*<div className={styles.GridViewSoftEnter}>*/}
                            {/*{searchedArticle}*/}
                            <div style={{marginTop:"1rem", width:"100%", display: "flex", flexWrap:"wrap"}}>
                            {articleItems}
                            </div>
                            {/*</div>*/}
                        </div>

                    </div>
                    <Pagination totalElements={props.totalElements}
                                totalPages={props.totalPages}
                                matchParams={props.match.params}
                                onNextPageHandler={onNextPageHandler}
                    />
                </>
            }
        </React.Fragment>
    );
}


const mapStateToProps = (state) => {
    return {
        userInfo: state.authPage.userInfo,
        isLogedin: state.authPage.token !== null,
        shoppingCart: state.shopPage.shoppingCart,

        shopFilter: state.filter.shopFilter
    }
}

const mapDispatchToProps = (dispatch) => {
    return {

        onAddItemToShoppingCart: (userId, branchMarketplaceId, marketplaceId, item) => dispatch(actions.addItemToShoppingCart(userId, branchMarketplaceId, marketplaceId, item)),
        onSearchBarPlaceholderChanged: (newString) => dispatch(actions.searchBarPlaceholderChanged(newString))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ArticleItems));