import React from 'react';
import styles from "./HeaderWithTitleAndBackChevron.module.css";
import {ChevronLeftIcon} from "@heroicons/react/outline";

const HeaderWithTitleAndBackChevron = (props) => {
    return(
        <div className={styles.CartHeader} style={{position:"relative", justifyContent:"center", width:"100vw"}}>
            {props.icon && <ChevronLeftIcon className={styles.IconStyle} style={{position:"absolute", left:"0"}} onClick={props.onIconClick}/>}
            <p>{props.title}</p>
        </div>
    );
}

export default HeaderWithTitleAndBackChevron;


