import React from 'react';
import {connect} from "react-redux";
import styles from "./Section.module.css"


const Section = (props) => {

    return (
        <div className={styles.Section} style={{backgroundColor: props.color, margin: props.withMargin ? '64px' : 0}}>

            {!props.leftImage ?
                <>
                    {props.Content}
                    <img style={{height:"100%"}} src={props.imageSrc} alt=""/>
                </>
                :
                <>
                    <img style={{height:"100%"}} src={props.imageSrc} alt=""/>
                    {props.Content}
                </>
            }
            {props.children}
        </div>

    );
}

const mapStateToProps = (state) => {
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(Section);
