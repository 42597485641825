import React, {useEffect} from "react";
import styles from './ActionMenuRes.module.css';
import * as actions from "../../../store/actions";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";

const ActionMenuRes = (props) => {

    useEffect(() => {
        window.addEventListener('scroll', () => {
            if(window.scrollY > (props.scrollY ? props.scrollY : 342)) {
                props.onActionMenuStickyChanged(true)
            } else {
                props.onActionMenuStickyChanged(false);
            }
        });
    }, []);

    let actionMenuStyle = !props.actionMenuSticky ? styles.ActionMenuRes : [styles.ActionMenuRes, styles.PositionSticky].join(' ') ;

    return(
        <div className={actionMenuStyle}>
            {props.children}
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        actionMenuSticky: state.actionMenu.actionMenuSticky
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onActionMenuStickyChanged: (actionMenuSticky) => dispatch(actions.actionMenuStickyChanged(actionMenuSticky)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ActionMenuRes));