import React, {useEffect, useState} from 'react'
import PhoneNumberVerificationModal from "../../../../AuthPage/PhoneNumberVerification/PhoneNumberVerification";
import Backdrop from "../../../../UI/Backdrop/Backdrop";
import summaryAndConfirmationStyles from "../../4-SummaryAndConfirmation/SummaryAndConfirmation.module.css";
import styles from './EnterPhoneNumber.module.css';
import * as actions from "../../../../../store/actions";
import {connect} from "react-redux";
import Spinner from "../../../../UI/Spinner/Spinner";
import Button from "../../../../UI/Buttons/Button/Button";

const EnterPhoneNumber = (props) => {

    let deliveryMethod = localStorage.getItem('deliveryMethod') || '';
    const [phoneNumber, phoneNumberSetter] = useState(localStorage.getItem('phoneNumber') || props.userProfile && props.userProfile.phoneNumber)

    const [isPhoneNumberModalVisible, isPhoneNumberModalVisibleHandler] = useState(false);


    useEffect(() => {
        if (props.userProfile === null) {
            props.onFetchUserProfile(localStorage.getItem('email'));
        }

        if (props.collectOrDelivery.collect === null || props.collectOrDelivery.delivery === null) {
            if (deliveryMethod) {
                if (deliveryMethod === 'collectInPharmacy') {
                    props.onCollectOrDeliveryChoice({collect: true, delivery: false});
                } else if (deliveryMethod === 'deliveryToAddress') {
                    props.onCollectOrDeliveryChoice({collect: false, delivery: true});
                }
            }
        }
    }, [])

    useEffect(() => {
        if (props.userProfile) {
            phoneNumberSetter(localStorage.getItem('phoneNumber') || props.userProfile.phoneNumber)
        }
    }, [props.userProfile])


    const enterPhoneNumber = () => {
        isPhoneNumberModalVisibleHandler(true);
    }

    const closePhoneVerificationModal = () => {
        isPhoneNumberModalVisibleHandler(false);
    }

    const showPhoneNumber = (phoneNumber) => {
        phoneNumberSetter(phoneNumber);
    }

    return (
        <>
            <div className={styles.EnterPhoneNumberWrapper}>
                {/*{props.userProfile !== null && props.userProfile.phoneNumber !== null ?*/}
                {props.loadingUserProfile ? <Spinner/> :
                    phoneNumber !== null ?

                        <div style={{paddingTop: "1rem"}}>
                            <div style={{fontWeight: "700", paddingBottom: "1rem"}}>Vaš broj telefona</div>
                            <br/>
                            {/*<div>{props.userProfile.phoneNumber}</div>*/}
                            <div style={{display: "flex", alignItems: "center"}}>
                                {phoneNumber} &nbsp;&nbsp;
                                <Button buttontype="blue"
                                        onClick={() => isPhoneNumberModalVisibleHandler(true)}
                                >Koristi drugi broj telefona
                                </Button>
                            </div>
                        </div>
                        :
                        <>
                            <div>Broj telefona je neophodan za zaključenje rezervacije!</div>
                            <br/>
                            <div className={summaryAndConfirmationStyles.PulsingPhoneText}
                                 style={{color: "red", textDecoration: "underline", cursor: "pointer"}}
                                 onClick={enterPhoneNumber}>Unesite broj telefona
                            </div>
                        </>
                }
            </div>

            {isPhoneNumberModalVisible &&
                <PhoneNumberVerificationModal closePhoneVerificationModal={closePhoneVerificationModal}
                                              showPhoneNumber={(phoneNumber) => showPhoneNumber(phoneNumber)}
                                              orderPhoneNumber
                />
            }
            <Backdrop show={isPhoneNumberModalVisible}
                      backdropClickHandler={closePhoneVerificationModal}
                      zIndex={15}
                      backgroundColor="rgba(0, 0, 0, .2)"/>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        userProfile: state.userProfile.userProfile,
        userProfileError: state.userProfile.userProfileError,
        loadingUserProfile: state.userProfile.loadingUserProfile,

        userInfo: state.authPage.userInfo,

        collectOrDelivery: state.orderCompletion.collectOrDelivery,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onFetchUserProfile: (email) => dispatch(actions.fetchUserProfile(email)),
        onCollectOrDeliveryChoice: (collectOrDelivery) => dispatch(actions.collectOrDeliveryChoice(collectOrDelivery)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EnterPhoneNumber);