import * as actionsTypes from '../actions/actionsTypes'

const initialState = {
    collectOrDelivery: {
        collect: null,
        delivery: null
    },

    outOfDateItems: null,
    finalCheckShoppingCartFailed: false,
    loadingShoppingCartCheck: false,

    orderSocketConnected: null,
    orderSocketConnectionStart: false
}

const orderCompletionReducer = (state = initialState, action) => {
    switch (action.type) {

        case actionsTypes.COLLECT_OR_DELIVERY_CHOICE:
            return {
                ...state,
                collectOrDelivery: {...action.collectOrDelivery},
            }

        case actionsTypes.SHOPPING_CART_CHECKED:
            return {
                ...state,
                outOfDateItems: action.outOfDateItems,
                finalCheckShoppingCartFailed: false,
                loadingShoppingCartCheck: false
            }
        case actionsTypes.FINAL_CHECK_SHOPPING_CART_FAILED:
            return {
                ...state,
                outOfDateItems: null,
                finalCheckShoppingCartFailed: true,
                loadingShoppingCartCheck: false
            }
        case actionsTypes.FETCH_SHOPPING_CART_START:
            return {
                ...state,
                outOfDateItems: null,
                finalCheckShoppingCartFailed: false,
                loadingShoppingCartCheck: true
            }
        case actionsTypes.ORDER_SOCKET_CONNECTION_CHANGED:
            return {
                ...state,
                orderSocketConnected: action.orderSocketConnected,
                orderSocketConnectionStart: false
            }
        case actionsTypes.ORDER_SOCKET_CONNECTION_START:
            return {
                ...state,
                orderSocketConnected: null,
                orderSocketConnectionStart: true
            }
        default:
            return state;
    }
}

export default orderCompletionReducer;
