import React from 'react'
import styles from './MyProfilePaneContentHolder.module.css'
import {Route} from "react-router-dom";



const MyProfilePaneContentHolder = props => {

    return (

        <div className={styles.MyProfilePaneHolder}>
            <div className={styles.MyProfilePane}>
                {props.children}
            </div>
        </div>


    )

}

export default MyProfilePaneContentHolder