import React from 'react'
// import {HomeIcon} from "@heroicons/react/outline";
import Button from "../../UI/Buttons/Button/Button";
import ModalWithHeader from "../../UI/Modals/ModalWithHeader/ModalWithHeader";
import {withRouter} from 'react-router-dom';

const IsLoginModal = (props) => {

    const enterStoreProfilePage = () => {
        localStorage.setItem('beforeEnteringStorePage', window.location.pathname);
        props.history.push('/storeinfo/' + props.match.params.branchMarketplaceId + '/' + props.match.params.marketplaceId + '/' + props.match.params.branchMarketplaceName + '/' + props.match.params.marketplaceName);
    }

    return(
        <ModalWithHeader onCloseClick={props.onCloseClick}
                         // icon={<HomeIcon style={{width: "48px", height: "48px"}}/>}
                         show={props.show}
                         backdropClickHandler={props.backdropClickHandler}
                         buttons={
                             <>
                                 <Button onClick={props.cancel}>Odustani</Button>
                                 <Button buttontype="blue" onClick={props.notLoggedIn}>Prijavi se</Button>
                             </>
                         }
        >
            <p style={{margin: "0", fontSize: "20px", fontWeight: "bold", textAlign: "center"}}>Niste prijavljeni!</p>
            <p style={{textAlign: "center", fontSize: "14px", lineHeight: "1.7"}}>
                Opcija <strong>Poruči i preuzmi</strong> podrazumeva da <br/>
                <strong> korisnik mora biti prijavljen</strong>
            </p>
            {window.location.pathname.includes('shop') &&
                <div style={{marginTop:"1rem", display:"flex", flexDirection:"column", alignItems:"center", textAlign:"center", fontSize:"14px"}}>
                    <p>Ukoliko želiš da posetiš profilnu stranicu apoteke, klikni na dugme ispod</p>
                    <Button buttontype='blue' style={{padding:"1rem"}} onClick={enterStoreProfilePage}>Profilna stranica apoteke</Button>
                </div>
            }
        </ModalWithHeader>
    );
}

export default withRouter(IsLoginModal);