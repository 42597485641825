import React from 'react';
import styles from './Modal.module.css';
import {ReactComponent as ModalCloseIcon} from "../../../../assets/icons/closeModalIcon.svg";
import Backdrop from "../../Backdrop/Backdrop";

const Modal = (props) => {

    return (
        <>
            <div className={`${styles.Modal} ${props.className}`} style={props.style}>
                {props.modalHeader &&
                    <div className={styles.ModalHeader}
                         style={props.modalHeaderStyle}
                    >
                        <ModalCloseIcon style={{cursor: "pointer"}} onClick={props.onModalCloseIconClick}/>
                    </div>
                }
                <div className={styles.ModalContent} style={props.modalContentStyle}>
                    {props.children}
                </div>
            </div>

            {!props.noBackdrop &&
                <Backdrop show={props.show}
                          backdropClickHandler={props.backdropClickHandler}
                          zIndex={30}
                          backgroundColor="rgba(0, 0, 0, .2)"/>
            }
        </>
    )
};

export default Modal;