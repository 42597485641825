import React from "react";
import ModalWithHeader from "../ModalWithHeader/ModalWithHeader";
import {isAndroid, isIOS, isMobile} from "react-device-detect";
import AppButton from "../../Buttons/AppButton/AppButton";

const AppStoreModal = (props) => {

    return (
        <ModalWithHeader
            show={props.show}
            onCloseClick={props.onCloseClick}
            style={{zIndex: "100", width: "100vw"}}
        >
            <div style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-evenly",
                height: "100%",
                alignItems: "center"
            }}>
                <p>Preuzmite aplikaciju za svoj mobilni uređaj!</p>
                {isAndroid && <AppButton style={{width:"50vw"}}/>}
                {isMobile && isIOS && <AppButton apple style={{width:"50vw"}}/>}
            </div>

        </ModalWithHeader>
    );
}

export default AppStoreModal;