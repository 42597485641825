import React, {useRef, useEffect} from 'react';
import styles from './SearchItem.module.css';
import {connect} from 'react-redux';
import * as actions from "../../../../../store/actions";
import { withRouter} from 'react-router-dom'
import {textToUrl} from "../../../../GobalFunctions/GlobalFunctions";

const SearchItem = React.memo(props => {

    const fieldRef = useRef(null);


    // Detektuje prmenu u selekciji (key up i key down) i pomera scroll ako se taj item ne vidi
    useEffect(() => {

        if (props.selectedIndex === 0 && props.index === 1) {
            fieldRef.current.scrollIntoView({
                behavior: "auto",
                block: 'nearest',
                inline: 'start'
            });
        }

        if (props.selectedIndex === props.index ) {
            fieldRef.current.scrollIntoView({
                behavior: "smooth",
                block: 'nearest',
                inline: 'start'
            });
        }
    }, [props.selectedIndex])

    useEffect(() => {

        //Check if we select article
        if (props.isSelected && props.selectedIndex === props.index){
            let url;
            // Check weather search is triggered from MasterArtikalCMS component.
            //If it is than we should redirect again to master-artikal-cms page.
            if (props.history.location.pathname.includes("/cms/master-artikal-cms/")){
                url = '/cms/master-artikal-cms/' + props.article.id;
            }
            else {
                if (props.history.location.pathname.includes("/shop/")){
                    url = '/shop/' + props.match.params.branchMarketplaceId + '/' + props.match.params.marketplaceId + '/0/' + props.article.id + '/' + props.match.params.branchMarketplaceName + '/' + props.match.params.marketplaceName;
                    //Izmena da na enter u pretrazi u shopu ulazi direktno u infolek tog artikla
                    // url = '/articles-info/' + props.article.jkl + "/" + props.article.id + "/" + props.match.params.branchMarketplaceId + '/' + props.match.params.marketplaceId;
                }
                else {
                    if (props.location.hash !== '') {
                        url = '/articles-and-stores/' + props.article.id + '/0#' + props.location.hash.substring(1);
                    }
                    else {
                        url = '/articles-and-stores/' + props.article.id + '/0' + '/' + textToUrl(props.article.name);
                    }

                }

            }

            localStorage.setItem('searchRoute', url);
            props.history.push(url)
            props.onSelectDetected(false)
        }
    }, [props.isSelected])

    const selectArticleHandler = () => {
        let url1;

        if (props.history.location.pathname.includes("/cms/master-artikal-cms/")){
            url1 = '/cms/master-artikal-cms/' + props.article.id;
        }
        else {
            if (props.history.location.pathname.includes("/shop/")){
                url1 = '/shop/' + props.match.params.branchMarketplaceId + '/' + props.match.params.marketplaceId + '/0/' + props.article.id + '/' + props.match.params.branchMarketplaceName + '/' + props.match.params.marketplaceName;
                //izmena da klikom na nekih artikal u pretrazi u shopu ulazi direktno na infolek
                // url1 = '/articles-info/' + props.article.jkl + "/" + props.article.id + "/" + props.match.params.branchMarketplaceId + '/' + props.match.params.marketplaceId;

            }
            else {
                if (props.location.hash !== '') {
                    url1 = '/articles-and-stores/' + props.article.id + '/0#' + props.location.hash.substring(1);
                }
                else {
                    url1 = '/articles-and-stores/' + props.article.id + '/0' + '/' + textToUrl(props.article.name);
                }
            }

        }
        props.history.push(url1);

    }

    // const articleInfoHandler = () => {
    //     let url = null;
    //     if (!window.location.pathname.includes('searched-articles')) {
    //         const branchMarketplaceId = props.location.pathname.split('/')[2];
    //         const marketplaceId = props.location.pathname.split('/')[3];
    //         url = '/articles-info/' + props.articleItem.itemResponse.jkl + "/" + props.articleItem.itemResponse.id + '/' + branchMarketplaceId + '/' + marketplaceId;
    //         if (props.selectedStore !== null) {
    //             props.onStoreDetailsForInfoPage(props.selectedStore.marketplaceResponse);
    //         }
    //     } else {
    //         url = '/articles-info/' + props.articleItem.itemResponse.jkl + "/" + props.articleItem.itemResponse.id;
    //     }
    //     localStorage.setItem('beforeEnteringInfoLek', window.location.pathname);
    //     props.onArticleInStore(props.articleItem);
    //     props.history.push(url);
    // }

    const addItemToShoppingCart = (article) => {
        props.onAddItemToShoppingCart(props.userInfo.id, props.match.params.branchMarketplaceId, props.match.params.marketplaceId, article.id);
    }

    return (
        <div className={(props.onMainPage && props.article.name.length>56) ?
            (props.selectedIndex === props.index) ?
                [styles.SearchItemMainPage, styles.ScrollLongArticleName].join(' ')
                :
                [styles.SearchItemMainPage, styles.ScrollLongArticleNameHover].join(' ')
            :
            [styles.SearchItemMainPage, styles.SearchItemAppPage, styles.ShortArticleName].join(' ')}>
            <a className={props.selectedIndex === props.index ? styles.Selected : null} onMouseDown={selectArticleHandler} ref={fieldRef}>
                <span><div dangerouslySetInnerHTML={{__html: props.article.nameHighlighted /*+ ", " + props.article.manufacturerName*/ }}></div></span>
            </a>
        </div>
    );
})

const mapStateToProps = (state) => {
    return {
        selectedIndex: state.mainPage.selectedIndex,
        isSelected: state.mainPage.isSelected,

        userInfo: state.authPage.userInfo,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onSelectDetected: (isSelected) => dispatch(actions.selectDetected(isSelected)),
        onAddItemToShoppingCart: (userId, branchMarketplaceId, marketplaceId, itemId) => dispatch(actions.addItemToShoppingCartUsingItemId(userId, branchMarketplaceId, marketplaceId, itemId)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SearchItem));
