import React from 'react';
import MyLoyaltyPoints from "./MyLoyaltyPoints/MyLoyaltyPoints";
import LoyaltyCatalogue from "./LoyaltyCatalogue/LoyaltyCatalogue";

const MyLoyalty = () => {
    return (
        <>
            <MyLoyaltyPoints/>
            <LoyaltyCatalogue/>
        </>
    );
};

export default MyLoyalty;
