import React, {Fragment, useEffect, useState} from 'react';
import * as actions from "../../../store/actions";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import SingleTherapy from "./SingleTherapy/SingleTherapy";
import styles from './MyTherapies.module.css';
import Button from "../../UI/Buttons/Button/Button";
import TherapyArticles from "./TherapyArticles/TherapyArticles";
import NewTherapy from "./NewTherapy/NewTherapy";
import CNCModal from "../../SearchResultsPage/CNCModal/CNCModal";
import NoAddressModal from "../../SearchResultsPage/NoAddressModal/NoAddressModal";
import Media from "react-media";
import TherapyArticlesMobile from "./TherapyArticles/TherapyArticlesMobile";
import PerfectScrollbar from "react-perfect-scrollbar";
import HeaderWithTitleAndBackChevron
    from "../../../componentsRes/HeaderWithTitleAndBackChevron/HeaderWithTitleAndBackChevron";

const MyTherapies = (props) => {

    const [therapySelectedData, therapySelectedDataSetter] = useState({
        data: '',
        index: ''
    })

    const [newTherapy, newTherapySetter] = useState(false);
    const [isNoAddressModalVisible, isNoAddressModalVisibleHandler] = useState(false)

    const [therapies, setTherapies] = useState([])
    const [articlesInTherapy, setArticlesInTherapy] = useState(null);
    const [editTherapyState, setEditTherapyState] = useState({
        therapy: '',
        index: ''
    });
    const [availableOrderButton, setAvailableOrderButton] = useState(false)

    useEffect(() => {
        // todo srediti dodavanje terapije i artikla u redux pa vratiti uslov
        // if (props.userTherapies === null) {
            props.onFetchUserTherapies(localStorage.getItem('id'))
        // }
    }, [])

    const therapySelected = (data, index) => {
        therapySelectedDataSetter({data: data, index: index})
    }

    const therapyDeleted =()=> {
        therapySelectedDataSetter({data: '', index: ''})
        setArticlesInTherapy(null)
    }

    const therapyDeselect = () => {
        therapySelectedDataSetter({data: '', index: ''})
    }

    const startNewTherapy = () => {
        setEditTherapyState({
            therapy: '',
            index:''
        })
        newTherapySetter(true);
        props.onRemoveAllArticlesFromTherapy();
    }

    const editTherapy = (therapy, index) => {
        props.onRemoveAllArticlesFromTherapy();
        setEditTherapyState({therapy: therapy, index: index});
        newTherapySetter(true);
    }

    const cancelNewTherapy = () => {
        newTherapySetter(false)
    }

    const noAddressModal = () => {
        isNoAddressModalVisibleHandler(true);
    }

    const closeNoAddressModal = () => {
        isNoAddressModalVisibleHandler(false);
    }

    const openCNCModal = () => {
        if(localStorage.getItem('enteredAddress')) {
            props.onCNCModalToggle(true);
        } else {
            noAddressModal();
        }
    }

    const openEnterAddressModal =() => {
        isNoAddressModalVisibleHandler(false);
        props.onChooseLocationModalToggle(true);
    }

    useEffect(() => {
        if (therapies) {
            setTherapies([])
        }
        props.userTherapies && props.userTherapies.map((therapy, index) => {
            setTherapies(current =>
                [
                    ...current,
                    <SingleTherapy
                        key={index}
                        style={therapySelectedData.index === index ? {border: "1px solid #00B2B1"} : null}
                        therapy={therapy}
                        index={index}
                        therapySelectedCallback={() => therapySelected(therapy, index)}
                        removeArticles={()=> therapyDeleted()}
                        editTherapy = {()=> editTherapy(therapy, index)}/>
                ])
        })

    }, [props.userTherapies, therapySelectedData])

    useEffect(() => {
        if(therapySelectedData.data.status === 'USED' || therapySelectedData.data.status === 'EXPIRED') {
            setAvailableOrderButton(false)
        } else {
            setAvailableOrderButton(true)
        }
        setArticlesInTherapy(
            <TherapyArticles therapy={therapySelectedData.data}/>
        )
    }, [therapySelectedData])

    return (

            <Media queries={{
                small: "(max-width: 699px)",
                large: "(min-width: 700px)"
            }}>
                {matches => (
                    <Fragment>
                        {matches.small &&
                            (newTherapy ?
                                <div className={styles.NewTherapyMobile}>
                                    <HeaderWithTitleAndBackChevron icon title="Nova terapija" onIconClick={cancelNewTherapy}/>
                                    <NewTherapy onCancel={cancelNewTherapy} editTherapy={editTherapyState} therapyDeselect={therapyDeselect}/>
                                </div>
                                :
                                    <div className={styles.MyTherapiesWrapper}>
                                        <div className={styles.TherapyHeader}>
                                            {therapySelectedData.data === '' &&
                                            <div className={styles.TherapyHeaderLeftHalf}>
                                                <span>Moje terapije</span>
                                                <Button buttontype="blue"
                                                        style={{width: "200px", justifyContent: "center"}}
                                                        onClick={startNewTherapy}>
                                                    Nova terapija</Button>
                                            </div>
                                            // {therapySelectedData.data !== '' &&
                                            // :
                                            //     <div className={styles.TherapyHeaderRightSide}>
                                            //         <span>{therapySelectedData.data.name}</span>
                                            //         {/*<span>Terapija {therapySelectedData.data.authorizedMedic.firstName} {therapySelectedData.data.authorizedMedic.secondName}</span>*/}
                                            //         <Button buttontype="blue"
                                            //                 style={{width: "200px", justifyContent: "center"}}
                                            //                 onClick={openCNCModal}
                                            //         >Naruči dsdsd</Button>
                                            //     </div>
                                            }
                                        </div>

                                        <div style={{position: "relative"}}>
                                            {props.userTherapies && props.userTherapies.map((therapy, index) => {
                                                return (
                                                    <div key={index}>
                                                        <div className={styles.SingleTherapyWrapper}>
                                                            <SingleTherapy
                                                                style={therapySelectedData.index === index ? {border: "1px solid #00B2B1"} : null}
                                                                therapy={therapy}
                                                                index={index}
                                                                therapySelectedCallback={() => therapySelected(therapy, index)}
                                                                removeArticles={()=> therapyDeleted()}
                                                                editTherapy = {()=> editTherapy(therapy, index)}/>
                                                        </div>
                                                        {therapySelectedData.index === index &&
                                                            <div style={{position: "relative"}}>
                                                                <TherapyArticlesMobile therapy={therapy}
                                                                                       therapyDeselectedCallback={() => therapyDeselect(therapy, index)}/>
                                                                <div style={{
                                                                    width: "100%",
                                                                    position: "fixed",
                                                                    bottom: "0",
                                                                    left:"0",
                                                                    height:"5rem",
                                                                    backgroundColor:"white",
                                                                    // borderTop
                                                                }}>
                                                                    {availableOrderButton ?
                                                                    <Button buttontype="blue"
                                                                            style={{
                                                                                width: "200px",
                                                                                justifyContent: "center",
                                                                                position: "fixed",
                                                                                bottom: "1rem",
                                                                                left: "50%",
                                                                                transform: "translateX(-50%)"
                                                                            }}
                                                                            onClick={openCNCModal}
                                                                    >Naruči</Button>
                                                                        :
                                                                        (therapySelectedData.data.status === 'USED' ?
                                                                                <div  style={{
                                                                                    width: "100vw",
                                                                                    textAlign: "center",
                                                                                    position: "fixed",
                                                                                    left: "50%",
                                                                                    transform: "translateX(-50%)",
                                                                                    margin: 0
                                                                                }}>Terapija je iskorišćena</div> :
                                                                                therapySelectedData.data.status === 'EXPIRED' &&
                                                                                <div  style={{
                                                                                    width: "100vw",
                                                                                    textAlign: "center",
                                                                                    position: "fixed",
                                                                                    left: "50%",
                                                                                    transform: "translateX(-50%)",
                                                                                    margin: 0
                                                                                }}>Terapija je istekla</div>
                                                                        )
                                                                    }
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                            )
                        }
                        {matches.large &&
                            (newTherapy ?
                                    <NewTherapy onCancel={cancelNewTherapy} editTherapy={editTherapyState} therapyDeselect={therapyDeselect}/> :
                                    <div className={styles.MyTherapiesWrapper}>
                                        <div className={styles.TherapyHeader}>
                                            <div className={styles.TherapyHeaderLeftHalf}>
                                                <span>Moje terapije</span>
                                                <Button buttontype="blue"
                                                        style={{width: "200px", justifyContent: "center"}}
                                                        onClick={startNewTherapy}>
                                                    Nova terapija</Button>
                                            </div>
                                            {therapySelectedData.data !== '' &&
                                                <div className={styles.TherapyHeaderRightSide}>
                                                    <span>{therapySelectedData.data.name}</span>
                                                    {/*<span>Terapija {therapySelectedData.data.authorizedMedic.firstName} {therapySelectedData.data.authorizedMedic.secondName}</span>*/}
                                                    {availableOrderButton ?
                                                        <Button buttontype="blue"
                                                                style={{width: "200px", justifyContent: "center"}}
                                                                onClick={openCNCModal}
                                                        >Naruči</Button>
                                                        :
                                                        (therapySelectedData.data.status === 'USED' ?
                                                                <div>Terapija je iskorišćena</div> :
                                                                therapySelectedData.data.status === 'EXPIRED' &&
                                                                <div>Terapija je istekla</div>
                                                        )
                                                    }
                                                </div>
                                            }
                                        </div>

                                        <div style={{position: "relative", display: "flex"}}>
                                            <div className={styles.MyTherapiesWrapperScroll}>
                                                <PerfectScrollbar>
                                                    {therapies}
                                                </PerfectScrollbar>
                                            </div>
                                            {articlesInTherapy}
                                        </div>
                                    </div>
                            )
                        }
                        {props.cncModalToggle &&
                            <CNCModal myTherapyModal therapy={therapySelectedData.data}/>
                        }

                        {isNoAddressModalVisible &&
                            <NoAddressModal onCloseClick={closeNoAddressModal}
                                            show={isNoAddressModalVisible}
                                            backdropClickHandler={closeNoAddressModal}
                                            cancel={closeNoAddressModal}
                                            openEnterAddressModal={openEnterAddressModal}
                            />
                        }

                    </Fragment>
                )}
            </Media>

    );
};

const mapStateToProps = (state) => {
    return {
        userInfo: state.authPage.userInfo,
        isLogedin: state.authPage.token !== null,
        userTherapies: state.userProfile.userTherapies,
        storesContainingUserTherapy: state.userProfile.storesContainingUserTherapy,
        cncModalToggle: state.modal.cncModalToggle,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onFetchUserTherapies: (userId) => dispatch(actions.fetchUserTherapies(userId)),
        onCNCModalToggle: (cncModalToggle) => dispatch(actions.CNCModalToggle(cncModalToggle)),
        onChooseLocationModalToggle: (chooseLocationModalToggle) => dispatch(actions.chooseLocationModalToggle(chooseLocationModalToggle)),
        onRemoveAllArticlesFromTherapy: () => dispatch(actions.removeAllArticlesFromTherapy())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MyTherapies));