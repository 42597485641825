import React, {useState} from 'react';
import styles from './ResetPasswordPane.module.css'
import {withRouter} from "react-router-dom";
import axios from 'axios';
import {baseUrl} from "../../../axios/axios-apoteka";
import {Textbox} from 'react-inputs-validation';
import Toolbar from '../../Navigation/Toolbar/Toolbar';
import Button from "../../UI/Buttons/Button/Button"


const ResetPasswordPane = (props) => {

    const [inputState, inputStateChanged] = useState({
        inputPasswords: {
            password: "",
            repeatedPassword: ""
        },
        error: false
    })

    const inputChangedHandler = (event, inputIndentifier) => {
        const updatedInputForm = {
            ...inputState.inputPasswords
        }
        updatedInputForm[inputIndentifier] = event.target.value;
        inputStateChanged({
            inputPasswords: {...updatedInputForm}
        })
    }

    const resetPasswordHandler = (event) => {
        event.preventDefault();
        let updatedObject = {
            ...inputState.inputPasswords
        };

        if (updatedObject.password !== updatedObject.repeatedPassword) {
            inputStateChanged({
                ...inputState,
                error: true
            })
            return;
        }
        const toSend = {
            email: props.match.params.email,
            password: updatedObject.password
        }
        const URL = baseUrl + '/authorization/reset_forgotten_password';
        axios.put(URL, toSend, {params: {access_token: props.match.params.token}})
            .then(response => {
                redirectToLogin();

            })
            .catch(error => {
                console.log("Error se desio" + error);

            })
        console.log(props.match.params);

    }

    const doesPasswordMatch = () => {
        return inputState.inputPasswords.password === inputState.inputPasswords.repeatedPassword;
    }


    const redirectToLogin = () => {
        const url = '/auth/login';
        props.history.replace(url);
    }


    return (
        <>
        <Toolbar />
        <div className={styles.ResetPasswordPane}>
            <form action="">
                <div className={styles.ResetPasswordPaneHolder}>
                    <div className={styles.Input}>
                        <h2>Promena lozinke</h2>
                        {/*<Input inputtype="input" type="password" name="password" placeholder={"Novi password"}*/}
                        {/*       value={inputState.inputPasswords.password} onChange={(event) => inputChangedHandler(event, "password")}/>*/}
                        <Textbox
                            attributesInput={{ // Optional.
                                id: 'password',
                                name: 'password',
                                type: 'password',
                                placeholder: 'Nova Lozinka',
                            }}
                            value={inputState.inputPasswords.password} // Optional.[String].Default: "".
                            onChange={(value, event) => inputChangedHandler(event, "password")} // Required.[Func].Default: () => {}. Will return the value.
                            onBlur={(e) => {
                                console.log(e)
                            }} // Optional.[Func].Default: none. In order to validate the value on blur, you MUST provide a function, even if it is an empty function. Missing this, the validation on blur will not work.
                            validationOption={{
                                name: 'password', // Optional.[String].Default: "". To display in the Error message. i.e Please enter your {name}.
                                check: true, // Optional.[Bool].Default: true. To determin if you need to validate.
                                required: true, // Optional.[Bool].Default: true. To determin if it is a required field.
                                msgOnError: "Polje Lozinka ne moze da bude prazno",
                                customFunc: (password) => {
                                    const reg = /^(?=.*[a-zљњертжуиопшђасдфгхјклчћзџцвбнмšđžčć])(?=.*[A-ZЉЊЕРТЖУИОПШЂАСДФГХЈКЛЧЋЗЏЦВБНМŠĐŽČĆ])(?=.*\d)[a-zA-ZљњертжуиопшђасдфгхјклчћзџцвбнмšđžčćЉЊЕРТЖУИОПШЂАСДФГХЈКЛЧЋЗЏЦВБНМŠĐŽČĆ\d\w\W]{8,}$/;
                                    if (reg.test(String(password))) {
                                        return true;
                                    } else {
                                        return "Lozinka mora da sadrzi najmanje 8 karaktera, po jedno veliko i malo slovo i jedan broj";
                                    }
                                }
                            }}
                        />
                    </div>
                    <div className={styles.Input}>
                        {/*<p>Ponovi novi password:</p>*/}
                        {/*<Input inputtype="input" type="password" name="repeatedPassword" placeholder={"Ponovi password"}*/}
                        {/*       value={inputState.inputPasswords.repeatedPassword}*/}
                        {/*       onChange={(event) => inputChangedHandler(event, "repeatedPassword")}/>*/}
                        <Textbox
                            attributesInput={{ // Optional.
                                id: 'password',
                                name: 'repeatedPassword',
                                type: 'password',
                                placeholder: 'Ponovite lozinku',
                            }}
                            value={inputState.inputPasswords.repeatedPassword} // Optional.[String].Default: "".
                            onChange={(value, event) => inputChangedHandler(event, "repeatedPassword")} // Required.[Func].Default: () => {}. Will return the value.
                            onBlur={(e) => {
                                console.log(e)
                            }} // Optional.[Func].Default: none. In order to validate the value on blur, you MUST provide a function, even if it is an empty function. Missing this, the validation on blur will not work.
                            validationOption={{
                                name: 'password', // Optional.[String].Default: "". To display in the Error message. i.e Please enter your {name}.
                                check: true, // Optional.[Bool].Default: true. To determin if you need to validate.
                                required: true, // Optional.[Bool].Default: true. To determin if it is a required field.
                                msgOnError: "Polje Lozinka ne moze da bude prazno",
                                customFunc: (password) => {
                                    const reg = /^(?=.*[a-zљњертжуиопшђасдфгхјклчћзџцвбнмšđžčć])(?=.*[A-ZЉЊЕРТЖУИОПШЂАСДФГХЈКЛЧЋЗЏЦВБНМŠĐŽČĆ])(?=.*\d)[a-zA-ZљњертжуиопшђасдфгхјклчћзџцвбнмšđžčćЉЊЕРТЖУИОПШЂАСДФГХЈКЛЧЋЗЏЦВБНМŠĐŽČĆ\d\w\W]{8,}$/;
                                    if (!doesPasswordMatch()) {
                                        return "Unete lozinke se ne podudaraju";
                                    }
                                    if (reg.test(String(password))) {
                                        return true;
                                    } else {
                                        return "Lozinka mora da sadrzi najmanje 8 karaktera, po jedno veliko i malo slovo i jedan broj";
                                    }
                                }
                            }}
                        />
                    </div>
                    <br/>
                    <div className={styles.ResetPasswordButtonHolder}>
                        <Button buttontype='blue' onClick={resetPasswordHandler}>Promeni lozinku</Button>
                    </div>
                </div>
            </form>
        </div>
        </>
    );
}


export default withRouter(ResetPasswordPane);
