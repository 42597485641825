import React, {useState} from 'react';
import ModalWithHeader from "../ModalWithHeader/ModalWithHeader";
import {connect} from "react-redux";
import LBOInputField from "../../../MyProfilePage/MyProfilePane/LBOInputField/LBOInputField";
import Button from "../../Buttons/Button/Button";
import * as actions from "../../../../store/actions";

const LBOModal = (props) => {

    const [lbo, setLbo] = useState({
        lbo: null,
        saveToProfile: null
    })

    const passLBONumber = (lbo) => {
        setLbo(lbo)
    }

    const saveToProfile = () => {
        if(props.userProfile !== null) {
            const updatedUserProfile = {
                ...props.userProfile,
                lbo: lbo.lbo,
            }
            props.onUpdateUserProfile(updatedUserProfile);
            props.onButtonClick();
        }
    }

    return (
        <ModalWithHeader onCloseClick={props.onCloseClick}
                         style={{paddingBottom: "1rem"}}
                         show={props.show}
                         backdropClickHandler={props.onCloseClick}
                         buttons={
                                <Button buttontype='blue' style={{margin:"auto"}} onClick={saveToProfile}>Sačuvaj</Button>
                         }
        >

            <LBOInputField  passLBONumber={(lbo) => passLBONumber(lbo)}
                            onProfilePage={props.onProfilePage}
            />
        </ModalWithHeader>
    );
};

const mapStateToProps = (state) => {
    return {
        userProfile: state.userProfile.userProfile,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onUpdateUserProfile: (userProfile) => dispatch(actions.updateUserProfile(userProfile))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LBOModal);