import React, {Fragment, useEffect, useState} from 'react'
import styles from './Order.module.css'
import OrderStatus from "../../../UI/OrderStatus/OrderStatus";
import {ClockIcon, StarIcon, ThumbUpIcon, XCircleIcon, CheckCircleIcon, MinusCircleIcon} from "@heroicons/react/solid";
import SlideDown from "react-slidedown";
import 'react-slidedown/lib/slidedown.css'
import {ChevronDownIcon, ChevronUpIcon} from "@heroicons/react/outline";
import Media from "react-media";
import RateTransactionModal from "../../../UI/Modals/RateTransactionModal/RateTransactionModal";
import Button from "../../../UI/Buttons/Button/Button";
import * as actions from "../../../../store/actions";
import {connect} from "react-redux";
import SquareBadge from "../../../UI/SquareBadge/SquareBadge";
import {ReactComponent as PinIcon} from "../../../../assets/icons/pin_icon.svg";

const Order = (props) => {

    const [boxExpanded, boxExpandedToggle] = useState(false);
    const toggleCollapse = () => {
        boxExpandedToggle(!boxExpanded)
    }
    const [showRatingModal, showRatingModalSetter] = useState(false)
    const [ratingContainer, ratingContainerSetter] = useState()

    const rateTransaction = () => {
        showRatingModalSetter(true)
    }

    const saveRating = (rateValue) => {
        showRatingModalSetter(false);
        const rating = {
            ratingId: props.order.rating.id,
            rating: rateValue,
            ratingComment: ''
        }
        props.onRateTransaction(rating)
    }

    useEffect(() => {
        ratingContainerSetter(props.rateTransaction && props.order.rating !== null && (
            props.order.rating.rating === null
                ? <Button buttontype='blue' style={{
                    height: "32px",
                    fontSize: "14px",
                    padding: "8px",
                    margin: "8px 0"
                }} onClick={rateTransaction}>
                    <StarIcon style={{width: "1em", color: "white"}}/><span style={{padding: "4px"}}>Oceni apoteku</span>
                </Button>
                : <span className={styles.OrderRatingBadge}>
                                                <SquareBadge backgroundColor="#013A63">
                                                    <span style={{
                                                        color: "white",
                                                        fontWeight: "bold"
                                                    }}>{props.order.rating.rating}</span>
                                                </SquareBadge>&nbsp;Tvoja ocena</span>
        ))
    }, [props.allTransactions]);

    const redirectToGoogleMap = (latitude, longitude) => {
        window.open("http://maps.google.com/maps?z=18&t=m&q=loc:" + latitude + "++" + longitude, "_blank");
    }

    const items = props.order.items.map((item) => {
        const itemPrice = (item.priceWithDiscount && item.priceWithDiscount > 0) ?
            item.priceWithDiscount : item.price

        return (
            <Media
                queries={{
                    small: "(max-width: 699px)",
                    large: "(min-width: 700px)"
                }}>
                {matches => (
                    <Fragment>
                        {matches.small &&
                            <div style={{borderBottom:"1px solid #e1e5e9ff"}}>
                                <tr>
                                    <td style={{textAlign: "center"}}>{item.quantity}x</td>
                                    <td>{item.itemResponse.name}</td>
                                    <td style={{textAlign: "center", padding:"0"}}>{(itemPrice).toLocaleString("de-DE", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    })} RSD
                                    </td>
                                </tr>
                            </div>

                        }

                        {matches.large &&
                            <tr>
                                <td>{item.itemResponse.name}</td>
                                <td style={{textAlign: "center"}}>{item.quantity}</td>
                                <td style={{textAlign: "center"}}>{(itemPrice).toLocaleString("de-DE", {minimumFractionDigits:2, maximumFractionDigits:2})} RSD</td>
                            </tr>
                        }
                    </Fragment>
                )}
            </Media>
        )
    })

    return (
        <Media
            queries={{
                small: "(max-width: 699px)",
                large: "(min-width: 700px)"
            }}>
            {matches => (
                <Fragment>
                    {matches.small &&
                        <div key={props.order.id} className={styles.Order}>
                            <div style={{borderBottom:"1px solid #E2E6EA", display:"flex", justifyContent:"space-between", padding:"0.5rem"}}>
                                <div className={styles.OrderInfoPartSection}>
                                    <div className={styles.OrderInfoPartSectionTitle} style={{fontWeight:"bold"}}>
                                        {props.order.marketplaceResponse.name}
                                    </div>
                                    <div className={styles.OrderInfoPartSectionAddress}
                                         onClick={() => redirectToGoogleMap(props.order.marketplaceResponse.latitude, props.order.marketplaceResponse.longitude)}>
                                        <PinIcon style={{stroke:"inherit", width:"14px", height:"14px"}}/>
                                        {props.order.marketplaceResponse.address}
                                    </div>
                                    {ratingContainer}
                                </div>
                                <div className={styles.FeedbackPart}>

                                    {props.order.status === 'CREATED' &&
                                        <OrderStatus
                                            icon={<ClockIcon
                                                style={{width: "24px", height: "24px", fill: "#f58300"}}/>}
                                            status={'NA ČEKANJU'}
                                            statusStyle={{color: "#f58300"}}
                                            message={"Zahtev se obrađuje"}
                                        />
                                    }

                                    {props.order.status === 'PROCESSED' &&
                                        <OrderStatus
                                            icon={<ThumbUpIcon
                                                style={{width: "36px", height: "36px", fill: "#ffae00"}}/>}
                                            status={'U PRIPREMI'}
                                            statusStyle={{color: "#ffae00"}}
                                            message={props.order.willBeReadyIn ? "Preuzimanje od " + props.order.willBeReadyIn : "Možete preuzeti odmah"}
                                        />
                                    }

                                    {props.order.status === 'PREPARED' &&
                                        <OrderStatus style={{color: "#00b2b1"}}
                                                     status={'SPAKOVANO'}
                                                     icon={<ThumbUpIcon style={{width: "36px", height: "36px"}}/>}
                                                     message={"Vaša porudžbina je spakovana."}
                                        />
                                    }

                                    {props.order.status === 'TAKEN' &&
                                        <OrderStatus style={{color: "#013a63"}}
                                                     status={'PREUZETA'}
                                                     icon={<CheckCircleIcon style={{width: "36px", height: "36px"}}/>}
                                                     message={"Vaša porudžbina je preuzeta."}
                                        />
                                    }

                                    {props.order.status === 'REJECTED' &&
                                        <OrderStatus style={{color: "#c03950"}}
                                                     status={'ODBIJENO'}
                                                     icon={<XCircleIcon style={{width: "36px", height: "36px"}}/>}
                                                     message={"Vaša porudžbina nije prihvaćena."}
                                        />
                                    }

                                    {props.order.status === 'CANCELED' &&
                                        <OrderStatus style={{color: "#8b8c8f"}}
                                                     status={'OTKAZANA'}
                                                     icon={<MinusCircleIcon style={{width: "36px", height: "36px"}}/>}
                                                     message={"Vaša porudžbina je otkazana."}
                                        />
                                    }

                                </div>
                            </div>
                            <div style={{borderBottom:"1px solid #E2E6EA", display:"flex", justifyContent:"space-between", padding:"0.5rem", fontSize:"14px"}}>
                                <div className={styles.OrderInfoPartSection}>
                                    <div className={styles.OrderInfoPartSectionTitle}>Broj porudžbine</div>
                                    <div style={{fontWeight: "bold", color: "#259cbaff"}}>
                                        #{props.order.orderNumber === undefined ? props.order.id : props.order.orderNumber}
                                    </div>
                                </div>
                                <div className={styles.OrderInfoPartSection}>
                                    <div className={styles.OrderInfoPartSectionTitle}>Datum</div>
                                    <div>
                                        {props.day}.{props.month}.{props.year}.
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className={styles.OrderTotalMobile}>
                                    <p>Ukupna cena</p>
                                    <p className={styles.TotalAmount}>{(props.order.totalPrice)} RSD</p>
                                </div>
                            </div>
                            <SlideDown className={styles.MyDropdownSlidedown}
                                       transitionOnAppear={props.transaction}
                                       style={{width:"100%"}}
                            >
                                {boxExpanded ?
                                    <div className={styles.OrderList} onClick={toggleCollapse}>
                                        {(props.order.noteForMarketplace || props.order.noteForUser || props.order.noteForCanceledStatus) &&
                                            <div className={styles.NoteForUser}>
                                                {props.noteForMarketplace &&
                                                    <div>
                                                        <span style={{color: "#4d5055ff", fontWeight: "600"}}>Tvoja napomena:</span>
                                                        <p>{props.order.noteForMarketplace}</p>
                                                    </div>
                                                }
                                                {props.order.noteForUser && props.order.noteForUser.length > 0 &&
                                                    <div>
                                                        <span style={{color: "#4d5055ff", fontWeight: "600"}}>Napomena apotekara:</span>
                                                        <p>{props.order.noteForUser}</p>
                                                    </div>
                                                }
                                                {props.order.noteForCanceledStatus &&
                                                    <div>
                                                        <span style={{color: "#4d5055ff", fontWeight: "600"}}>Napomena apotekara prilikom odbacivanja porudzbine:</span>
                                                        <p>{props.order.noteForCanceledStatus}</p>
                                                    </div>
                                                }
                                            </div>
                                        }
                                        <table>
                                            {items}
                                        </table>
                                        <div className={styles.CollapsedOrderList} onClick={toggleCollapse}>
                                            Umanjen prikaz &nbsp;
                                            <ChevronUpIcon width={18} height={18}/>
                                        </div>
                                    </div>
                                    :
                                    <div className={styles.CollapsedOrderList} onClick={toggleCollapse}>
                                        Detalji porudžbine<ChevronDownIcon
                                        width={18} height={18}/>
                                    </div>
                                }
                            </SlideDown>
                        </div>
                    }

                    {matches.large &&
                        <>
                            <div key={props.order.id} className={styles.Order}>
                                <div className={styles.OrderHeader}>
                                    <div className={styles.OrderInfoPart}>
                                        <div className={styles.OrderInfoPartSection} style={{alignItems:"flex-start"}}>
                                            <div className={styles.OrderInfoPartSectionTitle}
                                                 style={{fontSize: "26px", fontWeight: "bold", color: "#259cbaff"}}>
                                                {props.order.marketplaceResponse.name}
                                            </div>
                                            <div className={styles.OrderInfoPartSectionAddress}
                                                 onClick={() => redirectToGoogleMap(props.order.marketplaceResponse.latitude, props.order.marketplaceResponse.longitude)}>
                                                <PinIcon style={{stroke:"inherit"}}/>
                                                {props.order.marketplaceResponse.address}
                                            </div>
                                        </div>
                                        <div className={styles.OrderInfoPartSection}>
                                            {ratingContainer}
                                        </div>
                                        <div className={styles.OrderInfoPartSection}>
                                            <div className={styles.OrderInfoPartSectionTitle}>Broj porudžbine:</div>
                                            <div style={{
                                                fontSize: "18px",
                                                fontWeight: "bold",
                                                color: "#259cbaff"
                                            }}>#{props.order.orderNumber === undefined ? props.order.id : props.order.orderNumber}</div>
                                        </div>
                                        <div className={styles.OrderInfoPartSection}>
                                            <div className={styles.OrderInfoPartSectionTitle}>{props.order.orderNumber === undefined ? "Poručeno":"Datum"}</div>
                                            <div style={{fontSize: "18px"}}>
                                                {props.day}.{props.month}.{props.year}.
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.FeedbackPart}>

                                        {props.order.status === 'CREATED' &&
                                            <OrderStatus
                                                icon={<ClockIcon
                                                    style={{width: "36px", height: "36px", fill: "#f58300"}}/>}
                                                status={'NA ČEKANJU'}
                                                statusStyle={{color: "#f58300"}}
                                                message={"Zahtev se obrađuje u apoteci"}
                                            />
                                        }

                                        {props.order.status === 'PROCESSED' &&
                                            <OrderStatus
                                                icon={<ThumbUpIcon
                                                    style={{width: "36px", height: "36px", fill: "#ffae00"}}/>}
                                                status={'U PRIPREMI'}
                                                statusStyle={{color: "#ffae00"}}
                                                message={props.order.willBeReadyIn ? "Preuzimanje od " + props.order.willBeReadyIn : "Možete preuzeti odmah"}
                                            />
                                        }

                                        {props.order.status === 'PREPARED' &&
                                            <OrderStatus style={{color: "#00b2b1"}}
                                                         status={'SPAKOVANO'}
                                                         icon={<ThumbUpIcon style={{width: "36px", height: "36px"}}/>}
                                                         message={"Vaša porudžbina je spakovana."}
                                            />
                                        }

                                        {props.order.status === 'TAKEN' &&
                                            <OrderStatus style={{color: "#013a63"}}
                                                         status={'PREUZETA'}
                                                         icon={<CheckCircleIcon style={{width: "36px", height: "36px"}}/>}
                                                         message={"Vaša porudžbina je preuzeta."}
                                            />
                                        }

                                        {props.order.status === 'REJECTED' &&
                                            <OrderStatus style={{color: "#c03950"}}
                                                         status={'ODBIJENO'}
                                                         icon={<XCircleIcon style={{width: "36px", height: "36px"}}/>}
                                                         message={"Vaša porudžbina nije prihvaćena."}
                                            />
                                        }

                                        {props.order.status === 'CANCELED' &&
                                            <OrderStatus style={{color: "#8b8c8f"}}
                                                         status={'OTKAZANO'}
                                                         icon={<MinusCircleIcon style={{width: "36px", height: "36px"}}/>}
                                                         message={"Vaša porudžbina je otkazana."}
                                            />
                                        }
                                    </div>
                                </div>
                                <div className={styles.OrderBody}>
                                    <SlideDown className={styles.MyDropdownSlidedown}
                                               transitionOnAppear={props.transaction}
                                    >
                                        {boxExpanded ?
                                            <div className={styles.OrderList} onClick={toggleCollapse}>
                                                {(props.order.noteForMarketplace || props.order.noteForUser || props.order.noteForCanceledStatus) &&
                                                    <div className={styles.NoteForUser}>
                                                        {props.order.noteForMarketplace &&
                                                            <div>
                                                                <span style={{color: "#4d5055ff", fontWeight: "600"}}>Tvoja napomena:</span>
                                                                <p>{props.order.noteForMarketplace}</p>
                                                            </div>
                                                        }
                                                        {props.order.noteForUser && props.order.noteForUser.length > 0 &&
                                                            <div style={{textAlign:"right"}}>
                                                                <span style={{color: "#4d5055ff", fontWeight: "600"}}>Napomena apotekara:</span>
                                                                <p>{props.order.noteForUser}</p>
                                                            </div>
                                                        }
                                                        {props.order.noteForCanceledStatus &&
                                                            <div style={{textAlign:"right"}}>
                                                                <span style={{color: "#4d5055ff", fontWeight: "600"}}>Napomena apotekara prilikom odbacivanja porudzbine:</span>
                                                                <p>{props.order.noteForCanceledStatus}</p>
                                                            </div>
                                                        }
                                                    </div>
                                                }

                                                <table>
                                                    <tr>
                                                        <th style={{width: "70%", textAlign: "left", cursor: "pointer"}}>Proizvodi &nbsp; <ChevronUpIcon width={14}/></th>
                                                        <th>Količina</th>
                                                        <th>Cena</th>
                                                    </tr>
                                                    {items}
                                                </table>
                                            </div>
                                            :
                                            <div className={styles.CollapsedOrderList} onClick={toggleCollapse}>
                                                Broj poručenih proizvoda: {items.length} &nbsp;&nbsp; <ChevronDownIcon width={18} height={18} />
                                            </div>
                                        }
                                    </SlideDown>
                                    <div className={styles.OrderTotal}>
                                        <p>Ukupna cena</p>
                                        <p className={styles.TotalAmount}>{(props.order.totalPrice)} RSD</p>
                                    </div>
                                </div>
                            </div>
                            {/*<p>Porudžbina je poslata apoteci u {props.hours}:{props.minutes}</p>*/}

                        </>
                    }
                    {showRatingModal &&
                        <RateTransactionModal
                            show={showRatingModal}
                            onRateButtonClick={saveRating}
                            shopName={props.order.marketplaceResponse.name}
                            orderNumber={props.order.orderNumber}
                            day={props.day}
                            month={props.month}
                            year={props.year}/>
                    }
                </Fragment>
            )}
        </Media>
    )
}

const mapStateToProps = (state) => {
    return {
        allTransactions: state.ordersAndTransactions.allTransactions,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onRateTransaction: (rating) => dispatch(actions.rateTransaction(rating)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Order);