import React from 'react';
import styles from './TherapyArticles.module.css';
import TherapyArticle from "./TherapyArticle/TherapyArticle";
import PerfectScrollbar from "react-perfect-scrollbar";

const TherapyArticles = (props) => {

    return (
        <div className={styles.TherapyArticles}>
            <PerfectScrollbar>
            {props.therapy && props.therapy.items.map((item, index) => {
                return(
                    <TherapyArticle article={item} key={index}/>
                );
            })}
            </PerfectScrollbar>
        </div>
    );
};

export default TherapyArticles;