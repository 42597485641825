import React, {Fragment} from "react";
import Wrapper from "../../UI/Wrapper/Wrapper";
import HeaderImage from "../../../assets/family.jpg";
import HeaderImageSmall from "../../../assets/familysmall.jpg";
import CoffeeImage from "../../../assets/coffee-gb.png";
import CoffeeImageSmall from "../../../assets/coffeesmall.png";
import Toolbar from "../../Navigation/Toolbar/Toolbar";
import styles from './LoyaltyProgramPage.module.css';
import Media from "react-media";

const LoyaltyProgramPage = () => {
    return (
        <Wrapper>
            <Toolbar/>
            <Media
                queries={{
                    small: "(max-width: 699px)",
                    large: "(min-width: 700px)"
                }}>
                {matches => (
                    <Fragment>
                        {matches.small &&
                            <div className={styles.HeaderImage} style={{backgroundImage: `url(${HeaderImageSmall})`}}/>
                        }

                        {matches.large &&
                            <div className={styles.HeaderImage} style={{backgroundImage: `url(${HeaderImage})`}}/>
                        }
                    </Fragment>
                )}
            </Media>

            <h2 className={styles.TextTitle}><span style={{color: "#FFAE00"}}>TVOJE VREME</span> Loyalty program</h2>
            <div className={styles.Text}>
                <span><strong>Je l’ imaš vremena?</strong></span>
                <p>Brz tempo današnjice često definiše naš dan više nego mi sami. Pitanje koje često postavljamo i sebi
                    i drugima glasi <strong>je l’ imaš dovoljno vremena?</strong> Za sebe, za prijatelje, za putovanje ili
                    jednostavno <strong>je l’ imaš dovoljno vremena za kafu?</strong>
                </p>
                <p>Zamisli da je danas ponedeljak. Šta prepričavaš u kancelariji? Da li ti
                    fali dan odmora ili dan za viđanje sa prijateljima iz drugog grada? <span><strong>Za šta ti fali vremena?</strong></span>
                </p>
                <p style={{marginTop: "0"}}>Kada razmisliš o svemu, neko vreme prošlo je na način na koji nisi želeo.
                    Nekada je to samo vikend, a nekada se period definiše emocijom. Možda je trenutak da se
                    zapitaš <strong>ko zaslužuje tvoje vreme?</strong></p>
                <p>Bez obzira na sve, <strong>za koga uvek imaš vremena?</strong> Ko je u tvojoj svakodnevici važniji od
                    gužve u saobraćaju, zbog koga putuješ u drugi grad i sa kime planiraš subotu veče?</p>
                <p>Znaš onaj osećaj koji daje dobra knjiga, dobro društvo, atmosfera oko tebe, onaj osećaj koji te natera da
                    pomisliš kako bi bilo dobro da nema sata. <strong>Kada stane tvoje vreme?</strong></p>
                <p>Čime meriš prolaznost vremena? Desetogodišnjicom mature ili shvatiš da se nekome bliži upis u prvi
                    razred? Koliko duboko u sećanje urežemo uspomene koje nam određuju <strong>uz koga to spoznajemo
                        vreme?</strong></p>

            </div>
            <Media
                queries={{
                    small: "(max-width: 699px)",
                    large: "(min-width: 700px)"
                }}>
                {matches => (
                    <Fragment>
                        {matches.small &&
                            <div className={styles.TextWithBackground}>
                                <img style={{width:"70vw"}} src={CoffeeImageSmall} alt=""/>
                                <div className={styles.Text}>
                                    <span><strong>Koliko vredi tvoje vreme?</strong></span>
                                    <p> Pre nego što odrediš gde konkurišeš za posao, u koju školu upisuješ prvaka, gde putuješ, kada
                                        ideš u bioskop, kada posećuješ prijatelje, kada organizuješ sastanak, ti prvo sagledaš TVOJE
                                        VREME.</p>
                                    <p> Zamisli da te je naša aplikacija sprečila da potrošiš 15 minuta više svog vremena. Kada kažemo
                                        sprečila, mislimo na to da ne moraš da brineš stižeš li na dogovorenu kafu, ako se pre toga
                                        uskoro negde završava dečiji rođendan, pas mora u šetnju, a ti na drugi kraj grada.</p>
                                    <p> Ako ne možemo da promenimo termin rođendana ili početka filma, sigurno možemo da uštedimo vreme
                                        u apoteci. Time što smo na tom mestu danas uštedeli vreme za tebe, mi ti dajemo više dodatnog
                                        vremena, vremena za prijatelje, porodicu, kolege, ti biraš, jer to je TVOJE VREME. </p>
                                    <p> Sada kada imaš više vremena, stvari zvuče bolje.</p>
                                    <p> Seti se kafe sa početka priče. Šta može biti još bolje? Zamisli da imaš i kafu i vreme.</p>
                                    <p>TVOJIM VREMENOM kod nas zaslužio si 5 minuta duže za svoju kafu, ili bolje je reći i kafu i 5
                                        minuta vremena.</p>
                                </div>
                            </div>
                        }

                        {matches.large &&
                            <div className={styles.TextWithBackground}>
                                <img src={CoffeeImage} alt=""/>
                                <div style={{margin: "auto"}}>
                                    <span><strong>Koliko vredi tvoje vreme?</strong></span>
                                    <p style={{width: "700px"}}>
                                        Pre nego što odrediš gde konkurišeš za posao, u koju školu upisuješ prvaka, gde
                                        putuješ, kada ideš u bioskop, kada posećuješ prijatelje, kada organizuješ
                                        sastanak, ti prvo sagledaš
                                        TVOJE VREME.Zamisli da te je naša aplikacija sprečila da potrošiš
                                        15 minuta više svog vremena. Kada kažemo
                                        sprečila, mislimo na to da ne moraš da brineš stižeš li na dogovorenu kafu, ako
                                        se pre toga uskoro negde završava dečiji rođendan, pas mora u šetnju, a ti na
                                        drugi kraj
                                        grada. Ako ne možemo da promenimo termin rođendana ili početka
                                        filma, sigurno možemo da uštedimo vreme u apoteci. Time što smo na tom mestu
                                        danas uštedeli vreme za tebe, mi ti dajemo
                                        više dodatnog vremena, vremena za prijatelje, porodicu, kolege, ti biraš, jer to
                                        je TVOJE VREME. Sada kada imaš više vremena, stvari zvuče bolje.Seti se kafe sa
                                        početka priče. Šta može biti još bolje?
                                        Zamisli da imaš i kafu i vreme.TVOJIM VREMENOM kod nas zaslužio si 5 minuta duže
                                        za
                                        svoju kafu, ili bolje je reći i kafu i 5
                                        minuta vremena.</p>
                                </div>
                            </div>
                        }
                    </Fragment>
                )}
            </Media>

            <div className={styles.Text}>
                <span><strong>Kako TVOJE VREME obezbeđuje kafu?</strong></span>
                <p style={{marginTop: "0"}}>
                    Rezervacijom putem platforme štedimo novac, a šta je ono što štedimo korišćenjem iste što nam
                    najviše znači - vreme. Sakupljajući novac, mi sakupljamo i minute. Na platformi apoteka.rs određeni
                    proizvodi su nosioci Loyalty programa. Kada uspešno preuzmeš svoje rezervacije sa Loyalty
                    proizvodima, mi ti dajemo još minuta. Svaki tvoj bod je jedan minut više. Određeni broj minuta možeš
                    konvertovati za kafu.
                </p>
                <span><strong>Zašto bi imao vremena samo za kafu?</strong></span>
                <p style={{marginTop: "0"}}>
                    Loyalty nivoi daju ti mogućnost da svoje minute konvertuješ za mnoge druge stvari za koje ti je
                    potrebno vreme. Tako uz nas možeš imati dovoljno vremena za putovanje, za trening, bioskop i mnoštvo
                    drugih stvari na koje bi potrošio svoje minute, sate i dane.
                </p>
                <span><strong>Kako se postaje član Loyalty programa?</strong></span>
                <p style={{marginTop: "0"}}>
                    Registracija automatski uključuje korisnika u sistem Loyalty programa. Svaki registrovani korisnik
                    postaje član Loyalty programa „TVOJE VREME“ i dobija poklon dobrodošlice.
                </p>
                <span><strong>Šta korisniku donosi Loyalty program?</strong></span>
                <p style={{marginTop: "0"}}>
                    Loyalty program omogućava korisnicima poklone dobrodošlice, online konsultacije sa farmaceutima,
                    specijalne akcije, kao i mogućnost konvertovanja stečenih minuta za razne proizvode iz
                    neapotekarskog asortimana.
                </p>
                <span><strong>Kako se stiču minuti u okviru Loyalty programa?</strong></span>
                <p style={{marginTop: "0"}}>
                    Na platformi apoteka.rs određeni proizvodi su deo Loyalty programa i uspešno preuzetim
                    rezervacijama koje sadrže te proizvode, korisnik stiče Loyalty minute.
                </p>
            </div>

            <Media
                queries={{
                    small: "(max-width: 699px)",
                    large: "(min-width: 700px)"
                }}>
                {matches => (
                    <Fragment>
                        {matches.small &&
                            <>
                                <div className={styles.TableStyle}>
                                    <tr>
                                        <th>
                                            <span className={styles.ColoredText}>Nivo 1</span> <br/>
                                            <span>TVOJI MINUT</span>
                                        </th>
                                    </tr>
                                    <tr className={styles.OrangeRow}>
                                        <td>10 – 59 min</td>
                                    </tr>
                                    <tr>
                                        <td>Poklon dobrodošlice nakon uspešne registracije - <span className={styles.ColoredText}>10 minuta (100 RSD)</span></td>
                                    </tr>
                                    <tr style={{borderTop:"1px solid #FFAE00", borderBottom:"1px solid #FFAE00"}}>
                                        <td>Poklon nakon uspešno realizovane
                                            prve porudžbine - <span className={styles.ColoredText}>20 minuta (200 RSD)</span></td>
                                    </tr>
                                    <tr>
                                        <td>Razgovor sa farmaceutom</td>
                                    </tr>
                                    <tr style={{borderTop:"1px solid #FFAE00"}}>
                                        <td>Mini Loyalty katalog</td>
                                    </tr>
                                </div>
                                <div className={styles.TableStyle}>
                                    <tr>
                                        <th>
                                            <span className={styles.ColoredText}>Nivo 2</span> <br/>
                                            <span>TVOJI SATI</span>
                                        </th>
                                    </tr>
                                    <tr className={styles.OrangeRow}>
                                        <td>60 min – 1439 min</td>
                                    </tr>
                                    <tr>
                                        <td>Loyalty katalog A</td>
                                    </tr>
                                    <tr style={{borderTop:"1px solid #FFAE00", borderBottom:"1px solid #FFAE00"}}>
                                        <td>Posebni akcijski proizvodi sniženi do 20%</td>
                                    </tr>
                                    <tr>
                                        <td>Razgovor sa farmaceutom</td>
                                    </tr>
                                    <tr style={{borderTop:"1px solid #FFAE00"}}>
                                        <td>Mini Loyalty katalog</td>
                                    </tr>
                                </div>
                                <div className={styles.TableStyle}>
                                    <tr>
                                        <th>
                                            <span className={styles.ColoredText}>Nivo 3</span> <br/>
                                            <span>TVOJI DANI</span>
                                        </th>
                                    </tr>
                                    <tr className={styles.OrangeRow}>
                                        <td>1440 i više minuta</td>
                                    </tr>
                                    <tr>
                                        <td>Loyalty katalog A + B</td>
                                    </tr>
                                    <tr style={{borderTop:"1px solid #FFAE00", borderBottom:"1px solid #FFAE00"}}>
                                        <td>Posebni akcijski proizvodi sniženi do 70%</td>
                                    </tr>
                                    <tr>
                                        <td>Razgovor sa farmaceutom</td>
                                    </tr>
                                    <tr style={{borderTop:"1px solid #FFAE00"}}>
                                        <td>Mini Loyalty katalog</td>
                                    </tr>
                                </div>
                            </>
                        }

                        {matches.large &&
                            <div className={styles.TableStyle}>
                                <tr>
                                    <th>
                                        <span className={styles.ColoredText}>Nivo 1</span> <br/>
                                        <span>TVOJI MINUT</span>
                                    </th>
                                    <th className={styles.MiddleColumn}>
                                        <span className={styles.ColoredText}>Nivo 2</span> <br/>
                                        <span>TVOJI SATI</span>
                                    </th>
                                    <th>
                                        <span className={styles.ColoredText}>Nivo 3</span> <br/>
                                        <span>TVOJI DANI</span>
                                    </th>
                                </tr>
                                <tr className={styles.OrangeRow}>
                                    <td>10 – 59 min</td>
                                    <td className={styles.MiddleColumn}>60 min – 1439 min</td>
                                    <td>1440 i više minuta</td>
                                </tr>
                                <tr>
                                    <td>Poklon dobrodošlice nakon uspešne registracije - <span className={styles.ColoredText}>10 minuta (100 RSD)</span></td>
                                    <td className={styles.MiddleColumn}>Loyalty katalog A</td>
                                    <td>Loyalty katalog A + B</td>
                                </tr>
                                <tr style={{borderTop:"1px solid #FFAE00", borderBottom:"1px solid #FFAE00"}}>
                                    <td>Poklon nakon uspešno realizovane
                                        prve porudžbine - <span className={styles.ColoredText}>20 minuta (200 RSD)</span></td>
                                    <td className={styles.MiddleColumn}>Posebni akcijski proizvodi sniženi do 20%</td>
                                    <td>Posebni akcijski proizvodi sniženi do 70%</td>
                                </tr>
                                <tr>
                                    <td>Razgovor sa farmaceutom</td>
                                    <td className={styles.MiddleColumn}>Razgovor sa farmaceutom</td>
                                    <td>Razgovor sa farmaceutom</td>
                                </tr>
                                <tr style={{borderTop:"1px solid #FFAE00"}}>
                                    <td>Mini Loyalty katalog</td>
                                    <td className={styles.MiddleColumn}>Mini Loyalty katalog</td>
                                    <td>Mini Loyalty katalog</td>
                                </tr>
                            </div>
                        }
                    </Fragment>
                )}
            </Media>

            <div className={styles.Text}>
                <span><strong>Kolika je vrednost minuta u okviru Loyalty programa?</strong></span>
                <p style={{marginTop: "0"}}>
                    1 minut ima vrednost 10 RSD.
                </p>
                <span><strong>Kako se troše dobijeni minuti?</strong></span>
                <p style={{marginTop: "0"}}>
                    Minuti u okviru Loyalty programa mogu se konvertovati za proizvode iz Loyalty kataloga ili za
                    posebne akcijske proizvode.
                </p>
                <span><strong>Šta znači Loyalty katalog?</strong></span>
                <p style={{marginTop: "0"}}>
                    Na platformi apoteka.rs Loyalty korisnicima su na raspolaganju proizvodi koji vrede određen broj
                    Loyalty minuta. Kada korisnik skupi definisan broj minuta za određeni nivo, svoje stečene minute
                    može konvertovati za proizvode iz dostupnog kataloga.
                </p>
                <span><strong>Kako se preuzimaju Loyalty proizvodi?</strong></span>
                <p style={{marginTop: "0"}}>
                    Odabrani proizvod iz Loyalty kataloga biće prosleđen korisniku na željenu adresu. U slučaju da
                    korisnik konvertuje svoje minute za uslugu, biće mu prosleđen elektronski vaučer.
                </p>
                <span><strong>Šta znače posebni akcijski proizvodi?</strong></span>
                <p style={{marginTop: "0"}}>
                    Posebni akcijski proizvodi su dostupni za rezervisanje za korisnike Nivoa 2 i Nivoa 3 po sniženim
                    cenama do 20%, odnosno do 70%.
                </p>
                <span><strong>Kako korisnik stiče nove minute u okviru Loyalty programa?</strong></span>
                <p style={{marginTop: "0"}}>
                    Korisnik stiče nove minute uspešno preuzetom rezervacijom Loyalty proizvoda koji vrede izvesni
                    broj Loyalty minuta. Loyalty minuti se automatski obračunavaju nakon uspešno preuzete
                    rezervacije.
                </p>
                <span><strong>Gde se proverava status Loyalty minuta?</strong></span>
                <p style={{marginTop: "0"}}>
                    Provera Loyalty minuta nalazi se u okviru stranice Moj nalog, u delu Loyalty minuti.
                </p>
                <span><strong>Kako se može napredovati kroz Loyalty nivoe?</strong></span>
                <p style={{marginTop: "0"}}>
                    Loyalty nivoi se mogu nadograditi prikupljanjem novih minuta, tako što korisnik uspešno
                    preuzima svoje rezervacija sa Loyalty proizvodima – nosiocima Loyalty minuta.</p>
                <p> Prema broju minuta u datom trenutku, korisnik menja poziciju u okviru Loyalty programa.</p>
            </div>
        </Wrapper>
    );
}

export default LoyaltyProgramPage;