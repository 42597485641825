import * as actionsTypes from '../actions/actionsTypes'

const initialState = {
    shoppingCardDrawer: false,

    toolbarWhite: false,
}

const toolbarReducer = (state = initialState, action ) => {
    switch (action.type) {
        case actionsTypes.SHOW_SHOPPING_CARD_DRAWER_CHANGED:
            return{
                ...state,
                shoppingCardDrawer: !state.shoppingCardDrawer
            }

        case actionsTypes.TOOLBAR_WHITE:
            return {
                ...state,
                toolbarWhite: action.toolbarWhite
            }
        default:
            return state;
    }
}

export default toolbarReducer;