import React from 'react'
import styles from './SubNavigation.module.css'
import { NavLink } from "react-router-dom";

const SubNavigation = () => {

    return (
        <div className={styles.SubNavigationMenuWrapper}>
            <nav>
                <ul>
                    <li>
                        <div className={styles.LinkTextWrapper}>
                            <NavLink to="/myprofile"
                                     exact
                                     className={styles.SubNavigation}
                                     activeClassName={styles.Selected}
                                >Moj Profil
                            </NavLink>
                        </div>
                    </li>
                    {/*<li>*/}
                    {/*    <div className={styles.LinkTextWrapper}>*/}
                    {/*        <NavLink to="/myprofile/privacy"  className={styles.SubNavigation} activeClassName={styles.Selected}>Lični podaci*/}
                    {/*        </NavLink>*/}
                    {/*    </div>*/}
                    {/*</li>*/}
                    <li>
                        <div className={styles.LinkTextWrapper}>
                            <NavLink to="/myprofile/my-orders"  className={styles.SubNavigation} activeClassName={styles.Selected}>Moje porudžbine</NavLink>
                        </div>
                    </li>
                    <li>
                        <div className={styles.LinkTextWrapper}>
                            <NavLink to="/myprofile/my-therapies" className={styles.SubNavigation} activeClassName={styles.Selected}>Moje terapije</NavLink>
                        </div>
                    </li>
                    <li>
                        <div className={styles.LinkTextWrapper}>
                            <NavLink to="/myprofile/loyalty"  className={styles.SubNavigation} activeClassName={styles.Selected}>Loyalty bodovi</NavLink>
                        </div>
                    </li>
                    {/*<li>*/}
                    {/*    <div className={styles.LinkTextWrapper}>*/}
                    {/*        <NavLink to="/myprofile/my-addresses"  className={styles.SubNavigation} activeClassName={styles.Selected}>Adrese</NavLink>*/}
                    {/*    </div>*/}
                    {/*</li>*/}
                    {/*<li>*/}
                    {/*    <div className={styles.LinkTextWrapper}>*/}
                    {/*        <NavLink to="/myprofile/favourite-pharmacies"  className={styles.SubNavigation} activeClassName={styles.Selected}>Omiljene apoteke</NavLink>*/}
                    {/*    </div>*/}
                    {/*</li>*/}
                </ul>
            </nav>
        </div>
    )
}

export default SubNavigation;