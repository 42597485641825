import * as actionsTypes from './actionsTypes'
import axios from "../../axios/axios-apoteka";
import {fetchAllShoppingCarts} from "./shopPageActions";

export const userProfile = (userProfile) => {
    return {
        type: actionsTypes.USER_PROFILE,
        userProfile: userProfile
    };
}

export const fetchUserProfileFailed = () => {
    return {
        type: actionsTypes.USER_PROFILE_FAILED
    };
}

export const fetchUserProfileStart = () => {
    return {
        type: actionsTypes.USER_PROFILE_START
    };
}

export const fetchUserProfile = (userEmail) => {
    return dispatch => {
        dispatch(fetchUserProfileStart());
        const URL = '/user/'+ userEmail;
        axios.get(URL)
            .then(response => {
                console.log(response.data);
                dispatch(userProfile(response.data))
            })
            .catch(error => {
                dispatch(fetchUserProfileFailed())
            })

    };
}

//user actions for updating user profile
export const updateUserProfileSuccess = (userProfileUpdated) => {
    return {
        type: actionsTypes.UPDATE_USER_PROFILE_SUCCESS,
        userProfileUpdated: userProfileUpdated
    };
}

export const updateUserProfileFailed = () => {
    return {
        type: actionsTypes.UPDATE_USER_PROFILE_FAILED
    };
}

export const updateUserProfileStart = () => {
    return {
        type: actionsTypes.UPDATE_USER_PROFILE_START
    };
}

export const updateUserProfile = (userProfile) => {
    return dispatch => {
        dispatch(updateUserProfileStart());
        const URL = '/user';
        axios.put(URL, userProfile)
            .then(response => {
                console.log(response.data);
                dispatch(updateUserProfileSuccess(userProfile))
            })
            .catch(error => {
                dispatch(updateUserProfileFailed())
            })

    };
}


//actions for deleteing user
export const deleteUserProfileSuccess = () => {
    return {
        type: actionsTypes.DELETE_USER_PROFILE_SUCCESS
    };
}

export const deleteUserProfileFailed = () => {
    return {
        type: actionsTypes.DELETE_USER_PROFILE_FAILED
    };
}

export const deleteUserProfileStart = () => {
    return {
        type: actionsTypes.DELETE_USER_PROFILE_START
    };
}

export const deleteUserProfile = (username) => {
    return dispatch => {
        dispatch(deleteUserProfileStart());
        const URL = '/user/' + username;
        axios.delete(URL)
            .then(response => {
                console.log(response.data);
                dispatch(deleteUserProfileSuccess())
            })
            .catch(error => {
                dispatch(deleteUserProfileFailed())
            })

    };
}

//actions for changing password
export const changePasswordSuccess = () => {
    return {
        type: actionsTypes.CHANGE_PASSWORD_SUCCESS
    };
}

export const changePasswordFailed = () => {
    return {
        type: actionsTypes.CHANGE_PASSWORD_FAILED
    };
}

export const wrongOldPassword = () => {
    return {
        type: actionsTypes.CHANGE_PASSWORD_WRONG_OLD_PASSWORD
    };
}

export const changePasswordStart = () => {
    return {
        type: actionsTypes.CHANGE_PASSWORD_START
    };
}

export const changePassword = (changePasswordRequest) => {
    return dispatch => {
        dispatch(changePasswordStart());
        const URL = '/user/change_password';
        axios.put(URL, changePasswordRequest)
            .then(response => {
                console.log(response.data);
                dispatch(changePasswordSuccess())
            })
            .catch(error => {
                if (error.response !== null && error.response !== undefined  && error.response.data === "Failed to confirm password") {
                    dispatch(wrongOldPassword())
                }
                if (error.response !== null && error.response !== undefined  && error.response.data === "Wrong id") {
                    dispatch(changePasswordFailed());
                }
            })

    };
}

//actions for user address
export const addressAdded = (newAddress) => {
    return {
        type: actionsTypes.ADD_NEW_USER_ADDRESS,
        newAddress: newAddress
    };
}

export const addNewUserAddressFailed = () => {
    return {
        type: actionsTypes.ADD_NEW_USER_ADDRESS_FAILED
    };
}

export const addNewUserAddressStart = () => {
    return {
        type: actionsTypes.ADD_NEW_USER_ADDRESS_START
    };
}

export const addNewUserAddress = (addressRequest) => {
    return dispatch => {
        dispatch(addNewUserAddressStart());
        const URL = '/user_address';
        axios.post(URL, addressRequest)
            .then(response => {
                console.log(response.data);
                dispatch(addressAdded(response.data))
            })
            .catch(error => {
                dispatch(addNewUserAddressFailed())
            })

    };
}


export const addressDeleted = (deletedAddressId) => {
    return {
        type: actionsTypes.DELETE_USER_ADDRESS,
        deletedAddressId: deletedAddressId
    };
}

export const deleteUserAddressFailed = () => {
    return {
        type: actionsTypes.DELETE_USER_ADDRESS_FAILED
    };
}

export const deleteUserAddressStart = () => {
    return {
        type: actionsTypes.DELETE_USER_ADDRESS_START
    };
}

export const deleteUserAddress = (addressId) => {
    return dispatch => {
        dispatch(deleteUserAddressStart());
        const URL = '/user_address/' + addressId;
        axios.delete(URL)
            .then(response => {
                console.log(response.data);
                dispatch(addressDeleted(addressId))
            })
            .catch(error => {
                dispatch(deleteUserAddressFailed())
            })

    };
}

export const addressUpdated = (updatedAddress) => {
    return {
        type: actionsTypes.UPDATE_USER_ADDRESS,
        updatedAddress: updatedAddress
    };
}

export const updateUserAddressFailed = () => {
    return {
        type: actionsTypes.UPDATE_USER_ADDRESS_FAILED
    };
}

export const updateUserAddressStart = () => {
    return {
        type: actionsTypes.UPDATE_USER_ADDRESS_START
    };
}

export const updateUserAddress = (addressRequest) => {
    return dispatch => {
        dispatch(updateUserAddressStart());
        const URL = '/user_address';
        axios.put(URL, addressRequest)
            .then(response => {
                console.log(response.data);
                dispatch(addressUpdated(response.data))
            })
            .catch(error => {
                dispatch(updateUserAddressFailed())
            })

    };
}

//fetching therapies
export const userTherapies = (userTherapies) => {
    return {
        type: actionsTypes.USER_THERAPIES,
        userTherapies: userTherapies
    };
}

export const fetchUserTherapiesFailed = () => {
    return {
        type: actionsTypes.USER_THERAPIES_FAILED
    };
}

export const fetchUserTherapiesStart = () => {
    return {
        type: actionsTypes.USER_THERAPIES_START
    };
}

export const fetchUserTherapies = (userId) => {
    return dispatch => {
        dispatch(fetchUserTherapiesStart());
        const URL = '/user_therapy/' + userId;
        axios.get(URL)
            .then(response => {
                console.log('TERAPIJE')
                console.log(response.data);
                dispatch(userTherapies(response.data))
            })
            .catch(error => {
                dispatch(fetchUserTherapiesFailed());
                console.log("error se desio: " + error)
            })

    };
}

export const setSelectedItem = (item) => {
    return {
        type: actionsTypes.SET_SELECTED_ITEM,
        selectedItem: item
    }
}

export const toggleTherapyArticleModal = (value) => {
    return {
        type: actionsTypes.TOGGLE_THERAPY_ARTICLE_MODAL,
        toggleTherapyArticleModal: value
    }
}

export const addArticleToTherapy = (itemToAdd) => {
    return {
        type: actionsTypes.ADD_ARTICLE_TO_THERAPY,
        itemToAdd: itemToAdd
    }
}

export const removeArticleFromTherapy = (itemToRemove) => {
    return {
        type: actionsTypes.REMOVE_ARTICLE_FROM_THERAPY,
        itemToRemove: itemToRemove
    }
}

// remove all articles from user therapy
export const removeAllArticlesFromTherapy =()=> {
    return {
        type: actionsTypes.REMOVE_ALL_ARTICLES_FROM_THERAPY
    }
}


// creating new user thrapy
export const createNewTherapyStart = () => {
    return {
        type: actionsTypes.CREATE_NEW_THERAPY_START
    }
}

export const newUserTherapyCreated = (therapyObject) => {
    return {
        type: actionsTypes.NEW_THERAPY_CREATED,
        newUserTherapy: therapyObject
    }
}

export const createNewTherapyFailed = () => {
    return {
        type: actionsTypes.CREATE_NEW_THERAPY_FAILED
    }
}

export const createNewTherapy = (userId, therapyObject) => {
    return dispatch => {
        dispatch(createNewTherapyStart());
        const URL = '/user_therapy/' + userId;
        axios.post(URL, therapyObject)
            .then(response => {
                console.log('NOVA TERAPIJA')
                console.log(response.data);
                dispatch(newUserTherapyCreated({...therapyObject, id: response.data}))
                // dispatch(fetchUserTherapies(userId))
            })
            .catch(error => {
                console.log("error se desio")
                dispatch(createNewTherapyFailed());
            })
    };
}

// delete user therapy
export const userTherapyDeleted = (therapyId) => {
    return {
        type: actionsTypes.USER_THERAPY_DELETED,
        deletedUserTherapyId: therapyId
    }
}

export const deleteUserTherapyStart = () => {
    return {
        type: actionsTypes.DELETE_USER_THERAPY_START
    }
}

export const deleteUserTherapyFailed = () => {
    return {
        type: actionsTypes.DELETE_USER_THERAPY_FAILED
    }
}

export const deleteUserTherapy = (therapyId) => {
    return dispatch => {
        dispatch(deleteUserTherapyStart());
        const URL = '/user_therapy/' + therapyId
        axios.delete(URL)
            .then(response => {
                console.log(response.data);
                dispatch(userTherapyDeleted(therapyId))
            })
            .catch(error => {
                dispatch(deleteUserTherapyFailed())
            })
    }
}

// ordering user therapy from pharmacy

export const storesContainingUserTherapy = (stores) => {
    return {
        type: actionsTypes.STORES_FOR_USER_THERAPY,
        storesContainingUserTherapy: stores
    }
}

export const orderTherapy = (page = 0, latitude, longitude, therapyArticles) => {
    return dispatch => {
        const URL = '/item_in_marketplace/marketplaces/therapy/in_radius/' + page + '?latitude=' + latitude + '&longitude=' + longitude + '&numOfReturns=32&isClickAndCollect=true'
        axios.post(URL, therapyArticles)
            .then(response => {
                console.log('orderTherapyresponse')
                console.log(response.data)
                dispatch(storesContainingUserTherapy(response.data))
            })
            .catch(error => {
                console.log('Error se desio: ' + error)
            })
    }
}

// add user therapy to shopping cart

export const addUserTherapyToShoppingCart = (therapyId, userId, marketplaceId, branchMarketplaceId) => {
    return dispatch => {
        const URL = '/shopping_cart/therapy/' + therapyId + '/' + userId + '/' + marketplaceId + '/' + branchMarketplaceId

        axios.post(URL)
            .then(response => {
                console.log('add user therapy to shopping cart')
                console.log(response.data)
                dispatch(fetchAllShoppingCarts(userId))
            })
            .catch(error => {
                console.log('Error se desio: ' + error)
            })
    }
}

// add article to therapy from info lek
export const addArticleToTherapyFromInfoLek = (state) => {
    return {
        type: actionsTypes.ADD_ARTICLE_TO_THERAPY_FROM_INFO_LEK,
        addArticleToTherapyFromInfoLekFlag: state
    }
}

// get new therapy name from infolek modal
export const newTherapyNameFromInfoLekModal =(name)=> {
    return {
        type: actionsTypes.GET_NEW_THERAPY_NAME_FROM_INFO_LEK_MODAL,
        newTherapyName: name
    }
}

export const addArticleToTherapyFromInfoLekModalToggle =(state)=> {
    return {
        type: actionsTypes.ADD_ARTICLE_TO_THERAPY_FROM_INFO_LEK_MODAL_TOGGLE,
        addArticleToTherapyFromInfoLekModalVisible: state
    }
}


// Endpoints za kreiranjje/azuriranje korisnicke terapuje: POST .../user_therapy/{user_id} u body-ju saljete informacije o terapiji i listu artikala kojii se dodaju u terapijju npr:
//         name: "Srce terapija",
//     description: "Ovo je korisnicka terapija",
//     therapyItemRequests: [
//     {
//         itemId: 6,
//         frequency: "" <OPTIONAL>,
//         dosing: "" <OPTIONAL>,
//         duration:  "" <OPTIONAL>,
//         note: "" <OPTIONAL>,
//         quantity: "" <REQUIRED>
//     },
//     {
//         itemId: 7,
//         frequency: "" <OPTIONAL>,
//         dosing: "" <OPTIONAL>,
//         duration:  "" <OPTIONAL>,
//         note: "" <OPTIONAL>,
//         quantity: 3 <REQUIRED>
//     },
//
// ]
