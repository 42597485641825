import React from 'react';
import { usePlacesWidget } from "react-google-autocomplete";
import {ReactComponent as FlagIcon} from "./../../../assets/icons/flag-icon.svg";
import * as actions from "../../../store/actions";
import {connect} from "react-redux";
import styles from './GoogleAutocomplete.module.css'

export const GoogleAutocomplete = (props, {a}) => {

    const sendData = (data) => {
        props.saveAddress(data);
    }

    const parseAddress = (place) => {
        if(place.formatted_address && place.geometry.location) {
            sendData(place);
        }
    }

    // apiKey: "AIzaSyCpJZvkDLC3WlGYN8SPquDYoisjFAKLjDk",
    const { ref } = usePlacesWidget({
        apiKey: "AIzaSyCpJZvkDLC3WlGYN8SPquDYoisjFAKLjDk",
        onPlaceSelected: (place) => {
            parseAddress(place)
            // console.log(place);
        },
        options: {
            types: ["address"],
            // types: ["pharmacy"],
            componentRestrictions: { country: "RS" },
        },
        language: "bs",
    });

    return (
        <div style={{display: "flex"}}>
            <FlagIcon style={{width: "32px"}}/>
            <input ref={ref}
                   className={styles.Input}/>
        </div>
    )
};


const mapDispatchToProps = (dispatch) => {
    return {
        onEnteredAddressData: (place) => dispatch(actions.enteredAddressData(place))
    }
}

export default connect(null, mapDispatchToProps)(GoogleAutocomplete);