import React, {Fragment, useEffect} from 'react';
import styles from './OrderCompletionMessagePage.module.css';
import {connect} from "react-redux";
import Button from '../../UI/Buttons/Button/Button'
import {Link} from "react-router-dom";
import Media from "react-media";
import {ReactComponent as OrderCompletionIcon} from "../../../assets/icons/order_completed.svg";
import HeaderWithTitleAndBackChevron
    from "../../../componentsRes/HeaderWithTitleAndBackChevron/HeaderWithTitleAndBackChevron";
import {ReactComponent as ChevronRight} from "./../../../assets/icons/chevronRight_icon.svg";

const OrderCompletionMessagePage = (props) => {

    useEffect(() => {
        return () => {
            localStorage.removeItem('deliveryMethod');
        }
    }, []);

    const backToMainPage =()=> {
        props.history.push('/');
    }
    const urlSplited = window.location.pathname.split("/")
    const totalLoyaltyMinutes = urlSplited[urlSplited.length - 1]/10;

    return (

        <Media queries={{
            small: "(max-width: 699px)",
            large: "(min-width: 700px)"
        }}>
            {matches => (
                <Fragment>

                    {matches.small &&
                    <div className={styles.OrderCompletionPageHolderMobile}>
                        <HeaderWithTitleAndBackChevron
                            title="Završena porudžbina"/>
                        <div className={styles.OrderCompletionPageMobile}>
                            <OrderCompletionIcon style={{margin: "2rem"}}/>
                            <p>Proizvodi su uspešno poručeni</p>
                            <p>Status pordžbine možete videti na strani <br/>
                                <Link to='/myprofile/my-orders' style={{textDecoration:"underline", color:"#00B2B1"}}>Moj profil</Link></p>
                            <div style={{borderTop:"1px solid #E2E6EA", paddingTop:"16px", width:"100vw"}}></div>
                            {totalLoyaltyMinutes > 0 &&
                                <p>Realizacijom ove porudžbine dobićeš {totalLoyaltyMinutes} loyalty minuta</p>
                            }
                        </div>
                        {!props.onRFZOpage &&
                        <Button buttontype='blue'
                            style={{display: "flex", marginTop: "auto", justifyContent:"center", width:"6rem"}}
                            onClick={backToMainPage}
                        >OK</Button>
                        }
                    </div>
                    }
                    {matches.large &&
                    <div>
                        <div className={styles.OrderCompletionPageHolder}>
                            <div className={styles.OrderCompletionPageMessage}>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}>
                                    <p style={{color: "#5b6977ff", fontSize:"22px", fontWeight:"bold"}}>Proizvodi su uspešno poručeni.</p>
                                    <br/>
                                    {totalLoyaltyMinutes > 0 &&
                                        <div style={{color:"#5b6977ff"}}>Realizacijom ove porudžbine dobićeš {totalLoyaltyMinutes} loyalty minuta</div>
                                    }
                                    <br/>
                                    <br/>
                                    <OrderCompletionIcon />
                                    <br/>
                                    <br/>
                                    <Link  to='/myprofile/my-orders'>
                                        <Button buttontype="blue"
                                        >Praćenje statusa porudžbine</Button></Link>
                                </div>
                                <br/>

                                <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}>
                                    <Link to='/' style={{color: "#5b6977ff", textDecoration: "none", fontSize:"14px", display:"flex", alignItems:"center"}}>Povratak na
                                        početnu stranu &nbsp; <ChevronRight /></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    }
                </Fragment>
            )}
        </Media>
    )
}

const mapStateToProps = (state) => {
    return {
        collectOrDelivery: state.orderCompletion.collectOrDelivery,
    }
}

export default connect(mapStateToProps)(OrderCompletionMessagePage);
