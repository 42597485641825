import React, {useEffect, useState} from "react";
import ModalWithHeader from "../ModalWithHeader/ModalWithHeader";
import {ReactComponent as RateTransactionIcon} from "../../../../assets/RateTransaction.svg";
import {ReactComponent as RatingStar} from "../../../../assets/icons/rating-star.svg";
import Button from "../../Buttons/Button/Button";
import {Rating} from "react-simple-star-rating";
import styles from './RateTransactionModal.module.css';
import * as actions from "../../../../store/actions";
import {connect} from "react-redux";

const RateTransactionModal = (props) => {

    const [rating, setRating] = useState(5) // initial rating value

    useEffect(() => {
        return () => {
            props.onRatingReset()
        };
    }, []);

    const handleRating = (rate) => {
        setRating(rate/20);
    }

    return (
        <ModalWithHeader noXIcon
                         buttons={
                             <div style={{width: "100%", display: "flex", justifyContent: "center"}}>
                                 <Button
                                     buttontype="blue"
                                     onClick={event => props.onRateButtonClick(rating)}>Oceni
                                 </Button>
                             </div>
                         }
                         show={props.show}>
            <div style={{display: "flex", flexDirection: "column", alignItems: "center", marginTop:"2rem"}}>
                <RateTransactionIcon/>
                <p><strong>Tvoje mišljenje nam je važno!</strong></p>
                <span>Prenesi nam svoje iskustvo u apoteci</span>
                <p><strong style={{fontSize:"20px"}}>{props.shopName}</strong></p>
                <span>prilikom preuzimanja i rezervacije porudžbine</span>
                <span><strong style={{fontSize:"20px"}}>#{props.orderNumber}</strong></span>
                <span>primljene <strong>{props.day}.{props.month}.{props.year}</strong></span>
            </div>
            <div style={{display: "flex", flexDirection: "column", alignItems: "center", marginTop:"2rem", width:"100%"}}>
                <p>Tvoja ocena:</p>
                <div color={styles.Rating}>
                    <Rating
                        onClick={handleRating}
                        iconsCount={5}
                        transition
                        initialValue={5}
                        size={50}
                        emptyIcon={<RatingStar style={{fill: "grey", padding: "2px"}}/>}
                        fullIcon={<RatingStar style={{padding: "2px"}}/>}
                        showTooltip
                        tooltipDefaultText=''
                        tooltipArray={['1.0', '2.0', '3.0', '4.0', '5.0']}
                        tooltipStyle={{
                            backgroundColor: "white",
                            color: "#013A63",
                            fontSize: "16px",
                            fontWeight: "bold"
                        }}
                    />
                </div>
            </div>
        </ModalWithHeader>
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        onRatingReset: () => dispatch(actions.rating(null)),
    }
}

export default connect(null, mapDispatchToProps)(RateTransactionModal);