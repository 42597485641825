import * as actionsTypes from '../actions/actionsTypes'

const initialState = {
    actionMenuSticky: false,
}

const actionMenuReducer = (state = initialState, action ) => {
    switch (action.type) {
        case actionsTypes.ACTION_MENU_STICKY:
            return {
                ...state,
                actionMenuSticky: action.actionMenuSticky
            }
        default:
            return state;
    }
}

export default actionMenuReducer;
