import React, { useEffect } from "react";
import styles from "./CMSPage.module.css";
import * as actions from "../../store/actions";
import { connect, useDispatch, useSelector } from "react-redux";
import Button from "../UI/Buttons/Button/Button";
import Wrapper from "../UI/Wrapper/Wrapper";

const CMSPage = (props) => {
  const logoutHandler = () => {
    props.onLogout();
    props.onResetUserInfo(null);
    props.onResetUserProfile(null);
  };

  const redirectToArticleCMSHandler = () => {
    let url = "cms/master-artikal-cms/6";
    props.history.push(url);
  };

  const redirectToStoresCMSHandler = () => {
    let url = "cms/prodajno-mesto-cms";
    props.history.push(url);
  };

  let isAdmin = false;
  localStorage
    .getItem("roles")
    .split(",")
    .map((role) => {
      if (role === "ADMIN") isAdmin = true;
    });

  const dispatch = useDispatch();
  const successfullyDeleted = useSelector((state) => state.userProfile.deleteUserProfileSuccess);

  useEffect(() => {
    if (successfullyDeleted) {
      setTimeout(() => {
        localStorage.clear();
        window.location.reload();
        window.location.pathname = "/auth/login";
      }, 10000);
    }
  }, [successfullyDeleted]);

  return (
    <Wrapper>
      <div className={styles.CMSPage}>
        <div className={styles.ActionButtonsHolder}>
          {isAdmin && (
            <>
              <Button
                onClick={redirectToArticleCMSHandler}
                style={{ backgroundColor: "grey", cursor: "default" }}
                buttontype="blue">
                CMS za proizvode
              </Button>
              <Button onClick={redirectToStoresCMSHandler} style={{ marginLeft: "1rem" }} buttontype="blue">
                CMS za prodajna mesta
              </Button>
            </>
          )}
          <Button
            onClick={() => {
              const idUser = localStorage.getItem("id");
              dispatch(actions.deleteUserProfile(idUser));
            }}
            style={{ marginLeft: "1rem" }}
            buttontype="blue">
            Delete account
          </Button>
          <br />
          {successfullyDeleted && (
            <div style={{ color: "#155724", fontSize: "24px", fontWeight: "700" }}>
              Your account has been successfully deleted
            </div>
          )}
        </div>
        <br />
        <Button onClick={logoutHandler} buttontype="blue">
          logout
        </Button>
      </div>
    </Wrapper>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLogout: () => dispatch(actions.logout()),
    onResetUserInfo: (userInfo) => dispatch(actions.userInfo(userInfo)),
    onResetUserProfile: (userProfile) => dispatch(actions.userProfile(userProfile)),
  };
};

export default connect(null, mapDispatchToProps)(CMSPage);
