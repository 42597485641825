import React from "react";
import styles from "./ArticleSubcategories2.module.css";

const ArticleSubcategories2 = (props) => {
    return(
        <div key={props.key} className={styles.ArticleSubcategories2} style={props.style} onClick={props.onClick} onMouseOver={props.onMouseOver}>
            {props.articleCategory.name}
        </div>
    );
}

export default ArticleSubcategories2