import React from 'react'
import styles from './TooltipComponent.module.css';

const TooltipComponent = (props) => {

    return(
        <span className={[props.className, styles.Tooltip].join(' ')} style={props.style}>
            {props.text}
        </span>
    );
}

export default TooltipComponent;