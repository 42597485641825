import React from 'react';
import ModalWithHeader from "../ModalWithHeader/ModalWithHeader";
import * as actions from "../../../../store/actions";
import {connect} from "react-redux";
import Button from "../../Buttons/Button/Button";
import TherapiesForModal from "./TherapiesForModal/TherapiesForModal";


const AddArticleToTherapyFromInfoLekModal = (props) => {

    const addArticleToList =()=>{
        if(props.newTherapyName) {
            const itemObj = {
                id: "",
                name: props.newTherapyName,
                description: '',
                therapyItemRequests: [{
                    itemId: props.article.id,
                    dosing: '',
                    frequency: '',
                    duration:  "",
                    note: "" ,
                    quantity: 1
                }]
            }
            props.onCreateNewTherapy(localStorage.getItem('id'), itemObj);
            props.onAddArticleToTherapyFromInfoLekModalToggle(false);
        } else {
            props.onAddArticleToTherapyFromInfoLek(true);
        }
    }

    return (
        <ModalWithHeader
            onCloseClick={props.onCloseClick}
            show={props.show}
            backdropClickHandler={props.onCloseClick}
            buttons={
                <Button buttontype='blue' style={{margin:"auto"}} onClick={addArticleToList}>Dodaj</Button>
            }
        >
        <h3 style={{width:"100%", textAlign:"left"}}>Dodaj u terapiju</h3>
        <div style={{width:"100%"}}>
            <TherapiesForModal article={props.article} />
        </div>
        </ModalWithHeader>
    );
};

const mapStateToProps = (state) => {
    return {
        userTherapies: state.userProfile.userTherapies,
        newTherapyName: state.userProfile.newTherapyName
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onAddItemToTherapy: (item) => dispatch(actions.addArticleToTherapy(item)),
        onAddArticleToTherapyFromInfoLek: (state) => dispatch(actions.addArticleToTherapyFromInfoLek(state)),
        onCreateNewTherapy: (userId, therapyObject) => dispatch(actions.createNewTherapy(userId, therapyObject)),
        onAddArticleToTherapyFromInfoLekModalToggle: (state) => dispatch(actions.addArticleToTherapyFromInfoLekModalToggle(state))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddArticleToTherapyFromInfoLekModal)