import React from 'react'
import apotekaLogo from '../../assets/logo_apoteka.svg';
import styles from './Logo.module.css';
import {withRouter} from "react-router-dom";

const logo = (props) => {

    const returnToMainPageHandler = () => {
        const url = '/';
        props.history.push(url)
    }

    return (
        <div className={styles.Logo} onClick={returnToMainPageHandler}>
                <img src={apotekaLogo} alt="apotekaLogo" />
        </div>
    );
}

export default withRouter(logo);

