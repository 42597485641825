import React, {useEffect, useState} from 'react';
import styles from './LogInPane.module.css'
import {connect} from "react-redux";
import {withRouter} from "react-router-dom"
import * as actions from '../../../store/actions/index'
import Spinner from "../../UI/Spinner/Spinner";
import authPageStyles from '../AuthPage.module.css';
import {redirectToError} from "../../../containers/App";
import Button from "../../UI/Buttons/Button/Button";
import {useForm} from 'react-hook-form';
import {EyeIcon, EyeOffIcon} from "@heroicons/react/outline";

const LogInPane = (props) => {

    const [inputState, inputStateChanged] = useState({
        credentials: {
            email: "",
            password: ""
        }
    })

    const [showPassword, toggleShowPassword] = useState(false);

    useEffect(() => {
        props.isLogin1(true);
    }, []);

    useEffect(() => {
        // stari kod
        // if (props.isLogin) {
        //     const url = localStorage.getItem("beforeLogin")
        //     alert(url)
        //     props.history.replace(url)
        //     localStorage.removeItem("beforeLogin");
        // }

        // PROBATI SA OVIM KODOM DA LI PROLAZI login ako je korisnik vec ulogovan
        if (props.isLogin) {
            let url = '/'
            if (localStorage.getItem('beforeLogin')) {
                url = localStorage.getItem("beforeLogin");
                localStorage.removeItem("beforeLogin");
            }
            props.history.replace(url)
        }
    }, [props.isLogin])


    const redirectToResetPassword = () => {
        const url = '/auth/passwordReset';
        props.history.push(url);
    }

    const [errorMessage, errorMessageHandler] = useState(null);
    if (props.loginServerError) {
        redirectToError(props);
    }

    useEffect(() => {
        if (props.loginError || props.userInfoError) {
            errorMessageHandler(
                <div className={styles.Shake}>
                    <p className={styles.ErrorMessage}>
                        Email ili password nisu ispravni!
                    </p>
                </div>)
        }
    }, [props.loginError, props.userInfoError])

    useEffect(() => {
    if (props.registrationUnconfirmed) {
        errorMessageHandler(<p>
            Niste potvrdili registraciju preko mail-a sa ovim nalogom. Molimo potvrdite nalog ili se ponovo registrujte.
        </p>)
    }
    },[props.registrationUnconfirmed])

    const {register, handleSubmit, formState: {errors}, getValues} = useForm();
    const onSubmit = () => {
        const values = getValues();
        inputStateChanged({
            ...inputState,
            credentials: {
                email: values.email,
                password: values.password,
            }
        })

        props.onLogin({email: values.email, password: values.password});
    }

    const resetErrorMessage = () => {
        errorMessageHandler(null);
    }

    let form = <Spinner/>
    if (!props.loadingLogin && !props.loadingUserInfo) {
        form = (
            <form className={authPageStyles.Form} onSubmit={handleSubmit(onSubmit)}>
                <input type="text" placeholder="Email" {...register("email", {
                    required: "Ovo polje ne može biti prazno",
                    // minLength:5,
                    maxLength: 256,
                    pattern: {
                        value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        message: "Unesena adresa nije validnog formata"
                    }
                })} />
                {errors.email && <span className={authPageStyles.ErrorMessage}>{errors.email.message}</span>}

                <div style={{width: "100%", display: "flex", position: "relative", marginLeft: "5%"}}>
                    <input type={showPassword ? "text" : "password"} placeholder="Lozinka" {...register("password", {
                        required: "Ovo polje ne može biti prazno",
                        onChange: resetErrorMessage
                        // minLength: {
                        //     value: 8,
                        //     message:"Lozinka nije validna"
                        // }
                    })}
                    />
                    {!showPassword ?
                        <EyeIcon className={styles.EyeIcon} onClick={() => toggleShowPassword(!showPassword)}/>
                        :
                        <EyeOffIcon className={styles.EyeIcon} onClick={() => toggleShowPassword(!showPassword)}/>
                    }
                </div>
                {errors.password && <span className={authPageStyles.ErrorMessage}>{errors.password.message}</span>}
                {errorMessage}
                <a onClick={redirectToResetPassword} style={{marginBottom: "1rem"}}>Zaboravili ste lozinku?</a>
                <Button type="submit" buttontype='lightblue' style={{width: "200px"}}>Prijava</Button>
            </form>
        );

    }

    return (
        <div className={styles.LogInPage}>
            {form}
            <div style={{marginBottom: "20px"}}></div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        loginError: state.authPage.loginError,
        registrationUnconfirmed: state.authPage.registrationUnconfirmed,
        loginServerError: state.authPage.loginServerError,
        loadingLogin: state.authPage.loadingLogin,
        loadingUserInfo: state.authPage.loadingUserInfo,
        userInfoError: state.authPage.userInfoError,
        isLogin: state.authPage.token !== null
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onLogin: (credentials) => dispatch(actions.login(credentials))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LogInPane));
