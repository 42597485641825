import React from 'react'
import {Route, withRouter} from "react-router-dom";
import StorePageMobileInfo from "./StorePageMobileInfo/StorePageMobileInfo";
import StorePageMobileAboutUs from "./StorePageMobileAboutUs/StorePageMobileAboutUs";

const StorePageMobileContent = () => {

    return (
        <div>
            <Route path="/storeinfo/:branchMarketplaceId/:marketplaceId/:brenchMarketplaceName/:marketplaceName" exact component={StorePageMobileInfo}/>
            <Route path="/storeinfo/:branchMarketplaceId/:marketplaceId/:brenchMarketplaceName/:marketplaceName/about-us" exact component={StorePageMobileAboutUs}/>
        </div>
    )
}

export default withRouter(StorePageMobileContent);