import React, {Fragment} from 'react';
import styles from './PicturePlacer.module.css'
import SearchWrapper from '../SearchWrapper/SearchWrapper'
import MainPageSubtitle from '../MainPageSubtitle/MainPageSubtitle'
import Toolbar from "../../Navigation/Toolbar/Toolbar";
import MainPageCounters from "../MainPageCounters/MainPageCounters";
import {connect} from "react-redux";
import configData from "../../../configConstants.json";
// import CommercialImagesLink from "../../../config.json";
import Background from "../../UI/Background/Background";
// import {redirectToCommercialLink} from "../../GobalFunctions/GlobalFunctions";
import Media from "react-media";
import HeaderWithImage from "../../../componentsRes/HeaderWithImage/HeaderWithImage";
import HIPPImage from '../../../assets/HiPP.png'
import {isAndroid, isIOS, isMobile} from "react-device-detect";
import AppButton from "../../UI/Buttons/AppButton/AppButton";

const picturePlacer = (props) => {

    // let backgroundColor = props.commercial !== null && props.commercial.backgroundColor;

    return (

        <Media
            queries={{
                small: "(max-width: 699px)",
                large: "(min-width: 700px)"
            }}>
            {matches => (
                <Fragment>
                    {matches.small &&
                        <div>
                            <Toolbar/>
                            <Background style={{height:"100vh",  background:"#FBFBFB"}}/>
                            <MainPageSubtitle />
                            <HeaderWithImage>
                                <SearchWrapper onMainPage={true}/>
                            </HeaderWithImage>
                            <MainPageCounters style={{backgroundColor:"red"}}/>
                            <div style={{display:"flex", alignItems:"center", justifyContent:"space-evenly", marginTop:"1rem"}}>
                                <div style={{fontSize:"14px"}}>Preuzmi aplikaciju</div>
                                {isMobile && isIOS && <AppButton apple style={{width:"30vw"}} />}
                                {isAndroid && <AppButton style={{width:"30vw"}} />}
                            </div>
                        </div>
                    }

                    {matches.large &&
                        <div className={styles.PicturePlacer} >
                            <Toolbar/>
                            {/*<Background style={{height:"100vh",  background:backgroundColor}}/>*/}
                            <Background style={{height:"100vh",  background:"#FBFBFB"}}/>
                            <div className={styles.Content}>
                                <div className={styles.LeftPane}>
                                    <MainPageSubtitle />
                                    <SearchWrapper onMainPage={true}/>

                                    <div style={{marginTop:"-1rem"}}>
                                        <h2 style={{marginBottom:"1rem", fontSize:"16px"}}>{configData.MainPage.SubtitleText}</h2>
                                        <MainPageCounters />
                                    </div>
                                </div>
                                {/*<div className={styles.CommercialImage} onClick={()=> {props.commercial !== null && redirectToCommercialLink(props.commercial.actionLink)}}>*/}
                                {/*    <img src={props.commercial !== null && CommercialImagesLink.CommercialImagesLink + props.commercial.imageLink} alt="" style={{objectFit:"contain"}}/>*/}
                                {/*</div> */}
                                <div className={styles.CommercialImage}>
                                    {/*<MainPagePicture className={styles.Image}/>*/}
                                    <img className={styles.image}
                                         src={HIPPImage}
                                    />
                                </div>
                            </div>
                        </div>
                    }
                </Fragment>
            )}
        </Media>


    )
};

const mapStateToProps = (state) => {
    return {
        isLoggedin: state.authPage.token !== null,
        commercial: state.mainPage.advertisement
    }
}

export default connect(mapStateToProps) (picturePlacer);
