import React, {Fragment} from 'react';
import SearchResults from "./SearchResults/SearchResults";
import SearchResultsHeader from './SearchResultsHeader/SearchResultsHeader';
import Toolbar from '../Navigation/Toolbar/Toolbar';
import {connect} from "react-redux";
import SearchResultsActionMenu from "./SearchResultsActionMenu/SearchResultsActionMenu";
import Background from "../UI/Background/Background";
import LiveChatButton from "../UI/LiveChatButton/LiveChatButton";
import Media from "react-media";
import CNCModal from "./CNCModal/CNCModal";

/*Komponenta koja predstavlja stranicu sa rezultatima pretrage nekog proizvoda*/

const searchResultsPage = (props) => {

    return (
        <div>
            <Background style={{height: "100%", zIndex: "-2"}}/>
            <Toolbar/>
            <div>
                <Media queries={{
                    small: "(max-width: 699px)",
                    large: "(min-width: 700px)"
                }}>
                    {matches => (
                        <Fragment>
                            {matches.small &&
                                <>
                                    <SearchResultsActionMenu/>
                                    <SearchResults/>
                                    {props.cncModalToggle &&
                                    <CNCModal />}
                                </>
                            }
                            {matches.large &&
                                <>
                                    <SearchResultsHeader/>
                                    <SearchResultsActionMenu/>
                                    <SearchResults/>
                                    {props.cncModalToggle &&
                                    <CNCModal />}
                                    <LiveChatButton/>
                                </>
                            }
                        </Fragment>
                    )}
                </Media>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        cncModalToggle: state.modal.cncModalToggle
    }
}

export default connect(mapStateToProps, null)(searchResultsPage);

