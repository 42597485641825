import React from "react";
import {withRouter, Route} from "react-router-dom";
import Article from "./Article/Article";
import InfoBoxTemplate from "../../../UI/InfoBoxTemplate/InfoBoxTemplate";
import SearchedArticlesInfo from "./SearchedArticlesInfo/SearchedArticlesInfo";
import SearchedStoresInfo from "./SearchedStoresInfo/SearchedStoresInfo";

const InfoBox = () => {

    return (
        <React.Fragment>
            <InfoBoxTemplate>
                <Route path="/articles-and-stores/:id/:page/:articleName" exact component={Article}/>
                <Route path="/searched-articles/:page/" exact component={SearchedArticlesInfo}/>
                <Route path="/searched-articles/:page/:string" exact component={SearchedArticlesInfo}/>
                <Route path="/unknown-item/:string" exact component={SearchedArticlesInfo}/>
                <Route path="/searched-stores/:page" exact component={SearchedStoresInfo}/>
                <Route path="/searched-stores/:page/:string" exact component={SearchedStoresInfo}/>
            </InfoBoxTemplate>
        </React.Fragment>
    );
}

export default (withRouter(InfoBox));