import React from 'react'
import * as actions from "../../../../../../store/actions";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import styles from "./PrescriptionImageThumbnail.module.css";
import {XIcon} from "@heroicons/react/outline";

const PrescriptionImageThumbnail = props => {

    const deletePrescriptionImage = (e) => {
        e.stopPropagation();
        props.deletePrescriptionImage();
    }

    return (
        <div key={props.key} className={styles.PrescriptionImagePreviewHolder} onClick={props.openPreviewModal}>
                <div className={styles.PrescriptionImagePreview}>
                    <img src={"https://test.apoteka.rs/cdn/prescriptions_images/" + props.prescriptionLink.imageLink} alt=""/>
                </div>
            <div className={styles.PrescriptionImageDeleteButton}
                 onClick={(e) => {deletePrescriptionImage(e)}}
            >
                <XIcon style={{width:"18px", stroke:"white"}} />
            </div>
        </div>
    )


}

const mapStateToProps = (state) => {
    return {
        selectedStore: state.appPage.selectedStore,

        prescriptionsLinks: state.shopPage.prescriptionsLinks,
        shoppingCart: state.shopPage.shoppingCart,

        userProfile: state.userProfile.userProfile,
        userProfileError: state.userProfile.userProfileError,
        loadingUserProfile: state.userProfile.loadingUserProfile,

        userInfo: state.authPage.userInfo,


    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onFetchSelectedStore: (branchMarketplaceId, marketplaceId) => dispatch(actions.fetchSelectedStore(branchMarketplaceId, marketplaceId)),
        onFetchUserProfile: (email) => dispatch(actions.fetchUserProfile(email)),
        onFetchShoppingCart: (userId, branchMarketplaceId, marketplaceId) => dispatch(actions.fetchShoppingCart(userId, branchMarketplaceId, marketplaceId)),

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PrescriptionImageThumbnail))