import React, {Fragment, useState} from 'react'
import Button from "../../UI/Buttons/Button/Button";
import AddAddressForm from "../../UI/AddAddressForm/AddAddressForm";
import Backdrop from "../../UI/Backdrop/Backdrop";
import * as actions from "../../../store/actions";
import {connect} from "react-redux";
import Address from './MyAddress/MyAddress';
import styles from './MyAddresses.module.css';
import ModalWithHeader from "../../UI/Modals/ModalWithHeader/ModalWithHeader";
import {LocationMarkerIcon} from "@heroicons/react/outline";
import Media from "react-media";

const MyAddresses = (props) => {

    const [isModalVisible, isModalVisibleHandler] = useState(false);

    let userAddresses;

    const updateAddressCallback = (addressId) => {
        localStorage.setItem('addressId', addressId);
        props.userProfile.userAddresses.map(address => {
            if (address.id === addressId) {
                localStorage.setItem('streetName', address.name);
                localStorage.setItem('number', address.number);
                localStorage.setItem('region', address.region);
                localStorage.setItem('city', address.city);
                localStorage.setItem('postalCode', address.postalCode);
                localStorage.setItem('floor', address.floor === null ? '' : address.floor);
                localStorage.setItem('apartment', address.apartment === null ? '' : address.apartment);
            }
        })
        openModal();
    }

    if (props.userProfile !== null) {
        userAddresses = props.userProfile.userAddresses.map((address) => {
            return (
                <Address key={address.id} address={address} updateAddressCall={updateAddressCallback}/>
            )
        })

        if (userAddresses.length === 0) {
            userAddresses = <p style={{color: "#535358"}}>Nema sačuvanih adresa.</p>
        }
    }

    const openModal = () => {
        isModalVisibleHandler(true);
    }

    const closeModal = () => {
        isModalVisibleHandler(false);
        setTimeout(() => {
            localStorage.removeItem('addressId')
            localStorage.removeItem('streetName');
            localStorage.removeItem('number');
            localStorage.removeItem('region');
            localStorage.removeItem('city');
            localStorage.removeItem('postalCode');
            localStorage.removeItem('floor');
            localStorage.removeItem('apartment');
        }, 1000)
    }

    //snimanje nove adrese
    const saveAddress = (formData) => {

        const userRequest = {
            username: localStorage.getItem("email"),
            name: formData.addressData.streetName,
            number: formData.addressData.number,
            region: formData.addressData.region,
            city: formData.addressData.city,
            postalCode: formData.addressData.postalCode,
            floor: formData.addressData.floor === '' ? null : formData.addressData.floor,
            apartment: formData.addressData.apartment === '' ? null : formData.addressData.apartment
        }
        props.onAddNewUserAddress(userRequest);
        closeModal();
    }

    //update postojece adrese
    const updateAddress = (formData) => {
        closeModal()
        const updateUserRequest = {
            id: localStorage.getItem('addressId'),
            name: formData.addressData.streetName,
            number: formData.addressData.number,
            region: formData.addressData.region,
            city: formData.addressData.city,
            postalCode: formData.addressData.postalCode,
            floor: formData.addressData.floor === '' ? null : formData.addressData.floor,
            apartment: formData.addressData.apartment === '' ? null : formData.addressData.apartment
        }
        props.onUpdateUserAddress(updateUserRequest);
    }

    // matkovi primeri
    // const saveAddress = (event) => {
    //     event.preventDefault();
    //
    //     // //snimanje nove adrese
    //     const userRequest = {
    //         username: localStorage.getItem("email"),
    //         name: "Cvijiceva",
    //         number: "229",
    //         region: "Palilula",
    //         city: "Belgrade",
    //         postalCode: "11000"
    //     }
    //     props.onAddNewUserAddress(userRequest);
    // }

    //brisanje vec postojece adrese
    // props.onDeleteUserAddress(22);

    //update vec postojece adrese
    // const updateUserRequest = {
    //     id: 23,
    //     name: "Gospodara Vucica",
    //     number: "229",
    //     region: "Zvezdara",
    //     city: "Belgrade",
    //     postalCode: "11030"
    // }
    // props.onUpdateUserAddress(updateUserRequest);
    //}

    return (
        <>

        <Media
            queries={{
                small: "(max-width: 699px)",
                large: "(min-width: 700px)"
            }}>
            {matches => (
                <Fragment>
                    {matches.small &&
                    <div className={styles.AddressesHeader}>
                        <div style={{display:"flex", alignItems:"center", color:"#013A63", fontWeight:"bold"}}>
                            <LocationMarkerIcon style={{width:"18px", height:"18px", marginRight:"1rem"}} />
                            Adrese
                        </div>

                        <Button style={{
                            fontSize: "14px",
                            color: "#219EBC",
                            padding: "0",
                            height: "2rem",
                            width: "10rem"

                        }}
                            onClick={openModal}>+ Dodaj novu adresu</Button>
                    </div>
                    }

                    {matches.large &&
                    <Fragment>
                        <div className={styles.AddressesHeader}>
                            <h2>Adrese</h2>

                            <Button style={{
                                fontSize: "14px",
                                color: "#00A4B4",
                                padding: "0"
                            }}
                                onClick={openModal}>+ DODAJ NOVU ADRESU</Button>
                        </div>
                    </Fragment>
                    }

                </Fragment>)}
        </Media>
            <div className={styles.AddressesHolder}>
                {userAddresses}
            </div>
            {isModalVisible &&
            <ModalWithHeader onCloseClick={closeModal}
                             contentStyle={{width: "100%"}}
            >
                <AddAddressForm saveAddress={saveAddress} updateAddressId={localStorage.getItem('addressId')}
                                updateAddress={updateAddress}/>
            </ModalWithHeader>
            }
            <Backdrop show={isModalVisible}
                      backdropClickHandler={closeModal}
                      zIndex={15}
                      backgroundColor="rgba(0, 0, 0, .2)"/>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        isLogedin: state.authPage.token !== null,

        userProfile: state.userProfile.userProfile,
        userProfileError: state.userProfile.userProfileError,
        loadingUserProfile: state.userProfile.loadingUserProfile
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onAddNewUserAddress: (userAddress) => dispatch(actions.addNewUserAddress(userAddress)),
        onUpdateUserAddress: (userAddress) => dispatch(actions.updateUserAddress(userAddress)),
        onDeleteUserAddress: (userAddressId) => dispatch(actions.deleteUserAddress(userAddressId)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAddresses);