import React from "react";
import styles from "./LandingPageForPromo.module.css";
import backImage from "../../assets/promo-image-apoteka.png";
import backImageMobile from "../../assets/promo-image-apoteka-mobile.png";
import google from "../../assets/Google.png";
import apple from "../../assets/Apple.png";
import rfzo from "../../assets/rfzo-logo.png";

function LandingPageForPromo() {
  let isMobile = false;
  let isTablet = false;
  let isDesktop = false;

  if (window.innerWidth < 576) {
    isMobile = true;
  } else if (window.innerWidth > 576 && window.innerWidth < 992) {
    isTablet = true;
  } else {
    isDesktop = true;
  }
  let imageUrl = "";

  if (isMobile) {
    imageUrl = backImageMobile;
  } else {
    imageUrl = backImage;
  }

  return (
    <div className={styles.pageWrapFull}>
      <div className={styles.pageWrap}>
        <img className={styles.image} alt="apoteka" src={imageUrl} />

        <div className={styles.description}>
          {!isTablet && !isMobile && <img className={styles.rfzoImage} alt="rfzo" src={rfzo} />}
          <div className={styles.paragraphWrap}>
            <div className={styles.paragraph}>
              Topla preporuka za hladne zimske dane - <strong>apoteka.rs</strong>, aplikacija putem koje možete pronaći i rezervisati farmaceutske proizvode brzo i jednostavno iz udobnosti svog doma!
              
              <br />
              <br />
              Putem aplikacije <strong>apoteka.rs</strong> omogućeno je organizovati pomoć oko preuzimanja rezervacija, za sve korisnike koji nisu u mogućnosti da to urade sami.            </div>

              <br></br>
              <br></br>

              Aplikacija pruža podršku u preuzimanju terapije iz apoteke direktno do vrata korisnika, bez potrebe za odlaskom u apoteku!

              <br></br>
              <br></br>

              Očuvanje zdravlja starijih je zajednički zadatak svih nas, zato <strong>korisnici penzionerskih kartica</strong> mogu računati na pružanje podrške pri preuzimanju potpuno <strong>besplatno.</strong>

              <br></br>
              <br></br>

              <strong>Preuzmite aplikaciju</strong> i obezbedite pomoć iz apoteke brzo, sigurno i bezbedno, direktno do vrata Vašeg doma.

          </div>

          <div className={styles.storeWrapAndContact}>
            {window.innerWidth > 576 && window.innerWidth < 992 && (
              <img className={styles.rfzoImage} alt="rfzo" src={rfzo} />
            )}

            <div className={styles.storeWrap}>
              <a
                target="_blank"
                rel="noopener noreferrer"
                className={styles.imageWrap}
                href="https://play.google.com/store/apps/details?id=rs.apoteka&hl=en_US">
                <img src={google} />
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                className={styles.imageWrap}
                href="https://apps.apple.com/us/app/apoteka-rs/id1642092872">
                <img src={apple} />
              </a>
            </div>

            <div>Za dodatne informacije pozovite nas besplatno na broj: 0800800700.</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LandingPageForPromo;
