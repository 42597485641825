import React from 'react'
import InformationBoxRight from "../../InformationBoxRight/InformationBoxRight";
import {connect} from "react-redux";

const StorePageMobileAboutUs = props => {
    return (
        <>
        <div>
            <InformationBoxRight selectedStore={props.selectedStore}/>
        </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        selectedStore: state.appPage.selectedStore
    }
}

export default connect(mapStateToProps)(StorePageMobileAboutUs);