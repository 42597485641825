import React, {Fragment, useContext, useEffect, useRef, useState} from "react";
import styles from "./ShopPage.module.css"
import {connect} from "react-redux";
import * as actions from "../../store/actions";
import {withRouter} from "react-router-dom";
import ShopPageHeader from "./ShopPageHeader/ShopPageHeader";
import ArticleItems from './ArticleItems/ArticleItems';
import Toolbar from "../Navigation/Toolbar/Toolbar";
import SideDrawer from "../SideDrawer/SideDrawer";
import Button from "../UI/Buttons/Button/Button";
import ShoppingCartList from "./ShoppingCartList/ShoppingCartList";
import ShopPageActionMenu from "./ShopPageActionMenu/ShopPageActionMenu";
import Wrapper from "../UI/Wrapper/Wrapper";
import LiveChatButton from "../UI/LiveChatButton/LiveChatButton";
import {FuzzySortContext} from "../../store/context/FuzzySort/FuzzySort";
import Media from "react-media";
import HeaderWithTitleAndBackChevron
    from "../../componentsRes/HeaderWithTitleAndBackChevron/HeaderWithTitleAndBackChevron";
import RpModal from "./ShopPageModals/RpModal";
import IsLoginModal from "./ShopPageModals/IsLoginModal";
import ActiveCartModal from "./ShopPageModals/ActiveCartModal";
import StartingNewCartModal from "./ShopPageModals/StartingNewCartModal";
import {ReactComponent as ShoppingCartIcon} from "../../assets/icons/cart_icon.svg";
import {ReactComponent as TrashIcon} from "../../assets/icons/trash_icon.svg";
import FilterBreadcrumbs from "../Filters/FilterBreadcrumbs/FilterBreadcrumbs";
import {isStoreOpenNow} from "../SearchResultsPage/SearchResults/StoreItems/StoreItem/StoreItem";
import {textToUrl} from "../GobalFunctions/GlobalFunctions";
import ShopNotOnlineModal
    from "../SearchResultsPage/SearchResults/StoreItems/StoreItem/ShopUnavailableModal/ShopUnavailableModal";
// import {fetchAllArticlesForSingleShop} from "../../store/actions";

const ShopPage = (props) => {

    // console.log('SHOP FILTER')
    // console.log(props.shopFilter)

    const [isModalVisible, isModalVisibleHandler] = useState(false);
    const [isNewCartAvailable, isNewCartAvailableHandler] = useState(false);
    const filterChanged = useRef(false);

    const fuzzySort = useContext(FuzzySortContext);
    const isFirstRun = useRef(true);

    const [breadcrumbs, breadcrumbsSetter] = useState(null);

    const [showShopIsOfflineModal, setShowShopIsOfflineModal] = useState(false)
    const [showShopIsClosedModal, setShowShopIsClosedModal] = useState(false);

    const [loyaltyFilter, setLoyaltyFilter] = useState(false)
    const [discountFilter, setDiscountFilter] = useState(false)

    useEffect(()=>{
        if(props.filterBreadcrumbs) {
            breadcrumbsSetter(
                <div className={styles.Breadcrumbs}>
                    &nbsp;
                    <div onClick={onFilterBreadcrumbsCategoryClick} className={styles.BreadcrumbText}>{props.filterBreadcrumbs.catName}</div>
                    <div onClick={onFilterBreadcrumbsSubcategoryClick}> {props.filterBreadcrumbs.scat1Name && <span>&nbsp;/ </span>} <span className={styles.BreadcrumbText}>{props.filterBreadcrumbs.scat1Name}</span></div>
                    <div> {props.filterBreadcrumbs.scat2Name && <span>&nbsp;/ </span>} <span className={styles.BreadcrumbText}>{props.filterBreadcrumbs.scat2Name}</span></div>
                </div>
            )
        }
    }, [props.filterBreadcrumbs, props.shopFilter])

    const onFilterBreadcrumbsCategoryClick = () => {
        props.onFilterBreadcrumbs(
            {
                ...props.filterBreadcrumbs,
                scat1: null,
                scat1Name: null,
                scat2: null,
                scat2Name: null
            });
        props.onShopFilterSelection({categoryId: props.filterBreadcrumbs.cat})
    }
    const onFilterBreadcrumbsSubcategoryClick = () => {
        props.onFilterBreadcrumbs(
            {
                ...props.filterBreadcrumbs,
                scat2: null,
                scat2Name: null
            });
        props.onShopFilterSelection({categoryId: props.filterBreadcrumbs.scat1})
    }

    const resetCategories = () => {
        props.onFilterBreadcrumbs(
            {
                cat: null,
                catName: null,
                scat1: null,
                scat1Name: null,
                scat2: null,
                scat2Name: null
            });
        props.onShopFilterSelection(null)
    }

    useEffect(() => {
        if (props.isLogedin) {
            if (props.allShoppingCarts === null) {
                props.onFetchAllShoppingCarts(localStorage.getItem('id'));
            } else {
                if (props.shoppingCart === null) {
                    props.onFetchShoppingCart(localStorage.getItem('id'), props.match.params.branchMarketplaceId, props.match.params.marketplaceId)
                }
            }
            props.onFetchSelectedStore(props.match.params.marketplaceId,props.match.params.branchMarketplaceId)
        }
    }, [])

    useEffect(()=>{
        setLoyaltyFilter(props.loyaltyFilter || localStorage.getItem('articleFilter_loyalty'))
    }, [props.loyaltyFilter])

    useEffect(()=>{
        setDiscountFilter(props.discountFilter || localStorage.getItem('articleFilter_discount'))
    }, [props.discountFilter])

    useEffect(() => {
        if (props.allShoppingCarts !== null && props.allShoppingCarts !== false) {
            if (!window.location.pathname.includes(props.allShoppingCarts[0].marketplaceResponse.branchMarketplaceId + '/' + props.allShoppingCarts[0].marketplaceResponse.marketplaceId)) {
                isModalVisibleHandler(true);
            }
        }
    }, [props.allShoppingCarts])

    useEffect(() => {
        //prilikom ulaska u shop, resetuje se props.shopFilter
        props.onShopFilterSelection({categoryId: null});

        //prilikom ucitavanja shopa, ako ima artikala u korpi tog shopa, korpa ce biti otvorena
        if (props.isLogedin) {
            if (props.allShoppingCarts !== null && props.allShoppingCarts !== false) {
                if (window.location.pathname.includes(props.allShoppingCarts[0].marketplaceResponse.branchMarketplaceId + '/' + props.allShoppingCarts[0].marketplaceResponse.marketplaceId)) {
                    props.onShowShoppingCartDrawer();
                }
            }
        }

        return () => {
            // props.onAllShoppingCarts(null);
            props.onInitShoppingCartAnimation(false);
            props.onResetSelectedStore(null);
            props.onResetOpenHoursForStore(null);
            props.onShopFilterSelection('');
            //todo pogledati metodu ispod
            props.onSearchBarPlaceholderChanged('');
            props.onSelectedStoreChanged(null);
            localStorage.removeItem('selectedArticleFilter');
            localStorage.removeItem('articleFilter_loyalty');
            props.deselectArticle();
            props.onFilterBreadcrumbs(null);
        }
    }, [])

    useEffect(() => {
        if (props.isLogedin) {
            if (props.match.params.searchedArticleId === undefined || props.match.params.searchedArticleId.match(/^[0-9]+$/) !== null) {
                props.onFetchNamesOfAllArticlesForSingleShop(props.match.params.branchMarketplaceId, props.match.params.marketplaceId);
                props.onFetchAllArticlesForSingleShop(props.match.params.branchMarketplaceId, props.match.params.marketplaceId,
                    0, localStorage.getItem('categoryFilter') || props.shopFilter.categoryId, null, loyaltyFilter, discountFilter, props.sortValue,32);
            }
        }

    }, [props.match.params.branchMarketplaceId, props.match.params.marketplaceId, props.shopFilter, props.match.params.searchedArticleId, loyaltyFilter, discountFilter, props.sortValue])

    //todo pokusati spojiti useeffect 4 i 5
    useEffect(() => {
        if (props.isLogedin) {
            if ((props.match.params.searchedArticleId === undefined || !props.match.params.searchedArticleId.match(/^[0-9]+$/) !== null) && props.totalElements !== -1 && props.totalPages !== -1) {
                //stari poziv
                // props.onFetchSliceOfArticlesForSingleShop(props.match.params.branchMarketplaceId, props.match.params.marketplaceId,
                //     props.match.params.page, props.shopFilter.categoryId, props.shopFilter.categoryArray, props.match.params.searchedArticleId !== undefined ? 32 : 32);
                props.onFetchSliceOfArticlesForSingleShop(props.match.params.branchMarketplaceId, props.match.params.marketplaceId,
                    props.match.params.page, localStorage.getItem('categoryFilter') || props.shopFilter.categoryId, null, props.sortValue, 32);
            }
        }

    }, [props.match.params.page, props.match.params.searchedArticleId])

    useEffect(() => {
        if (props.isLogedin) {
            if (props.match.params.searchedArticleId !== undefined) {
                //todo pitati matka
                // props.deselectArticle();
                if (props.match.params.searchedArticleId.match(/^[0-9]+$/) === null) {
                    if (props.matchedItems === null) {
                        props.onFetchNamesOfAllArticlesForSingleShop(props.match.params.branchMarketplaceId, props.match.params.marketplaceId);
                    } else {
                        if (!filterChanged.current) {
                            isFirstRun.current = false;
                            if (props.totalSelectedElements !== -1 && props.totalSelectedPages !== -1) {
                                props.onFetchSliceOfArticlesForSingleShop(props.match.params.branchMarketplaceId, props.match.params.marketplaceId,
                                    props.match.params.page, localStorage.getItem('categoryFilter') || props.shopFilter.categoryId, props.matchedItems, props.sortValue, 32);
                            } else {
                                props.onFetchAllArticlesForSingleShop(props.match.params.branchMarketplaceId, props.match.params.marketplaceId,
                                    props.match.params.page, localStorage.getItem('categoryFilter') || props.shopFilter.categoryId, props.matchedItems, loyaltyFilter, discountFilter, props.sortValue, 32);
                            }
                        } else {
                            filterChanged.current = false;
                        }
                    }
                } else {
                    props.onFetchSelectedArticle(props.match.params.searchedArticleId, props.match.params.branchMarketplaceId, props.match.params.marketplaceId);
                }
            }
        }

    }, [props.match.params.searchedArticleId, props.match.params.page, loyaltyFilter, discountFilter, props.sortValue])

    //izbegavamo pozivanje funcionalnosti ovog useEffect-a kada prvi put ulazimo na stranicu normalno preko main page-a (ne refresh-om iste),
    // ovo radimo preko isFirstRun ref promenjive
    // ako je korisnik refreshovao stranicu , gornji useEffect ce pozvati ponovno ucitavanje svih articlesNames-a.
    // Kada se ucitaju articlesNames, poziva se funkcionalnost ovog useEffect-a koji na osnovu putanje ili radi filter
    // apoteka po nazivu za string iz putanje ili vraca sve apoteke. Zatim poziva back kako bi dobili sve informacije o tim
    //filtriranim apotekama.
    useEffect(() => {
        if (props.isLogedin) {
            if (props.namesOfSingleShopArticles !== null && isFirstRun.current && props.match.params.searchedArticleId !== undefined && props.match.params.searchedArticleId.match(/^[0-9]+$/) === null) {
                const searchStringWords = fuzzySort.separateSearchStringByWords(props.match.params.searchedArticleId);
                const matchingResult = fuzzySort.sortResultsByStartWord(fuzzySort.filterByWords(props.namesOfSingleShopArticles, searchStringWords, 0), searchStringWords[0]);
                props.onMatchedItemsChanged(matchingResult);
                if (props.totalSelectedElements !== -1 && props.totalSelectedPages !== -1) {
                    props.onFetchSliceOfArticlesForSingleShop(props.match.params.branchMarketplaceId, props.match.params.marketplaceId, props.match.params.page, localStorage.getItem('categoryFilter') || props.shopFilter.categoryId, matchingResult, props.sortValue, 32);
                } else {
                    props.onFetchAllArticlesForSingleShop(props.match.params.branchMarketplaceId, props.match.params.marketplaceId, props.match.params.page, localStorage.getItem('categoryFilter') || props.shopFilter.categoryId, matchingResult, loyaltyFilter, discountFilter, props.sortValue, 32);
                }
            }
        }

    }, [props.namesOfSingleShopArticles, loyaltyFilter, discountFilter, props.sortValue]);

    useEffect(() => {
        if (props.isLogedin) {
            if (props.match.params.page !== undefined && props.match.params.searchedArticleId !== undefined && props.match.params.searchedArticleId.match(/^[0-9]+$/) === null) {
                if (props.totalSelectedElements !== -1 && props.totalSelectedPages !== -1) {
                    props.onFetchAllArticlesForSingleShop( props.match.params.branchMarketplaceId, props.match.params.marketplaceId, 0, localStorage.getItem('categoryFilter') || props.shopFilter.categoryId, props.matchedItems, loyaltyFilter, discountFilter, props.sortValue, 32);
                    filterChanged.current = true;
                }
            }
        }

    }, [props.shopFilter, loyaltyFilter, discountFilter, props.sortValue])

    const clearShoppingCartHandler = () => {
        localStorage.removeItem('addressSelected');
        localStorage.removeItem('selectedAddress');
        localStorage.removeItem('haveRp');
        localStorage.removeItem('deliveryMethod');
        localStorage.removeItem('totalPrice');
        props.onClearShoppingCart(props.userInfo.id, props.match.params.branchMarketplaceId, props.match.params.marketplaceId);
        props.onAllShoppingCarts(null);
    }

    const sideDrawerClosedHandler = () => {
        props.onShowShoppingCartDrawer();
    }

    const redirectToShoppingCart = () => {
        if (haveRp) {
            localStorage.setItem('haveRp', 'true');
        }

        let url

        //pod komentarom jer se vise na mobile-u ne preskace prva strana zavrsetka kupovine
        // if(window.innerWidth >= 700) {
        url = '/shoppingCart/' + props.match.params.branchMarketplaceId + '/' + props.match.params.marketplaceId
            + '/' + props.match.params.branchMarketplaceName + '/' + props.match.params.marketplaceName;
        // } else {
        //     url = '/orderCompletion/' + props.match.params.branchMarketplaceId + '/' + props.match.params.marketplaceId + '/delivery';
        // }
        props.history.push(url);
    }

    const isDisabled = () => {
        return !(props.shoppingCart !== null && props.shoppingCart.items.length !== 0);
    }

    const clearCartStyle = isDisabled() ? styles.ClearCartDisabled : styles.ClearCart;

    const [haveRp, haveRpSetter] = useState(false);
    const [checkFinish, checkFinishSetter] = useState(false);
    const [isRpModalVisible, isRpModalVisibleHandler] = useState(false);
    const [fromShoppingCart, setFromShoppingCart] = useState(false);

    const closeRpModal = () => {
        isRpModalVisibleHandler(false);
        haveRpSetter(false);
        checkFinishSetter(false);
    }

    const checkRp = () => {
        checkFinishSetter(false);
        haveRpSetter(false);
        if (props.shoppingCart !== null) {
            props.shoppingCart.items.map((item) => {
                if (item.itemResponse.itemAvailabilityCode && item.itemResponse.itemAvailabilityCode !== 'BR' && item.itemResponse.itemAvailabilityCode !== 'MS') {
                    haveRpSetter(true);
                }
            })
        }
        checkFinishSetter(true);
    }


    // poziva se kada se udje u apoteku i proverava da li je apoteka otvorena i da li je online
    useEffect(() => {
        if (props.selectedStore && !isStoreOpenNow(props.selectedStore.marketplaceResponse.openingHoursResponse)) {
            setShowShopIsClosedModal(true)
        } else if (props.selectedStore && props.selectedStore.marketplaceResponse && props.selectedStore.marketplaceResponse.isClickAndCollect === 1 && props.selectedStore.marketplaceResponse.takesOrders === 0) {
            setShowShopIsOfflineModal(true)
        } else if (props.selectedStore && isStoreOpenNow(props.selectedStore.marketplaceResponse.openingHoursResponse) &&
            (props.selectedStore && props.selectedStore.marketplaceResponse && props.selectedStore.marketplaceResponse.isClickAndCollect === 1 && props.selectedStore.marketplaceResponse.takesOrders === 1)
        && fromShoppingCart) {
            checkRp()
        }
    }, [props.selectedStore])


    const checkIfShopIsClosed = () => {
        setFromShoppingCart(true);
        //ako apoteka ne radi ili nije online, otvara se modal
        if (!isStoreOpenNow(props.selectedStore.marketplaceResponse.openingHoursResponse)) {
            setShowShopIsClosedModal(true);
        } else {
            props.onFetchSelectedStore(props.selectedStore.marketplaceResponse.marketplaceId,props.selectedStore.marketplaceResponse.branchMarketplaceId)
        }
    }

    const proceedToOrder = () => {
        setShowShopIsClosedModal(false);
        setShowShopIsOfflineModal(false)
        checkRp();
    }

    useEffect(() => {
        if (haveRp) {
            isRpModalVisibleHandler(true);
        }
    }, [haveRp])

    useEffect(() => {
        if (checkFinish) {
            if (!haveRp) {
                redirectToShoppingCart();
                checkFinishSetter(false);
            }
        }
    }, [checkFinish])

    const [isLoginModalVisible, isLoginModalVisibleHandler] = useState(true);

    const getBack = () => {
        isLoginModalVisibleHandler(false);
        let url = localStorage.getItem("beforeEnteringStore");
        props.history.replace(url);
    }

    const openLoginModal = () => {
        isLoginModalVisibleHandler(true);
    }

    const closeLoginModal = () => {
        isLoginModalVisibleHandler(false);
    }

    const redirectToLogin = () => {
        localStorage.setItem('beforeLogin', window.location.pathname);
        props.history.push("/auth/login");
    }

    const onBackdropClicked = () => {
        if (isLoginModalVisible) {
            closeLoginModal();
        } else if (isRpModalVisible) {
            closeRpModal();
        }
    }

    const backToActiveCart = () => {
        if (props.allShoppingCarts !== null) {
            isModalVisibleHandler(false);
            let url = '/shop/' + props.allShoppingCarts[0].marketplaceResponse.branchMarketplaceId + '/' + props.allShoppingCarts[0].marketplaceResponse.marketplaceId + '/0'+ '/' + textToUrl(props.allShoppingCarts[0].marketplaceResponse.branchMarketplaceResponse.name) + '/' + textToUrl(props.allShoppingCarts[0].marketplaceResponse.name);
            props.onFetchSelectedStore(props.allShoppingCarts[0].marketplaceResponse.branchMarketplaceId, props.allShoppingCarts[0].marketplaceResponse.marketplaceId);
            // props.onAllShoppingCarts(null);
            props.history.replace(url);
        }
    }

    const clearActiveShoppingCart = () => {
        if (props.allShoppingCarts !== null) {
            props.onClearShoppingCart(props.userInfo.id, props.allShoppingCarts[0].marketplaceResponse.branchMarketplaceId, props.allShoppingCarts[0].marketplaceResponse.marketplaceId);
            props.onAllShoppingCarts(null);
        }
        isModalVisibleHandler(false);
        isNewCartAvailableHandler(true);
        localStorage.removeItem('haveRp');
    }

    const closeModal = () => {
        isNewCartAvailableHandler(false);
    }

    const cancelModal = () => {
        isModalVisibleHandler(false);
        props.history.replace(localStorage.getItem("beforeEnteringStore"));
        localStorage.removeItem('beforeEnteringStore');
    }

    const closeShopNotOnlineModal = () => {
       setShowShopIsClosedModal(false);
       setShowShopIsOfflineModal(false);
    }

    return (

        <React.Fragment>
            <Wrapper>
                <Toolbar/>
                <LiveChatButton/>
                <ShopPageHeader/>
                <ShopPageActionMenu badActionClicked={openLoginModal}
                />
                {props.filterBreadcrumbs.cat &&
                    <FilterBreadcrumbs onResetClick={resetCategories}>
                        <div>{breadcrumbs}</div>
                    </FilterBreadcrumbs>
                }
                <ArticleItems articles={props.singleShopArticles}
                              loadingArticles={props.loadingSingleShopArticles}
                              articlesFailed={props.singleShopArticlesFailed}
                              totalPages={props.match.params.searchedArticleId !== undefined && props.match.params.searchedArticleId.match(/^[0-9]+$/) === null ? props.totalSelectedPages : props.totalPages}
                              totalElements={props.match.params.searchedArticleId !== undefined && props.match.params.searchedArticleId.match(/^[0-9]+$/) === null ? props.totalSelectedElements : props.totalElements}
                              selectedArticles={props.selectedArticles}
                              selectedArticlesFailed={props.selectedArticlesFailed}
                              loadingSelectedArticles={props.loadingSelectedArticles}
                              searchedParams={props.match.params.searchedArticleId}
                              onBlurImageClick={openLoginModal}
                    // quickBuy={localStorage.getItem('quickBuy')}
                    // alreadyHaveShoppingCart={isLoginModalVisible && true}
                />

                <Media
                    queries={{
                        small: "(max-width: 699px)",
                        large: "(min-width: 700px)"
                    }}>

                    {matches => (
                        <Fragment>
                            {matches.small &&
                                <SideDrawer
                                    sideDrawerExtraStyle={{
                                        top: "0",
                                        left: "0",
                                        height: "100vh",
                                        width: "100vw",
                                        boxShadow: "0 0 1.2rem #acaaaa",
                                    }}
                                    backdrop={true}
                                    open={props.drawerToggleClicked}
                                    closed={sideDrawerClosedHandler}>
                                    <HeaderWithTitleAndBackChevron icon onIconClick={sideDrawerClosedHandler}
                                                                   title="Pregled korpe"/>
                                    <ShoppingCartList/>
                                    <div className={styles.CartActionsHolder}>
                                        <Button onClick={clearShoppingCartHandler} className={clearCartStyle} disabled={isDisabled()}>
                                            Isprazni korpu
                                        </Button>
                                        <Button buttontype={isDisabled() ? 'disabled' : 'blue'}
                                                onClick={checkIfShopIsClosed}
                                                disabled={isDisabled()}
                                                style={{height: "42px"}}
                                        >
                                            Zaključi rezervaciju
                                        </Button>
                                    </div>
                                </SideDrawer>
                            }

                            {matches.large &&
                                <SideDrawer
                                    sideDrawerExtraStyle={{
                                        top: "140px",
                                        right: "20px",
                                        height: "calc(100vh - 6.875rem - 5rem - 1rem",
                                        boxShadow: "0 0 1.2rem #acaaaa",
                                        width: "450px",
                                    }}
                                    backdrop={true}
                                    open={props.drawerToggleClicked}
                                    closed={sideDrawerClosedHandler}>
                                    <div className={styles.CartHeader}>
                                        <span>Vaša korpa</span>
                                        <ShoppingCartIcon className={styles.IconStyle}/>
                                    </div>
                                    <ShoppingCartList/>
                                    <div className={styles.CartActionsHolder}>
                                        <Button onClick={clearShoppingCartHandler} className={clearCartStyle} disabled={isDisabled()}>
                                            Isprazni korpu
                                            <TrashIcon />
                                        </Button>
                                        <Button buttontype={isDisabled() ? 'disabled' : 'blue'}
                                                onClick={checkIfShopIsClosed}
                                                disabled={isDisabled()}
                                        >
                                            Zaključi rezervaciju
                                        </Button>
                                    </div>
                                </SideDrawer>
                            }
                        </Fragment>
                    )}
                </Media>

                {isRpModalVisible &&
                    <RpModal
                        onCloseClick={closeRpModal}
                        show={isRpModalVisible || !props.isLogedin && isLoginModalVisible}
                        backdropClickHandler={onBackdropClicked}
                        haveRp={redirectToShoppingCart}
                        dontHaveRp={closeRpModal}
                    />
                }

                {!props.isLogedin && isLoginModalVisible &&
                    <IsLoginModal onCloseClick={closeLoginModal}
                                  show={isLoginModalVisible}
                                  backdropClickHandler={closeLoginModal}
                                  notLoggedIn={redirectToLogin}
                                  cancel={getBack}
                    />
                }
            </Wrapper>

            {isModalVisible &&
                <ActiveCartModal onCloseClick={cancelModal}
                                 show={isModalVisible}
                                 backdropClickHandler={cancelModal}
                                 toActiveCart={backToActiveCart}
                                 clearActiveCart={clearActiveShoppingCart}
                />
            }

            {isNewCartAvailable &&
                <StartingNewCartModal
                    onCloseClick={closeModal}
                    show={isNewCartAvailable}
                    backdropClickHandler={closeModal}
                />
            }

            {(showShopIsClosedModal || showShopIsOfflineModal) && props.selectedStore &&
                <ShopNotOnlineModal
                    show={showShopIsOfflineModal || showShopIsClosedModal}
                    onCloseClick={closeShopNotOnlineModal}
                    shop={props.selectedStore && props.selectedStore}
                    fromShoppingCart={fromShoppingCart}
                    callCheckRp={checkRp}
                    shopIsClosed={showShopIsClosedModal}
                    shopIsOffline={showShopIsOfflineModal}
                    backdropClickHandler={closeShopNotOnlineModal}
                    proceedToNextStep={proceedToOrder}
                />
            }

        </React.Fragment>
    );
}

const mapStateToProps = (state) => {
    return {
        drawerToggleClicked: state.toolbar.shoppingCardDrawer,
        shoppingCart: state.shopPage.shoppingCart,
        userInfo: state.authPage.userInfo,
        allShoppingCarts: state.shopPage.allShoppingCarts,
        loadingAllShoppingCarts: state.shopPage.loadingAllShoppingCarts,
        allShoppingCartsFailed: state.shopPage.allShoppingCartsFailed,

        singleShopArticles: state.shopPage.singleShopArticles,
        loadingSingleShopArticles: state.shopPage.loadingSingleShopArticles,
        singleShopArticlesFailed: state.shopPage.singleShopArticlesFailed,
        totalElements: state.shopPage.totalSingleShopArticleElements,
        totalPages: state.shopPage.totalSingleShopArticlePages,

        selectedArticles: state.shopPage.selectedArticles,
        selectedArticlesFailed: state.shopPage.selectedArticlesFailed,
        loadingSelectedArticles: state.shopPage.loadingSelectedArticles,
        totalSelectedElements: state.shopPage.totalSelectedElements,
        totalSelectedPages: state.shopPage.totalSelectedPages,

        matchedItems: state.mainPage.matchedItems,
        // isLogedin: state.authPage.token !== null,
        isLogedin: localStorage.getItem("token") !== null,

        namesOfSingleShopArticles: state.shopPage.namesOfSingleShopArticles,

        shopFilter: state.filter.shopFilter,
        articleCategoryDetails: state.filter.articleCategoryDetails,
        filterBreadcrumbs: state.filter.filterBreadcrumbs,

        selectedStore: state.appPage.selectedStore,

        loyaltyFilter: state.filter.loyaltyFilter,
        discountFilter: state.filter.discountFilter,

        sortValue: state.shopPage.sortValue
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onShowShoppingCartDrawer: () => dispatch(actions.showShoppingCartDrawerChanged()),
        onClearShoppingCart: (userId, branchMarketplaceId, marketplaceId) => dispatch(actions.clearShoppingCartAction(userId, branchMarketplaceId, marketplaceId)),
        onAllShoppingCarts: (allShoppingCarts) => dispatch(actions.allShoppingCarts(allShoppingCarts)),
        onFetchSelectedStore: (branchMarketplaceId, marketplaceId, checkIfTakesOrders) => dispatch(actions.fetchSelectedStore(branchMarketplaceId, marketplaceId, checkIfTakesOrders)),
        onInitShoppingCartAnimation: (value) => dispatch(actions.initiateAddToShoppingCartAnimation(value)),

        onResetSelectedStore: (value) => dispatch(actions.selectedStoreChanged(value)),
        onResetOpenHoursForStore: (value) => dispatch(actions.openHoursForStore(value)),

        deselectArticle: () => dispatch(actions.deselectArticle()),

        onFetchAllArticlesForSingleShop: (branchMarketplaceId, marketplaceId, page, categoryId, targetArray, loyaltyFilter, discountFilter, sortState, numberOfReturns) => dispatch(actions.fetchAllArticlesForSingleShop(branchMarketplaceId, marketplaceId, page, categoryId, targetArray, loyaltyFilter, discountFilter, sortState, numberOfReturns)),
        onFetchSliceOfArticlesForSingleShop: (branchMarketplaceId, marketplaceId, page, categoryId, targetArray, sortState, numberOfReturns) => dispatch(actions.fetchSliceOfArticlesForSingleShop(branchMarketplaceId, marketplaceId, page, categoryId, targetArray, sortState, numberOfReturns)),
        onFetchNamesOfAllArticlesForSingleShop: (branchMarketplaceId, marketplaceId) => dispatch(actions.fetchNamesOfAllArticlesForSingleShop(branchMarketplaceId, marketplaceId)),
        onFetchSelectedArticle: (selectedArticleId, branchMarketplaceId, marketplaceId) => dispatch(actions.fetchSelectedArticle(selectedArticleId, branchMarketplaceId, marketplaceId)),

        onShopFilterSelection: (shopFilter) => dispatch(actions.shopFilterSelection(shopFilter)),

        onSearchBarPlaceholderChanged: (newString) => dispatch(actions.searchBarPlaceholderChanged(newString)),

        onMatchedItemsChanged: (matchedItems) => dispatch(actions.matchedItemsChanged(matchedItems)),

        onSelectedStoreChanged: (selectedStore) => dispatch(actions.selectedStoreChanged(selectedStore)),

        onFetchAllShoppingCarts: (userId) => dispatch(actions.fetchAllShoppingCarts(userId)),
        onFetchShoppingCart: (userId, branchMarketplaceId, marketplaceId) => dispatch(actions.fetchShoppingCart(userId, branchMarketplaceId, marketplaceId)),
        onFilterBreadcrumbs: (filterBreadcrumbs) => dispatch(actions.filterBreadcrumbs(filterBreadcrumbs)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ShopPage));