import React, {useState} from 'react';
import styles from './AddArticleToTherapyModal.module.css';
import ModalWithHeader from "../ModalWithHeader/ModalWithHeader";
import Button from "../../Buttons/Button/Button";
import Input from "../../Input/Input";
import * as actions from "../../../../store/actions";
import {connect} from "react-redux";

const AddArticleToTherapyModal = (props) => {

    const [itemData, setItemData] = useState({
        item: props.editItem ? props.selectedItem.item : props.item,
        quantity: props.editItem ? props.selectedItem.quantity : 1,
        dosing: props.editItem ? props.selectedItem.dosing : null ,
        frequency: props.editItem ? props.selectedItem.frequency : null,
        duration: props.editItem ? props.selectedItem.duration : null
    })

    const incrementQuantity = () => {
        let temp = itemData.quantity;
        setItemData({
            ...itemData,
            quantity: temp+1
        })
    }

    const decrementQuantity = () => {
        let temp = itemData.quantity
        if (itemData.quantity > 1) {
            setItemData({
                ...itemData,
                quantity: temp-1
            })
        }
    }

    const editItemQuantity = (e) => {
        let temp = parseInt(e.target.value)
        setItemData({
            ...itemData,
            quantity: temp
        })
    }

    const setDosing =(e)=> {
        setItemData({
            ...itemData,
            dosing: e.target.value
        })
    }

    const setFrequency =(e)=> {
        setItemData({
            ...itemData,
            frequency: e.target.value
        })
    }

    const setDuration =(e)=> {
        setItemData({
            ...itemData,
            duration: e.target.value
        })
    }

    const addArticleToList = () => {
        props.onAddItemToTherapy(itemData)
        props.onCloseClick();
    }

    const onClickSelect =(e)=> {
        e.target.select();
    }

    return (
        <ModalWithHeader onCloseClick={props.onCloseClick}
                         style={{paddingBottom: "1rem"}}
                         show={props.show}
                         backdropClickHandler={props.onCloseClick}
                         buttons={
                             <Button buttontype='blue' style={{margin:"auto"}} onClick={addArticleToList}>{props.editItem ? "Sačuvaj izmene" : "Dodaj u terapiju"}</Button>
                         }
        >

                <div>
                    <div style={{display: "flex", justifyContent: "space-between"}}>
                        <div>
                            <div>
                                <strong style={{fontSize: "20px"}}>{itemData.item.name}</strong>
                            </div>
                            <div> {itemData.item.manufacturerName}</div>
                        </div>
                        <div style={{display: "flex", alignItems:"center"}}>
                            <Button className={styles.QuantityButton} onClick={decrementQuantity}>-</Button>&nbsp;
                            <Input autocomplete="off"
                                          value={itemData.quantity}
                                          onChange={e => editItemQuantity(e)}
                                          className={styles.QuantityInput}
                                          type="number"
                                          onClick={onClickSelect}
                            />&nbsp;
                            <Button className={styles.QuantityButton} onClick={incrementQuantity}>+</Button>
                        </div>
                    </div>
                    <br />
                    <label>Doza</label>
                    <Input
                        as="textarea"
                        value={itemData.dosing}
                        autocomplete="off"
                        onChange={(e) => setDosing(e)}
                    />
                    <br/>
                    <label>Frekvencija</label>
                    <Input
                        as="textarea"
                        value={itemData.frequency}
                        autocomplete="off"
                        onChange={(e) => setFrequency(e)}
                    />
                    <br/>
                    <label>Trajanje</label>
                    <Input
                        as="textarea"
                        value={itemData.duration}
                        autocomplete="off"
                        onChange={(e) => setDuration(e)}
                    />

                </div>
        </ModalWithHeader>
    );
};

const mapStateToProps = (state) => {
    return {
        // userTherapies: state.userProfile.userTherapies
        selectedItem: state.userProfile.selectedItem,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        // onFetchUserTherapies: (userId) => dispatch(actions.fetchUserTherapies(userId)),
        onAddItemToTherapy: (item) => dispatch(actions.addArticleToTherapy(item))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddArticleToTherapyModal);
