import * as actionsTypes from './actionsTypes'
import axios from '../../axios/axios-apoteka'

//akcije za fetch artikala koji odgovaraju unetom stringu u search bar-u
export const allSearchedArticles = (articles) => {
    return {
        type: actionsTypes.ALL_SEARCHED_ARTICLES,
        searchedArticles: articles

    };
}

export const sliceOfSearchedArticles = (articles) => {
    return {
        type: actionsTypes.SLICE_OF_SEARCHED_ARTICLES,
        searchedArticles: articles

    };
}

export const fetchAllSearchedArticlesFailed = () => {
    return {
        type: actionsTypes.FETCH_ALL_SEARCHED_ARTICLES_FAIlED
    };
}

export const fetchAllSearchedArticlesServerError = () => {
    return {
        type: actionsTypes.FETCH_ALL_SEARCHED_ARTICLES_SERVER_ERROR
    };
}

export const fetchAllSearchedArticlesStart = () => {
    return {
        type: actionsTypes.FETCH_ALL_SEARCHED_ARTICLES_START
    };
}


export const fetchAllSearchedArticles = (articlesIds, page, itemFilter='OTHER_ITEM') => {
    return dispatch => {
        dispatch(fetchAllSearchedArticlesStart());
        const URL = '/item/page/' + page + '?numOfReturns=32' + '&itemFilter=' + itemFilter;
        axios.post(URL, articlesIds)
            .then(response => {
                dispatch(allSearchedArticles(response.data))
                // console.log(response);
            })
            .catch(error => {
                if (error.response !== null && error.response !== undefined  && error.response.data === "") {
                    dispatch(fetchAllSearchedArticlesFailed())
                }
                else {
                    dispatch(fetchAllSearchedArticlesServerError());
                }
            })
    };
}

export const fetchSliceOfSearchedArticles = (articlesIds, page, itemFilter='OTHER_ITEM') => {
    return dispatch => {
        dispatch(fetchAllSearchedArticlesStart());
        const URL = '/item/slice/' + page + '?numOfReturns=32' + '&itemFilter=' + itemFilter;
        axios.post(URL, articlesIds)
            .then(response => {
                dispatch(sliceOfSearchedArticles(response.data))
                // console.log(response);
            })
            .catch(error => {
                if (error.response !== null && error.response !== undefined  && error.response.data === "") {
                    dispatch(fetchAllSearchedArticlesFailed())
                }
                else {
                    dispatch(fetchAllSearchedArticlesServerError());
                }
            })
    };
}

// akcije za fetch svih artikala
export const allArticles = (articles) => {
    return {
        type: actionsTypes.ALL_ARTICLES_OBJECTS,
        articles: articles

    };
}

export const sliceOfArticles = (articles) => {
    return {
        type: actionsTypes.SLICE_OF_ALL_ARTICLES_OBJECTS,
        articles: articles

    };
}

export const fetchAllArticlesFailed = () => {
    return {
        type: actionsTypes.FETCH_ALL_ARTICLES_OBJECTS_FAIlED
    };
}

export const fetchAllArticlesServerError = () => {
    return {
        type: actionsTypes.FETCH_ALL_ARTICLES_OBJECTS_SERVER_ERROR
    };
}

export const fetchAllArticlesStart = () => {
    return {
        type: actionsTypes.FETCH_ALL_ARTICLES_OBJECTS_START
    };
}

//staro -  za itemFilter prima jedan od parametara OTHER_ITEM, PRESCRIPTION_DRUG_ITEM, SUPPLEMENT_ITEM, DRUG_ITEM
//kategorije -- /item/page/0?numOfReturns=32&groupId=1&filter=id in (2757,5993,6,9228,26,2666)
export const fetchAllArticles = (page, categoryId=null, targetArray= null, loyaltyFilter = false) => {
    return dispatch => {
        let URL
        let filter;
        let targetArrayString = "";
        if (targetArray !== null){
            dispatch(fetchAllSearchedArticlesStart());
            targetArrayString = "(";
            targetArray.forEach(target => {
                targetArrayString = targetArrayString.concat(target.id);
                targetArrayString = targetArrayString.concat(",");
            })
            targetArrayString = targetArrayString.slice(0, -1);
            targetArrayString = targetArrayString.concat(")");

        }
        else {
            dispatch(fetchAllArticlesStart());
        }

        if (targetArrayString !== "" || loyaltyFilter) {
            filter = "&filter=";
            if (targetArrayString !== "") {
                filter += "id in " + targetArrayString;
                if(loyaltyFilter) {
                    filter += " and itemLoyalityPoints > 0";
                }
            } else {
                filter += "itemLoyalityPoints > 0"
            }
        } else {
            filter = ""
        }

        URL = '/item/page/'+ page + "?numOfReturns=32" + (categoryId !== null ? '&groupId=' + categoryId : "") + filter;
        axios.get(URL)
            .then(response => {
                targetArray !== null ? dispatch(allSearchedArticles(response.data)) :  dispatch(allArticles(response.data));
            })
            .catch(error => {
                if (error.response !== null && error.response !== undefined  && error.response.data === "") {
                    targetArray !== null ? dispatch(fetchAllSearchedArticlesFailed()) : dispatch(fetchAllArticlesFailed());
                }
                else {
                    dispatch(fetchAllArticlesServerError());
                }
            })
    };
}

//staro -- za itemFilter prima jedan od parametara OTHER_ITEM, PRESCRIPTION_DRUG_ITEM, SUPPLEMENT_ITEM, DRUG_ITEM
//kategorije -- /item/slice/0?numOfReturns=32&groupId=1&filter=id in (2757,5993,6,9228,26,2666)
export const fetchSliceOfArticles = (page, categoryId=null, targetArray= null, loyaltyFilter = false) => {
    return dispatch => {

        let URL;
        let filter;
        let targetArrayString = "";
        if (targetArray !== null){
            dispatch(fetchAllSearchedArticlesStart());
            targetArrayString = "(";
            targetArray.forEach(target => {
                targetArrayString = targetArrayString.concat(target.id);
                targetArrayString = targetArrayString.concat(",");
            })
            targetArrayString = targetArrayString.slice(0, -1);
            targetArrayString = targetArrayString.concat(")");

        }
        else {
            dispatch(fetchAllArticlesStart());
        }

        if (targetArrayString !== "" || loyaltyFilter) {
            filter = "&filter=";
            if (targetArrayString !== "") {
                filter += "id in " + targetArrayString;
                if(loyaltyFilter) {
                    filter += " and itemLoyalityPoints > 0";
                }
            } else {
                filter += "itemLoyalityPoints > 0"
            }
        } else {
            filter = ""
        }

        URL = '/item/slice/'+ page + "/?numOfReturns=32"  + (categoryId !== null ? '&groupId=' + categoryId : "") + filter;
        axios.get(URL)
            .then(response => {
                targetArray !== null ? dispatch(sliceOfSearchedArticles(response.data)) : dispatch(sliceOfArticles(response.data));
                // console.log(response);
            })
            .catch(error => {
                if (error.response !== null && error.response !== undefined  && error.response.data === "") {
                    targetArray !== null ?   dispatch(fetchAllSearchedArticlesFailed()) :   dispatch(fetchAllArticlesFailed());
                }
                else {
                    dispatch(fetchAllSearchedArticlesServerError());
                }
            })
    };
}

export const resetArticles = () => {
    return {
        type: actionsTypes.RESET_ARTICLES,
    };
}