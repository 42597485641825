import * as actionsTypes from '../actions/actionsTypes'

const initialState = {
    stores: null,
    distances: null,
    totalElements: -1,
    totalPages: -1,
    storesError: false,
    storesServerError: false,
    loadingStores: false,

    //stores for rfzo modal
    storesForModal: null,
    storesForModalDistances: null,
    loadingStoresForModal: false,
    loadingStoresForModalFailed: false

}

const storesSearchReducer = (state = initialState, action) => {
    switch (action.type) {

        case actionsTypes.ALL_SEARCHED_STORES:
            return {
                ...state,
                loadingStores: false,
                stores: action.stores.list,
                distances: null,
                totalElements: action.stores.totalElements,
                totalPages: action.stores.totalPages,
                storesError: false,
                storesServerError: false

            }
        case actionsTypes.SLICE_OF_SEARCHED_STORES:
            return {
                ...state,
                loadingStores: false,
                stores: action.stores,
                distances: null,
                storesError: false,
                storesServerError: false

            }
        case actionsTypes.ALL_SEARCHED_STORES_IN_RADIUS:
            return {
                ...state,
                loadingStores: false,
                stores: action.stores.stores,
                distances: action.stores.distances,
                totalElements: action.stores.stores.length,
                totalPages: 1,
                storesError: false,
                storesServerError: false

            }
        case actionsTypes.FETCH_ALL_SEARCHED_STORES_FAIlED:
            return {
                ...state,
                stores: null,
                distances: null,
                totalElements: -1,
                totalPages: -1,
                storesError: true,
                loadingStores: false,
                storesServerError: false
            }

        case actionsTypes.FETCH_ALL_STORES_FOR_ARTICLE_SERVER_ERROR:
            return {
                ...state,
                stores: null,
                distances: null,
                totalElements: -1,
                totalPages: -1,
                storesError: false,
                loadingStores: false,
                storesServerError: true
            }

        case actionsTypes.FETCH_ALL_SEARCHED_STORES_START:
            return {
                ...state,
                loadingStores: true,
                storesServerError: false,
                storesError: false
            }


        case actionsTypes.ALL_STORES_OBJECTS:
            return {
                ...state,
                loadingStores: false,
                stores: action.stores.list,
                distances: null,
                totalElements: action.stores.totalElements,
                totalPages: action.stores.totalPages,
                storesError: false,
                storesServerError: false
            }
        case actionsTypes.SLICE_OF_ALL_STORES_OBJECTS:
            return {
                ...state,
                stores: action.stores,
                distances: null,
                loadingStores: false,
                storesError: false,
                storesServerError: false
            }
        case actionsTypes.ALL_STORES_IN_RADIUS:
            return {
                ...state,
                loadingStores: false,
                stores: action.stores.stores,
                distances: action.stores.distances,
                totalElements: action.stores.totalElements,
                totalPages: action.stores.totalPages,
                storesError: false,
                storesServerError: false
            }

        case actionsTypes.FETCH_ALL_STORES_OBJECTS_FAILED:
            return {
                ...state,
                stores: null,
                loadingStores: false,
                storesError: true,
                storesServerError: false,
                distances: null,
                totalElements: -1,
                totalPages: -1
            }
        case actionsTypes.FETCH_ALL_STORES_OBJECTS_SERVER_ERROR:
            return {
                ...state,
                stores: null,
                distances: null,
                totalElements: -1,
                totalPages: -1,
                storesError: false,
                loadingStores: false,
                storesServerError: true
            }
        case actionsTypes.FETCH_ALL_STORES_OBJECTS_START:
            return {
                ...state,
                loadingStores: true,
                storesError: false,
                storesServerError: false
            }
        case actionsTypes.FETCH_ALL_STORES_FOR_MODAL:
            return {
                ...state,
                storesForModal: action.storesForModal.stores,
                storesForModalDistances: action.storesForModal.distances,
                loadingStoresForModal: false,
                loadingStoresForModalFailed: false
        }
        case actionsTypes.FETCH_ALL_STORES_FOR_MODAL_START:
            return {
                ...state,
                storesForModal: null,
                storesForModalDistances: null,
                loadingStoresForModal: true,
                loadingStoresForModalFailed: false
            }
        case actionsTypes.FETCH_ALL_STORES_FOR_MODAL_FAILED:
            return {
                ...state,
                storesForModal: null,
                storesForModalDistances: null,
                loadingStoresForModal: false,
                loadingStoresForModalFailed: true
            }

        default:
            return state;
    }
}

export default storesSearchReducer;
