import React, {useEffect, useRef, useState} from "react";
import styles from './PrescriptionImageUploader.module.css';
import Button from "../../../../UI/Buttons/Button/Button";
import * as actions from "../../../../../store/actions";
import {connect} from "react-redux";
import { withRouter } from "react-router-dom";
import ImageUploader from "react-images-upload";
import Spinner from '../../../../UI/Spinner/Spinner'
import Modal from "../../../../UI/Modals/Modal/Modal";

const PrescriptionImageUploader = (props) => {

    const [uploadFinished, uploadFinishedHandler] = useState(false);

    useEffect(()=>{
        if(uploadFinished === true) {
            if(!props.loadingPrescriptionsUpload) {
                props.closeModal();
                uploadFinishedHandler(false);
            }
        }
    }, [uploadFinished, props.loadingPrescriptionsUpload])

    //Metode i state za slike recepta
    const [prescriptionPictures, setPrescriptionPictures] = useState([]);
    const onDropPrescriptionPictures = picture => {
        setPrescriptionPictures([...picture]);
        // props.onUpdatePictures(picture.map((picture) => {
        //     return picture.name
        // }));
    };
    const pictureElement = useRef(null);

    // function toClear(){
    //     pictureElement.current.clearPictures();
    //     setPrescriptionPictures([]);
    //     // props.onUpdatePictures([]);
    // }

    const savePrescriptionHandler = (e) => {
        e.preventDefault();
        const data = new FormData();
        prescriptionPictures.forEach(prescription => data.append('files', prescription));
        props.onUploadPrescriptions(localStorage.getItem("id"), props.match.params.branchMarketplaceId, props.match.params.marketplaceId, data);
        uploadFinishedHandler(true);
    }

    const uploadInProgressMessage =
        <div className={styles.UploadProgressMessage}>
            <p>Otpremanje fotografije u toku, molimo sačekajte...</p>
            <Spinner />
        </div>

    return (

        <Modal className={styles.Modal}>
            <div className={styles.ImagesUploadHolder}>
                <ImageUploader
                    {...props}
                    ref={pictureElement}
                    className={styles.ImageUploader}
                    fileContainerStyle={{padding:"10px", boxShadow:"none", margin:"0", borderRadius:"3px"}}
                    withIcon={true}
                    onChange={onDropPrescriptionPictures}
                    imgExtension={[".jpg", ".gif", ".png", ".jpeg"]}
                    maxFileSize={10485760}
                    withPreview={true}
                    singleImage={false}
                    fileSizeError={"Slika koju ste izabrali je previše velika"}
                    fileTypeError={"Izabrani dokument nije odgovarajućeg formata"}
                    buttonText={'Izaberi sliku recepta'}
                    label={'Maksimalna veličina slike: 10mb, prima formate: jpg, jpeg, png, gif'}
                    buttonStyles={{backgroundColor:"#013a63ff", borderRadius:"6px", color:"white", border:"none", fontWeight:"bold", padding:"1rem"}}
                    labelStyles={{fontSize:"14px"}}
                />
            </div>

            <div className={styles.ButtonsHolder}>
                <Button buttontype='blue' onClick={props.closeModal}>Zatvori modal</Button>
                {props.loadingPrescriptionsUpload ? uploadInProgressMessage : (props.uploadPrescriptionsFailed ? <p>Desila se greška</p> : <Button buttontype='lightblue' onClick={savePrescriptionHandler}>Sačuvaj recepte</Button>)}
            </div>
        </Modal>
    );
}

const mapStateToProps = (state) => {
    return {
        uploadPrescriptionsFailed: state.shopPage.uploadPrescriptionsFailed,
        loadingPrescriptionsUpload: state.shopPage.loadingPrescriptionsUpload
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onUploadPrescriptions: (userId, branchMarketplaceId, marketplaceId, prescriptions) => dispatch(actions.uploadPrescriptions(userId, branchMarketplaceId, marketplaceId, prescriptions))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PrescriptionImageUploader));

