import React, {useEffect, useRef, useState} from 'react';
import styles from "../SearchBar.module.css";
import { connect } from 'react-redux';
import {withRouter} from "react-router-dom";
import * as actions from "../../../../../store/actions";

const SearchInput = (props) => {

    const fieldRef = useRef(null);

    const [searchInputState, searchInputStateChanged] = useState({
        typing: false,
        typingTimeout: 0,
        inputString: '',
        typingString: ''
    });

    //Ako smo izabrali neki novi lek, onda zatvoriti padajuci menu search input-a (SearchItems).
    useEffect(() => {
        fieldRef.current.value = "";
        fieldRef.current.blur();
        props.openList(false);
    },[props.match.params])

    const onBlurHandler = () => {
        props.onIsSearchInputInFocus(false);
        setTimeout(()=> {
            if (fieldRef.current !== null){
                fieldRef.current.value = "";
                fieldRef.current.blur();
                props.openList(false);
            }

        }, 200)
    }

    const onFocusHandler =()=> {
        props.onIsSearchInputInFocus(true);
    }

    const onClickHandler =(e) =>{
        e.target.select();
    }

   useEffect(()=>{

       searchInputStateChanged({
           typingString: props.searchBarPlaceholder
       })

   }, [props.searchBarPlaceholder])

    /**
     * This method will be called for every change in search bar.
     * Method will be called depending on string length, and if length is 3 or more, we will open SearchItems menu.
     * @param event
     */
    const enterSearchTextHandler = (event) => {

        if (searchInputState.typingTimeout) {
            clearTimeout(searchInputState.typingTimeout)
        }

        let str = event.target.value;
        searchInputStateChanged({
            ...searchInputState,
            typingString: str
        })

        if (str === '' || str.length < 3){
            props.openList(false);
        }

        if (str.trim().length >= 3) {
            searchInputStateChanged({
                // typingString: str,
                inputString: str,
                typing: false,
                typingTimeout: setTimeout(() => {
                    props.onSearchStringChange(str);
                    props.openList(str !== '');
                }, 600)
            })
        }
    }

    /**
     * This method will handle up,down and enter key press.
     * @param event
     */
    const handleArrowPress = (event) => {
        const key = event.keyCode;
        //ako je pritisnut key down taster povecavamo brojac index-a za 1
        if (props.isListOpen && key === 40 && props.selectedIndex < props.numberOfMatchedArticles){
            props.onSelectedIndexChanged(props.selectedIndex + 1 )
        }
        if (props.isListOpen && key === 38 && props.selectedIndex > 0){

            event.stopPropagation()
            event.preventDefault();
            props.onSelectedIndexChanged(props.selectedIndex - 1)
        }

        //User press Enter (13)
        if (props.isListOpen && key === 13){

            if ( props.selectedIndex >= 0) {
                event.stopPropagation();
                event.preventDefault();
                props.onSelectDetected(true);
            }
            //za string leka u shopu
            if (props.selectedIndex === 0 && props.history.location.pathname.includes("/shop/")) {
                let url = '/shop/' + props.match.params.branchMarketplaceId + "/"
                    + props.match.params.marketplaceId + "/0/" + searchInputState.inputString + '/' + props.match.params.branchMarketplaceName + '/' + props.match.params.marketplaceName;
                props.history.push(url);
            }
        }
    }

    let value = searchInputState.typingString!==undefined && (searchInputState.typingString).length>60 ? (searchInputState.typingString).substr(0, 60)+"..." : (searchInputState.typingString);
    return (
            <input type="input"
                   placeholder={!props.storesSearch
                       ? ((!props.onMainPage && props.articleDetails !== null)
                           ? ((props.articleDetails.name).length>60
                               ? ((props.articleDetails.name).substr(0,60)+"...")
                               : props.articleDetails.name)
                           : (window.location.pathname.includes('shop')
                               ? "Pretražite proizvode"
                               : "Unesite naziv proizvoda"))
                       : "Unesite naziv apoteke"}
                   ref={fieldRef}
                   value={value}
                   style={props.isListOpen ? {boxShadow: "none", border: "none"} : null}
                   onChange={enterSearchTextHandler}
                   onKeyDown={handleArrowPress}
                   onBlur={onBlurHandler}
                   onFocus={onFocusHandler}
                   onClick={onClickHandler}
                   className={window.location.pathname.includes('stores') ? styles.SearchBarShopPage : null}
            />
    );
}

const mapStateToProps = (state) => {
    return {
        stores: state.appPage.stores,
        articles: state.mainPage.articles,
        searchBarPlaceholder: state.mainPage.searchBarPlaceholder,
        searchString: state.mainPage.searchString,
        selectedIndex: state.mainPage.selectedIndex,
        numberOfMatchedArticles: state.mainPage.numberOfMatchedArticles,
        articleDetails: state.appPage.articleDetails,
        searchInputFocus: state.appPage.searchInputFocus
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        onSearchStringChange: (string) => dispatch(actions.searchStringChanged(string)),
        onSelectedIndexChanged: (index) => dispatch(actions.selectedIndexChanged(index)),
        onSelectDetected: (isSelected) => dispatch(actions.selectDetected(isSelected)),

        onIsSearchInputInFocus: (searchInputFocus) => dispatch(actions.isSearchInputInFocus(searchInputFocus))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SearchInput));