import * as actionsTypes from '../actions/actionsTypes'

const initialState = {
    //for quick store select modal
    storesModal: null,
    distancesModal:null,
    loadingStoresModal: false,
    storesModalServerError: false,
    storesModalError: false,

    stores: null,
    distances: null,
    totalElements: -1,
    totalPages: -1,
    minPrice: -1,
    maxPrice: -1,
    storesError: false,
    storesServerError: false,
    loadingStores: false,

    loadingUserLocation: false,
    userLocationError: false,

    articleDetails: null,
    articleDetailsError: false,
    loadingArticleDetails: false,

    storeDetails: null,
    storeDetailsError: false,
    loadingStoreDetails: false,
    selectedStore: null,

    showGoogleMap: false,

    //open hours for store
    openHoursForStore: null,
    openHoursForStoreError: false,
    loadingStoreOpenHours: false,

    searchInputFocus: false,

    //zoom credentials
    zoomCredentials: null,
    zoomCredentialsError: false,
    loadingZoomCredentials: false,

    //storeItemModal
    storeItemModal:{
        isOpen: false,
        storeItem: null
    },

    place: {
        formatted_address: null,
        lat: null,
        lng: null
    },

    createUserSearchInputError: false,
    loadingCreateUserSearchInput: false,
}

const appPageReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionsTypes.ALL_STORES_FOR_ARTICLE:
            return {
                ...state,
                loadingStores: false,
                stores: action.stores.list,
                distances: null,
                totalElements: action.stores.totalElements,
                totalPages: action.stores.totalPages,
                minPrice: action.stores.minPrice,
                maxPrice: action.stores.maxPrice,
                storesError: false,
                storesServerError: false

            }
        case actionsTypes.ALL_STORES_IN_RADIUS_FOR_ARTICLE:
            return {
                ...state,
                loadingStores: false,
                stores: action.stores.stores,
                distances: action.stores.distances,
                totalElements: action.stores.totalElements,
                totalPages: action.stores.totalPages,
                minPrice: 0,
                maxPrice: 0,
                storesError: false,
                storesServerError: false

            }

        case actionsTypes.ALL_STORES_IN_RADIUS_FOR_ARTICLE_MODAL:
            return {
                ...state,
                storesModal: action.storesModal.stores,
                distancesModal: action.storesModal.distances,
                loadingStoresModal: false,
                storesModalServerError: false,
                storesModalError: false,
            }
        case actionsTypes.FETCH_ALL_STORES_FOR_ARTICLE_MODAL_START:
            return {
                ...state,
                loadingStoresModal: true,
                storesModalServerError: false,
                storesModalError: false,
            }
        case actionsTypes.FETCH_ALL_STORES_FOR_ARTICLE_MODAL_FAIlED:
            return {
                ...state,
                loadingStoresModal: false,
                storesModalServerError: false,
                storesModalError: true,
            }
        case actionsTypes.FETCH_ALL_STORES_FOR_ARTICLE_MODAL_SERVER_ERROR:
            return {
                ...state,
                loadingStoresModal: false,
                storesModalServerError: true,
                storesModalError: false,
            }
        case actionsTypes.RESET_ALL_STORES_IN_RADIUS_FOR_ARTICLE_MODAL:
            return {
                ...state,
                storesModal: null,
                distancesModal: null,
            }

        case actionsTypes.SLICE_OF_STORES_FOR_ARTICLE:
            return {
                ...state,
                loadingStores: false,
                stores: action.stores,
                distances: null,
                storesError: false,
                storesServerError: false

            }
        case actionsTypes.FETCH_ALL_STORES_FOR_ARTICLE_FAIlED:
            return {
                ...state,
                stores: null,
                distances: null,
                totalElements: -1,
                totalPages: -1,
                minPrice: -1,
                maxPrice: -1,
                storesError: true,
                loadingStores: false,
                storesServerError: false
            }

        case actionsTypes.FETCH_ALL_STORES_FOR_ARTICLE_SERVER_ERROR:
            return {
                ...state,
                stores: null,
                distances: null,
                totalElements: -1,
                totalPages: -1,
                minPrice: -1,
                maxPrice: -1,
                storesError: false,
                loadingStores: false,
                storesServerError: true
            }

        case actionsTypes.FETCH_ALL_STORES_FOR_ARTICLE_START:
            return {
                ...state,
                loadingStores: true,
                storesServerError: false,
                storesError: false

            }
        case actionsTypes.FETCH_USER_LOCATION_START:
            return {
                ...state,
                loadingUserLocation: true,
                userLocationError: false

            }
        case actionsTypes.FETCH_USER_LOCATION_FINISHED:
            return {
                ...state,
                loadingUserLocation: false,
                userLocationError: false

            }
        case actionsTypes.FETCH_USER_LOCATION_ERROR:
            return {
                ...state,
                loadingUserLocation: false,
                userLocationError: true

            }

        case actionsTypes.FETCH_DETAILS_FOR_ARTICLE:
            return {
                ...state,
                articleDetails: action.articleDetails,
                articleDetailsError: false,
                loadingArticleDetails: false
            }
        case actionsTypes.FETCH_DETAILS_FOR_ARTICLE_FAILED:
            return {
                ...state,
                articleDetails: null,
                articleDetailsError: true,
                loadingArticleDetails: false

            }
        case actionsTypes.FETCH_DETAILS_FOR_ARTICLE_START:
            return {
                ...state,
                loadingArticleDetails: true,
                articleDetailsError: false
            }
        case actionsTypes.FETCH_DETAILS_FOR_STORE:
            return {
                ...state,
                storeDetails: action.storeDetails,
                storeDetailsError: false,
                loadingStoreDetails: false
            }
        case actionsTypes.FETCH_DETAILS_FOR_STORE_FAILED:
            return {
                ...state,
                storeDetailsError: true
            }
        case actionsTypes.FETCH_DETAILS_FOR_STORE_START:
            return {
                ...state,
                loadingStoreDetails: true
            }
        // case actionsTypes.SELECTED_STORE_CHANGED:
        //     return {
        //         ...state,
        //         selectedStore: {
        //             ...action.selectedStore,
        //             apotekaResponse: {...action.selectedStore.apotekaResponse},
        //             prodajnoMestoResponse: {...action.selectedStore.prodajnoMestoResponse}
        //         }
        //     }
        case actionsTypes.SELECTED_STORE_CHANGED:
            return {
                ...state,
                selectedStore: action.selectedStore
            }
        case actionsTypes.LOAD_SELECTED_STORE:
            return {
                ...state,
                selectedStore: action.selectedStore
            }


        case actionsTypes.SHOW_GOOGLE_MAP_CHANGED:
            return {
                ...state,
                showGoogleMap: action.showGoogleMap
            }

        case actionsTypes.FETCH_OPEN_HOURS_FOR_STORE:
            return {
                ...state,
                openHoursForStore: action.openHoursForStore,
                openHoursForStoreError: false,
                loadingStoreOpenHours: false
            }
        case actionsTypes.FETCH_OPEN_HOURS_FOR_STORE_FAILED:
            return {
                ...state,
                openHoursForStoreError: true
            }
        case actionsTypes.FETCH_OPEN_HOURS_FOR_STORE_START:
            return {
                ...state,
                loadingStoreOpenHours: true
            }

        case actionsTypes.RESET_APP_PAGE_STORE:
            return {
                ...state,

                stores: null,
                distances: null,
                totalElements: -1,
                totalPages: -1,
                minPrice: -1,
                maxPrice: -1,

                articleDetails: null,
                articleDetailsError: false,
                loadingArticleDetails: false,

                storeDetails: null,
                selectedStore: null,
                storeDetailsError: false,
                loadingStoreDetails: false,

                openHoursForStore: null,
                openHoursForStoreError: false,
                loadingStoreOpenHours: false

            }

        case actionsTypes.SEARCH_INPUT_FOCUS:
            return {
                ...state,
                searchInputFocus: action.searchInputFocus
            }

        //zoom
        case actionsTypes.FETCH_ZOOM_CREDENTIALS:
            return {
                ...state,
                zoomCredentials: action.zoomCredentials,
                zoomCredentialsError: false,
                loadingZoomCredentials: false,
            }
        case actionsTypes.FETCH_ZOOM_CREDENTIALS_FAILED:
            return {
                ...state,
                zoomCredentials: null,
                zoomCredentialsError: true,
                loadingZoomCredentials: false

            }
        case actionsTypes.FETCH_ZOOM_CREDENTIALS_START:
            return {
                ...state,
                zoomCredentials: null,
                loadingZoomCredentials: true,
                zoomCredentialsError: false
            }

        case actionsTypes.STORE_ITEM_MODAL:
            return {
                ...state,
                storeItemModal: {...action.storeItemModal}
            }

        case actionsTypes.TOGGLE_SHOP_NOT_ONLINE_MODAL:
            return {
                ...state,
                toggleShopNotOnlineModal: action.toggleShopNotOnlineModal
            }

        case actionsTypes.ENTERED_ADDRESS_DATA:
            return {
                ...state,
                place: {...action.place}
            }
        case actionsTypes.CREATE_USER_SEARCH_INPUT:
            return {
                ...state,
                createUserSearchInputError: false,
                loadingCreateUserSearchInput: false,
            }
        case actionsTypes.CREATE_USER_SEARCH_INPUT_FAILED:
            return {
                ...state,
                createUserSearchInputError: true,
                loadingCreateUserSearchInput: false,
            }
        case actionsTypes.CREATE_USER_SEARCH_INPUT_START:
            return {
                ...state,
                createUserSearchInputError: false,
                loadingCreateUserSearchInput: true,
            }
        default:
            return state;
    }
}
export default appPageReducer;
