import React from 'react'
import styles from './SearchedArticleItemMobile.module.css'
import DefaultImage from "../../../../assets/PictureHolder.png"
import {withRouter} from 'react-router-dom'
import * as actions from "../../../../store/actions";
import {connect} from "react-redux";
import ConfigData from "../../../../config.json";
import Button from "../../../UI/Buttons/Button/Button";
import RegulativaNotice from './../../../UI/RegulativaNotice/RegulativaNotice'
import {ReactComponent as CartIcon} from "../../../../assets/icons/cart_icon.svg";
import {textToUrl} from "../../../GobalFunctions/GlobalFunctions";
import ItemAvailabilityBadge from "../../../UI/ItemAvailabilityBadge/ItemAvailabilityBadge";
import LoyaltyBadge from "../../../UI/LoyaltyBadge/LoyaltyBadge";
import SquareBadge from "../../../UI/SquareBadge/SquareBadge";
import TooltipComponent from "../../../UI/TooltipComponent/TooltipComponent";

const SearchedArticleItemMobile = (props) => {

    // const [isPriceButtonPressed, isPriceButtonPressedHandler] = useState(false);
    // const [isPhotoButtonPressed, isPhotoButtonPressedHandler] = useState(false);

    let styling = {
        mainDiv: props.searchedArticle ? [styles.SearchedArticleItemMobile, styles.SearchedArticleMobile].join(' ') : styles.SearchedArticleItemMobile,
        articleName: styles.ArticleName,
        itemPrice: styles.ItemPrice,
        itemPicture: styles.ItemPicture,
        additionalInfo: styles.AdditionalInfoGridView,
    }

    const animateAddingItem = (e) => {
        e.stopPropagation();
        props.addItemToShoppingCart();
    }


    const articleInfoHandler = () => {
        let url;
        if (!window.location.pathname.includes('searched-articles')) {
            const branchMarketplaceId = props.location.pathname.split('/')[2];
            const marketplaceId = props.location.pathname.split('/')[3];
            url = '/articles-info/' + props.articleItem.itemResponse.jkl + "/" + props.articleItem.itemResponse.id + '/' + branchMarketplaceId + '/' + marketplaceId + '/' + textToUrl(props.articleItem.itemResponse.name)
            + '/' + props.match.params.branchMarketplaceName + '/' + props.match.params.marketplaceName;
            if (props.selectedStore !== null) {
                props.onStoreDetailsForInfoPage(props.selectedStore.marketplaceResponse);
            }
        } else {
            url = '/articles-info/' + props.articleItem.itemResponse.jkl + "/" + props.articleItem.itemResponse.id + "/" + textToUrl(props.articleItem.itemResponse.name);
        }
        localStorage.setItem('beforeEnteringInfoLek', window.location.pathname);
        props.onArticleInStore(props.articleItem);
        props.history.push(url);
    }

    const searchForArticle = () => {
        let url = '/articles-and-stores/' + props.articleItem.itemResponse.id + '/0' + '/' + textToUrl(props.articleItem.itemResponse.name);
        props.history.push(url);
    }

    let itemAdvancedAction =
        window.location.pathname.includes('shop') ?

            <Button buttontype='whitedarkblue'
                    style={{padding: "1rem", fontWeight: "600", position: "relative"}}
                    onClick={animateAddingItem}>
                <CartIcon  style={{width: "20px", stroke: "inherit", left: "20px"}}/>
                <span style={{paddingLeft: "1rem"}}>Dodaj</span>
                {props.quantityInCart > 0&&
                <span className={styles.QuantityInCart}>{props.quantityInCart}</span>
                }
            </Button>
            :

            <div onClick={searchForArticle}
                 style={{textDecoration: "underline", marginRight: "1rem", cursor: "pointer", fontSize: "12px"}}
            >Proveri dostupnost</div>

    const itemAvailability = props.articleItem.itemResponse.itemAvailabilityCode !== null &&
        <ItemAvailabilityBadge itemResponse={props.articleItem.itemResponse} />

    const loyaltyPointsIcon =
        <div className={styles.LoyaltyPointsIcon}>
            <LoyaltyBadge
                number={props.articleItem && props.articleItem.itemResponse.itemLoyalityPoints/10}
            />
        </div>

    const itemPrice = props.articleItem.itemPrice !== null &&
    props.articleItem.itemPriceWithDiscount && props.articleItem.itemPriceWithDiscount > 0 ?
        <div style={{display:"flex", justifyContent:"space-between", alignItems:"center", width:"100%"}}>
            <div style={{display:"flex"}}>
                <h2 className={styling.itemPrice}>{(props.articleItem.itemPriceWithDiscount).toFixed(2)} <span
                    className={styles.RsdSpan}>RSD</span></h2>
                <h2 className={styling.itemPrice} style={{textDecoration:"line-through", color:"#88959E", marginLeft:"8px"}}>{(props.articleItem.itemPrice).toFixed(2)} <span
                    className={styles.RsdSpan}>RSD</span></h2>
            </div>
        </div>
        :
        <h2 className={styling.itemPrice}>{(props.articleItem.itemPrice).toFixed(2)} <span
            className={styles.RsdSpan}>RSD</span></h2>

    // const showItemPrice = () => {
    //     isPriceButtonPressedHandler(true);
    //     setTimeout(() => {
    //         isPriceButtonPressedHandler(false)
    //     }, 10000)
    // }
    //
    // const showItemPhoto = () => {
    //     isPhotoButtonPressedHandler(true);
    //     setTimeout(() => {
    //         isPhotoButtonPressedHandler(false);
    //     }, 10000)
    // }

    const discountBadge = <div className={styles.SqBox}>
        <SquareBadge
            backgroundColor={"#FFAE00"}
            marginLeft={"6px"}
        >
            <span style={{color: "white"}}>%</span>
        </SquareBadge>
        <TooltipComponent className={styles.TooltipSBText} text="Artikal je na popustu!"/>
    </div>

    return (
        <React.Fragment>
            <div className={styling.mainDiv} style={props.style}>
                <div onClick={articleInfoHandler} style={{cursor: "pointer"}}>
                    <div className={styling.itemPicture} style={{position:"relative"}}>
                        {(props.articleItem.itemResponse.jkl !== null && props.articleItem.itemResponse.jkl.trim() !== "" && props.articleItem.itemResponse.itemAvailabilityCode !== 'BR') ?
                            // !isPhotoButtonPressed ?
                            //     <h2 onClick={showItemPhoto} style={{
                            //     textDecoration: "underline",
                            //     cursor: "pointer",
                            //     color: "#848688"
                            // }}>Prikaži fotografiju</h2>
                            //     :
                            //     <img style={{borderRadius: "8px"}}
                            //                                    src={props.articleItem.itemResponse.pictures !== null ? process.env.REACT_APP_CDN_PATH + props.articleItem.itemResponse.pictures : DefaultImage}
                            //                                    alt=""
                            // />
                            <RegulativaNotice  imgStyle={{borderRadius: "8px"}}
                                               style={{position: "absolute", bottom:"20px", left:"0", display: "flex", alignItems:"center"}}
                                               iconStyle={{width:"36px", stroke:"#F58300"}}
                            />
                            :
                            <img style={{borderRadius: "8px"}}
                                 src={props.articleItem.itemResponse.pictures !== null ? process.env.REACT_APP_CDN_PATH + props.articleItem.itemResponse.pictures : DefaultImage}
                                 alt=""
                            />
                        }
                    </div>
                    <div className={styles.ArticleNameWrapper}>
                        <p className={styling.articleName}>
                            {props.articleItem.itemResponse.name}
                        </p>
                    </div>
                    {/*-----------------------------------------------------------------------------*/}

                </div>

                {/*{ props.gridView ? <span className={styling.tooltipText}>{props.articleItem.itemResponse.name}</span> : null}*/}
                {/*<p>raspoloživa količina: {props.storeItem.itemQuantity}</p>*/}

                <div className={styles.AdditionalInfo}>
                    <div className={styles.BottomHolder}>
                        <div className={styles.PriceAndDetailsHolder}>
                            <>
                                {/*{((props.articleItem.itemResponse.itemAvailabilityCode === 'R' || props.articleItem.itemResponse.itemAvailabilityCode === 'SZR') && window.location.pathname.includes('shop')) ?*/}
                                {(props.articleItem.itemResponse.jkl !== null && window.location.pathname.includes('shop')) ?
                                    // !isPriceButtonPressed ?
                                    //     <h2 className={styling.itemPrice}
                                    //         style={{cursor: "pointer", fontSize: "1rem", margin: "0"}}
                                    //         onClick={showItemPrice}>Prikaži cenu</h2> : props.articleItem.itemPrice !== null && itemPrice
                                    // <h2 className={styling.itemPrice} style={{display: "flex", alignItems: "center"}}><span style={{fontSize: "16px", color:"#4D5055"}}>Regulativa</span>&nbsp; &nbsp; <ExclamationCircleIcon style={{width:"20px", stroke:"#F58300"}}/></h2>
                                    <h2 className={styling.itemPrice} style={{display: "flex", alignItems: "center"}}><span className={styles.ArticleItemPriceRegulativa}>Regulativa</span></h2>
                                    :
                                    (props.articleItem.itemPrice !== null && itemPrice)
                                }
                            </>
                            {props.articleItem.itemPriceWithDiscount !== null && props.articleItem.itemPriceWithDiscount > 0 && discountBadge}

                            <div className={styles.MoreDetails} onClick={articleInfoHandler}>
                                Detaljnije +
                            </div>

                        </div>
                    </div>
                    <div className={styles.BottomLineWrapper}>
                        {/*<InfoIcon className={styles.ArticleInfoIcon}/>*/}
                        {itemAdvancedAction}
                        {itemAvailability}
                        {props.articleItem.itemResponse.itemLoyalityPoints > 0 && loyaltyPointsIcon}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => {
    return {
        shoppingCart: state.shopPage.shoppingCart,
        loadingShoppingCart: state.shopPage.loadingShoppingCart,
        isInitialState: state.shopPage.isInitialState,
        initAddToShoppingCartAnimation: state.shopPage.initAddToShoppingCartAnimation,
        userInfo: state.authPage.userInfo,

        selectedStore: state.appPage.selectedStore,

        singleShopArticles: state.shopPage.singleShopArticles,
        loadingSingleShopArticles: state.shopPage.loadingSingleShopArticles,
        singleShopArticlesFailed: state.shopPage.singleShopArticlesFailed,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onFetchSelectedStore: (branchMarketplaceId, marketplaceId) => dispatch(actions.fetchSelectedStore(branchMarketplaceId, marketplaceId)),

        onStoreDetailsForInfoPage: (store) => dispatch(actions.detailsForStoreInfoPage(store)),
        onArticleInStore: (articleInStore) => dispatch(actions.articleInStore(articleInStore))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SearchedArticleItemMobile));

