import * as actionsTypes from "../actions/actionsTypes";

const initialState = {
    userProfile: null,
    userProfileError: false,
    loadingUserProfile: false,

    updateUserProfileSuccess: false,
    updateUserProfileFailed: false,
    loadingUpdateUserProfile: false,

    deleteUserProfileSuccess: false,
    deleteUserProfileFailed: false,
    loadingDeleteUserProfile: false,

    changePasswordSuccess: false,
    loadingChangePassword: false,
    changePasswordFailed: false,
    changePasswordWrongOldPassword: false,

    addNewUserAddressError: false,
    loadingAddNewUserAddress: false,

    updateUserAddressError: false,
    loadingUpdateUserAddress: false,

    deleteUserAddressError: false,
    loadingDeleteUserAddress: false,

    userTherapies: null,
    loadingUserTherapies: false,
    loadingUserTherapiesFailed: false,

    selectedItem: null,
    toggleTherapyArticleModal: false,

    articles: [],
    articlesForTherapyItemRequests: [],

    newTherapyCreated: false,
    createNewTherapyStart: false,
    createNewTherapyFailed: false,

    deleteUserTherapyStart: false,
    deleteUserTherapyFailed: false,

    storesContainingUserTherapy: null,
    storesContainingUserTherapyDistances: null,

    addArticleToTherapyFromInfoLekFlag: false,

    newTherapyName: null,

    addArticleToTherapyFromInfoLekModalVisible: false

}

const userProfileReducer = (state = initialState, action) => {
    switch (action.type) {

        case actionsTypes.USER_PROFILE:
            return {
                ...state,
                userProfile: action.userProfile,
                userProfileError: false,
                loadingUserProfile: false
            }
        case actionsTypes.USER_PROFILE_FAILED:
            return {
                ...state,
                userProfile: null,
                userProfileError: true,
                loadingUserProfile: false
            }
        case actionsTypes.USER_PROFILE_START:
            return {
                ...state,
                userProfileError: false,
                loadingUserProfile: true
            }
        case actionsTypes.UPDATE_USER_PROFILE_SUCCESS:
            return {
                ...state,
                userProfile: {
                    ...state.userProfile,
                    ...action.userProfileUpdated
                },
                updateUserProfileSuccess: true,
                updateUserProfileFailed: false,
                loadingUpdateUserProfile: false
            }
        case actionsTypes.UPDATE_USER_PROFILE_START:
            return {
                ...state,
                updateUserProfileSuccess: false,
                updateUserProfileFailed: false,
                loadingUpdateUserProfile: true
            }
        case actionsTypes.UPDATE_USER_PROFILE_FAILED:
            return {
                ...state,
                updateUserProfileSuccess: false,
                updateUserProfileFailed: true,
                loadingUpdateUserProfile: false
            }
        case actionsTypes.DELETE_USER_PROFILE_SUCCESS:
            return {
                ...state,
                deleteUserProfileSuccess: true,
                deleteUserProfileFailed: false,
                loadingDeleteUserProfile: false
            }
        case actionsTypes.DELETE_USER_PROFILE_START:
            return {
                ...state,
                deleteUserProfileSuccess: false,
                deleteUserProfileFailed: false,
                loadingDeleteUserProfile: true
            }
        case actionsTypes.DELETE_USER_PROFILE_FAILED:
            return {
                ...state,
                deleteUserProfileSuccess: false,
                deleteUserProfileFailed: true,
                loadingDeleteUserProfile: false
            }
        case actionsTypes.CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,
                changePasswordSuccess: true,
                loadingChangePassword: false,
                changePasswordFailed: false,
                changePasswordWrongOldPassword: false
            }
        case actionsTypes.CHANGE_PASSWORD_START:
            return {
                ...state,
                changePasswordSuccess: false,
                loadingChangePassword: true,
                changePasswordFailed: false,
                changePasswordWrongOldPassword: false
            }
        case actionsTypes.CHANGE_PASSWORD_WRONG_OLD_PASSWORD:
            return {
                ...state,
                changePasswordSuccess: false,
                loadingChangePassword: false,
                changePasswordFailed: false,
                changePasswordWrongOldPassword: true
            }
        case actionsTypes.CHANGE_PASSWORD_FAILED:
            return {
                ...state,
                changePasswordSuccess: false,
                loadingChangePassword: false,
                changePasswordFailed: true,
                changePasswordWrongOldPassword: true
            }
        case actionsTypes.ADD_NEW_USER_ADDRESS:
            return {
                ...state,
                userProfile: {...state.userProfile, userAddresses: [...state.userProfile.userAddresses, action.newAddress]},
                addNewUserAddressError: false,
                loadingAddNewUserAddress: false
            }
        case actionsTypes.ADD_NEW_USER_ADDRESS_FAILED:
            return {
                ...state,
                addNewUserAddressError: true,
                loadingAddNewUserAddress: false
            }
        case actionsTypes.ADD_NEW_USER_ADDRESS_START:
            return {
                ...state,
                addNewUserAddressError: false,
                loadingAddNewUserAddress: true
            }
        case actionsTypes.UPDATE_USER_ADDRESS:
            return {
                ...state,
                userProfile: {...state.userProfile,
                    userAddresses: state.userProfile.userAddresses.map(address => {
                        if (address.id === action.updatedAddress.id) {
                            return action.updatedAddress;
                        }
                        return address;
                    })},
                updateUserAddressError: false,
                loadingUpdateUserAddress: false
            }
        case actionsTypes.UPDATE_USER_ADDRESS_FAILED:
            return {
                ...state,
                updateUserAddressError: true,
                loadingUpdateUserAddress: false
            }
        case actionsTypes.UPDATE_USER_ADDRESS_START:
            return {
                ...state,
                updateUserAddressError: false,
                loadingUpdateUserAddress: true
            }
        case actionsTypes.DELETE_USER_ADDRESS:
            return {
                ...state,
                userProfile: {...state.userProfile,
                    userAddresses: state.userProfile.userAddresses.filter(address => {
                        if (address.id !== action.deletedAddressId) {return address;}
                    })},
                deleteUserAddressError: false,
                loadingDeleteUserAddress: false
            }
        case actionsTypes.DELETE_USER_ADDRESS_FAILED:
            return {
                ...state,
                addNewUserAddressError: true,
                loadingDeleteUserAddress: false
            }
        case actionsTypes.DELETE_USER_ADDRESS_START:
            return {
                ...state,
                deleteUserAddressError: false,
                loadingDeleteUserAddress: true
            }
        case actionsTypes.USER_THERAPIES:
            return {
                ...state,
                userTherapies: action.userTherapies,
                loadingUserTherapies: false,
                loadingUserTherapiesFailed: false
            }
        case actionsTypes.USER_THERAPIES_START:
            return {
                ...state,
                loadingUserTherapies: true,
                loadingUserTherapiesFailed: false
            }
        case actionsTypes.USER_THERAPIES_FAILED:
            return {
                ...state,
                loadingUserTherapies: false,
                loadingUserTherapiesFailed: true
            }
        case actionsTypes.SET_SELECTED_ITEM:
            return {
                ...state,
                selectedItem: action.selectedItem
            }
        case actionsTypes.TOGGLE_THERAPY_ARTICLE_MODAL:
            return {
                ...state,
                toggleTherapyArticleModal: action.toggleTherapyArticleModal
            }
        case actionsTypes.ADD_ARTICLE_TO_THERAPY:
            let tempArticleArray = [...state.articles];
            let tempArticlesForTherapyItemRequests = [...state.articlesForTherapyItemRequests];
            if (tempArticleArray.map(el => el.item.id).includes(action.itemToAdd.item.id)) {
                tempArticleArray = tempArticleArray.map((article, index) => {
                    if (article.item.id === action.itemToAdd.item.id) {

                        tempArticlesForTherapyItemRequests.splice(index, 1,{
                            itemId: action.itemToAdd.item.id,
                            frequency: action.itemToAdd.frequency !== '/' ? action.itemToAdd.frequency : null,
                            dosing: action.itemToAdd.dosing !== '/' ? action.itemToAdd.dosing : null,
                            duration: action.itemToAdd.duration !== '/' ? action.itemToAdd.duration : null,
                            quantity: action.itemToAdd.quantity
                        });
                        return (
                            tempArticleArray[index] = {...action.itemToAdd}
                        );
                    } else {
                        return article
                    }
                })
            }
            else {
                tempArticleArray.push(action.itemToAdd);
                tempArticlesForTherapyItemRequests.push({
                    itemId: action.itemToAdd.item.id,
                    frequency: action.itemToAdd.frequency !== '/' ? action.itemToAdd.frequency : null,
                    dosing: action.itemToAdd.dosing !== '/' ? action.itemToAdd.dosing : null,
                    duration: action.itemToAdd.duration !== '/' ? action.itemToAdd.duration : null,
                    quantity: action.itemToAdd.quantity
                });
            }
            console.log("------------------------")
            console.log(tempArticleArray)
            console.log(tempArticlesForTherapyItemRequests)
            console.log("----------------------")
            return {
                ...state,
                articles: tempArticleArray,
                articlesForTherapyItemRequests: tempArticlesForTherapyItemRequests
            }
        case actionsTypes.REMOVE_ARTICLE_FROM_THERAPY:
            return {
                ...state,
                articles: state.articles.filter(a => {
                    if (+a.item.id !== +action.itemToRemove) {
                        return a
                    }
                }),
                articlesForTherapyItemRequests : state.articlesForTherapyItemRequests.filter(a => {
                    if (+a.itemId !== +action.itemToRemove) {
                        return a
                    }
                })
            }
        case actionsTypes.REMOVE_ALL_ARTICLES_FROM_THERAPY:
            return {
                ...state,
                articles: [],
                articlesForTherapyItemRequests: []
            }
        case actionsTypes.CREATE_NEW_THERAPY_START:
            return{
                ...state,
                newTherapyCreated: false,
                createNewTherapyStart: true,
                createNewTherapyFailed: false
            }
        case actionsTypes.NEW_THERAPY_CREATED:
            let tempNewTherapy = {
                // ...action.newUserTherapy,
                id: action.newUserTherapy.id,
                name: action.newUserTherapy.name,
                description: action.newUserTherapy.description,
                items: state.articles,
                authorizedMedic: action.newUserTherapy.authorizedMedic,
                created: action.newUserTherapy.created,
                therapyDiagnosisResponse: action.newUserTherapy.therapyDiagnosisResponse
            }

            let tempTherapies = state.userTherapies;

            if(tempTherapies.map(el => el.id).includes(tempNewTherapy.id)){
                tempTherapies = tempTherapies.map((therapy, index) => {
                    if(therapy.id === tempNewTherapy.id) {
                        return(
                            tempTherapies[index] = {...tempNewTherapy}
                        )
                    } else {
                        return therapy
                    }
                })
            } else {
                tempTherapies.push(tempNewTherapy)
            }

            return {
                ...state,
                userTherapies: [...tempTherapies]
                // createNewTherapyStart: false,
                // createNewTherapyFailed: false
            }
        case actionsTypes.CREATE_NEW_THERAPY_FAILED:
            return {
                ...state,
                newTherapyCreated: false,
                createNewTherapyStart: false,
                createNewTherapyFailed: true
            }

        case actionsTypes.USER_THERAPY_DELETED:
            return {
                ...state,
                userTherapies: state.userTherapies.filter(t => {
                    if (+t.id !== +action.deletedUserTherapyId) {
                        return t
                    }
                })
            }
        case actionsTypes.DELETE_USER_THERAPY_START:
            return {
                ...state,
                deleteUserTherapyStart: true,
                deleteUserTherapyFailed: false
            }
        case actionsTypes.DELETE_USER_THERAPY_FAILED:
            return {
                ...state,
                deleteUserTherapyStart: false,
                deleteUserTherapyFailed: true
            }
        case actionsTypes.STORES_FOR_USER_THERAPY:
            return {
                ...state,
                storesContainingUserTherapy: action.storesContainingUserTherapy,
                storesContainingUserTherapyDistances: action.storesContainingUserTherapy.distances
            }
        case actionsTypes.ADD_ARTICLE_TO_THERAPY_FROM_INFO_LEK:
            return {
                ...state,
                addArticleToTherapyFromInfoLekFlag: action.addArticleToTherapyFromInfoLekFlag
            }
        case actionsTypes.GET_NEW_THERAPY_NAME_FROM_INFO_LEK_MODAL:
            return {
                ...state,
                newTherapyName: action.newTherapyName
            }
        case actionsTypes.ADD_ARTICLE_TO_THERAPY_FROM_INFO_LEK_MODAL_TOGGLE:
            return {
                ...state,
                addArticleToTherapyFromInfoLekModalVisible: action.addArticleToTherapyFromInfoLekModalVisible
            }
        default:
            return state;
    }
}

export default userProfileReducer;