import React, {Fragment, useEffect} from 'react'
import styles from '../SearchedInfo.module.css'
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import stylesStore from "./Store.module.css";
import * as actions from "../../../../../store/actions";
import Media from "react-media";
import SquareBadge from "../../../../UI/SquareBadge/SquareBadge";
import {StarIcon} from "@heroicons/react/solid";
import {ReactComponent as RFZOIcon} from "../../../../../assets/icons/rfzo-icon-with-border.svg"
import {ReactComponent as DeliveryIcon} from "../../../../../assets/icons/delivery.svg"

const SearchedStoresInfo = (props) => {

    useEffect(() => {
        props.onCountMarketplaces();
    }, [])

    const displayedText =
        props.match.params.string &&
        //pretraga stringa apoteka
        window.location.pathname.includes('searched-stores/' + props.match.params.page + '/' + props.match.params.string) &&
        <> {props.match.params.string === 'all_stores' ?
            <p className={styles.Text}>Pregled svih apoteka u sistemu</p>
            :
            <p className={styles.Text}>Traženi tekst: <strong style={{color: "#00375cff", fontSize: "18px"}}>{props.match.params.string}</strong></p>
        }
        <div style={{display:"flex", flexDirection:"column", marginTop: "0.5rem", marginBottom:"0.5rem", color:"#5b6977ff"}}>
            <span style={{display:"flex", paddingBottom:"3px"}}>
                <SquareBadge
                    backgroundColor={"#FFAE00"}
                    marginLeft={"6px"}
                >
                                <span style={{color: "white"}}>%</span>
                            </SquareBadge>
                <span style={{paddingLeft:"1rem"}}>Popust na određene artikle – USKORO!</span>
            </span>
            <span style={{display:"flex", paddingBottom:"3px"}}>
                <SquareBadge
                backgroundColor={"#00B1B0"}
                marginLeft={"6px"}
            >
                                <DeliveryIcon style={{width: "1.375rem", stroke: "white"}}/>
                            </SquareBadge>
                <span style={{paddingLeft:"1rem"}}>Apoteka vrši isporuku</span>
            </span>
            <span style={{display: "flex", paddingBottom: "3px"}}>
                <SquareBadge
                    backgroundColor={"#4D5055"}
                    marginLeft={"6px"}
                >
                    <RFZOIcon/>
                </SquareBadge>
                <span style={{paddingLeft: "1rem"}}>Rezervacija RFZO terapije</span>
            </span>
            <span style={{display:"flex"}}>
                <SquareBadge
                    backgroundColor={"#013A63"}
                    marginLeft={"6px"}
                >
                                {/*<span style={{color:"white", fontWeight:"bold", fontSize:"14px"}}>9.{randomIntFromInterval(0,9)}</span>*/}
                    <StarIcon style={{width: "1.375rem", color: "white"}}/>
                            </SquareBadge>
                <span style={{paddingLeft:"1rem"}}>Apoteka ima manje od 10 ocenjenih rezervacija</span>
            </span>
        </div>
        </>

    return (
        <Media
            queries={{
                small: "(max-width: 699px)",
                large: "(min-width: 700px)"
            }}>
            {matches => (
                <Fragment>
                    {matches.small &&
                        <div style={{margin: "0 0.5rem 0.5rem"}}>
                            {displayedText}
                        </div>
                    }
                    {matches.large &&
                        <div className={stylesStore.ShopInfoBox} style={{height: "100%"}}>
                            <p className={styles.Title}>Rezultat pretrage</p>
                            <div style={{margin: "auto 0", display: "flex", flexDirection: "column", justifyContent: "center"}}>
                                {displayedText}
                            </div>
                        </div>
                    }
                </Fragment>)}
        </Media>
    )
}

const mapStateToProps = (state) => {
    return {
        matchedStores: state.storesSearchPage.stores,
        apotekaRsMarketplaces: state.mainPage.apotekaRsMarketplaces
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onCountMarketplaces: () => dispatch(actions.countMarketplacesAction()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SearchedStoresInfo));
