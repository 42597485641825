import React, {Fragment, useEffect, useState} from "react";
import styles from './MyLoyaltyPoints.module.css';
import {ReactComponent as GoldClockIcon} from "../../../../assets/icons/goldClock.svg";
import {ReactComponent as GreyClockIcon} from "../../../../assets/icons/greyClock.svg";
import {ReactComponent as GreyClockIconMobile} from "../../../../assets/icons/mGreyClock.svg";
import {ReactComponent as GoldClockIconMobile} from "../../../../assets/icons/mGoldClock.svg";
import * as actions from "../../../../store/actions";
import {connect} from "react-redux";
import {Link, withRouter} from "react-router-dom";
import Spinner from "../../../UI/Spinner/Spinner";
import ProgressBar from "@ramonak/react-progress-bar";
import Media from "react-media";

const MyLoyaltyPoints = (props) => {

    const [loyaltyPoints, loyaltyPointsSetter] = useState(<Spinner/>);
    const [mins, setMins] = useState(0);
    const [hours, setHours] = useState(0);
    const [days, setDays] = useState(0);
    const [firstBarFill, setFirstBarFill] = useState(0);
    const [secondBarFill, setSecondBarFill] = useState(0);
    const [timeNeededText, setTimeNeededText] = useState();

    useEffect(() => {
        if (props.userProfile === null) {
            props.onFetchUserProfile(localStorage.getItem('email'))
        } else {
            if (props.userProfile !== null && props.userProfile !== undefined) {
                loyaltyPointsToTime(props.userProfile.userLoyalityPoints / 10);
                // loyaltyPointsToTime(500 / 10);
            }
            loyaltyPointsSetter(props.userProfile !== null &&
                <div className={styles.Points}>
                    {/*<span className={styles.Number}>{props.userProfile.userLoyalityPoints}</span>*/}
                    <span className={styles.Number}>{days}d {hours}h {mins}min</span>
                </div>)
        }
    }, [props.userProfile, mins, hours, days]);

    const loyaltyPointsToTime = (minutes) => {
        setMins(minutes % 60);
        let h = Math.floor(minutes / 60)
        h = h % 24;
        setHours(h);
        setDays(Math.floor(hours / 24))
        // let mins = minutes % 60;
        // let hours = Math.floor(minutes / 60);
        // let days = Math.floor(hours / 24);
        // let hourss = hours % 24;
        setFirstBarFill(minutes);
        //todo odraditi tekstulani ispis minuta i sati u zavisnosti od broja (1 minut, 2 minuta, 1 sat, 2 sata, 5 sati,..)
        if (minutes < 60) {
            setTimeNeededText(<span className={styles.TimeNeededText}>Nedostaje ti još <span className={styles.TimeNeededTime}>{60 - minutes} minuta</span> do sledećeg nivoa</span>)
        } else if (h < 24){
            setTimeNeededText(<span className={styles.TimeNeededText}>Nedostaje ti još <span className={styles.TimeNeededTime}>{24 - h} sati</span> do sledećeg nivoa</span>)
        } else {
            setTimeNeededText(<span className={styles.TimeNeededText}>Dostigli ste maskimalnu vrednost loyalty programa</span>)
        }
        if (h > 0) {
            setSecondBarFill(h)
        }
    }

    const loyaltyProgress =
        <Media
            queries={{
                small: "(max-width: 699px)",
                large: "(min-width: 700px)"
            }}>
            {matches => (
                <Fragment>
                    {matches.small &&
                        <>
                            <div className={styles.ProgressWrap}>
                                <div className={styles.LevelWrapper} style={{top: "-15px", left: "-20px"}}>
                                    <GoldClockIconMobile/>
                                </div>
                                <ProgressBar completed={firstBarFill}
                                             maxCompleted={60}
                                             isLabelVisible={false}
                                             bgColor="#FFAE00"
                                             width="140px"
                                             height="12px"
                                             baseBgColor="#D0D4D9"
                                             margin="6px"
                                />
                                <div className={styles.LevelWrapper}
                                     style={secondBarFill === 0
                                         ? {top: "-7px", left: "132px"}
                                         : {top: "-15px", left: "130px"}
                                }>
                                    {secondBarFill === 0 ? <GreyClockIconMobile/> : <GoldClockIconMobile/>}
                                </div>
                                <ProgressBar completed={secondBarFill}
                                             maxCompleted={24}
                                             isLabelVisible={false}
                                             bgColor="#FFAE00"
                                             width="140px"
                                             height="12px"
                                             baseBgColor="#D0D4D9"
                                             margin="6px"

                                />
                                <div className={styles.LevelWrapper}
                                     style={secondBarFill >= 24
                                         ? {top: "-15px", right: "-10px"}
                                         : {top: "-7px", right: "-6px"}}>
                                    {secondBarFill >= 24 ? <GoldClockIconMobile/> : <GreyClockIconMobile/>}
                                </div>
                            </div>
                            <div className={styles.LevelLabelWrapper}>
                                <span className={styles.LevelLabelText} style={{color: "#FFAE00"}}>Nivo 1</span>
                                <span className={styles.LevelLabelText}
                                      style={firstBarFill >= 60 ? {color: "#FFAE00"} : null}>Nivo 2</span>
                                <span className={styles.LevelLabelText}
                                      style={secondBarFill >= 24 ? {color: "#FFAE00"} : null}>Nivo 3</span>
                            </div>
                        </>
                    }

                    {matches.large &&
                        <>
                            <div className={styles.ProgressWrap}>
                                <div className={styles.LevelWrapper} style={{top: "-27px", left: "-37px"}}>
                                    <GoldClockIcon/>
                                </div>
                                <ProgressBar completed={firstBarFill}
                                             maxCompleted={60}
                                             isLabelVisible={false}
                                             bgColor="#FFAE00"
                                             width="220px"
                                             height="16px"
                                             baseBgColor="#D0D4D9"
                                             margin="8px"
                                />
                                <div className={styles.LevelWrapper}
                                     style={secondBarFill === 0
                                         ? {top: "-12px", left: "208px"}
                                         : {top: "-25px", left: "192px"}
                                }>
                                    {secondBarFill === 0 ? <GreyClockIcon/> : <GoldClockIcon/>}
                                </div>
                                <ProgressBar completed={secondBarFill}
                                             maxCompleted={24}
                                             isLabelVisible={false}
                                             bgColor="#FFAE00"
                                             width="220px"
                                             height="16px"
                                             baseBgColor="#D0D4D9"
                                             margin="8px"

                                />
                                <div className={styles.LevelWrapper} style={{top: "-12px", right: "-20px"}}>
                                    {secondBarFill >= 24 ? <GoldClockIcon/> : <GreyClockIcon/>}
                                </div>
                            </div>
                            <div className={styles.LevelLabelWrapper}>
                                <span className={styles.LevelLabelText} style={{color: "#FFAE00"}}>Nivo 1</span>
                                <span className={styles.LevelLabelText}
                                      style={firstBarFill >= 60 ? {color: "#FFAE00"} : null}>Nivo 2</span>
                                <span className={styles.LevelLabelText}
                                      style={secondBarFill >= 24 ? {color: "#FFAE00"} : null}>Nivo 3</span>
                            </div>
                        </>
                    }
                </Fragment>
            )}
        </Media>

    return (
        <div className={styles.LoyaltyPageWrapper}>
            <div className={styles.LeftPart}>
                <h2>Loyalty minuti</h2>
                <p> Ovde su prikazani tvoji trenutni Loyalty minuti, koje možeš da iskoristiš u svakom momentu.
                    Hvala što si član apoteka.rs porodice i što brigu o tebi i tvojim najbližima deliš sa nama. </p>
                <p>Bliža od najbliže – apoteka.rs!</p>
                <Link to="/aboutLoyalty" style={{color:"#FFAE00", fontWeight:"bold"}}>Saznaj više</Link>
            </div>
            <div className={styles.LoyaltyPointsWrapper}>
                <div className={styles.Mark}>
                    <span>Tvoj loyalty</span>
                </div>
                {loyaltyPoints}
                {timeNeededText}
                {loyaltyProgress}
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        userProfile: state.userProfile.userProfile,
        userProfileError: state.userProfile.userProfileError,
        loadingUserProfile: state.userProfile.loadingUserProfile,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onFetchUserProfile: (email) => dispatch(actions.fetchUserProfile(email)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MyLoyaltyPoints));