import React, {useEffect, useState} from 'react'
import styles from './ProdajnoMestoRadnoVremeForm.module.css'
import Input from '../../../UI/Input/Input'
import Spinner from "../../../UI/Spinner/Spinner";
import axios from '../../../../axios/axios-apoteka'
import * as actions from "../../../../store/actions";
import {connect} from "react-redux";
import Button from '../../../UI/Buttons/Button/Button';
import {XCircleIcon} from "@heroicons/react/outline";

const ProdajnoMestoRadnoVremeForm = (props) => {

    const [inputState, inputStateChanged] = useState({
        openHours: {
            monday: null,
            tuesday: null,
            wednesday: null,
            thursday: null,
            friday: null,
            saturday: null,
            sunday: null,
        },
        nonStop: false,
        saver: {}

    })

    useEffect(() => {
        props.onFetchOpenHoursForStore(props.marketplaceId, props.branchMarketplaceId);
    }, [props.marketplaceId, props.branchMarketplaceId])

    useEffect(() => {
        if (props.openHoursForStore !== null) {
            inputStateChanged({
                openHours: {
                    monday: props.openHoursForStore.monday === null ? '' : props.openHoursForStore.monday,
                    tuesday: props.openHoursForStore.tuesday === null ? '' : props.openHoursForStore.tuesday,
                    wednesday: props.openHoursForStore.wednesday === null ? '' : props.openHoursForStore.wednesday,
                    thursday: props.openHoursForStore.thursday === null ? '' : props.openHoursForStore.thursday,
                    friday: props.openHoursForStore.friday === null ? '' : props.openHoursForStore.friday,
                    saturday: props.openHoursForStore.saturday === null ? '' : props.openHoursForStore.saturday,
                    sunday: props.openHoursForStore.sunday === null ? '' : props.openHoursForStore.sunday

                }
            })
        }

    },[props.openHoursForStore])

    const inputChangedHandler = (event, inputIndentifier) => {
        const updatedInputForm = {
            ...inputState.openHours
        }
        updatedInputForm[inputIndentifier] = event.target.value;
        inputStateChanged({
            openHours: {...updatedInputForm}
        })
    }

    const updateStoreOpenHours = (event) => {
        event.preventDefault();
        let updatedObject = {
            ...inputState.openHours

        };
        let toUpdate = {
            ...props.openHoursForStore
        }
        const nonStopStr = '00:00 do 24:00';
        toUpdate.monday = inputState.nonStop ? nonStopStr : updatedObject.monday === '' ? null : updatedObject.monday;
        toUpdate.tuesday = inputState.nonStop ? nonStopStr : updatedObject.tuesday === '' ? null : updatedObject.tuesday;
        toUpdate.wednesday = inputState.nonStop ? nonStopStr : updatedObject.wednesday === '' ? null : updatedObject.wednesday;
        toUpdate.thursday = inputState.nonStop ? nonStopStr : updatedObject.thursday === '' ? null : updatedObject.thursday;
        toUpdate.friday = inputState.nonStop ? nonStopStr : updatedObject.friday === '' ? null : updatedObject.friday;
        toUpdate.saturday = inputState.nonStop ? nonStopStr : updatedObject.saturday === '' ? null : updatedObject.saturday;
        toUpdate.sunday = inputState.nonStop ? nonStopStr : updatedObject.sunday === '' ? null : updatedObject.sunday;

        console.log(toUpdate)

        const URL = '/marketplace/update/opening_hours/' + props.marketplaceId + "/" + props.branchMarketplaceId ;
        axios.put(URL, toUpdate)
            .then(response => {
                console.log(response);
                props.onOpenHoursChanged(false)

            })
            .catch(error => {
                console.log("Error se desio" + error);

            })

    }

    const closeModalHandler = () => {
        props.onOpenHoursChanged(false);
    }

    const nonStopHandler = () => {
        if (!inputState.nonStop) {
            const saver = {...inputState.openHours};
            const nonStopStr = '00:00 do 24:00';
            const openHoursNonStopValue = {
                monday: nonStopStr,
                tuesday: nonStopStr,
                wednesday: nonStopStr,
                thursday: nonStopStr,
                friday: nonStopStr,
                saturday: nonStopStr,
                sunday: nonStopStr
            }
            inputStateChanged({
                ...inputState,
                openHours: openHoursNonStopValue,
                nonStop: !inputState.nonStop,
                saver: saver
            })
        }
        else {
            inputStateChanged({
                ...inputState,
                openHours: {...inputState.saver},
                nonStop: !inputState.nonStop,
                saver: {}
            })
        }
    }

    let inputs = <Spinner/>;
    if (props.openHoursForStore !== null) {

        inputs = (
            <React.Fragment>
                <div className={styles.ProdajnoMestoRadnoVreme}>
                    <div onClick={closeModalHandler} className={styles.XButton}><XCircleIcon/></div>
                    <h3>Radna vremena ovog objekta</h3>
                    <p>Radna vremena uneti u obliku: 00:00 do 00:00</p>
                    <div className={styles.Days}>
                        {/*<p>Ponedeljak</p>*/}
                        <Input inputtype="input" type="text" name="monday" placeholder={"Ponedeljak"}
                               label="Ponedeljak"
                               value={inputState.openHours.monday}
                               onChange={(event) => inputChangedHandler(event, "monday")}/>
                    </div>
                    <div className={styles.Days}>
                        {/*<p>Utorak</p>*/}
                        <Input inputtype="input" type="text" name="tuesday" placeholder={"Utorak"}
                               label="Utorak"
                               value={inputState.openHours.tuesday}
                               onChange={(event) => inputChangedHandler(event, "tuesday")}/>
                    </div>
                    <div className={styles.Days}>
                        {/*<p>Sreda</p>*/}
                        <Input inputtype="input" type="text" name="wednesday" placeholder={"Sreda"}
                               label = "Sreda"
                               value={inputState.openHours.wednesday}
                               onChange={(event) => inputChangedHandler(event, "wednesday")}/>
                    </div>
                    <div className={styles.Days}>
                        {/*<p>Cetvrtak</p>*/}
                        <Input inputtype="input" type="text" name="thursday" placeholder={"Četvrtak"}
                               label="Četvrtak"
                               value={inputState.openHours.thursday}
                               onChange={(event) => inputChangedHandler(event, "thursday")}/>
                    </div>
                    <div className={styles.Days}>
                        {/*<p>Petak</p>*/}
                        <Input inputtype="input" type="text" name="friday" placeholder={"Petak"}
                               label = "Petak"
                               value={inputState.openHours.friday}
                               onChange={(event) => inputChangedHandler(event, "friday")}/>
                    </div>
                    <div className={styles.Days}>
                        {/*<p>Subota</p>*/}
                        <Input inputtype="input" type="text" name="saturday" placeholder={"Subota"}
                               label="Subota"
                               value={inputState.openHours.saturday}
                               onChange={(event) => inputChangedHandler(event, "saturday")}/>
                    </div>
                    <div className={styles.Days}>
                        {/*<p>Nedelja</p>*/}
                        <Input inputtype="input" type="text" name="sunday" placeholder={"Nedelja"}
                               label="Nedelja"
                               value={inputState.openHours.sunday}
                               onChange={(event) => inputChangedHandler(event, "sunday")}/>
                    </div>
                    <div className={styles.Days}>
                        {/*<p>Nedelja</p>*/}
                        <Input inputtype="checkbox" type="checkbox" name="nonStop"
                               label="Radi non-stop"
                               style={{opacity:"1", width:"fit-content"}}
                               checked={inputState.nonStop}
                               onChange={nonStopHandler}/>
                    </div>
                </div>

            </React.Fragment>
        );

    }

    return (
        <div className={styles.ProdajnoMestoForm}>
            {/*{props.storeDetails !== null && <h3>{props.storeDetails.naziv}</h3>}*/}
            <form>
                {inputs}
                <Button buttontype='whiteblue' onClick={updateStoreOpenHours} style={{float:"right", padding:"8px"}}>Sačuvaj radna vremena</Button>
            </form>
        </div>

    );
}


const mapStateToProps = (state) => {
    return {
        openHoursForStore: state.appPage.openHoursForStore,
        openHoursForStoreError: state.appPage.openHoursForStoreError,
        loadingStoreOpenHours: state.appPage.loadingStoreOpenHours
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onFetchOpenHoursForStore: (marketplaceId, branchMarketplaceId) => dispatch(actions.fetchOpenHoursForStore(marketplaceId, branchMarketplaceId)),
        onOpenHoursChanged: (openHoursChanged) => dispatch(actions.openHoursModalChanged(openHoursChanged))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProdajnoMestoRadnoVremeForm);
