import React from 'react';
import styles from './TherapyArticlesMobile.module.css';
import HeaderWithTitleAndBackChevron
    from "../../../../componentsRes/HeaderWithTitleAndBackChevron/HeaderWithTitleAndBackChevron";
import TherapyArticle from "./TherapyArticle/TherapyArticle";

const TherapyArticlesMobile = (props) => {

    const closeTherapyDetails = () => {
        props.therapyDeselectedCallback();
    }

    return (
        <div className={styles.TherapyArticlesMobile}>
            <HeaderWithTitleAndBackChevron icon title="Terapija" onIconClick={closeTherapyDetails}/>
            <div style={{overflowY:"auto", height:"calc(100vh - 106px - 5rem)"}}>
            {props.therapy && props.therapy.items.map((item, index) => {
                return(
                    <TherapyArticle article={item} key={index}/>
                );
            })}
            </div>
        </div>
    );
};

export default TherapyArticlesMobile;
