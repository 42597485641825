import * as actionsTypes from './actionsTypes'
import axios from '../../axios/axios-apoteka'

export const allSearchedStores = (stores) => {
    return {
        type: actionsTypes.ALL_SEARCHED_STORES,
        stores: stores

    };
}

export const sliceOfSearchedStores = (stores) => {
    return {
        type: actionsTypes.SLICE_OF_SEARCHED_STORES,
        stores: stores

    };
}

export const fetchAllSearchedStoresFailed = () => {
    return {
        type: actionsTypes.FETCH_ALL_SEARCHED_STORES_FAIlED
    };
}

export const fetchAllSearchedStoresServerError = () => {
    return {
        type: actionsTypes.FETCH_ALL_SEARCHED_STORES_SERVER_ERROR
    };
}

export const fetchAllSearchedStoresStart = () => {
    return {
        type: actionsTypes.FETCH_ALL_SEARCHED_STORES_START
    };
}

//isti filter
export const fetchAllSearchedStores = (storesIds, page, filter) => {
    return dispatch => {
        dispatch(fetchAllSearchedStoresStart());
        const URL = '/marketplace/page/' + page + "?numOfReturns=32" + filter;
        axios.post(URL, storesIds)
            .then(response => {
                dispatch(allSearchedStores(response.data))
            })
            .catch(error => {
                if (error.response !== null && error.response !== undefined  && error.response.data === "") {
                    dispatch(fetchAllSearchedStoresFailed())
                    console.log("error " + error);
                }
                else {
                    dispatch(fetchAllSearchedStoresServerError());
                }
            })
    };
}

export const fetchSliceOfSearchedStores = (storesIds, page, filter) => {
    return dispatch => {
        dispatch(fetchAllSearchedStoresStart());
        const URL = '/marketplace/slice/' + page + "/?numOfReturns=32" + filter;
        axios.post(URL, storesIds)
            .then(response => {
                dispatch(sliceOfSearchedStores(response.data))
            })
            .catch(error => {
                if (error.response !== null && error.response !== undefined  && error.response.data === "") {
                    dispatch(fetchAllSearchedStoresFailed())
                    console.log("Bacio error");
                }
                else {
                    dispatch(fetchAllSearchedStoresServerError());
                }
            })
    };
}


//ACTIONS FOR FETCHING STORES IN RADIUS
export const allSearchedStoresInRadius = (stores) => {
    return {
        type: actionsTypes.ALL_SEARCHED_STORES_IN_RADIUS,
        stores: stores

    };
}

//isti filter
export const fetchAllSearchedStoresInRadius = (storesIds, latitude, longitude, filter) => {
    return dispatch => {
        dispatch(fetchAllSearchedStoresStart());

        const URL = '/marketplace/in_radius'
            + '?' + "latitude=" + latitude
            + '&' + "longitude=" + longitude
            + filter;

        axios.post(URL, storesIds)
            .then(response => {
                dispatch(allSearchedStoresInRadius(response.data))
                console.log(response);
            })
            .catch(error => {
                if (error.response !== null && error.response !== undefined  && error.response.data === "") {
                    console.log('error ' + error);
                    dispatch(fetchAllSearchedStoresFailed());
                }
                else {
                    console.log('error ' + error);
                    dispatch(fetchAllSearchedStoresServerError());
                }
            })
    };
}

// akcije za fetch svih apoteka
export const allStores = (stores) => {
    return {
        type: actionsTypes.ALL_STORES_OBJECTS,
        stores: stores

    };
}

export const sliceOfStores = (stores) => {
    return {
        type: actionsTypes.SLICE_OF_ALL_STORES_OBJECTS,
        stores: stores

    };
}

export const fetchAllStoresFailed = () => {
    return {
        type: actionsTypes.FETCH_ALL_STORES_OBJECTS_FAILED
    };
}

export const fetchAllStoresServerError = () => {
    return {
        type: actionsTypes.FETCH_ALL_STORES_OBJECTS_SERVER_ERROR
    };
}

export const fetchAllStoresStart = () => {
    return {
        type: actionsTypes.FETCH_ALL_STORES_OBJECTS_START
    };
}

//PRIMER SVI FILTERI: filter=location:'Beograd' and isClickCollect:1 and haveDelivery:1 and openingHours.monday:'00:00 do 24:00'
export const fetchAllStores = (page, filter) => {
    return dispatch => {
        dispatch(fetchAllStoresStart());
        const URL = '/marketplace/page/'+ page + "/?numOfReturns=32" + filter;
        // const URL = '/marketplace/in_radius/page/'+ page + "/?numOfReturns=32" + filter;
        // za in radius kada ima adresu
        console.log('URL iz reduxa')
        console.log(URL)
        axios.get(URL)
            .then(response => {
                console.log(response);
                dispatch(allStores(response.data))

            })
            .catch(error => {
                if (error.response !== null && error.response !== undefined  && error.response.data === "") {
                    dispatch(fetchAllStoresFailed())
                }
                else {
                    dispatch(fetchAllStoresServerError());
                }
            })
    };
}

export const fetchSliceOfStores = (page, filter) => {
    return dispatch => {
        dispatch(fetchAllStoresStart());
        const URL = '/marketplace/slice/'+ page + "/?numOfReturns=32" + filter;
        // const URL = '/marketplace/in_radius/slice/'+ page + "/?numOfReturns=32" + filter;
        // za inradius kada ima adresu za
        axios.get(URL)
            .then(response => {
                dispatch(sliceOfStores(response.data))
                console.log(response);
            })
            .catch(error => {
                if (error.response !== null && error.response !== undefined  && error.response.data === "") {
                    dispatch(fetchAllStoresFailed())
                }
                else {
                    dispatch(fetchAllStoresServerError());
                }
            })
    };
}

//ACTIONS FOR FETCHING ALL STORES IN MY RADIUS
export const allStoresInRadius = (stores) => {
    return {
        type: actionsTypes.ALL_STORES_IN_RADIUS,
        stores: stores

    };
}

//ima filter kao gore
export const fetchAllStoresInRadius = (latitude, longitude, filter, page) => {
    return dispatch => {
        dispatch(fetchAllStoresStart());

        const URL = '/marketplace/in_radius/page/' + page
            + '?' + "latitude=" + latitude
            + '&' + "longitude=" + longitude
            + filter;

        axios.get(URL)
            .then(response => {
                dispatch(allStoresInRadius(response.data))
                console.log(response);
            })
            .catch(error => {
                if (error.response !== null && error.response !== undefined  && error.response.data === "") {
                    dispatch(fetchAllStoresFailed());
                }
                else {
                    dispatch(fetchAllStoresServerError());
                }
            })
    };
}

//ACTIONS FOR DETERMINING WHETHER inMyRadius IS CALLED
export const fetchStores = ({storesIds, page, filter, latitude, longitude, modal = false}) => {
    return dispatch => {
        if(modal) {
            dispatch(fetchAllStoresInRadiusModal({latitude, longitude, filter}))
        } else {
            if (latitude && longitude) {
                if (storesIds) {
                    dispatch(fetchAllSearchedStoresInRadius(storesIds, latitude, longitude, filter))
                } else dispatch(fetchAllStoresInRadius(latitude, longitude, filter, page));
            } else {
                if (storesIds) {
                    dispatch(page > 0 ? fetchSliceOfSearchedStores(storesIds, page, filter) : fetchAllSearchedStores(storesIds, page, filter));
                } else
                    dispatch(page > 0 ? fetchSliceOfStores(page, filter) : fetchAllStores(page, filter));
            }
        }
    };
}

// fetch all stores for rfzo modal
export const allStoresForModal = (storesForModal) => {
    return {
        type:actionsTypes.FETCH_ALL_STORES_FOR_MODAL,
        storesForModal: storesForModal
    }
}

export const fetchAllStoresModalStart =()=> {
    return {
        type: actionsTypes.FETCH_ALL_STORES_FOR_MODAL_START
    }
}

export const fetchAllStoresModalFailed =()=> {
    return {
        type: actionsTypes.FETCH_ALL_STORES_FOR_MODAL_FAILED
    }
}

export const fetchAllStoresInRadiusModal =({latitude, longitude, filter}) => {

    return dispatch => {
        dispatch(fetchAllStoresModalStart());

        const URL = '/marketplace/in_radius/page/0'
            + '?' + "latitude=" + latitude
            + '&' + "longitude=" + longitude
            + '&' + "numOfReturns="  + 20
            + filter;

        axios.get(URL)
            .then(response => {
                dispatch(allStoresForModal(response.data))
                console.log(response);
            })
            .catch(error => {
                if (error.response !== null && error.response !== undefined  && error.response.data === "") {
                    dispatch(fetchAllStoresModalFailed());
                }
                else {
                    dispatch(fetchAllStoresServerError());
                }
            })
    };
}