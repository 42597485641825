import React from "react";
import {withRouter} from 'react-router-dom'
import * as actions from "../../../../store/actions";
import {connect} from "react-redux";
import {textToUrl} from "../../../GobalFunctions/GlobalFunctions";
import ArticleItem from "../../../ShopPage/ArticleItems/ArticleItem/ArticleItem";

const RelatedArticle = (props) => {

    let article = {
        itemPrice: null,
        itemResponse: {...props.article}
    }

    const selectHandler = () => {
        let url;
        if (props.match.params.branchMarketplaceId === undefined || props.match.params.marketplaceId === undefined) {
            url = '/articles-info/' + props.article.jkl + '/' + props.article.id + '/' + textToUrl(props.article.name);
            props.onArticleDetailsForInfoPage(props.article);
        } else {
            url = '/articles-info/' + props.article.jkl + '/' + props.article.id + '/' +
                props.match.params.branchMarketplaceId + '/' + props.match.params.marketplaceId + '/' + textToUrl(props.article.name);
        }
        props.history.push(url)
    }

    return (
        article &&
        <ArticleItem articleItem={article} onClick={selectHandler}/>
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        onArticleDetailsForInfoPage: (articleDetails) => dispatch(actions.articleDetailsForInfoPage(articleDetails)),
    }
}

export default connect(null, mapDispatchToProps)(withRouter(RelatedArticle));