import React, {useEffect} from 'react'
import styles from './GoodbyePage.module.css'
import Toolbar from "../Navigation/Toolbar/Toolbar";
import { Link } from 'react-router-dom'
import * as actions from "../../store/actions";
import {connect} from "react-redux";
import Wrapper from "../UI/Wrapper/Wrapper";
import Button from "./../UI/Buttons/Button/Button";

const GoodbyePage = (props) => {



    useEffect(()=>{

            props.onLogout();
            props.onResetUserInfo(null);
            props.onResetUserProfile(null);

        }, [])

    return (
        <Wrapper>
            <Toolbar />
            <div className={styles.GoodbyePageWrapper}>
                <div className={styles.MessageBox}>
                    <p>Vaš profil je obrisan.</p>
                    <p>Hvala što ste koristili portal <strong>apoteka.rs</strong>.</p>
                    <br/>
                    <Button buttontype="blue">
                        <Link to={'/'}>Povratak na početnu stranu</Link>
                    </Button>

                </div>
            </div>

        </Wrapper>
    )

}

const mapDispatchToProps = dispatch => {
    return {
        onDeleteUserProfile: (username) => dispatch(actions.deleteUserProfile(username)),
        onLogout: () => dispatch(actions.logout()),
        onResetUserInfo: (userInfo) => dispatch(actions.userInfo(userInfo)),
        onResetUserProfile: (userProfile) => dispatch(actions.userProfile(userProfile))

    }
}

export default connect(null, mapDispatchToProps)(GoodbyePage)