import React, {useEffect, useState} from 'react'
import OrderCompletionPageTemplateMobile from "../OrderCompletionPageTemplateMobile/OrderCompletionPageTemplateMobile";
import HeaderWithTitleAndBackChevron
    from "../../../../componentsRes/HeaderWithTitleAndBackChevron/HeaderWithTitleAndBackChevron";
import styles from "./DeliveryOptionsMobile.module.css";
import ButtonRes from "../../../../componentsRes/UIRes/ButtonsRes/ButtonRes/ButtonRes";
import {ReactComponent as PharmacySignIcon} from "../../../../assets/icons/pharmacy_cross_icon.svg";
import {ReactComponent as DeliveryIcon} from "../../../../assets/icons/delivery.svg";
import * as actions from "../../../../store/actions";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";

const DeliveryOptionsMobile = (props) => {

    const [unableToDeliver, unableToDeliverSetter] = useState("Ova apoteka ne vrši isporuku.");
    const [haveDrugInCart, haveDrugInCartSetter] = useState(localStorage.getItem('haveDrugInCart') || false)

    useEffect(() => {
        /**
         *  Moraju da se ucitaju podaci o apoteci ponovo jer se prilikom izlaska sa ShopPage.js brise selectedStore
         * */
        props.onFetchSelectedStore(props.match.params.marketplaceId, props.match.params.branchMarketplaceId);
    }, []);

    useEffect(()=>{
        props.shoppingCart !== null && props.shoppingCart.items.map(item => {
            if(item.itemResponse.jkl !== null) {
                haveDrugInCartSetter(true);
                localStorage.setItem('haveDrugInCart', 'true')
            }
        })

        return() =>{
            localStorage.removeItem('haveDrugInCart');
        }
    }, [])

    useEffect(()=> {
        if (props.selectedStore !== null && +props.selectedStore.marketplaceResponse.haveDelivery !== 1) {
            unableToDeliverSetter("Ova apoteka ne vrši isporuku.")
        } else if (localStorage.getItem('haveDrugInCart') === 'true') {
            unableToDeliverSetter("Dostava nije moguća za lekove");
        }
    }, [])

    const collectInPharmacyMobile = () => {
        console.log("collectInPharm")
        localStorage.setItem('deliveryMethod', 'collectInPharmacy');
        props.onCollectOrDeliveryChoice({collect: true, delivery: false});
        redirectToUserInfoPage();
    }

    const deliveryToSelectedAddressMobile = () => {
        console.log("deliveryToAddress");
        localStorage.setItem('deliveryMethod', 'deliveryToAddress');
        props.onCollectOrDeliveryChoice({collect: false, delivery: true});
        redirectToUserInfoPage();
    }

    const redirectToUserInfoPage = () => {
        const url = '/orderCompletion/' + props.match.params.branchMarketplaceId + "/" + props.match.params.marketplaceId + '/userInfo'
            + '/' + props.match.params.branchMarketplaceName + '/' + props.match.params.marketplaceName;
        props.history.push(url);
    }

    const redirectToPreviousPage =()=> {
        const url = '/shoppingCart/' + props.match.params.branchMarketplaceId + "/" + props.match.params.marketplaceId
            + '/' + props.match.params.branchMarketplaceName + '/' + props.match.params.marketplaceName;
        props.history.push(url);
        localStorage.removeItem('haveDrugInCart');
    }
    
    return (
        <OrderCompletionPageTemplateMobile
            header={<HeaderWithTitleAndBackChevron
                icon
                onIconClick={redirectToPreviousPage}
                title="Način preuzimanja"
            />}
        >
            <div className={styles.DeliveryOptionsHolderMobile}>
                <div className={styles.MessageHeader}>Izaberi način preuzimanja</div>
                <div className={styles.ChoiceWrapper}>
                    <>
                        <ButtonRes buttontype="big-button" onClick={collectInPharmacyMobile} style={{marginBottom:"1rem"}}>
                            <PharmacySignIcon/>
                            <span style={{color: "#013A63"}}>Preuzimanje u apoteci</span>
                        </ButtonRes>
                    </>

                    {/* ISPORUKA NA ZELJENU ADRESU */}
                    {/*{props.selectedStore !== null && +props.selectedStore.marketplaceResponse.haveDelivery === 1 ?*/}
                    <>
                        {((props.selectedStore !== null && +props.selectedStore.marketplaceResponse.haveDelivery === 1) && !(localStorage.getItem('haveDrugInCart') === 'true'))
                            ?
                            <ButtonRes buttontype="big-button" onClick={deliveryToSelectedAddressMobile}>
                                <DeliveryIcon style={{stroke:"#013A63"}}/>
                                <span style={{color: "#013A63"}}>Isporuka na adresu</span>
                            </ButtonRes>
                            :
                            <>
                                <ButtonRes buttontype="big-button" disabled>
                                    <DeliveryIcon style={{stroke:"#bdccd4ff"}}/>
                                    <span style={{color: "#bdccd4ff"}}>Isporuka na adresu</span>
                                </ButtonRes>
                                <div style={{
                                    padding: "1rem 0",
                                    display: "flex",
                                    justifyContent: "center"
                                }}>
                                    <div style={{color: "#bdccd4ff"}}>
                                        <div>
                                            {unableToDeliver}
                                        </div>
                                        {/*<div>Ova apoteka ne vrši isporuku.</div>*/}
                                    </div>
                                </div>
                            </>
                        }
                    </>                  
                </div>
            </div>
        </OrderCompletionPageTemplateMobile>
    )
}

const mapStateToProps = (state) => {
    return {
        selectedStore: state.appPage.selectedStore,
        collectOrDelivery: state.orderCompletion.collectOrDelivery,
        shoppingCart: state.shopPage.shoppingCart
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onCollectOrDeliveryChoice: (collectOrDelivery) => dispatch(actions.collectOrDeliveryChoice(collectOrDelivery)),
        onFetchSelectedStore: (branchMarketplaceId, marketplaceId) => dispatch(actions.fetchSelectedStore(branchMarketplaceId, marketplaceId)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DeliveryOptionsMobile));