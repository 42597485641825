import React from 'react'
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import styles from "./StoreItemsError.module.css"
import SearchInputRequest
    from "../../../../SearchArticlesResultsPage/UnknownItem/SearchInputRequest/SearchInputRequest";

const StoreItemsError = (props) => {

    return (
        <div className={styles.StoreItemsError}>
            <div className={styles.SearchResultError}>
                <p className={styles.SearchResult0}>Pretraga apoteka - 0 </p>
                <p className={styles.SearchResultUnavailable}>Nema na stanju</p>
            </div>
            {window.location.pathname.includes('searched-stores') ?
                <div className={styles.SearchResultErrorMessage}>Nijedna apoteka ne odgovara parametrima pretrage.</div>
                :
                <SearchInputRequest inputText={props.articleDetails && props.articleDetails.name}/>
            }
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        articleDetails: state.appPage.articleDetails,
    }
}

export default connect(mapStateToProps)(withRouter(StoreItemsError));
