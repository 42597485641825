import React, {useRef, useEffect} from 'react';
import styles from '../SearchItem/SearchItem.module.css'
import {connect} from 'react-redux';
import * as actions from "../../../../../store/actions";
import { withRouter} from 'react-router-dom'
import {ReactComponent as SearchIcon} from "../../../../../assets/icons/search_icon.svg";

const SearchedStringSearchItem = React.memo(props => {

    const fieldRef = useRef(null);


    // Detektuje prmenu u selekciji (key up i key down) i pomera scroll ako se taj item ne vidi
    useEffect(() =>{

        if (props.selectedIndex === 0 && props.index === 1) {
            fieldRef.current.scrollIntoView({
                behavior: "auto",
                block: 'nearest',
                inline: 'start'
            });
        }

        if (props.selectedIndex === props.index ) {
            fieldRef.current.scrollIntoView({
                behavior: "smooth",
                block: 'nearest',
                inline: 'start'
            });
        }
    }, [props.selectedIndex])

    useEffect(() => {

        //Check if we select article
        if (props.isSelected && props.selectedIndex === props.index){
            let url;

            if (props.history.location.pathname.includes("/shop/")){
                url = '/shop/' + props.match.params.branchMarketplaceId + '/' + props.match.params.marketplaceId + '/0/' + props.searchString + '/' + props.match.params.branchMarketplaceName + '/' + props.match.params.marketplaceName;
            }
            else {
                if ((props.onMainPage && props.searchArticles) || window.location.pathname.includes('searched-articles')){
                    url = '/searched-articles/0/' + props.searchString;
                }
                if ((props.onMainPage && !props.searchArticles) || window.location.pathname.includes('searched-stores')) {
                    url = '/searched-stores/0/' +  props.searchString;
                }
                if ((!props.onMainPage && props.searchArticles) || window.location.pathname.includes('articles-and-stores')){
                    url = '/searched-articles/0/' + props.searchString;
                }
            }

            props.history.push(url)
            props.onSelectDetected(false)
        }
    }, [props.isSelected])

    const selectArticleHandler = () => {
        let url1;
        if (props.history.location.pathname.includes("/shop/")){
            url1 = '/shop/' + props.match.params.branchMarketplaceId + '/' + props.match.params.marketplaceId + '/0/' + props.searchString + '/' + props.match.params.branchMarketplaceName + '/' + props.match.params.marketplaceName;
        }
        else {
            if ((props.onMainPage && props.searchArticles) || window.location.pathname.includes('searched-articles')){
                url1 = '/searched-articles/0/' + props.searchString;
            }
            if ((props.onMainPage && !props.searchArticles) || window.location.pathname.includes('searched-stores')) {
                url1 = '/searched-stores/0/' +  props.searchString;
            }
            if ((!props.onMainPage && props.searchArticles) || window.location.pathname.includes('articles-and-stores')){
                url1 = '/searched-articles/0/' + props.searchString;
            }
        }
        props.history.push(url1);

    }

    return (
        <div className={props.onMainPage ? styles.SearchItemMainPage : [styles.SearchItemMainPage, styles.SearchItemAppPage].join(' ')}>
            <a className={props.selectedIndex === props.index ? styles.Selected : null} onMouseDown={selectArticleHandler} ref={fieldRef} style={{width:"100%"}}>
                <div className={styles.SearchItemImage}>
                    <SearchIcon style={window.innerWidth >= 700 ? {fontSize:"1.75rem"} : {fontSize:"1.5rem"}} />
                </div>
                <span>{props.searchString}</span>
            </a>

        </div>
    );
})

const mapStateToProps = (state) => {
    return {
        searchString: state.mainPage.searchString,
        selectedIndex: state.mainPage.selectedIndex,
        isSelected: state.mainPage.isSelected,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onSelectDetected: (isSelected) => dispatch(actions.selectDetected(isSelected)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SearchedStringSearchItem));
