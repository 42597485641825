import React, {useEffect, useState} from 'react'
import styles from './ChooseLocation.module.css';
import * as actions from "../../../store/actions";
import {connect} from "react-redux";
import GoogleAutocomplete from "../GoogleAutocomplete/GoogleAutocomplete";
import Button from "../../UI/Buttons/Button/Button";
import {XIcon} from "@heroicons/react/outline";
import {ReactComponent as UseYourLocationIcon} from "./../../../assets/icons/useYourCurrentLocation_icon.svg";
import ModalWithHeader from "../../UI/Modals/ModalWithHeader/ModalWithHeader";
import {withRouter} from "react-router-dom";
import Geocode from "react-geocode";


const ChooseLocation = (props) => {

    const [place, setPlace] = useState(null);
    const [myAddress, setMyAddress] = useState(localStorage.getItem('enteredAddress') || 'Pretraga po mojoj lokaciji');
    const [flag, setFlag] = useState(false);

    // set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
    // Geocode.setApiKey("AIzaSyDgpS6zEWrUld1-aIgqKgS6dJY8z1CT1s8");  --- ovaj je bio pre implementacije Geocode biblioteke
    Geocode.setApiKey("AIzaSyCpJZvkDLC3WlGYN8SPquDYoisjFAKLjDk");

    const saveAddress = (data) => {
        setFlag(true);
        setPlace(data);
    }

    // ako pretraga adrese treba da se aktivira klikom na dugme Sacuvaj, zakomentarisati ovaj useEffect
    useEffect(() => {
        if (place !== null && flag) {
            saveAddressToToolbar()
            setFlag(false)
        }
    }, [place])

    const saveAddressToToolbar = () => {
        if (place !== null && place !== undefined) {
            props.onEnteredAddressData(place);
            localStorage.setItem("enteredAddress", place.formatted_address.split(',')[0] + ',' + place.formatted_address.split(',')[1]);
            localStorage.setItem("addressLongitude", place.geometry.location.lng());
            localStorage.setItem("addressLatitude", place.geometry.location.lat());
        }
        props.onChooseLocationModalToggle(false);
    }

    const removeAddress = () => {
        localStorage.removeItem("enteredAddress");
        localStorage.removeItem("addressLongitude");
        localStorage.removeItem("addressLatitude");
        props.onEnteredAddressData({
            formatted_address: null,
            lat: null,
            lng: null
        });
        setPlace(null);
    }


//---------- using Geocode ------------------


    const useMyLocation = () => {

        const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

        if (isSafari) {
            console.log("Safari")
            navigator.geolocation.getCurrentPosition(success, errors, options);
        } else {
            if (navigator.geolocation) {
                let loading = false;
                navigator.permissions
                    .query({name: "geolocation"})
                    .then(function (result) {
                        if (result.state === "granted") {
                            props.onFetchUserLocationStart();
                            navigator.geolocation.getCurrentPosition(success);
                            console.log('GRANTED')
                            //If granted then you can directly call your function here
                            console.log(success);
                        } else if (result.state === "prompt") {
                            props.onFetchUserLocationStart();
                            navigator.geolocation.getCurrentPosition(success, errors, options);
                            console.log('PROMPT')
                        } else if (result.state === "denied") {
                            console.log(result.state);

                            alert('Za korišćenje ove funkcionalnosti moraš omogućiti pristup svojoj lokaciji u pretraživaču.')
                        }
                        result.onchange = function () {
                            console.log(result.state);
                        };
                    });
            } else {
                alert("Sorry Not available!");
            }
        }
    }

    let options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
    };

    function success(pos) {
        let params = {
            formatted_address: myAddress,
            lat: pos.coords.latitude,
            lng: pos.coords.longitude,
        }
        let address

        Geocode.fromLatLng(params.lat, params.lng).then(
            (response) => {
                address = response.results[0].formatted_address;
                params = {...params, formatted_address: address}
                props.onEnteredAddressData(params);
                localStorage.setItem("enteredAddress", address);
                localStorage.setItem("addressLongitude", params.lng);
                localStorage.setItem("addressLatitude", params.lat);
                props.onChooseLocationModalToggle(false);
                props.onFetchUserLocationFinished();
            },
            (error) => {
                console.error(error);
            }
        );
    }

    function errors(err) {
        props.onFetchUserLocationError();
        console.warn(`ERROR(${err.code}): ${err.message}`);
    }


// set response language. Defaults to english.
    Geocode.setLanguage("bs");

// set response region. Its optional.
// A Geocoding request with region=es (Spain) will return the Spanish city.
    Geocode.setRegion("sr");

// set location_type filter . Its optional.
// google geocoder returns more that one address for given lat/lng.
// In some case we need one address as response for which google itself provides a location_type filter.
// So we can easily parse the result for fetching address components
// ROOFTOP, RANGE_INTERPOLATED, GEOMETRIC_CENTER, APPROXIMATE are the accepted values.
// And according to the below google docs in description, ROOFTOP param returns the most accurate result.
    Geocode.setLocationType("ROOFTOP");

// Enable or disable logs. Its optional.
    Geocode.enableDebug();

// Get address from latitude & longitude.
    // Geocode.fromLatLng(place.geometry.location.lat(), place.geometry.location.lng()).then(
    //     (response) => {
    //         const address = response.results[0].formatted_address;
    //         console.log(address);
    //     },
    //     (error) => {
    //         console.error(error);
    //     }
    // );

// Get formatted address, city, state, country from latitude & longitude when
// Geocode.setLocationType("ROOFTOP") enabled
// the below parser will work for most of the countries
//     Geocode.fromLatLng(place.geometry.location.lat(), place.geometry.location.lng()).then(
//         (response) => {
//             const address = response.results[0].formatted_address;
//             let city, state, country;
//             for (let i = 0; i < response.results[0].address_components.length; i++) {
//                 for (let j = 0; j < response.results[0].address_components[i].types.length; j++) {
//                     switch (response.results[0].address_components[i].types[j]) {
//                         case "locality":
//                             city = response.results[0].address_components[i].long_name;
//                             break;
//                         case "administrative_area_level_1":
//                             state = response.results[0].address_components[i].long_name;
//                             break;
//                         case "country":
//                             country = response.results[0].address_components[i].long_name;
//                             break;
//                     }
//                 }
//             }
//             console.log(city, state, country);
//             console.log(address);
//         },
//         (error) => {
//             console.error(error);
//         }
//     );


// Get latitude & longitude from address.
//     Geocode.fromAddress("Eiffel Tower").then(
//         (response) => {
//             const { lat, lng } = response.results[0].geometry.location;
//             console.log(lat, lng);
//         },
//         (error) => {
//             console.error(error);
//         }
//     );

//---------- end of using geocode


// use my location
//
//     const useMyLocation = () => {
//
//             const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
//
//             if (isSafari) {
//                 console.log("Safari")
//                 navigator.geolocation.getCurrentPosition(success, errors, options);
//             } else {
//                 if (navigator.geolocation) {
//                     let loading = false;
//                     navigator.permissions
//                         .query({name: "geolocation"})
//                         .then(function (result) {
//                             if (result.state === "granted") {
//                                 props.onFetchUserLocationStart();
//                                 navigator.geolocation.getCurrentPosition(success);
//                                 console.log('GRANTED')
//                                 //If granted then you can directly call your function here
//                                 // console.log(success);
//                             } else if (result.state === "prompt") {
//                                 props.onFetchUserLocationStart();
//                                 navigator.geolocation.getCurrentPosition(success, errors, options);
//                                 console.log('PROPMT')
//                             } else if (result.state === "denied") {
//                                 console.log(result.state);
//
//                                 alert('Za korišćenje ove funkcionalnosti moraš omogućiti pristup svojoj lokaciji u pretraživaču.')
//                             }
//                             result.onchange = function () {
//                                 console.log(result.state);
//                             };
//                         });
//                 } else {
//                     alert("Sorry Not available!");
//                 }
//             }
//     }
//
//
//     let options = {
//         enableHighAccuracy: true,
//         timeout: 5000,
//         maximumAge: 0,
//     };
//
//     const fetchData = async (url) => {
//         try {
//             const response = await fetch(url);
//             const json = await response.json();
//             switch (json.status){
//                 case ("OK"):
//                     console.log('qwerty')
//                     console.log(json.results[0])
//                     localStorage.setItem('enteredAddress', json.results[0].formatted_address)
//                     saveResponseToToolbar(json.results[0]);
//                     setMyAddress(json.results[0].formatted_address);
//                     break;
//                 case ("ZERO_RESULTS"):
//                     alert("Lokacija nije nađena.");
//                     break;
//                 case ("REQUEST_DENIED"):
//                     alert('Zahtev je odbijen.')
//                     break;
//                 default:
//                     alert("Došlo je do greške: " + json.status)
//             }
//         } catch (error) {
//             console.log("error", error);
//         }
//     };
//
//     useEffect(() => {
//         if (props.place && reverseFlag) {
//             console.log('usao u use effect')
//             let lat = props.place.lat;
//             let long = props.place.lng;
//             console.log(lat, long)
//             if (lat) {
//                 const url = 'https://maps.googleapis.com/maps/api/geocode/json?' + 'latlng=' + lat + ',' + long + '&language=bs&location_type=ROOFTOP&result_type=street_address&key=AIzaSyDgpS6zEWrUld1-aIgqKgS6dJY8z1CT1s8';
//                 fetchData(url);
//                 setReverseFlag(false);
//             }
//         }
//
//     }, [props.place])
//
//     function success(pos) {
//         let params = {
//             formatted_address: myAddress,
//             lat: pos.coords.latitude,
//             lng: pos.coords.longitude,
//         }
//         console.log('params')
//         console.log(params)
//         setReverseFlag(true)
//         props.onEnteredAddressData(params);
//         localStorage.setItem("enteredAddress", params.formatted_address);
//         localStorage.setItem("addressLongitude", params.lng);
//         localStorage.setItem("addressLatitude", params.lat);
//         props.onChooseLocationModalToggle(false);
//
//         props.onFetchUserLocationFinished();
//     }
//
//     function errors(err) {
//         props.onFetchUserLocationError();
//         console.warn(`ERROR(${err.code}): ${err.message}`);
//     }

//---------------- .end of use my location

    const closeModal = () => {
        props.onChooseLocationModalToggle(false)
    }

    return (
        props.chooseLocationModalToggle &&
        <>
            <ModalWithHeader
                noXIcon
                show={props.chooseLocationModalToggle}
                backdropClickHandler={closeModal}
                buttons={
                    <>
                        <Button onClick={closeModal}>Preskoči ovaj korak</Button>
                        <Button buttontype="blue"
                                onClick={saveAddressToToolbar}
                        >Sačuvaj</Button>
                    </>
                }
            >
                <div className={styles.ChooseLocationWrapper}>
                    <div>
                        <h2>Unesi svoju adresu</h2>
                        <p>Unesi svoju adresu kako bismo pronašli tebi najbliže apoteke.</p>


                        {props.place.formatted_address !== null &&
                            <>
                                <p style={{marginBottom: "0"}}>Trenutno izabrano:</p>
                                <div className={styles.ChosenAddress}>
                                    {props.place.formatted_address}
                                    <XIcon className={styles.RemoveAddress} onClick={removeAddress}/>
                                </div>
                                <br/>
                            </>
                        }

                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            alignItems: "center"
                        }}>
                            <UseYourLocationIcon/> &nbsp;
                            <p style={{
                                margin: "0, auto",
                                lineHeight: "100%",
                                textDecoration: "underline",
                                cursor: "pointer"
                            }}
                               onClick={useMyLocation}
                            >Upotrebite svoju trenutnu lokaciju,</p> &nbsp;
                            <span> ili</span>
                        </div>
                        <GoogleAutocomplete saveAddress={saveAddress}/>
                    </div>
                </div>
            </ModalWithHeader>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        chooseLocationModalToggle: state.modal.chooseLocationModalToggle,
        place: state.appPage.place,
        loadingUserLocation: state.appPage.loadingUserLocation,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onChooseLocationModalToggle: (chooseLocationModalToggle) => dispatch(actions.chooseLocationModalToggle(chooseLocationModalToggle)),
        onEnteredAddressData: (place) => dispatch(actions.enteredAddressData(place)),
        onFetchUserLocationStart: () => dispatch(actions.fetchUserLocationStart()),
        onFetchUserLocationError: () => dispatch(actions.fetchUserLocationError()),
        onFetchUserLocationFinished: () => dispatch(actions.fetchUserLocationFinished()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ChooseLocation));