import React, {useState} from 'react';
import styles from './SearchWrapper.module.css'
import SearchBar from './SearchBar/SearchBar'
import MainPageButtons from '../MainPageButtons/MainPageButtons'

const SearchWrapper = (props) => {

    const [typeOfSearchState, typeOfSearchStateChanged] = useState("ITEMS");


    const changeTypeOfSearch = (typeOfSearch) => {
        typeOfSearchStateChanged(typeOfSearch);
    }

    return (
        <div className={styles.Content}>
            <div className={props.onMainPage ? styles.SearchWrapper : [styles.SearchWrapper, styles.SearchWrapperSmall].join(' ') }>
                <MainPageButtons changeTypeOfSearch={changeTypeOfSearch}/>
                <SearchBar onMainPage={props.onMainPage} typeOfSearch={typeOfSearchState}/>
            </div>
        </div>
    );
}

export default SearchWrapper;

