import React, {useEffect, useState} from 'react'
import {connect} from "react-redux";
import styles from './StoreInModal.module.css';

const StoreInModal = (props) => {

    const [distances, setDistances] = useState(null);

    useEffect(()=>{
        if(props.index === 0) {
            props.pharmacySelectedCallback(props.store, props.index)
        }
    }, [])

    const selectPharmacy = (index) => {
        localStorage.setItem('selectedPharmacyIndex', index);
        props.pharmacySelectedCallback(props.store, props.index);
    }

    useEffect(() => {
        if (props.distances) { // udaljenost za brzu kupovinu
            setDistances(<div>
                {(parseInt(props.distances[props.index]) / 1000).toFixed(2)} km
            </div>)
        }
        if (props.distancesModal) { // udaljenost za RFZO modal
            setDistances(<div>
                {(parseInt(props.distancesModal[props.index]) / 1000).toFixed(2)} km
            </div>)
        }
        if (props.storesContainingUserTherapyDistances) { // udaljenost za korisnicke terapije
            setDistances(<div>
                {(parseInt(props.storesContainingUserTherapyDistances[props.index]) / 1000).toFixed(2)} km
            </div>)
        }

    }, [props.distances, props.distancesModal, props.storesContainingUserTherapyDistances])

    return (
        <div className={styles.StoreInModalWrapper} onClick={()=>{selectPharmacy(props.index)}}>
            <div className={styles.SubWrapper}>
                <div style={{width:"75%"}}>
                    {props.rfzoModal
                        ? props.store.name
                        : props.store.marketplaceResponse.name}
                </div>
                {distances}
            </div>
            <div className={props.selected ? [styles.StoreInModalRadioButtonWrapper, styles.StoreInModalRadioButtonWrapperSelected].join(' ') : styles.StoreInModalRadioButtonWrapper}>
                <div className={props.selected ? [styles.StoreInModalRadioButton, styles.StoreInModalRadioButtonSelected].join(' ') : styles.StoreInModalRadioButton}></div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        // storesModal: state.appPage.storesModal,
        distancesModal: state.appPage.distancesModal,
        // loadingStoresModal: state.appPage.loadingStoresModal,
        distances: state.storesSearchPage.storesForModalDistances,
        storesContainingUserTherapyDistances: state.userProfile.storesContainingUserTherapyDistances
    }
}

export default connect(mapStateToProps)(StoreInModal);
