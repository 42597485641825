import * as actionsTypes from '../actions/actionsTypes'

const initialState = {
    stores: null,
    error: false,
    updatedPictures: [],
    updatedBranchMarketplacePictures: [],
    openHoursChanged: false,
    itemAvailabilities: null,
    itemAvailabilitiesError: false

}

const cmsPageReducer = (state = initialState, action) => {
    switch (action.type) {

        case actionsTypes.ALL_STORES:
            return {
                ...state,
                stores: action.stores,
                error: false
            }
        case actionsTypes.FETCH_ALL_ARTICLES_FAIlED:
            return {
                ...state,
                error: true
            }
        case actionsTypes.UPDATE_PICTURES:
            return {
                ...state,
                updatedPictures: [...action.updatedPictures]
            }
        case actionsTypes.UPDATE_BRANCH_MARKETPLACE_PICTURES:
            return {
                ...state,
                updatedBranchMarketplacePictures: [...action.updatedPictures]
            }
        case actionsTypes.OPEN_HOURS_MODAL:
            return {
                ...state,
                openHoursChanged: action.openHoursChanged
            }
        case actionsTypes.STORE_CHANGED:
            return {
                ...state,
                stores:
                    state.stores.map((store, index) => {
                        if (index === action.changedStoreIndex){
                            return {
                                ...action.changedStore,
                                branchMarketplaceResponse: { ...action.changedStore.branchMarketplaceResponse}
                            }
                        }
                        else {
                            if (store.branchMarketplaceId === action.changedStore.branchMarketplaceId) {
                                store.branchMarketplaceResponse = {...action.changedStore.branchMarketplaceResponse}
                            }
                            return store;
                        }
                    })

            }
        case actionsTypes.ITEM_AVAILABILITIES:
            return {
                ...state,
                itemAvailabilities: action.itemAvailabilities,
                itemAvailabilitiesError: false
            }
        case actionsTypes.FETCH_ITEM_AVAILABILITIES_FAIlED:
            return {
                ...state,
                itemAvailabilitiesError: true
            }
        default:
            return state;
    }

}

export default cmsPageReducer;
