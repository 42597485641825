import React, {useEffect} from 'react';
import styles from './SearchItemForTherapy.module.css';
import {PlusIcon} from "@heroicons/react/outline";
import * as actions from "../../../../../../../store/actions";
import {connect} from "react-redux";

const SearchItemForTherapy = (props) => {

    const clickItemHandler = () => {
        props.onSetSelectedItem(props.item)
        props.close();
        props.onToggleTherapyArticleModal(true)
        // dispatch(therapyActions.updateTherapy(null))
    }

    useEffect(()=>{
        if(props.enter) {
            props.onSetSelectedItem(props.enter)
            props.close();
            props.onToggleTherapyArticleModal(true)
        }
    }, [props.enter])

    return (
        <>
            <div className={styles.SearchItem} data-toggle="modal"
                 onClick={clickItemHandler}
            >
                <div className={styles.IconWrapper}><PlusIcon className={styles.IconStyle}/></div>
                <span key={props.item.id}>{props.item.name}</span>
            </div>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        articles: state.mainPage.articles,
        error: state.mainPage.articlesError,
        loadingArticles: state.mainPage.loadingArticles,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onSetSelectedItem: (item) => dispatch(actions.setSelectedItem(item)),
        onToggleTherapyArticleModal: (value) => dispatch(actions.toggleTherapyArticleModal(value))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchItemForTherapy);
