import React, {Fragment} from 'react'
import styles from './Button.module.css'

const Button = (props) => {

    let button;
    switch (props.buttontype) {
        case ('blue'):
            button = <button
                className={styles.BlueButton}
                {...props}/>
            break;
        case ('lightblue'):
            button = <button
                className={styles.LightBlueButton}
                {...props}/>
            break;
        case ('whiteblue'):
            button=<button
            className={styles.WhiteBlueButton}
                {...props}/>
            break;
        case ('whitedarkblue'):
            button=<button
                className={styles.WhiteDarkBlueButton}
                {...props}/>
            break;
        case ('whitebrown'):
            button=<button
                className={styles.WhiteBrownButton}
                {...props}/>
            break;
        case ('yellow'):
            button = <button
                className={styles.YellowButton}
                {...props}/>
            break;
        case ('disabled'):
            button = <button
                className={styles.DisabledButton}
                {...props}/>
            break;
        default:
            button = <button
                className={styles.Link}
                {...props}/>
    }

    return (
        <Fragment>
            {button}
        </Fragment>
    );

}

export default Button