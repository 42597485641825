import React, {useEffect, useState} from 'react';
import * as actions from "../../../../../../store/actions";
import {connect} from "react-redux";
import styles from "./TherapyForModal.module.css";

const TherapyForModal = (props) => {

    const [hasItemInTherapy, setHasItemInTherapy] = useState(false);

    useEffect(() => {
        props.therapy && props.therapy.items.map(item => {
            if (item.itemResponse.id === props.article.id) {
                setHasItemInTherapy(true)
            }
        })
    }, [props.therapy])

    const selectTherapy = (index) => {
        props.therapySelectedCallback(props.therapy, props.index);
    }


    useEffect(()=>{
        if(props.addArticleToTherapyFromInfoLekFlag) {
            addArticleToTherapy()
        }
        props.onAddArticleToTherapyFromInfoLek(false);

    }, [props.addArticleToTherapyFromInfoLekFlag])

    const addArticleToTherapy = () => {
        if (props.selected) {
            let tempArray = [];
            props.therapy.items.map(item => {
                let oldItem = {
                    itemId: item.itemResponse.id,
                    dosing: item.dosing,
                    duration: item.duration,
                    frequency: item.frequency,
                    quantity: item.quantity
                }
                tempArray.push(oldItem);
            })

                let newItem = {
                    dosing: '',
                    duration: '',
                    frequency: '',
                    itemId: props.article.id,
                    quantity: 1
                }
            tempArray.push(newItem);
            updateTherapy(tempArray)
        }
    }

    const updateTherapy = (tempArray) => {
            const itemObj = {
                id: props.therapy.id,
                name: props.therapy.name,
                description: '',
                therapyItemRequests: tempArray
            }
            props.onCreateNewTherapy(localStorage.getItem('id'), itemObj);
            props.onAddArticleToTherapyFromInfoLekModalToggle(false);
    }

    return (
        <div onClick={!hasItemInTherapy && selectTherapy}
             className={!hasItemInTherapy ?
                 [styles.TherapyForModal, styles.TherapyForModalClickable].join(' ')
                 :
                 [styles.TherapyForModal, styles.TherapyForModalDisabled].join(' ')}
             style={props.selected ? {backgroundColor:"lightgray"} : {border:"1px solid green"}}
        >
            <span style={hasItemInTherapy ? {color:"lightgray"} : {} }>{props.therapy.name}</span>
            {hasItemInTherapy ?
                <span style={{fontSize:"12px", paddingRight:"0.5rem", color:"lightgray"}}>Artikal već postoji</span>
                :
                <span style={{fontSize:"18px", color:"#00B2B1", paddingRight:"0.5rem"}}>+</span>

            }
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        userTherapies: state.userProfile.userTherapies,
        addArticleToTherapyFromInfoLekFlag: state.userProfile.addArticleToTherapyFromInfoLekFlag,
        articlesForTherapyItemRequests: state.userProfile.articlesForTherapyItemRequests,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onAddItemToTherapy: (item) => dispatch(actions.addArticleToTherapy(item)),
        onAddArticleToTherapyFromInfoLek: (state) => dispatch(actions.addArticleToTherapyFromInfoLek(state)),
        onCreateNewTherapy: (userId, therapyObject) => dispatch(actions.createNewTherapy(userId, therapyObject)),
        onAddArticleToTherapyFromInfoLekModalToggle: (state) => dispatch(actions.addArticleToTherapyFromInfoLekModalToggle(state))

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TherapyForModal);