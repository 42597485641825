import React, {useState} from "react";
import styles from './MyAddress.module.css';
import * as actions from "../../../../store/actions";
import {connect} from "react-redux";
import {TrashIcon} from "@heroicons/react/outline";
import Button from "../../../UI/Buttons/Button/Button";
import ModalWithHeader from "../../../UI/Modals/ModalWithHeader/ModalWithHeader";
import Backdrop from "../../../UI/Backdrop/Backdrop";

const Address = (props) => {

    const [isModalVisible, isModalVisibleHandler] = useState(false);

    const openModal = () => {
        isModalVisibleHandler(true);
    }

    const closeModal = () => {
        isModalVisibleHandler(false);
    }

    const deleteAddress = () => {
        props.onDeleteUserAddress(props.address.id);
    }

    const updateAddress = () => {
        props.updateAddressCall(props.address.id);
    }

    return (
        <>
            {isModalVisible ?
                <ModalWithHeader onCloseClick={closeModal}
                                 icon={<TrashIcon style={{width: "48px", height: "48px"}}/>}
                                 buttons={
                                     <>
                                         <Button onClick={closeModal}>Odustani</Button>
                                         <Button buttontype="blue"
                                                 onClick={deleteAddress}
                                         >Obriši</Button>
                                     </>
                                 }
                >
                        <div style={{
                            padding: "1rem",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center"
                        }}>
                            <div>
                                <p>Da li zaista želiš da obrišeš adresu?</p>
                            </div>

                            <br/>
                        </div>
                </ModalWithHeader>
                :
                null}
            <Backdrop show={isModalVisible}
                      backdropClickHandler={closeModal}
                      zIndex={15}
                      backgroundColor="rgba(0, 0, 0, .2)"/>



            <div className={styles.AddressCard}>
                <div className={styles.AddressHeader}>
                    <h3>Adresa {parseInt(props.userProfile.userAddresses.indexOf(props.address)) + 1}</h3>
                    <TrashIcon style={{width: "24px", height: "24px"}} onClick={openModal}/>
                </div>
                <div className={styles.AddressWrapper}>
                    <div className={styles.AddressPart}>
                        <p>{props.address.name} {props.address.number}</p>
                        <div style={{display: 'flex'}}>
                            {props.address.floor !== null && <p>Sprat: {props.address.floor} &nbsp;&nbsp;</p>}
                            {props.address.apartment !== null && <p>Stan: {props.address.apartment}</p>}
                        </div>
                        <p>{props.address.postalCode} {props.address.region}, {props.address.city}</p>
                    </div>
                    <Button buttontype='whiteblue' style={{alignSelf: "end", height:"37px", padding:"0 1rem"}} onClick={updateAddress}>
                        IZMENI
                    </Button>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        isLogedin: state.authPage.token !== null,
        userInfo: state.authPage.userInfo,

        userProfile: state.userProfile.userProfile,
        userProfileError: state.userProfile.userProfileError,
        loadingUserProfile: state.userProfile.loadingUserProfile

    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        onAddNewUserAddress: (userAddress) => dispatch(actions.addNewUserAddress(userAddress)),
        onUpdateUserAddress: (userAddress) => dispatch(actions.updateUserAddress(userAddress)),
        onDeleteUserAddress: (userAddressId) => dispatch(actions.deleteUserAddress(userAddressId)),
        onFetchUserProfile: (email) => dispatch(actions.fetchUserProfile(email)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Address);