import React, {useEffect} from "react";
import styles from "./ActionMenu.module.css";
import * as actions from "../../../store/actions";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";

const ActionMenu = (props) => {

    useEffect(() => {
        window.addEventListener('scroll', () => {
            if(window.scrollY > (props.scrollY ? props.scrollY : 300)) {
                props.onActionMenuStickyChanged(true)
            } else {
                props.onActionMenuStickyChanged(false);
            }
        });
    }, []);

    let actionMenuStyle = !props.actionMenuSticky ? styles.ActionMenu : [styles.ActionMenu, styles.PositionSticky].join(' ') ;

    return(
        <div className={actionMenuStyle} style={props.style}>
            <div className={styles.ActionMenuHolder} style={props.holderStyle}>
                {props.children}
            </div>
        </div>
    );
}


const mapStateToProps = (state) => {
    return {
        actionMenuSticky: state.actionMenu.actionMenuSticky
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onActionMenuStickyChanged: (actionMenuSticky) => dispatch(actions.actionMenuStickyChanged(actionMenuSticky)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ActionMenu));