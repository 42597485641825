import React from 'react';
import styles from './InformationBoxRight.module.css';


const InformationBoxRight = (props) => {

    let pharmacyDescription = props.selectedStore && (props.selectedStore.marketplaceResponse.description ? props.selectedStore.marketplaceResponse.description : props.selectedStore.branchMarketplaceResponse.description);

    return(
        <div className={styles.InformationBoxRightWrapper}>

            <div className={styles.PharmacyName}><strong>Apoteka {props.selectedStore && props.selectedStore.marketplaceResponse.name}</strong></div>

            <div className={styles.PharmacyDescription}>
                {pharmacyDescription}
            </div>
        </div>
    );
}

export default InformationBoxRight;