import React, {useEffect, useState} from 'react';
import * as actions from "../../../../../store/actions";
import {connect} from "react-redux";
import TherapyForModal from "./TherapyForModal/TherapyForModal";
import Spinner from "../../../Spinner/Spinner";
import styles from './TherapiesForModal.module.css'
import PerfectScrollbar from "react-perfect-scrollbar";

const TherapiesForModal = (props) => {

    const [therapies, setTherapies] = useState([])

    const [selectedTherapyData, selectedTherapyDataSetter] = useState({
        data: '',
        index: ''
    })

    const [nameValue, setNameValue] = useState('')

    const therapySelected = (data, index) => {
        selectedTherapyDataSetter({data: data, index: index})
    }

    useEffect(()=>{
        if(props.userTherapies === null) {
            props.onFetchUserTherapies(localStorage.getItem('id'))
        }
    },[])

    useEffect(()=>{
        if(selectedTherapyData.index !== '' && nameValue !== '') {
            props.OnNewTherapyNameFromInfoLekModal(null)
            setNameValue('')
        }

        if(therapies) {
            setTherapies([])
        }

        if(props.userTherapies) {
            props.userTherapies.map((therapy, index) => {
                if(therapy.authorizedMedic === null && !therapy.promotion) {
                    setTherapies(prev => [
                        ...prev,
                        <TherapyForModal therapy={therapy}
                                         index={index}
                                         key={index}
                                         style={selectedTherapyData.index === index ? {border: "1px solid #00B2B1"} : null}
                                         therapySelectedCallback={() => therapySelected(therapy, index)}
                                         selected={selectedTherapyData.index === index}
                                         article={props.article}
                        />

                    ])
                }
            })
        } else {
            setTherapies('Nema korisničkih terapija')
        }
    }, [props.userTherapies, selectedTherapyData])


    useEffect(()=>{
        if(props.newTherapyName && selectedTherapyData.index !== '') {
            selectedTherapyDataSetter({data: '', index: ''})
        }
    }, [props.newTherapyName])

    const setNewTherapyName =(e)=> {
        setNameValue(e.target.value)
        props.OnNewTherapyNameFromInfoLekModal(e.target.value)
    }


    return (
        <div>
            <div className={styles.TherapyList}>
            {therapies && therapies.length > 0 ?
                <PerfectScrollbar style={{height:"112px"}}>
                    {therapies}
                </PerfectScrollbar>
                :
                <Spinner />
            }
            </div>
            <div className={styles.NewTherapyBox}>
                <p>Dodajte u novu terapiju</p>
                <input type="text"
                       className={styles.TherapyNameInputField}
                       value={nameValue}
                       placeholder='Naziv terapije'
                       onChange={(e)=> setNewTherapyName(e)}
                />

            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        userTherapies: state.userProfile.userTherapies,
        selectedItem: state.userProfile.selectedItem,
        newTherapyName: state.userProfile.newTherapyName

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onFetchUserTherapies: (userId) => dispatch(actions.fetchUserTherapies(userId)),
        onAddItemToTherapy: (item) => dispatch(actions.addArticleToTherapy(item)),
        onCreateNewTherapy: (userId, therapyObject) => dispatch(actions.createNewTherapy(userId, therapyObject)),
        OnNewTherapyNameFromInfoLekModal: (name) => dispatch(actions.newTherapyNameFromInfoLekModal(name))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TherapiesForModal);