import React from "react";
import styles from "./ModalWithHeader.module.css";
import {ReactComponent as CloseIcon} from "../../../../assets/icons/closeModalIcon.svg";
import Backdrop from "../../Backdrop/Backdrop";

const ModalWithHeader = (props) => {
    return (
        <>
            <div className={`${styles.ModalWithHeader} ${props.className}`} style={props.style}>
                <div className={styles.Header} style={{backgroundColor: props.backgroundColor}}>
                    {!props.noXIcon &&
                        <CloseIcon className={styles.CloseIcon} onClick={props.onCloseClick}/>
                    }
                </div>
                <div className={styles.Content} style={props.contentStyle}>
                    {props.children}
                    <div className={styles.ActionWrapper}>
                        {props.buttons}
                    </div>
                </div>
            </div>
            {!props.noBackdrop &&
                <Backdrop show={props.show}
                          backdropClickHandler={props.backdropClickHandler}
                          zIndex={30}
                          backgroundColor="rgba(0, 0, 0, .2)"/>
            }
        </>
    );
}

export default ModalWithHeader;