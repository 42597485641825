import React, {Fragment} from "react";
import styles from "./RFZOItem.module.css";
import DefaultImage from "./../../../assets/PictureHolder.png";
import ConfigData from "./../../../config.json";
import {ReactComponent as RegulativaIcon} from "./../../../assets/icons/regulativa_s.svg";
import Media from "react-media";
import ItemAvailabilityBadge from "../../UI/ItemAvailabilityBadge/ItemAvailabilityBadge";

const RFZOItem = (props) => {

    const itemAvailability = props.itemInShoppingCart.itemResponse.itemAvailabilityCode !== null  &&
    <ItemAvailabilityBadge itemResponse={props.itemInShoppingCart.itemResponse} />

    const itemImage =
        <div>
            {props.itemInShoppingCart.itemResponse.jkl !== null && props.itemInShoppingCart.itemResponse.jkl.trim() !== "" && props.itemInShoppingCart.itemResponse.itemAvailabilityCode !== 'BR' ?
                <RegulativaIcon />
                :
                <img style={{maxWidth: "5rem", maxHeight: "5rem"}}
                     src={props.itemInShoppingCart.itemResponse.pictures !== null ? process.env.REACT_APP_CDN_PATH + props.itemInShoppingCart.itemResponse.pictures : DefaultImage}
                     alt=""/>
            }
        </div>

    const itemPrice =
        <div className={styles.ItemPrice}
             style={{display:"flex", alignItems:"center"}}
        >
            {props.itemInShoppingCart && !(props.itemInShoppingCart.rfzoQuantity > props.itemInShoppingCart.quantity) ?
                props.itemInShoppingCart.rfzoParticipationPrice.toLocaleString("de-DE", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                }) + " RSD"
                :
                <span style={{color: "#FFAE00"}}>
                    nema na stanju
                </span>
            }
            </div>
    // : <p style={{display:"flex", alignItems:"center"}}><QuestionMarkCircleIcon style={{width:"24px"}}/> RSD</p>)


    return (
        <React.Fragment>

                <Media queries={{
                    small: "(max-width: 699px)",
                    large: "(min-width: 700px)"
                }}>
                    {matches => (
                        <Fragment>
                            {matches.small &&
                                <div className={styles.ShoppingCartListItem} style={{justifyContent:"space-between", padding:"8px 0"}}>
                                    <div style={{display:"flex"}}>
                                        <div className={styles.Image} style={{padding: "0"}}>
                                            {itemImage}
                                        </div>
                                        <div className={styles.MiddlePartSideDrawer} style={{position:"relative"}}>
                                            <p style={{fontWeight: "700"}}>{props.itemInShoppingCart.itemResponse.name}</p>
                                            <div style={{position: "absolute", bottom: "35px", left:"-50px"}}>
                                                {itemAvailability}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.RightPartSideDrawer}>
                                        {itemPrice}
                                        <div className={styles.Quantity}>
                                            <p className={styles.QuantityText} style={{
                                                width: "25vw",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center"
                                            }}>Količina: {props.itemInShoppingCart.quantity}</p>
                                            {/*<input className={styles.QuantityText} readOnly type="number" maxLength='2' value={quantityValue}/>*/}
                                        </div>
                                    </div>
                                </div>
                            }
                            {matches.large &&
                                <div className={styles.ShoppingCartListItem}>
                                    <div className={styles.Image} style={{minWidth: "100px", minHeight: "100px"}}>
                                        {itemImage}
                                    </div>
                                    {/*{window.location.pathname.includes('shoppingCart') ?*/}
                                    <div className={styles.RightPartShopPage} style={{height: "105px", position: "relative"}}>
                                        <p style={{width: "60%"}}>{props.itemInShoppingCart.itemResponse.name}</p>
                                        <div style={{position: "absolute", bottom: "50px", left:"-13px"}}>
                                            {itemAvailability}
                                        </div>
                                        <div className={styles.Quantity} style={{margin: "auto 0"}}>
                                            x{props.itemInShoppingCart.rfzoQuantity}
                                        </div>
                                        {itemPrice}
                                    </div>
                                </div>
                            }
                        </Fragment>
                    )}
                </Media>


        </React.Fragment>
    );
}

export default RFZOItem;