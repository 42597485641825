import React, {Fragment} from 'react'
import styles from '../SearchedInfo.module.css';
import stylesStore from "../SearchedStoresInfo/Store.module.css";
import * as actions from "../../../../../store/actions";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import Media from "react-media";
import SquareBadge from "../../../../UI/SquareBadge/SquareBadge";
import {ReactComponent as RegulativaIcon} from "../../../../../assets/icons/regulativa_s.svg";

const SearchedArticlesInfo = (props) => {

    // useEffect(()=>{
    //     if(props.articles === null)
    //     props.onInitArticles();
    // }, [])

    return (
        <Media queries={{
            small: "(max-width: 699px)",
            large: "(min-width: 700px)"
        }}>
            {matches => (
                <Fragment>
                    {matches.small &&
                        <div className={stylesStore.ShopInfoBox} style={{padding:"0.5rem 1rem"}}>
                                {props.match.params.string &&
                                    // pretraga stringa artikala
                                    (window.location.pathname.includes('searched-articles/' + props.match.params.page + '/' + props.match.params.string) ||
                                    window.location.pathname.includes('unknown-item/' + props.match.params.string)) &&
                                    <>
                                        <p className={styles.Text}>Traženi tekst:
                                            <strong style={{color: "#00375cff", fontSize: "18px"}}>
                                                {props.match.params.string}
                                            </strong>
                                        </p>
                                    </>
                                }
                        </div>
                    }
                    {matches.large &&
                        <div className={stylesStore.ShopInfoBox}>
                            <p className={styles.Title}>Rezultat pretrage</p>
                            <div style={{color:"#5b6977ff"}}>
                                {props.match.params.string ?
                                    // pretraga stringa artikala
                                    (window.location.pathname.includes('searched-articles/' + props.match.params.page + '/' + props.match.params.string) ||
                                        window.location.pathname.includes('unknown-item/' + props.match.params.string)) &&
                                    <>
                                        <p className={styles.Text} style={{paddingBottom:"1rem"}}>Traženi tekst: &nbsp;
                                            <strong style={{color: "#00375cff", fontSize: "18px"}}>
                                                {props.match.params.string}
                                            </strong>
                                        </p>
                                    </>
                                    :
                                    // svi artikli
                                    window.location.pathname.includes('searched-articles/' + props.match.params.page) &&
                                    <p className={styles.Text} style={{paddingBottom:"1rem"}}>Pregled svih proizvoda u sistemu</p>
                                }
                                <span style={{display: "flex", alignItems: "center"}}>
                                    <SquareBadge
                                        backgroundColor="#fc6d6dff"
                                    >
                                        <span style={{color: "white", fontWeight: "bold"}}>Rp</span>
                                    </SquareBadge>
                                    <span style={{paddingLeft: "2rem"}}>Ovaj lek se izdaje samo uz recept</span>
                                </span>
                                <div style={{display:"flex", alignItems:"center"}}>
                                    <RegulativaIcon style={{height:"40px"}}/>
                                    <p style={{paddingLeft:"1rem"}}>Usled zakohnskih regulativa za neke proizvode nismo u mogućnosti da prikažemo sliku i/ili cenu</p>
                                </div>
                            </div>
                        </div>
                    }
                </Fragment>
            )}
        </Media>
    )
}

const mapStateToProps = (state) => {
    return {
        totalElements: state.articlesSearchPage.totalArticleElements,
        articles: state.articlesSearchPage.articles,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onInitArticles: () => dispatch(actions.initArticles()),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SearchedArticlesInfo));