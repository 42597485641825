import React from 'react'
import styles from './MainPageSubtitle.module.css'
import configData from '../../../configConstants.json';
import Textra from "react-textra";

const MainPageSubtitle = () => {

    const slogani = [configData.MainPage.SubtitleTitle.Title1,
                     configData.MainPage.SubtitleTitle.Title2,
                     configData.MainPage.SubtitleTitle.Title3,
                     configData.MainPage.SubtitleTitle.Title4];

    return (
        <div className={styles.MainPageSubtitleWrapper}>

            <h1 className={styles.Big}>
                <span style={{color: "#FFAE00", whiteSpace:"wrap"}}>Poruči i preuzmi </span>
                 <span> iz najbliže apoteke</span>
            </h1>

            <h2 className={styles.AnimatedText}>
                <Textra
                    effect='leftRight'
                    duration={650}
                    stopDuration={5350}
                    data={slogani}
                />
            </h2>

        </div>
    );
}
export default MainPageSubtitle;