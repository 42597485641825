import React, {useEffect, useState} from 'react'
import * as actions from "../../../../store/actions";
import {connect} from "react-redux";
import Order from "../Order/Order";

const Transactions = (props) => {

    useEffect(() => {
        if (props.userProfile === null) {
            props.onFetchUserProfile(localStorage.getItem('email'))
        }
        props.onFetchAllTransactions(localStorage.getItem('id'));

    }, [])

    const [allTransactions, allTransactionsSetter] = useState(null)

    const showTransactions = () => {
        if (props.allTransactions !== null) {
            allTransactionsSetter(props.allTransactions.map((transaction) => {

                let date = new Date(transaction.created);
                let day = date.getDate();
                let month = date.getMonth() + 1;
                let year = date.getFullYear();

                let hours = date.getHours();
                let minutes = date.getMinutes();

                return (
                    <Order key={transaction.id}
                           order={transaction}
                           date={date}
                           day={day}
                           month={month}
                           year={year}
                           hours={hours}
                           minutes={minutes}
                           userInfo={props.userInfo}
                           rateTransaction={true}
                    />
                )
            })
            )
        }
    }

 useEffect(() => {
     if (props.allTransactions) {
         showTransactions()
     }
 }, [props.allTransactions]);

    const noTransactions = <div style={{color:"#535358", fontSize:"16px"}}>Još uvek nemate nijednu arhiviranu porudžbinu</div>

    return (
        <>
            {allTransactions !== null && allTransactions.length > 0 ? allTransactions : noTransactions}
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        isLogedin: state.authPage.token !== null,
        userInfo: state.authPage.userInfo,

        userProfile: state.userProfile.userProfile,
        userProfileError: state.userProfile.userProfileError,
        loadingUserProfile: state.userProfile.loadingUserProfile,

        allTransactions: state.ordersAndTransactions.allTransactions,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onFetchUserProfile: (email) => dispatch(actions.fetchUserProfile(email)),
        onFetchAllTransactions: (userId) => dispatch(actions.fetchAllTransactions(userId)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Transactions)