import React, {useEffect, useState} from 'react'
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import styles from './MasterArtikalForm.module.css'
import Input from '../../../UI/Input/Input'
import Spinner from "../../../UI/Spinner/Spinner";
import * as actions from "../../../../store/actions";
import axios from "../../../../axios/axios-apoteka";
import Button from "../../../UI/Buttons/Button/Button";
import msgStyles from "../../CMSPage.module.css";

const MasterArtikalForm = (props) => {

    const [inputState, inputStateChanged] = useState({
        article: {
            name: "",
            tax: "",
            description: "",
            genericName: "",
            atc: "",
            jkl: "",
            shape: "",
            formula: 0.0,
            intensity: "",
            packageShape: "",
            packageQuantity: "",
            itemLoyalityPoints: 0,
            itemAvailability: "",
            itemAvailabilityCode: "",
            manufacturerName: "",
            manufacturerState: ""

        }
    })

    const [selectedAvailability, setSelectedAvailability] = useState("");
    const [highlightedAvailability, setHighlightedAvailability] = useState("");
    const [itemAvailabilitiesState, itemAvailabilitiesStateChanged] = useState([]);

    const [reportMessageState, reportMessageStateChanged] = useState({
        showErrorMessage: false,
        errorMessage: "Desila se greška, pokušajte ponovo",
        showSuccessMessage: false,
        successMessage: "Uspesno se ažurirali",

    })

    useEffect(() => {
        props.onFetchDetailsForArticle(props.match.params.id);
        // props.onUpdatePictures([]);
        props.clearPictureList();
    }, [props.match.params.id])

    useEffect(() => {
        if (props.loadingArticleDetails === false && props.articleDetails !== null && props.itemAvailabilities !== null) {
            inputStateChanged({
                article: {
                    ...props.articleDetails,
                    name: props.articleDetails.name === null ? "" : props.articleDetails.name,
                    tax: props.articleDetails.tax === null ? -1 : props.articleDetails.tax,
                    description: props.articleDetails.description === null ? "" : props.articleDetails.description,
                    genericName: props.articleDetails.genericName === null ? "" : props.articleDetails.genericName,
                    atc: props.articleDetails.atc === null ? "" : props.articleDetails.atc,
                    jkl: props.articleDetails.jkl === null ? "" : props.articleDetails.jkl,
                    shape: props.articleDetails.shape === null ? "" : props.articleDetails.shape,
                    formula: props.articleDetails.formula === null ? -1 : props.articleDetails.formula,
                    intensity: props.articleDetails.intensity === null ? "" : props.articleDetails.intensity,
                    packageShape: props.articleDetails.packageShape === null ? "" : props.articleDetails.packageShape,
                    packageQuantity: props.articleDetails.packageQuantity === null ? "" : props.articleDetails.packageQuantity,
                    itemLoyalityPoints: props.articleDetails.itemLoyalityPoints === null ? 0 : props.articleDetails.itemLoyalityPoints,
                    itemAvailability: props.articleDetails.itemAvailability === null ? "" : props.articleDetails.itemAvailability,
                    itemAvailabilityCode: props.articleDetails.itemAvailabilityCode === null ? "" : props.articleDetails.itemAvailabilityCode,
                    manufacturerName: props.articleDetails.manufacturerName === null ? "" : props.articleDetails.manufacturerName,
                    manufacturerState: props.articleDetails.manufacturerState === null ? "" : props.articleDetails.manufacturerState,
                }
            })
            itemAvailabilitiesStateChanged(props.itemAvailabilities.map((itemAvailability) => {
                return itemAvailability.description + ' | ' + itemAvailability.code
            }))
            console.log(itemAvailabilitiesState);
        }
    }, [props.loadingArticleDetails, props.itemAvailabilities])

    const inputChangedHandler = (event, inputIndentifier) => {
        const updatedInputForm = {
            ...inputState.article
        }
        updatedInputForm[inputIndentifier] = event.target.value;
        inputStateChanged({
            article: {...updatedInputForm}
        })
    }

    const updateArticle = (event) => {
        event.preventDefault();
        let updatedObject = {
            ...inputState.article
        };
        updatedObject.tax = +updatedObject.tax;
        updatedObject.formula = +updatedObject.formula;
        updatedObject.itemLoyalityPoints = +updatedObject.itemLoyalityPoints;

        // updatedObject.itemAvailability = selectedAvailability.split('|')[0].trim();
        // updatedObject.itemAvailabilityCode = selectedAvailability.split('|')[1].trim();

        const toUpdate = {
            ...updatedObject,
            pictures: [...props.updatedPictures]
        }
        console.log(toUpdate)
        const URL = '/item/update';
        axios.put(URL, toUpdate)
            .then(response => {
                reportMessageStateChanged({
                    ...reportMessageState,
                    showSuccessMessage: true
                })
                setTimeout(() => {
                    reportMessageStateChanged({
                        ...reportMessageState,
                        showSuccessMessage: false
                    })
                }, 4000)

            })
            .catch(error => {
                console.log("Error se desio" + error);
                reportMessageStateChanged({
                    ...reportMessageState,
                    showErrorMessage: true
                })
                setTimeout(() => {
                    reportMessageStateChanged({
                        ...reportMessageState,
                        showErrorMessage: false
                    })
                }, 4000)
            })

    }

    let inputs = <Spinner/>;
    if (!props.loadingArticleDetails && props.articleDetails !== null) {

        inputs = (
            <React.Fragment>
                <div>
                    <p>Naziv:</p>
                    <Input inputtype="input" type="text" name="name" placeholder={"Naziv Master proizvoda"}
                           value={inputState.article.name} onChange={(event) => inputChangedHandler(event, "name")}/>
                </div>
                <div>
                    <p>Opis proizvoda:</p>
                    <Input inputtype="textarea" type="text" name="description" placeholder={"Opis proizvoda"}
                           value={inputState.article.description}
                           onChange={(event) => inputChangedHandler(event, "description")}/>
                </div>
                {/*<div>*/}
                {/*    <p>Dostupnost artikla:</p>*/}
                {/*    <Input inputtype="input" type="text" name="itemAvailability" placeholder={"Dostupnost artikla"}*/}
                {/*           value={inputState.article.itemAvailability}*/}
                {/*           onChange={(event) => inputChangedHandler(event, "itemAvailability")}/>*/}
                {/*    <ComboBox*/}
                {/*        options={itemAvailabilitiesState}*/}
                {/*        placeholder="Izaberite dostupnost za artikl"*/}
                {/*        defaultIndex={1}*/}
                {/*        optionsListMaxHeight={300}*/}
                {/*        style={{*/}
                {/*            width: "750px",*/}
                {/*            margin: "0 auto",*/}
                {/*            marginTop: "50px"*/}
                {/*        }}*/}
                {/*        focusColor="#20C374"*/}
                {/*        renderOptions={(option) => (*/}
                {/*            <div className={styles.ComboBoxOption}>{option}</div>*/}
                {/*        )}*/}
                {/*        onSelect={(option) => setSelectedAvailability(option)}*/}
                {/*        // onChange={(event) => console.log(event.target.value)}*/}
                {/*        enableAutocomplete*/}
                {/*        onOptionsChange={(option) => setHighlightedAvailability(option)}*/}
                {/*    />*/}
                {/*</div>*/}
                <div>
                    <p>pdv:</p>
                    <Input inputtype="input" type="number" name="tax" placeholder={"pdv"} value={inputState.article.tax}
                           onChange={(event) => inputChangedHandler(event, "tax")}/>
                </div>
                <div>
                    <p>Genericki naziv:</p>
                    <Input inputtype="input" type="text" name="genericName" placeholder={"Genericki naziv"}
                           value={inputState.article.genericName}
                           disabled={true}
                           style={{background:"#eae8e8"}}
                           onChange={(event) => inputChangedHandler(event, "genericName")}/>
                </div>
                <div>
                    <p>atc:</p>
                    <Input inputtype="input" type="text" name="atc" placeholder={"atc"} value={inputState.article.atc}
                           disabled={true}
                           style={{background:"#eae8e8"}}
                           onChange={(event) => inputChangedHandler(event, "atc")}/>
                </div>
                <div>
                    <p>jkl:</p>
                    <Input inputtype="input" type="text" name="jkl" placeholder={"jkl"} value={inputState.article.jkl}
                           disabled={true}
                           style={{background:"#eae8e8"}}
                           onChange={(event) => inputChangedHandler(event, "jkl")}/>
                </div>
                <div>
                    <p>formula:</p>
                    <Input inputtype="input" type="number" name="formula" placeholder={"formula"} value={inputState.article.formula}
                           onChange={(event) => inputChangedHandler(event, "formula")}/>
                </div>
                <div>
                    <p>oblik:</p>
                    <Input inputtype="input" type="text" name="shape" placeholder={"oblik"}
                           value={inputState.article.shape} onChange={(event) => inputChangedHandler(event, "shape")}/>
                </div>
                <div>
                    <p>jacina:</p>
                    <Input inputtype="input" type="text" name="intensity" placeholder={"jacina"}
                           value={inputState.article.intensity}
                           onChange={(event) => inputChangedHandler(event, "intensity")}/>
                </div>
                <div>
                    <p>Pakovanje oblik:</p>
                    <Input inputtype="input" type="text" name="packageShape" placeholder={"Pakovanje oblik"}
                           value={inputState.article.packageShape}
                           onChange={(event) => inputChangedHandler(event, "packageShape")}/>
                </div>
                <div>
                    <p>Pakovanje kolicina:</p>
                    <Input inputtype="input" type="text" name="packageQuantity" placeholder={"Pakovanje kolicina"}
                           value={inputState.article.packageQuantity}
                           onChange={(event) => inputChangedHandler(event, "packageQuantity")}/>
                </div>
                <div>
                    <p>Loyality bodovi:</p>
                    <Input inputtype="input" type="number" name="itemLoyalityPoints" placeholder={"Loyality bodovi"} value={inputState.article.itemLoyalityPoints}
                           onChange={(event) => inputChangedHandler(event, "itemLoyalityPoints")}/>
                </div>
                <div>
                    <p>Naziv proizvodjaca:</p>
                    <Input inputtype="input" type="text" name="manufacturerName" placeholder={"Naziv proizvodjaca"}
                           value={inputState.article.manufacturerName}
                           disabled={true}
                           style={{background:"#eae8e8"}}
                           onChange={(event) => inputChangedHandler(event, "manufacturerName")}/>
                </div>
                <div>
                    <p>Drzava proizvodjaca:</p>
                    <Input inputtype="input" type="text" name="manufacturerState" placeholder={"Drzava proizvodjaca"}
                           value={inputState.article.manufacturerState}
                           disabled={true}
                           style={{background:"#eae8e8"}}
                           onChange={(event) => inputChangedHandler(event, "manufacturerState")}/>
                </div>
            </React.Fragment>
        );

    }

    return (
        <div className={(props.articleDetails !== null && props.articleDetails.description !== null) ? [styles.MasterArtikalForm, styles.Selected].join(' ') : styles.MasterArtikalForm}>
            {props.articleDetails !== null && <h3>{props.articleDetails.name}</h3>}
            <form>
                {inputs}
                {reportMessageState.showErrorMessage && <p className={msgStyles.ErrorMsg}>{reportMessageState.errorMessage}</p>}
                {reportMessageState.showSuccessMessage && <p className={msgStyles.SuccessMsg}>{reportMessageState.successMessage}</p>}
                <Button buttontype='blue' onClick={updateArticle}>Sačuvaj izmene</Button>
            </form>
        </div>

    );
}

const mapStateToProps = (state) => {
    return {
        articleDetails: state.appPage.articleDetails,
        loadingArticleDetails: state.appPage.loadingArticleDetails,
        updatedPictures: state.cmsPage.updatedPictures,
        itemAvailabilities: state.cmsPage.itemAvailabilities,
        itemAvailabilitiesError: state.cmsPage.itemAvailabilitiesError
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onFetchDetailsForArticle: (articleId) => dispatch(actions.fetchDetailsForArticle(articleId)),
        onUpdatePictures: (pictures) => dispatch(actions.updatePictures(pictures))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MasterArtikalForm));
