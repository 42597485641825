import React from 'react'
import styles from './Spinner.module.css'

const spinner = () => {

    return (
        <div className={styles.Loader}>Loading...</div>
        // <div className={styles.LoaderHolder}>
        //     <div className={props.small ? styles.LoaderSmall : styles.Loader}> </div>
        //     <p style={{paddingTop:"20px"}}>Učitavanje...</p>
        // </div>

    )
}
export default spinner
