import React, {useEffect} from 'react';
import * as actions from "../../../../store/actions";
import {ReactComponent as VideoIcon} from "../../../../assets/icons/video_icon.svg";
import Button from "../../Buttons/Button/Button";
import ModalWithHeader from "../ModalWithHeader/ModalWithHeader";
import {withRouter} from "react-router-dom";
import styles from "./LiveChatModal.module.css";
import {connect} from "react-redux";

const LiveChatModal = (props) => {

    const redirectToZoom = () => {
        props.history.push('/video-call')
    }

    const redirectToLogin = () => {
        const url = '/auth/login';
        localStorage.setItem('beforeLogin', window.location.pathname);
        props.history.replace(url);
    }

    useEffect(() => {
        if(props.userProfile === null) {
            props.onFetchUserProfile(localStorage.getItem('email'));
        }
    },[])

    return (
        <>
            <ModalWithHeader onCloseClick={props.closeChatModal}
                             icon={<VideoIcon style={{width: "45px", height: "45px", stroke: "#036e6e"}}/>}
                             show={props.show}
                             backdropClickHandler={props.backdropClickHandler}
                             buttons={
                                 <>
                                     {props.isLoggedIn && props.userProfile && props.userProfile.userLoyalityPoints <= 0 ?
                                         <Button onClick={props.closeChatModal} style={{margin:"auto"}}>Zatvori</Button> :
                                     <Button onClick={props.closeChatModal}>Nije mi potrebna pomoć</Button>}
                                     {props.isLoggedIn
                                         ? props.userProfile && props.userProfile.userLoyalityPoints > 0 ?
                                         <Button buttontype="blue" onClick={redirectToZoom}>Pomoć farmaceuta</Button> : null
                                         : <Button buttontype="blue" onClick={redirectToLogin}>Prijavi se</Button>
                                     }
                                 </>}
            >
                {props.isLoggedIn && props.userProfile && props.userProfile.userLoyalityPoints <= 0
                    ?
                    <div className={styles.ContentWrapper}>
                        <p className={styles.Title}>POTREBNA TI JE POMOĆ NAŠEG FARMACEUTA?</p>
                        <br/>
                        <p className={styles.Text}> Deo našeg Loyalty programa obuhvata i <strong>POMOĆ FARMACEUTA</strong> koja je korisniku dostupna nakon prve uspešno realizovane porudžbine.
                        </p>
                        <p className={styles.Text}>Pozivamo te da budeš deo <strong>FAMILY TIME Loyalty programa</strong>,
                            sakupljaš minute i uživaš u benefitima koje smo
                            pripremili za tebe i tvoje najbliže.
                        </p>
                        {!props.isLoggedIn &&
                            <p className={styles.Text}>Za razgovor sa farmaceutom <br/>
                                <strong>potrebno je da korisnik bude prijavljen</strong>.</p>
                        }
                    </div>
                    :
                    <div className={styles.ContentWrapper}>
                        <p className={styles.Title}>POTREBNA TI JE POMOĆ NAŠEG FARMACEUTA?</p>
                        <br/>
                        <p className={styles.Text}>Kontaktiraj sa farmaceutom klikom na dugme <br/>
                            <strong>Pomoć farmaceuta (09 - 17h)</strong><br/>
                            Razgovor se odvija putem <strong>Zoom platforme</strong>.<br/>
                            Nisi u obavezi da uključiš svoju kameru.
                        </p>
                        {!props.isLoggedIn &&
                            <p className={styles.Text}>Za razgovor sa farmaceutom <br/>
                                <strong>potrebno je da korisnik bude prijavljen</strong>.</p>
                        }
                    </div>
                }
            </ModalWithHeader>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        isLoggedIn: state.authPage.token !== null,
        userProfile: state.userProfile.userProfile
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onFetchUserProfile: (email) => dispatch(actions.fetchUserProfile(email)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LiveChatModal));