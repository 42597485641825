import React from 'react'
import ModalWithHeader from "../../../UI/Modals/ModalWithHeader/ModalWithHeader";
import Button from "../../../UI/Buttons/Button/Button";
import {withRouter} from "react-router-dom";

const GoToLoginModal = (props) => {

    const redirectToLogin = () => {
        localStorage.setItem('beforeLogin', window.location.pathname);
        const url = '/auth/login';
        props.history.replace(url);
    }

    return(
        <ModalWithHeader onCloseClick={props.onCloseClick}
                         show={props.show}
                         backdropClickHandler={props.backdropClickHandler}
                         buttons={
                             <>
                                 <Button onClick={props.cancel}>Odustani</Button>
                                 <Button buttontype="blue" onClick={redirectToLogin}>Prijavi se</Button>
                             </>
                         }
        >
            <p style={{margin: "0", fontSize: "20px", fontWeight: "bold", textAlign: "center"}}>Niste prijavljeni!</p>
            <br/>
            <br/>
            <br/>
            <p style={{textAlign: "center", fontSize: "14px", lineHeight: "1.7"}}>
                Opcija <strong>Obaveštenje o raspoloživosti proizvoda</strong> podrazumeva da <br/>
                <strong> korisnik mora biti prijavljen.</strong>
            </p>
        </ModalWithHeader>
    )
}

export default (withRouter(GoToLoginModal));