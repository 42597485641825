import React, {Fragment} from 'react';
import Button from "../../../UI/Buttons/Button/Button";
import styles from './UserInfoSection.module.css';
import {MailIcon, PencilAltIcon, PhoneIcon} from "@heroicons/react/outline";
import {ReactComponent as RFZOIcon} from "./../../../../assets/icons/rfzo_logo.svg";
import Media from "react-media";

const UserInfoSection = (props) => {
    return (
        <Media
            queries={{
                small: "(max-width: 699px)",
                large: "(min-width: 700px)"
            }}>
            {matches => (
                <Fragment>
                    {matches.small &&
                    <Fragment>
                        {/*<div className={styles.UserInfo}>*/}
                            <div className={!props.nameAndSurname ? styles.Filed : styles.FiledNameAndSurname}>
                                {props.email && <MailIcon style={{width:"18px", height:"18px"}} /> }
                                {props.phoneNumber && <PhoneIcon style={{width:"18px", height:"18px"}} /> }
                                {props.lbo && <RFZOIcon style={{width:"18px", height:"18px"}} /> }
                                <div className={props.nameAndSurname ? styles.NameAndSurname : styles.Information}>{props.information}</div>
                                {localStorage.getItem('isGoogleLogin') === 'false' ?
                                    (props.onClick &&
                                        <Button onClick={props.onClick} className={props.nameAndSurname ? styles.NameAndSurnameEditButton : styles.PhoneNumberEditButton}>
                                            <PencilAltIcon style={{width: "18px", height: "18px"}}/>
                                        </Button>
                                    )
                                    :
                                    (props.onClick && (props.phoneNumber || props.lbo) &&
                                        <Button onClick={props.onClick} className={styles.PhoneNumberEditButton} >
                                            <PencilAltIcon style={{width: "24px", height: "24px"}}/>
                                        </Button>
                                    )
                                }
                            </div>
                            {(props.nameAndSurname || props.phoneNumber) && <hr style={{width: "100%", borderTop:"1px solid #E2E6EA"}}/>}
                        {/*</div>*/}
                    </Fragment>
                    }
                    {matches.large &&
                    <Fragment>
                        <div className={styles.UserInfo} style={props.style}>
                            <div className={styles.Label} style={props.labelStyle}>{props.label}</div>
                            <div className={styles.Filed}>
                                <div className={styles.Information}>{props.information}</div>
                                {localStorage.getItem('isGoogleLogin') === 'false' ?
                                    (props.onClick &&
                                        <Button onClick={props.onClick}>
                                            <PencilAltIcon style={{width: "24px", height: "24px"}}/>
                                        </Button>
                                    )
                                    :
                                    ((props.onClick && (props.phoneNumber || props.lbo)) &&
                                        <Button onClick={props.onClick}>
                                            <PencilAltIcon style={{width: "24px", height: "24px"}}/>
                                        </Button>
                                    )
                                }
                            </div>
                        </div>
                    </Fragment>
                    }

                </Fragment>)}
        </Media>



    );
}

export default UserInfoSection;