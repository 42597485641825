import React, {useEffect, useState} from 'react';
import ArticleCategoryRes from "./ArticleCategoryRes/ArticleCategoryRes";
import styles from './ArticleCategoriesRes.module.css'
import * as actions from "../../../store/actions";
import {connect} from "react-redux";
import SlideDown from "react-slidedown";
import PerfectScrollbar from "react-perfect-scrollbar";
import {ReactComponent as CloseModal} from "./../../../assets/icons/closeModalIcon.svg";

const ArticleCategoriesRes = (props) => {

    const [categoryArray, categoryArraySetter] = useState([]);
    const [subCategoryArray, subCategoryArraySetter] = useState(null)
    const [subCategory2Array, subCategory2ArraySetter] = useState(null)

    const [colorFlag, colorFlagSetter] = useState(-1)
    const [colorFlagSub1, colorFlagSub1Setter] = useState(-1)
    const [colorFlagSub2, colorFlagSub2Setter] = useState(-1)

    const [articleCategory, articleCategorySetter] = useState(null)
    const [articleSubcategory1, articleSubcategory1Setter] = useState(null)
    const [categoryBoxOpen, categoryBoxOpenHandler] = useState(-1)
    const [subcategoryLoader, subcategoryLoaderSetter] = useState(false)

    const [subcategoryBoxOpen, subcategoryBoxOpenHandler] = useState(-1)

    const selectFilter = (catId, e) => {
        e.stopPropagation();
        props.onShopFilterSelection({
            categoryId: catId
        })
        localStorage.setItem('categoryFilter', catId);
        props.backdropClickHandler()

    }

    useEffect(() => {
        if (props.articleCategoryDetails === null) {
            props.onFetchCategoriesForArticles();
        }
    }, [])

    //---------------- handlovanje kategorija ---------------
    useEffect(() => {

        if (props.articleCategoryDetails !== null) {

            categoryArraySetter(props.articleCategoryDetails.map((articleCategory, index) => {
                return (
                    <SlideDown>
                        <ArticleCategoryRes key={index} articleCategory={articleCategory}
                                            onClick={() => fetchSubcategories1(articleCategory, index)}
                                            selectedStyle={styles.Selected}
                                            flag={colorFlag === index}
                                            onCategoryNameClick={(e) => {
                                                selectFilter(articleCategory.id, e)
                                            }}
                        />
                        {categoryBoxOpen === index &&
                            <div>
                                {subCategoryArray}
                            </div>}
                    </SlideDown>
                );
            }))

        }

        subcategoryLoaderSetter(false)

    }, [props.articleCategoryDetails, colorFlag, subcategoryLoader])
    //--------------------------------------------------------


    // ---------------- handlovanje sub categories 1--------
    const fetchSubcategories1 = (articleCategory, index) => {
        colorFlagSetter(index);
        categoryBoxOpenHandler(index);
        articleCategorySetter(articleCategory);

        if(categoryBoxOpen === index) {
            categoryBoxOpenHandler(-1);
            colorFlagSetter(-1);
        }
    }


    useEffect(()=>{
        subCategoryArraySetter([]);

            articleCategory !== null && articleCategory.subcategories.map((subCat, index) => {

                return (
                    subCategoryArraySetter(subCategoryArray => [
                        ...subCategoryArray,
                        <SlideDown>
                            <ArticleCategoryRes key={'subCat1' + index} articleCategory={subCat}
                                                style={{marginLeft:"24px", marginRight:"16px", padding:"0", borderBottom:"1px solid #E2E6EA", boxSizing:"border-box"}}
                                                onClick={() => fetchSubcategories2(subCat, index)}
                                                flag={colorFlagSub1 === index}
                                                onCategoryNameClick={(e) => {
                                                    selectFilter(subCat.id, e)
                                                }}
                            />
                            {subcategoryBoxOpen === index &&
                                <div>
                                    {subCategory2Array}
                                </div>
                            }
                        </SlideDown>
                    ])
                )
            });

            subcategoryLoaderSetter(true);

    }, [articleCategory, colorFlagSub1])
    // ------------------------------------------------------

    // ---------------- handlovanje sub categories 2 --------------
    const fetchSubcategories2 = (articleSubcategory1, index) => {
        articleSubcategory1Setter(articleSubcategory1);
        colorFlagSub1Setter(index);
        subcategoryBoxOpenHandler(index);

        if (subcategoryBoxOpen === index) {
            subcategoryBoxOpenHandler(-1);
            colorFlagSub1Setter(-1);
        }
    }

    useEffect(()=>{
        if(articleCategory !== null) {
            subCategory2ArraySetter([]);

            articleSubcategory1 !== null && articleSubcategory1.subcategories.map((subCat2, index) => {

                return (
                    subCategory2ArraySetter(subCategory2Array => [
                        ...subCategory2Array,
                        <ArticleCategoryRes key={'subCat2' + index}
                                            articleCategory={subCat2}
                                            style={{paddingLeft:"32px", borderBottom:"none"}}
                                            onClick={() => colorFlagSub2Setter(index)}
                                            flag={colorFlagSub2 === index}
                                            onCategoryNameClick={(e) => {
                                                selectFilter(subCat2.id, e)
                                            }}
                        />
                    ])
                )

            });

        }
    }, [articleSubcategory1, colorFlagSub2])

    //-----------------------------------------

    return (
        <div>
            <div className={styles.ArticleCategoriesRes}>
                <div className={styles.CategoryHeader}>
                    <p><strong>Kategorije</strong></p>
                    <CloseModal style={{width: "32px", stroke: "#4A4F54"}} onClick={props.backdropClickHandler}/>
                </div>
                <PerfectScrollbar>
                    {categoryArray}
                </PerfectScrollbar>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        articleCategoryDetails: state.filter.articleCategoryDetails
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onFetchCategoriesForArticles: () => dispatch(actions.fetchCategoriesForArticles()),
        onShopFilterSelection: (shopFilter) => dispatch(actions.shopFilterSelection(shopFilter))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ArticleCategoriesRes);