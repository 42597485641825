import React from 'react'
import styles from './MainPageButton.module.css'

const mainPageButton = (props) => (

    <div className={props.inDropdown && props.active ? [styles.MainPageButton, styles.ActiveInDropdown].join(' ') :
        ((props.inDropdown ? [styles.MainPageButton, styles.InDropdown].join(' ') : (props.active ? styles.MainPageButtonActive : styles.MainPageButton)))}>
        <a href={props.link} className={props.active ? styles.active : null} target={props.target}>
            <button onClick={() => props.changeTypeOfSearch(props.type)}>{props.children}</button>
        </a>
    </div>
);

export default mainPageButton;