import React from 'react'
import styles from './StorePageSubNavigation.module.css';
import {NavLink, withRouter} from "react-router-dom";
import * as actions from "../../../../store/actions";
import {connect} from "react-redux";

const StorePageSubNavigation = (props) => {

    return (
        <div className={styles.SubNavigationMenuWrapper}>
            <nav>
                <ul>
                    <li>
                        <div className={styles.LinkTextWrapper}>
                            <NavLink
                                to={"/storeinfo/" + props.match.params.branchMarketplaceId + '/' + props.match.params.marketplaceId + '/' + props.match.params.branchMarketplaceName + '/' + props.match.params.marketplaceName}
                                exact
                                className={styles.SubNavigation}
                                activeClassName={styles.Selected}
                            >Informacije
                            </NavLink>
                        </div>
                    </li>
                    <li>
                        <div className={styles.LinkTextWrapper}>
                            <NavLink
                                to={"/storeinfo/" + props.match.params.branchMarketplaceId + '/' + props.match.params.marketplaceId + '/' + props.match.params.branchMarketplaceName + '/' + props.match.params.marketplaceName + '/about-us'}
                                exact
                                className={styles.SubNavigation}
                                activeClassName={styles.Selected}
                            >O nama
                            </NavLink>
                        </div>
                    </li>
                </ul>
            </nav>
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        selectedStore: state.appPage.selectedStore
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onFetchSelectedStore: (marketplaceId, branchMarketplaceId) => dispatch(actions.fetchSelectedStore(marketplaceId, branchMarketplaceId)),
        onSelectedStoreChanged: (selectedStore) => dispatch(actions.selectedStoreChanged(selectedStore)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(StorePageSubNavigation));