import React, {useContext, useEffect, useState} from 'react'
import * as actions from "../../../../store/actions";
import {connect} from "react-redux";
import {OrderSocketContext} from "../../../../store/context/OrderSocket/OrderSocket";
import Order from '../Order/Order'
import styles from './Orders.module.css';

const Orders = (props) => {

    const orderSocket = useContext(OrderSocketContext);

    const [socketNotConnectedMessage, socketNotConnectedMessageSetter] = useState(null)

    useEffect(() => {
        if(props.orderSocketConnected === null || props.orderSocketConnectionStart) {
            socketNotConnectedMessageSetter(<p style={{color: "#f07e1bff"}}>Uspostavljanje konekcije...</p>)
        } else if (props.orderSocketConnected === false) {
            socketNotConnectedMessageSetter(<p style={{color: "#fc6d6dff"}}>Problemi sa konekcijom! Statusi porudžbina mogu biti neažurni.</p>)
            orderSocket.connect()
        } else {
            socketNotConnectedMessageSetter(null)
        }
    }, [props.orderSocketConnected, props.orderSocketConnectionStart])

    useEffect(() => {
        if (!orderSocket.isConnected() && localStorage.getItem("token") !== null) {
            orderSocket.connect();
        }

        return () => {
            if(orderSocket.isConnected()) {
                orderSocket.closeConnection();
            }
        }
    }, []);

    useEffect(() => {
        if (props.userProfile === null) {
            props.onFetchUserProfile(localStorage.getItem('email'))
        }
        props.onFetchAllOrders(localStorage.getItem('id'));

    }, [])

    let allOrders = null;

    if (props.allOrders !== null) {

        allOrders = props.allOrders.map((order) => {

            let date = new Date(order.created);
            let day = date.getDate();
            let month = date.getMonth() + 1;
            let year = date.getFullYear();

            return (
                <Order key={order.id}
                       order={order}
                       date={date}
                       day={day}
                       month={month}
                       year={year}
                       userInfo={props.userInfo}
                />
            )
        })
    }

    const noActiveOrders = <div className={styles.NoActiveOrders}>
        Trenutno nemate aktivnih porudžbina
    </div>;

    return (
        <>
            <div style={{position:"relative"}}>
                <div style={{position: "absolute", right:"0", top:"-60px"}}>{socketNotConnectedMessage}</div>
            </div>
            {props.allOrders !== null && props.allOrders.length > 0 ? allOrders : noActiveOrders}
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        isLogedin: state.authPage.token !== null,
        userInfo: state.authPage.userInfo,

        userProfile: state.userProfile.userProfile,
        userProfileError: state.userProfile.userProfileError,
        loadingUserProfile: state.userProfile.loadingUserProfile,

        allOrders: state.ordersAndTransactions.allOrders,
        singleOrder: state.ordersAndTransactions.singleOrder,

        orderSocketConnected: state.orderCompletion.orderSocketConnected,
        orderSocketConnectionStart: state.orderCompletion.orderSocketConnectionStart
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onFetchUserProfile: (email) => dispatch(actions.fetchUserProfile(email)),
        onFetchAllOrders: (userId) => dispatch(actions.fetchAllOrders(userId)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Orders)