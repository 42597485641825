import React from 'react'
import styles from './OrderCompletionPageTemplateMobile.module.css'

const OrderCompletionPageTemplateMobile = (props) => {

    return (
        <>
            {/*Wrapper komponenta*/}
            <div className={styles.WrapperForOCPTMobile} style={props.style}>
                <div>
                    {props.header}
                </div>
                {props.children}
            </div>
        </>
    )
}

export default OrderCompletionPageTemplateMobile;