import React from 'react';
import styles from './NoMatchSearchItem.module.css'
import {ReactComponent as SearchIcon } from "../../../../../assets/icons/search_icon.svg";
import {withRouter} from "react-router-dom";

const NoMatchSearchItem = React.memo(props => {

    const redirectToNoArticlesFound =()=> {
        const url = '/unknown-item/' + props.searchString;
        props.history.push(url)
    }

    return (
        <div className={props.onMainPage ? styles.NoMatchSearchItemMainPage : [styles.NoMatchSearchItemMainPage, styles.NoMatchSearchItemAppPage].join(' ')} onClick={redirectToNoArticlesFound}>
            <a>
                <div className={styles.NoMatchSearchItemImage}>
                    <SearchIcon/>
                </div>
                {/*<span>{props.message}</span>*/}
                <span>{props.searchString}</span>
            </a>
        </div>
    );
})


export default (withRouter(NoMatchSearchItem));
