import React, {createContext} from 'react'
import {baseUrl} from "../../../axios/axios-apoteka";
import * as actions from "../../actions";
import {connect} from "react-redux";


const RFZOTherapySocketContext = createContext("")

export { RFZOTherapySocketContext }

const RFZOTherapySocket = (props) => {
    let stompClient = null;
    let connected = false;

    const connect = () => {
        props.onRFZOSocketConnectionStart();
        const Stomp = require("stompjs");
        let SockJS = require("sockjs-client");
        SockJS = new SockJS(baseUrl + "/ws-rfzo-therapy-communication"/* + "?access_token="+localStorage.getItem('token')*/);
        stompClient = Stomp.over(SockJS);
        stompClient.connect({}, afterConnect, onConnectionClosed);
    };

    const closeConnection = () => {
        if (stompClient) {
            stompClient.disconnect();
            props.onRFZOSocketConnectionStatus(null);
        }
        connected = false;
    }

    const onConnectionClosed = () => {
        console.log("Konekcija rfzo therapy ws-a zatvorena!")
        props.onRFZOSocketConnectionStatus(false);
        connected = false;
    }

    const tickleConnection = () => {
        connected ? closeConnection() : connect();
    }

    const afterConnect = () => {
        connected = true;
        props.onRFZOSocketConnectionStatus(true);
        stompClient.subscribe(
            "/user/" + localStorage.getItem('email') + "/queue/messages",
            onMessageReceived
        );
    };

    const onError = (err) => {
        connected = false;
        props.onRFZOSocketConnectionStatus(false);
        console.log(err);
    };

    const isConnected = () => {
        return connected
    }

    const onMessageReceived = (msg) => {

        const message = JSON.parse(msg.body);
        // const active = JSON.parse(sessionStorage.getItem("recoil-persist"))
        //     .chatActiveContact;

        switch (message.type) {
            case 'RFZO_THERAPY_RESPONSE':
                props.onFetchRFZOTherapy(message.content.rfzoTherapyId);
                break;
            case 'ORDER_DENIED':

                break;
            case 'ORDER_CANCELED':

                break;
            case 'RFZO_THERAPY_RESPONSE_WITHOUT_CONTENT':
                props.onChangeRFZOTheraptStatus("PROCESSED_WITHOUT_CONTENT");
                props.onSetWillBeReady(message.content.willBeReady);
                break;
            case 'RFZO_THERAPY_REQUEST_EXPIRED':
                props.onChangeRFZOTheraptStatus("REQUEST_EXPIRED");
                break;
            case 'RFZO_THERAPY_EXPIRED':
                props.onChangeRFZOTheraptStatus("EXPIRED");
                break;
            case 'RFZO_THERAPY_CANCELED':
                props.onChangeRFZOTheraptStatus("CANCELED");
                props.onSetCancelNote(message.content.note);
                break;

        }
    };

    const sendMessage = (msg) => {
        stompClient.send("/apoteka-rs-socket/therapy", {}, JSON.stringify(msg));
    };

    const toReturn = {
        connect,
        isConnected,
        sendMessage,
        closeConnection,
        tickleConnection
    }


    return (
        <RFZOTherapySocketContext.Provider value={toReturn}>
            {props.children}
        </RFZOTherapySocketContext.Provider>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        onFetchRFZOTherapy: (rfzoTherapyId) => dispatch(actions.fetchRFZOTherapy(rfzoTherapyId)),
        onRFZOSocketConnectionStatus: (status) => dispatch(actions.RFZOSocketConnectionStatus(status)),
        onRFZOSocketConnectionStart: () => dispatch(actions.RFZOSocketConnectionStart()),
        onChangeRFZOTheraptStatus: (status) => dispatch(actions.changeRFZOTherapyStatus(status)),
        onSetWillBeReady: (willBeReady) => dispatch(actions.setWillBeReady(willBeReady)),
        onSetCancelNote: (cancelNote) => dispatch(actions.setCancelNote(cancelNote))
    }
}
export default connect(null, mapDispatchToProps)(RFZOTherapySocket)
