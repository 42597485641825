import React from 'react'
import styles from './HederWithImage.module.css';
// import {redirectToCommercialLink} from "../../components/GobalFunctions/GlobalFunctions";
// import CommercialImagesLink from "../../config.json";
import {connect} from "react-redux";
import {ReactComponent as MainPagePicture} from "../../assets/mainPagePicture.svg";

const HeaderWithImage = (props) => {

    return (
        <div className={styles.HeaderWithImage}>
            {/*ako treba da slika bude klikabilna*/}
            {/*<div className={styles.CommercialImage} onClick={()=> {props.commercial !== null && redirectToCommercialLink(props.commercial.actionLink)}}>*/}
            <div className={styles.Children}>
                {props.children}
            </div>
            <div className={styles.MobileHeaderImage}>
                {/*<img src={props.commercial !== null && CommercialImagesLink.CommercialImagesLink + props.commercial.imageLink} alt="" className={styles.Image}/>*/}
                <MainPagePicture />

            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        commercial: state.mainPage.advertisement
    }
}

export default connect(mapStateToProps) (HeaderWithImage);