import React, {Fragment, useEffect} from "react";
import styles from "./StorePageHeader.module.css";
import Background from "../../UI/Background/Background";
import ShopInfoBox from "../../ShopPage/ShopPageHeader/ShopInfoBox/ShopInfoBox";
import {redirectToCommercialLink} from "../../GobalFunctions/GlobalFunctions";
import StoreImage from "../../../assets/StorePageHeader.png";
import * as actions from "../../../store/actions";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import Media from "react-media";

const StorePageHeader = (props) => {

    useEffect(() => {
        if (props.selectedStore === null) {
            props.onFetchSelectedStore(props.match.params.marketplaceId, props.match.params.branchMarketplaceId);
        }
    }, [props.match.params.branchMarketplaceId, props.match.params.marketplaceId, props.selectedStore])

    return (

        <Media
            queries={{
                small: "(max-width: 699px)",
                large: "(min-width: 700px)"
            }}>

            {matches => (
                <Fragment>
                    {matches.small &&
                     <div className={styles.StoreInfoHeaderMobile}>
                            <ShopInfoBox storePage={true} />
                     </div>
                    }

                    {matches.large &&
                    <div className={styles.StorePageHeader}>
                        <Background style={{height: "450px", background: "#F6F6F6"}}/>
                        <div className={styles.Content}>
                            <div className={styles.ContentLeft}>
                                <div className={styles.ExitAndShopBrandGroup}>
                                    <ShopInfoBox onStorePage={true}/>
                                </div>
                            </div>
                            <div className={styles.StoreImage}
                                 onClick={() => {
                                     props.commercial && props.commercial.actionLink && redirectToCommercialLink(props.commercial.actionLink)
                                 }}>
                                <img
                                    src={StoreImage}
                                    alt="commercial image" /*style={{width: "450px"}}*/
                                    className={styles.Image}/>
                            </div>
                        </div>
                    </div>
                    }
                </Fragment>
            )}
        </Media>




    );
}

const mapStateToProps = (state) => {
    return {
        selectedStore: state.appPage.selectedStore,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onFetchSelectedStore: (branchMarketplaceId, marketplaceId) => dispatch(actions.fetchSelectedStore(branchMarketplaceId, marketplaceId))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(StorePageHeader));
