import React, {useEffect, useRef, useState} from 'react'
import styles from './StoreItem.module.css'
// import {faToggleOff} from "@fortawesome/free-solid-svg-icons/faToggleOff";
// import {faToggleOn} from "@fortawesome/free-solid-svg-icons/faToggleOn";
import {withRouter} from 'react-router-dom';
import {ClockIcon, ViewListIcon} from "@heroicons/react/outline";
import SquareBadge from "../../../../UI/SquareBadge/SquareBadge";
// import {randomIntFromInterval} from "../../../../GobalFunctions/GlobalFunctions";
import {StarIcon, ThumbUpIcon} from "@heroicons/react/solid";
import {selectedStoreOpeningHours, textToUrl} from "../../../../GobalFunctions/GlobalFunctions";
import * as actions from "../../../../../store/actions";
import {connect} from "react-redux";
import Button from "../../../../UI/Buttons/Button/Button";
import TooltipComponent from "../../../../UI/TooltipComponent/TooltipComponent";
import {ReactComponent as FewIcon} from "../../../../../assets/icons/inStockCheckIcon.svg";
import {ReactComponent as LastIcon} from "../../../../../assets/icons/inStockWarningIcon.svg";
import {ReactComponent as ALotIcon} from "../../../../../assets/icons/inStockDoubleCheckIcon.svg";
import {ReactComponent as ShoppingCartIcon} from "../../../../../assets/icons/cart_icon.svg";
import {ReactComponent as PinIcon} from "../../../../../assets/icons/pin_icon.svg";
import {ReactComponent as PhoneIcon} from "../../../../../assets/icons/phone_icon.svg"
import {ReactComponent as RFZOIcon} from "../../../../../assets/icons/rfzo-icon-with-border.svg"
import {ReactComponent as DeliveryIcon} from "../../../../../assets/icons/delivery.svg"

//METHODS FOR CHECKING WEATHER STORE IS OPEN OR CLOSE
export const isStoreOpenNow = (storeOpeningHours) => {
    const date = new Date();
    switch (date.getDay()) {
        case 0:
            return checkForOpen(date, storeOpeningHours.sunday);
        case 1:
            return checkForOpen(date, storeOpeningHours.monday);
        case 2:
            return checkForOpen(date, storeOpeningHours.tuesday);
        case 3:
            return checkForOpen(date, storeOpeningHours.wednesday);
        case 4:
            return checkForOpen(date, storeOpeningHours.thursday);
        case 5:
            return checkForOpen(date, storeOpeningHours.friday);
        case 6:
            return checkForOpen(date, storeOpeningHours.saturday);
    }
}
export const haveOpeningHours = (storeOpeningHours) => {
    if (storeOpeningHours.sunday !== null && storeOpeningHours.sunday !== undefined) return true;
    if (storeOpeningHours.monday !== null && storeOpeningHours.monday !== undefined) return true;
    if (storeOpeningHours.tuesday !== null && storeOpeningHours.tuesday !== undefined) return true;
    if (storeOpeningHours.wednesday !== null && storeOpeningHours.wednesday !== undefined) return true;
    if (storeOpeningHours.thursday !== null && storeOpeningHours.thursday !== undefined) return true;
    if (storeOpeningHours.friday !== null && storeOpeningHours.friday !== undefined) return true;
    if (storeOpeningHours.saturday !== null && storeOpeningHours.saturday !== undefined) return true;
    return false;
}
export const checkForOpen = (date, workingTimeStr) => {
    if (workingTimeStr === null || workingTimeStr === undefined || workingTimeStr === 'Ne radi') return null;
    const workingTime = workingTimeStr.split(' do ');
    let openingTimeHours = workingTime[0].split(":")[0];
    let closingTimeHours = workingTime[1].split(":")[0];

    let checkTime = date.getHours() + ':' + date.getMinutes();
    checkTime = getMinutes(checkTime);

    if (openingTimeHours < closingTimeHours) {
        return (checkTime >= getMinutes(workingTime[0]) && checkTime <= getMinutes(workingTime[1]));
    } else {
        return (checkTime >= getMinutes(workingTime[0]) && checkTime <= getMinutes(workingTime[1])+1440);
    }

}
export const getMinutes = (timeString) => {
    let [hh, mm] = timeString.split(":");
    return parseInt(hh) * 60 + parseInt(mm);
}
//END OF SECTION FOR CHECKING WEATHER STORE IS OPEN OR CLOSE


const StoreItem = (props) => {

    const fieldRef = useRef(null);
    let storeItem = props.storeItem.marketplaceResponse ? props.storeItem.marketplaceResponse : props.storeItem;


    const isOpen = props.storeItem !== null && isStoreOpenNow(storeItem.openingHoursResponse);

    let storeWorkingHours = <ViewListIcon />;
    if(props.storeItem !== null) {
        storeWorkingHours = selectedStoreOpeningHours(storeItem.openingHoursResponse);
    }

    useEffect(() => {
        if ((props.selectedStore !== null
            && props.selectedStore.marketplaceResponse.marketplaceId === storeItem.marketplaceId
            && props.selectedStore.marketplaceResponse.branchMarketplaceId === storeItem.branchMarketplaceId)) {
            fieldRef.current.scrollIntoView({
                behavior: "smooth",
                block: 'nearest',
                inline: 'start'
            });
        }
    }, [props.selectedStore])

    const storeEnterHandler = (e) => {
            //u zavisnosti sa koje strane se ulazi - if - pretraga leka, else - sve apoteke (ili pretraga stringa apoteke)
            if (props.storeItem.marketplaceResponse) {

                // e.stopPropagation();
                props.selectedStoreHandler(props.storeItem);
                localStorage.setItem('beforeEnteringStore', window.location.pathname);
                const url = props.articleId
                    ? '/shop/' + props.storeItem.marketplaceResponse.branchMarketplaceId + '/' + props.storeItem.marketplaceResponse.marketplaceId + '/' + 0 + '/' + props.articleId + '/' + textToUrl(props.storeItem.branchMarketplaceResponse.name) + '/' + textToUrl(props.storeItem.marketplaceResponse.name)
                    : '/shop/' + storeItem.branchMarketplaceId + '/' + storeItem.marketplaceId + '/' + 0 + '/' + textToUrl(storeItem.branchMarketplaceResponse.name) + '/' + textToUrl(storeItem.marketplaceResponse.name);

                // deo koji stavlja tekucu rutu na stack
                // da bi se po izlasku iz shopa vratili na stranu sa rezultatima.
                let urlReplace = window.location.pathname

                if (props.storeItem !== null && storeItem.isClickAndCollect === 1) {
                    props.history.replace(urlReplace);
                    props.history.push(url);
                } else {
                    props.onGetStoreItemModalData({isOpen: true, storeItem: props.storeItem});
                }
            } else {
                // restrukturacija props.storeItem, jer se na profilnoj strani apoteke i u modalu, ocekuje struktura kao sa searched-article page
                const storeItem = {
                    branchMarketplaceResponse: {
                        address: props.storeItem.branchMarketplaceResponse.address,
                        branchMarketplacePictures: props.storeItem.branchMarketplaceResponse.branchMarketplacePictures,
                        description: props.storeItem.branchMarketplaceResponse.description,
                        email: props.storeItem.branchMarketplaceResponse.email,
                        id: props.storeItem.branchMarketplaceResponse.id,
                        name: props.storeItem.branchMarketplaceResponse.name
                    },
                    marketplaceResponse: {
                        branchMarketplaceId: props.storeItem.branchMarketplaceId,
                        address: props.storeItem.address,
                        email: props.storeItem.email,
                        isClickAndCollect: props.storeItem.isClickAndCollect,
                        haveRFZO: props.storeItem.haveRFZO,
                        haveDelivery: props.storeItem.haveDelivery,
                        marketplaceId: props.storeItem.marketplaceId,
                        name: props.storeItem.name,
                        openingHoursResponse: props.storeItem.openingHoursResponse,
                        phoneNumber: props.storeItem.phoneNumber,
                        postalCode: props.storeItem.postalCode,
                        shortDescription: props.storeItem.shortDescription,
                        website: props.storeItem.website
                    }
                }

                props.selectedStoreHandler(storeItem); // vraca props.selectedStore, koji sadrzi podatke za profilnu stranu apoteke
                localStorage.setItem('beforeEnteringStore', window.location.pathname);
                const url = '/shop/' + props.storeItem.branchMarketplaceId + '/' + props.storeItem.marketplaceId + '/' + 0 + '/' + textToUrl(storeItem.branchMarketplaceResponse.name) + '/' + textToUrl(storeItem.marketplaceResponse.name);

                // deo koji stavlja tekucu rutu na stack
                // da bi se po izlasku iz shopa vratili na stranu sa rezultatima.
                let urlReplace = window.location.pathname

                if (props.storeItem.isClickAndCollect === 1) {
                    props.history.replace(urlReplace);
                    props.history.push(url);
                } else {
                    props.onGetStoreItemModalData({
                        isOpen: true,
                        storeItem: storeItem
                    });
                }
            }
    }

    let styling = {
        mainDiv: [styles.StoreItem, styles.StoreItemGridView].join(' '),
        boxHeader: styles.BoxHeader,
        boxFooter: styles.BoxFooter,
        boxMiddle: styles.BoxMiddle,
        pharmacyName: styles.PharmacyName,
        pharmacyContactInfo: styles.PharmacyContactInfo,
        quantityInfo: styles.QuantityInfoGridView,
        phoneNumber: null,
        additionalInfo: styles.AdditionalInfoGridView,
        locationAndAddress: null,
        // AMark: props.storeItem.marketplaceResponse.isClickAndCollect === 1 ? [styles.AMark, styles.AMarkGridView].join(' ') : [styles.AMarkHidden],
        // Price: props.storeItem.marketplaceResponse.isClickAndCollect === 1 ? [null] : [styles.DisplayNone]
    }

    //za List View
    // if (!props.gridView && !props.mobileView) {
    //     styling = {
    //         mainDiv: (props.selectedStore !== null
    //             && props.selectedStore.marketplaceResponse.marketplaceId === props.storeItem.marketplaceResponse.marketplaceId
    //             && props.selectedStore.marketplaceResponse.branchMarketplaceId === props.storeItem.marketplaceResponse.branchMarketplaceId) ?
    //             [styles.StoreItem, styles.StoreItemListView].join(' ') : [styles.StoreItem, styles.StoreItemListView].join(' '),
    //         // tooltipText: styles.TooltipText,
    //         displaySpaceBetween: styles.QuantityInfoListView,
    //         phoneNumber: styles.ListViewPhoneNumber,
    //         additionalInfo: styles.AdditionalInfoListView,
    //         locationAndAddress: styles.LocationAndAddress,
    //         // AMark: props.storeItem.marketplaceResponse.isClickAndCollect === 1 ? [styles.AMark, styles.AMarkListView].join(' ') : null,
    //         // Price: props.storeItem.marketplaceResponse.isClickAndCollect === 1 ? [null] : [styles.DisplayNone]
    //     }
    // }

    let oneFewMenu = (quantity) => {
        if (quantity <= 1) {
            return <p className={styles.One}><LastIcon style={{height:"14px", paddingRight:"0.5rem"}}/><strong>Na stanju</strong>&nbsp;|&nbsp;jedan</p>;
        } else if (quantity <= 5) {
            return <p className={styles.Few}><FewIcon style={{height:"14px", paddingRight:"0.5rem"}}/><strong>Na stanju</strong>&nbsp;|&nbsp;nekoliko</p>;
        } else if (quantity > 5) return <p className={styles.Many}><ALotIcon style={{height:"14px", paddingRight:"0.5rem"}}/><strong>Na stanju</strong>&nbsp;|&nbsp;puno</p>

    }

    const redirectToGoogleMap = (latitude, longitude) => {
        window.open("http://maps.google.com/maps?z=18&t=m&q=loc:" + latitude + "++" + longitude, "_blank");
    }

        //kreiran storeItemProp jer se na 'articles-and-stores' i na 'searched-stores' putanjama storeItem objekat je razlicito mapiran (jedan ima marketplaceResponse, drugi ne)
    const storeItemProp = window.location.pathname.includes("articles-and-stores") ? props.storeItem.marketplaceResponse : props.storeItem

    const [ratingBadge, setRatingBadge] = useState();

    useEffect(() => {
        setRatingBadge(<div className={styles.SqBox4}>
            <SquareBadge
                size="26px"
                backgroundColor={"#013A63"}
                marginRight={"6px"}
            >
                {props.storeItem &&
                    (storeItemProp.numOfRates < 10
                            ? <>
                                <StarIcon style={{width: "1.375rem", color: "white"}}/>
                                <TooltipComponent className={styles.TooltipText}
                                                  text="Apoteka ima manje od 10 ocenjenih rezervacija"/>
                            </>
                            : <>
                                <span className={styles.RateBadgeText}>
                                    {storeItemProp.averageRate && storeItemProp.averageRate.toFixed(1)}
                                </span>
                                <TooltipComponent className={styles.TooltipText}
                                                  text="Prosečna ocena apoteke"/>
                            </>
                    )
                }
            </SquareBadge>
        </div>)
    }, [props.storeItem])

    return (
        <React.Fragment>
            <div
                className={styling.mainDiv}
                ref={fieldRef}>
                {/*header*/}
                <div className={styling.boxHeader}>
                    <div className={styles.WorkingTime}>
                        <div style={{display: "flex", alignItems: "center"}}>
                            {isOpen !== null ? (!isOpen ?
                                    <p className={styles.OpenHoursDenied}>
                                        <ClockIcon
                                            style={{width: "1.4rem", height: "1.4rem"}}/><span>&nbsp; Zatvoreno</span></p> :
                                    <p className={styles.OpenHoursAccess}>
                                        <ClockIcon style={{
                                            width: "1.4rem",
                                            height: "1.4rem"
                                        }}/>
                                        <span>&nbsp;{storeWorkingHours}</span>
                                        &nbsp;

                                    </p>) :
                                null}
                        </div>
                    </div>
                    <div className={styles.BoxHeaderBadges}>
                        <div className={styles.SqBox1}>
                            {/*{props.storeItem.isClickAndCollect === 1 ? (props.storeItem.takesOrders === 1 ?*/}
                            {/*        <div style={{width:"10px", height:"10px", borderRadius:"50%", backgroundColor:"green"}}></div>*/}
                            {/*        :*/}
                            {/*        <div style={{width:"10px", height:"10px", borderRadius:"50%", backgroundColor:"red"}}></div>) :*/}
                            {/*    null*/}
                            {/*}*/}
                            {((props.storeItem.marketplaceResponse && props.storeItem.marketplaceResponse.isClickAndCollect && props.storeItem.marketplaceResponse.takesOrders === 1)
                                || (props.storeItem.isClickAndCollect === 1 && props.storeItem.takesOrders === 1)) &&
                                <SquareBadge
                                    backgroundColor={"#FBC932"}
                                    marginLeft={"6px"}
                                >
                                    <ThumbUpIcon style={{width: "1.375rem", fill: "white"}}/>
                                </SquareBadge>
                            }
                            <TooltipComponent className={styles.TooltipText} text="Apoteka je dostupna za online poručivanje"/>
                        </div>
                        {storeItem.haveRFZO === 1 &&
                            <div className={styles.SqBox3}>
                                <SquareBadge
                                    backgroundColor={"#4D5055"}
                                    marginLeft={"6px"}
                                >
                                    <RFZOIcon />
                                    {/*<span style={{color:"white", fontWeight:"bold"}}>P</span>*/}
                                </SquareBadge>
                                <TooltipComponent className={styles.TooltipText} text="Rezervacija RFZO terapije"/>
                            </div>
                        }
                        {storeItem.numOfItemsWithDiscount > 0 &&
                        <div className={styles.SqBox1}>
                            <SquareBadge
                                backgroundColor={"#FFAE00"}
                                marginLeft={"6px"}
                            >
                                <span style={{color: "white"}}>%</span>
                            </SquareBadge>
                            <TooltipComponent className={styles.TooltipText} text="Popusti na određene proizvode"/>
                        </div>
                        }
                        {storeItem.haveDelivery === 1 &&
                        <div className={styles.SqBox3}>
                            <SquareBadge
                                backgroundColor={"#00B1B0"}
                                marginLeft={"6px"}
                            >
                                <DeliveryIcon style={{width: "1.375rem", stroke: "white"}}/>
                                {/*<span style={{color:"white", fontWeight:"bold"}}>P</span>*/}
                            </SquareBadge>
                            <TooltipComponent className={styles.TooltipText} text="Apoteka vrši isporuku"/>
                        </div>
                        }
                    </div>
                </div>
                {/*srednji deo*/}
                <div className={styling.boxMiddle}>
                    <div className={styling.pharmacyName} onClick={storeEnterHandler}>
                        <div className={styles.SqBox4}>
                            {ratingBadge}
                        </div>
                        <div>
                            <h2>{storeItem.name}</h2>
                            <span style={{fontSize: "12px", color: "#5b6977ff"}}>
                                {props.storeItem.branchMarketplaceResponse.name}</span>
                        </div>
                    </div>

                    <div className={styling.pharmacyContactInfo}>
                        <p className={styling.locationAndAddress}
                           style={{lineHeight: '1.2rem'}}>
                            <PinIcon style={{stroke:"#4D5055", width:"16px", height:"16px"}}/> {storeItem.location}{storeItem.location !== null && ','} {storeItem.address}</p>
                        {/*<div className={styling.displaySpaceBetween}>*/}
                        <p className={styling.phoneNumber}>
                            <PhoneIcon style={{stroke:"#4D5055", width:"16px", height:"16px"}}/> {storeItem.phoneNumber} </p>
                        {props.distance && <p style={{cursor: "pointer", textDecoration: "underline"}}
                                              onClick={() => redirectToGoogleMap(props.storeItem.latitude, props.storeItem.longitude)}>Udaljenost: {(Math.round(+props.distance)/1000).toFixed(2)} km</p>}
                    </div>
                </div>

                {/*footer*/}
                <div className={styling.boxFooter}>
                    <div className={styling.quantityInfo}>

                        {/*informacija o kolicini i ceni prikazuje se samo na strani pretrage leka*/}
                        {!window.location.pathname.includes('searched-stores') &&
                        <div className={styling.additionalInfo}>
                            <div style={{display: "flex", flexDirection: "row"}}>

                                {/*<p className={styles.VerticalLine}>|</p>*/}
                                {!props.storeItem.itemQuantity > 0 ?
                                    <p className={styles.OnStockDenied}><strong>Nema na stanju</strong></p> :
                                    <p className={styles.OnStockAccess}></p>}
                                {(window.localStorage.getItem('roles') !== null && window.localStorage.getItem('roles').split(',').includes('ADMIN')) ?
                                    <p>Količina: {props.storeItem.itemQuantity}&nbsp;&nbsp;</p> :
                                    oneFewMenu(props.storeItem.itemQuantity)
                                }
                            </div>
                            {(window.localStorage.getItem('roles') !== null && window.localStorage.getItem('roles').split(',').includes('ADMIN')) ?
                                <p style={{fontSize: "0.625rem"}}> {/*className={styling.Price}*/}
                                    <strong
                                        style={{fontSize: "0.75rem"}}>{(props.storeItem.itemPrice).toLocaleString("de-DE", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    })} </strong>RSD
                                </p> : null}
                        </div>
                        }
                    </div>
                    {storeItem.isClickAndCollect === 1 &&
                        <Button buttontype='whitedarkblue'
                                style={{padding: "0 8px", fontWeight: "600", position: "relative"}}
                                onClick={storeEnterHandler}>
                            <ShoppingCartIcon style={{width: "20px", color: "inherit", left: "24px"}}/>
                            <span style={{fontSize:"12px", paddingLeft: "8px"}}>Uđi u apoteku</span>
                        </Button>

                    }
                </div>
            </div>
        </React.Fragment>
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        onGetStoreItemModalData: (storeItem) => dispatch(actions.getStoreItemModalData(storeItem)),
    }
}

export default connect(null, mapDispatchToProps)(withRouter(StoreItem));