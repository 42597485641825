import React, {Fragment, useEffect, useState} from "react";
import styles from './StoreItems.module.css';
import StoreItem from './StoreItem/StoreItem'
import Spinner from '../../../UI/Spinner/Spinner'
import {connect} from "react-redux";
import * as actions from "../../../../store/actions";
import {withRouter} from "react-router-dom";
import StoreItemsError from "./StoreItemsError/StoreItemsError";
import ResponsiveGridLayout from 'react-grid-layout';
import {redirectToError} from "../../../../containers/App";
import Pagination from "../../../UI/Pagination/Pagination";
import StoreItemModal from "./StoreItem/StoreItemModal/StoreItemModal";
import Media from "react-media";
import {textToUrl} from "../../../GobalFunctions/GlobalFunctions";

const StoreItems = (props) => {

    const [filterState, filterStateSetter] = useState('')
    const [inMyRadiusActivated, inMyRadiusActivatedHandler] = useState({activated: false, coords: null});

    //poziva se samo kada se menja paginacija
    useEffect(() => {
        if (props.totalElements !== -1 && props.totalPages !== -1) {
            if (localStorage.getItem("enteredAddress")) {
                props.onFetchStoresForArticle({
                    articleId: props.match.params.id,
                    page: props.match.params.page,
                    latitude: localStorage.getItem("addressLatitude"),
                    longitude: localStorage.getItem("addressLongitude"),
                    distance: 6000000,
                    filter: filterState
                })
            }
            else {
                props.onFetchSliceOfStoresForArticle(props.match.params.id, props.match.params.page, filterState);
            }
        }
    }, [props.match.params.page])


    //poziva se kad god se ucitava novi lek ili menja grad
    useEffect(() => {
        let temp = ''
        for (var a in localStorage) {
            if (a.includes("storeFilter_")) {
                const temp1 = a.split('_')[1];
                temp = temp1 === 'inMyRadius' ? '' : temp1;
            }
        }

        if(temp !== '' && filterState === '')
            return;

        if (inMyRadiusActivated.activated) {
            props.onFetchStoresForArticle({
                articleId: props.match.params.id,
                page: props.match.params.page,
                latitude: inMyRadiusActivated.coords.latitude,
                longitude: inMyRadiusActivated.coords.longitude,
                distance: 20000,
                filter: filterState
            })
        } else {
            if (localStorage.getItem("enteredAddress")) {
                props.onFetchStoresForArticle({
                    articleId: props.match.params.id,
                    page: props.match.params.page,
                    latitude: localStorage.getItem("addressLatitude"),
                    longitude: localStorage.getItem("addressLongitude"),
                    distance: 6000000,
                    filter: filterState
                })
            } else {
                props.onFetchStoresForArticle({
                    articleId: props.match.params.id,
                    page: props.match.params.page,
                    filter: filterState
                })
            }
        }
    }, [props.match.params.id, filterState, inMyRadiusActivated.activated, props.place])

    //--------------------------- filteri

    useEffect(() => {
        let tempArray = []
        for (var a in localStorage) {
            if (a.includes("storeFilter_")) {
                const temp1 = a.split('_')[1];

                if(temp1 !== 'inMyRadius') {
                    tempArray.push(temp1)
                } else {
                    localStorage.removeItem('storeFilter_inMyRadius');
                }
            }
        }
                props.onStoreFilterSelection({...props.storeFilter, filters: tempArray})
    }, [])

    //PRIMER SVI FILTERI: filter=marketplace.location:'Beograd' and marketplace.isClickCollect:1 and marketplace.haveDelivery:1 and marketplace.openingHours.monday:'00:00 do 24:00'
    useEffect(() => {

        if (props.storeFilter.filters.length > 0) {
            let value = ' and ';

            props.storeFilter.filters.map((filter, index) => {
                //todo kad se uradi za grad, umesto ':grad' staviti localstorage.getItem('location')
                if (filter === 'location') value += 'marketplace.' + filter + ':grad'
                else if (filter === 'openingHours') {
                    const weekdays = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
                    let day = new Date();
                    value += 'marketplace.' + filter + "." + weekdays[day.getDay()] + ":'00:00 do 24:00'";
                } else if (filter === 'hasDiscount') {
                    value += 'numOfItemsWithDiscount> 0';
                } else {
                    value += 'marketplace.' + filter + ':1';
                }
                if (index !== props.storeFilter.filters.length - 1) value += ' and ';
            })

            if (value !== ' and ')
                filterStateSetter(value);
        } else {
            filterStateSetter('')
        }
    }, [props.storeFilter]);

    //Vraca stranu (vrednost page) u url-u na 0 pri promeni filtera
    useEffect(() => {
        if (props.match.params.page !== 0) {
            onNextPageHandler(0);
        }
    }, [filterState, inMyRadiusActivated.activated, props.place])


    //--------------------------- end filteri
    //----------- in my radius
    useEffect(() => {
        if (props.storeFilter.inMyRadius === true) {
            storesInMyRadiusHandler();
        }
        if (inMyRadiusActivated.activated === true) {
            turnOffInMyRadius()
        }
    }, [props.storeFilter.inMyRadius])


    const page = props.match.url.split("/");

    const turnOffInMyRadius = () => {
        inMyRadiusActivatedHandler({activated: false, coords: null});
        // alert('Meho, ugasi malo!')
        if (window.location.pathname.includes('searched-stores')) {
            if (props.matchedStoresNames === null) {
                // props.onFatchAllStores(filter)
                props.onFetchStores({page: props.match.params.page, filter: filterState});
                // props.history.replace("/")
            } else {
                props.onFetchStores({
                    storesIds: props.matchedStoresNames,
                    page: props.match.params.page,
                    filter: filterState
                });
            }
            const url = '/searched-stores/' + props.match.params.page + '/' + props.match.params.searchedString;
            props.history.push(url)

        } else {
            if (localStorage.getItem("enteredAddress")) {
                props.onFetchStoresForArticle({
                    articleId: props.match.params.id,
                    page: props.match.params.page,
                    latitude: localStorage.getItem("addressLatitude"),
                    longitude: localStorage.getItem("addressLongitude"),
                    distance: 6000000,
                    filter: filterState
                })
            }else {
                props.onFetchStoresForArticle({articleId: props.match.params.id, page: page[3], filter: filterState});
            }
            // }
            const url = '/articles-and-stores/' + props.match.params.id + '/' + page[3] + props.location.hash;
            props.history.push(url)
        }
    }

    //Params for fetching user location
    const storesInMyRadiusHandler = () => {

        if (props.storeFilter.inMyRadius) {

            const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

            if (isSafari) {
                // navigator.geolocation.getCurrentPosition(success, errors, options);
                navigator.geolocation.getCurrentPosition(success, errors, options);
            } else {
                if (navigator.geolocation) {
                    let loading = false;
                    navigator.permissions
                        .query({name: "geolocation"})
                        .then(function (result) {
                            if (result.state === "granted") {
                                props.onFetchUserLocationStart();
                                navigator.geolocation.getCurrentPosition(success);
                                //If granted then you can directly call your function here
                            } else if (result.state === "prompt") {
                                props.onFetchUserLocationStart();
                                navigator.geolocation.getCurrentPosition(success, errors, options);
                            } else if (result.state === "denied") {
                                console.log(result.state);
                                localStorage.removeItem("storeFilter_inMyRadius");
                                props.onStoreFilterSelection({...props.storeFilter, inMyRadius: false})
                                alert('Za korišćenje ove funkcionalnosti moraš omogućiti pristup svojoj lokaciji u pretraživaču.')
                            }
                            result.onchange = function () {
                                console.log(result.state);
                            };
                        });
                } else {
                    alert("Sorry Not available!");
                }
            }
        } else {
            turnOffInMyRadius();
        }
    }

    let options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
    };

    function success(pos) {
        // let crd = pos.coords;
        // if (window.location.pathname.includes('searched-stores')) {
        // props.matchedStoresNames ? props.onFetchAllSearchedStoresInRadius(props.matchedStoresNames, crd.latitude, crd.longitude, 20000) :
        // props.onFetchStores({latitude:crd.latitude, longitude:crd.longitude, distance:20000, filter:filterState});
        inMyRadiusActivatedHandler({
            activated: true,
            coords: pos.coords
        });
        props.onFetchUserLocationFinished();
    }

    function errors(err) {
        props.onFetchUserLocationError();
        console.warn(`ERROR(${err.code}): ${err.message}`);
    }
    //----------- in my radius


    const onNextPageHandler = (page) => {
        const url = '/articles-and-stores/' + props.match.params.id + '/' + page + '/' + textToUrl(props.match.params.articleName);
        props.history.push(url)
    }

    // const showSelectedStoreHandler = (e, store) => {
    //     e.stopPropagation();
    //     props.onSelectedStoreChanged(store);
    //     const url = props.match.url + '/' + store.marketplaceResponse.marketplaceId +
    //         '/' + store.marketplaceResponse.branchMarketplaceId + props.location.hash;
    //     props.history.replace(url);
    // }


    let storeItems = null;
    let grid = <Spinner/>;
    // let counter = 0;
    let x = 0;
    let y = 0;
    let indexFlag = 0;

    /**
     *  logika za prikaz grida sa reklamom koja zauzima dva boxa vertikalno na index == 1, tj. drugoj poziciji
     *
     // const dataGridFill = (index) => {
    //     x++;
    //
    //     if (index === 0) {
    //         x = 0;
    //     }
    //
    //
    //     // if ((x === 1 && y === 0) || (x === 1 && y === 1)) {
    //     //     x++;
    //     // }
    //
    //     if ((y <= 1 && index % 3 === 0 && index !== 0) || (y >= 2 && x === 4 && index !== 0)) {
    //         x = 0;
    //         counter = 0;
    //         y++;
    //     }
    //     // if(counter===0){
    //     //    y++;
    //     // }
    //     counter++;
    // }
     */

    const dataGridFill = (index) => {
        if (index === 0) {
            x = 0;
        } else {
            x++;
            if (x === 4) {
                y++;
                x = 0;
            }
        }
    }


    if (!props.loadingStores && !props.loadingUserLocation) {
        if (props.storesError) {
            storeItems = <StoreItemsError/>;
        } else {
            if (props.storesServerError) {
                redirectToError(props);
            } else {
                // if (props.gridView) {
                    storeItems = props.stores !== null && props.stores.map((storeItem, index) => {

                        dataGridFill(index);
                        indexFlag = index;

                        return (
                            <div key={index} data-grid={{x: x, y: y, w: 1, h: 1, isDraggable: false}}
                                 className={styles.ElementInGridCell}>
                                {/*<div key={index}>*/}
                                <StoreItem storeItem={storeItem}
                                           key={[storeItem.marketplaceResponse.branchMarketplaceId,
                                           storeItem.marketplaceResponse.marketplaceId]}
                                        // showStoreDetails={e => showSelectedStoreHandler(e, storeItem)}
                                           selectedStore={props.selectedStore}
                                           distance={props.distances !== null && props.distances[index]}
                                           selectedStoreHandler={props.onSelectedStoreChanged}
                                           gridView={props.gridView}
                                           articleId={props.match.params.id}
                                       // isCNC = {Math.random() < 0.5}
                                />
                            </div>
                        );
                        // }

                    });

                    grid = props.stores !== null &&
                        <Media
                            queries={{
                                small: "(max-width: 699px)",
                                large: "(min-width: 700px)"
                            }}>
                            {matches => (
                                <Fragment>
                                    {matches.small &&
                                        <div style={{display: "flex", flexDirection: "column", width: "100vw"}}>
                                            {storeItems}
                                        </div>
                                    }

                                    {matches.large &&
                                        <ResponsiveGridLayout cols={4} rowHeight={240} width={1340}>
                                            {storeItems}
                                        </ResponsiveGridLayout>
                                    }
                                </Fragment>
                            )}
                        </Media>
            }
        }

    }

    return (
        <React.Fragment>

            {/*buttons za toggle view*/}
            {/*<div className={styles.ListOrGridToggleButtons}>*/}
            {/*    <button onClick={() => listOrGridViewToggleHandler(true)}*/}
            {/*            className={gridView ? [styles.ListOrGridToggleButton, styles.ToggleButtonSelected].join(' ') : styles.ListOrGridToggleButton}>*/}
            {/*        <BsGrid style={{fontSize: "24px"}}/></button>*/}
            {/*    <button onClick={() => listOrGridViewToggleHandler(false)}*/}
            {/*            className={!gridView ? [styles.ListOrGridToggleButton, styles.ToggleButtonSelected].join(' ') : styles.ListOrGridToggleButton}>*/}
            {/*        <ListIcon style={{fontSize: "24px"}}/></button>*/}
            {/*</div>*/}

            {props.gridView ?

                <div className={styles.Grid}>
                    <div className={styles.GridViewSoftEnter}>
                        {props.storesError ? <StoreItemsError/> : grid}
                    </div>
                </div>
                :
                <div className={styles.ListViewSoftEnter} style={{
                    display: "flex",
                    justifyContent: "center",
                    backgroundColor: "white",
                    borderRadius: "15px",
                    marginTop: "1rem"
                }}>
                    {/*{props.storesError ? <StoreItemsError/> : list}*/}
                </div>

            }
            <div className={styles.PagesHolder}>
                <Pagination totalElements={props.totalElements}
                            totalPages={props.totalPages}
                            matchParams={props.match.params}
                            onNextPageHandler={onNextPageHandler}

                />
            </div>

            {props.storeItemModal.isOpen &&
                <StoreItemModal onCloseClick={() => props.onGetStoreItemModalData({isOpen: false, storeItem: null})}
                                storeItem={props.storeItemModal.storeItem}
                                onOpen={props.storeItemModal.isOpen}
                />
            }

        </React.Fragment>

    );

}

const mapStateToProps = (state) => {
    return {
        stores: state.appPage.stores,
        distances: state.appPage.distances,
        loadingStores: state.appPage.loadingStores,
        storesError: state.appPage.storesError,
        storesServerError: state.appPage.storesServerError,
        selectedStore: state.appPage.selectedStore,
        totalElements: state.appPage.totalElements,
        totalPages: state.appPage.totalPages,
        gridView: state.filter.gridView,
        loadingUserLocation: state.appPage.loadingUserLocation,
        storeFilter: state.filter.storeFilter,

        storeItemModal: state.appPage.storeItemModal,
        place: state.appPage.place,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onFetchSliceOfStoresForArticle: (articleId, page, filter) => dispatch(actions.fetchSliceOfStoresForArticle(articleId, page, filter)),
        onSelectedStoreChanged: (selectedStore) => dispatch(actions.selectedStoreChanged(selectedStore)),

        // onFetchAllStores: (page, filter) => dispatch(actions.fetchAllStores(page, filter)),
        onFetchStoresForArticle: ({
                                      articleId,
                                      page,
                                      filter,
                                      latitude,
                                      longitude,
                                      distance
                                  }) => dispatch(actions.fetchStoresForArticle({
            articleId,
            page,
            filter,
            latitude,
            longitude,
            distance
        })),

        // ovu treba promeniti -- onFetchSliceOfStores: (page, filter) => dispatch(actions.fetchSliceOfStores(page, filter)),

        // onFetchAllStoresInRadiusForArticle: (articleId, latitude, longitude, distance) => dispatch(actions.fetchAllStoresInRadiusForArticle(articleId, latitude, longitude, distance)),
        onFetchUserLocationStart: () => dispatch(actions.fetchUserLocationStart()),
        onFetchUserLocationError: () => dispatch(actions.fetchUserLocationError()),
        onFetchUserLocationFinished: () => dispatch(actions.fetchUserLocationFinished()),
        onGetStoreItemModalData: (storeItem) => dispatch(actions.getStoreItemModalData(storeItem)),
        onStoreFilterSelection: (storeFilter) => dispatch(actions.storeFilterSelection(storeFilter)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(StoreItems));
