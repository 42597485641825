import React, {useEffect, useState} from 'react'
import styles from './ProdajnoMestoForm.module.css'
import Input from '../../../UI/Input/Input'
import Spinner from "../../../UI/Spinner/Spinner";
import * as actions from "../../../../store/actions";
import {connect} from "react-redux";
import {Textbox} from "react-inputs-validation";
import Button from "../../../UI/Buttons/Button/Button";
import axios from "../../../../axios/axios-apoteka";
import msgStyles from "../../CMSPage.module.css";
import Backdrop from "../../../UI/Backdrop/Backdrop";


const ProdajnoMestoForm = (props) => {

    const [inputState, inputStateChanged] = useState({
        store: {},

    })

    const [reportMessageState, reportMessageStateChanged] = useState({
        showErrorMessage: false,
        errorMessage: "Desila se greška, pokušajte ponovo",
        showSuccessMessage: false,
        successMessage: "Uspesno se ažurirali",

    });

    useEffect(() => {
        console.log(props.storeDetails);
        console.log(props.index)
        props.onUpdatePictures([]);
        props.onUpdateBranchMarketplacePictures([]);
        inputStateChanged({
            store: {
                name: props.storeDetails.name === null ? '' : props.storeDetails.name,
                meaningfulName: props.storeDetails.meaningfulName === null ? '' : props.storeDetails.meaningfulName,
                shortDescription: props.storeDetails.shortDescription === null ? '' : props.storeDetails.shortDescription,
                description: props.storeDetails.description === null ? '' : props.storeDetails.description,
                phoneNumber: props.storeDetails.phoneNumber === null ? '' : props.storeDetails.phoneNumber,
                email: props.storeDetails.email === null ? '' : props.storeDetails.email,
                website: props.storeDetails.website === null ? '' : props.storeDetails.website,
                location: props.storeDetails.location === null ? '' : props.storeDetails.location,
                postalCode: +props.storeDetails.postalCode,
                address: props.storeDetails.address === null ? '' : props.storeDetails.address,
                latitude: +props.storeDetails.latitude,
                longitude: +props.storeDetails.longitude,
                isApotekaRs: +props.storeDetails.isApotekaRs,
                isClickAndCollect: +props.storeDetails.isClickAndCollect,
                haveDelivery: +props.storeDetails.haveDelivery,
                haveRFZO: +props.storeDetails.haveRFZO,
                deleted: +props.storeDetails.deleted,
                boIpAddress: props.storeDetails.boIpAddress === null ? '' : props.storeDetails.boIpAddress,


                branchMarketplaceName: props.storeDetails.branchMarketplaceResponse.name === null ? '' : props.storeDetails.branchMarketplaceResponse.name,
                branchMarketplaceEmail: props.storeDetails.branchMarketplaceResponse.email === null ? '' : props.storeDetails.branchMarketplaceResponse.email,
                branchMarketplaceShortDescription: props.storeDetails.branchMarketplaceResponse.shortDescription === null ? '' : props.storeDetails.branchMarketplaceResponse.shortDescription,
                branchMarketplaceDescription: props.storeDetails.branchMarketplaceResponse.description === null ? '' : props.storeDetails.branchMarketplaceResponse.description,
                branchMarketplaceAddress: props.storeDetails.branchMarketplaceResponse.address === null ? '' : props.storeDetails.branchMarketplaceResponse.address,
                branchMarketplacePhoneNumber: props.storeDetails.branchMarketplaceResponse.phoneNumber === null ? '' : props.storeDetails.branchMarketplaceResponse.phoneNumber,
                branchMarketplaceCompanyNumber: props.storeDetails.branchMarketplaceResponse.companyNumber === null ? '' : props.storeDetails.branchMarketplaceResponse.companyNumber,
                branchMarketplaceTaxIdentificationNumber: props.storeDetails.branchMarketplaceResponse.taxIdentificationNumber === null ? '' : props.storeDetails.branchMarketplaceResponse.taxIdentificationNumber

            }
        })
    }, [props.storeDetails])

    const inputChangedHandler = (event, inputIndentifier) => {
        const updatedInputForm = {
            ...inputState.store,
        }
        updatedInputForm[inputIndentifier] = event.target.value;
        inputStateChanged({
            store: {...updatedInputForm}
        })
    }

    const isApotekaRsHandler = () => {
        inputStateChanged({
            store: {
                ...inputState.store,
                isApotekaRs: !inputState.store.isApotekaRs
            }
        })
    }

    const isClickAndCollectHandler = () => {
        inputStateChanged({
            store: {
                ...inputState.store,
                isClickAndCollect: !inputState.store.isClickAndCollect
            }
        })
    }

    const haveDeliveryHandler = () => {
        inputStateChanged({
            store: {
                ...inputState.store,
                haveDelivery: !inputState.store.haveDelivery
            }
        })
    }

    const haveRFZOHandler = () => {
        inputStateChanged({
            store: {
                ...inputState.store,
                haveRFZO: !inputState.store.haveRFZO
            }
        })
    }

    const deletedHandler = () => {
        inputStateChanged({
            store: {
                ...inputState.store,
                deleted: !inputState.store.deleted
            }
        })
    }



    const showOpenHoursButtonHandler = (event) => {
        event.preventDefault();
        // props.showOpenHoursHandler();
        props.onOpenHoursChanged(true);
    }


    const updateStore = (event) => {
        event.preventDefault();
        let updatedObject = {
            ...inputState.store

        };

        const updatedBranchMarketplace = {
            ...props.storeDetails.branchMarketplaceResponse,
            name: updatedObject.branchMarketplaceName === '' ? null : updatedObject.branchMarketplaceName,
            phoneNumber: updatedObject.branchMarketplacePhoneNumber === '' ? null : updatedObject.branchMarketplacePhoneNumber,
            email: updatedObject.branchMarketplaceEmail === '' ? null : updatedObject.branchMarketplaceEmail,
            address: updatedObject.branchMarketplaceAddress === '' ? null : updatedObject.branchMarketplaceAddress,
            companyNumber: updatedObject.branchMarketplaceCompanyNumber === '' ? null : updatedObject.branchMarketplaceCompanyNumber,
            shortDescription: updatedObject.branchMarketplaceShortDescription === '' ? null : updatedObject.branchMarketplaceShortDescription,
            description: updatedObject.branchMarketplaceDescription === '' ? null : updatedObject.branchMarketplaceDescription,
            taxIdentificationNumber: updatedObject.branchMarketplaceTaxIdentificationNumber === '' ? null : updatedObject.branchMarketplaceTaxIdentificationNumber,
            branchMarketplacePictures: [...props.updatedBranchMarketplacePictures]
        }

        let toUpdate = {
            ...props.storeDetails,
            website: updatedObject.website === '' ? null : updatedObject.website,
            name: updatedObject.name === '' ? null : updatedObject.name,
            meaningfulName: updatedObject.meaningfulName === '' ? null : updatedObject.meaningfulName,
            shortDescription: updatedObject.shortDescription === '' ? null : updatedObject.shortDescription,
            description: updatedObject.description === '' ? null : updatedObject.description,
            location: updatedObject.location === '' ? null : updatedObject.location,
            postalCode: +updatedObject.postalCode === 0 ? null : +updatedObject.postalCode,
            address: updatedObject.address === '' ? null : updatedObject.address,
            phoneNumber: updatedObject.phoneNumber === '' ? null : updatedObject.phoneNumber,
            email: updatedObject.email === '' ? null : updatedObject.email,
            latitude: +updatedObject.latitude === 0 ? null : +updatedObject.latitude,
            longitude: +updatedObject.longitude === 0 ? null : +updatedObject.longitude,
            isApotekaRs: +updatedObject.isApotekaRs,
            isClickAndCollect: +updatedObject.isClickAndCollect,
            haveDelivery: +updatedObject.haveDelivery,
            haveRFZO: +updatedObject.haveRFZO,
            deleted: +updatedObject.deleted,
            boIpAddress: updatedObject.boIpAddress === '' ? null : updatedObject.boIpAddress,
            branchMarketplaceResponse: updatedBranchMarketplace,
            pictures: [...props.updatedPictures]

        }


        console.log(toUpdate)

        const URL = '/marketplace/update';
        // const tokenParam = {
        //     params: {
        //         access_token: props.token
        //     }
        // }
        axios.put(URL, toUpdate/*, tokenParam*/)
            .then(response => {
                props.onStoreChanged(toUpdate, props.index);
                reportMessageStateChanged({
                    ...reportMessageState,
                    showSuccessMessage: true
                })
                setTimeout(() => {
                    reportMessageStateChanged({
                        ...reportMessageState,
                        showSuccessMessage: false
                    })
                }, 4000)
            })
            .catch(error => {
                console.log("Error se desio" + error);
                reportMessageStateChanged({
                    ...reportMessageState,
                    showErrorMessage: true
                })
                setTimeout(() => {
                    reportMessageStateChanged({
                        ...reportMessageState,
                        showErrorMessage: false
                    })
                }, 4000)
            })

    }

    let inputs = <Spinner/>;
    if (props.storeDetails !== null) {

        inputs = (
            <React.Fragment>
                <div>
                    <p>Naziv</p>
                    <Input inputtype="input" type="text" name="name" maxlength="25" placeholder={"Naziv Prodajnog mesta"}
                           value={inputState.store.name} onChange={(event) => inputChangedHandler(event, "name")}/>
                </div>
                <div>
                    <p>Smislen naziv</p>
                    <Input inputtype="input" type="text" name="meaningfulName"
                           placeholder={"Smislen naziv prodajnog mesta"} value={inputState.store.meaningfulName}
                           onChange={(event) => inputChangedHandler(event, "meaningfulName")}/>
                </div>
                <div>
                    <p>Kratak opis Prodajnog mesta</p>
                    <Input inputtype="textarea" type="text" name="shortDescription" placeholder={"Kratak opis prodajnog mesta"}
                           value={inputState.store.shortDescription}
                           onChange={(event) => inputChangedHandler(event, "shortDescription")}/>
                </div>
                <div>
                    <p>Opis Prodajnog mesta</p>
                    <Input inputtype="textarea" type="text" name="description" placeholder={"Opis prodajnog mesta"}
                           value={inputState.store.description}
                           onChange={(event) => inputChangedHandler(event, "description")}/>
                </div>
                <div>
                    <p>Broj telefona</p>
                    <Textbox
                        attributesInput={{
                            id: "phone",
                            name: "phone",
                            placeholder: "Broj telefona (ako ih je vise, razdvojiti ih zarezom)",
                            type: "text"
                        }}
                        value={inputState.store.phoneNumber} //Optional.[String].Default: "".
                        onChange={(value, event) => inputChangedHandler(event, "phoneNumber")}  //Required.[Func].Default: () => {}. Will return the value.
                        customStyleInput={{marginLeft: "7px", marginRight: "7px"}}
                        customStyleWrapper={{width: "100%"}} //Optional.[Object].Default: {}.
                        customStyleContainer={{width: "100%"}} //Optional.[Object].Default: {}.
                        onBlur={(e) => {
                        }} //Optional.[Func].Default: none. In order to validate the value on blur, you MUST provide a function, even if it is an empty function. Missing this, the validation on blur will not work.
                        validationOption={{
                            name: "phone", //Optional.[String].Default: "". To display in the Error message. i.e Please enter your {name}.
                            check: true, //Optional.[Bool].Default: true. To determin if you need to validate.
                            required: false, //Optional.[Bool].Default: true. To determin if it is a required field.
                            msgOnError: "Polje za broj telefona ne moze da bude prazno",
                            customFunc: (email) => {
                                const reg = /^$|^((((0[0-9]{2,3}\/[0-9]{3}\-[0-9]{3,4}),)\s*)*)(0[0-9]{2,3}\/[0-9]{3}\-[0-9]{3,4})$/; //regex za telefon, empty string ili vise telefona odvojenih zarezom
                                // const reg = /(^$|^(|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$)/;
                                if (reg.test(String(email).toLowerCase())) {
                                    return true;
                                } else {
                                    return "Uneseni telefoni nisu validnog formata - validan format 012(3)/234-567(8)";
                                }
                            }
                        }}
                    />
                    {/*<Input inputtype="input" type="text" name="phoneNumber"*/}
                    {/*       placeholder={"Broj telefona (ako ih je vise, razdvojiti ih zarezom)"}*/}
                    {/*       value={inputState.store.phoneNumber}*/}
                    {/*       onChange={(event) => inputChangedHandler(event, "phoneNumber")}/>*/}
                </div>
                <div style={{width: "100%"}}>
                    <p>Email</p>
                    {/*<Input inputtype="input" type="text" name="email"*/}
                    {/*       placeholder={"Email (ako ih je vise, razdvojiti ih zarezom)"} value={inputState.store.email}*/}
                    {/*       onChange={(event) => inputChangedHandler(event, "email")}/>*/}
                    <div style={{width: "100%"}}>
                        <Textbox
                            attributesInput={{
                                id: "email",
                                name: "email",
                                placeholder: "Email",
                                type: "text"
                            }}
                            value={inputState.store.email} //Optional.[String].Default: "".
                            onChange={(value, event) => inputChangedHandler(event, "email")}  //Required.[Func].Default: () => {}. Will return the value.
                            customStyleInput={{marginLeft: "7px", marginRight: "7px"}}
                            customStyleWrapper={{width: "100%"}} //Optional.[Object].Default: {}.
                            customStyleContainer={{width: "100%"}} //Optional.[Object].Default: {}.
                            onBlur={(e) => {
                            }} //Optional.[Func].Default: none. In order to validate the value on blur, you MUST provide a function, even if it is an empty function. Missing this, the validation on blur will not work.
                            validationOption={{
                                name: "email", //Optional.[String].Default: "". To display in the Error message. i.e Please enter your {name}.
                                check: true, //Optional.[Bool].Default: true. To determin if you need to validate.
                                required: false, //Optional.[Bool].Default: true. To determin if it is a required field.
                                msgOnError: "Email polje ne moze da bude prazno",
                                customFunc: (email) => {
                                    const reg = /(^$|^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$)/; //regex za email ili empty string
                                    if (reg.test(String(email).toLowerCase())) {
                                        return true;
                                    } else {
                                        return "Unesena email adresa nije validnog formata";
                                    }
                                }
                            }}
                        />
                    </div>
                </div>
                <div>
                    <p>Website</p>
                    <Input inputtype="input" type="text" name="website" placeholder={"Website"}
                           value={inputState.store.website}
                           onChange={(event) => inputChangedHandler(event, "website")}/>
                </div>
                <div>
                    <p style={{width: "74%"}}>Mesto</p>
                    <Input inputtype="input" type="text" name="location" placeholder={"Mesto"}
                           value={inputState.store.location}
                           onChange={(event) => inputChangedHandler(event, "location")}/>
                    <p style={{width: "40%"}}>Poštanski broj</p>
                    <Input inputtype="input" type="number" name="postalCode" placeholder={"Poštanski broj"}
                           value={inputState.store.postalCode}
                           onChange={(event) => inputChangedHandler(event, "postalCode")}/>
                </div>
                <div>
                    <p>Adresa</p>
                    <Input inputtype="input" type="text" name="address" placeholder={"Adresa prodajnog mesta"}
                           value={inputState.store.address}
                           onChange={(event) => inputChangedHandler(event, "address")}/>
                </div>
                <div>
                    <p>BO ip address</p>
                    <Input inputtype="input" type="text" name="boIpAddress" placeholder={"Back office ip address"}
                           value={inputState.store.boIpAddress}
                           onChange={(event) => inputChangedHandler(event, "boIpAddress")}/>
                </div>
                {/*<div>*/}
                {/*    <p>Poštanski broj</p>*/}
                {/*    <Input inputtype="input" type="number" name="postalCode" placeholder={"Poštanski broj"}*/}
                {/*           value={inputState.store.postalCode}*/}
                {/*           onChange={(event) => inputChangedHandler(event, "postalCode")}/>*/}
                {/*</div>*/}
                <div>
                    <p>Latitude (prvi br na mapi)</p>
                    <Input inputtype="input" type="number" name="latitude" placeholder={"Latitude"}
                           value={inputState.store.latitude}
                           onChange={(event) => inputChangedHandler(event, "latitude")}/>
                </div>
                <div>
                    <p>Longitude (drugi br na mapi)</p>
                    <Input inputtype="input" type="number" name="longitude" placeholder={"Longitude"}
                           value={inputState.store.longitude}
                           onChange={(event) => inputChangedHandler(event, "longitude")}/>
                </div>
                <div style={{display:"flex", alignItems:"center"}}>

                    <Input inputtype="checkbox" type="checkbox" name="deleted"
                           style={{opacity: "1", width: "fit-content", position: "relative"}}
                           checked={!inputState.store.deleted}
                           onChange={deletedHandler}/>
                    <p style={{whiteSpace:"nowrap"}}>Prikazi apoteku na apoteka.rs</p>

                    <Input inputtype="checkbox" type="checkbox" name="isApotekaRs"
                           style={{opacity: "1", width: "fit-content", position: "relative"}}
                           checked={inputState.store.isApotekaRs}
                           onChange={isApotekaRsHandler}/>
                    <p style={{whiteSpace:"nowrap"}}>Prikazi lager na apoteka.rs</p>

                    <Input inputtype="checkbox" type="checkbox" name="isClickAndCollect"
                           style={{opacity: "1", width: "fit-content", position: "relative"}}
                           checked={inputState.store.isClickAndCollect}
                           onChange={isClickAndCollectHandler}/>
                    <p style={{whiteSpace:"nowrap"}}>U sistemu click and collect</p>

                    <Input inputtype="checkbox" type="checkbox" name="haveDelivery"
                           style={{opacity: "1", width: "fit-content", position: "relative"}}
                           checked={inputState.store.haveDelivery}
                           onChange={haveDeliveryHandler}/>
                    <p style={{whiteSpace:"nowrap"}}>Ima dostavu</p>

                    <Input inputtype="checkbox" type="checkbox" name="haveDelivery"
                           style={{opacity: "1", width: "fit-content", position: "relative"}}
                           checked={inputState.store.haveRFZO}
                           onChange={haveRFZOHandler}/>
                    <p style={{whiteSpace:"nowrap"}}>Ima RFZO terapiju</p>
                </div>
                <div>
                    <p>Naziv HQ-a</p>
                    <Input inputtype="input" type="text" name="branchMarketplaceName" placeholder={"Naziv HQ-a"}
                           value={inputState.store.branchMarketplaceName}
                           onChange={(event) => inputChangedHandler(event, "branchMarketplaceName")}/>
                </div>
                <div>
                    <p>Email HQ-a</p>
                    <Textbox
                        attributesInput={{
                            id: "branchMarketplaceEmail",
                            name: "branchMarketplaceEmail",
                            placeholder: "Email HQ-a",
                            type: "text"
                        }}
                        value={inputState.store.branchMarketplaceEmail} //Optional.[String].Default: "".
                        onChange={(value, event) => inputChangedHandler(event, "branchMarketplaceEmail")}  //Required.[Func].Default: () => {}. Will return the value.
                        customStyleInput={{marginLeft: "7px", marginRight: "7px"}}
                        customStyleWrapper={{width: "100%"}} //Optional.[Object].Default: {}.
                        customStyleContainer={{width: "100%"}} //Optional.[Object].Default: {}.
                        onBlur={(e) => {
                        }} //Optional.[Func].Default: none. In order to validate the value on blur, you MUST provide a function, even if it is an empty function. Missing this, the validation on blur will not work.
                        validationOption={{
                            name: "email", //Optional.[String].Default: "". To display in the Error message. i.e Please enter your {name}.
                            check: true, //Optional.[Bool].Default: true. To determin if you need to validate.
                            required: false, //Optional.[Bool].Default: true. To determin if it is a required field.
                            msgOnError: "Email polje ne moze da bude prazno",
                            customFunc: (email) => {
                                const reg = /(^$|^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$)/; //regex za email ili empty string
                                if (reg.test(String(email).toLowerCase())) {
                                    return true;
                                } else {
                                    return "Unesena email adresa nije validnog formata";
                                }
                            }
                        }}
                    />
                </div>
                <div>
                    <p>Kratak Opis HQ-a</p>
                    <Input inputtype="textarea" type="text" name="branchMarketplaceShortDescription"
                           placeholder={"Kratak Opis HQ-a"} value={inputState.store.branchMarketplaceShortDescription}
                           onChange={(event) => inputChangedHandler(event, "branchMarketplaceShortDescription")}/>
                </div>
                <div>
                    <p>Opis HQ-a</p>
                    <Input inputtype="textarea" type="text" name="branchMarketplaceDescription"
                           placeholder={"Opis HQ-a"} value={inputState.store.branchMarketplaceDescription}
                           onChange={(event) => inputChangedHandler(event, "branchMarketplaceDescription")}/>
                </div>
                <div>
                    <p>Adresa HQ-a</p>
                    <Input inputtype="input" type="text" name="branchMarketplaceAddress" placeholder={"Adresa HQ-a"}
                           value={inputState.store.branchMarketplaceAddress}
                           onChange={(event) => inputChangedHandler(event, "branchMarketplaceAddress")}/>
                </div>
                <div>
                    <p>Broj telefona HQ-a</p>
                    <Textbox
                        attributesInput={{
                            id: "branchMarketplacePhoneNumber",
                            name: "branchMarketplacePhoneNumber",
                            placeholder: "Broj telefona HQ-a (ako ih je vise, razdvojiti ih zarezom)",
                            type: "text"
                        }}
                        value={inputState.store.branchMarketplacePhoneNumber} //Optional.[String].Default: "".
                        onChange={(value, event) => inputChangedHandler(event, "branchMarketplacePhoneNumber")}  //Required.[Func].Default: () => {}. Will return the value.
                        customStyleInput={{marginLeft: "7px", marginRight: "7px"}}
                        customStyleWrapper={{width: "100%"}} //Optional.[Object].Default: {}.
                        customStyleContainer={{width: "100%"}} //Optional.[Object].Default: {}.
                        onBlur={(e) => {
                        }} //Optional.[Func].Default: none. In order to validate the value on blur, you MUST provide a function, even if it is an empty function. Missing this, the validation on blur will not work.
                        validationOption={{
                            name: "phone", //Optional.[String].Default: "". To display in the Error message. i.e Please enter your {name}.
                            check: true, //Optional.[Bool].Default: true. To determin if you need to validate.
                            required: false, //Optional.[Bool].Default: true. To determin if it is a required field.
                            msgOnError: "Polje za broj telefona ne moze da bude prazno",
                            customFunc: (email) => {
                                const reg = /^$|^(((0[0-9]{2}\/[0-9]{3}\-[0-9]{3,4}),*)*)$/; //regex za telefon, empty string ili vise telefona odvojenih zarezom
                                if (reg.test(String(email).toLowerCase())) {
                                    return true;
                                } else {
                                    return "Uneseni telefoni nisu validnog formata - validan format 012/234-567(8)";
                                }
                            }
                        }}
                    />
                </div>
                <div>
                    <p>Maticni HQ-a</p>
                    <Input inputtype="input" type="text" name="branchMarketplaceCompanyNumber"
                           placeholder={"Maticni broj HQ-a"} value={inputState.store.branchMarketplaceCompanyNumber}
                           onChange={(event) => inputChangedHandler(event, "branchMarketplaceCompanyNumber")}/>
                </div>
                <div>
                    <p>PIB HQ-a</p>
                    <Input inputtype="input" type="text" name="branchMarketplaceTaxIdentificationNumber"
                           placeholder={"PIB HQ-a"}
                           value={inputState.store.branchMarketplaceTaxIdentificationNumber}
                           onChange={(event) => inputChangedHandler(event, "branchMarketplaceTaxIdentificationNumber")}/>
                </div>
                <Button buttontype='blue' disabled={props.openHoursChanged} onClick={showOpenHoursButtonHandler}
                        style={{margin: "auto"}}
                >Izmeni radna vremena</Button>
                <br/>

                {/*{props.storeDetails.branchMarketplaceResponse.branchMarketplacePictures !== null &&*/}
                {/*<p style={{width: "100%", color: "green", display: "flex", justifyContent: "center"}}>Ova filijala vec*/}
                {/*    ima setovanu fotografiju</p>}*/}

                <Backdrop show={props.openHoursChanged}
                          backdropClickHandler={()=>{props.onOpenHoursChanged(false)}}
                          zIndex={15}
                          backgroundColor="rgba(0, 0, 0, .2)"/>

            </React.Fragment>
        );

    }

    return (
        <div className={styles.ProdajnoMestoForm}>
            {/*{props.storeDetails !== null && <h3>{props.storeDetails.name}</h3>}*/}
            <form>
                {inputs}

                <div >
                    {reportMessageState.showErrorMessage && <p className={msgStyles.ErrorMsg}>{reportMessageState.errorMessage}</p>}
                    {reportMessageState.showSuccessMessage && <p className={msgStyles.SuccessMsg}>{reportMessageState.successMessage}</p>}
                </div>
                <Button buttontype='blue'
                        disabled={props.openHoursChanged}
                        onClick={updateStore}
                        style={{margin: "auto"}}
                >Sačuvaj izmene</Button>
            </form>
        </div>

    );
}


const mapStateToProps = (state) => {
    return {
        token: state.authPage.token,
        openHoursChanged: state.cmsPage.openHoursChanged,
        updatedPictures: state.cmsPage.updatedPictures,
        updatedBranchMarketplacePictures: state.cmsPage.updatedBranchMarketplacePictures
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onOpenHoursChanged: (openHoursChanged) => dispatch(actions.openHoursModalChanged(openHoursChanged)),
        onStoreChanged: (changedStore, changedStoreIndex) => dispatch(actions.storeChanged(changedStore, changedStoreIndex)),
        onUpdatePictures: (pictures) => dispatch(actions.updatePictures(pictures)),
        onUpdateBranchMarketplacePictures: (pictures) => dispatch(actions.updateBranchMarketplacePictures(pictures))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProdajnoMestoForm);
