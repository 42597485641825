import * as actionsTypes from './actionsTypes';

export const showShoppingCartDrawerChanged = () => {
    return {
        type: actionsTypes.SHOW_SHOPPING_CARD_DRAWER_CHANGED
    };
}

export const toolbarWhiteChanged = (toolbarWhite) => {
    return {
        type: actionsTypes.TOOLBAR_WHITE,
        toolbarWhite: toolbarWhite
    }
}