import React, {Fragment, useEffect, useState} from "react";
import styles from "./LoyaltyBadge.module.css";
import TooltipComponent from "../TooltipComponent/TooltipComponent";
import Media from "react-media";
import ModalWithHeader from "../Modals/ModalWithHeader/ModalWithHeader";
import Button from "../Buttons/Button/Button";
import {ReactComponent as GoldClockIcon} from "../../../assets/icons/goldClock.svg";

const LoyaltyBadge = (props) => {

    let loyaltyBadge = [styles.LoyaltyBadge, props.className].join(' ');

    const [loyaltyTime, setLoyaltyTime] = useState(null);
    const [tooltipModalVisible, setTooltipModalVisible] = useState(false);
    const [mobileTooltip, setMobileTooltip] = useState();

    useEffect(() => {
        if (props.number > 0) {
            let mins;
            console.log("props.number")
            console.log(props.number);
            if (props.number % 60 < 10) {
                mins = "0" + props.number % 60
            } else {
                mins = props.number % 60
            }
            let h = Math.floor(props.number / 60)
            h = h % 24;
            if (h > 0) {
                setLoyaltyTime(Math.floor(props.number / 60) + ":" + mins);
            } else {
                setLoyaltyTime("0:" + mins)
            }
        }
    }, [props.number]);

    const openTooltipModal = () => {
        setTooltipModalVisible(true);
    }

    const closeTooltipModal = (e) => {
        e.stopPropagation();
        setTooltipModalVisible(false);
    }

    useEffect(()=>{
        setMobileTooltip(
            <ModalWithHeader show={tooltipModalVisible}
                             backdropClickHandler={closeTooltipModal}
                             onCloseClick={closeTooltipModal}
                             className={styles.TooltipModal}
                             contentStyle={{textAlign: "center"}}
                             backgroundColor="#FFAE00"
            >
                <GoldClockIcon/>
                <span style={{marginTop: "1rem", marginBottom: "1rem"}}>Naša aplikacija ti štedi vreme, čime ti ostavljamo dodatne minute za trenutke koji su ti važni. Svaki naš
Loyalty bod je minut više za tebe. Uspešnom rezervacijom proizvoda koji su deo Loyalty programa, u bilo
kojoj apoteci koju ti odabereš, stičeš naznačeni broj Loyalty minuta.</span>
                <Button onClick={closeTooltipModal} buttontype="yellow">Zatvori</Button>
            </ModalWithHeader>
        )
    }, [tooltipModalVisible])

    return (
        <div className={loyaltyBadge}
             onClick={openTooltipModal}
             style={{
                 width: props.width,
                 height: props.height,
                 backgroundColor: props.backgroundColor,
                 borderRadius: props.borderRadius,
                 border: props.border
             }}
        >
            <div className={styles.TooltipWrapper}>
                <div className={styles.Number}>{loyaltyTime}</div>
            </div>
            <Media
                queries={{
                    small: "(max-width: 699px)",
                    large: "(min-width: 700px)"
                }}>
                {matches => (
                    <Fragment>
                        {matches.small && tooltipModalVisible &&
                            mobileTooltip
                        }
                        {matches.large &&
                            <TooltipComponent className={styles.TooltipText}
                                              text="Naša aplikacija ti štedi vreme, čime ti ostavljamo dodatne minute za trenutke koji su ti važni. Svaki naš
Loyalty bod je minut više za tebe. Uspešnom rezervacijom proizvoda koji su deo Loyalty programa, u bilo
kojoj apoteci koju ti odabereš, stičeš naznačeni broj Loyalty minuta."/>
                        }
                    </Fragment>)}
            </Media>
        </div>
    );
}

export default LoyaltyBadge