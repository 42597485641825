import React, {useEffect, useState} from "react";
import ArticleAction from "./ArticleAction/ArticleAction";
import styles from './ArticleAction/ArticleAction.module.css'
import TooltipComponent from "../../../UI/TooltipComponent/TooltipComponent";
import {ReactComponent as HeartIcon} from "../../../../assets/icons/heart.svg";
import {ReactComponent as CompareIcon} from "../../../../assets/icons/copy.svg";
import {ReactComponent as VideoIcon} from "../../../../assets/icons/video_icon.svg";
import AddArticleToTherapyFromInfoLekModal
    from "../../../UI/Modals/AddArticleToTherapyFromInfoLekModal/AddArticleToTherapyFromInfoLekModal";
import * as actions from "../../../../store/actions";
import {connect} from "react-redux";

const ArticleActions = (props) => {

    const [showAddArticleModal, setShowAddArticleModal] = useState(false)

    const openAddArticleModal = () => {
        setShowAddArticleModal(true)
        props.onAddArticleToTherapyFromInfoLekModalToggle(true);
    }

    const closeAddArticleModal = () => {
        setShowAddArticleModal(false)
        props.onAddArticleToTherapyFromInfoLekModalToggle(false);
    }

    useEffect(()=>{
        if(props.addArticleToTherapyFromInfoLekModalVisible === false) {
            closeAddArticleModal()
        }
    }, [props.addArticleToTherapyFromInfoLekModalVisible])

    return (
        <>
        <div>
            <div className={styles.TooltipWrapper} style={{cursor: "pointer", userSelect: "none", color: "#4a4f54"}} onClick={openAddArticleModal}>
                <ArticleAction text={'Terapija'}>
                    <HeartIcon style={{width: "1.5rem", padding: "0 1rem", stroke: "#4a4f54"}}/>
                </ArticleAction>
                <TooltipComponent className={styles.TooltipText} text="Terapija"/>
            </div>

            <div className={styles.TooltipWrapper} style={{cursor: "pointer", userSelect: "none", color: "#4a4f54"}}>
                <ArticleAction text={'Online podrška'}>
                    <VideoIcon style={{width: "1.5rem", padding: "0 1rem", stroke: "#4a4f54"}}/>
                </ArticleAction>
                <TooltipComponent className={styles.TooltipText} text="Pozovi farmaceuta"/>
            </div>

            <div className={styles.TooltipWrapper} style={{cursor: "pointer", userSelect: "none", color: "ButtonHighlight"}}>
                <ArticleAction text={'Uporedi lek'}>
                    <CompareIcon style={{width: "1.5rem", padding: "0 1rem", stroke: "ButtonHighlight"}}/>
                </ArticleAction>
                <TooltipComponent className={styles.TooltipText} text="Uporedi lek - USKORO!"/>
            </div>
        </div>
            {showAddArticleModal &&
                <AddArticleToTherapyFromInfoLekModal
                    show={showAddArticleModal}
                    onCloseClick={closeAddArticleModal}
                    backdropClickHandler={closeAddArticleModal}
                    article={props.article}
                />
            }
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        addArticleToTherapyFromInfoLekModalVisible: state.userProfile.addArticleToTherapyFromInfoLekModalVisible
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onFetchArticleInfo: (articleJKL) => dispatch(actions.fetchArticleInfo(articleJKL)),
        onAddArticleToTherapyFromInfoLekModalToggle: (state) => dispatch(actions.addArticleToTherapyFromInfoLekModalToggle(state))
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(ArticleActions);