import * as actionsTypes from "../actions/actionsTypes";

const initialState = {


    rfzoTherapy: null,
    rfzoTherapyFailed: false,
    loadingRFZOTherapy: false,

    acceptRFZOTherapyFailed: false,
    loadingAcceptRFZOTherapy: false,
    rfzoTherapyAccepted: false,
    rfzoTherapyRejected: false,

    rejectRFZOTherapyFailed: false,
    loadingRejectRFZOTherapy: false,

    rfzoTherapyCreated: false,
    loadingCreateRfzoTherapy: false,
    createRfzoTherapyFailed: false,

    RFZOSocketStatus: null,
    RFZOSocketConnectionStart: false,

    rfzoModalToggle: false,

    rfzoEmptyTherapyDeleted: false,
    rfzoEmptyTherapyDeleteStart: false,
    rfzoEmptyTherapyDeleteFailed: false,

    rfzoTherapyExpire: false,
    rfzoTherapyExpireStart: false,
    rfzoTherapyExpireFailed: false,

    rfzoTherapyCancel: false,
    rfzoTherapyCancelStart: false,
    rfzoTherapyCancelFailed: false

}

const rfzoTherapyReducer = (state = initialState, action) => {
    switch (action.type) {

        case actionsTypes.FETCH_RFZO_THERAPY:
            return {
                ...state,
                rfzoTherapy: action.rfzoTherapy,
                rfzoTherapyFailed: false,
                loadingRFZOTherapy: false
            }
        case actionsTypes.FETCH_RFZO_THERAPY_FAILED:
            return {
                ...state,
                rfzoTherapyFailed: true,
                loadingRFZOTherapy: false
            }
        case actionsTypes.FETCH_RFZO_THERAPY_START:
            return {
                ...state,
                rfzoTherapyFailed: false,
                loadingRFZOTherapy: true
            }

        case actionsTypes.RFZO_THERAPY_ACEPTED:
            return {
                ...state,
                rfzoTherapyRejected: false,
                rfzoTherapyAccepted: true,
                acceptRFZOTherapyFailed: false,
                loadingAcceptRFZOTherapy: false
            }
        case actionsTypes.ACCEPT_RFZO_THERAPY_FAILED:
            return {
                ...state,
                rfzoTherapyRejected: false,
                rfzoTherapyAccepted: false,
                acceptRFZOTherapyFailed: true,
                loadingAcceptRFZOTherapy: false
            }
        case actionsTypes.ACCEPT_RFZO_THERAPY_START:
            return {
                ...state,
                rfzoTherapyRejected: false,
                rfzoTherapyAccepted: false,
                acceptRFZOTherapyFailed: false,
                loadingAcceptRFZOTherapy: true
            }


        case actionsTypes.RFZO_THERAPY_REJECTED:
            return {
                ...state,
                rfzoTherapyRejected: true,
                rfzoTherapyAccepted: false,
                rejectRFZOTherapyFailed: false,
                loadingRejectRFZOTherapy: false
            }
        case actionsTypes.REJECT_RFZO_THERAPY_FAILED:
            return {
                ...state,
                rfzoTherapyRejected: false,
                rfzoTherapyAccepted: false,
                rejectRFZOTherapyFailed: true,
                loadingRejectRFZOTherapy: false
            }
        case actionsTypes.REJECT_RFZO_THERAPY_START:
            return {
                ...state,
                rfzoTherapyAccepted: false,
                rejectRFZOTherapyFailed: false,
                loadingRejectRFZOTherapy: true
            }
        case actionsTypes.RFZO_THERAPY_CREATED:
            return {
                ...state,
                rfzoTherapyCreated: true,
                loadingCreateRfzoTherapy: false,
                createRfzoTherapyFailed: false
            }
        case actionsTypes.CREATE_RFZO_THERAPY_FAILED:
            return {
                ...state,
                rfzoTherapyCreated: false,
                loadingCreateRfzoTherapy: false,
                createRfzoTherapyFailed: true
            }
        case actionsTypes.CREATE_RFZO_THERAPY_START:
            return {
                ...state,
                rfzoTherapyCreated: false,
                loadingCreateRfzoTherapy: true,
                createRfzoTherapyFailed: false
            }
        case actionsTypes.RESET_RFZO_THERAPY_CREATED:
            return {
                ...state,
                rfzoTherapyCreated: false,
                loadingCreateRfzoTherapy: false,
                createRfzoTherapyFailed: false
            }
        case actionsTypes.CHECK_RFZO_THERAPY:
            return {
                ...state,
                rfzoTherapy: action.checkRFZOTherapy.length > 0 ? {...action.checkRFZOTherapy[0]} : null,
                rfzoTherapyFailed: false,
                loadingRFZOTherapy: false,
            }
        case actionsTypes.CHECK_RFZO_THERAPY_FAILED:
            return {
                ...state,
                rfzoTherapy: null,
                rfzoTherapyFailed: true,
                loadingRFZOTherapy: false,
            }
        case actionsTypes.CHECK_RFZO_THERAPY_START:
            return {
                ...state,
                rfzoTherapy: null,
                rfzoTherapyFailed: false,
                loadingRFZOTherapy: true,
            }
        case actionsTypes.RFZO_SOCKET_CONNECTION_STATUS:
            return {
                ...state,
                RFZOSocketStatus: action.RFZOSocketStatus,
                RFZOSocketConnectionStart: false
            }
        case actionsTypes.RFZO_SOCKET_CONNECTION_START:
            return {
                ...state,
                RFZOSocketConnectionStart: true
            }
        case actionsTypes.RFZO_MODAL_TOGGLE:
            return {
                ...state,
                rfzoModalToggle: action.rfzoModalToggle
            }
        case actionsTypes.DELETE_EMPTY_RFZO_THERAPY_START:
            return {
                ...state,
                rfzoEmptyTherapyDeleted: false,
                rfzoEmptyTherapyDeleteStart: true,
                rfzoEmptyTherapyDeleteFailed: false
            }
        case actionsTypes.DELETE_EMPTY_RFZO_THERAPY_FAILED:
            return {
                ...state,
                rfzoEmptyTherapyDeleted: false,
                rfzoEmptyTherapyDeleteStart: false,
                rfzoEmptyTherapyDeleteFailed: true
            }
        case actionsTypes.DELETE_EMPTY_RFZO_THERAPY_SUCCESS:
            return {
                ...state,
                rfzoEmptyTherapyDeleted: true,
                rfzoEmptyTherapyDeleteStart: false,
                rfzoEmptyTherapyDeleteFailed: false
            }
        case actionsTypes.RESET_EMPTY_RFZO_THERAPY_FAILED:
            return {
                ...state,
                rfzoEmptyTherapyDeleted: false,
                rfzoEmptyTherapyDeleteStart: false,
                rfzoEmptyTherapyDeleteFailed: false
            }


        case actionsTypes.RFZO_THERAPY_EXPIRE:
            return {
                ...state,
                rfzoTherapyExpire: true,
                rfzoTherapyExpireStart: false,
                rfzoTherapyExpireFailed: false,
                rfzoTherapy: null
            }
        case actionsTypes.RFZO_THERAPY_EXPIRE_FAILED:
            return {
                ...state,
                rfzoTherapyExpire: false,
                rfzoTherapyExpireStart: false,
                rfzoTherapyExpireFailed: true
            }
        case actionsTypes.RFZO_THERAPY_EXPIRE_START:
            return {
                ...state,
                rfzoTherapyExpire: false,
                rfzoTherapyExpireStart: true,
                rfzoTherapyExpireFailed: false
            }


        case actionsTypes.RFZO_THERAPY_CANCEL:
            return {
                ...state,
                rfzoTherapyCancel: true,
                rfzoTherapyCancelStart: false,
                rfzoTherapyCancelFailed: false,
                rfzoTherapy: null
            }
        case actionsTypes.RFZO_THERAPY_CANCEL_FAILED:
            return {
                ...state,
                rfzoTherapyCancel: false,
                rfzoTherapyCancelStart: false,
                rfzoTherapyCancelFailed: true
            }
        case actionsTypes.RFZO_THERAPY_CANCEL_START:
            return {
                ...state,
                rfzoTherapyCancel: false,
                rfzoTherapyCancelStart: true,
                rfzoTherapyCancelFailed: false
            }

        case actionsTypes.RFZO_THERAPY_STATUS_CHANGED:
            return {
                ...state,
                rfzoTherapy: {...state.rfzoTherapy, status: action.rfzoTherapyStatus}
            }

        case actionsTypes.SET_WILL_BE_READY:
            return {
                ...state,
                rfzoTherapy: {...state.rfzoTherapy, willBeReady: action.willBeReady}
            }

        case actionsTypes.SET_CANCEL_NOTE:
            return {
                ...state,
                rfzoTherapy: {...state.rfzoTherapy, noteForUser: action.cancelNote}
            }
        default:
            return state;
    }
}

export default rfzoTherapyReducer;
