import React from 'react';
import styles from "../../ShopPage/ArticleItems/ArticleItem/ArticleItem.module.css";
import SquareBadge from "../SquareBadge/SquareBadge";
import TooltipComponent from "../TooltipComponent/TooltipComponent";

const ItemAvailabilityBadge = (props) => {

    // const [boxColor, setBoxColor] = useState(null);
    //
    // useEffect(() => {
    //     switch (props.itemResponse.itemAvailabilityShortCode) {
    //         case('RP'):
    //             setBoxColor('#FC6D6D');
    //             break;
    //         case('BR'):
    //             setBoxColor ('#00B2B1');
    //             break;
    //         case('SZ'):
    //         case('Z'):
    //         case('SR'):
    //             setBoxColor ( '#376D9F');
    //             break;
    //         case('NR'):
    //         case('ZN'):
    //         case('SN'):
    //             setBoxColor ( '#4D5055');
    //             break;
    //         case('MS'):
    //             setBoxColor ( '#FBC932');
    //             break;
    //         case('D'):
    //             setBoxColor ( '#9752A9');
    //             break;
    //         default:
    //             break;
    //     }
    // }, [props.itemResponse])


    return (
        props.itemResponse.itemAvailabilityShortCode &&
        <div className={props.className ? props.className : styles.ItemAvailability}>
            <SquareBadge
                backgroundColor = {props.itemResponse.itemAvailabilityColor}
            >
                <span style={{color: "white", fontWeight: "bold", fontSize:"14px"}}>{props.itemResponse.itemAvailabilityShortCode}</span>
            </SquareBadge>
            <TooltipComponent className={styles.ItemAvailabilityTooltipText} text={props.itemResponse.itemAvailability}/>
        </div>
    );
};

export default ItemAvailabilityBadge;