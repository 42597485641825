import React, {useEffect, useState} from 'react';
import styles from './SearchBar.module.css'
import SearchItems from '../SearchItems/SearchItems'
import { connect } from 'react-redux';
import * as actions from "../../../../store/actions";
import {withRouter} from "react-router-dom";
import Backdrop from "../../../UI/Backdrop/Backdrop";
import SearchInput from "./SearchInput/SearchInput";
import SearchStores from "../SearchStores/SearchStores";


const SearchBar = React.memo(props => {

    // const fieldRef = useRef(null);
    //
    // const [searchBarState, setSearchBarState] = useState({
    //     typing: false,
    //     typingTimeout: 0,
    //     inputString: '',
    //     typingString: ''
    // });

    //Promenjiva na osnovu koje prikazujemo SearchItems
    const [showMatchedItemsState, showMatchedItemsStateChanged] = useState({
        isSearchListForItemsOpen: false,
        isSearchListForStoresOpen: false
    })

    //Ucitava podatke za search bar sa servera.
    // Prvo proverava da li podaci nisu vec ucitani i proverava da li smo na main page-u a ne na stranici shop-a.
    // Ako ispunjava ove uslove onda pozivamo metodu za ucitavanje sa back-a.
    useEffect(() => {
        if (props.articles === null && !props.history.location.pathname.includes("/shop/")){
            props.onInitArticles();
        }
        if (props.storesNames === null && props.onMainPage){
            props.onInitStoresNames();
        }
    }, [])

    // //Ako smo izabrali neki novi lek, onda zatvoriti padajuci menu search bar-a (SearchItems).
    // useEffect(() => {
    //     // fieldRef.current.value = "";
    //     // fieldRef.current.blur();
    //     showMatchedItemsStateChanged({
    //         isSearchListForItemsOpen: false,
    //         isSearchListForStoresOpen: false
    //     });
    // },[props.match.params])


    // /**
    //  * This method will be called for every change in search bar.
    //  * Method will be called depending on string length, and if length is 3 or more, we will open SearchItems menu.
    //  * @param event
    //  */
    // const enterSearchTextHandler = (event) => {
    //
    //     if (searchBarState.typingTimeout) {
    //         clearTimeout(searchBarState.typingTimeout)
    //     }
    //
    //     let str = event.target.value;
    //     setSearchBarState({
    //         ...searchBarState,
    //         typingString: str
    //     })
    //
    //     if (str === '' || str.length < 3){
    //         setShowMatchedItemsState({
    //             isSearchListOpen: false
    //         });
    //     }
    //
    //     if (str.trim().length >= 3) {
    //         setSearchBarState({
    //             // typingString: str,
    //             inputString: str,
    //             typing: false,
    //             typingTimeout: setTimeout(() => {
    //                 props.onSearchStringChange(str);
    //                 setShowMatchedItemsState({
    //                     isSearchListOpen: str !== ''
    //                 });
    //             }, 600)
    //         })
    //     }
    // }

    // /**
    //  * This method will handle up,down and enter key press.
    //  * @param event
    //  */
    // const handleArrowPress = (event) => {
    //     const key = event.keyCode;
    //     //ako je pritisnut key down taster povecavamo brojac index-a za 1
    //     if (showMatchedItemsState.isSearchListOpen && key === 40 && props.selectedIndex < props.numberOfMatchedArticles){
    //         props.onSelectedIndexChanged(props.selectedIndex + 1 )
    //     }
    //     if (showMatchedItemsState.isSearchListOpen && key === 38 && props.selectedIndex > 0){
    //
    //         event.stopPropagation()
    //         event.preventDefault();
    //         props.onSelectedIndexChanged(props.selectedIndex - 1)
    //     }
    //
    //     //User press Enter (13)
    //     if (showMatchedItemsState.isSearchListOpen && key === 13){
    //
    //         if ( props.selectedIndex > 0) {
    //             event.stopPropagation();
    //             event.preventDefault();
    //             props.onSelectDetected(true);
    //         }
    //         if (props.selectedIndex === 0 && props.history.location.pathname.includes("/shop/")) {
    //             props.history.push("/shop/" + props.match.params.branchMarketplaceId + "/"
    //                 + props.match.params.marketplaceId + "/0/" + searchBarState.inputString);
    //         }
    //
    //     }
    // }

    /**
     * This method will close SearchItems menu and clear search bar.
     */
    const clickOnBackdropHandler = () => {
        showMatchedItemsStateChanged({
            isSearchListForItemsOpen: false,
            isSearchListForStoresOpen: false

        });
        // setSearchBarState({
        //     ...searchBarState,
        //     typingString: '',
        //     inputString: ''
        // })
    }

    const changeSearchListForItemsOpen = (value) => {
        showMatchedItemsStateChanged({
            isSearchListForItemsOpen: value,
            isSearchListForStoresOpen: false

        });
    }

    const changeSearchListForStoresOpen = (value) => {
        showMatchedItemsStateChanged({
            isSearchListForItemsOpen: false,
            isSearchListForStoresOpen: value

        });
    }

    // const clickOnSearchIcon = () => {
    //     if (props.history.location.pathname.includes("/shop/") && showMatchedItemsState.isSearchListOpen) {
    //         props.history.push("/shop/" + props.match.params.branchMarketplaceId + "/"
    //             + props.match.params.marketplaceId + "/0/" + searchBarState.inputString);
    //     }
    // }

    return (
        <React.Fragment>
            <div className={props.onMainPage ? styles.SearchBarMainPageWrapper : styles.SearchBarActionMenuWrapper}>
                <div
                    className={props.onMainPage ? styles.SearchBarMainPage : [styles.SearchBarMainPage, styles.SearchBarActionMenu].join(' ')}>

                    {/*<input type="input"*/}
                    {/*       placeholder={(!props.onMainPage && props.articleDetails !== null) ? props.articleDetails.name : "Unesite naziv artikla"}*/}
                    {/*       ref={fieldRef}*/}
                    {/*       value={searchBarState.typingString}*/}
                    {/*       style={showMatchedItemsState.isSearchListOpen ? {boxShadow: "none", border: "none"} : null}*/}
                    {/*       onChange={enterSearchTextHandler}*/}
                    {/*       onKeyDown={handleArrowPress}*/}
                    {/*       className={window.location.pathname.includes('shop') ? styles.SearchBarShopPage : null}*/}
                    {/*       />*/}
                    {((!window.location.pathname.includes('searched-stores') && !props.onMainPage) ||
                    (props.onMainPage && props.typeOfSearch === "ITEMS")) ? <SearchInput
                        storesSearch={false}
                        onMainPage={props.onMainPage}
                        openList={changeSearchListForItemsOpen}
                        isListOpen={showMatchedItemsState.isSearchListForItemsOpen}
                    /> : null}

                    {/*/!*todo dodati da se prikazuje samo ako je u pitanju homepage*!/*/}
                    {/*{props.onMainPage && (!props.searchInputFocus &&*/}
                    {/*    <div style={{borderLeft: "1px solid #DDDDDD", height: "40%", alignSelf: "center"}}></div>)*/}
                    {/*}*/}

                    {((props.onMainPage && props.typeOfSearch === "STORES") || window.location.pathname.includes('searched-stores')) &&
                    <SearchInput
                        storesSearch={true}
                        onMainPage={props.onMainPage}
                        openList={changeSearchListForStoresOpen}
                        isListOpen={showMatchedItemsState.isSearchListForStoresOpen}
                    />}

                    {showMatchedItemsState.isSearchListForItemsOpen?<SearchItems onMainPage={props.onMainPage} />:null}
                    {(props.onMainPage || window.location.pathname.includes('searched-stores')) && (showMatchedItemsState.isSearchListForStoresOpen?<SearchStores onMainPage={props.onMainPage} />:null)}

                </div>
            </div>
            <Backdrop show={showMatchedItemsState.isSearchListForItemsOpen || showMatchedItemsState.isSearchListForStoresOpen}
                      backdropClickHandler={clickOnBackdropHandler}
                      zIndex={20}/>
        </React.Fragment>

    );
})

const mapStateToProps = (state) => {
    return {
        stores: state.appPage.stores,
        articles: state.mainPage.articles,
        storesNames: state.mainPage.storesNames,
        searchString: state.mainPage.searchString,
        selectedIndex: state.mainPage.selectedIndex,
        numberOfMatchedArticles: state.mainPage.numberOfMatchedArticles,
        articleDetails: state.appPage.articleDetails,
        searchInputFocus: state.appPage.searchInputFocus
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        onSearchStringChange: (string) => dispatch(actions.searchStringChanged(string)),
        onSelectedIndexChanged: (index) => dispatch(actions.selectedIndexChanged(index)),
        onSelectDetected: (isSelected) => dispatch(actions.selectDetected(isSelected)),
        onInitArticles: () => dispatch(actions.initArticles()),
        onInitStoresNames: () => dispatch(actions.initStoresNames()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SearchBar));
