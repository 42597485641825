import React, {useEffect} from 'react'
import styles from './Stepline.module.css'
import {connect} from "react-redux";
import { withRouter } from 'react-router-dom'

const Stepline = props => {

    let url = props.location.pathname.split('/');

    useEffect(() => {
        url = props.location.pathname.split('/');
        console.log(url);
    }, [window.location.pathname])

    const StepNumberBlock = (props) =>
        <div className={!props.deliverySelected && props.address ? [styles.SteplineItem,styles.Skipped].join(' ') :
            (props.marker ? [styles.SteplineItem, styles.Marked].join(' ') : [styles.SteplineItem, styles.Unmarked].join(' '))}>
            <div className={!props.deliverySelected && props.address ? [styles.StepNumberText, styles.Skipped].join(' ') :
                (props.marker ? [styles.StepNumberText, styles.Marked].join(' ') : [styles.StepNumberText, styles.Unmarked].join(' '))}>
                {props.stepNumberText}
            </div>
        </div>

    return (
        <>
            <div className={styles.SteplineWrapper}>
                <div className={styles.StepsWrapper}>
                    <StepNumberBlock stepNumberText="Proizvodi i količina"
                                      marker={(url.length === 6 || (url[4] === 'delivery')
                                      || (url[4] === 'addresses') || (url[4] === 'confirmation')
                                      ) && true} />

                    <StepNumberBlock stepNumberText="Način isporuke"
                                      marker={(url[4] === 'delivery' || (url[4] === 'addresses')
                                      || (url[4] === 'confirmation')
                                      ) && true} />

                    <StepNumberBlock stepNumberText="Korisnički podaci"
                                      // address={true}
                                      deliverySelected={localStorage.getItem('deliveryMethod') === 'deliveryToAddress'}
                                      marker={(url[4] === 'addresses' || url[4] === 'confirmation') && true} />

                    <StepNumberBlock stepNumberText="Završetak rezervacije"
                                      marker={url[4] === 'confirmation' && true} />
                </div>
            </div>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        selectedStore: state.appPage.selectedStore,
        collectOrDelivery: state.orderCompletion.collectOrDelivery,
    }
}

export default connect(mapStateToProps, null) (withRouter(Stepline))