import React from 'react'
import {withRouter, Route} from 'react-router-dom'
import Toolbar from "../Navigation/Toolbar/Toolbar";
import SubNavigation from "./SubNavigation/SubNavigation";
import MyProfilePaneContentHolder from './MyProfilePaneContentHolder/MyProfilePaneContentHolder'
import MyProfilePane from "./MyProfilePane/MyProfilePane";
import MyOrders from "./MyOrders/MyOrders";
// import MyFavouritePharmacies from './MyFavouritePharmacies/MyFavouritePharmacies'
import Wrapper from "../UI/Wrapper/Wrapper";
import MyTherapies from "./MyTherapies/MyTherapies";
import MyLoyalty from "./MyLoyalty/MyLoyalty";

const MyProfilePage = () => {

    return (
        <Wrapper>
            <Toolbar/>
            <SubNavigation/>
            <MyProfilePaneContentHolder>
                <Route path="/myprofile" exact component={MyProfilePane}/>
                <Route path="/myprofile/my-orders" exact component={MyOrders}/>
                <Route path="/myprofile/loyalty" exact component={MyLoyalty}/>
                <Route path="/myprofile/my-therapies" exact component={MyTherapies}/>
                {/*<Route path="/myprofile/favourite-pharmacies" exact component={MyFavouritePharmacies}/>*/}
            </MyProfilePaneContentHolder>
        </Wrapper>
    );
}

export default (withRouter(MyProfilePage));