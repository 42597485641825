import * as actionsTypes from './actionsTypes'
import axios from '../../axios/axios-apoteka'



export const rfzoTherapy = (rfzoTherapy) => {
    return {
        type: actionsTypes.FETCH_RFZO_THERAPY,
        rfzoTherapy: rfzoTherapy
    };
}

export const fetchRFZOTherapyFailed = () => {
    return {
        type: actionsTypes.FETCH_RFZO_THERAPY_FAILED
    };
}

export const fetchRFZOTherapyStart = () => {
    return {
        type: actionsTypes.FETCH_RFZO_THERAPY_START
    };
}

export const fetchRFZOTherapy = (rfzoTherapyId) => {

    return dispatch => {
        dispatch(fetchRFZOTherapyStart());
        const URL = '/rfzo_therapy/' + rfzoTherapyId;
        axios.get(URL)
            .then(response => {
                console.log(response.data)
                dispatch(rfzoTherapy(response.data))
            })
            .catch(error => {
                console.log("Error se desio" + error);
                dispatch(fetchRFZOTherapyFailed())
            })
    };

}

//accept rfzo therapy
export const rfzoTherapyAccepted = () => {
    return {
        type: actionsTypes.RFZO_THERAPY_ACEPTED,
    };
}

export const acceptRFZOTherapyFailed = () => {
    return {
        type: actionsTypes.ACCEPT_RFZO_THERAPY_FAILED
    };
}

export const acceptRFZOTherapyStart = () => {
    return {
        type: actionsTypes.ACCEPT_RFZO_THERAPY_START
    };
}

export const acceptRFZOTherapy = (rfzoTherapyId, recipientEmail = null) => {

    return dispatch => {
        dispatch(acceptRFZOTherapyStart());
        const URL = '/rfzo_therapy/' + rfzoTherapyId + (recipientEmail === null ? "" : "?recipientEmail=" + recipientEmail);
        axios.post(URL)
            .then(response => {
                console.log(response.data)
                dispatch(rfzoTherapyAccepted(response.data))
            })
            .catch(error => {
                console.log("Error se desio" + error);
                dispatch(acceptRFZOTherapyFailed())
            })
    };

}


//reject rfzo therapy
export const rfzoTherapyRejected = () => {
    return {
        type: actionsTypes.RFZO_THERAPY_REJECTED,
    };
}

export const rejectRFZOTherapyFailed = () => {
    return {
        type: actionsTypes.REJECT_RFZO_THERAPY_FAILED
    };
}

export const rejectRFZOTherapyStart = () => {
    return {
        type: actionsTypes.REJECT_RFZO_THERAPY_START
    };
}

export const rejectRFZOTherapy = (rfzoTherapyId, status, recipientEmail = null) => {
    return dispatch => {
        dispatch(rejectRFZOTherapyStart());
        const URL = "/rfzo_therapy/" + rfzoTherapyId + "?status=" + status + (recipientEmail === null ? "" : "&recipientEmail=" + recipientEmail);
        axios.delete(URL)
            .then(response => {
                console.log(response.data)
                dispatch(rfzoTherapyRejected())
            })
            .catch(error => {
                console.log("Error se desio" + error);
                dispatch(rejectRFZOTherapyFailed())
            })
    };

}

//create RFZO therapy
export const rfzoTherapyCreated = () => {
    return {
        type: actionsTypes.RFZO_THERAPY_CREATED,
    };
}

export const createRFZOTherapyFailed = () => {
    return {
        type: actionsTypes.CREATE_RFZO_THERAPY_FAILED
    };
}

export const createRFZOTherapyStart = () => {
    return {
        type: actionsTypes.CREATE_RFZO_THERAPY_START
    };
}

export const createRFZOTherapy = (rfzoTherapyRequest) => {

    return dispatch => {
        dispatch(createRFZOTherapyStart());
        const URL = '/rfzo_therapy';
        axios.post(URL, rfzoTherapyRequest)
            .then(response => {
                console.log(response.data)
                dispatch(rfzoTherapyCreated(response.data))
            })
            .catch(error => {
                console.log("Error se desio" + error);
                dispatch(createRFZOTherapyFailed())
            })
    };

}

export const resetRFZOTherapyCreated = () => {
    return {
        type: actionsTypes.RESET_RFZO_THERAPY_CREATED
    }
}

// check if active rfzo therapy exist
export const checkRFZOTherapy = (checkRFZOTherapy) => {
    return {
        type: actionsTypes.CHECK_RFZO_THERAPY,
        checkRFZOTherapy: checkRFZOTherapy
    };
}

export const checkAllRFZOTherapiesFailed = () => {
    return {
        type: actionsTypes.CHECK_RFZO_THERAPY_FAILED
    };
}

export const checkAllRFZOTherapiesStart = () => {
    return {
        type: actionsTypes.CHECK_RFZO_THERAPY_START
    };
}

export const checkAllRFZOTherapies = (userId) => {
    return dispatch => {
        dispatch(checkAllRFZOTherapiesStart());
        const URL = '/rfzo_therapy/user/' + userId;
        axios.get(URL)
            .then(response => {
                // console.log(response.data)
                dispatch(checkRFZOTherapy(response.data))
            })
            .catch(error => {
                console.log("Error se desio" + error);
                dispatch(checkAllRFZOTherapiesFailed())
            })
    };

}

// delete empty rfzo therapy

export const deleteEmptyRFZOTherapyStart = () => {
    return {
        type: actionsTypes.DELETE_EMPTY_RFZO_THERAPY_START
    };
}

export const deleteEmptyRFZOTherapySuccess =()=> {
    return {
        type: actionsTypes.DELETE_EMPTY_RFZO_THERAPY_SUCCESS
    }
}

export const deleteEmptyRFZOTherapyFailed = () => {
    return {
        type: actionsTypes.DELETE_EMPTY_RFZO_THERAPY_FAILED
    }
}

export const resetEmptyRFZOTherapy =()=> {
    return {
        type: actionsTypes.RESET_EMPTY_RFZO_THERAPY_FAILED
    }
}


export const deleteEmptyRFZOTherapy = (rfzoTherapyId, userEmail) => {
    return dispatch => {
        dispatch(deleteEmptyRFZOTherapyStart());
        const URL = '/rfzo_therapy/no_content/' + rfzoTherapyId + '?recipientEmail=' + userEmail;
        axios.delete(URL)
            .then(response => {
                console.log(response.data);
                dispatch(deleteEmptyRFZOTherapySuccess())
            })
            .catch(error => {
                dispatch(deleteEmptyRFZOTherapyFailed())
            })

    };
}


export const rfzoTherapyExpireConfirmed = () => {
    return {
        type: actionsTypes.RFZO_THERAPY_EXPIRE
    };
}

export const rfzoTherapyExpireStart =()=> {
    return {
        type: actionsTypes.RFZO_THERAPY_EXPIRE_START
    }
}

export const rfzoTherapyExpireFailed = () => {
    return {
        type: actionsTypes.RFZO_THERAPY_EXPIRE_FAILED
    }
}

export const rfzoTherapyExpireConfirm = (rfzoTherapyId) => {
    return dispatch => {
        dispatch(rfzoTherapyExpireStart());
        const URL = '/rfzo_therapy/expired/' + rfzoTherapyId;
        axios.delete(URL)
            .then(response => {
                console.log(response.data);
                dispatch(rfzoTherapyExpireConfirmed())
            })
            .catch(error => {
                dispatch(rfzoTherapyExpireFailed())
            })

    };
}


//terrapija odbijena od starne apoteke
export const rfzoTherapyCancelConfirmed = () => {
    return {
        type: actionsTypes.RFZO_THERAPY_CANCEL
    };
}

export const rfzoTherapyCancelStart =()=> {
    return {
        type: actionsTypes.RFZO_THERAPY_CANCEL_START
    }
}

export const rfzoTherapyCancelFailed = () => {
    return {
        type: actionsTypes.RFZO_THERAPY_CANCEL_FAILED
    }
}

export const rfzoTherapyCancelConfirm = (rfzoTherapyId) => {
    return dispatch => {
        dispatch(rfzoTherapyCancelStart());
        const URL = '/rfzo_therapy/cancel/' + rfzoTherapyId;
        axios.delete(URL)
            .then(response => {
                console.log(response.data);
                dispatch(rfzoTherapyCancelConfirmed())
            })
            .catch(error => {
                dispatch(rfzoTherapyCancelFailed())
            })

    };
}


// rfzo socket connection
export const RFZOSocketConnectionStatus = (status) => {
    return {
        type: actionsTypes.RFZO_SOCKET_CONNECTION_STATUS,
        RFZOSocketStatus: status
    };
}

export const RFZOSocketConnectionStart =() => {
    return {
        type: actionsTypes.RFZO_SOCKET_CONNECTION_START,
    }
}

// rfzo modal toggle
export const RFZOModalToggle =(rfzoModalToggle)=> {
    return {
        type: actionsTypes.RFZO_MODAL_TOGGLE,
        rfzoModalToggle: rfzoModalToggle
    };
}

//change rfzo request status
export const changeRFZOTherapyStatus = (status) => {
    return {
        type: actionsTypes.RFZO_THERAPY_STATUS_CHANGED,
        rfzoTherapyStatus: status
    };
}

export const setWillBeReady = (willBeReady) => {
    return {
        type: actionsTypes.SET_WILL_BE_READY,
        willBeReady: willBeReady
    };
}

export const setCancelNote = (note) => {
    return {
        type: actionsTypes.SET_CANCEL_NOTE,
        cancelNote: note
    };
}
