import React, {createContext} from 'react'
import {baseUrl} from "../../../axios/axios-apoteka";
import {connect} from "react-redux";
import * as actions from "../../actions";
const OrderSocketContext = createContext("Heloo woorld")

export { OrderSocketContext }

const OrderSocket = (props) => {
    let stompClient = null;
    let connected = false;

    const connect = () => {
        props.onOrderSocketConnectionStart();
        const Stomp = require("stompjs");
        let SockJS = require("sockjs-client");
        SockJS = new SockJS(baseUrl + "/ws-order-communication"/* + "?access_token="+localStorage.getItem('token')*/);
        stompClient = Stomp.over(SockJS);
        stompClient.connect({}, afterConnect, onConnectionClosed);
    };

    const closeConnection = () => {
        if (stompClient) {
            stompClient.disconnect();
            props.onOrderSocketConnected(null);

        }
        connected = false;
    }

    const onConnectionClosed = () => {
        console.log("Konekcija ws-a zatvorena!")
        props.onOrderSocketConnected(false);
        connected = false;
    }

    const tickleConnection = () => {
        connected ? closeConnection() : connect();
    }

    const afterConnect = () => {
        console.log("Uspesno uspostavljena konekcija")
        connected = true;
        props.onOrderSocketConnected(true);
        stompClient.subscribe(
            "/user/" + localStorage.getItem('email') + "/queue/messages",
            onMessageReceived
        );
    };

    const onError = (err) => {
        // connected = false;
        // props.onOrderSocketConnected(false);
        console.log(err);
    };

    const isConnected = () => {
        return connected
    }

    const onMessageReceived = (msg) => {

        const message = JSON.parse(msg.body);
        // const active = JSON.parse(sessionStorage.getItem("recoil-persist"))
        //     .chatActiveContact;

        switch (message.type) {
            case 'ORDER_ACK':
                props.onOrderChangedStatus(message)
                console.log(message);
                break;
            case 'ORDER_REJECTED':
                props.onOrderChangedStatus(message)
                console.log("Order is denied")
                console.log(message);
                break;
            case 'ORDER_CANCELED':
                props.onOrderChangedStatus(message)
                console.log("Order is canceled")
                console.log(message);
                break;
            case 'ORDER_CHANGE_STATUS':
                props.onOrderChangedStatus(message)
                console.log("Order status has canceled")
                console.log(message);
                break;
            case 'ORDER_TAKEN':
                props.onOrderChangedStatus(message)
                console.log("Order status has canceled")
                console.log(message);
                break;
        }
    };

    const sendMessage = (msg) => {
        stompClient.send("/apoteka-rs-socket/order", {}, JSON.stringify(msg));
    };

    const toReturn = {
        connect,
        isConnected,
        sendMessage,
        closeConnection,
        tickleConnection
    }

    return (
        <OrderSocketContext.Provider value={toReturn}>
            {props.children}
        </OrderSocketContext.Provider>
    )
}
const mapDispatchToProps = (dispatch) => {
    return {
        onOrderSocketConnected: (connected) => dispatch(actions.orderSocketConnectionChanged(connected)),
        onOrderSocketConnectionStart: () => dispatch(actions.orderSocketConnectionStart()),
        onOrderChangedStatus: (orderInfo) => dispatch(actions.orderChangedStatus(orderInfo))
    }
}
export default connect(null, mapDispatchToProps)(OrderSocket)
