import * as actionsTypes from '../actions/actionsTypes'

const initialState = {
    singleShopArticles: null,
    totalSingleShopArticleElements: -1,
    totalSingleShopArticlePages: -1,
    loadingSingleShopArticles: false,
    singleShopArticlesFailed: false,

    namesOfSingleShopArticles: null,
    loadingNamesOfSingleShopArticles: false,
    namesOfsingleShopArticlesFailed: false,

    selectedArticles: null,
    totalSelectedElements: -1,
    totalSelectedPages: -1,
    selectedArticlesFailed: false,
    loadingSelectedArticles: false,

    shoppingCart: null,
    totalShoppingCartPrice: 0,
    totalShoppingCartLoyalityPoints: 0,
    shoppingCartFailed: false,
    loadingShoppingCart: false,
    clearShoppingCartFailed: false,
    clearingShoppingCart: false,

    addItemToShoppingCartFailed: false,
    loadingActionAddItemToShoppingCart: false,

    loadingChangeQuantityOfShoppingCartItem: false,
    changeQuantityOfShoppingCartItemFailed: false,

    removeItemFromShoppingCartFailed: false,
    loadingActionRemoveItemFromShoppingCart: false,

    allShoppingCarts: null,
    loadingAllShoppingCarts: false,
    allShoppingCartsFailed: false,

    initAddToShoppingCartAnimation: false,

    uploadPrescriptionsFailed: false,
    loadingPrescriptionsUpload: false,

    deletePrescriptionFailed: false,
    loadingDeletePrescription: false,
    deleteAllPrescriptionsFailed: false,
    loadingDeleteAllPrescriptions: false,

    numberOfItemsInShoppingCart: 0,

    sortValue: '-1'
}

const shopPageReducer = (state = initialState, action) => {
    switch (action.type) {

        case actionsTypes.ALL_SINGLE_STORE_ARTICLES:
            return {
                ...state,
                singleShopArticles: action.singleShopArticles.list,
                totalSingleShopArticleElements: action.singleShopArticles.totalElements,
                totalSingleShopArticlePages: action.singleShopArticles.totalPages,
                loadingSingleShopArticles: false,
                singleShopArticlesFailed: false
            }
        case actionsTypes.SLICE_OF_SINGLE_STORE_ARTICLES:
            return {
                ...state,
                singleShopArticles: action.singleShopArticles,
                loadingSingleShopArticles: false,
                singleShopArticlesFailed: false
            }
        case actionsTypes.FETCH_SINGLE_STORE_ARTICLES_FAILED:
            return {
                ...state,
                singleShopArticles: null,
                loadingSingleShopArticles: false,
                singleShopArticlesFailed: true
            }
        case actionsTypes.FETCH_SINGLE_STORE_ARTICLES_START:
            return {
                ...state,
                singleShopArticles: null,
                loadingSingleShopArticles: true,
                singleShopArticlesFailed: false
            }

        //reducer for fetching articles using articles ids for shop
        case actionsTypes.ALL_STORE_ARTICLES_FOR_ARTICLES_IDS:
            return {
                ...state,
                selectedArticles: action.articlesForArticlesIds.list,
                totalSelectedElements: action.articlesForArticlesIds.totalElements,
                totalSelectedPages: action.articlesForArticlesIds.totalPages,
                loadingSelectedArticles: false,
                selectedArticlesFailed: false
            }
        case actionsTypes.SLICE_OF_STORE_ARTICLES_FOR_ARTICLES_IDS:
            return {
                ...state,
                selectedArticles: action.articlesForArticlesIds,
                loadingSelectedArticles: false,
                selectedArticlesFailed: false
            }
        case actionsTypes.FETCH_STORE_ARTICLES_FOR_ARTICLES_IDS_FAILED:
            return {
                ...state,
                selectedArticles: null,
                loadingSelectedArticles: false,
                selectedArticlesFailed: true
            }
        case actionsTypes.FETCH_STORE_ARTICLES_FOR_ARTICLES_IDS_START:
            return {
                ...state,
                selectedArticles: null,
                loadingSelectedArticles: true,
                selectedArticlesFailed: false
            }


        case actionsTypes.NAME_OF_ALL_SINGLE_STORE_ARTICLES:
            return {
                ...state,
                namesOfSingleShopArticles: action.namesOfSingleShopArticles,
                loadingNamesOfSingleShopArticles: false,
                namesOfsingleShopArticlesFailed: false
            }
        case actionsTypes.FETCH_NAME_OF_ALL_SINGLE_STORE_ARTICLES_FAILED:
            return {
                ...state,
                namesOfSingleShopArticles: null,
                loadingNamesOfSingleShopArticles: false,
                namesOfsingleShopArticlesFailed: true
            }
        case actionsTypes.FETCH_NAME_OF_ALL_SINGLE_STORE_ARTICLES_START:
            return {
                ...state,
                loadingNamesOfSingleShopArticles: true,
                namesOfsingleShopArticlesFailed: false
            }
        case actionsTypes.FETCH_SELECTED_ARTICLE:
            return {
                ...state,
                selectedArticles: action.selectedArticle,
                loadingSelectedArticles: false,
                selectedArticlesFailed: false
            }
        case actionsTypes.DESELECT_SELECTED_ARTICLE:
            return {
                ...state,
                selectedArticles: null,
                loadingSelectedArticles: false,
                selectedArticlesFailed: false
            }
        case actionsTypes.FETCH_SELECTED_ARTICLE_FAILED:
            return {
                ...state,
                selectedArticles: null,
                loadingSelectedArticles: false,
                selectedArticlesFailed: true
            }
        case actionsTypes.FETCH_SELECTED_ARTICLE_START:
            return {
                ...state,
                loadingSelectedArticles: true,
                selectedArticlesFailed: false
            }
        case actionsTypes.FETCH_SHOPPING_CART:
            return {
                ...state,
                shoppingCart: action.shoppingCart,
                totalShoppingCartPrice: (action.shoppingCart !== null && action.shoppingCart !== false) ? action.shoppingCart.totalPrice : 0,
                totalShoppingCartLoyalityPoints: (action.shoppingCart !== null && action.shoppingCart !== false) ? action.shoppingCart.totalLoyalityPoints : 0,
                loadingShoppingCart: false,
                shoppingCartFailed: false,
                numberOfItemsInShoppingCart: (action.shoppingCart === null || action.shoppingCart === false) && 0
            }
        case actionsTypes.FETCH_SHOPPING_CART_FAILED:
            return {
                ...state,
                shoppingCart: {
                    userResponse: null,
                    marketplaceResponse: null,
                    items: []
                },
                loadingShoppingCart: false,
                totalShoppingCartPrice: 0,
                totalShoppingCartLoyalityPoints: 0,
                shoppingCartFailed: true
            }
        case actionsTypes.FETCH_SHOPPING_CART_START:
            return {
                ...state,
                loadingShoppingCart: true,
                shoppingCartFailed: false,
                totalShoppingCartPrice: 0,
                totalShoppingCartLoyalityPoints: 0
            }
        case actionsTypes.CLEAR_SHOPPING_CART:
            return {
                ...state,
                shoppingCart: {
                    ...state.shoppingCart,
                    items: [],
                    prescriptionsLinks: []
                },
                numberOfItemsInShoppingCart: 0,
                loadingShoppingCart: false,
                shoppingCartFailed: false,
                clearShoppingCartFailed: false,
                clearingShoppingCart: false,
                totalShoppingCartPrice: 0,
                totalShoppingCartLoyalityPoints: 0
            }
        case actionsTypes.CLEAR_SHOPPING_CART_FAILED:
            return {
                ...state,
                loadingShoppingCart: false,
                shoppingCartFailed: false,
                clearShoppingCartFailed: true,
                clearingShoppingCart: false
            }
        case actionsTypes.CLEAR_SHOPPING_CART_START:
            return {
                ...state,
                clearShoppingCartFailed: false,
                clearingShoppingCart: true
            }
        case actionsTypes.ADD_ITEM_TO_SHOPPING_CART:
            let newItems = [...state.shoppingCart.items];
            let exist = false;
            newItems = newItems.map((item) => {
                if (item.itemResponse.id === action.item.shoppingCartResponse.itemResponse.id){
                    exist = true;
                    return action.item.shoppingCartResponse;
                }
                else {
                    return item;
                }
            });

            if (!exist) {
                newItems.push(action.item.shoppingCartResponse);
            }
            return {
                ...state,
                shoppingCart: {
                    ...state.shoppingCart,
                    items: newItems,
                    totalPrice: action.item.totalShoppingCartPrice,
                    totalLoyalityPoints: action.item.totalShoppingCartLoyalityPoints
                },
                loadingActionAddItemToShoppingCart: false,
                singleShopArticlesFailed: false,
                numberOfItemsInShoppingCart: newItems.length,
                totalShoppingCartPrice: action.item.totalShoppingCartPrice,
                totalShoppingCartLoyalityPoints: action.item.totalShoppingCartLoyalityPoints
            }
        case actionsTypes.ADD_ITEM_TO_SHOPPING_CART_FAILED:
            return {
                ...state,
                loadingActionAddItemToShoppingCart: false,
                addItemToShoppingCartFailed: true
            }
        case actionsTypes.ADD_ITEM_TO_SHOPPING_CART_START:
            return {
                ...state,
                loadingActionAddItemToShoppingCart: true,
                addItemToShoppingCartFailed: false
            }
        case actionsTypes.REMOVE_ITEM_FROM_SHOPPING_CART:
            let newItems1 = [...state.shoppingCart.items];
            newItems1 = newItems1.filter((item) => {
                if (!(item.itemResponse.id === action.item.itemResponse.id)){
                    return item
                }
            });
            return {
                ...state,
                shoppingCart: {
                    ...state.shoppingCart,
                    items: newItems1,
                    totalPrice: action.totalPrice
                },
                removeItemFromShoppingCartFailed: false,
                loadingActionRemoveItemFromShoppingCart: false,
                numberOfItemsInShoppingCart: newItems1.length,
                totalShoppingCartPrice: action.total.totalShoppingCartPrice,
                totalShoppingCartLoyalityPoints: action.total.totalShoppingCartLoyalityPoints
            }
        case actionsTypes.REMOVE_ITEM_FROM_SHOPPING_CART_FAILED:
            return {
                ...state,
                removeItemFromShoppingCartFailed: true,
                loadingActionRemoveItemFromShoppingCart: false
            }
        case actionsTypes.REMOVE_ITEM_FROM_SHOPPING_CART_START:
            return {
                ...state,
                removeItemFromShoppingCartFailed: false,
                loadingActionRemoveItemFromShoppingCart: true
            }
        case actionsTypes.CHANGE_QUANTITY_OF_SHOPPING_CART_ITEM:
            let newItems2 = [...state.shoppingCart.items];
            newItems2 = newItems2.map((item) => {
                if (item.itemResponse.id === action.item.shoppingCartResponse.itemResponse.id){
                    return action.item.shoppingCartResponse;
                }
                else {
                    return item;
                }
            });

            return {
                ...state,
                shoppingCart: {
                    ...state.shoppingCart,
                    items: newItems2,
                    totalPrice: action.item.totalShoppingCartPrice,
                    totalLoyalityPoints: action.item.totalShoppingCartLoyalityPoints
                },
                loadingChangeQuantityOfShoppingCartItem: false,
                changeQuantityOfShoppingCartItemFailed: false,
                numberOfItemsInShoppingCart: newItems2.length,
                totalShoppingCartPrice: action.item.totalShoppingCartPrice,
                totalShoppingCartLoyalityPoints: action.item.totalShoppingCartLoyalityPoints
            }
        case actionsTypes.CHANGE_QUANTITY_OF_SHOPPING_CART_ITEM_FAILED:
            return {
                ...state,
                loadingChangeQuantityOfShoppingCartItem: false,
                changeQuantityOfShoppingCartItemFailed: true
            }
        case actionsTypes.CHANGE_QUANTITY_OF_SHOPPING_CART_ITEM_START:
            return {
                ...state,
                loadingChangeQuantityOfShoppingCartItem: true,
                changeQuantityOfShoppingCartItemFailed: false
            }
        case actionsTypes.FETCH_ALL_SHOPPING_CARTS:
            const shoppingCart = action.allShoppingCarts !== null && action.allShoppingCarts !== [] ? action.allShoppingCarts[0] : {
            userResponse: null,
                marketplaceResponse: null,
                items: [],
                prescriptionsLinks: []
        };
            return {
                ...state,
                shoppingCart: shoppingCart,
                totalShoppingCartPrice: shoppingCart.totalPrice,
                totalShoppingCartLoyalityPoints: shoppingCart.totalLoyalityPoints,
                allShoppingCarts: action.allShoppingCarts,
                loadingAllShoppingCarts: false,
                allShoppingCartsFailed: false
            }
        case actionsTypes.FETCH_ALL_SHOPPING_CARTS_FAILED:
            return {
                ...state,
                allShoppingCarts: false,
                loadingAllShoppingCarts: false,
                allShoppingCartsFailed: true
            }
        case actionsTypes.FETCH_ALL_SHOPPING_CARTS_START:
            return {
                ...state,
                loadingAllShoppingCarts: true,
                allShoppingCartsFailed: false
            }

        case actionsTypes.INITIATE_ADD_TO_SHOPPING_CART_ANIMATION:
            return {
                ...state,
                initAddToShoppingCartAnimation: action.initAnimationValue
            }


        case actionsTypes.UPLOAD_PRESCRIPTIONS_FINISHED:
            return {
                ...state,
                shoppingCart: {
                    ...state.shoppingCart,
                    prescriptionsLinks: [...action.prescriptionsLinks]
                },
                uploadPrescriptionsFailed: false,
                loadingPrescriptionsUpload: false
            }
        case actionsTypes.UPLOAD_PRESCRIPTIONS_FAILED:
            return {
                ...state,
                uploadPrescriptionsFailed: true,
                loadingPrescriptionsUpload: false
            }
        case actionsTypes.UPLOAD_PRESCRIPTIONS_START:
            return {
                ...state,
                uploadPrescriptionsFailed: false,
                loadingPrescriptionsUpload: true
            }


        case actionsTypes.DELETE_PRESCRIPTION:
            return {
                ...state,
                shoppingCart: {
                    ...state.shoppingCart,
                    prescriptionsLinks: state.shoppingCart.prescriptionsLinks.filter(prescriptionLink => {
                        if (prescriptionLink.id !== +action.prescriptionId) {
                            return prescriptionLink;
                        }
                    })
                },
                deletePrescriptionFailed: false,
                loadingDeletePrescription: false
            }
        case actionsTypes.DELETE_PRESCRIPTION_FAILED:
            return {
                ...state,
                deletePrescriptionFailed: true,
                loadingDeletePrescription: false
            }
        case actionsTypes.DELETE_PRESCRIPTION_START:
            return {
                ...state,
                deletePrescriptionFailed: false,
                loadingDeletePrescription: true
            }


        case actionsTypes.DELETE_ALL_PRESCRIPTIONS:
            return {
                ...state,
                shoppingCart: {
                    ...state.shoppingCart,
                    prescriptionsLinks: []
                },
                deleteAllPrescriptionsFailed: false,
                loadingDeleteAllPrescriptions: false
            }
        case actionsTypes.DELETE_ALL_PRESCRIPTIONS_FAILED:
            return {
                ...state,
                deleteAllPrescriptionsFailed: true,
                loadingDeleteAllPrescriptions: false
            }
        case actionsTypes.DELETE_ALL_PRESCRIPTIONS_START:
            return {
                ...state,
                deleteAllPrescriptionsFailed: false,
                loadingDeleteAllPrescriptions: true
            }
        case actionsTypes.NUMBER_OF_ITEMS_IN_SHOPPING_CART:
            return {
                ...state,
                numberOfItemsInShoppingCart: action.numberOfItemsInShoppingCart
            }
        case actionsTypes.SORT_SHOP_ARTICLES:
            return {
                ...state,
                sortValue: action.sortValue
            }
        default:
            return state;
    }
}

export default shopPageReducer;
