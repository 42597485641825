import React, {Fragment} from "react";
import Modal from "../Modal/Modal";
import Media from "react-media";

const FilterModal = (props) => {
    return (
        <>
            <Media
                queries={{
                    small: "(max-width: 699px)",
                    large: "(min-width: 700px)"
                }}>
                {matches => (
                    <Fragment>
                        {matches.small &&
                            <div className={props.className}>
                                <Modal style={{padding: "0", position: "fixed", bottom:"0", left: "0"}}
                                       showBackdrop={props.show}
                                       backdropClickHandler={props.backdropClickHandler}
                                >
                                    <div className={props.classNameContent}>
                                        {props.children}
                                    </div>
                                </Modal>
                            </div>
                        }

                        {matches.large &&
                            <div className={props.className}>
                                <Modal style={{padding: "0", position: "absolute", top: "62px", left: "30px"}}
                                       show={props.show}
                                       backdropClickHandler={props.backdropClickHandler}>
                                    <div className={props.classNameContent}>
                                        {props.children}
                                    </div>
                                </Modal>
                            </div>
                        }
                    </Fragment>
                )}
            </Media>
        </>
    );
}

export default FilterModal;