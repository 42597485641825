import React, {useEffect, useState} from 'react'
import styles from './../AddressSelection.module.css'
import Button from "../../../../UI/Buttons/Button/Button";
import * as actions from "../../../../../store/actions";
import {connect} from "react-redux";
import {withRouter} from 'react-router-dom';
import AddAddressForm from "../../../../UI/AddAddressForm/AddAddressForm";
import Backdrop from "../../../../UI/Backdrop/Backdrop";
import {PlusCircleIcon} from "@heroicons/react/solid";
import ModalWithHeader from "../../../../UI/Modals/ModalWithHeader/ModalWithHeader";
import OrderCompletionPageTemplateMobile
    from "../../OrderCompletionPageTemplateMobile/OrderCompletionPageTemplateMobile";
import HeaderWithTitleAndBackChevron
    from "../../../../../componentsRes/HeaderWithTitleAndBackChevron/HeaderWithTitleAndBackChevron";


const AddressSelectionMobile = (props) => {

    const [addressSelected, addressSelectedHandler] = useState(() => {
        const selectedTemp = localStorage.getItem('addressSelected');
        const initialValue = JSON.parse(selectedTemp);
        return initialValue || false
    });

    const [selectedAddressId, selectedAddressIdHandler] = useState(localStorage.getItem('selectedAddress') || '');

    const [isModalVisible, isModalVisibleHandler] = useState(false);

    const [userAddresses, userAddressesSetter] = useState();

    useEffect(() => {
        // selectedAddressIdHandler(localStorage.getItem('selectedAddress'));
        // addressSelectedHandler(() => {
        //     const selectedTemp = localStorage.getItem('addressSelected');
        //     const initialValue = JSON.parse(selectedTemp);
        //     return initialValue || false;
        // })

        return () => {
            localStorage.removeItem('saveNameToProfile');
        }
    }, [])

    // useEffect(() => {
    //     if (window.pageYOffset !== 0) {
    //         window.scrollTo(0, 0);
    //     }
    // }, [])

    useEffect(() => {
        props.collectOrDelivery.delivery && localStorage.setItem('addressSelected', JSON.stringify(addressSelected));
    }, [addressSelected])

    useEffect(() => {
        if (props.userProfile === null) {
            props.onFetchUserProfile(localStorage.getItem('email'));
        }

        if(props.collectOrDelivery.collect === null && props.collectOrDelivery.delivery === null) {
            if(localStorage.getItem('deliveryMethod') === 'deliveryToAddress') {
                props.onCollectOrDeliveryChoice({collect: false, delivery: true})
            } else {
                props.onCollectOrDeliveryChoice({collect: true, delivery: false})
            }
        }
    }, [])

    useEffect(() => {
        // if (props.userProfile !== null && props.userProfile.userAddresses.length === 1) {
        //     chooseAddressMobile(props.userProfile.userAddresses[0].id)
        // }

        if (props.userProfile && props.userProfile.phoneNumber !== null && !localStorage.getItem('phoneNumber')) {
            localStorage.setItem('phoneNumber', props.userProfile.phoneNumber)
        }
    }, [props.userProfile])

    const chooseAddressMobile = (addressId) => {
        addressSelectedHandler(true);
        localStorage.setItem('selectedAddress', addressId);
        selectedAddressIdHandler(addressId);
        redirectToSummary();
    }

    useEffect(() => {
        if (props.userProfile !== null) {
            userAddressesSetter(props.userProfile.userAddresses.map((address) => {
                return (
                    <div key={address.id}
                         className={styles.AddressesList}
                         onClick={() => {
                             chooseAddressMobile(address.id)
                         }}
                    >

                        <div
                            className={!addressSelected ? styles.AddressesListRadioButtonWrapper : (+selectedAddressId === address.id ? [styles.AddressesListRadioButtonWrapper, styles.AddressesListRadioButtonWrapperSelected].join(' ') : styles.AddressesListRadioButtonWrapper)}>
                            <div
                                className={!addressSelected ? styles.AddressesListRadioButton : (+selectedAddressId === address.id ? [styles.AddressesListRadioButton, styles.AddressesListRadioButtonSelected].join(' ') : styles.AddressesListRadioButton)}></div>
                        </div>
                        <div>{address.name} {address.number} </div>
                        <div>{address.apartment !== "null" && address.apartment !== null ? 'stan: ' + address.apartment : null} {address.floor && address.floor !== 'null' ? 'sprat: ' + address.floor : null}</div>
                        <div>{address.postalCode} {address.region}, {address.city}</div>
                    </div>
                )
            }))
        }
    }, [props.userProfile])

    useEffect(()=>{
        if (props.userProfile && props.userProfile.userAddresses.length === 0) {
            userAddressesSetter(
                <>
                    <p style={{color: "#535358"}}>Nema sačuvanih adresa.</p>
                    <p style={{color: "#535358"}}>Da bismo mogli da Vam pošaljemo proizvode, morate uneti adresu
                        klikom
                        na dugme <strong>Dodaj novu adresu</strong></p>
                </>
            )
        }
    }, [])

    const openModal = () => {
        isModalVisibleHandler(true);
    }

    const closeModal = () => {
        isModalVisibleHandler(false);
    }

    //snimanje nove adrese
    const saveAddress = (formData) => {
        closeModal();
        const userRequest = {
            username: localStorage.getItem("email"),
            name: formData.addressData.streetName,
            number: formData.addressData.number,
            region: formData.addressData.region,
            city: formData.addressData.city,
            postalCode: formData.addressData.postalCode,
            floor: formData.addressData.floor === '' ? null : formData.addressData.floor,
            apartment: formData.addressData.apartment === '' ? null : formData.addressData.apartment
        }
        props.onAddNewUserAddress(userRequest);
    }

    const redirectToSummary = () => {

        // TODO srediti da nema timeout-a
        setTimeout(() => {
            const branchMarketplaceId = window.location.pathname.split('/')[2];
            const marketplaceId = window.location.pathname.split('/')[3];
            const url = '/orderCompletion/' + branchMarketplaceId + "/" + marketplaceId + '/' + 'confirmation'
                + '/' + props.match.params.branchMarketplaceName + '/' + props.match.params.marketplaceName;
            // props.history.replace('/')
            props.history.push(url);
        }, 500)
    }

    const redirectToPreviousPage = () => {
        const url = '/orderCompletion/' + props.match.params.branchMarketplaceId + "/" + props.match.params.marketplaceId + '/userInfo'
            + '/' + props.match.params.branchMarketplaceName + '/' + props.match.params.marketplaceName;
        props.history.push(url);
    }

    return (

        <OrderCompletionPageTemplateMobile
            header={<HeaderWithTitleAndBackChevron
                icon
                onIconClick={redirectToPreviousPage}
                title="Adresa za isporuku"
            />}
        >
            <>
                {props.collectOrDelivery.delivery &&
                    <div style={{height: "100%", display: "flex", flexDirection: "column"}}>
                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "flex-start",
                            width: "100%",
                            marginTop:"2rem",
                            marginLeft:"1rem"
                        }}>
                            <div style={{fontWeight: "bold"}}>Izaberite adresu za dostavu</div>
                        </div>
                        <br/>
                        <div className={styles.UserAddressesWrapper}>
                            {userAddresses}
                            <div className={styles.AddressesList} style={{padding: "0"}}>
                                <Button style={{width: "100%", height: "100%", justifyContent: "center"}}
                                        onClick={openModal}><PlusCircleIcon
                                    style={{height: "42px", width: "42px", color: "#00B2B1"}}/></Button>
                            </div>
                        </div>


                        {isModalVisible &&
                            <ModalWithHeader onCloseClick={closeModal}
                                             contentStyle={{width: "100%"}}
                            >
                                <AddAddressForm saveAddress={saveAddress}/>
                            </ModalWithHeader>
                        }

                        <Backdrop show={isModalVisible}
                                  backdropClickHandler={closeModal}
                                  zIndex={15}
                                  backgroundColor="rgba(0, 0, 0, .2)"/>
                    </div>
                }
            </>
        </OrderCompletionPageTemplateMobile>
    );
}

const mapStateToProps = (state) => {
    return {
        userProfile: state.userProfile.userProfile,
        userProfileError: state.userProfile.userProfileError,
        loadingUserProfile: state.userProfile.loadingUserProfile,
        collectOrDelivery: state.orderCompletion.collectOrDelivery,

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onFetchUserProfile: (email) => dispatch(actions.fetchUserProfile(email)),
        onAddNewUserAddress: (userAddress) => dispatch(actions.addNewUserAddress(userAddress)),
        onCollectOrDeliveryChoice: (collectOrDelivery) => dispatch(actions.collectOrDeliveryChoice(collectOrDelivery)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AddressSelectionMobile))