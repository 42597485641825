import React, {useRef, useEffect} from 'react';
import styles from '../../SearchItems/SearchItem/SearchItem.module.css'
import {connect} from 'react-redux';
import * as actions from "../../../../../store/actions/index";
import { withRouter} from 'react-router-dom'
import {textToUrl} from "../../../../GobalFunctions/GlobalFunctions";

const SearchStore = React.memo(props => {

    const fieldRef = useRef(null);


    // Detektuje prmenu u selekciji (key up i key down) i pomera scroll ako se taj item ne vidi
    useEffect(() => {

        if (props.selectedIndex === 0 && props.index === 1) {
            fieldRef.current.scrollIntoView({
                behavior: "auto",
                block: 'nearest',
                inline: 'start'
            });
        }

        if (props.selectedIndex === props.index ) {
            fieldRef.current.scrollIntoView({
                behavior: "smooth",
                block: 'nearest',
                inline: 'start'
            });
        }
    }, [props.selectedIndex])

    useEffect(() => {

        //Check if we select article by pressing ENTER
        if (props.isSelected && props.selectedIndex === props.index){
            let url;

            if (props.location.hash !== '') {
                url = '/shop/' + props.store.idBranchMarketplace + '/' + props.store.idMarketplace + '/0' +'#' + props.location.hash.substring(1);
            }
            else {
                if(props.store.isClickCollect === 1) {
                    localStorage.setItem('beforeEnteringStore', window.location.pathname)
                    url = '/shop/' + props.store.idBranchMarketplace + '/' + props.store.idMarketplace + '/0' + '/' + textToUrl(props.store.branchMarketplaceName) + '/' + textToUrl(props.store.name);
                } else {
                    localStorage.setItem('beforeEnteringStorePage', window.location.pathname)
                    url = '/storeinfo/' + props.store.idBranchMarketplace + '/' + props.store.idMarketplace + '/' + textToUrl(props.store.branchMarketplaceName) + '/' + textToUrl(props.store.name);
                }
            }

            props.history.push(url)
            props.onSelectDetected(false)
        }
    }, [props.isSelected])

    //Check if we select article by click
    const selectStoreHandler = () => {
        let url1;
        if (props.location.hash !== '') {
            url1 = '/shop/' + props.store.idBranchMarketplace + '/' + props.store.idMarketplace + '/0' +'#' + props.location.hash.substring(1);
        }
        else {
            // ako je apoteka u CNC sistemu, ulazi se u shop, ako nije, ulazi se na profilnu stranu
            if(props.store.isClickCollect === 1) {
                localStorage.setItem('beforeEnteringStore', window.location.pathname)
                url1 = '/shop/' + props.store.idBranchMarketplace + '/' + props.store.idMarketplace + '/0' + '/' + textToUrl(props.store.branchMarketplaceName) + '/' + textToUrl(props.store.name);
            } else {
                localStorage.setItem('beforeEnteringStorePage', window.location.pathname)
                url1 = '/storeinfo/' + props.store.idBranchMarketplace + '/' + props.store.idMarketplace + '/' + textToUrl(props.store.branchMarketplaceName) + '/' + textToUrl(props.store.name);
            }
        }

        props.history.push(url1);

    }

    return (
        <div className={props.onMainPage ? styles.SearchItemMainPage : [styles.SearchItemMainPage, styles.SearchItemAppPage].join(' ')} style={{width:"115%"}}>
            <a className={props.selectedIndex === props.index ? styles.Selected : null} onClick={selectStoreHandler} ref={fieldRef}>
                <span><div dangerouslySetInnerHTML={{__html: props.store.nameHighlighted }}></div> {props.store.address} , {props.store.location}</span>
            </a>

        </div>
    );
})

const mapStateToProps = (state) => {
    return {
        selectedIndex: state.mainPage.selectedIndex,
        isSelected: state.mainPage.isSelected,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onSelectDetected: (isSelected) => dispatch(actions.selectDetected(isSelected)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SearchStore));
