import React, {useState} from 'react';
import styles from './InformationBox.module.css';
import {ClockIcon, LocationMarkerIcon, MailIcon, PhoneIcon, PlusIcon} from "@heroicons/react/outline";
import {Link} from "react-router-dom";

const InformationBox = (props) => {

    //todo promeniti u liniji ispod, na kraju da bude shortDecription kada bude uradjen taj deo za branchmarketplace
    let longPharmacyDescriptionText = props.selectedStore && (props.selectedStore.marketplaceResponse.shortDescription ? props.selectedStore.marketplaceResponse.shortDescription : props.selectedStore.branchMarketplaceResponse.description);
    let shortPharmacyDescriptionText = longPharmacyDescriptionText && longPharmacyDescriptionText.substring(0, 49);

    const [shortText, shortTextSetter] = useState(true);

    const showMoreText = () => {
        shortTextSetter(!shortText);
    }

    let pharmacyPhone = props.selectedStore && props.selectedStore.marketplaceResponse.phoneNumber;
    pharmacyPhone = pharmacyPhone && pharmacyPhone.split(",").join(", ");

    let currentDayNumber = new Date().getDay();

    const redirectToGoogleMap = (latitude, longitude) => {
        window.open("http://maps.google.com/maps?z=18&t=m&q=loc:" + latitude+"++"+longitude, "_blank");
    }

    return(
        <div className={styles.InformationBox}>


            <div className={styles.InfoTitle}><strong>Info</strong></div>

            {shortPharmacyDescriptionText &&
            <div className={styles.LeftBoxItemWrapper}>
                <div style={{width:"24px", marginRight:"27px"}}>
                    <PlusIcon className={styles.IconStyle} />
                </div>

                {props.selectedStore && longPharmacyDescriptionText && longPharmacyDescriptionText.length < 50 ?
                    <div className={styles.PharmacyInfo}>
                        {shortPharmacyDescriptionText}
                    </div>
                        :
                    (shortText ?
                            <div className={styles.PharmacyInfo}>
                                {shortPharmacyDescriptionText}...<span
                                style={{textDecoration: "underline", color: "#219EBC", cursor: "pointer"}}
                                onClick={showMoreText}> Detaljnije</span>
                            </div>
                            :
                            <div className={styles.PharmacyInfo}>
                                {longPharmacyDescriptionText} <span
                                style={{textDecoration: "underline", color: "#219EBC", cursor: "pointer"}}
                                onClick={showMoreText}> Manje detalja</span>
                            </div>
                    )
                }
            </div> }

            <div className={styles.LeftBoxItemWrapper}>
                <div style={{width:"24px", marginRight:"27px"}}>
                    <LocationMarkerIcon className={styles.IconStyle}/>
                </div>
                <div className={styles.PharmacyAddress} onClick={() => redirectToGoogleMap(props.selectedStore.marketplaceResponse.latitude, props.selectedStore.marketplaceResponse.longitude)}> {props.selectedStore && props.selectedStore.marketplaceResponse.location}, {props.selectedStore && props.selectedStore.marketplaceResponse.address}</div>
            </div>


            <div className={styles.LeftBoxItemWrapper}>
                <div style={{width:"24px", marginRight:"27px"}}>
                    <PhoneIcon className={styles.IconStyle}/>
                </div>
                <div className={styles.PharmacyPhone}><a href={`tel:${pharmacyPhone}`} style={{color:"#219EBC"}}>{pharmacyPhone}</a></div>
            </div>


            <div className={styles.LeftBoxItemWrapper}>
                <div style={{width:"24px", marginRight:"27px"}}>
                    <ClockIcon className={styles.IconStyle}/>
                </div>
                <div className={styles.PharmacyOpeningHoursWrapper}>
                    <div className={currentDayNumber===1 ? [styles.PharmacyOpeningHoursDays, styles.CurrentDay].join(" ") : styles.PharmacyOpeningHoursDays}>
                        <div className={styles.Day}>Ponedeljak:</div>
                        <div>{props.selectedStore && props.selectedStore.marketplaceResponse.openingHoursResponse.monday}</div>
                    </div>
                    <div className={currentDayNumber===2 ? [styles.PharmacyOpeningHoursDays, styles.CurrentDay].join(" ") : styles.PharmacyOpeningHoursDays}>
                        <div className={styles.Day}>Utorak:</div>
                        <div>{props.selectedStore && props.selectedStore.marketplaceResponse.openingHoursResponse.tuesday}</div>
                    </div>
                    <div className={currentDayNumber===3 ? [styles.PharmacyOpeningHoursDays, styles.CurrentDay].join(" ") : styles.PharmacyOpeningHoursDays}>
                        <div className={styles.Day}>Sreda:</div>
                        <div>{props.selectedStore && props.selectedStore.marketplaceResponse.openingHoursResponse.wednesday}</div>
                    </div>
                    <div className={currentDayNumber===4 ? [styles.PharmacyOpeningHoursDays, styles.CurrentDay].join(" ") : styles.PharmacyOpeningHoursDays}>
                        <div className={styles.Day}>Četvrtak:</div>
                        <div>{props.selectedStore && props.selectedStore.marketplaceResponse.openingHoursResponse.thursday}</div>
                    </div>
                    <div className={currentDayNumber===5 ? [styles.PharmacyOpeningHoursDays, styles.CurrentDay].join(" ") : styles.PharmacyOpeningHoursDays}>
                        <div className={styles.Day}>Petak:</div>
                        <div>{props.selectedStore && props.selectedStore.marketplaceResponse.openingHoursResponse.friday}</div>
                    </div>
                    <div className={currentDayNumber===6 ? [styles.PharmacyOpeningHoursDays, styles.CurrentDay].join(" ") : styles.PharmacyOpeningHoursDays}>
                        <div className={styles.Day}>Subota:</div>
                        <div>{props.selectedStore && props.selectedStore.marketplaceResponse.openingHoursResponse.saturday}</div>
                    </div>
                    <div className={currentDayNumber===0 ? [styles.PharmacyOpeningHoursDays, styles.CurrentDay].join(" ") : styles.PharmacyOpeningHoursDays}>
                        <div className={styles.Day}>Nedelja:</div>
                        <div>{props.selectedStore && props.selectedStore.marketplaceResponse.openingHoursResponse.sunday}</div>
                    </div>
                </div>
            </div>

            {props.selectedStore && props.selectedStore.branchMarketplaceResponse.email &&
                <div className={styles.LeftBoxItemWrapper}>
                    <div style={{width:"24px", marginRight:"27px"}}>
                        <MailIcon className={styles.IconStyle}/>
                    </div>
                    <Link className={styles.PharmacyEmail}
                          onClick={(e) => {
                              window.location = "mailto:" + props.selectedStore.branchMarketplaceResponse.email;
                              e.preventDefault();
                          }}>{props.selectedStore.branchMarketplaceResponse.email}</Link>
                </div>
            }
        </div>
    );
}

export default InformationBox;