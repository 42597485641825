import React from 'react'
import styles from './SquareBadge.module.css'

const SquareBadge = (props) => {

    let squareBadge = [styles.SquareBadge, props.className].join(' ')

    return (
        <div className={squareBadge}
             style={{
                 width: props.size,  //Default value is 26px, add size props in created component if size need to be changed
                 height: props.size,  //Default value is 26px, add size props in created component if size need to be changed
                 backgroundColor: props.backgroundColor,
                 marginLeft: props.marginLeft,
                 marginRight: props.marginRight,
                 border: props.border,
                 cursor: props.pointer && "pointer",
                 position: props.position,
                 borderRadius: props.borderRadius
             }}
             onClick={props.onClick}
        >
            {props.children}
        </div>
    )
}

export default SquareBadge;