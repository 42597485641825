import React, {Fragment} from "react";
import {withRouter} from 'react-router-dom'
import styles from './SearchResults.module.css';
import {connect} from "react-redux";
import StoreItems from "./StoreItems/StoreItems";
import StoreSearchItems from "./StoreSearchItems/StoreSearchItems";
import Media from "react-media";
import Wrapper from "../../UI/Wrapper/Wrapper";
import Background from "../../UI/Background/Background";
import * as actions from "../../../store/actions";

const SearchResults = (props) => {

    return (
        <Wrapper>
            <Background style={{height:"100%", zIndex:"-2"}}/>
            <Media queries={{
                small: "(max-width: 699px)",
                large: "(min-width: 700px)"
            }}>
                {matches => (
                    <Fragment>
                        {matches.small &&
                        <div className={styles.SearchResultsRes}>
                            <div className={styles.SearchResultsWrapperRes}>
                                {window.location.pathname.includes('searched-stores') ? <StoreSearchItems/> :
                                    <StoreItems/>}
                            </div>
                        </div>
                        }
                        {matches.large &&
                        <div className={styles.SearchResults} >
                            <div className={styles.SearchResultsWrapper} >
                                {window.location.pathname.includes('searched-stores') ? <StoreSearchItems/> :
                                    <StoreItems/>}
                            </div>
                        </div>
                        }
                    </Fragment>
                )}
            </Media>
        </Wrapper>
    );
}

const mapStateToProps = (state) => {
    return {
        stores: state.appPage.stores,
        loadingStores: state.appPage.loadingStores,
        showGoogleMap: state.appPage.showGoogleMap,
        toggleShopNotOnlineModal: state.appPage.toggleShopNotOnlineModal,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onToggleShopNotOnlineModal: (state) => dispatch(actions.toggleShopNotOnlineModal(state))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SearchResults));
