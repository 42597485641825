import React from "react";
import styles from './Actions.module.css';

const Actions = (props) => {
    return(
        <div className={styles.Actions} style={props.style}>
            {props.children}
        </div>
    );
}

export default Actions;