import React from "react";
import styles from "./ArticleCategoryRes.module.css"
import {ReactComponent as ChevronRightIcon} from "../../../../assets/icons/chevronRight_icon.svg";

const ArticleCategory = (props) => {

    return (
        <div
            className={!props.flag ? styles.ArticleCategoryRes : [styles.ArticleCategoryRes, styles.ArticleCategorySelected, props.selectedStyle].join(' ')}
            onMouseOver={props.onMouseOver}
            onMouseLeave={props.onMouseLeave}
            onClick={props.onClick}
            style={props.style}
        >
            <span onClick={props.onCategoryNameClick}>{props.articleCategory.name}</span>
            {props.articleCategory.subcategories.length > 0 && <ChevronRightIcon
                style={props.flag ? {rotate: "270deg", stroke: "white"} : {stroke: "#000000", rotate: "90deg",}}/>}
        </div>
    );
}

export default ArticleCategory;
