import React from 'react'
import ModalWithHeader from "../../UI/Modals/ModalWithHeader/ModalWithHeader";
import styles from "../CNCModal/CNCModal.module.css";
import Button from "../../UI/Buttons/Button/Button";

const NoAddressModal = (props) => {

    return (
        <ModalWithHeader
            show={props.show}
            backdropClickHandler={props.backdropClickHandler}
            onCloseClick={props.onCloseClick}
            buttons={
                <div className={styles.BottomActionsWrapper}>
                    <Button onClick={props.cancel}>Odustani</Button>
                    <Button buttontype="blue" onClick={props.openEnterAddressModal}>Unesi adresu</Button>
                </div>
            }
        >
            <p style={{margin: "0", fontSize: "20px", fontWeight: "bold", textAlign: "center"}}>Adresa nije uneta!</p>
            <p style={{textAlign: "center", fontSize: "14px", lineHeight: "1.7"}}>
                Za ovu funkcionalnost moraš uneti adresu.
            </p>
        </ModalWithHeader>
    )
}

export default NoAddressModal;