import React, {useEffect, useState} from 'react';
import styles from './MainPageButtons.module.css';
import MainPageButton from './MainPageButton/MainPageButton'
import {ChevronDownIcon} from "@heroicons/react/outline";


const MainPageButtons = (props) => {

    const [activeButton, setActiveButton] = useState(1);

    const [mainPageButtonsDropdown, mainPageButtonsDropdownHandler] = useState(styles.MainPageButtonsDropdownClosed)

    let itemsButton =
        <div onClick={()=>setActiveButton(1)}>
            <MainPageButton active={activeButton === 1 ? true : null}
                            type={"ITEMS"}
                            changeTypeOfSearch={props.changeTypeOfSearch}
            >
                <div className={styles.MainPageButtonsText}>
                    Proizvodi
                </div>
            </MainPageButton>
        </div>

    let storesButton =
        <div onClick={()=>setActiveButton(2)}>
            <MainPageButton active={activeButton === 2 ? true : null}
                            type={"STORES"}
                            changeTypeOfSearch={props.changeTypeOfSearch}
            >
                <div className={styles.MainPageButtonsText}>
                    Apoteke
                </div>
            </MainPageButton>
        </div>

    const [selectedButton, selectedButtonHandler] = useState(itemsButton)

    const handleDropDownMenu = () => {
        if(mainPageButtonsDropdown === styles.MainPageButtonsDropdownClosed) {
            mainPageButtonsDropdownHandler(styles.MainPageButtonsDropdownOpen)
        } else {
            mainPageButtonsDropdownHandler(styles.MainPageButtonsDropdownClosed)
        }
    }

    useEffect(()=> {
        if (activeButton === 1) {
            selectedButtonHandler(itemsButton)
        }
        if (activeButton === 2) {
            selectedButtonHandler(storesButton);
        }
    }, [activeButton])



    return (

        <div className={styles.MainPageButtonsWrapper} >
            <div className={styles.MainPageButtonsSelectionBox} onClick={handleDropDownMenu}>
                {selectedButton}
                <span><ChevronDownIcon className={styles.DropdownChevron} /></span>
            </div>

            <div className={[styles.MainPageButtonsDropdown, mainPageButtonsDropdown].join(' ')} >
                <div className={styles.MainPageSingleButtonWrapper} onClick={handleDropDownMenu}>
                    <div onClick={()=>setActiveButton(1)} style={{borderBottom:"1px solid lightgray"}}>
                        <MainPageButton active={activeButton === 1 ? true : null}
                                        type={"ITEMS"}
                                        changeTypeOfSearch={props.changeTypeOfSearch}
                                        inDropdown={true}
                        >
                            <div className={styles.MainPageButtonsText}>
                                Proizvodi
                            </div>
                        </MainPageButton>
                    </div>
                </div>
                <div className={styles.MainPageSingleButtonWrapper} onClick={handleDropDownMenu}>
                    <div onClick={()=>setActiveButton(2)}>
                        <MainPageButton active={activeButton === 2 ? true : null}
                                        type={"STORES"}
                                        changeTypeOfSearch={props.changeTypeOfSearch}
                                        inDropdown={true}
                        >
                            <div className={styles.MainPageButtonsText}>
                                Apoteke
                            </div>
                        </MainPageButton>
                    </div>
                </div>
            </div>
        </div>

    )

}


export default MainPageButtons;