import React from "react";
import styles from './ErrorPage.module.css';
import {Link} from 'react-router-dom';
import {ReactComponent as Image404} from "../../assets/404_img.svg";
import Background from "../UI/Background/Background";
import {ChevronRightIcon} from "@heroicons/react/outline";
import Toolbar from "../Navigation/Toolbar/Toolbar";

const ErrorPage = () => {
    return (
        <>
            <Toolbar/>
            <div className={styles.ErrorPage}>
                <Background style={{height: "100%"}}/>
                <div className={styles.Title}>Došlo je do greške, strana nije pronađena</div>
                <Image404 style={{margin:"3rem"}}/>
                <Link to='/' replace style={{display: "flex"}}>
                    <span className={styles.LinkToMain}>Povratak na početnu stranu</span>
                    <ChevronRightIcon style={{width: "18px"}}/>
                </Link>
            </div>
        </>

    );
}

export default ErrorPage;

// Izvinjavamo se, stranica nije pronađena.
//     Link koji ste pratili je neispravan ili je stranica uklonjena.
//
//     Stranica koju tražite trenutno nije dostupna.
//     Dok rešavamo problem možete se vratiti na početnu stranicu ili na meni sa leve strane.
//
//     Ako smatrate da je ovo problem o kojem bi trebalo da nas obavestite, molimo vas da nas kontaktirate na sledećoj adresi:
//
//     prodaja@tehnomanija.rs.