import React from "react";
import styles from './ArticleDetailsHolder.module.css';
import PerfectScrollbar from "react-perfect-scrollbar";

const ArticleDetailsHolder = (props) => {

    const showDetails = () => {
        document.getElementById("articleInfo").scrollIntoView({behavior: "smooth", block: "center"});
    }

    return (
        <div className={styles.ArticleInfoPageArticleTextBox}>
            <p style={{fontSize: "1.5rem", padding: "0 1rem"}}>{props.title}</p>
            <div style={{width:"100%"}}>
                <PerfectScrollbar style={{height: "150px", padding: "0 1rem"}}>
                    {props.details}
                </PerfectScrollbar>
                <div style={{width: "100%", display:"flex", justifyContent:"space-between", alignItems:"flex-end", borderTop: "1px solid #E2EBEC", marginTop: "1rem"}}>
                    <p style={{
                        padding: "0 1rem"
                    }}>Šifra proizvoda: {props.id}</p>
                    <p onClick={showDetails} style={{
                        cursor: "pointer",
                        padding: "0 1rem"
                    }}>
                        + Detaljnije
                    </p>
                </div>
            </div>
        </div>
    );
}

export default ArticleDetailsHolder;