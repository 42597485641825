import React, {useEffect, useState} from "react";
import styles from "./SearchInputRequest.module.css";
import {ReactComponent as NoItemFound} from "../../../../assets/no-article-found.svg";
import {ExclamationCircleIcon} from "@heroicons/react/outline";
import Button from "../../../UI/Buttons/Button/Button";
import SuccessSearchInputModal from "../SuccessSearchInputModal/SuccessSearchInputModal";
import GoToLoginModal from "../GoToLoginModal/GoToLoginModal";
import {withRouter} from "react-router-dom";
import * as actions from "../../../../store/actions";
import {connect} from "react-redux";

const SearchInputRequest = (props) => {

    const [showGoToLoginModal, showGoToLoginModalHandler] = useState(false)
    const [showSuccessSearchInputModal, showSuccessSearchInputModalHandler] = useState(false)
    const [searchInputSent, searchInputSentHandler] = useState(false);

    const createSearchInputRequest = () => {
        if (props.isLoggedIn && localStorage.getItem("id") !== undefined) {
            console.log('input text')
            // console.log(inputText)
            props.onCreateUserSearchInput({inputText: props.inputText, userId: localStorage.getItem("id")})
            searchInputSentHandler(true);
        } else {
            showGoToLoginModalHandler(true)
        }
    }

    useEffect(() => {
        //Kod koji odredjuje placeholder u search baru
        if (props.match.params.searchString !== null)
            props.onSearchBarPlaceholderChanged(props.match.params.searchedString);
    }, [])

    useEffect(() => {
        console.log('PROPS')
        console.log(props)
        if (searchInputSent && !props.createUserSearchInputError && !props.loadingCreateUserSearchInput) {
            showSuccessSearchInputModalHandler(true)
        }
        if (props.createUserSearchInputError) {
            alert('Došlo je do greške, pokušajte ponovo');
        }
    }, [searchInputSent, props.createUserSearchInputError, props.loadingCreateUserSearchInput])

    return (
        <>
            <div className={styles.UnknownItemMessageHolder}>
                <NoItemFound/>
                <div className={styles.UnknownItemMessage}>
                    <ExclamationCircleIcon className={styles.UnknownItemIcon}/>
                        <p style={{fontSize: "1rem"}}>
                            {props.unknownItem
                                ? "Traženi proizvod nije pronađen!"
                                : "Traženi proizvod trenutno nije dostupan ni u jednoj apoteci u sistemu."
                            }
                        </p>
                </div>
                <div style={{padding: "0 12px", display: "flex", flexDirection: "column", alignItems: "center"}}>

                    {props.unknownItem &&
                        <>
                            <p><strong>Proveri da li je unet dobar naziv proizvoda.</strong></p>
                            <p>Ukoliko je uneti naziv ispravan, a bez rezultata pretrage, <strong>tu smo da
                                pomognemo</strong>!
                            </p>
                        </>
                    }

                    <p style={{marginBottom: "0"}}>Ako želiš da se naš farmaceut detaljno raspita o traženom proizvodu i
                        obavesti te o njegovoj
                        dostupnosti, klikni na dugme <strong>"Obaveštenje o raspoloživosti proizvoda"</strong> i očekuj
                        da te kontaktira naš farmaceut!</p>
                </div>

                <div className={styles.AdditionalActionsHolder}>
                    <div className={styles.SupportHolder}>
                        <Button buttontype='blue' style={{width: "100%", height: "100%", justifyContent: "center", padding:"0 8px"}}
                                onClick={createSearchInputRequest}
                        >
                            <p style={{color: "white", fontSize: "16px", fontWeight: "500"}}>
                                Obaveštenje o raspoloživosti proizvoda
                            </p>
                        </Button>
                    </div>
                </div>
            </div>

            {showGoToLoginModal &&
                <GoToLoginModal show={showGoToLoginModal}
                                backdropClickHandler={() => showGoToLoginModalHandler(false)}
                                onCloseClick={() => showGoToLoginModalHandler(false)}
                                cancel={() => showGoToLoginModalHandler(false)}
                />
            }

            {showSuccessSearchInputModal &&
                <SuccessSearchInputModal show={showSuccessSearchInputModal}
                                         backdropClickHandler={() => showSuccessSearchInputModalHandler(false)}
                                         onCloseClick={() => showSuccessSearchInputModalHandler(false)}
                                         cancel={() => showSuccessSearchInputModalHandler(false)}
                />
            }
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        isLoggedIn: state.authPage.token !== null,
        createUserSearchInputError: state.appPage.createUserSearchInputError,
        loadingCreateUserSearchInput: state.appPage.loadingCreateUserSearchInput
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onCreateUserSearchInput: (searchInputRequest) => dispatch(actions.createUserSearchInput(searchInputRequest)),
        onSearchBarPlaceholderChanged: (newString) => dispatch(actions.searchBarPlaceholderChanged(newString)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SearchInputRequest));