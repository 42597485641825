import React, {useState} from "react";
import styles from './LiveChatButton.module.css';
import {ReactComponent as VideoIcon} from "../../../assets/icons/video_icon.svg";
import LiveChatModal from "../Modals/LiveChatModal/LiveChatModal";
import {ReactComponent as ArrowRight} from "./../../../assets/icons/arrow-right.svg";

const LiveChatButton = (props) => {

    const [isChatModalVisible, isChatModalVisibleHandler] = useState(false);

    const checkLogin = () => {
        isChatModalVisibleHandler(true);
    }

    const closeChatModal = () => {
        isChatModalVisibleHandler(false);
    }

    return (
        <div className={!props.noTextStripe && styles.LiveChatButtonContainer} style={props.noTextStripe && {height:"100%"}}>
            <div className={styles.LiveChatButton} style={props.style} onClick={checkLogin}>
                <VideoIcon style={{stroke: "white", width: "45px", height: "45px"}}/>
                {props.children}
            </div>
            {!props.noTextStripe &&
                <div className={styles.TextStripe}>
                    Video-razgovor sa našim farmaceutom &nbsp; &nbsp; <ArrowRight style={{width:"20px", height:"20px"}}/>

                </div>
            }

            {isChatModalVisible &&
                <LiveChatModal closeChatModal={closeChatModal}
                               show={isChatModalVisible}
                               backdropClickHandler={closeChatModal}/>

            }
        </div>
    );
}

export default LiveChatButton;