import * as actionsTypes from "./actionsTypes";
import axios from "../../axios/axios-apoteka";

// actions for store details for article info page
export const detailsForStoreInfoPage = (storeDetails) => {
    return {
        type: actionsTypes.FETCH_DETAILS_FOR_STORE_FOR_INFO_PAGE,
        storeDetails: storeDetails

    };
}

export const fetchDetailsForStoreInfoPageFailed = () => {
    return {
        type: actionsTypes.FETCH_DETAILS_FOR_STORE_FOR_INFO_PAGE_FAILED
    };
}

export const fetchDetailsForStoreInfoPageStart = () => {
    return {
        type: actionsTypes.FETCH_DETAILS_FOR_STORE_FOR_INFO_PAGE_START
    };
}

export const fetchDetailsForStoreInfoPage = (marketplaceId, branchMarketplaceId) => {
    return dispatch => {
        fetchDetailsForStoreInfoPageStart();
        const URL = '/marketplace/' + marketplaceId + "/" + branchMarketplaceId;
        axios.get(URL)
            .then(response => {
                dispatch(detailsForStoreInfoPage(response.data));
            })
            .catch(error => {
                console.log("Error se desio" + error);
                dispatch(fetchDetailsForStoreInfoPageFailed());
            })

    };
}


// actions for store details for article info page
export const articleDetailsForInfoPage = (articleDetails) => {
    return {
        type: actionsTypes.FETCH_ARTICLE_DETAILS_FOR_INFO_PAGE,
        articleDetails: articleDetails

    };
}

export const fetchArticleDetailsForInfoPageFailed = () => {
    return {
        type: actionsTypes.FETCH_ARTICLE_DETAILS_FOR_INFO_PAGE_FAILED
    };
}

export const fetchArticleDetailsForInfoPageStart = () => {
    return {
        type: actionsTypes.FETCH_ARTICLE_DETAILS_FOR_INFO_PAGE_START
    };
}

export const fetchArticleDetailsForInfoPage = (articleId) => {
    return dispatch => {
        fetchArticleDetailsForInfoPageStart();
        const URL = '/item/' + articleId;
        axios.get(URL)
            .then(response => {
                dispatch(articleDetailsForInfoPage(response.data));
                console.log(response.data)
            })
            .catch(error => {
                console.log("Error se desio" + error);
                dispatch(fetchArticleDetailsForInfoPageFailed());
            })

    };
}


export const articleInStore = (articleInStore) => {
    return {
        type: actionsTypes.FETCH_ARTICLE_IN_STORE,
        articleInStore: articleInStore

    };
}

export const fetchArticleInStoreFailed = () => {
    return {
        type: actionsTypes.FETCH_ARTICLE_IN_STORE_FAILED
    };
}

export const fetchArticleInStoreStart = () => {
    return {
        type: actionsTypes.FETCH_ARTICLE_IN_STORE_START
    };
}

export const fetchArticleInStore = (articleId, branchMarketplaceId, marketplaceId) => {
    return dispatch => {
        fetchArticleInStoreStart();
        const URL = '/item_in_marketplace/' + articleId + '/' + branchMarketplaceId  + '/' + marketplaceId;
        axios.get(URL)
            .then(response => {
                dispatch(articleInStore(response.data));
                console.log(response.data)
            })
            .catch(error => {
                console.log("Error se desio" + error);
                dispatch(fetchArticleInStoreFailed());
            })

    };
}



//actions for related articles
export const relatedArticlesForArticle = (relatedArticles) => {
    return {
        type: actionsTypes.FETCH_RELATED_ARTICLES_FOR_ARTICLE,
        relatedArticles: relatedArticles

    };
}

export const fetchRelatedArticlesForArticleFailed = () => {
    return {
        type: actionsTypes.FETCH_RELATED_ARTICLES_FOR_ARTICLE_FAILED
    };
}

export const fetchRelatedArticlesForArticleStart = () => {
    return {
        type: actionsTypes.FETCH_RELATED_ARTICLES_FOR_ARTICLE_START
    };
}

export const fetchRelatedArticlesForArticle = (articleId) => {
    return dispatch => {
        dispatch(fetchRelatedArticlesForArticleStart());
        const URL = '/related_item/ingredient/' + articleId;
        axios.get(URL)
            .then(response => {
                console.log(response.data);
                dispatch(relatedArticlesForArticle(response.data))
            })
            .catch(error => {
                dispatch(fetchRelatedArticlesForArticleFailed())
            })
    };
}


export const relatedArticlesOnTerapijskaIndikacija = (relatedArticles) => {
    return {
        type: actionsTypes.FETCH_RELATED_ARTICLES_ON_TERAPIJSKA_INDIKACIJA,
        relatedArticlesOnTerapijskaIndikacija: relatedArticles

    };
}

export const fetchRelatedArticlesOnTerapijskaIndikacijaFailed = () => {
    return {
        type: actionsTypes.FETCH_RELATED_ARTICLES_ON_TERAPIJSKA_INDIKACIJA_FAILED
    };
}

export const fetchRelatedArticlesOnTerapijskaIndikacijaStart = () => {
    return {
        type: actionsTypes.FETCH_RELATED_ARTICLES_ON_TERAPIJSKA_INDIKACIJA_START
    };
}

export const fetchRelatedArticlesOnTerapijskaIndikacija = (articleId) => {
    return dispatch => {
        dispatch(fetchRelatedArticlesOnTerapijskaIndikacijaStart());
        const URL = '/related_item/therapy_indication/' + articleId;
        axios.get(URL)
            .then(response => {
                console.log(response.data);
                dispatch(relatedArticlesOnTerapijskaIndikacija(response.data))
            })
            .catch(error => {
                dispatch(fetchRelatedArticlesOnTerapijskaIndikacijaFailed())
            })
    };
}

//actions for article info
export const articleInfo = (articleInfo) => {
    return {
        type: actionsTypes.FETCH_ARTICLE_INFO,
        articleInfo: articleInfo

    };
}

export const fetchArticleInfoFailed = () => {
    return {
        type: actionsTypes.FETCH_ARTICLE_INFO_FAILED
    };
}

export const fetchArticleInfoStart = () => {
    return {
        type: actionsTypes.FETCH_ARTICLE_INFO_START
    };
}

export const fetchArticleInfo = (articleJKL) => {
    return dispatch => {
        dispatch(fetchArticleInfoStart());
        const URL = '/lek_info/' + articleJKL;
        axios.get(URL)
            .then(response => {
                console.log(response.data)
                dispatch(articleInfo(response.data))
            })
            .catch(error => {
                console.log("Error se desio" + error);
                dispatch(fetchArticleInfoFailed())
                dispatch(articleInfo(null))
            })
    };
}

//actions for fetch related articles from category
export const categoryRelatedArticles = (categoryRelatedArticles) => {
    return {
        type: actionsTypes.FETCH_CATEGORY_RELATED_ARTICLES,
        categoryRelatedArticles: categoryRelatedArticles

    };
}

export const fetchCategoryRelatedArticlesFailed = () => {
    return {
        type: actionsTypes.FETCH_CATEGORY_RELATED_ARTICLES_FAILED
    };
}

export const fetchCategoryRelatedArticlesStart = () => {
    return {
        type: actionsTypes.FETCH_CATEGORY_RELATED_ARTICLES_START
    };
}

export const fetchCategoryRelatedArticles = (articleId) => {
    return dispatch => {
        dispatch(fetchCategoryRelatedArticlesStart());
        const URL = '/related_item/group/' + articleId;
        axios.get(URL)
            .then(response => {
                console.log('kategorije iz reduxa')
                console.log(response.data)
                dispatch(categoryRelatedArticles(response.data))
            })
            .catch(error => {
                console.log("Error se desio" + error);
                dispatch(fetchCategoryRelatedArticlesFailed())
                dispatch(categoryRelatedArticles(null))
            })
    };
}

//za zadati artikal vraca kategoriju kojoj pripada
export const findCategoryForArticleSuccesss = (categoryForArticle) => {
    return {
        type: actionsTypes.CATEGORY_FOR_ARTICLE,
        categoryForArticle: categoryForArticle
    };
}

export const findCategoryForArticleStart = () => {
    return {
        type: actionsTypes.CATEGORY_FOR_ARTICLE_START
    };
}

export const findCategoryForArticleFailed = () => {
    return {
        type: actionsTypes.CATEGORY_FOR_ARTICLE_FAILED
    };
}

export const findCategoryForArticle = (articleId) => {
    return dispatch => {
        dispatch(findCategoryForArticleStart());
        const URL = '/item/item_groups/' + articleId;
        axios.get(URL)
            .then(response => {
                console.log(response.data)
                dispatch(findCategoryForArticleSuccesss(response.data))
            })
            .catch(error => {
                console.log("Error se desio" + error);
                dispatch(findCategoryForArticleFailed())
                dispatch(findCategoryForArticleSuccesss(null))
            })
    };
}