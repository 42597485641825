import * as actionsTypes from './actionsTypes'
import axios from '../../axios/axios-apoteka'

export const allStoresForArticle = (stores) => {
    return {
        type: actionsTypes.ALL_STORES_FOR_ARTICLE,
        stores: stores

    };
}

export const sliceOfStoresForArticle = (stores) => {
    return {
        type: actionsTypes.SLICE_OF_STORES_FOR_ARTICLE,
        stores: stores

    };
}

export const fetchAllStoresForArticleStart = () => {
    return {
        type: actionsTypes.FETCH_ALL_STORES_FOR_ARTICLE_START
    };
}

export const fetchAllStoresForArticleFailed = () => {
    return {
        type: actionsTypes.FETCH_ALL_STORES_FOR_ARTICLE_FAIlED
    };
}

export const fetchAllStoresForArticleServerError = () => {
    return {
        type: actionsTypes.FETCH_ALL_STORES_FOR_ARTICLE_SERVER_ERROR
    };
}


export const fetchAllStoresForArticleModalStart = () => {
    return {
        type: actionsTypes.FETCH_ALL_STORES_FOR_ARTICLE_MODAL_START
    };
}

export const fetchAllStoresForArticleModalFailed = () => {
    return {
        type: actionsTypes.FETCH_ALL_STORES_FOR_ARTICLE_MODAL_FAIlED
    };
}

export const fetchAllStoresForArticleModalServerError = () => {
    return {
        type: actionsTypes.FETCH_ALL_STORES_FOR_ARTICLE_MODAL_SERVER_ERROR
    };
}


//PRIMER SVI FILTERI: filter=marketplace.location:'Beograd' and marketplace.isClickCollect:1 and marketplace.haveDelivery:1 and marketplace.openingHours.monday:'00:00 do 24:00'
export const fetchAllStoresForArticle = (articleId, page, filter) => {
    return dispatch => {
        dispatch(fetchAllStoresForArticleStart());

        const URL = '/item_in_marketplace/page'
            + '?filter=item.id:' + articleId + filter + '&page=' + page
            +  '&numOfReturns=32';

        axios.get(URL)
            .then(response => {
                dispatch(allStoresForArticle(response.data))
                // console.log(response);
            })
            .catch(error => {
                if (error.response !== null && error.response !== undefined  && error.response.data === "") {
                    dispatch(fetchAllStoresForArticleFailed())
                }
                else {
                    dispatch(fetchAllStoresForArticleServerError());
                }
            })
    };
}

export const fetchSliceOfStoresForArticle = (articleId, page, filter) => {
    return dispatch => {
        dispatch(fetchAllStoresForArticleStart());

        const URL = '/item_in_marketplace/slice'
        + '?filter=item.id:' + articleId + filter + '&page=' + page
        + '&numOfReturns=32';

        axios.get(URL)
            .then(response => {
                dispatch(sliceOfStoresForArticle(response.data))
            })
            .catch(error => {
                if (error.response !== null && error.response !== undefined && error.response.data === "") {
                    dispatch(fetchAllStoresForArticleFailed());
                }
                else {
                    dispatch(fetchAllStoresForArticleServerError());
                }
            })

    };
}

export const detailsForArticle = (articleDetails) => {
    return {
        type: actionsTypes.FETCH_DETAILS_FOR_ARTICLE,
        articleDetails: articleDetails

    };
}

export const fetchDetailsForArticleFailed = () => {
    return {
        type: actionsTypes.FETCH_DETAILS_FOR_ARTICLE_FAILED
    };
}

export const fetchDetailsForArticleStart = () => {
    return {
        type: actionsTypes.FETCH_DETAILS_FOR_ARTICLE_START
    };
}

export const fetchDetailsForArticle = (articleId) => {
    return dispatch => {
        dispatch(fetchDetailsForArticleStart());
        const URL = '/item/' + articleId;
        axios.get(URL)
            .then(response => {
                // console.log(response.data)
                dispatch(detailsForArticle(response.data))
            })
            .catch(error => {
                console.log(error);
                dispatch(fetchDetailsForArticleFailed())
            })
    };
}


export const detailsForStore = (storeDetails) => {
    return {
        type: actionsTypes.FETCH_DETAILS_FOR_STORE,
        storeDetails: storeDetails

    };
}

export const fetchDetailsForStoreFailed = () => {
    return {
        type: actionsTypes.FETCH_DETAILS_FOR_STORE_FAILED
    };
}

export const fetchDetailsForStoreStart = () => {
    return {
        type: actionsTypes.FETCH_DETAILS_FOR_STORE_START
    };
}

export const fetchDetailsForStore = (marketplaceId, branchMarketplaceId) => {
    return dispatch => {
        dispatch(fetchDetailsForStoreStart());
        const URL = '/marketplace/' + marketplaceId + "/" + branchMarketplaceId;
        axios.get(URL)
            .then(response => {
                console.log(response.data)
                dispatch(detailsForStore(response.data))
            })
            .catch(error => {
                console.log("Error se desio" + error);
                dispatch(fetchDetailsForStoreFailed())
            })

    };
}


export const selectedStoreChanged = (selectedStore) => {
    return {
        type: actionsTypes.SELECTED_STORE_CHANGED,
        selectedStore: selectedStore
    };
}

export const loadSelectedStore = (selectedStore) => {
    return {
        type: actionsTypes.LOAD_SELECTED_STORE,
        selectedStore: {
            itemPrice: null,
            itemQuantity: null,
            itemPriceWithDiscount: null,
            discount: null,
            marketplaceResponse: {...selectedStore},
            itemResponse: null,
            branchMarketplaceResponse: {...selectedStore.branchMarketplaceResponse}
        }
    };
}

export const fetchSelectedStore = (marketplaceId, branchMarketplaceId) => {
    return dispatch => {
        const URL = '/marketplace/' + marketplaceId + "/" + branchMarketplaceId;
        axios.get(URL)
            .then(response => {
                console.log(response.data)
                dispatch(loadSelectedStore(response.data));
            })
            .catch(error => {
                console.log("Error se desio" + error);
                dispatch(selectedStoreChanged(null));
            })

    };
}

export const fetchSelectedStore2 = (itemId, marketplaceId, branchMarketplaceId) => {
    return dispatch => {
        const URL = '/item_in_marketplace/marketplace/' + itemId + '/' + branchMarketplaceId + "/" + marketplaceId;
        axios.get(URL)
            .then(response => {
                console.log(response.data)
                dispatch(selectedStoreChanged(response.data));
            })
            .catch(error => {
                console.log("Error se desio" + error);
                dispatch(selectedStoreChanged(null));
            })

    };
}


export const showGoogleMapChanged = (show) => {
    return {
        type: actionsTypes.SHOW_GOOGLE_MAP_CHANGED,
        showGoogleMap: show
    }
}

//actions for open hours for specific store
export const openHoursForStore = (openHoursForStore) => {
    return {
        type: actionsTypes.FETCH_OPEN_HOURS_FOR_STORE,
        openHoursForStore: openHoursForStore

    };
}

export const fetchOpenHoursForStoreFailed = () => {
    return {
        type: actionsTypes.FETCH_OPEN_HOURS_FOR_STORE_FAILED
    };
}

export const fetchOpenHoursForStoreStart = () => {
    return {
        type: actionsTypes.FETCH_OPEN_HOURS_FOR_STORE_START
    };
}

export const fetchOpenHoursForStore = (marketplaceId, branchMarketplaceId) => {
    return dispatch => {
        dispatch(fetchOpenHoursForStoreStart());
        const URL = '/marketplace/opening_hours/' + marketplaceId + "/" + branchMarketplaceId;
        axios.get(URL)
            .then(response => {
                dispatch(openHoursForStore(response.data))
            })
            .catch(error => {
                console.log("Error se desio" + error);
                dispatch(fetchOpenHoursForStoreFailed())
            })

    };
}

//ACTIONS FOR FETCHING STORES IN RADIUS FOR ITEM
export const allStoresInRadiusForArticle = (stores) => {
    return {
        type: actionsTypes.ALL_STORES_IN_RADIUS_FOR_ARTICLE,
        stores: stores

    };
}

//Dodaju se filteri isto kao primer gore
export const fetchAllStoresInRadiusForArticle = (articleId, page, latitude, longitude, distance, filter, numberOfReturns= 32) => {
    return dispatch => {
        dispatch(fetchAllStoresForArticleStart());

        const URL = '/item_in_marketplace/in_radius/page'
            + '?filter=item.id:' + articleId + filter + '&page=' + page
            + '&' + "latitude=" + latitude
            + '&' + "longitude=" + longitude
            + '&' + "distance="  + distance
            + '&' + "numOfReturns="  + numberOfReturns;

        axios.get(URL)
            .then(response => {
                dispatch(allStoresInRadiusForArticle(response.data))
            })
            .catch(error => {
                if (error.response !== null && error.response !== undefined  && error.response.data === "") {
                    dispatch(fetchAllStoresForArticleFailed())
                }
                else {
                    dispatch(fetchAllStoresForArticleServerError());
                }
            })
    };
}

export const allStoresInRadiusForArticleModal = (storesModal) => {
    return {
        type: actionsTypes.ALL_STORES_IN_RADIUS_FOR_ARTICLE_MODAL,
        storesModal: storesModal
    };
}

export const resetAllStoresInRadiusForArticleModal = () => {
    return {
        type: actionsTypes.RESET_ALL_STORES_IN_RADIUS_FOR_ARTICLE_MODAL
    }
}

//Dodaju se filteri isto kao primer gore
export const fetchAllStoresInRadiusForArticleModal = (articleId, page, latitude, longitude, distance, filter) => {
    return dispatch => {
        dispatch(fetchAllStoresForArticleModalStart());

        const URL = '/item_in_marketplace/in_radius/page'
            + '?filter=item.id:' + articleId + filter + '&page=' + page
            + '&' + "latitude=" + latitude
            + '&' + "longitude=" + longitude
            + '&' + "distance="  + distance
            + '&' + "numOfReturns="  + 20;

        axios.get(URL)
            .then(response => {
                dispatch(allStoresInRadiusForArticleModal(response.data))
                console.log(response);
            })
            .catch(error => {
                if (error.response !== null && error.response !== undefined  && error.response.data === "") {
                    dispatch(fetchAllStoresForArticleModalFailed())
                }
                else {
                    dispatch(fetchAllStoresForArticleModalServerError());
                }
            })
    };
}

export const fetchUserLocationStart = () => {
    return {
        type: actionsTypes.FETCH_USER_LOCATION_START
    };
}

export const fetchUserLocationFinished = () => {
    return {
        type: actionsTypes.FETCH_USER_LOCATION_FINISHED
    };
}

export const fetchUserLocationError = () => {
    return {
        type: actionsTypes.FETCH_USER_LOCATION_ERROR
    };
}

export const isSearchInputInFocus = (searchInputFocus) => {
    return {
        type: actionsTypes.SEARCH_INPUT_FOCUS,
        searchInputFocus: searchInputFocus
    };
}


//action for reset store values when closing appPage component
export const resetAppPageStoreValues = () => {
    return {
        type: actionsTypes.RESET_APP_PAGE_STORE
    };
}

export const fetchStoresForArticle = ({articleId, page, filter, latitude, longitude, distance, modal = false}) => {
    return dispatch => {
        if(latitude && longitude) {
            if (modal) {
                dispatch(fetchAllStoresInRadiusForArticleModal(articleId, page, latitude, longitude, distance, filter))
            } else {
                dispatch(fetchAllStoresInRadiusForArticle(articleId, page, latitude, longitude, distance, filter))
            }
        } else {
           dispatch(fetchAllStoresForArticle(articleId, page, filter))
        }

    };
}

// for video call

export const zoomCredentials = (zoomCredentials) => {
    return {
        type: actionsTypes.FETCH_ZOOM_CREDENTIALS,
        zoomCredentials: zoomCredentials

    };
}

export const fetchZoomCredentialsFailed = () => {
    return {
        type: actionsTypes.FETCH_ZOOM_CREDENTIALS_FAILED
    };
}

export const fetchZoomCredentialsStart = () => {
    return {
        type: actionsTypes.FETCH_ZOOM_CREDENTIALS_START
    };
}

export const fetchZoomCredentials = (data) => {
    return dispatch => {
        dispatch(fetchZoomCredentialsStart());
        const URL = '/job/zoom_signature';
        axios.post(URL, data)
            .then(response => {
                console.log(response.data)
                dispatch(zoomCredentials(response.data))
            })
            .catch(error => {
                console.log(error);
                dispatch(fetchZoomCredentialsFailed())
            })
    };
}

// fetch user's entered address for fine search
export const enteredAddressData = (place) => {
    return {
        type: actionsTypes.ENTERED_ADDRESS_DATA,
        place: place

    };
}

export const getStoreItemModalData = (storeItem) => {
    return {
        type: actionsTypes.STORE_ITEM_MODAL,
        storeItemModal: storeItem
    };
}

export const toggleShopNotOnlineModal = (toggleShopNotOnlineModal) => {
    return {
        type: actionsTypes.TOGGLE_SHOP_NOT_ONLINE_MODAL,
        toggleShopNotOnlineModal: toggleShopNotOnlineModal
    }
}

//actions for saving search string
export const createSearchInput = () => {
    return {
        type: actionsTypes.CREATE_USER_SEARCH_INPUT
    };
}

export const createUserSearchInputFailed = () => {
    return {
        type: actionsTypes.CREATE_USER_SEARCH_INPUT_FAILED
    };
}

export const createUserSearchInputStart = () => {
    return {
        type: actionsTypes.CREATE_USER_SEARCH_INPUT_START
    };
}

export const createUserSearchInput = (searchRequest) => {
    return dispatch => {
        dispatch(createUserSearchInputStart());
        const URL = '/user_search_input';
        axios.post(URL, searchRequest)
            .then(response => {
                console.log(response.data);
                dispatch(createSearchInput())
            })
            .catch(error => {
                console.log(error);
                dispatch(createUserSearchInputFailed())
            })

    };
}



