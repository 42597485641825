import * as actionsTypes from '../actions/actionsTypes'

const initialState = {
    articles: null,
    totalElements: -1,
    totalPages: -1,
    articlesError: false,
    articlesServerError: false,
    loadingArticles: false,

    allArticles: null,
    totalArticleElements: -1,
    totalArticlePages: -1,
    loadingAllArticles: false,
    allArticlesFailed: false

}

const articlesSearchReducer = (state = initialState, action) => {
    switch (action.type) {

        case actionsTypes.ALL_SEARCHED_ARTICLES:
            return {
                ...state,
                loadingArticles: false,
                articles: action.searchedArticles.list,
                totalElements: action.searchedArticles.totalElements,
                totalPages: action.searchedArticles.totalPages,
                articlesError: false,
                articlesServerError: false

            }
        case actionsTypes.SLICE_OF_SEARCHED_ARTICLES:
            return {
                ...state,
                loadingArticles: false,
                articles: action.searchedArticles,
                articlesError: false,
                articlesServerError: false

            }
        case actionsTypes.FETCH_ALL_SEARCHED_ARTICLES_FAIlED:
            return {
                ...state,
                articles: null,
                totalElements: -1,
                totalPages: -1,
                articlesError: true,
                loadingArticles: false,
                articlesServerError: false
            }

        case actionsTypes.FETCH_ALL_SEARCHED_ARTICLES_SERVER_ERROR:
            return {
                ...state,
                articles: null,
                totalElements: -1,
                totalPages: -1,
                articlesError: false,
                loadingArticles: false,
                articlesServerError: true
            }

        case actionsTypes.FETCH_ALL_SEARCHED_ARTICLES_START:
            return {
                ...state,
                loadingArticles: true,
                articlesServerError: false,
                articlesError: false

            }


        case actionsTypes.ALL_ARTICLES_OBJECTS:
            return {
                ...state,
                allArticles: action.articles.list,
                totalArticleElements: action.articles.totalElements,
                totalArticlePages: action.articles.totalPages,
                loadingAllArticles: false,
                allArticlesFailed: false
            }
        case actionsTypes.SLICE_OF_ALL_ARTICLES_OBJECTS:
            return {
                ...state,
                allArticles: action.articles,
                loadingAllArticles: false,
                allArticlesFailed: false
            }
        case actionsTypes.FETCH_ALL_ARTICLES_OBJECTS_FAIlED:
            return {
                ...state,
                allArticles: null,
                loadingAllArticles: false,
                allArticlesFailed: true
            }
        case actionsTypes.FETCH_ALL_ARTICLES_OBJECTS_START:
            return {
                ...state,
                loadingAllArticles: true,
                allArticlesFailed: false
            }
        case actionsTypes.RESET_ARTICLES:
            return {
                ...state,
                allArticles: null,
                totalArticleElements: -1,
                totalArticlePages: -1,
                loadingAllArticles: false,
                allArticlesFailed: false,
                articles: null,
                totalElements: -1,
                totalPages: -1,
                articlesError: false,
                articlesServerError: false,
                loadingArticles: false,
            }

        default:
            return state;
    }
}

export default articlesSearchReducer;