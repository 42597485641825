import React, {Fragment, useContext, useEffect, useRef, useState} from "react";
import styles from '../StoreItems/StoreItems.module.css';
import Spinner from '../../../UI/Spinner/Spinner'
import {connect} from "react-redux";
import * as actions from "../../../../store/actions";
import {withRouter} from "react-router-dom";
import StoreItemsError from "../StoreItems/StoreItemsError/StoreItemsError";
import ResponsiveGridLayout from 'react-grid-layout';
import {redirectToError} from "../../../../containers/App";
import {FuzzySortContext} from "../../../../store/context/FuzzySort/FuzzySort";
import Pagination from "../../../UI/Pagination/Pagination";
import StoreItemModal from "../StoreItems/StoreItem/StoreItemModal/StoreItemModal";
import Media from "react-media";
import StoreItem from "../StoreItems/StoreItem/StoreItem";


const StoreSearchItems = (props) => {

    const fuzzySort = useContext(FuzzySortContext);
    const isFirstRun = useRef(true);
    const [filterState, filterStateSetter] = useState(null)

    //useEffect koji dohvata filtere iz localstorage-a i ucitava ih u filtere. Sluzi prilkom refresha da se zadrzi staro stanje
    //ali i prikom ucitavanja CnC stranice
    useEffect(() => {
        if (props.storeFilter.filters === '') {
            let temp = []
            let radius = false
            for (let key in localStorage) {
                if (key.includes('storeFilter')) temp.push(key.split('_')[1])
            }
            if (temp.length === 0) {
                temp = ''
            }
            props.onStoreFilterSelection({inMyRadius: radius, filters: temp});
        }
    }, [])


    //poziva se kad god se ucitava novi niz pretrazenih apoteka ili menja grad
    //poziva se ako smo promenili searchString u putanji, tj. ako smo pretrazili novi string u search bar-u.
    // ako je stranica refresh-ovana onda je matchedStoresNames = null i moramo ga ponovo ucitati sa back-a
    //todo videti sa Matkom da se ovo sredi
    useEffect(() => {
        if (props.matchedStoresNames === null) {
            props.onInitStoresNames();
        } else {
            if (props.match.params.searchedString !== 'all_stores' && filterState !== null) {
                isFirstRun.current = false;
                if (localStorage.getItem('addressLongitude') && localStorage.getItem('addressLatitude')) {
                    props.onFetchStores({
                        storesIds: props.matchedStoresNames,
                        page: props.match.params.page,
                        filter: filterState,
                        latitude: localStorage.getItem('addressLatitude'),
                        longitude: localStorage.getItem('addressLongitude'),
                    })
                } else if (filterState !== null) {
                    props.onFetchStores({
                        storesIds: props.matchedStoresNames,
                        page: props.match.params.page,
                        filter: filterState
                    });
                }
            }
        }

    }, [props.match.params.searchedString, props.match.params.page, props.matchedStoresNames, filterState])

    //izbegavamo pozivanje funcionalnosti ovog useEffect-a kada prvi put ulazimo na stranicu normalno preko main page-a (ne refresh-om iste),
    // ovo radimo preko isFirstRun ref promenjive
    // ako je korisnik refreshovao stranicu , gornji useEffect ce pozvati ponovno ucitavanje svih storesNames-a.
    // Kada se ucitaju storesNames, poziva se funkcionalnost ovog useEffect-a koji na osnovu putanje ili radi filter
    // apoteka po nazivu ili filter cnc apoteka ili sve apoteke. Zatim poziva back kako bi dobili sve informacije o tim
    //filtriranim apotekama.
    //todo videti sa Matkom da se ovo sredi
    useEffect(() => {
        if (props.storesNames !== null && isFirstRun.current && props.match.params.searchedString !== 'all_stores') {

            let matchingResult;
            const searchStringWords = fuzzySort.separateSearchStringByWords(props.match.params.searchedString);
            matchingResult = fuzzySort.sortResultsByStartWord(fuzzySort.filterByWords(props.storesNames, searchStringWords, 0), searchStringWords[0]);
            props.onMatchedStoresChanged(matchingResult);
            if(filterState) {
                props.onFetchStores({storesIds: matchingResult, page: props.match.params.page, filter: filterState});
                } else {
                props.onFetchStores({storesIds: matchingResult, page: props.match.params.page, filter: ""});
            }
        }
    }, [props.storesNames])

    useEffect(() => {
        if (props.match.params.page !== undefined && props.match.params.searchedString === 'all_stores' && filterState !== null) {
            if (localStorage.getItem('addressLatitude') && localStorage.getItem('addressLongitude')) {
                props.onFetchStores({
                    page: props.match.params.page,
                    filter: filterState,
                    latitude: localStorage.getItem('addressLatitude'),
                    longitude: localStorage.getItem('addressLongitude')
                });
            } else {
                if (props.totalElements !== -1 && props.totalPages !== -1) {
                    props.onFetchStores({page: props.match.params.page, filter: filterState});
                } else {
                    props.onFetchStores({page: props.match.params.page, filter: filterState});
                }
            }
        }
    }, [props.match.params.page, filterState]);

    //PRIMER SVI FILTERI: filter=marketplace.location:'Beograd' and marketplace.isClickCollect:1 and marketplace.haveDelivery:1 and marketplace.openingHours.monday:'00:00 do 24:00'
    useEffect(() => {
        if (props.storeFilter.filters.length > 0) {
            let value = '&filter=';
            props.storeFilter.filters.map((filter, index) => {
                //todo kad se uradi za grad, umesto ':grad' staviti localstorage.getItem('location')
                if (filter === 'location') value += filter + ':grad'
                else if (filter === 'openingHours') {
                    const weekdays = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
                    let day = new Date();
                    value += filter + "." + weekdays[day.getDay()] + ":'00:00 do 24:00'";
                }
                else if (filter === 'hasDiscount') {
                    value += 'numOfItemsWithDiscount > 0';
                }
                else {
                    value += filter + ':1';
                }
                if (index !== props.storeFilter.filters.length - 1) value += ' and ';
            })

            if (value !== '&filter=')
                filterStateSetter(value);
        } else {
            filterStateSetter('')
        }

    }, [props.storeFilter]);

    //Vraca stranu (vrednost page) u url-u na 0 pri promeni filtera
    useEffect(() => {
        if (props.match.params.page !== 0) {
            onNextPageHandler(0);
        }
    }, [filterState])

    // const [gridView, listOrGridViewToggleHandler] = useState(true);

    const onNextPageHandler = (page) => {
        const url = '/searched-stores/' + page + '/' + props.match.params.searchedString;
        props.history.push(url)
    }

    //Kod koji odredjuje placeholder u search baru
    if (props.match.params.searchedString !== "all_stores") {
        props.onSearchBarPlaceholderChanged(props.match.params.searchedString);
    }

    let storeItems = <Spinner/>;
    let grid = <Spinner/>;
    // let counter = 0;
    let x = 0;
    let y = 0;
    let indexFlag = 0;

    const dataGridFill = (index) => {
        if (index === 0) {
            x = 0;
        } else {
            x++;
            if (x === 4) {
                y++;
                x = 0;
            }
        }
    }

    if (!props.loadingStores && !props.loadingUserLocation) {
        if (props.storesError) {
            storeItems = <StoreItemsError/>;
        } else {
            if (props.storesServerError) {
                redirectToError(props);
            } else {

                if (props.gridView) {

                    storeItems = props.stores !== null && props.stores.map((storeItem, index) => {

                        dataGridFill(index);
                        indexFlag = index;

                        return (
                            <div key={index} data-grid={{x: x, y: y, w: 1, h: 1, isDraggable: false}}
                                 className={styles.ElementInGridCell}>
                                <StoreItem storeItem={storeItem}
                                           key={[storeItem.branchMarketplaceId,
                                               storeItem.marketplaceId]}
                                            // showStoreDetails={e => showSelectedStoreHandler(e, storeItem)}
                                           selectedStore={props.selectedStore}
                                           distance={props.distances !== null && props.distances[index]}
                                           selectedStoreHandler={props.onSelectedStoreChanged}
                                           gridView={props.gridView}
                                    // articleId={props.match.params.id}
                                />
                            </div>
                        );
                    });

                    grid = props.stores !== null &&
                        <Media
                            queries={{
                                small: "(max-width: 699px)",
                                large: "(min-width: 700px)"
                            }}>
                            {matches => (
                                <Fragment>
                                    {matches.small &&
                                        <div style={{display: "flex", flexDirection: "column", width: "100vw"}}>
                                            {storeItems}
                                        </div>
                                    }

                                    {matches.large &&
                                        <ResponsiveGridLayout cols={4} rowHeight={240} width={1340}>
                                            {storeItems}
                                        </ResponsiveGridLayout>
                                    }
                                </Fragment>
                            )}
                        </Media>
                }
            }
        }
    }

    return (
        <React.Fragment>

            <div className={styles.Grid}>
                <div className={styles.GridViewSoftEnter}>
                    {props.storesError ? <StoreItemsError/> : grid}
                </div>
            </div>

            <div className={styles.PagesHolder}>
                <Pagination totalElements={props.totalElements}
                            totalPages={props.totalPages}
                            matchParams={props.match.params}
                            onNextPageHandler={onNextPageHandler}
                />
            </div>

            {props.storeItemModal.isOpen &&
                <StoreItemModal onCloseClick={() => props.onGetStoreItemModalData({isOpen: false, storeItem: null})}
                                storeItem={props.storeItemModal.storeItem}
                                onOpen={props.storeItemModal.isOpen}
                />
            }

        </React.Fragment>
    );
}

const mapStateToProps = (state) => {
    return {
        storesNames: state.mainPage.storesNames,
        matchedStoresNames: state.mainPage.matchedStores,
        searchString: state.mainPage.searchString,

        stores: state.storesSearchPage.stores,
        distances: state.storesSearchPage.distances,
        loadingStores: state.storesSearchPage.loadingStores,
        storesError: state.storesSearchPage.storesError,
        storesServerError: state.storesSearchPage.storesServerError,
        totalElements: state.storesSearchPage.totalElements,
        totalPages: state.storesSearchPage.totalPages,
        loadingUserLocation: state.appPage.loadingUserLocation,

        selectedStore: state.appPage.selectedStore,
        gridView: state.filter.gridView,

        storeFilter: state.filter.storeFilter,

        storeItemModal: state.appPage.storeItemModal
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        // onFetchAllSearchedStores: (storesIds, page, filter) => dispatch(actions.fetchAllSearchedStores(storesIds, page, filter)),
        onSelectedStoreChanged: (selectedStore) => dispatch(actions.selectedStoreChanged(selectedStore)),
        onMatchedStoresChanged: (matchedStores) => dispatch(actions.matchedStoresChanged(matchedStores)),
        onInitStoresNames: () => dispatch(actions.initStoresNames()),
        onSearchBarPlaceholderChanged: (newString) => dispatch(actions.searchBarPlaceholderChanged(newString)),

        // onFetchAllStores: (page, filter) => dispatch(actions.fetchAllStores(page, filter)),
        onFetchStores: ({storesIds, page, filter, latitude, longitude}) => dispatch(actions.fetchStores({
            storesIds,
            page,
            filter,
            latitude,
            longitude,
        })),
        onFetchSliceOfStores: (page, filter) => dispatch(actions.fetchSliceOfStores(page, filter)),

        // onFetchSearchedStores: ({storesIds, page, filter, latitude, longitude, distance}) => dispatch(actions.fetchSearchedStores({storesIds, page, filter, latitude, longitude, distance})),

        onFetchUserLocationStart: () => dispatch(actions.fetchUserLocationStart()),
        onFetchUserLocationError: () => dispatch(actions.fetchUserLocationError()),
        onFetchUserLocationFinished: () => dispatch(actions.fetchUserLocationFinished()),
        onStoreFilterSelection: (storeFilter) => dispatch(actions.storeFilterSelection(storeFilter)),
        onGetStoreItemModalData: (storeItem) => dispatch(actions.getStoreItemModalData(storeItem))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(StoreSearchItems));