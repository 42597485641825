import React from 'react'
import InformationBox from "../../InformationBox/InformationBox";
import {connect} from "react-redux";

const StorePageMobileInfo = props => {
    return (
        <>
            <InformationBox selectedStore={props.selectedStore}/>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        selectedStore: state.appPage.selectedStore
    }
}

export default connect(mapStateToProps)(StorePageMobileInfo);