import React, {useEffect, useState} from "react"
import {connect} from "react-redux";
import * as actions from "../../../store/actions";
import Button from "../../UI/Buttons/Button/Button";
import Spinner from "../../UI/Spinner/Spinner";
import StoreInModal from "./StoreInModal/StoreInModal";
import styles from './CNCModal.module.css';
import {withRouter} from "react-router-dom";
import axios from "../../../axios/axios-apoteka";
import ModalWithHeader from "../../UI/Modals/ModalWithHeader/ModalWithHeader";
import PerfectScrollbar from "react-perfect-scrollbar";
import {ReactComponent as NoPharmaciesInCNC} from "./../../../assets/icons/nema_porucivanja_img.svg";
import {textToUrl} from "../../GobalFunctions/GlobalFunctions";

const CNCModal = (props) => {

    const [storesModal, setStoresModal] = useState(null);

    const [selectedPharmacyData, selectedPharmacyDataSetter] = useState({
        data: '',
        index: ''
    })

    const [modalContent, modalContentSetter] = useState(<Spinner />)
    const [buttonSectionContent, buttonSectionContentSetter] = useState(null)
    const [modalContentFlag, modalContentFlagHandler] = useState(false);
    const [pharmacyStores, setPharmacyStores] = useState(null)

    const pharmacySelected = (data, index) => {
        selectedPharmacyDataSetter({data: data, index: index})
    }

    useEffect(()=>{
        // ucitavanje apoteka za brzu kupovinu
        if(props.storesModal) {
            setPharmacyStores (props.storesModal);
        }

        // ucitavanje apoteka za korisnicku terapiju
        if(props.storesContainingUserTherapy) {
            setPharmacyStores(props.storesContainingUserTherapy.stores)
        }
    }, [props.storesModal, props.storesContainingUserTherapy])

    useEffect(() => {
        if(selectedPharmacyData.index=== '' )
            selectedPharmacyDataSetter({...selectedPharmacyData, index: 0})

        // ovde bi trebalo implementirati promise
        setStoresModal(pharmacyStores && pharmacyStores.map((store, index) => {
            return (
                <StoreInModal
                    key = {index}
                    store={store}
                    index={index}
                    pharmacySelectedCallback={()=>pharmacySelected(store, index)}
                    selected={selectedPharmacyData.index === index}
                />
            )
        }))

    }, [selectedPharmacyData, pharmacyStores])

    useEffect(()=>{
            setTimeout(()=>{
                if(storesModal && storesModal.length >=0) {
                    modalContentFlagHandler(true)
                }
                else {
                    modalContentFlagHandler(false)
                }
            }, 300)
    }, [storesModal])

    // ako smo modal otvorili sa strane Moje terapije, ovde se iz terapije izdvajaju artikli i salje se upit na back da
    // bi se dobio niz apoteka koje sadrze sve artikle iz terapije
    useEffect(()=> {
        if(props.therapy) {
            let therapyArticles = []
            props.therapy.items.map(article => {
                    therapyArticles.push({quantity: article.quantity, itemId: article.itemResponse ? article.itemResponse.id : article.item.id})
            })
            if(therapyArticles.length === props.therapy.items.length) {
                props.onOrderTherapy(0, localStorage.getItem('addressLatitude'), localStorage.getItem('addressLongitude'), therapyArticles)
            }
        }
    }, [props.therapy])


    useEffect(() => {
        return () => {
            modalContentSetter(null)
            props.onResetAllStoresForArticleModal();
        }
    }, [])

    useEffect(()=>{
        if(modalContentFlag === true) {
            if (storesModal && storesModal.length > 0) {
                modalContentSetter(
                    <div style={{width: "100%"}}>
                        <p className={styles.HeaderText}>Poruči za preuzimanje</p>
                        {props.myTherapyModal
                            ? <div>
                                <p className={styles.Name}>Terapija <strong style={{color:"#00B2B1"}}>{props.therapy.name}</strong></p>
                            </div>
                            : (props.articleDetails &&
                                <div className={styles.ArticleInfoWrapper}>
                                    <p className={styles.Name}>{props.articleDetails.name}</p>
                                    {(props.articleDetails.itemAvailabilityCode && props.articleDetails.itemAvailabilityShortCode !== 'MS' && props.articleDetails.itemAvailabilityShortCode !== 'BR') &&
                                        <p>Lek na recept</p>}
                                </div>
                            )
                        }
                        <div>
                            <p className={styles.Message}>Izaberite apoteku</p>
                            {props.loadingStoresModal ?
                                <Spinner/>
                                :
                                <PerfectScrollbar style={{height: "190px", width: "calc(100% + 1rem)"}}>
                                    {storesModal}
                                </PerfectScrollbar>
                            }
                        </div>
                    </div>
                )

                buttonSectionContentSetter(
                    <div className={styles.BottomActionsWrapper}>
                        <Button buttontype="whiteblue" onClick={buyMore} style={{height: "auto"}}>Kupi još iz iste apoteke</Button>
                        <Button buttontype="blue" onClick={finishOrder}>Završi porudžbinu</Button>
                    </div>
                )

            } else {
                modalContentSetter(
                    props.myTherapyModal
                        ? <div style={{width: "100%", height: "80%", display: "flex", flexDirection: "column", justifyContent: "space-evenly", textAlign:"center"}}>
                            <div>Nažalost, proizvodi iz terapije </div>
                            <div><strong style={{color:"#00B2B1"}}>{props.therapy.name} </strong></div>
                            <div>nisu dostupni u apotekama</div>
                            <div>koje su <strong>u sistemu Poruči i preuzmi.</strong></div>
                            <br/>
                            <NoPharmaciesInCNC style={{margin: "0 auto"}}/>
                            <br/>
                        </div>
                        :
                        <div style={{width: "100%", height: "80%", display: "flex", flexDirection: "column", justifyContent: "space-evenly", textAlign:"center"}}>
                            <div>Nažalost, traženi <strong>proizvod nije dostupan</strong></div>
                            <div>u apotekama koje su <strong>u sistemu Poruči i preuzmi.</strong></div>
                            <br/>
                            <NoPharmaciesInCNC style={{margin: "0 auto"}}/>
                            <br/>
                            <div><strong>Proizvod je raspoloživ za offline kupovinu</strong></div>
                            <div>u apotekama navedenim na</div>
                            <div>stranici ove pretrage.</div>
                        </div>
                )

                buttonSectionContentSetter(
                    <div className={styles.BottomActionsWrapper} style={{display:"flex", justifyContent:"center"}}>
                        <Button buttontype="blue" onClick={() => props.onCNCModalToggle(false)}>Zatvori</Button>
                    </div>
                )
            }
        }
    }, [modalContentFlag, storesModal])

    useEffect(() => {
        //prazni se zbog toga sto pri ulasku na CartSummary redirektuje u shop ako postoji ova promenljiva
        //CartSummary useEffect() sa metodom backToShop() - potrebno zbog uslova za (ne)izvrsavanje backToShop()
        localStorage.removeItem('beforeEnteringStore');

        if (props.articleDetails !== null) {
            if (localStorage.getItem("enteredAddress")) {
                props.onFetchStoresForArticle({
                    articleId: props.articleDetails.id,
                    page: 0,
                    latitude: localStorage.getItem("addressLatitude"),
                    longitude: localStorage.getItem("addressLongitude"),
                    distance: 6000000,
                    filter: 'and marketplace.isClickCollect:1',
                    modal: true
                })
            } else {
                props.onFetchStoresForArticle({
                    articleId: props.articleDetails.id,
                    page: 0,
                    filter: 'and marketplace.isClickCollect:1',
                    modal: true
                })
            }
        }
    }, [])

    const addToCartAndRedirect = (url) => {
        if (props.isLoggedIn) {
            localStorage.setItem('beforeEnteringStore', window.location.pathname)
            if (props.allShoppingCarts && (props.allShoppingCarts[0].marketplaceResponse.branchMarketplaceResponse.id !== selectedPharmacyData.data.marketplaceResponse.branchMarketplaceId ||
                (props.allShoppingCarts[0].marketplaceResponse.branchMarketplaceResponse.id === selectedPharmacyData.data.marketplaceResponse.branchMarketplaceId &&
                    props.allShoppingCarts[0].marketplaceResponse.marketplaceId !== selectedPharmacyData.data.marketplaceResponse.marketplaceId))) {
                const URL = '/shopping_cart/' + props.userInfo.id + '/' + props.allShoppingCarts[0].marketplaceResponse.branchMarketplaceResponse.id + '/' + props.allShoppingCarts[0].marketplaceResponse.marketplaceId;
                axios.delete(URL)
                    .then(response => {
                        props.onFinishClearShoppingCart();
                        props.onAllShoppingCarts(null);
                        !props.myTherapyModal
                            ? props.onAddItemToShoppingCart(props.userInfo.id, +selectedPharmacyData.data.marketplaceResponse.branchMarketplaceId, +selectedPharmacyData.data.marketplaceResponse.marketplaceId, props.articleDetails.id)
                            : props.onAddUserTherapyToShoppingCart(props.therapy.id, props.userInfo.id, +selectedPharmacyData.data.marketplaceResponse.marketplaceId, +selectedPharmacyData.data.marketplaceResponse.branchMarketplaceId)
                        props.history.push(url);
                        props.onCNCModalToggle(false);
                    })
                    .catch(error => {
                        // console.log("Error se desio" + error);
                    })
            } else {
                !props.myTherapyModal
                    ? props.onAddItemToShoppingCart(props.userInfo.id, selectedPharmacyData.data.marketplaceResponse.branchMarketplaceId, selectedPharmacyData.data.marketplaceResponse.marketplaceId, props.articleDetails.id)
                    : props.onAddUserTherapyToShoppingCart(props.therapy.id, props.userInfo.id, +selectedPharmacyData.data.marketplaceResponse.marketplaceId, +selectedPharmacyData.data.marketplaceResponse.branchMarketplaceId)
                props.history.push(url)
                props.onCNCModalToggle(false);
            }
        }
    }

    const buyMore = () => {
        !props.myTherapyModal ?
        addToCartAndRedirect('/shop/' + selectedPharmacyData.data.marketplaceResponse.branchMarketplaceId + '/' + selectedPharmacyData.data.marketplaceResponse.marketplaceId + '/0' + '/' + props.articleDetails.id + '/' + textToUrl(selectedPharmacyData.data.branchMarketplaceResponse ? selectedPharmacyData.data.branchMarketplaceResponse.name : selectedPharmacyData.data.marketplaceResponse.branchMarketplaceResponse.name) + '/' + textToUrl(selectedPharmacyData.data.marketplaceResponse.name))
            :
        addToCartAndRedirect('/shop/' + selectedPharmacyData.data.marketplaceResponse.branchMarketplaceId + '/' + selectedPharmacyData.data.marketplaceResponse.marketplaceId + '/0' + '/' + textToUrl(selectedPharmacyData.data.marketplaceResponse.branchMarketplaceResponse.name) + '/' + textToUrl(selectedPharmacyData.data.marketplaceResponse.name))
    }

    const finishOrder = () => {
        addToCartAndRedirect('/shoppingCart/' + selectedPharmacyData.data.marketplaceResponse.branchMarketplaceId + '/' + selectedPharmacyData.data.marketplaceResponse.marketplaceId + '/' + textToUrl(selectedPharmacyData.data.branchMarketplaceResponse ? selectedPharmacyData.data.branchMarketplaceResponse.name : selectedPharmacyData.data.marketplaceResponse.branchMarketplaceResponse.name) + '/' + textToUrl(selectedPharmacyData.data.marketplaceResponse.name));
    }

    return (
        <>
            <ModalWithHeader
                show={props.cncModalToggle}
                backdropClickHandler={() => props.onCNCModalToggle(false)}
                onCloseClick={() => props.onCNCModalToggle(false)}
                buttons={buttonSectionContent}
            >
                {modalContent}
            </ModalWithHeader>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        cncModalToggle: state.modal.cncModalToggle,
        articleDetails: state.appPage.articleDetails,
        storesModal: state.appPage.storesModal,
        distancesModal: state.appPage.distancesModal,
        loadingStoresModal: state.appPage.loadingStoresModal,
        userInfo: state.authPage.userInfo,
        isLoggedIn: state.authPage.token !== null,
        allShoppingCarts: state.shopPage.allShoppingCarts,
        storesContainingUserTherapy: state.userProfile.storesContainingUserTherapy,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        onCNCModalToggle: (cncModalToggle) => dispatch(actions.CNCModalToggle(cncModalToggle)),
        onFetchStoresForArticle: ({articleId, page, filter, latitude, longitude, distance, modal}) => dispatch(actions.fetchStoresForArticle({
            articleId, page, filter, latitude, longitude, distance, modal
        })),
        onResetAllStoresForArticleModal: () => dispatch(actions.resetAllStoresInRadiusForArticleModal()),
        onAddItemToShoppingCart: (userId, branchMarketplaceId, marketplaceId, itemId) => dispatch(actions.addItemToShoppingCartUsingItemId(userId, branchMarketplaceId, marketplaceId, itemId)),
        onClearShoppingCart: (userId, branchMarketplaceId, marketplaceId) => dispatch(actions.clearShoppingCartAction(userId, branchMarketplaceId, marketplaceId)),
        onFinishClearShoppingCart: () => dispatch(actions.clearShoppingCart()),
        onAllShoppingCarts: (allShoppingCarts) => dispatch(actions.allShoppingCarts(allShoppingCarts)),
        onOrderTherapy: (page, latitude, longitude, therapyArticles) => dispatch(actions.orderTherapy(page, latitude, longitude, therapyArticles)),
        onAddUserTherapyToShoppingCart: (therapyId, userId, marketplaceId, branchMarketplaceId) => dispatch(actions.addUserTherapyToShoppingCart(therapyId, userId, marketplaceId, branchMarketplaceId))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CNCModal));