import React, {useEffect, useState, useRef} from 'react'
import styles from './ProdajnoMestoCMS.module.css'
import * as actions from "../../../store/actions";
import {connect} from "react-redux";
import ProdajnoMestoForm from "./ProdajnoMestoForm/ProdajnoMestoFrom";
import ProdajnoMestoRadnoVremeForm from "./ProdajnoMestoRadnoVremeForm/ProdajnoMestoRadnoVremeForm";
import Modal from "../../UI/Modals/Modal/Modal"
import ImageUploader from "react-images-upload";


const ProdajnoMestoCMS = (props) => {

    const [indexState, indexStateChanged] = useState({
        index: [null,null],
        inputs: <div>Selektuj apoteku</div>,
        nameOfCurrent: "Nijedna apoteka nije selektovana"
    })

    const pictureElement1 = useRef(null);
    const pictureElement2 = useRef(null);

    function toClear(){
        pictureElement1.current.clearPictures();
        // pictureElement1.current.setPictures([]);
        setPictures([]);
        setBranchMarketplacePictures([]);
        pictureElement2.current.clearPictures();
        // pictureElement1.current.setPictures([]);
        props.onUpdatePictures([]);
        props.onUpdateBranchMarketplacePictures([]);
    }

    //Metode i state za slike za marketplace i za slike za branch_marketplace
    const [pictures, setPictures] = useState([]);
    const [branchMarketplacePictures, setBranchMarketplacePictures] = useState([]);
    const onDropForMarketplace = picture => {
        // picture = [picture[picture.length-1]]
        setPictures([...pictures, picture]);
        props.onUpdatePictures(picture.map((picture) => {
            return picture.name
        }));

    };
    const onDropForBranchMarketplace = picture => {
        setBranchMarketplacePictures([...branchMarketplacePictures, picture]);
        // picture = [picture[picture.length-1]]
        props.onUpdateBranchMarketplacePictures(picture.map((picture) => {
            return picture.name
        }));

    };


    useEffect(() => {
        if (props.stores === null){
            props.onInitStores();
        }

    }, [])

    const changeSelectedStore = (store, index) => {
        indexStateChanged({
            ...indexState,
            index: [store.branchMarketplaceId, store.marketplaceId],
            inputs: <ProdajnoMestoForm storeDetails={store} index={index}/>,
            inputsOpenHours: <ProdajnoMestoRadnoVremeForm marketplaceId={store.marketplaceId}
                                                          branchMarketplaceId={store.branchMarketplaceId}/>,
            nameOfCurrent: store.name
        })
        toClear();
    }

    let allStores = null;
    if (props.stores !== undefined && props.stores !== null) {
        allStores = props.stores.map((store, index) => {
            return (<div key={[store.branchMarketplaceId, store.marketplaceId]} className={(store.branchMarketplaceId === indexState.index[0] && store.marketplaceId === indexState.index[1]) ? styles.Selected : ((store.latitude !== null && store.longitude !== null) ? styles.Updated : null)}
                         onClick={() => {changeSelectedStore(store, index)}}>
                <p>{store.name}, {store.address}, {store.location} | {store.branchMarketplaceResponse.name}</p>
            </div>)
        });
    }

    return (
        <div className={styles.ProdajnoMestoCMS}>
            <div className={styles.Left}>
                {allStores}
            </div>
            <div className={styles.Right}>
                <p className={styles.NameOfCurrent}>{indexState.nameOfCurrent}</p>
                {indexState.inputs}
                <div className={styles.ImagesUploadHolder}>
                    <ImageUploader
                        {...props}
                        ref={pictureElement1}
                        className={styles.ImageUploader}
                        fileContainerStyle={{padding:"10px", boxShadow:"none"}}
                        withIcon={true}
                        onChange={onDropForMarketplace}
                        imgExtension={[".jpg", ".gif", ".png"]}
                        maxFileSize={5242880}
                        withPreview={true}
                        singleImage={true}
                        buttonText={'Izaberi slike za prodajno mesto'}
                        label={'Maksimalna veličina slike: 5mb, prima formate: jpg, png, gif'}
                    />

                    <ImageUploader
                        {...props}
                        ref={pictureElement2}
                        className={styles.ImageUploader}
                        fileContainerStyle={{padding:"10px", boxShadow:"none"}}
                        withIcon={true}
                        onChange={onDropForBranchMarketplace}
                        imgExtension={[".jpg", ".gif", ".png"]}
                        maxFileSize={5242880}
                        withPreview={true}
                        singleImage={true}
                        buttonText={'Izaberi slike za filijalu'}
                        label={'Maksimalna veličina slike: 5mb, prima formate: jpg, png, gif'}
                    />
                </div>
                {props.openHoursChanged && <Modal>{indexState.inputsOpenHours}</Modal>}
            </div>

        </div>
    );
}
const mapStateToProps = (state) => {
    return {
        stores: state.cmsPage.stores,
        error: state.cmsPage.error,
        openHoursChanged: state.cmsPage.openHoursChanged
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onInitStores: () => dispatch(actions.initStores()),
        onUpdatePictures: (pictures) => dispatch(actions.updatePictures(pictures)),
        onUpdateBranchMarketplacePictures: (pictures) => dispatch(actions.updateBranchMarketplacePictures(pictures))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProdajnoMestoCMS);
