import React, {Fragment, useEffect, useState} from "react";
import ActionMenu from "../../UI/ActionMenu/ActionMenu";
import SearchBar from "../../MainPage/SearchWrapper/SearchBar/SearchBar";
import styles from "../../SearchResultsPage/SearchResultsActionMenu/SearchResultsActionMenu.module.css";
import {connect} from "react-redux";
import searchStyles from "./ShopPageActionMenu.module.css";
import Media from "react-media";
import ActionMenuRes from "../../../componentsRes/UIRes/ActionMenuRes/ActionMenuRes";
import ShopInfoBox from "../ShopPageHeader/ShopInfoBox/ShopInfoBox";
import {ReactComponent as LoyaltyFilterIcon} from "../../../assets/icons/loyalty-watch.svg";
import {ReactComponent as FilterIcon} from "../../../assets/icons/filter_icon.svg";
import {ReactComponent as DiscountIcon} from "../../../assets/icons/discount_icon.svg";
import ArticleCategories from "../../Filters/ArticleCategories/ArticleCategories";
import ArticleCategoriesRes from "../../Filters/ArticleCategoriesRes/ArticleCategoriesRes";
import SquareBadge from "../../UI/SquareBadge/SquareBadge";
import TooltipComponent from "../../UI/TooltipComponent/TooltipComponent";
import * as actions from "../../../store/actions";
import ShopArticleSort from "./ShopArticleSort/ShopArticleSort";

const ShopPageActionMenu = (props) => {

    const [isFilterModalVisible, isFilterModalVisibleHandler] = useState(false);

    const [isArticleFilterSelected, isArticleFilterSelectedHandler] = useState(false);
    const [loyaltyFilter, setLoyaltyFilter] = useState(null);
    const [discountFilter, setDiscountFilter] = useState(null);

    useEffect(() => {
        let shopFilter = localStorage.getItem('selectedArticleFilter');
        if(shopFilter === 'OTHER_ITEM' || shopFilter === null){
            isArticleFilterSelectedHandler(false)
        } else {
            isArticleFilterSelectedHandler(true)
        }

    }, [localStorage.getItem('selectedArticleFilter')])

    useEffect(() => {
        if (localStorage.getItem('articleFilter_loyalty')){
            props.onLoyaltyFilter(true)
            setLoyaltyFilter(true)
        }
        if(localStorage.getItem('articleFilter_discount')) {
            props.onDiscountFilter(true);
            setDiscountFilter(true);
        }
    }, [])

    useEffect(() => {
        if (loyaltyFilter !== null) {
            if (loyaltyFilter) {
                props.onLoyaltyFilter(true)
                localStorage.setItem('articleFilter_loyalty', 'true')
            } else {
                props.onLoyaltyFilter(false)
                localStorage.removeItem('articleFilter_loyalty')
            }
        }
    }, [loyaltyFilter])

    useEffect(() => {
        if (discountFilter !== null) {
            if (discountFilter) {
                props.onDiscountFilter(true)
                localStorage.setItem('articleFilter_discount', 'true')
            } else {
                props.onDiscountFilter(false)
                localStorage.removeItem('articleFilter_discount')
            }
        }
    }, [discountFilter])

    const openFilterModal = () => {
        isFilterModalVisibleHandler(true);
    }

    const filterAction = () => {
        props.isLogedin ?
            openFilterModal() :
            props.badActionClicked()
    }

    const closeFilterModal =()=> {
        isFilterModalVisibleHandler(false);
    }

    const loyaltyFilterHandler = () => {
        props.isLogedin ?
            setLoyaltyFilter(!loyaltyFilter) :
            props.badActionClicked();
    }

    const discountFilterHandler = () => {
        props.isLogedin ?
            setDiscountFilter(prev => !prev) :
            props.badActionClicked()
    }

    let filterLoyaltyButton =
        <SquareBadge
            className={styles.QuickFilter}
            size={"32px"}
            marginLeft={"12px"}
            onClick={loyaltyFilterHandler}
            pointer
        >
            <LoyaltyFilterIcon className={loyaltyFilter ? styles.IconStyleClicked : styles.IconStyle}/>
            <TooltipComponent className={styles.TooltipText} text="Artikli sa loyalty bodovima"/>
        </SquareBadge>;

    const filterDiscountButton =
        <SquareBadge
            className={styles.QuickFilter}
            size={"32px"}
            marginLeft={"12px"}
            onClick={discountFilterHandler}
            pointer
        >
            {/*zakomentarisano dok se ne nadje odgovarajuca ikonica */}
            {/*<div className={discountFilter ? styles.IconStyleClicked : styles.IconStyle}>%</div>*/}
            <DiscountIcon className={discountFilter ? styles.IconStyleClicked : styles.IconStyle}/>
            <TooltipComponent className={styles.TooltipText} text="Artikli na popustu"/>
        </SquareBadge>;

    return (
        <>
            <Media
                queries={{
                    small: "(max-width: 699px)",
                    large: "(min-width: 700px)"
                }}>
                {matches => (
                    <Fragment>

                        {matches.small &&
                            <>
                                <ActionMenuRes>
                                    <ShopInfoBox/>
                                    <div style={{display: "flex", flexDirection:"column"}}>
                                        {props.isLogedin ? <SearchBar/> :
                                            <div style={{
                                                height: "36px",
                                                width: "648px",
                                                display: "flex",
                                                justifyContent: "space-between"
                                            }}>
                                                <div onClick={props.badActionClicked}
                                                     className={searchStyles.SearchDiv}
                                                >
                                                    <p style={{margin: "auto 0", fontWeight: "400", color: "#c5c4c4"}}>
                                                        Pretraga</p>
                                                </div>
                                            </div>
                                        }
                                        <div style={{display:"flex", justifyContent:"space-between"}}>
                                            <div style={{display:"flex", alignItems:"center"}}>
                                                <FilterIcon onClick={filterAction} style={{
                                                    height: "22px",
                                                    width: "22px",
                                                    marginLeft: "8px",
                                                    cursor: "pointer",
                                                }}/>
                                                {filterLoyaltyButton}
                                                {filterDiscountButton}
                                            </div>
                                            <ShopArticleSort />
                                        </div>
                                    </div>
                                    {/*stari filteri*/}
                                    {/*{props.isLogedin && isFilterModalVisible &&*/}
                                    {/*    <ShopFilter show={isFilterModalVisible}*/}
                                    {/*                backdropClickHandler={closeFilterModal}>*/}
                                    {/*    </ShopFilter>}*/}

                                    {/*nove kategorije*/}
                                    {props.isLogedin && isFilterModalVisible &&
                                        // <FilterModal >
                                            <ArticleCategoriesRes show={isFilterModalVisible}
                                                                  backdropClickHandler={closeFilterModal}>
                                            </ArticleCategoriesRes>
                                        // </FilterModal>
                                    }
                                </ActionMenuRes>
                            </>
                        }

                        {matches.large &&
                            <ActionMenu holderStyle={{justifyContent: "space-between", position: "relative"}}>
                                <div className={styles.SearchResultsAM}>
                                    {/*DUGME ZA OTVARANJE KATEGORIJA*/}
                                    <button onClick={filterAction} style={{cursor: "pointer"}}
                                            className={isArticleFilterSelected ? [styles.FilterButton, styles.FilterButtonSelected].join(' ') : [styles.FilterButton, styles.FilterButtonUnselected].join(' ')}
                                    >Kategorije</button>
                                    {filterLoyaltyButton}
                                    {filterDiscountButton}
                                </div>
                                {props.isLogedin ? <SearchBar/> :
                                    <div style={{height: "36px", width: "648px", display: "flex", justifyContent: "space-between"}}>
                                        <div onClick={props.badActionClicked}
                                             className={searchStyles.SearchDiv}
                                        >
                                            <p style={{margin: "auto 0", fontWeight: "400", color: "#c5c4c4"}}>
                                                Za pretragu lekova u ovoj apoteci morate biti ulogovani</p>
                                        </div>
                                    </div>
                                }
                                {<ShopArticleSort badActionClicked={props.badActionClicked}/>}

                                {/*stari filteri*/}
                                {/*{props.isLogedin && isFilterModalVisible &&*/}
                                {/*    <ShopFilter show={isFilterModalVisible}*/}
                                {/*                backdropClickHandler={closeFilterModal}>*/}
                                {/*    </ShopFilter>}*/}

                                {/*nove kategorije*/}
                                {props.isLogedin && isFilterModalVisible &&
                                    <ArticleCategories show={isFilterModalVisible}
                                                       backdropClickHandler={closeFilterModal}>
                                    </ArticleCategories>}
                            </ActionMenu>
                        }
                    </Fragment>
                )}
            </Media>

        </>
    );
}

const mapStateToProps = (state) => {
    return {
        isLogedin: state.authPage.token !== null,
        loyaltyFilter: state.filter.loyaltyFilter,
        discountFilter: state.filter.discountFilter
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onLoyaltyFilter: (loyaltyFilter) => dispatch(actions.loyaltyFilter(loyaltyFilter)),
        onDiscountFilter: (discountFilter) => dispatch(actions.discountFilter(discountFilter))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ShopPageActionMenu);