import React, {createContext} from "react";
const fuzzysort = require('fuzzysort');


const FuzzySortContext = createContext("")

export { FuzzySortContext }

const FuzzySort = (props) => {

    // const filterStores = () => {
    //     let promise = fuzzysort.goAsync(searchStringWords[0], props.storesNames, {keys: ['name'], threshold: -50000});
    //     promise.then(results => {
    //         let matchingResult = filterByWords(results, searchStringWords, 1);
    //         props.onMatchedStoresChanged(matchingResult);
    //         // const firstItem = (<SearchedStringSearchItem key={0} index={index++} onMainPage={props.onMainPage} searchArticles={false}/>)
    //         // matchedStoresChanged([firstItem , ...matchingResult.map(result => (<SearchStore key={result.id} store={result} index={index++} onMainPage={props.onMainPage}/>))]);
    //         // if (index === 2 && !props.storesNamesError && !props.loadingStoresNames) {
    //         //
    //         //     matchedStoresChanged([(<NoMatchSearchItem
    //         //         message={configData.SearchItems.NotFindArticleText + props.searchString}
    //         //         onMainPage={props.onMainPage}
    //         //         key={index}/>)]);
    //         // }
    //         // props.onNumberOfMatchedArticlesChanged(index-1);
    //         // props.onSelectedIndexChanged(0);
    //     })
    //     // if(invalidated) promise.cancel()
    // }

    const sortResultsByStartWord = (results, startWord) => {
        const resultsWithStartWord = [];
        results = results.filter(result => {
            if(result.name.toLowerCase().startsWith(startWord.toLowerCase())){
                resultsWithStartWord.push(result);
            }
            else {
                return result;
            }
        });
        resultsWithStartWord.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
        return resultsWithStartWord.concat(results);
    }

    //Algoritam koji proverava za svaku rec rekurzivno
    const filterByWords = (results, searchStringWords, idx) => {
        if (idx === searchStringWords.length || searchStringWords[idx] === '') {
            return results.map((articleObj) => {
                
                return {
                    ...articleObj.obj,
                    nameHighlighted: searchStringWords.length > 1 ? articleObj.obj.name : fuzzysort.highlight(articleObj[0], '<strong>', '</strong>'),
                    // nameHighlighted: articleObj.obj.fullName,
                    name: articleObj.obj.name
                }
            });
        }
        const obj = idx === 0 ? results : results.map((object) => object.obj);
        const threshold = idx === 0 ? -1000 : -50000;
        results = fuzzysort.go(searchStringWords[idx], obj, {keys: ['name'], threshold: threshold});
        idx++;
        return filterByWords(results, searchStringWords, idx);
    }

    const splitArrayOfSentencesIntoArrayOfWords = (sentenceObjArray) =>  {
        return sentenceObjArray.map(sentenceObj => {
            return separateSearchStringByWords(sentenceObj.name).map(word => {
                return {
                    ...sentenceObj,
                    fullName: sentenceObj.name,
                    name: word
                }
            });
        }).flat(1);
    }

    const mergeArrayById = (array) => {
        return array.filter((value, index, self) =>
            index === self.findIndex((t) => (
                t.id === value.id
            ))
        )
    }

    const separateSearchStringByWords = (searchString) => {
        return searchString.split(' ').filter(string => {
            if (string.trim() !== '') {
                return string.trim();
            }
        });
    }

    const toReturn = {
        filterByWords,
        separateSearchStringByWords,
        sortResultsByStartWord,
        splitArrayOfSentencesIntoArrayOfWords,
        mergeArrayById
    }


    return (
        <FuzzySortContext.Provider value={toReturn}>
            {props.children}
        </FuzzySortContext.Provider>
    )
}
export default FuzzySort