import * as actionsTypes from "../actions/actionsTypes";

const initialState = {
    registrationError: false,
    registrationServerError: false,
    loadingRegistration: false,
    registrationSuccess: false,

    token: null,
    loginError: false,
    loginServerError: false,
    registrationUnconfirmed: false,
    loadingLogin: false,

    userInfo: null,
    loadingUserInfo: false,
    userInfoError: false,

    loadingForgetPassword: false,
    forgetPasswordError: false,

    googleRecaptchaResponse: null,
    googleRecaptchaVerificationError: false,
    loadingGoogleRecaptchaVerification: false,

    PPTOSModalOpened: false
}

const authPageReducer = (state = initialState, action) => {
    switch (action.type) {

        case actionsTypes.REGISTER_USER_SUCCESS:
            return {
                ...state,
                registrationError: false,
                registrationServerError: false,
                loadingRegistration: false,
                registrationSuccess: true
            }
        case actionsTypes.REGISTER_USER_FAILED:
            return {
                ...state,
                registrationError: true,
                registrationServerError: false,
                loadingRegistration: false,
                registrationSuccess: false
            }
        case actionsTypes.REGISTRATION_SERVER_ERROR:
            return {
                ...state,
                registrationError: false,
                registrationServerError: true,
                loadingRegistration: false,
                registrationSuccess: false
            }
        case actionsTypes.REGISTER_USER_START:
            return {
                ...state,
                loadingRegistration: true,
                registrationSuccess: false,
                registrationServerError: false,
                registrationError: false
            }
        case actionsTypes.LOGIN_USER_SUCCESS:
            return {
                ...state,
                token: action.token,
                loadingLogin: false,
                loginError: false,
                loginServerError: false,
                registrationUnconfirmed: false
            }
        case actionsTypes.LOGIN_USER_FAILED:
            return {
                ...state,
                loginError: true,
                loginServerError: false,
                loadingLogin: false,
                registrationUnconfirmed: false
            }
        case actionsTypes.LOGIN_SERVER_ERROR:
            return {
                ...state,
                loginError: false,
                loginServerError: true,
                loadingLogin: false,
                registrationUnconfirmed: false
            }
        case actionsTypes.REGISTRATION_UNCONFIRMED:
            return {
                ...state,
                loginError: false,
                loadingLogin: false,
                registrationUnconfirmed: true
            }
        case actionsTypes.LOGIN_USER_START:
            return {
                ...state,
                loadingLogin: true,
                loginError: false,
                registrationUnconfirmed: false
            }
        case actionsTypes.LOGOUT_USER:
            return {
                ...state,
                token: null,
                loginError: false,
                loadingLogin: false,
                registrationUnconfirmed: false
            }
        case actionsTypes.FORGET_PASSWORD_START:
            return {
                ...state,
                loadingForgetPassword: true,
                forgetPasswordError: false
            }
        case actionsTypes.FORGET_PASSWORD_SUCCESS:
            return {
                ...state,
                loadingForgetPassword: false,
                forgetPasswordError: false
            }
        case actionsTypes.FORGET_PASSWORD_FAILED:
            return {
                ...state,
                loadingForgetPassword: false,
                forgetPasswordError: true
            }
        case actionsTypes.FETCH_USER_INFO_START:
            return {
                ...state,
                loadingUserInfo: true,
                userInfoError: false
            }
        case actionsTypes.FETCH_USER_INFO:
            return {
                ...state,
                userInfo: action.userInfo,
                loadingUserInfo: false,
                userInfoError: false
            }
        case actionsTypes.FETCH_USER_INFO_FAILED:
            return {
                ...state,
                loadingUserInfo: false,
                loadingLogin: false,
                userInfoError: true
            }
        case actionsTypes.GOOGLE_RECAPTCHA_VERIFICATION_START:
            return {
                ...state,
                loadingGoogleRecaptchaVerification: true,
                googleRecaptchaVerificationError: false
            }
        case actionsTypes.GOOGLE_RECAPTCHA_VERIFICATION:
            return {
                ...state,
                googleRecaptchaResponse: action.googleRecaptchaResponse,
                loadingGoogleRecaptchaVerification: false,
                googleRecaptchaVerificationError: false
            }
        case actionsTypes.GOOGLE_RECAPTCHA_VERIFICATION_FAILED:
            return {
                ...state,
                googleRecaptchaResponse: null,
                loadingGoogleRecaptchaVerification: false,
                googleRecaptchaVerificationError: true
            }
        case actionsTypes.PP_TOS_MODAL_HANDLER:
            return {
                ...state,
                PPTOSModalOpened: action.PPTOSModalOpened
            }
        default:
            return state;
    }

}

export default authPageReducer;
