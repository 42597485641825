import React, {useContext, useEffect, useRef, useState} from 'react';
import Button from "../UI/Buttons/Button/Button";
import * as actions from "../../store/actions";
import {connect} from "react-redux";
import {Link, withRouter} from "react-router-dom";
import {RFZOTherapySocketContext} from "../../store/context/RFZOTherapySocket/RFZOTherapySocket";
import Spinner from "../UI/Spinner/Spinner";
import RFZOItem from "./RFZOItem/RFZOItem";
import OrderCompletionMessagePage from "../ShoppingCart/OrderCompletionMessagePage/OrderCompletionMessagePage";
import RFZOSocketModal from "../UI/Modals/RFZOSocketModal/RFZOSocketModal";
import Wrapper from "../UI/Wrapper/Wrapper";
import InactiveToolbar from "./InactiveToolbar/InactiveToolbar";
import styles from './RFZOpage.module.css';

const RFZOpage = (props) => {

    const [rfzoTherapyArticles, rfzoTherapyArticlesChanged] = useState(<Spinner/>);
    const rfzoTherapySocket = useContext(RFZOTherapySocketContext);
    const [acceptOrRejectText, setAcceptOrRejectText] = useState(localStorage.getItem('acceptOrRejectRFZO') || null);
    const [showRFZOSocketModal, setShowRFZOSocketModal] = useState(false);
    const [itemsTotalQty, setItemsTotalQty] = useState(0);

    useEffect(() => {
        const branchMarketplaceId = window.location.pathname.split('/')[2]
        const marketplaceId = window.location.pathname.split('/')[3]
        props.onFetchSelectedStore(marketplaceId, branchMarketplaceId);

        if (props.rfzoTherapy === null) {
            props.onCheckAllRFZOTherapies(localStorage.getItem('id'));
        }

        return () => {
            setAcceptOrRejectText(null)
            rfzoTherapyArticlesChanged(null);
            localStorage.removeItem('acceptOrRejectRFZO');
            localStorage.removeItem('rfzoWithoutContent')
            props.onResetEmptyRFZOTherapy();
            rfzoTherapySocket.closeConnection();
        }
    }, [])

    useEffect(()=> {
        if(props.rfzoTherapy && props.rfzoTherapy.status === 'PROCESSED_WITHOUT_CONTENT') {
            localStorage.setItem('rfzoWithoutContent', 'true')
        }
    }, [props.rfzoTherapy])

    useEffect(()=> {
        props.rfzoTherapy && props.rfzoTherapy.items.map(itemQty => {
            setItemsTotalQty(prev => prev + itemQty.quantity)
        })
    },[props.rfzoTherapy])

    useEffect(()=>{
        console.log('RFZO')
        console.log(props.rfzoTherapy)
    }, [props.rfzoTherapy])

    const therapyWithoutContent = () => {
        if (props.rfzoTherapy && props.rfzoTherapy.status === 'PROCESSED_WITHOUT_CONTENT') {
            props.onDeleteEmptyRFZOTherapy(props.rfzoTherapy.id, props.rfzoTherapy.marketplaceResponse.marketplaceUserEmail);
            localStorage.removeItem('rfzoWithoutContent')
        }
    }

    const rfzoTherapyExpireConfirm = () => {
        if (props.rfzoTherapy && props.rfzoTherapy.status === 'REQUEST_EXPIRED') {
            props.onRFZOTherapyExpireConfirm(props.rfzoTherapy.id);
            props.history.push('/')
        }
    }

    const rfzoTherapyCancelConfirm = () => {
        if (props.rfzoTherapy && props.rfzoTherapy.status === 'CANCELED') {
            props.onRFZOTherapyCancelConfirm(props.rfzoTherapy.id);
            props.history.replace('/')
        }
    }

    useEffect(()=> {
        if(props.rfzoEmptyTherapyDeleted) {
            props.onResetRFZOTherapy();
            props.history.push('/')
        }
    }, [props.rfzoEmptyTherapyDeleted])

    useEffect(() => {
        // if(props.selectedStore !== null && localStorage.getItem('acceptOrRejectRFZO') === null) {
        console.log('iz useeffecta')
        console.log(props.rfzoTherapy)
        if (props.rfzoTherapy && (props.rfzoTherapy.status === 'REQUESTED' || props.rfzoTherapy.status === 'REQUEST_SENT_TO_POS')) {
            rfzoTherapyArticlesChanged(
                <>
                    <Spinner/>
                    <div style={{color: "#00B2B1", fontSize: "24px"}}>
                        <strong>{props.rfzoTherapy.marketplaceResponse.name}</strong>
                    </div>
                    <div>{props.rfzoTherapy.marketplaceResponse.address}</div>
                    <br/>
                    {/*<p style={{fontSize: "24px", textAlign: "center"}}>U toku je provera dostupnosti RFZO terapije u apoteci</p>*/}
                    <p style={{fontSize: "24px", textAlign: "center"}}>Farmaceut upravo proverava RFZO terapiju u apoteci.</p>
                    <p style={{fontSize: "24px", textAlign: "center"}}>Nakon potvrde dostupnosti, RFZO terapiju možeš odmah rezervisati u apoteci.</p>
                    <br/>
                    <div>Provera može potrajati do 10 minuta. Molimo te da ne napuštaš</div>
                    <div>ovu stranicu, kako se podaci ne bi izgubili.</div>
                </>
            )
        }
        if (props.rfzoTherapy === null && props.rfzoTherapyRejected || acceptOrRejectText === 'reject') {
            rfzoTherapyArticlesChanged(
                <>
                    <p><strong>Nisi prihvatio/la preuzimanje svoje RFZO terapije</strong></p>
                    {/*<p><strong>u apoteci {props.rfzoTherapy.markeplaceResponse.name}</strong></p>*/}
                    <br/><br/>
                    <div>Ako želiš da ponovo proveriš svoju RFZO terapiju</div>
                    <div>moraš sačekati da ova apoteka stornira tvoj zahtev.</div>
                    <br/><br/>
                    <Link to='/'>Povratak na početnu stranu</Link>
                </>
            )
        }
        if (props.rfzoTherapy && props.rfzoTherapy.status === 'PROCESSED_WITHOUT_CONTENT' || localStorage.getItem('rfzoWithoutContent')) {
            rfzoTherapyArticlesChanged(
                <>
                    <div>RFZO terapija nije dostupna za uneseni LBO.</div>
                    <br/><br/>
                    {props.rfzoTherapy.willBeReady && <div>Tvoja RFZO terapija će biti dostupna {props.rfzoTherapy.willBeReady}.</div>}
                    {/*{props.rfzoTherapy.willBeReady && <div>Vaša RFZO terapija će biti dostupna {props.rfzoTherapy.willBeReady.dayOfMonth}.{props.rfzoTherapy.willBeReady.monthValue}{props.rfzoTherapy.willBeReady.year}.</div>}*/}
                    <br/><br/>
                    <div>
                        <Button buttontype="blue"
                                onClick={therapyWithoutContent}>
                            U redu
                        </Button>
                    </div>
                    {/*<Link to='/'>Povratak na početnu stranu</Link>*/}

                </>
            )
        }
        if (props.rfzoTherapy && props.rfzoTherapy.status === 'REQUEST_EXPIRED') {
            rfzoTherapyArticlesChanged(
                <>
                    {/* todo ne stiže napomena koja je unesena*/}
                    <div>RFZO terapija je istekla. Izvinjavamo se, apoteka nije obradila tvoj zahtev u predvidjenom roku. Hvala na razumevanju, pokušaj kasnije.</div>
                    <br/><br/>
                    <div>
                        <Button buttontype="blue"
                                onClick={rfzoTherapyExpireConfirm}>
                            U redu
                        </Button>
                    </div>
                    {/*<Link to='/'>Povratak na početnu stranu</Link>*/}

                </>
            )
        }

        if (props.rfzoTherapy && props.rfzoTherapy.status === 'CANCELED') {
            rfzoTherapyArticlesChanged(
                <>
                    <div>RFZO terapija je odbijena. Apoteka trenutno nije u mogućnosti da obradi tvoju porudžbinu.  {props.rfzoTherapy.noteForUser !== null ? "Napomena: " + props.rfzoTherapy.noteForUser : ""}.</div>
                    <br/><br/>
                    <div>
                        <Button buttontype="blue"
                                onClick={rfzoTherapyCancelConfirm}>
                            U redu
                        </Button>
                    </div>
                    {/*<Link to='/'>Povratak na početnu stranu</Link>*/}

                </>
            )
        }



        if (props.rfzoTherapy && props.rfzoTherapy.status === 'EXPIRED') {
            rfzoTherapyArticlesChanged(
                <>
                    {/*TODO ne radi redirekcija na home page*/}
                    <div>Nisi poslao/la odgovor u predviđeno vreme. Kada apoteka stornira recept, možeš ponovo pokušati.</div>
                    <br />
                    <br />
                    <Link to='/'>U redu</Link>

                </>
            )
        }


        if (props.rfzoTherapy === null && props.rfzoTherapyAccepted || acceptOrRejectText === 'accept') {
            rfzoTherapyArticlesChanged(
                <div style={{marginTop:"-5rem"}}>
                    <OrderCompletionMessagePage onRFZOpage/>
                </div>
            )
        }
    }, [props.rfzoTherapy, props.rfzoTherapyAccepted, props.rfzoTherapyRejected])


    const socketModalFlag = useRef('firstRun')

    useEffect(() => {
        if (localStorage.getItem("token") === null) {
            console.log("Ne otvara web socket konekciju posto niste ulogovani")
        } else {
            rfzoTherapySocket.connect();
        }
        return () => {
            rfzoTherapySocket.closeConnection();
        }
    }, []);


    useEffect(() => {
        if (props.RFZOSocketStatus === null && socketModalFlag.current !== 'firstRun') {
            setShowRFZOSocketModal(true)
        }
        if (props.RFZOSocketStatus === true && socketModalFlag.current === 'firstRun') {
            socketModalFlag.current = 'notFirstRun';
        }
    }, [props.RFZOSocketConnectionStart, props.RFZOSocketStatus])

    // simuliranje prekida konekcije
    // useEffect(() => {
    //     setTimeout(() => {
    //         rfzoTherapySocket.closeConnection();
    //     }, 8000)
    // }, [])

    useEffect(() => {
        // if (props.rfzoTherapy && props.rfzoTherapy.items.length > 0 && props.rfzoTherapy.status === 'REQUESTED') {
        if (props.rfzoTherapy && props.rfzoTherapy.status === 'PROCESSED') {
            rfzoTherapyArticlesChanged(
                <div className={styles.RFZOArticleList} >
                    {props.rfzoTherapy.items.map((item, index) => {
                        return (
                            <div key={index} style={{marginRight: "2rem"}}>
                                <RFZOItem itemInShoppingCart={item}/>
                            </div>
                        )
                        // return (
                        //     <p key={index}>
                        //         {item.itemResponse.name} cena artikla: {item.price} kolicina na stanju: {item.quantity}, cena participacije: {item.rfzoParticipationPrice} kolicina: {item.rfzoQuantity}
                        //     </p>
                        // )
                    })}
                    <br/>
                    <br/>
                    <div style={{textAlign:"right", fontSize:"18px", marginRight:"32px"}}><strong>Ukupan iznos participacije: <span style={{marginLeft:"32px"}}>{props.rfzoTherapy.totalPrice.toFixed(2)} RSD</span></strong></div>
                    <br/>
                    <br/>
                    <div style={{width:"100%", textAlign:"center"}}>
                        {props.rfzoTherapy.willBeReady && <div>Tvoja RFZO terapija će biti dostupna {props.rfzoTherapy.willBeReady}.</div>}
                        {props.rfzoTherapy.lastTherapy && <div style={{color:"#00B2B1", fontWeight:"bold"}}>Ovo je tvoja poslenja terapija!</div>}
                        {itemsTotalQty === 0 && <div style={{color:"red"}}>Nema dostupnih artikala za traženu RFZO terapiju.</div>}
                    </div>
                </div>);
        }
    }, [props.rfzoTherapy, itemsTotalQty])

    const connectAgain = () => {
        setShowRFZOSocketModal(false);
        rfzoTherapySocket.connect();
    }

    const acceptRFZOTherapy = () => {
        props.onAcceptRFZOTherapy(props.rfzoTherapy.id, props.rfzoTherapy.marketplaceResponse.marketplaceUserEmail);
        setAcceptOrRejectText('accept');
        localStorage.setItem('acceptOrRejectRFZO', 'accept');
        props.onResetRFZOTherapy();
    }

    const rejectRFZOTherapy = () => {
        props.onRejectRFZOTherapy(props.rfzoTherapy.id, "REJECTED", props.rfzoTherapy.marketplaceResponse.marketplaceUserEmail)
        setAcceptOrRejectText('reject');
        localStorage.setItem('acceptOrRejectRFZO', 'reject');
        props.onResetRFZOTherapy();
    }

    const noArticlesInTherapy = () => {
        props.onRejectRFZOTherapy(props.rfzoTherapy.id, "REJECTED_OUT_OF_STOCK", props.rfzoTherapy.marketplaceResponse.marketplaceUserEmail)
        setAcceptOrRejectText(null);
        localStorage.removeItem('acceptOrRejectRFZO')
        props.onResetRFZOTherapy();
        props.history.replace('/');
    }

    return (
        <>
            <InactiveToolbar/>
            <Wrapper>
                <div className={styles.WrapperStyle} >
                    {rfzoTherapyArticles}
                    <br/>
                    <br/>
                    {/*{(props.rfzoTherapy && props.rfzoTherapy.items.length > 0) && localStorage.getItem('acceptOrRejectRFZO') === null &&*/}
                    {(props.rfzoTherapy && props.rfzoTherapy.status === 'PROCESSED' && itemsTotalQty > 0) &&
                        <div style={{width: "50%", display: "flex", justifyContent: "space-between"}}>
                            <Button
                                onClick={rejectRFZOTherapy}>Odbaci
                            </Button>
                            <Button buttontype="blue"
                                    onClick={acceptRFZOTherapy}
                            >
                                Naruči
                            </Button>
                        </div>
                    }
                    {(props.rfzoTherapy && props.rfzoTherapy.status === 'PROCESSED' && itemsTotalQty === 0) &&
                        <div style={{width: "50%", display: "flex", justifyContent: "center"}}>
                            <br/><br/>
                            <div>
                                <Button buttontype="blue"
                                        onClick={noArticlesInTherapy}>
                                    U redu
                                </Button>
                            </div>
                        </div>
                    }
                </div>

            </Wrapper>
            {showRFZOSocketModal &&
                <RFZOSocketModal
                    show={showRFZOSocketModal}
                    connectAgain={connectAgain}
                    backdropClickHandler={() => setShowRFZOSocketModal(false)}
                />
            }
        </>
    );
};


const mapStateToProps = (state) => {
    return {
        selectedStore: state.appPage.selectedStore,
        rfzoTherapy: state.rfzoTherapy.rfzoTherapy,
        loadingRFZOTherapy: state.rfzoTherapy.loadingRFZOTherapy,
        RFZOSocketStatus: state.rfzoTherapy.RFZOSocketStatus,
        RFZOSocketConnectionStart: state.rfzoTherapy.RFZOSocketConnectionStart,
        rfzoTherapyAccepted: state.rfzoTherapy.rfzoTherapyAccepted,
        rfzoTherapyRejected: state.rfzoTherapy.rfzoTherapyRejected,
        rfzoEmptyTherapyDeleted: state.rfzoTherapy.rfzoEmptyTherapyDeleted,
        rfzoEmptyTherapyDeleteStart: state.rfzoTherapy.rfzoEmptyTherapyDeleteStart,
        rfzoEmptyTherapyDeleteFailed: state.rfzoTherapy.rfzoEmptyTherapyDeleteFailed,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onAcceptRFZOTherapy: (rfzoTherapyId, recipientEmail) => dispatch(actions.acceptRFZOTherapy(rfzoTherapyId, recipientEmail)),
        onRejectRFZOTherapy: (rfzoTherapyId, status, recipientEmail) => dispatch(actions.rejectRFZOTherapy(rfzoTherapyId, status, recipientEmail)),
        onFetchSelectedStore: (marketplaceId, branchMarketplaceId) => dispatch(actions.fetchSelectedStore(marketplaceId, branchMarketplaceId)),
        onCheckAllRFZOTherapies: (userId) => dispatch(actions.checkAllRFZOTherapies(userId)),
        onResetRFZOTherapy: () => dispatch(actions.rfzoTherapy(null)),
        onDeleteEmptyRFZOTherapy: (rfzoTherapyId, userEmail) => dispatch(actions.deleteEmptyRFZOTherapy(rfzoTherapyId, userEmail)),
        onRFZOTherapyExpireConfirm: (rfzoTherapyId) => dispatch(actions.rfzoTherapyExpireConfirm(rfzoTherapyId)),
        onRFZOTherapyCancelConfirm: (rfzoTherapyId) => dispatch(actions.rfzoTherapyCancelConfirm(rfzoTherapyId)),
        onResetEmptyRFZOTherapy: ()=> dispatch(actions.resetEmptyRFZOTherapy())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RFZOpage));
