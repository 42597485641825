import * as actionsTypes from './actionsTypes'
import axios from '../../axios/axios-apoteka'

export const toolbarChanged = (changed) => {
    return {
        type: actionsTypes.TOOLBAR_CHANGED,
        changed: changed
    }
}

export const allArticles = (articles) => {
    return {
        type: actionsTypes.ALL_ARTICLES,
        articles: articles

    };
}
export const fetchArticlesFailed = () => {
    return {
        type: actionsTypes.FETCH_ALL_ARTICLES_FAIlED
    };
}

export const fetchArticlesStart = () => {
    return {
        type: actionsTypes.FETCH_ALL_ARTICLES_START
    };
}


export const initArticles = () => {
    return dispatch => {
        const URL = '/item/names';
        dispatch(fetchArticlesStart());
        axios.get(URL)
            .then(response => {
                dispatch(allArticles(response.data))
                // console.log(response.data);
            })
            .catch(error => {
                console.log("Error se desio" + error);
                dispatch(fetchArticlesFailed());
            })

    };
}

export const searchBarPlaceholderChanged = (newString) => {
    return {
        type: actionsTypes.SEARCH_BAR_PLACEHOLDER_CHANGED,
        searchBarPlaceholder: newString
    }
}

export const matchedItemsChanged = (matchedItems) => {
    return {
        type: actionsTypes.MATCHED_ITEMS_CHANGED,
        matchedItems: matchedItems
    };
}

export const searchStringChanged = (searchString) => {
    return {
        type: actionsTypes.SEARCH_STRING_CHANGED,
        searchString: searchString
    }
}

export const selectDetected = (isSelected) => {
    return {
        type: actionsTypes.SELECT_DETECTED,
        isSelected: isSelected
    }
}

export const selectedIndexChanged = (index) => {
    return {
        type: actionsTypes.SELECTED_INDEX_CHANGED,
        selectedIndex: index
    }
}

export const numberOfMatchedArticlesChanged = (numberOfMatchedArticles) => {
    return {
        type: actionsTypes.NUMBER_OF_MATCHED_ARTICLES_CHANGED,
        numberOfMatchedArticles: numberOfMatchedArticles
    }
}


//count of marketplaces
export const countMarketplaces = (countMarketplaces) => {
    return {
        type: actionsTypes.COUNT_MARKETPLACES,
        countMarketplaces: countMarketplaces

    };
}

export const countMarketplacesAction = () => {
    return dispatch => {
        const URL = '/marketplace/count';
        axios.get(URL)
            .then(response => {
                dispatch(countMarketplaces(response.data))
            })
            .catch(error => {
            })

    };
}

//Actions for fetching stores for search bar
export const allStoresNames = (storesNames) => {
    return {
        type: actionsTypes.ALL_STORES_NAMES,
        storesNames: storesNames

    };
}
export const fetchAllStoresNamesFailed = () => {
    return {
        type: actionsTypes.FETCH_ALL_STORES_NAMES_FAIlED
    };
}

export const fetchAllStoresNamesStart = () => {
    return {
        type: actionsTypes.FETCH_ALL_STORES_NAMES_START
    };
}

export const initStoresNames = () => {
    return dispatch => {
        const URL = '/marketplace/names';
        dispatch(fetchAllStoresNamesStart());
        axios.get(URL)
            .then(response => {
                dispatch(allStoresNames(response.data))
                // console.log(response.data);
            })
            .catch(error => {
                console.log("Error se desio" + error);
                dispatch(fetchAllStoresNamesFailed());
            })

    };
}

export const matchedStoresChanged = (matchedStores) => {
    return {
        type: actionsTypes.MATCHED_STORES_CHANGED,
        matchedStores: matchedStores
    };
}

//ADVERTISEMENT ACTIONS
export const randomAdvertisement = (advertisement) => {
    return {
        type: actionsTypes.FETCH_RANDOM_ADVERTISEMENT,
        advertisement: advertisement

    };
}
export const fetchRandomAdvertisementFailed = () => {
    return {
        type: actionsTypes.FETCH_RANDOM_ADVERTISEMENT_FAIlED
    };
}

export const fetchRandomAdvertisementStart = () => {
    return {
        type: actionsTypes.FETCH_RANDOM_ADVERTISEMENT_START
    };
}

export const fetchRandomAdvertisement = () => {
    return dispatch => {
        const URL = '/advertisement/random?type=web';
        dispatch(fetchRandomAdvertisementStart());
        axios.get(URL)
            .then(response => {
                dispatch(randomAdvertisement(response.data))
                // console.log(response.data);
            })
            .catch(error => {
                console.log("Error se desio" + error);
                dispatch(fetchRandomAdvertisementFailed());
            })

    };
}

//Order rating actions
export const rating = (rating) => {
    return {
        type: actionsTypes.FETCH_RATING,
        rating: rating

    };
}
export const fetchRatingFailed = () => {
    return {
        type: actionsTypes.FETCH_RATING_FAIlED
    };
}

export const fetchRatingStart = () => {
    return {
        type: actionsTypes.FETCH_RATING_START
    };
}

export const fetchRating = (userId) => {
    return dispatch => {
        const URL = '/click_and_collect_transaction_rating/'+ userId;
        dispatch(fetchRatingStart());
        axios.get(URL)
            .then(response => {
                dispatch(rating(response.data))
                // console.log(response.data);
            })
            .catch(error => {
                console.log("Error se desio" + error);
                dispatch(fetchRatingFailed());
            })

    };
}
