import React from 'react';
import styles from './Layout.module.css';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import Footer from '../Footer/Footer'
import * as actions from '../../store/actions';

const Layout = (props) => {

    // const sideDrawerClosedHandler = () => {
    //     props.onShowShoppingCartDrawer();
    // }
    //
    // const redirectToShoppingCart = () => {
    //     const url = '/shoppingCart';
    //     // props.history.replace('/')
    //     props.history.push(url);
    // }

    return (
        <div className={styles.Layout}>
            {/*<Backdrop show={props.showBackdrop} />*/}

                {/*<Toolbar />*/}
                {/*Korpa na celoj stranici sa backdropom*/}
                {/*<SideDrawer*/}
                {/*    backdrop ={true}*/}
                {/*    open={props.drawerToggleClicked}*/}
                {/*    closed={sideDrawerClosedHandler}>*/}
                {/*    <p>Korpa</p>*/}
                {/*    <button onClick={redirectToShoppingCart}>*/}
                {/*        /!*<Backdrop show={props.shoppingCardDrawer} backdropClickHandler={props.closed}/>*!/*/}
                {/*        Zavrsi kupovinu*/}
                {/*    </button>*/}
                {/*</SideDrawer>*/}
                <main className={styles.LayoutContent} style={window.location.pathname.includes('rfzo-therapy/') ? {minHeight: "100vh"} : {}}>
                    {props.children}
                </main>
                {/*{props.location.pathname === '/' && <Footer/> }*/}
                 {!window.location.pathname.includes('rfzo-therapy/') && <Footer/>}
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        showBackdrop: state.mainPage.showBackdrop,
        backdropClickHandler: state.mainPage.backdropClickHandler,
        drawerToggleClicked: state.toolbar.shoppingCardDrawer
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onShowShoppingCartDrawer: () => dispatch(actions.showShoppingCartDrawerChanged())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Layout));
