import React, {useEffect, useRef, useState} from 'react'
import MasterArtikalForm from "./MasterArtikalForm/MasterArtikalForm";
import styles from './MasterArtikalCMS.module.css'
import ImageUploader from "react-images-upload";
import * as actions from "../../../store/actions";
import {connect} from "react-redux";
import SearchBar from "../../MainPage/SearchWrapper/SearchBar/SearchBar";
import Wrapper from "../../UI/Wrapper/Wrapper";

const MasterArtikalCMS = (props) => {

    useEffect(() => {
        props.onFetchItemAvailabilities();
    },[])

    const fieldRef = useRef(null);

    const [pictures, setPictures] = useState([]);
    const onDrop = picture => {
        setPictures([...pictures, picture]);
        props.onUpdatePictures(picture.map((picture) => {
            return picture.name
        }));

    };

    const clearPictureList = () => {
        fieldRef.current.clearPictures();
        //TODO srediti brisanje slika
        props.onUpdatePictures([]);
        setPictures([]);

    };

    return (
        <Wrapper>
            <div className={styles.MasterArtikalCMS}>
                <div style={{display: "flex", justifyContent: "center"}}>
                    <SearchBar onMainPage={false}/>
                </div>
                {/*<SearchWrapper onMainPage={false} />*/}
                <MasterArtikalForm clearPictureList={clearPictureList}/>
                <ImageUploader
                    {...props}
                    ref={fieldRef}
                    withIcon={true}
                    onChange={onDrop}
                    imgExtension={[".jpg", ".gif", ".png"]}
                    maxFileSize={5242880}
                    withPreview={true}
                    singleImage={true}
                    buttonText={'Izaberi slike za ovaj proizvod'}
                    label={'Maksimalna veličina slike: 5mb, prima formate: jpg, png, gif'}
                />
            </div>
        </Wrapper>
    );
}



const mapDispatchToProps = (dispatch) => {
    return {
        onUpdatePictures: (pictures) => dispatch(actions.updatePictures(pictures)),
        onFetchItemAvailabilities: () => dispatch(actions.fetchItemAvailabilities())
    }
}

export default connect(null, mapDispatchToProps)(MasterArtikalCMS);
