import React, {useEffect, useState} from 'react';
import styles from "./LBOInputField.module.css";
import {useForm} from "react-hook-form";
import * as actions from "../../../../store/actions";
import {connect} from "react-redux";

const LBOInputField = (props) => {

    useEffect(()=>{
        if (props.userProfile === null) {
            props.onFetchUserProfile(localStorage.getItem('email'));
        }
    },[])

    const {register, handleSubmit, formState: {errors}, getValues, setFocus} = useForm({mode: 'onBlur',
        defaultValues: {
            saveLBOToProfile: false
        }
    });

    const [saveLBOToProfileValue, setSaveLBOToProfileValue] = useState(false)

    useEffect(()=>{
        if(props.userProfile && props.userProfile.lbo)
        setFocus('lbo')
    }, [props.userProfile])

    useEffect(() => {
        console.log(saveLBOToProfileValue)
    }, [saveLBOToProfileValue])


    const onSubmit = data => {
        const values = getValues()

        if (values.lbo.length === 11) {
            localStorage.setItem('LBO', values.lbo)
            props.passLBONumber({
                lbo: values.lbo,
                saveToProfile: saveLBOToProfileValue
            })
        } else if (values.lbo.length !==11) {
            localStorage.removeItem('LBO')
        }
    }

    const checkKey =(e)=> {
        if(e.keyCode === 13 || e.keyCode === 69 || e.keyCode === 107 || e.keyCode === 109 || e.keyCode === 190) {
            e.preventDefault();
        }
    }

    return (
        <div>
            <p className={styles.LBOLabel}>Unesite svoj LBO broj</p>
            <form onBlur={onSubmit} onKeyDown={checkKey}>
                <div className={styles.LBOInputFieldWrapper}>
                    <input
                        type="number" placeholder="LBO broj"
                        defaultValue={props.userProfile && props.userProfile.lbo}
                        className={styles.LBOInputField}
                        {...register("lbo", {
                            required: true,
                            minLength: {
                                value: 11,
                                message: "LBO broj mora imati tačno 11 cifara"
                            },
                            maxLength: {
                                value: 11,
                                message: "LBO broj mora imati tačno 11 cifara"
                            },
                        })}
                    />
                    {<span className={styles.ErrorMessage}>{errors.lbo && errors.lbo.message}</span>}
                </div>
                {!props.onProfilePage &&
                    <div className={styles.LBOInputFieldWrapper}>
                        <div style={{display: "flex", justifyContent: "flex-start", alignItems: "center"}}>
                            <input type="checkbox"
                                   className={styles.SaveLBOCheckbox}
                                   checked={saveLBOToProfileValue}
                                   onClick={()=>setSaveLBOToProfileValue(!saveLBOToProfileValue)}
                                   placeholder="saveLBOToProfile" {...register("saveLBOToProfile", {})} />&nbsp;
                            <span style={{fontSize:"14px"}}>Sačuvaj LBO na profilu</span>
                        </div>
                    </div>
                }
            </form>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        userProfile: state.userProfile.userProfile,
        userProfileError: state.userProfile.userProfileError,
        loadingUserProfile: state.userProfile.loadingUserProfile,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onFetchUserProfile: (email) => dispatch(actions.fetchUserProfile(email)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LBOInputField);