import * as actionsTypes from "./actionsTypes";
import axios from "../../axios/axios-apoteka";

//actions for fetching all orders for one user
export const allOrders = (allOrders) => {
    return {
        type: actionsTypes.FETCH_ALL_ORDERS,
        allOrders: allOrders
    };
}

export const fetchAllOrdersFailed = () => {
    return {
        type: actionsTypes.FETCH_ALL_ORDERS_FAILED
    };
}

export const fetchAllOrdersStart = () => {
    return {
        type: actionsTypes.FETCH_ALL_ORDERS_START
    };
}

export const fetchAllOrders = (userId) => {
    return dispatch => {
        dispatch(fetchAllOrdersStart());
        const URL = '/click_and_collect_order/orders_for_user/' + userId;
        axios.get(URL)
            .then(response => {
                if (response.data === "") {
                    dispatch(allOrders(null));
                }
                else {
                    dispatch(allOrders(response.data));
                }
            })
            .catch(error => {
                console.log("Error se desio" + error);
                dispatch(fetchAllOrdersFailed())
            })
    };
}


//actions for fetching all transactions for one user
export const allTransactions = (allTransactions) => {
    return {
        type: actionsTypes.FETCH_ALL_TRANSACTIONS,
        allTransactions: allTransactions
    };
}

export const fetchAllTransactionsFailed = () => {
    return {
        type: actionsTypes.FETCH_ALL_TRANSACTIONS_FAILED
    };
}

export const fetchAllTransactionsStart = () => {
    return {
        type: actionsTypes.FETCH_ALL_TRANSACTIONS_START
    };
}

export const fetchAllTransactions = (userId) => {
    return dispatch => {
        dispatch(fetchAllTransactionsStart());
        const URL = '/click_and_collect_transaction/' + userId;
        axios.get(URL)
            .then(response => {
                if (response.data === "") {
                    dispatch(allTransactions(null));
                }
                else {
                    dispatch(allTransactions(response.data));
                }
            })
            .catch(error => {
                console.log("Error se desio" + error);
                dispatch(fetchAllTransactionsFailed())
            })
    };
}

//actions for fetching single order for a user
export const singleOrder = (singleOrder) => {
    return {
        type: actionsTypes.FETCH_SINGLE_ORDER,
        singleOrder: singleOrder
    };
}

export const fetchSingleOrderFailed = () => {
    return {
        type: actionsTypes.FETCH_SINGLE_ORDER_FAILED
    };
}

export const fetchSingleOrderStart = () => {
    return {
        type: actionsTypes.FETCH_SINGLE_ORDER_START
    };
}

export const fetchSingleOrder = (orderId) => {
    return dispatch => {
        dispatch(fetchSingleOrderStart());
        const URL = '/click_and_collect_order/' + orderId;
        axios.get(URL)
            .then(response => {
                if (response.data === "") {
                    dispatch(singleOrder(null));
                }
                else {
                    dispatch(singleOrder(response.data));
                }
            })
            .catch(error => {
                console.log("Error se desio" + error);
                dispatch(fetchSingleOrderFailed())
            })
    };
}

export const rateTransaction = (ratingParent) => {
    return dispatch => {
        const URL = '/click_and_collect_transaction_rating/' + ratingParent.ratingId;
            axios.put(URL, {rating: ratingParent.rating, ratingComment: ratingParent.ratingComment})
                .then(response => {
                    console.log("response")
                    console.log(response)
                    dispatch(updateTransaction(ratingParent))
                })
                .catch(error => {
                    console.log("Error se desio" + error);
                })
    }
}

export const updateTransaction = (ratingParent) => {
    return {
        type: actionsTypes.RATE_TRANSACTION,
        ratingParent: ratingParent
    }
}

export const orderChangedStatus = (orderInfo) => {
    return {
        type: actionsTypes.ORDER_CHANGED_STATUS,
        orderInfo: orderInfo
    };
}