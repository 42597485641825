import React, {Fragment} from 'react';
import styles from './OrderStatus.module.css';
import Media from "react-media";

const OrderStatus = (props) => {
    return(
        <Media
            queries={{
                small: "(max-width: 699px)",
                large: "(min-width: 700px)"
            }}>
            {matches => (
                <Fragment>
                    {matches.small &&
                        <div className={styles.OrderStatus} style={props.style}>
                            <div className={styles.ColoredStatusMobile}>
                                <div className={styles.Status} style={props.statusStyle}>{props.status}</div>
                                {props.icon}
                            </div>
                            {props.message && <div className={styles.Message}>{props.message}</div>}
                        </div>
                    }

                    {matches.large &&
                        <div className={styles.OrderStatus} style={props.style}>
                            {props.icon}
                            <div className={styles.Status} style={props.statusStyle}>{props.status}</div>
                            {props.message && <div className={styles.Message}>{props.message}</div>}
                        </div>
                    }
                </Fragment>
            )}
        </Media>
    )
}

export default OrderStatus;