import React from 'react'
import ModalWithHeader from "../../../UI/Modals/ModalWithHeader/ModalWithHeader";
import Button from "../../../UI/Buttons/Button/Button";

const SuccessSearchInputModal = (props) => {

    return(
        <ModalWithHeader onCloseClick={props.onCloseClick}
                         show={props.show}
                         backdropClickHandler={props.backdropClickHandler}
                         buttons={
                             <div style={{width:"100%", display:"flex", justifyContent:"center"}}>
                                 <Button buttontype="blue" onClick={props.cancel}>Zatvori</Button>
                             </div>
                         }
        >
            <p style={{margin: "0", fontSize: "20px", fontWeight: "bold", textAlign: "center"}}>Zahtev je uspešno poslat!</p>
            <p style={{textAlign: "center", fontSize: "14px", lineHeight: "1.7"}}>
                <br/>
                <br/>
                <br/>
                Očekuj odgovor našeg farmaceuta!
            </p>
        </ModalWithHeader>
    )
}

export default SuccessSearchInputModal