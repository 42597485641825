import React from "react";
import {ReactComponent as XIcon} from "../../../assets/icons/closeModalIcon.svg";

const FilterBreadcrumbs = (props) => {
    return (
        <div style={{margin:"16px 32px 8px", display:"flex", justifyContent:"center", alignItems:"center"}}>Izabrana kategorija:
            <span> {props.children}</span> &nbsp;
            <XIcon onClick={props.onResetClick} style={{stroke:"#00B1B0", cursor:"pointer"}}/>
        </div>
    );
}

export default FilterBreadcrumbs;
