import React from 'react'
import configData from "../../../../config.json";
import AppleBadge from "../../../../assets/download-on-the-app-store-badge.png";
import PlayStoreBadge from "../../../../assets/google-play-badge.png";

const AppButton =(props)=> {

    return(
            <a href={props.apple ? configData.AppStoreLink : configData.PlayStoreLink} target={"_blank"}>
                <img
                    alt={props.apple ? 'Preuzmite za iPhone' : 'Preuzmite za Android'}
                    style={props.style}
                    src={props.apple ? AppleBadge : PlayStoreBadge}/>
            </a>
    )
}

export default AppButton