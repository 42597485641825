import React, {useEffect, useState} from 'react';
import FilterModal from "../../../UI/Modals/FilterModal/FilterModal";
import styles from './StoreFilter.module.css'
import * as actions from "../../../../store/actions";
import {connect} from "react-redux";
import {ArrowUpIcon} from "@heroicons/react/solid";
import Button from '../../../UI/Buttons/Button/Button'
// import Switch from "react-switch";
import {withRouter} from "react-router-dom";
import {useForm} from "react-hook-form";
import Input from "../../../UI/Input/Input";


const StoreFilter = (props) => {

    const filerList = [
        {storageKey: 'storeFilter_haveRFZO', title: 'Rezervacija RFZO terapije'},
        {storageKey: 'storeFilter_isClickCollect', title: 'U sistemu poruči i preuzmi'},
        {storageKey: 'storeFilter_haveDelivery', title: 'Isporuka na adresu'},
        {storageKey: 'storeFilter_openingHours', title: 'Otvoreno 00-24'},
        {storageKey: 'storeFilter_hasDiscount', title: 'Apoteka ima artikle na sniženju'}
    ];

    const [chosenFilter, chosenFilterSetter] = useState({
        storeFilter_isClickCollect: !!localStorage.getItem('storeFilter_isClickCollect') || false,
        storeFilter_haveDelivery: !!localStorage.getItem('storeFilter_haveDelivery') || false,
        storeFilter_haveRFZO: !!localStorage.getItem('storeFilter_haveRFZO') || false,
        storeFilter_openingHours: !!localStorage.getItem('storeFilter_openingHours') || false,
        storeFilter_hasDiscount: !!localStorage.getItem('storeFilter_hasDiscount') || false
    })


    // addressSelectedHandler(()=> {
    //     const selectedTemp = localStorage.getItem('addressSelected');
    //     const initialValue = JSON.parse(selectedTemp);
    //     return initialValue || false;
    // })

    const [selectedCheckboxes, selectedCheckboxesSetter] = useState([]);
    useEffect(()=>{
        console.log(selectedCheckboxes)
    }, [selectedCheckboxes])


    const selectedCheckbox = (args) => {
        selectedCheckboxesSetter(selectedCheckboxes => [...selectedCheckboxes, args]);

        switch (args) {
            case 'storeFilter_isClickCollect':
                chosenFilterSetter({
                    ...chosenFilter,
                    storeFilter_isClickCollect: !chosenFilter.storeFilter_isClickCollect
                });
                break;
            case 'storeFilter_haveDelivery':
                chosenFilterSetter(
                    {...chosenFilter, storeFilter_haveDelivery: !chosenFilter.storeFilter_haveDelivery}
                )
                break;
            case 'storeFilter_haveRFZO':
                chosenFilterSetter(
                    {...chosenFilter, storeFilter_haveRFZO: !chosenFilter.storeFilter_haveRFZO}
                )
                break;
            case 'storeFilter_openingHours':
                chosenFilterSetter(
                    {...chosenFilter, storeFilter_openingHours: !chosenFilter.storeFilter_openingHours}
                )
                break;
            case 'storeFilter_hasDiscount':
                chosenFilterSetter(
                    {...chosenFilter, storeFilter_hasDiscount: !chosenFilter.storeFilter_hasDiscount}
                )
                break;
            default:
                chosenFilterSetter(
                    {...chosenFilter}
                )
        }
    }

    const filterHandler = (filter) => {
        switch (filter.toString()) {
            case 'storeFilter_isClickCollect':
                chosenFilter.storeFilter_isClickCollect ? localStorage.setItem('storeFilter_isClickCollect', 'true') : localStorage.removeItem('storeFilter_isClickCollect');
                break;
            case 'storeFilter_haveDelivery':
                chosenFilter.storeFilter_haveDelivery ? localStorage.setItem('storeFilter_haveDelivery', 'true') : localStorage.removeItem('storeFilter_haveDelivery')
                break;
            case 'storeFilter_haveRFZO':
                chosenFilter.storeFilter_haveRFZO ? localStorage.setItem('storeFilter_haveRFZO', 'true') : localStorage.removeItem('storeFilter_haveRFZO');
                break;
            case 'storeFilter_openingHours':
                chosenFilter.storeFilter_openingHours ? localStorage.setItem('storeFilter_openingHours', 'true') : localStorage.removeItem('storeFilter_openingHours')
                break;
            case 'storeFilter_hasDiscount':
                chosenFilter.storeFilter_hasDiscount ? localStorage.setItem('storeFilter_hasDiscount', 'true') : localStorage.removeItem('storeFilter_hasDiscount')
                break;
            default :
                alert("Došlo je do greške. Molimo Vas, osvežite stranicu");
        }
    }

    const [applyFilters, applyFiltersHandler] = useState(false)
    useEffect(() => {
        if(applyFilters) {
            for (const argument of selectedCheckboxes) {
                filterHandler(argument)

                let keys = Object.keys(chosenFilter);
                let inMyRadius = false;

                let filtered = keys.filter(function (key) {
                    return chosenFilter[key];
                })

                filtered = filtered.map(filter => {
                        return filter.split('_')[1]
                    }
                )

                props.onStoreFilterSelection({inMyRadius: inMyRadius, filters: filtered});
            }
            applyFiltersHandler(false);
            props.backdropClickHandler();
        }
    }, [applyFilters])

    const check = (key) => {
        switch (key) {
            case 'storeFilter_isClickCollect':
                return (chosenFilter.storeFilter_isClickCollect);
            case 'storeFilter_haveDelivery':
                return (chosenFilter.storeFilter_haveDelivery);
            case 'storeFilter_haveRFZO':
                return (chosenFilter.storeFilter_haveRFZO);
            case 'storeFilter_openingHours':
                return (chosenFilter.storeFilter_openingHours);
            case 'storeFilter_hasDiscount':
                return (chosenFilter.storeFilter_hasDiscount);
            default:
                return false;
        }
    }

    const {register, handleSubmit, formState: {errors}} = useForm();
    // const onSubmit = data => ;
    console.log(errors);


    let subCategoryFilterList = filerList.map((filter, index) => {

        if(window.location.pathname.includes('articles-and-stores') && filter.storageKey === 'storeFilter_hasDiscount') {
            return
        }

        return (
            <div key={index}
                 className={styles.SubCategoryWrapper}
            >
                <label>
                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        alignItems: "center"
                    }}>
                        <Input inputtype="checkbox" type="checkbox"
                               style={{
                                   opacity: "1",
                                   position: "relative",
                                   width: "20px",
                                   float: "left",
                                   padding: "0",
                                   margin: "-.1rem 0 0 -.5rem",
                                   transform: "scale(1.4)"
                               }}
                               checked={check(filter.storageKey)}
                               onChange={() => selectedCheckbox(filter.storageKey)}
                        />
                        <span>&nbsp;{filter.title}</span>
                    </div>
                </label>
            </div>
        )
    })

    const subCategoryList =
        <>
            <span className={styles.SubCategoryTitle} style={{borderBottom:"1px solid #e2e8ecff"}}>Izaberite filtere</span> {/*izabrani root category*/}
            <div className={styles.SubCategoryList}>
                {subCategoryFilterList}
            </div>
        </>

    return (
        <FilterModal classNameContent={styles.StoreFilter}
                     show={props.show}
                     backdropClickHandler={props.backdropClickHandler}>
            <div style={{position: "absolute"}}><ArrowUpIcon/></div>
            {/*<div className={styles.RootCategoryWrapper}>{rootCategoryList}</div>*/}
            <div className={styles.SubCategoriesWrapper} style={{borderBottom:"1px solid #e2e8ecff"}}>{subCategoryList}</div>
            <div style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "70px",
                boxSizing: "border-box"
            }}>
                <Button buttontype="whitedarkblue" style={{
                    width: "60%",
                    display: "flex",
                    justifyContent: "center",
                    fontWeight: "800",
                    position: "relative"
                }}
                        onClick={()=>{applyFiltersHandler(true)}}
                >Primeni filtere</Button>
            </div>
        </FilterModal>
    );
}

const mapStateToProps = (state) => {
    return {
        storeFilter: state.filter.storeFilter,

        loadingUserLocation: state.appPage.loadingUserLocation,
        userLocationError: state.appPage.userLocationError,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onStoreFilterSelection: (storeFilter) => dispatch(actions.storeFilterSelection(storeFilter)),

        onFetchUserLocationStart: () => dispatch(actions.fetchUserLocationStart()),
        onFetchUserLocationError: () => dispatch(actions.fetchUserLocationError()),
        onFetchUserLocationFinished: () => dispatch(actions.fetchUserLocationFinished()),

        onFetchStores: ({page, filter, latitude, longitude, distance}) => dispatch(actions.fetchStores({
            page,
            filter,
            latitude,
            longitude,
            distance
        })),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(StoreFilter));