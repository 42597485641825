import * as actionsTypes from '../actions/actionsTypes'

const initialState = {
    allOrders: null,
    loadingAllOrders: false,
    allOrdersFailed: false,

    allTransactions: null,
    loadingAllTransactions: false,
    allTransactionsFailed: false,

    singleOrder: null,
    loadingSingleOrder: false,
    singleOrderFailed: false,

    ratingParent: null
}


const ordersAndTransactionsReducer = (state = initialState, action) => {
    switch (action.type) {

        case actionsTypes.FETCH_ALL_ORDERS:
            return {
                ...state,
                allOrders: action.allOrders,
                loadingAllOrders: false,
                allOrdersFailed: false,
            }

        case actionsTypes.FETCH_ALL_ORDERS_START:
            return {
                ...state,
                loadingAllOrders: true,
                allOrdersFailed: false
            }
        case actionsTypes.FETCH_ALL_ORDERS_FAILED:
            return {
                ...state,
                allOrdersFailed: true,
                loadingAllOrders: false
            }

        // case actionsTypes.ORDER_ACKNOWLEDGED:
        //     return {
        //         ...state,
        //         allOrders: action.ackMessage !== null && state.allOrders.map(order => {
        //             if (order.id === +(action.ackMessage.orderNumber.substring(1))){
        //                 return {...order, willBeReadyIn: action.ackMessage.willBeReadyIn, processed: 1}
        //             }
        //             return order
        //         })
        //     }

        case actionsTypes.FETCH_ALL_TRANSACTIONS:
            return {
                ...state,
                allTransactions: action.allTransactions,
                loadingAllTransactions: false,
                allTransactionsFailed: false
            }

        case actionsTypes.FETCH_ALL_TRANSACTIONS_START:
            return {
                ...state,
                loadingAllTransactions: true,
                allTransactionsFailed: false
            }

        case actionsTypes.FETCH_ALL_TRANSACTIONS_FAILED:
            return {
                ...state,
                loadingAllTransactions: false,
                allTransactionsFailed: true
            }

        case actionsTypes.FETCH_SINGLE_ORDER:
            return {
                ...state,
                singleOrder: action.singleOrder,
                loadingSingleOrder: false,
                singleOrderFailed: false,
            }

        case actionsTypes.FETCH_SINGLE_ORDER_START:
            return {
                ...state,
                loadingSingleOrder: true,
                singleOrderFailed: false
            }
        case actionsTypes.FETCH_SINGLE_ORDER_FAILED:
            return {
                ...state,
                singleOrderFailed: true,
                loadingSingleOrder: false
            }

        case actionsTypes.RATE_TRANSACTION:
            let tempTransactions = [...state.allTransactions]

            tempTransactions = tempTransactions.map(t => {
                if (t.rating && t.rating.id === action.ratingParent.ratingId) {
                    t.rating.rating = action.ratingParent.rating;
                    t.rating.ratingComment = action.ratingParent.ratingComment;
                }
                return t;
            })

            return {
                ...state,
                allTransactions: tempTransactions
            }

        case actionsTypes.ORDER_CHANGED_STATUS:
            return {
                ...state,
                allOrders: state.allOrders.map(order => {
                    if(order.id === action.orderInfo.content.cncOrderId) {
                        if (action.orderInfo.type === "ORDER_CANCELED") {
                            return {...order, status: action.orderInfo.content.orderStatus,
                                willBeReadyIn: action.orderInfo.willBeReadyIn, noteForCanceledStatus: action.orderInfo.content.note}
                        }
                        if (action.orderInfo.type === "ORDER_REJECTED" || action.orderInfo.type === "ORDER_ACK") {
                            return {...order, status: action.orderInfo.content.orderStatus,
                                willBeReadyIn: action.orderInfo.willBeReadyIn, noteForUser: action.orderInfo.content.note}
                        }
                        return {...order, status: action.orderInfo.content.orderStatus,
                            willBeReadyIn: action.orderInfo.willBeReadyIn}
                    }
                    return order
                })
            }
        default:
            return state;
    }
}

export default ordersAndTransactionsReducer;
