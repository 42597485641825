import React, {useEffect, useState} from 'react';
import styles from './ShopArticleSort.module.css'
import * as actions from "../../../../store/actions";
import {connect} from "react-redux";
import {ChevronDownIcon} from "@heroicons/react/outline";

const ShopArticleSort = (props) => {

    const [selectedOption, setSelectedOption] = useState('');

    useEffect(()=>{
        if(selectedOption !== '')
        props.onSortShopArticles(selectedOption)
    }, [selectedOption])

    const handleOptionChange = (event) => {
        props.isLogedin ?
        setSelectedOption(event.target.value)
            : props.badActionClicked();
    };

    return (
        <div className={styles.SelectHolder} >
            <select className={styles.SelectStyle}
                    id="selectOption"
                    value={selectedOption}
                    onChange={handleOptionChange}
            >
                <option value="0">Podrazumevano</option>
                <option value="asc">Po ceni: rastuće</option>
                <option value="desc">Po ceni: opadajuće</option>
            </select>
            <ChevronDownIcon width='16px'/>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        isLogedin: state.authPage.token !== null,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        onSortShopArticles: (value) => dispatch(actions.sortShopArticles(value))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ShopArticleSort);