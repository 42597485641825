import React, {useEffect, useState} from 'react'
import styles from './AddressSelection.module.css'
import Button from "../../../UI/Buttons/Button/Button";
import * as actions from "../../../../store/actions";
import {connect} from "react-redux";
import { withRouter } from 'react-router-dom';
import AddAddressForm from "../../../UI/AddAddressForm/AddAddressForm";
import Backdrop from "../../../UI/Backdrop/Backdrop";
import OrderCompletionPageTemplate from "../OrderCompletionPageTemplate/OrderCompletionPageTemplate";
import {PlusCircleIcon} from "@heroicons/react/solid";
import ModalWithHeader from "../../../UI/Modals/ModalWithHeader/ModalWithHeader";
import {ChevronLeftIcon, ChevronRightIcon} from "@heroicons/react/outline";
import TotalAmount from "../TotalAmount/TotalAmount";
import Actions from "../Actions/Actions";
import EditNameAndSurname from "./EnterNameAndSurname/EditNameAndSurname";
import EnterPhoneNumber from "./EnterPhoneNumber/EnterPhoneNumber";
import AlertModal from "../4-SummaryAndConfirmation/AlertModal/AlertModal";
// import PharmacyData from "../PharmacyData/PharmacyData";

const AddressSelection = (props) => {

    const [addressSelected, addressSelectedHandler] = useState(()=> {
        const selectedTemp = localStorage.getItem('addressSelected');
        const initialValue = JSON.parse(selectedTemp);
        return initialValue || false
    });

    const [selectedAddressId, selectedAddressIdHandler] = useState(localStorage.getItem('selectedAddress') || '');

    const [isModalVisible, isModalVisibleHandler] = useState(false);
    const [showAlertModal, showAlertModalSetter] = useState(false);

    const [tooltipFinishText, setTooltipFinishText] = useState("");

    let userAddresses;

    const [triggerEnterNameAndSurnameComponent, triggerEnterNameAndSurnameComponentSetter] = useState(false)

    useEffect(()=>{
        selectedAddressIdHandler(localStorage.getItem('selectedAddress'));
        addressSelectedHandler(()=> {
            const selectedTemp = localStorage.getItem('addressSelected');
            const initialValue = JSON.parse(selectedTemp);
            return initialValue || false;
        })

        return()=> {
            localStorage.removeItem('saveNameToProfile');
        }
    },[])

    useEffect(()=>{
        if(window.pageYOffset !== 0) {
            window.scrollTo(0,0);
        }
    }, [])

    useEffect(()=>{
        props.collectOrDelivery.delivery && localStorage.setItem('addressSelected', JSON.stringify(addressSelected));
    }, [addressSelected])

    useEffect(()=>{
        if(props.userProfile === null) {
            props.onFetchUserProfile(localStorage.getItem('email'));
        }
    }, [])

    useEffect(() => {
        if(localStorage.getItem('deliveryMethod') === 'deliveryToAddress' ) {
            if (props.userProfile !== null && props.userProfile.userAddresses.length === 1) {
                selectAddress(props.userProfile.userAddresses[0].id)
            }
        }

        if(props.userProfile && props.userProfile.phoneNumber !== null && !localStorage.getItem('phoneNumber')) {
            localStorage.setItem('phoneNumber', props.userProfile.phoneNumber)
        }
    },[props.userProfile])

    const selectAddress = (addressId) => {
        addressSelectedHandler(true);
        localStorage.setItem('selectedAddress', addressId);
        selectedAddressIdHandler(addressId);
    }

    const chooseAddressMobile = (addressId) => {
        selectAddress(addressId);
        redirectToSummary();
    }

    if(props.userProfile !== null) {
        userAddresses = props.userProfile.userAddresses.map((address) => {
            return (
                    <div key={address.id}
                         className={styles.AddressesList}
                         onClick={()=>{
                             if(window.innerWidth > 699) {
                                 selectAddress(address.id)
                             } else {
                                 chooseAddressMobile(address.id)
                             }
                         }}
                    >
                        <div className={!addressSelected ? styles.AddressesListRadioButtonWrapper : (+selectedAddressId === address.id ? [styles.AddressesListRadioButtonWrapper, styles.AddressesListRadioButtonWrapperSelected].join(' ') : styles.AddressesListRadioButtonWrapper)}>
                            <div className={!addressSelected ? styles.AddressesListRadioButton : (+selectedAddressId === address.id ? [styles.AddressesListRadioButton, styles.AddressesListRadioButtonSelected].join(' ') : styles.AddressesListRadioButton)}></div>
                        </div>
                        <div>{address.name} {address.number} </div>
                        <div>{address.apartment !== "null" && address.apartment !== null ? 'stan: '+ address.apartment : null} {address.floor && address.floor !== 'null' ? 'sprat: ' + address.floor : null}</div>
                        <div>{address.postalCode} {address.region}, {address.city}</div>
                    </div>
            )
        })

        if(userAddresses.length === 0) {
            userAddresses =
            <>
                <p style={{color: "#535358"}}>Nema sačuvanih adresa.</p>
                <p style={{color: "#535358"}}>Da bismo mogli da Vam pošaljemo proizvode, morate uneti adresu klikom na dugme <strong>Dodaj novu adresu</strong></p>
            </>
        }
    }

    const openModal = () => {
        isModalVisibleHandler(true);
    }

    const closeModal = () => {
        isModalVisibleHandler(false);
    }

    const openAlertModal = () => {
        showAlertModalSetter(true)
    }

    const closeAlertModal = () => {
        showAlertModalSetter(false)
    }

    //snimanje nove adrese
    const saveAddress = (formData) => {
        closeModal();
        const userRequest = {
            username: localStorage.getItem("email"),
            name: formData.addressData.streetName,
            number: formData.addressData.number,
            region: formData.addressData.region,
            city: formData.addressData.city,
            postalCode: formData.addressData.postalCode,
            floor: formData.addressData.floor === '' ? null : formData.addressData.floor,
            apartment: formData.addressData.apartment === '' ? null : formData.addressData.apartment
        }
        props.onAddNewUserAddress(userRequest);
    }

    const isDisabled = () => {
        //ako nema broja telefona
        // if (!localStorage.getItem('phoneNumber')) {
        //     setTooltipFinishText("Telefon je obavezan");
        //     return true
        // //ako je isporuka na adresu, a adresa ne postoji, ili nije selektovana
        // } else if(((props.userProfile !== null && +props.userProfile.userAddresses.length === 0) || !addressSelected) && props.collectOrDelivery.delivery) {
        //     setTooltipFinishText("Morate izabrati adresu");
        //     return true
        // //ako nije uneseno ime, odnosno prezime
        // } else if (!localStorage.getItem('firstName') || !localStorage.getItem('secondName')){
        //     setTooltipFinishText("A ime?");
        //     return true
        if (!localStorage.getItem('phoneNumber')
            || (((props.userProfile !== null && +props.userProfile.userAddresses.length === 0) || !addressSelected) && props.collectOrDelivery.delivery)
            || !localStorage.getItem('firstNameForOrder')
            || !localStorage.getItem('secondNameForOrder')) {
            props.collectOrDelivery.delivery
                ? setTooltipFinishText("Da biste nastavili dalje morate uneti ime, prezime i telefon, i izabrati adresu za osporuku")
                : setTooltipFinishText("Da biste nastavili dalje morate uneti ime, prezime i telefon")
            return true
        } else {
            return false;
        }
    }

    const redirectToSummary = () => {
        if (isDisabled()) {
            openAlertModal();
        } else {
            triggerEnterNameAndSurnameComponentSetter(true)

//TODO srediti da nema timeout-a
            setTimeout(() => {
                const branchMarketplaceId = window.location.pathname.split('/')[2];
                const marketplaceId = window.location.pathname.split('/')[3];
                const url = '/orderCompletion/' + branchMarketplaceId + "/" + marketplaceId + '/' + 'confirmation'
                    + '/' + props.match.params.branchMarketplaceName + '/' + props.match.params.marketplaceName;
                // props.history.replace('/')
                props.history.push(url);
            }, 500)

        }
    }

    const redirectToPreviousPage =()=> {
        localStorage.removeItem('selectedAddress')
        localStorage.removeItem('addressSelected')
        const url = '/orderCompletion/' + props.match.params.branchMarketplaceId + "/" + props.match.params.marketplaceId + '/delivery'
            + '/' + props.match.params.branchMarketplaceName + '/' + props.match.params.marketplaceName;
        props.history.push(url);
    }

    return (
        <>
            <OrderCompletionPageTemplate
                topPart={
                    <Actions>
                        <Button style={{fontSize:"20px"}}
                                onClick={redirectToPreviousPage}><ChevronLeftIcon
                            style={{width: "20px", marginRight: "1rem"}}/> Nazad</Button>
                        <Button
                            // onClick={addressSelected ? redirectToSummary : null}
                            onClick={redirectToSummary}
                            buttontype='blue'
                            style={{width:"170px", height:"60px", fontSize:"20px", padding: "0 2rem"}}
                        >Dalje <ChevronRightIcon style={{width: "20px", marginLeft: "1rem"}}/></Button>
                    </Actions>
                }

                leftPart={
        <>
            <div style={{fontSize: "24px", paddingBottom:'1.5rem', borderBottom:"1px solid #e2e8ecff"}}>Korisnički podaci</div>
            <EditNameAndSurname
                triggerEnterNameAndSurnameComponent={triggerEnterNameAndSurnameComponent}
            />
            <br/>
            <br/>
            <EnterPhoneNumber />
            <br/>

            {props.collectOrDelivery.delivery &&
                <div style={{height: "100%", display: "flex", flexDirection: "column"}}>
                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "flex-start",
                        width: "100%",
                        marginBottom: "2rem"
                    }}>
                        <div style={{fontWeight: "bold"}}>Izaberite adresu za dostavu</div>
                    </div>
                    <br/>
                    <div className={styles.UserAddressesWrapper}>
                        {userAddresses}
                        <div className={styles.AddressesList} style={{padding: "0"}}>
                            <Button style={{width: "100%", height: "100%", justifyContent: "center"}}
                                    onClick={openModal}><PlusCircleIcon
                                style={{height: "42px", width: "42px", color: "#00B2B1"}}/></Button>
                        </div>
                    </div>


                    {isModalVisible ?
                        <ModalWithHeader onCloseClick={closeModal}
                                         contentStyle={{width: "100%"}}
                        >
                            <AddAddressForm saveAddress={saveAddress}/>
                        </ModalWithHeader>
                        :
                        null
                    }
                    <Backdrop show={isModalVisible}
                              backdropClickHandler={closeModal}
                              zIndex={15}
                              backgroundColor="rgba(0, 0, 0, .2)"/>
                </div>
            }
        </>

                }

                rightPart={
                    <>
                        <div style={{display: "flex", flexDirection: "column", height: "100%", width: "100%"}}>

                            {/*<div>*/}
                            {/*    <PharmacyData />*/}
                            {/*</div>*/}
                            <TotalAmount
                                totalState={(+(Math.round(localStorage.getItem('totalPrice') + "e+2") + "e-2")).toFixed(2)}/>
                            <div style={{fontSize: "24px"}}>Način isporuke:</div>
                            {props.collectOrDelivery.delivery ?
                                <div style={{fontSize: "20px", color: "black"}}>Isporuka na adresu</div> :
                                <div style={{fontSize: "20px", color: "black"}}>Preuzimanje u apoteci</div>
                            }
                        </div>
                    </>
                }
            />
            {showAlertModal &&
                <AlertModal onCloseClick={closeAlertModal}
                            backdropClickHandler={closeAlertModal}
                            show={showAlertModal}
                            messageText={tooltipFinishText}
                />
            }
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        userProfile: state.userProfile.userProfile,
        userProfileError: state.userProfile.userProfileError,
        loadingUserProfile: state.userProfile.loadingUserProfile,
        collectOrDelivery: state.orderCompletion.collectOrDelivery,

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onFetchUserProfile: (email) => dispatch(actions.fetchUserProfile(email)),
        onAddNewUserAddress: (userAddress) => dispatch(actions.addNewUserAddress(userAddress)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AddressSelection))