import React from 'react';
import ModalWithHeader from "../../../../../UI/Modals/ModalWithHeader/ModalWithHeader";
import {withRouter} from "react-router-dom";
import {BanIcon} from "@heroicons/react/outline";
import Button from "../../../../../UI/Buttons/Button/Button";
import * as actions from "../../../../../../store/actions";
import {connect} from "react-redux";
import {ReactComponent as NoPharmaciesInCNC} from "../../../../../../assets/icons/nema_porucivanja_img.svg";
import {textToUrl} from "../../../../../GobalFunctions/GlobalFunctions";

const StoreItemModal = (props) => {

    const enterShop = () => {
        props.history.push('/storeinfo/' + props.storeItem.marketplaceResponse.branchMarketplaceId + '/' + props.storeItem.marketplaceResponse.marketplaceId + '/' + textToUrl(props.storeItem.branchMarketplaceResponse.name) + '/' + textToUrl(props.storeItem.marketplaceResponse.name));
        props.onGetStoreItemModalData({isOpen: false, storeItem: null})
    }

    return (
            <ModalWithHeader
                show={props.onOpen}
                backdropClickHandler={props.onCloseClick}
                onCloseClick={props.onCloseClick}
                style={{height: "500px", width: "400px"}}
                icon={<BanIcon style={{width: "48px", height: "48px"}}/>}
            >
                <div style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    height: "100%"
                }}>
                    <p>Opcija <strong><i>Poruči i preuzmi</i></strong></p>
                    <div><strong>nije dostupna</strong> u apoteci</div>
                    <p style={{fontSize: "18px", fontWeight: "bold"}}>{props.storeItem.marketplaceResponse.name}</p>
                    <NoPharmaciesInCNC style={{margin: "0 auto"}}/>
                    <p>  Rezervacija je moguća pozivom na telefon navedene apoteke, koji je dostupan na profilnoj strani apoteke.</p>
                    <div style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "auto",
                        width: "100%"
                    }}>
                        <Button onClick={props.onCloseClick}>Zatvori</Button>
                        <Button buttontype="blue" onClick={enterShop}>Poseti profilnu stranu apoteke</Button>
                    </div>
                </div>
            </ModalWithHeader>
    )
}

const mapStateToProps = (state) => {
    return {
        storeItemModal: state.appPage.storeItemModal
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onGetStoreItemModalData: (storeItem) => dispatch(actions.getStoreItemModalData(storeItem))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(StoreItemModal));