import React from 'react';
import styles from "./ConfirmationModal.module.css";
import Backdrop from "../../Backdrop/Backdrop";
import Button from "../../Buttons/Button/Button";

const ConfirmationModal = (props) => {
    return (
        <>
            <div className={`${styles.ConfirmationModal} ${props.className}`} style={props.style}>
                <div className={styles.Header} style={{backgroundColor: props.backgroundColor}}/>
                <div className={styles.Content} style={props.contentStyle}>
                    <div>{props.text}</div>
                    <div className={styles.ActionWrapper}>
                        {props.denyButton &&
                            <Button onClick={props.denyButton} buttontype='whiteblue'>Ne</Button>}
                        {props.confirmButton &&
                            <Button onClick={props.confirmButton} buttontype='blue'>Da</Button>}
                    </div>
                </div>
            </div>
            {!props.noBackdrop &&
                <Backdrop show={props.show}
                          backdropClickHandler={props.backdropClickHandler}
                          zIndex={30}
                          backgroundColor="rgba(0, 0, 0, .2)"/>
            }
        </>
    );
};

export default ConfirmationModal;