import React, {useEffect, useState} from 'react';
import './App.css';
import Layout from '../components/Layout/Layout'
import MainPage from '../components/MainPage/MainPage'
import MasterArtikalCMS from "../components/CMSPage/MasterArtikalCMS/MasterArtikalCMS";
import ProdajnoMestoCMS from "../components/CMSPage/ProdajnoMestoCMS/ProdajnoMestoCMS";
import CMSPage from "../components/CMSPage/CMSPage"
import {connect} from 'react-redux'
import * as actions from '../store/actions/index'


import {Route, Switch, Redirect, withRouter} from 'react-router-dom';
import ArticleInfoPage from "../components/ArticleInfoPage/ArticleInfoPage";
import AuthPage from "../components/AuthPage/AuthPage";
import SearchResultsPage from '../components/SearchResultsPage/SearchResultsPage'
import SearchArticlesResultsPage from '../components/SearchArticlesResultsPage/SearchArticlesResultsPage'
import ResetPasswordPane from "../components/AuthPage/ResetPasswordPane/ResetPasswordPane";
import ForgotPasswordPane from "../components/AuthPage/ForgotPasswordPane/ForgotPasswordPane";
import MyProfilePage from "../components/MyProfilePage/MyProfilePage";
import ShopPage from "../components/ShopPage/ShopPage";
import ValidateRegistrationPane from "../components/AuthPage/ValidateRegistrationPane/ValidateRegistrationPane";
import OrderCompletionPage from "../components/ShoppingCart/OrderCompletionPage/OrderCompletionPage";
import ErrorPage from "../components/ErrorPage/ErrorPage";
import StorePage from "../components/StorePage/StorePage";
import GoodbyePage from "../components/GoodbyePage/GoodbyePage";
import TermsOfService from "../components/LegalDocuments/TermsOfService/TermsOfService";
import PrivacyPolicy from "../components/LegalDocuments/PrivacyPolicy/PrivacyPolicy";
// import VideoCall from "../components/VideoCall/VideoCallFunction/VideoCall";
import VideoCallPage from "../components/VideoCall/VideoCallPage";
import PrivacyPolicyMobile from "../components/LegalDocuments/PrivacyPolicy/PrivacyPolicyMobile";
import TermsOfServiceMobile from "../components/LegalDocuments/TermsOfService/TermsOfServiceMobile";
import MisljenjeMinistarstva from './../components/LegalDocuments/MisljenjeMinistarstva/MisljenjeMinistarstva'
import CookieBot from "react-cookiebot";
import ComingSoon from "../components/Informations/ComingSoon/ComingSoon";
import LoyaltyProgramPage from "../components/SimplePages/LoyaltyProgramPage/LoyaltyProgramPage";
import RFZOpage from "../components/RFZOPage/RFZOpage";
import LoyaltyProgramMobile from "../components/SimplePages/LoyaltyProgramPage/LoyaltyProgramMobile";
import "../index.css"
import "../fonts/FontsFree-Net-DS-DIGI-2.ttf"
import AboutUs from "../components/Informations/AboutUs/AboutUs";
import LandingPageForPromo from '../components/LandingPageForPromo/LandingPageForPromo';

const domainGroupId = '1a4688bf-a5ed-4363-89b3-a04298383f0e'

export const redirectToError = (props) => {
    const url = '/error';
    props.history.replace(url);
}

const App = (props) => {

    const [xx, setXX] = useState(false)

    useEffect(() => {
        props.onTryAutoSignup();

        if(props.isLogedin) {
            props.onCheckAllRFZOTherapies(localStorage.getItem('id'));
        }
    }, []);


    useEffect(()=>{
        if(props.rfzoTherapy !== null) {
            setXX(true)
            let url = '/rfzo-therapy/' + props.rfzoTherapy.marketplaceResponse.branchMarketplaceId + '/' + props.rfzoTherapy.marketplaceResponse.marketplaceId;
            props.history.push(url)
        } else {
            setXX(false);
        }
    },[props.rfzoTherapy])

    const [cookieBotFlag, cookieBotFlagSetter] = useState(false);

    // pokusaj da se cookieBot ne renderuje dva puta
    const [cookieBot, cookieBotSetter] = useState(null)
    useEffect(()=>{
        cookieBotSetter(cookieBotHandler())
    }, [cookieBotFlag])

    //useEffect za fetch rating-a koji nije ocenjen
    useEffect(() => {

        if (props.userInfo !== null && props.isLogedin) {
            let isApotekaUser = false;
            localStorage.getItem("roles").split(",").map(role => {
                if (role === 'APOTEKA_USER') isApotekaUser = true;
            });

            if (isApotekaUser) {
                props.onFetchRating(props.userInfo.id);
            }
        }
    }, [props.userInfo])

    const cookieBotHandler = () => {
        if (!cookieBotFlag) {
            cookieBotFlagSetter(true)
            return <CookieBot domainGroupId={domainGroupId} />
        }
    }

    // useEffect(() => {
    //     if (props.isLogedin && props.userProfile === null && localStorage.getItem('email') !== null && localStorage.getItem('token') !== null) {
    //         props.onFetchUserProfile(localStorage.getItem('email'));
    //     }
    //     if (!props.isLogedin){
    //         props.onUserProfile(null);
    //     }
    // }, [props.isLogedin])

    /**
     *  Ovo bi trebalo da na unosenje random stringa u url vraca error page.
     *  Problem je sto i na reload strane vraca error.
     * */
        // useEffect(()=>{
        //     if(props.match.url !== window.location.pathname) {
        //         let url = '/error';
        //         props.history.push(url);
        //     }
        // }, [])

    let routes = (
            <Switch>
                <Route path="/auth/passwordReset" component={ForgotPasswordPane}/>
                <Route path="/auth/forget_password/:email/:token" component={ResetPasswordPane}/>
                <Route path="/auth/validate_registration/:email/:token" component={ValidateRegistrationPane}/>
                <Route path="/auth" component={AuthPage}/>

                {/* <Route path="/searched-stores/:page/:searchedString" component={SearchResultsPage}/> */}
                {/* <Route path="/searched-stores/:page" component={SearchResultsPage}/> */}

                {/* <Route path="/searched-articles/:page/:searchedString" component={SearchArticlesResultsPage}/> */}
                {/* <Route path="/searched-articles/:page" component={SearchArticlesResultsPage}/> */}
                {/* <Route path="/unknown-item/:searchedString" component={SearchArticlesResultsPage} /> */}

                {/* <Route path="/PrivacyPolicy" component={PrivacyPolicy}/> */}
                {/* <Route path="/TermsOfService" component={TermsOfService}/> */}
                <Route path="/misljenje-ministarstva-zdravlja" component={MisljenjeMinistarstva}/>

                <Route path="/AboutUs" component={ComingSoon}/>
                <Route path="/Marketing" component={ComingSoon}/>
                <Route path="/BecomeAPartner" component={ComingSoon}/>

                {/* <Route path="/articles-and-stores/:id/:page/:articleName" component={SearchResultsPage}/> */}

                {/* <Route path="/articles-info/:jkl/:id/:branchMarketplaceId/:marketplaceId/:articleName" component={ArticleInfoPage}/> */}
                {/* <Route path="/articles-info/:jkl/:id/:articleName" component={ArticleInfoPage}/> */}

                {/* <Route path="/shop/:branchMarketplaceId/:marketplaceId/:page/:searchedArticleId/:branchMarketplaceName/:marketplaceName" component={ShopPage}/> */}
                {/* <Route path="/shop/:branchMarketplaceId/:marketplaceId/:page/:branchMarketplaceName/:marketplaceName" component={ShopPage}/> */}
                <Route path="/error" component={ErrorPage}/>
                <Route path="/goodbye" component={GoodbyePage}/>
                {/* <Route path={"/storeinfo/:branchMarketplaceId/:marketplaceId/:branchMarketplaceName/:marketplaceName"} component={StorePage}/> */}
                <Route path="/aboutLoyalty" component={LoyaltyProgramPage}/>   
            {/* <Route path={"/rfzo-therapy/:branchMarketplaceId/:marketplaceId"} component={RFZOpage} />" */}

                <Route path="/" exact component={LandingPageForPromo}/>
                <Redirect to="/"/>
            </Switch>
        );
    if (props.isLogedin) {
        let isAdmin = false;
        localStorage.getItem("roles").split(",").map(role => {
            if (role === 'ADMIN') isAdmin = true;
        });
        isAdmin = true


        routes = (
    //         xx ?
    //             <Switch>
    //                 <Route path={"/rfzo-therapy/:branchMarketplaceId/:marketplaceId"} component={RFZOpage} />
    //                 {/*<Redirect to={"/rfzo-therapy/:branchMarketplaceId/:marketplaceId"} component={RFZOpage} />*/}
    //             </Switch>
    //             :
            <Switch>

               <Route path="/auth" component={AuthPage}/>
               <Route path="/auth/forget_password/:email/:token" component={ResetPasswordPane}/>
               <Route path="/auth" component={AuthPage}/>
               <Route path="/myprofile" component={MyProfilePage}/>

               {/* <Route path="/shoppingCart/:branchMarketplaceId/:marketplaceId" component={OrderCompletionPage}/> */}

               {/* <Route path="/searched-stores/:page/:searchedString" component={SearchResultsPage}/> */}
               {/* <Route path="/searched-stores/:page" component={SearchResultsPage}/> */}

               {/* <Route path="/searched-articles/:page/:searchedString" component={SearchArticlesResultsPage}/> */}
               {/* <Route path="/searched-articles/:page" component={SearchArticlesResultsPage}/> */}
               {/* <Route path="/unknown-item/:searchedString" component={SearchArticlesResultsPage} /> */}

               {/* <Route path="/PrivacyPolicy" component={PrivacyPolicy}/> */}
               {/* <Route path="/TermsOfService" component={TermsOfService}/> */}
               <Route path="/misljenje-ministarstva-zdravlja" component={MisljenjeMinistarstva}/>

               <Route path="/AboutUs" component={ComingSoon}/>
               <Route path="/Marketing" component={ComingSoon}/>
               <Route path="/BecomeAPartner" component={ComingSoon}/>

               {/* <Route path="/articles-and-stores/:id/:page/:articleName" component={SearchResultsPage}/> */}

               {/* <Route path="/articles-info/:jkl/:id/:branchMarketplaceId/:marketplaceId/:articleName/:branchMarketplaceName/:marketplaceName" component={ArticleInfoPage}/> */}
               {/* <Route path="/articles-info/:jkl/:id/:articleName" component={ArticleInfoPage}/> */}

               {/* <Route path="/shop/:branchMarketplaceId/:marketplaceId/:page/:searchedArticleId/:branchMarketplaceName/:marketplaceName" component={ShopPage}/> */}
               {/* <Route path="/shop/:branchMarketplaceId/:marketplaceId/:page/:branchMarketplaceName/:marketplaceName" component={ShopPage}/> */}

               {/* <Route path="/orderCompletion/:branchMarketplaceId/:marketplaceId" component={OrderCompletionPage}/> */}

               {/* <Route path="/video-call" component={VideoCallPage} /> */}

               {isAdmin && <Route path="/cms/master-artikal-cms/:id" component={MasterArtikalCMS}/>}
               {isAdmin && <Route path="/cms/prodajno-mesto-cms" component={ProdajnoMestoCMS}/>}
               {isAdmin && <Route path="/cms" component={CMSPage}/>}
               {/* <Route path="/error" component={ErrorPage}/> */}
               {/* <Route path="/goodbye" component={GoodbyePage}/> */}
               {/* <Route path="/" exact component={MainPage}/> */}

               {/* <Route path={"/storeinfo/:branchMarketplaceId/:marketplaceId/:branchMarketplaceName/:marketplaceName"} component={StorePage}/> */}

               {/* <Route path="/aboutLoyalty" component={LoyaltyProgramPage}/> */}

               {/* <Route path={"/rfzo-therapy/:branchMarketplaceId/:marketplaceId"} component={RFZOpage} /> */}

               <Redirect to="/cms"/>
            </Switch>
        );
    }

    let mobileRoutes = (
        <Switch>
            <Route path={"/m/PrivacyPolicy"} component={PrivacyPolicyMobile} />
            <Route path={"/m/TermsOfService"} component={TermsOfServiceMobile} />
            <Route path={"/m/misljenje-ministarstva-zdravlja"} component={MisljenjeMinistarstva}/>
            <Route path={"/m/aboutLoyalty"} component={LoyaltyProgramMobile}/>
            <Redirect to="/" />
        </Switch>
    );

    return (
        <div>
            {!window.location.pathname.includes('/m/') ?
                <Layout>
                    {routes}
                    {cookieBot}
                    {/*{cookieBot !== null && cookieBot}*/}
                </Layout>
                :
                <>
                    {mobileRoutes}
                </>
            }
        </div>
    );
}

const mapStateToProps = state => {
    return {
        isLogedin: localStorage.getItem("token") !== null,
        userInfo: state.authPage.userInfo,
        rfzoTherapy: state.rfzoTherapy.rfzoTherapy
        // userProfile: state.userProfile.userProfile
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onTryAutoSignup: () => dispatch(actions.loginCheckState()),
        onFetchRating: (userId) => dispatch(actions.fetchRating(userId)),
        // onFetchUserProfile: (email) => dispatch(actions.fetchUserProfile(email)),
        // onUserProfile: (userProfile) => dispatch(actions.userProfile(userProfile)),
        onCheckAllRFZOTherapies: (userId) => dispatch(actions.checkAllRFZOTherapies(userId))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));