import React, {Fragment, useEffect, useState} from "react";
import ActionMenu from "../../UI/ActionMenu/ActionMenu";
import styles from "../../SearchResultsPage/SearchResultsActionMenu/SearchResultsActionMenu.module.css";
import SearchBar from "../../MainPage/SearchWrapper/SearchBar/SearchBar";
import ShopFilter from "../../ShopPage/ShopPageActionMenu/ShopFilter/ShopFilter";
import ActionMenuRes from "../../../componentsRes/UIRes/ActionMenuRes/ActionMenuRes";
import Media from "react-media";
import {withRouter} from "react-router-dom";
import HeaderWithTitleAndBackChevron from "../../../componentsRes/HeaderWithTitleAndBackChevron/HeaderWithTitleAndBackChevron";
import InfoBox from "../../SearchResultsPage/SearchResultsHeader/InfoBox/InfoBox";
import {ReactComponent as FilterIcon} from "../../../assets/icons/filter_icon.svg";
import ArticleCategories from "../../Filters/ArticleCategories/ArticleCategories";
import ArticleCategoriesRes from "../../Filters/ArticleCategoriesRes/ArticleCategoriesRes";
import SquareBadge from "../../UI/SquareBadge/SquareBadge";
import TooltipComponent from "../../UI/TooltipComponent/TooltipComponent";
import {ReactComponent as LoyaltyFilterIcon} from "../../../assets/icons/loyalty-watch.svg";
import * as actions from "../../../store/actions";
import {connect} from "react-redux";

const SearchArticlesResultsPageActionMenu = (props) => {

    const [isFilterModalVisible, isFilterModalVisibleHandler] = useState(false);
    const [isArticleFilterSelected, isArticleFilterSelectedHandler] = useState(false);
    const [loyaltyFilter, setLoyaltyFilter] = useState(null);

    useEffect(() => {
        let shopFilter = localStorage.getItem('selectedArticleFilter');
        if(shopFilter === 'OTHER_ITEM' || shopFilter === null){
            isArticleFilterSelectedHandler(false)
        } else {
            isArticleFilterSelectedHandler(true)
        }

    }, [localStorage.getItem('selectedArticleFilter')])

    useEffect(() => {
        if (localStorage.getItem('articleFilter_loyalty')){
            props.onLoyaltyFilter(true)
            setLoyaltyFilter(true)
        }
    }, [])

    useEffect(() => {
        if (loyaltyFilter !== null) {
            if (loyaltyFilter) {
                props.onLoyaltyFilter(true)
                localStorage.setItem('articleFilter_loyalty', 'true')
            } else {
                props.onLoyaltyFilter(false)
                localStorage.removeItem('articleFilter_loyalty')
            }
        }
    }, [loyaltyFilter])

    const openFilterModal = () => {
        isFilterModalVisibleHandler(true);
    }

    const closeFilterModal = () => {
        isFilterModalVisibleHandler(false);
    }

    const goBack = () => {
        props.history.push('/');
    }

    const loyaltyFilterHandler =()=> {
        setLoyaltyFilter(!loyaltyFilter);
    }

    let filterLoyaltyButton =
        <SquareBadge
            className={styles.QuickFilter}
            size={"32px"}
            marginLeft={"12px"}
            onClick={loyaltyFilterHandler}
            pointer
        >
            <LoyaltyFilterIcon className={loyaltyFilter ? styles.IconStyleClicked : styles.IconStyle}/>
            <TooltipComponent className={styles.TooltipText} text="Artikli sa loyalty bodovima"/>
        </SquareBadge>;

    return (
        <Media
            queries={{
                small: "(max-width: 699px)",
                large: "(min-width: 700px)"
            }}>
            {matches => (
                <Fragment>

                    {matches.small &&
                        <ActionMenuRes>
                            <HeaderWithTitleAndBackChevron
                                icon
                                onIconClick={goBack}
                                title="Dostupni proizvodi"
                            />
                            <InfoBox />
                            <div style={{display: "flex"}}>
                                <SearchBar/>
                                {filterLoyaltyButton}
                                <FilterIcon onClick={openFilterModal} className={styles.FilterIconMobile}/>
                            </div>
                            {/*STARI FILTERI ZA ULOGOVANE I NEULOGOVANE KORISNIKE*/}
                            {props.isLogedin && isFilterModalVisible &&
                                <ShopFilter show={isFilterModalVisible}
                                            backdropClickHandler={closeFilterModal}>
                                </ShopFilter>}
                            {isFilterModalVisible &&
                                <ArticleCategoriesRes show={isFilterModalVisible}
                                                      backdropClickHandler={closeFilterModal}>
                                </ArticleCategoriesRes>}

                            {/*{/*nove kategorije*/}
                            {/*{props.isLogedin && isFilterModalVisible &&*/}
                            {/*    // <FilterModal>*/}
                            {/*    <ArticleCategoriesRes show={isFilterModalVisible}*/}
                            {/*                       backdropClickHandler={closeFilterModal}>*/}
                            {/*    </ArticleCategoriesRes>*/}
                            {/*     </FilterModal>*/}
                            {/*}*/}

                        </ActionMenuRes>
                    }

                    {matches.large &&
                        <ActionMenu holderStyle={{justifyContent: "flex-start", position: "relative"}}>
                            <div className={styles.SearchResultsAM} style={{paddingRight: "200px"}}>
                                <button onClick={openFilterModal}
                                        className={isArticleFilterSelected ? [styles.FilterButton, styles.FilterButtonSelected].join(' ') : [styles.FilterButton, styles.FilterButtonUnselected].join(' ')}
                                >Kategorije
                                </button>
                                {filterLoyaltyButton}
                                {/*{isFilterModalVisible &&*/}
                                {/*<ShopFilter show={isFilterModalVisible}*/}
                                {/*            backdropClickHandler={closeFilterModal}>*/}
                                {/*</ShopFilter>}*/}

                                {/*nove kategorije*/}
                                {isFilterModalVisible &&
                                    <ArticleCategories show={isFilterModalVisible}
                                                       backdropClickHandler={closeFilterModal}>
                                    </ArticleCategories>
                                }
                            </div>
                            <SearchBar/>
                        </ActionMenu>
                    }
                </Fragment>
            )}
        </Media>
    );
}

const mapStateToProps = (state) => {
    return {
        loyaltyFilter: state.filter.loyaltyFilter
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onLoyaltyFilter: (loyaltyFilter) => dispatch(actions.loyaltyFilter(loyaltyFilter))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SearchArticlesResultsPageActionMenu));