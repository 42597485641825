import React, {useEffect, useState} from 'react'
import ZoomMtgEmbedded from '@zoomus/websdk/embedded';
import * as actions from "../../../store/actions";
import {connect} from "react-redux";
import styles from './VideoCall.module.css';
import Button from "../../UI/Buttons/Button/Button";
import Spinner from './../../UI/Spinner/Spinner'


const VideoCall = (props) => {

    const client = ZoomMtgEmbedded.createClient();

    // setup your signature endpoint here: https://github.com/zoom/meetingsdk-sample-signature-node.js
    // This Sample App has been updated to use SDK App type credentials https://marketplace.zoom.us/docs/guides/build/sdk-app
    const sdkKey = 'pv5VI1705IrXFnuRUnva6mEuJhD2UhMOH6Bb'
    const meetingNumber = '3504479966'
    const role = 0
    const leaveUrl = 'https://www.apoteka.rs'
    const userName = localStorage.getItem('firstName') + " " + localStorage.getItem('secondName');
    const userEmail = localStorage.getItem('email')
    const passWord = 'a59LZi'
    // pass in the registrant's token if your meeting or webinar requires registration. More info here:
    // Meetings: https://marketplace.zoom.us/docs/sdk/native-sdks/web/client-view/meetings#join-registered
    // Webinars: https://marketplace.zoom.us/docs/sdk/native-sdks/web/client-view/webinars#join-registered
    const registrantToken = ''

    // const [showJoinButton, showJoinButtonHandler] = useState(props.zoomCredentials)
    const [showJoinButton, showJoinButtonHandler] = useState(false)


    useEffect(() => {
        props.onFetchZoomCredentials({
            meetingNumber: '3504479966',
            role: role
        })
    }, [])

    useEffect(() => {
        if (props.zoomCredentials !==null)
            showJoinButtonHandler(true)
    }, [props.zoomCredentials])


    function startMeeting() {

        let meetingSDKElement = document.getElementById('meetingSDKElement');

        client.init({
            leaveUrl: leaveUrl,
            disableInvite: true,
            inviteUrlFormat: 'hide',
            debug: true,
            zoomAppRoot: meetingSDKElement,

            disableRecord: true,
            showMeetingHeader: false,
            videoDrag: false,
            language: 'en-US',
            customize: {
                video: {
                    popper: {
                        disableDraggable: true
                    },
                    isResizable: false,
                    viewSizes: {
                        default: {
                            width: 1000,
                            height: 600
                        },
                    },
                },
                meetingInfo: ['topic', 'host', 'enctype'],
            }
        }).then(r => {
                client.join({
                    sdkKey: sdkKey,
                    signature: props.zoomCredentials,
                    meetingNumber: meetingNumber,
                    password: passWord,
                    userName: userName,
                    userEmail: userEmail,
                    tk: registrantToken,
                    success: () => {
                        document.querySelector('button[title="Participants"]').style.display = "none"
                    },
                }).then(r => {
                    showJoinButtonHandler(false);
                })
            }
        );
    }


    //TODO: odreagovati na pojavu id="notistack-snackbar" i ucitati Zoom logout stranu sa drugim disclaimerom
    // const myElement = document.getElementById('notistack-snackbar');
    //
    // let observer = new MutationObserver(function(mutations) {
    //     if (document.contains(myElement)) {
    //         alert("It's in the DOM!");
    //         observer.disconnect();
    //     }
    // });
    //
    // observer.observe(document, {attributes: false, childList: true, characterData: false, subtree:true});


    return (
        <div className={styles.Zoom}>
            <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                <h1>Razgovor sa farmaceutom</h1>
                <br/>
                <div className={styles.Description}>
                    <div>Naši farmaceuti dostupni su svakog radnog dana od 9 do 17 časova.</div>
                    <div>Možeš ih kontaktirati klikom na dugme "Započni razgovor".</div>
                    <div>Razgovor se odvija putem Zoom platforme, a korisnik nije u obavezi da uključi svoju kameru.</div>
                </div>
                <br/>
                {/* For Component View */}
                <div id="meetingSDKElement">
                    {/* Zoom Meeting SDK Component View Rendered Here */}
                </div>

                {showJoinButton ?
                <Button buttontype="lightblue" onClick={startMeeting} style={{width: "200px", marginTop: "1rem"}}>
                    Započni razgovor
                </Button>
                    :
                <Spinner />
                }
                {/*<div className={styles.Description}>*/}
                {/*    <div>Poštovani korisnici</div>*/}
                {/*    <div>Ova funkcionalnost će biti uskoro dostupna</div>*/}
                {/*    <div>Vaša apoteka.rs</div>*/}
                {/*</div>*/}
            </div>
        </div>
    );

}


const mapStateToProps = (state) => {
    return {
        zoomCredentials: state.appPage.zoomCredentials,
        loadingZoomCredentials: state.appPage.loadingZoomCredentials,
        zoomCredentialsError: state.appPage.zoomCredentialsError
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onFetchZoomCredentials: (data) => dispatch(actions.fetchZoomCredentials(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(VideoCall);
