import React, {useEffect} from 'react';
import styles from "./Toolbar.module.css"
import NavigationItems from "../NavigationItems/NavigationItems"
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import Logo from '../../Logo/Logo'
import * as actions from "../../../store/actions";
import ChooseLocation from "../../Global/ChooseLocation/ChooseLocation";

const Toolbar = (props) => {

    useEffect(() => {
        window.addEventListener('scroll', () => {
            if(window.scrollY > 0) {
                props.onToolbarWhiteChanged(true)
            } else {
                props.onToolbarWhiteChanged(false);
            }
        });
    }, []);

    useEffect( () => {
        return(() => {window.scroll({top:0})});
    }, [])

    let toolbarStyle = !props.toolbarWhite ? [styles.Toolbar, styles.PositionAbsolute].join(' ') : [styles.Toolbar, styles.PositionFixed].join(' ') ;

    return (
        // <header className={props.location.pathname !=='/' ? [styles.Toolbar, styles.ToolbarWhite].join(' ') : styles.Toolbar}>
        <header className={toolbarStyle}>
            <div className={styles.ToolbarHolder}>
                <Logo/>
                <div className={styles.ToolbarRightContent}>
                    {/*{props.authToken ?*/}
                    {/*    // <div className={styles.UserLoginBox}>Prijavljeni ste kao {props.userInfo.firstName} {props.userInfo.secondName}</div> : null}*/}
                    {/*    <div className={styles.UserLoginBox}>{props.userInfo.username}</div> : null}*/}
                    <nav>
                        <NavigationItems/>
                    </nav>
                </div>
            </div>
            {/* <ChooseLocation /> */}
        </header>
    )
}

const mapStateToProps = (state) => {
    return {
        authToken: state.authPage.token !== null,
        userInfo: state.authPage.userInfo,
        toolbarWhite: state.toolbar.toolbarWhite
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onToolbarWhiteChanged: (toolbarWhite) => dispatch(actions.toolbarWhiteChanged(toolbarWhite)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Toolbar));
