import React, {useState} from 'react'
import styles from './SearchedArticleItem.module.css';
// import PerfectScrollbar from "react-perfect-scrollbar";
import RegulativaNotice from "../../../UI/RegulativaNotice/RegulativaNotice";
import {ChevronRightIcon, XIcon} from "@heroicons/react/outline";
import {withRouter} from "react-router-dom";
import Button from "../../../UI/Buttons/Button/Button";
import TooltipComponent from "../../../UI/TooltipComponent/TooltipComponent";
import ArticleAction from "../../../ArticleInfoPage/ArticleActionsHolder/ArtucleActions/ArticleAction/ArticleAction";
import {ExclamationCircleIcon} from "@heroicons/react/outline";
import * as actions from "../../../../store/actions";
import {connect} from "react-redux";
import {ReactComponent as HeartIcon} from "../../../../assets/icons/heart.svg";
import {ReactComponent as CompareIcon} from "../../../../assets/icons/copy.svg";
import {ReactComponent as ShoppingCartIcon} from './../../../../assets/icons/cart_icon.svg'
import Magnifier from "react-magnifier";
import LoyaltyBadge from "../../../UI/LoyaltyBadge/LoyaltyBadge";
import {textToUrl} from "../../../GobalFunctions/GlobalFunctions";
import AlertQuantityModal from "../../../UI/Modals/AlertQuantityModal/AlertQuantityModal";
import ItemAvailabilityBadge from "../../../UI/ItemAvailabilityBadge/ItemAvailabilityBadge";
import SquareBadge from "../../../UI/SquareBadge/SquareBadge";

const SearchedArticleItem = (props) => {

    const [showAlertModal, showAlertModalSetter] = useState(false);

    const articleInfoHandler = () => {
        let url;
        const branchMarketplaceId = props.location.pathname.split('/')[2];
        const marketplaceId = props.location.pathname.split('/')[3];
        if (props.article !== null) {
        url = '/articles-info/' + props.article.itemResponse.jkl + "/" + props.article.itemResponse.id + '/' + branchMarketplaceId + '/' + marketplaceId + '/' + textToUrl(props.article.itemResponse.name)
        + '/' + props.match.params.branchMarketplaceName + '/' + props.match.params.marketplaceName;
            props.onStoreDetailsForInfoPage(props.article.marketplaceResponse);
            localStorage.setItem('beforeEnteringInfoLek', window.location.pathname);
            props.onArticleInStore(props.article);
            props.history.push(url);
        }
    }

    const addItemToShoppingCart = (e) => {
        e.stopPropagation();
        if (props.quantityInCart < props.article.itemQuantity) {
            if (props.quantityInCart !== 99)
                props.addToCart();
        } else {
            showAlertModalSetter(true)
        }
    }

    const closeAlertModal = () => {
        showAlertModalSetter(false)
    }


    const loyaltyPointsIcon =
        <div className={styles.LoyaltyPointsIcon}>
            <LoyaltyBadge
                number={props.article.itemResponse.itemLoyalityPoints/10}
            />
        </div>

    let itemAvailability = props.article.itemResponse.itemAvailabilityCode !== null &&
        <ItemAvailabilityBadge itemResponse={props.article.itemResponse} className={styles.ItemAvailability}/>

    const itemImage =
        <div className={styles.ArticleImageHolder}>
            {props.article.itemResponse.jkl !== null && props.article.itemResponse.jkl.trim() !== "" && props.article.itemResponse.itemAvailabilityCode !== 'BR' ?
                <RegulativaNotice imgStyle={{borderRadius: "8px"}}
                                  style={{width: "70%", display: "flex", alignItems: "center", fontSize: "14px", marginTop: "2rem"}}
                                  iconStyle={{width: "36px", stroke: "#F58300"}}
                                  wrappingStyle={{display: "flex", flexDirection: "column", alignItems: "center"}}
                />
                :
                // <img className={styles.ArticleImage}
                //      src={props.articlePicture}
                //      alt="article image"
                // />
                <Magnifier src={props.articlePicture} imgAlt="article image"
                zoomFactor={0.075}
                mgShowOverflow={false}
                mgShape={"square"}
                mgBorderWidth={0}
                mgMouseOffsetX={0}
                mgMouseOffsetY={0}
                mgWidth={2000}
                mgHeight={2000}
                height="100%"
                style={{objectFit:"contain"}}
                />
            }
            {itemAvailability}
            {props.article.itemResponse.itemLoyalityPoints > 0 && loyaltyPointsIcon}
        </div>

    const removeSearchedArticleItem = () => {
        let url = window.location.pathname.substring(0, window.location.pathname.lastIndexOf('/'))
        props.searchBarPlaceholderChanged('');
        props.history.push(url);
    }

    return (
        <div className={styles.SearchedArticleItem}>
            {itemImage}
            <div className={styles.SearchedArticleItemTextBox}>
                <p className={styles.Title}>{props.articleTitle}</p>
                <div style={{width: "100%"}}>
                    {/*<PerfectScrollbar style={{height: "150px", padding: "0 1rem"}}>*/}
                    {/*    {props.details}*/}
                    {/*</PerfectScrollbar>*/}
                    <div className={styles.MoreDetailsWrapper}>
                        <p className={styles.MoreDetailsAction} onClick={articleInfoHandler}>
                            Detaljnije &nbsp; <ChevronRightIcon style={{width: "18px"}}/>
                        </p>
                        <p style={{padding: "0 1rem", width: "fit-content"}}>Šifra proizvoda: {props.articleId}</p>
                    </div>
                </div>
            </div>
            <div className={styles.ActionsHolder}>
                <div style={{position: "absolute", right: "0", top: "0"}}>
                    <XIcon style={{width: "24px", height: "24px", padding: "1rem", color: "#4A4F54", cursor:"pointer"}} onClick={removeSearchedArticleItem}/>
                </div>
                <div>
                    <div className={styles.TooltipWrapper} style={{cursor:"pointer", userSelect:"none", color:"ButtonHighlight"}}>
                        <ArticleAction text={'Terapija'}>
                            <HeartIcon style={{width:"1.5rem", padding:"0 1rem", stroke:"ButtonHighlight"}} />
                        </ArticleAction>
                        <TooltipComponent className={styles.TooltipText} text="Terapija - USKORO!"/>
                    </div>
                    <div className={styles.TooltipWrapper} style={{cursor:"pointer", userSelect:"none", color:"ButtonHighlight"}}>
                        <ArticleAction text={'Uporedi lek'}>
                            <CompareIcon style={{width:"1.5rem", padding:"0 1rem", stroke:"ButtonHighlight"}}/>
                        </ArticleAction>
                        <TooltipComponent className={styles.TooltipText} text="Uporedi lek - USKORO!"/>
                    </div>
                </div>
                <div className={styles.SelectedArticlePrice}>
                    {props.article.itemResponse.jkl !== null ?
                        <span style={{display: "flex", alignItems: "center"}}><span style={{fontSize: "16px", color:"#4D5055"}}>Regulativa</span>&nbsp; &nbsp; <ExclamationCircleIcon style={{width:"20px", stroke:"#F58300"}}/></span> :
                        <>
                            <span>Cena:</span>
                            {(props.article.itemPriceWithDiscount && props.article.itemPriceWithDiscount > 0) ?
                                <span style={{fontSize: "18px", fontWeight: "bold"}}>
                                            <div className={styles.OriginalPrice}>
                                                <span>{(props.article.itemPrice).toFixed(2)}</span>
                                                <span>&nbsp; RSD</span>
                                            </div>
                                         <div style={{display: "flex"}}>
                                             <span>{(props.article.itemPriceWithDiscount).toFixed(2)}</span>
                                             <span>&nbsp; RSD</span>
                                            </div>
                                    </span>
                                :
                                <span style={{fontSize: "18px", fontWeight: "700"}}>
                                {props.article.itemPrice.toFixed(2)} RSD</span>
                            }
                        </>
                    }
                </div>
                <div className={styles.AddSelectedArticleToCartButton}>
                    <Button buttontype='whitedarkblue'
                            style={{padding: "1rem", fontWeight: "600", position: "relative"}}
                            onClick={addItemToShoppingCart}>
                        <ShoppingCartIcon style={{width:"24px", height:"24px", stroke:"inherit"}}/>
                        {/*<ShoppingCartIcon className={styles.ShoppingCartIcon}/>*/}
                        <span style={{paddingLeft: "1rem"}}>Dodaj</span>
                        {props.quantityInCart > 0 &&
                            <span className={styles.QuantityInCart}>{props.quantityInCart}</span>
                        }
                    </Button>
                    {props.article.itemPriceWithDiscount !== null && props.article.itemPriceWithDiscount > 0 &&
                        <div className={styles.SqBox}>
                            <SquareBadge
                                backgroundColor={"#FFAE00"}
                                marginLeft={"6px"}
                            >
                                <span style={{color: "white"}}>%</span>
                            </SquareBadge>
                            <TooltipComponent className={styles.TooltipSBText} text="Artikal je na popustu!"/>
                        </div>
                    }
                </div>
            </div>
            {showAlertModal &&
                <AlertQuantityModal
                    closeAlertQuantityModal={closeAlertModal}
                    show={showAlertModal}
                    backdropClickHandler={closeAlertModal}/>
            }
        </div>
    );
}


const mapDispatchToProps = (dispatch) => {
    return {
        onStoreDetailsForInfoPage: (store) => dispatch(actions.detailsForStoreInfoPage(store)),
        onArticleInStore: (articleInStore) => dispatch(actions.articleInStore(articleInStore)),
        searchBarPlaceholderChanged: (newString) => dispatch(actions.searchBarPlaceholderChanged(newString))
    }
}

export default connect(null,mapDispatchToProps)(withRouter(SearchedArticleItem));