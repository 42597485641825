import * as actionsTypes from './actionsTypes'
import axios from "../../axios/axios-apoteka";

export const collectOrDeliveryChoice = (collectOrDelivery) => {
    return {
        type: actionsTypes.COLLECT_OR_DELIVERY_CHOICE,
        collectOrDelivery: collectOrDelivery
    };
}

//Actions for check-ing the order before finishing it
export const shoppingCartChecked = (outOfDateItems) => {
    return {
        type: actionsTypes.SHOPPING_CART_CHECKED,
        outOfDateItems: outOfDateItems

    };
}

export const finalCheckShoppingCartFailed = () => {
    return {
        type: actionsTypes.FINAL_CHECK_SHOPPING_CART_FAILED
    };
}

export const finalCheckShoppingCartStart = () => {
    return {
        type: actionsTypes.FINAL_CHECK_SHOPPING_CART_START
    };
}

export const finalCheckShoppingCart = (userId, branchMarketplaceId, marketplaceId) => {
    return dispatch => {
        dispatch(finalCheckShoppingCartStart());
        const URL = '/shopping_cart/check/' + userId + '/' + branchMarketplaceId  + '/' + marketplaceId;
        axios.get(URL)
            .then(response => {
                console.log(response.data)
                dispatch(shoppingCartChecked(response.data))
            })
            .catch(error => {
                dispatch(finalCheckShoppingCartFailed())
            })
    };
}

export const orderSocketConnectionChanged = (connected) => {
    return {
        type: actionsTypes.ORDER_SOCKET_CONNECTION_CHANGED,
        orderSocketConnected: connected
    };
}

export const orderSocketConnectionStart = () => {
    return {
        type: actionsTypes.ORDER_SOCKET_CONNECTION_START
    };
}