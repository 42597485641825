import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import EditNameAndSurname from "../EnterNameAndSurname/EditNameAndSurname";
import OrderCompletionPageTemplateMobile
    from "../../OrderCompletionPageTemplateMobile/OrderCompletionPageTemplateMobile";
import HeaderWithTitleAndBackChevron
    from "../../../../../componentsRes/HeaderWithTitleAndBackChevron/HeaderWithTitleAndBackChevron";
import EnterPhoneNumber from "../EnterPhoneNumber/EnterPhoneNumber";
import Button from "../../../../UI/Buttons/Button/Button";
import AlertModal from "../../4-SummaryAndConfirmation/AlertModal/AlertModal";

const UserInfoMobile = (props) => {

    const [triggerEnterNameAndSurnameComponent, triggerEnterNameAndSurnameComponentSetter] = useState(false);
    const [showAlertModal, showAlertModalSetter] = useState(false);

    const [tooltipFinishText, setTooltipFinishText] = useState("");

    useEffect(() => {
        if(props.userProfile && props.userProfile.phoneNumber !== null && !localStorage.getItem('phoneNumber')) {
            localStorage.setItem('phoneNumber', props.userProfile.phoneNumber)
        }
    },[props.userProfile])

    const redirectToAddressMobile = () => {
        if (isDisabled()) {
            openAlertModal();
        } else {
            triggerEnterNameAndSurnameComponentSetter(true);
            let url = '/orderCompletion/' + props.match.params.branchMarketplaceId + "/" + props.match.params.marketplaceId;
            setTimeout(() => {
                props.collectOrDelivery.delivery ?
                    url += '/addresses/' + props.match.params.branchMarketplaceName + '/' + props.match.params.marketplaceName :
                    url += '/confirmation' + '/' + props.match.params.branchMarketplaceName + '/' + props.match.params.marketplaceName;

                props.history.push(url);
            }, 500)
        }
    }

    const redirectToPreviousPage = () => {
        const url = '/orderCompletion/' + props.match.params.branchMarketplaceId + "/" + props.match.params.marketplaceId + '/delivery'
            + '/' + props.match.params.branchMarketplaceName + '/' + props.match.params.marketplaceName;
        props.history.push(url);
    }

    const isDisabled = () => {
        if (!localStorage.getItem('phoneNumber')
            || !localStorage.getItem('firstNameForOrder')
            || !localStorage.getItem('secondNameForOrder')) {
            setTooltipFinishText("Da biste nastavili dalje morate uneti ime, prezime i telefon");
            return true
        } else {
            return false;
        }
    }

    const openAlertModal = () => {
        showAlertModalSetter(true)
    }

    const closeAlertModal = () => {
        showAlertModalSetter(false)
    }

    return (
        <OrderCompletionPageTemplateMobile
            header={<HeaderWithTitleAndBackChevron
                icon
                onIconClick={redirectToPreviousPage}
                title="Korisnički podaci"
            />}
        >
            <br/>
            <br/>
            <br/>
            <EditNameAndSurname
                triggerEnterNameAndSurnameComponent={triggerEnterNameAndSurnameComponent}
            />
            <br/>
            <EnterPhoneNumber />
            <br/>
            <Button buttontype='blue' onClick={redirectToAddressMobile}>Dalje</Button>
            {showAlertModal &&
                <AlertModal onCloseClick={closeAlertModal}
                            backdropClickHandler={closeAlertModal}
                            show={showAlertModal}
                            messageText={tooltipFinishText}
                />
            }
        </OrderCompletionPageTemplateMobile>
    );
}

const mapStateToProps = (state) => {
    return {
        selectedStore: state.appPage.selectedStore,
        collectOrDelivery: state.orderCompletion.collectOrDelivery,
        userProfile: state.userProfile.userProfile,
    }
}

export default connect(mapStateToProps)(withRouter(UserInfoMobile));