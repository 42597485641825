import * as actionsTypes from '../actions/actionsTypes'

const initialState = {
    gridView: true,
    // shopFilter: '',
    shopFilter: {
        categoryId: null,
        categoryArray: []
    },
    advCategoryImageStart: false,
    advCategoryImageFailed: false,
    advCategoryImage: null,

    storeFilter: {
        inMyRadius: false,
        filters: ''
    },

    articleCategoryDetails: null,

    filterBreadcrumbs: {
        cat: null,
        catName: null,
        scat1: null,
        scat1Name: null,
        scat2: null,
        scat2Name: null
    },

    loyaltyFilter: false,
    discountFilter: false
}

const filterReducer = (state = initialState, action) => {
    switch (action.type) {

        case actionsTypes.GRID_LIST_TOGGLE_VIEW:
            return {
                ...state,
                gridView: action.gridView,
            }
        case actionsTypes.SHOP_FILTER:
            return {
                ...state,
                shopFilter: {...action.shopFilter},
            }
        case actionsTypes.RESET_SHOP_FILTER:
            return {
                ...state,
                shopFilter: '',
            }
        case actionsTypes.STORE_FILTER:
            return {
                ...state,
                storeFilter: {...action.storeFilter},
            }
        case actionsTypes.FETCH_ARTICLE_CATEGORY_DETAILS:
            return {
                ...state,
                articleCategoryDetails: action.articleCategoryDetails
            }
        case actionsTypes.FILTER_BREADCRUMBS:
            return {
                ...state,
                filterBreadcrumbs: {...action.filterBreadcrumbs},
            }
        case actionsTypes.LOYALTY_FILTER:
            return {
                ...state,
                loyaltyFilter: action.loyaltyFilter
            }
        case actionsTypes.DISCOUNT_FILTER:
            return {
                ...state,
                discountFilter: action.discountFilter
            }
        case actionsTypes.FETCH_ADV_CATEGORY_IMAGE_START:
            return {
                ...state,
                advCategoryImage: null,
                advCategoryImageStart: true,
                advCategoryImageFailed: false
            }
        case actionsTypes.FETCH_ADV_CATEGORY_IMAGE_FAILED:
            return {
                ...state,
                advCategoryImage: null,
                advCategoryImageStart: false,
                advCategoryImageFailed: true
            }
        case actionsTypes.ADV_CATEGORY_IMAGE:
            return {
                ...state,
                advCategoryImage: action.advCategoryImage,
                advCategoryImageStart: false,
                advCategoryImageFailed: false
            }

        default:
            return state;
    }
}

export default filterReducer;