import React, {useEffect, useRef, useState} from 'react';
import {connect} from "react-redux";
import * as actions from '../../../store/actions/index'
import Spinner from '../../UI/Spinner/Spinner'
import authPageStyles from '../AuthPage.module.css';
import 'react-inputs-validation/lib/react-inputs-validation.min.css';
import ConfirmButton from '../../UI/Buttons/Button/Button';
import {redirectToError} from "../../../containers/App";
import ReCAPTCHA from "react-google-recaptcha";
import PPTOSModal from "../PPTOSModal/PPTOSModal";
import {useForm} from 'react-hook-form';
import styles from "../AuthPage.module.css";
import Input from "../../UI/Input/Input";


const SITE_KEY = "6Lcp2CIeAAAAAF3shXPB-urKhV1ss2cjdveIVNcw";

const RegistrationPane = (props) => {

    const [isWantNewsletter, isWantNewsletterHandler] = useState(false)


    useEffect(() => {
        props.isLogin1(false);
    }, []);

    useEffect(() => {

        if (props.registrationServerError) {
            redirectToError(props);
        }
        if (props.registrationError) {
            inputStateChanged({
                ...inputState,
                userData: {...inputState.userData},
                registrationMessage: <p>Korisnik sa ovim email-om već postoji!</p>
            })
        }
        if (props.registrationSuccess) {
            inputStateChanged({
                ...inputState,
                userData: {
                    username: "",
                    password: "",
                    repeatedPassword: "",
                    permitAdvertising: ""
                },
                registrationMessage: <p style={{textAlign: "center", fontSize: "0.875rem"}}>Poslat Vam je verifikacioni
                    mail, molimo potvrdite registraciju!</p>
            })
        }

    }, [props.registrationSuccess, props.registrationError, props.registrationServerError])

    const [inputState, inputStateChanged] = useState({
        userData: {
            username: "",
            password: "",
            repeatedPassword: "",
            permitAdvertising: ""
        },
        errorRepeaterPass: false,
        registrationMessage: ''
    })

    const [reCaptchaToken, setReCaptchaToken] = useState("");

    const recaptchaRef = useRef();

    const openPPTOSModal = (e) => {
        e.preventDefault();
        const values = getValues();
        inputStateChanged({
            ...inputState,
            userData: {
                username: values.email,
                password: values.password,
                repeatedPassword: values.repeatedPassword,
                permitAdvertising: isWantNewsletter
            }
        })
    }

    const registerHandler = () => {
        // event.preventDefault();
        let credentialParams = {
            ...inputState.userData
        };

        if (credentialParams.password !== credentialParams.repeatedPassword) {
            inputStateChanged({
                ...inputState,
                errorRepeaterPass: true
            })
            return;
        }
        props.onRegistration(inputState.userData);
    }

    const onChangeReCaptcha = (value) => {
        setReCaptchaToken(value);
        console.log("Captcha value:", value);
        props.onSendRecaptchaToken(value);
    }

    const {register, handleSubmit, formState: {errors, isValid}, getValues} = useForm({
        mode:"onChange"
    });

    const onSubmit = (data, e) => {

        openPPTOSModal(e);
        if (data && (getValues('password') === getValues('repeatedPassword'))) {
            props.onPPTOSModalOpenHandler(true);
        }
    }

    let form = <Spinner/>
    if (!props.loadingRegistration) {
        form = (
            <form className={authPageStyles.Form} onSubmit={handleSubmit(onSubmit)}>
                <input type="text" placeholder="Email" {...register("email", {
                    required: "Ovo polje ne može biti prazno",
                    // minLength:5,
                    maxLength: 256,
                    pattern: {
                        value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        message: "Unesena adresa nije validnog formata"
                    }
                })} />
                {errors.email && <span className={authPageStyles.ErrorMessage}>{errors.email.message}</span>}

                <input type="password" placeholder="Lozinka" {...register("password", {
                    required: "Ovo polje ne može biti prazno",
                    minLength: {
                        value: 8,
                        message: "Lozinka mora imati najmanje 8 karaktera"
                    },
                    maxLength: {
                        value: 128,
                        message: "Lozinka ne može imati više od 128 karaktera"
                    },
                    pattern: {
                        value: /^(?=.*[a-zљњертжуиопшђасдфгхјклчћзџцвбнмšđžčć])(?=.*[A-ZЉЊЕРТЖУИОПШЂАСДФГХЈКЛЧЋЗЏЦВБНМŠĐŽČĆ])(?=.*\d)[a-zA-ZљњертжуиопшђасдфгхјклчћзџцвбнмšđžčćЉЊЕРТЖУИОПШЂАСДФГХЈКЛЧЋЗЏЦВБНМŠĐŽČĆ\d\w\W]{8,}$/,
                        message: "Lozinka mora da sadrži najmanje jedno veliko, jedno malo slovo i jedan broj"
                    }
                })} />
                {errors.password && <span className={authPageStyles.ErrorMessage}>{errors.password.message}</span>}

                <input type="password" placeholder="Ponovite lozinku" {...register("repeatedPassword", {
                    required: "Ovo polje ne moze biti prazno",
                    minLength: {
                        value: 8,
                        message: "Lozinka mora imati najmanje 8 karaktera"
                    },
                    maxLength: {
                        value: 128,
                        message: "Lozinka ne može imati više od 128 karaktera"
                    },
                    validate: {
                        notEqual: (value) => {
                            if (value !== getValues("password")) return "Lozinke se ne podudaraju!"
                        }
                    }
                })}
                />
                {errors.repeatedPassword && <span className={authPageStyles.ErrorMessage}>{errors.repeatedPassword.message}</span>}

                <div style={{display:"flex", alignItems:"center"}}>
                    <Input inputtype="checkbox" type="checkbox"
                           style={{opacity: "1", width: "fit-content", position: "relative"}}
                           checked={isWantNewsletter}
                           onChange={() => {
                               isWantNewsletterHandler(!isWantNewsletter)
                           }}
                    />
                    <p className={styles.SmallText}>
                        Želim da primam promotivne materijale
                    </p>
                </div>

                <br/>
                <div style={{height: "30px", paddingBottom: "5px"}}>
                    {inputState.registrationMessage}
                </div>
                <br/>
                <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey={SITE_KEY}
                    onChange={onChangeReCaptcha}
                    // onExpired={() => {recaptchaRef.reset()}}
                />
                {/*  Disable dugmeta ukoliko nije prosla recaptcha i ukoliko polja nisu pravilno popunjena  */}
                <ConfirmButton
                    type="submit"
                    style={{width: "200px", marginTop: "1rem"}}
                    buttontype={true ? 'lightblue' : 'disabled'}
                    // buttontype={isValid && props.googleRecaptchaResponse !== null && props.googleRecaptchaResponse.success ? 'lightblue' : 'disabled'}
                    // disabled={isValid && props.googleRecaptchaResponse !== null && props.googleRecaptchaResponse.success ? false : true}
                > Kreiraj nalog </ConfirmButton>
            </form>
        );
    }

    return (
        <React.Fragment>
            {form}
            <div>
                <PPTOSModal onSubmitModal={registerHandler}>

                </PPTOSModal>
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
        registrationError: state.authPage.registrationError,
        registrationServerError: state.authPage.registrationServerError,
        loadingRegistration: state.authPage.loadingRegistration,
        registrationSuccess: state.authPage.registrationSuccess,
        googleRecaptchaResponse: state.authPage.googleRecaptchaResponse,
        PPTOSModalOpened: state.authPage.PPTOSModalOpened
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onRegistration: (userData) => {
            dispatch(actions.registration(userData))
        },
        onSendRecaptchaToken: (token) => {
            dispatch(actions.sendReCaptchaToken(token))
        },
        onPPTOSModalOpenHandler: (PPTOSModalOpened) => {
            dispatch(actions.PPTOSModalOpenHandler(PPTOSModalOpened))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationPane);