import React, {useEffect, useState} from 'react';
import styles from './UserMenu.module.css';
import NavigationItem from "../NavigationItems/NavigationItem/NavigationItem";
import * as actions from "../../../store/actions";
import {connect} from "react-redux";
import { withRouter } from "react-router-dom";


const UserMenu = (props) => {
    //
    // /**
    //  *  Deo koda koji enabluje ili disabluje link za aktivnu korpu
    //  * */
    // const [shoppingCartCheckForActiveLink, shoppingCartCheckForActiveLinkHandler] = useState(false)
    // const [goToActiveCartLinkEnabled, goToActiveCartLinkEnabledHandler] = useState(false);
    //
    // useEffect(()=> {
    //     if(props.userInfo) {
    //         props.onFetchAllShoppingCarts(props.userInfo.id);
    //         shoppingCartCheckForActiveLinkHandler(true);
    //         goToActiveCartLinkEnabledHandler(false);
    //     }
    // }, [])
    //
    // useEffect(()=>{
    //     if (shoppingCartCheckForActiveLink) {
    //         if(props.allShoppingCarts !== null) {
    //             goToActiveCartLinkEnabledHandler(true);
    //             shoppingCartCheckForActiveLinkHandler(false);
    //             props.onAllShoppingCarts(null);
    //         }
    //     }
    // }, [props.allShoppingCarts])
    //


    // /**
    //  *  Deo koda koji hendluje link ka aktivnoj korpi
    //  * */
    // const [shoppingCartCheck, shoppingCartCheckHandler] = useState(false);
    //
    // useEffect(()=>{
    //     if(shoppingCartCheck) {
    //         toActiveCart();
    //     }
    // },[props.allShoppingCarts])
    //
    //
    // const toActiveCart = () => {
    //     setTimeout(()=>{
    //         if(props.allShoppingCarts !== null) {
    //             let url = '/shop/' + props.allShoppingCarts[0].marketplaceResponse.branchMarketplaceResponse.id + '/' + props.allShoppingCarts[0].marketplaceResponse.marketplaceId + '/0';
    //             // props.onFetchSelectedStore(props.allShoppingCarts[0].marketplaceResponse.branchMarketplaceResponse.id, props.allShoppingCarts[0].marketplaceResponse.marketplaceId);
    //             props.onAllShoppingCarts(null);
    //             props.onResetSelectedStore(null);
    //             shoppingCartCheckHandler(false);
    //             props.history.replace(url);
    //         }
    //     }, 500);
    // }

    //
    // const goToActiveCart =()=> {
    //     if(props.userInfo.id) {
    //         props.onFetchAllShoppingCarts(props.userInfo.id);
    //         shoppingCartCheckHandler(true);
    //     }
    // }

    // /**
    //  *  .end Deo koda koji hendluje link ka aktivnoj korpi
    //  * */

    return (
        <React.Fragment>
            {props.loggedIn ?

                <div className={styles.UserMenu}>
                    <div className={styles.UserMenuOption}>
                        <NavigationItem link={"/myprofile"} fontSize="1rem">Moj profil</NavigationItem>
                        {/*<p className={goToActiveCartLinkEnabled ? styles.UserMenuLink : styles.UserMenuLinkDisabled} onClick={goToActiveCart}>Započeta kupovina</p>*/}

                        {/*<NavigationItem fontSize="1rem"*/}
                        {/*                disabled={props.allShoppingCarts === null}*/}
                        {/*                link={props.allShoppingCarts ? '/shop/' + props.allShoppingCarts[0].marketplaceResponse.branchMarketplaceResponse.id + '/' + props.allShoppingCarts[0].marketplaceResponse.marketplaceId + '/0' : '#'}*/}
                        {/*>Moja korpa</NavigationItem>*/}
                    </div>

                    <hr style={{width: "100%"}}/>

                    <div className={styles.UserMenuOption} onClick={props.logout}>
                        <NavigationItem link={"/"} fontSize="1rem">Odjavi se</NavigationItem>
                        {/*<a href="/">Odjavi se</a>*/}
                    </div>

                    {/*<div className={styles.UserMenuOption} onClick={props.logout}>Odjavi se</div>*/}
                    {/*</div>*/}

                </div>
                : null}
        </React.Fragment>
    );
}

const mapStateToProps = (state) => {
    return {
        allShoppingCarts: state.shopPage.allShoppingCarts,
        userInfo: state.authPage.userInfo,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onAllShoppingCarts: (allShoppingCarts) => dispatch(actions.allShoppingCarts(allShoppingCarts)),
        onFetchSelectedStore: (branchMarketplaceId, marketplaceId) => dispatch(actions.fetchSelectedStore(branchMarketplaceId, marketplaceId)),
        // onFetchAllShoppingCarts: (userId) => dispatch(actions.fetchAllShoppingCarts(userId)),
        onInitShoppingCartAnimation: (value) => dispatch(actions.initiateAddToShoppingCartAnimation(value)),
        onResetSelectedStore: (value) => dispatch(actions.selectedStoreChanged(value)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (withRouter(UserMenu));