import React, {useEffect, useState} from 'react';
import styles from './MainPageCounters.module.css';
import MainPageCounter from "./MainPageCounter/MainPageCounter";
import CountUp from "react-countup";
import {connect} from "react-redux";
import * as actions from "../../../store/actions";
import {withRouter} from "react-router-dom";
import VerticalLine from "../../UI/VericalLine/VerticalLine";

const MainPageCounters = (props) => {

    // let fontSize
    //
    // if (window.innerWidth < 700) {
    //     fontSize = '1.5rem';
    // } else {
    //     fontSize = '2.625rem';
    // }

    const [apoteka, apotekaSetter] = useState('');
    const [articles, articlesSetter] = useState('');

    useEffect(()=>{

        let lastDigitCnc = null;
        let lastDigitApoteka = null;
        let lastDigitArticles = null;

        // if (props.clickAndCollectMarketplaces !== null && props.apotekaRsMarketplaces !== null && props.numOfArticles !== null) {
        if (props.clickAndCollectMarketplaces !== null) {
            lastDigitCnc = props.clickAndCollectMarketplaces.toString().slice(-1);
        }
        if (props.apotekaRsMarketplaces !== null) {
            lastDigitApoteka = parseInt(props.apotekaRsMarketplaces).toString().slice(-1);
        }
        if (props.numOfArticles !== null) {
            lastDigitArticles = parseInt(props.numOfArticles).toString().slice(-1);
        }

        // if (lastDigitApoteka === '1' || lastDigitApoteka === '5' || lastDigitApoteka === '6' || lastDigitApoteka === '7' || lastDigitApoteka === '8' || lastDigitApoteka === '9' || lastDigitApoteka === '0') {
        //     apotekaSetter(<h4 className={styles.CounterText}>Apoteka <br /> u bazi</h4>)
        // } else if (lastDigitApoteka === '2' || lastDigitApoteka === '3' || lastDigitApoteka === '4') {
        //     apotekaSetter(<h4 className={styles.CounterText}>Apoteke <br /> u bazi</h4>);
        // }

        apotekaSetter(<h4 className={styles.CounterText}> Dostupne apoteke</h4>)

        // if (lastDigitArticles === '1') {
        //     articlesSetter(<h4 className={styles.CounterText}>Proizvod <br/> u bazi</h4>);
        // } else if (lastDigitArticles === '2' || lastDigitArticles === '3' || lastDigitArticles === '4') {
        //     articlesSetter(<h4 className={styles.CounterText}>Proizvoda <br/> u bazi</h4>);
        // } else if (lastDigitArticles === '5' || lastDigitArticles === '6' || lastDigitArticles === '7' || lastDigitArticles === '8' || lastDigitArticles === '9' || lastDigitArticles === '0') {
        //     articlesSetter(<h4 className={styles.CounterText}>Proizvoda <br/> u bazi</h4>);
        // }

        articlesSetter(<h4 className={styles.CounterText}> Dostupni proizvodi</h4>)

    }, [props.clickAndCollectMarketplaces, props.apotekaRsMarketplaces, props.numOfArticles])

    const CnCText = <h4  className={styles.CounterText}>Poruči i preuzmi</h4>;

    const showClickAndCollectStores = () => {
            const url = '/searched-stores/0/all_stores';
            props.onStoreFilterSelection({...props.storeFilter, filters: ['isClickCollect']});
            localStorage.setItem('storeFilter_isClickCollect', 'true')
            props.history.push(url);
    }

    const showAllStores = () => {
        // if (props.storesNames !== null && !props.storesNamesError && !props.loadingStoresNames) {
            // props.onMatchedStoresChanged(props.storesNames);
            const url = '/searched-stores/0/all_stores';
            props.history.push(url);
        // }
    }

    const showAllArticles = () => {
        if (props.articles !== null && !props.articlesError && !props.loadingArticles) {
            const url = '/searched-articles/0';
            props.history.push(url);
        }
    }

    let pharmaciesBox =
            props.apotekaRsMarketplaces !== null &&
            <MainPageCounter
                counterSubtitle={apoteka}
                onClick={props.apotekaRsMarketplaces ? showAllStores : null}
                clickable
            >
                <CountUp
                    start={0}
                    end={parseInt(props.apotekaRsMarketplaces)}
                    delay={0}
                    duration={3}
                    separator="."
                >
                    {({countUpRef, start}) => (
                        <div onLoad={start} className={styles.Numbers}>
                            <span ref={countUpRef}/>
                        </div>
                    )}
                </CountUp>
            </MainPageCounter>


    const articlesBox =
            props.numOfArticles !== null &&
            <MainPageCounter
                counterSubtitle={articles}
                onClick={props.numOfArticles ? showAllArticles : null}
                clickable
            >
                <CountUp
                    start={0}
                    end={parseInt(props.numOfArticles)}
                    delay={0}
                    duration={3}
                    separator="."
                >
                    {({countUpRef, start}) => (
                        <div onLoad={start} className={styles.Numbers}>
                            <span ref={countUpRef}/>
                        </div>
                    )}
                </CountUp>
            </MainPageCounter>


    const cncBox =
            props.clickAndCollectMarketplaces !== null &&
            <MainPageCounter counterSubtitle={CnCText}
                             onClick={props.clickAndCollectMarketplaces ? showClickAndCollectStores : null}
                             clickable> {/*clickable je parametar koji se unosi ako zelimo da MainPageCounter primi jos jednu css klasu, tj ako je Counter klikabilan*/}
                <CountUp
                    start={0}
                    end={parseInt(props.clickAndCollectMarketplaces)}
                    delay={0}
                    duration={3}
                    separator="."
                >
                    {({countUpRef, start}) => (
                        <div onLoad={start} className={styles.Numbers}>
                            <span ref={countUpRef}/>
                        </div>
                    )}
                </CountUp>
            </MainPageCounter>

    return (
        <div className={styles.MainPageCountersWrapper}>
            {pharmaciesBox}
            <VerticalLine style={{height: "60px"}}/>
            {articlesBox}
            <VerticalLine style={{height: "60px"}}/>
            {cncBox}
        </div>
    );

}

const mapStateToProps = (state) => {
    return {
        apotekaRsMarketplaces: state.mainPage.apotekaRsMarketplaces,
        clickAndCollectMarketplaces: state.mainPage.clickAndCollectMarketplaces,
        numOfArticles: state.mainPage.numOfArticles,
        storesNames: state.mainPage.storesNames,
        storesNamesError: state.mainPage.storesNamesError,
        loadingStoresNames: state.mainPage.loadingStoresNames,
        searchString: state.mainPage.searchString,

        articles: state.mainPage.articles,
        articlesNames: state.mainPage.articlesNames,
        articlesError: state.mainPage.articlesError,
        loadingArticles: state.mainPage.loadingArticles,

        storeFilter: state.filter.storeFilter
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onMatchedStoresChanged: (matchedStores) => dispatch(actions.matchedStoresChanged(matchedStores)),
        onMatchedItemsChanged: (matchedItems) => dispatch(actions.matchedItemsChanged(matchedItems)),
        onStoreFilterSelection: (storeFilter) => dispatch(actions.storeFilterSelection(storeFilter))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MainPageCounters));