import React, {useEffect, useState, Fragment} from "react";
import styles from "./ShoppingCartListItem.module.css";
import DefaultImage from "../../../../assets/PictureHolder.png";
import {MinusSmIcon, PlusSmIcon, QuestionMarkCircleIcon, XIcon} from "@heroicons/react/outline";
import ConfigData from "../../../../config.json";
import TooltipComponent from "../../../UI/TooltipComponent/TooltipComponent";
import {ReactComponent as RegulativaIcon} from "../../../../assets/icons/regulativa_s.svg";
import Media from "react-media";
import ItemAvailabilityBadge from "../../../UI/ItemAvailabilityBadge/ItemAvailabilityBadge";
import LoyaltyBadge from "../../../UI/LoyaltyBadge/LoyaltyBadge";

const ShoppingCartListItem = (props) => {

    const [quantityValue, quantityValueSetter] = useState(props.itemInShoppingCart.quantity);

    useEffect(() => {
        if (quantityValue !== props.itemInShoppingCart.quantity) {
            if (quantityValue > props.itemInShoppingCart.stockQuantity) {
                quantityValueSetter (props.itemInShoppingCart.stockQuantity);
            }
            props.editQuantityOfShoppingCartItem(quantityValue)
        }
    }, [quantityValue])

    useEffect(() => {
        quantityValueSetter(props.itemInShoppingCart.quantity)
    }, [props.itemInShoppingCart.quantity])

    const selectValue = (e) => {
        e.target.select();
    }

    const handleChange = (event) => {
        const min = null;
        const max = 999;
        let quantityValue = Math.max(min, Math.min(max, Number(event.target.value)))
        if(quantityValue > 0) {
            quantityValueSetter(Math.max(min, Math.min(max, Number(event.target.value))));
        } else {
            quantityValueSetter(null)
        }
    }

    const checkIfZero = (event) => {
        if(event.target.value <= 0) {
            quantityValueSetter(1)
        }
    }

    const incrementQuantity = () => {
        props.increaseQuantityOfShoppingCartItem();
    }

    const decrementQuantity = () => {
        props.decreaseQuantityOfShoppingCartItem();
    }

    const itemAvailability = props.itemInShoppingCart.itemResponse.itemAvailabilityCode !== null  &&
            <ItemAvailabilityBadge className={styles.ItemAvailability} itemResponse={props.itemInShoppingCart.itemResponse}/>

    const loyaltyPointsIcon = props.itemInShoppingCart.itemResponse.itemLoyalityPoints > 0 ?
        <div className={styles.LoyaltyPointsIcon}>
            <LoyaltyBadge
                number={props.itemInShoppingCart.itemResponse.itemLoyalityPoints / 10}
            />
        </div>
        : <div style={{width:"70px"}}></div>


    const itemImage =
        <div>
            {props.itemInShoppingCart.itemResponse.jkl !== null && props.itemInShoppingCart.itemResponse.jkl.trim() !== "" && props.itemInShoppingCart.itemResponse.itemAvailabilityCode !== 'BR' ?
                <RegulativaIcon />
                :
                <img style={{maxWidth: "5rem", maxHeight: "5rem"}}
                     src={props.itemInShoppingCart.itemResponse.pictures !== null ? process.env.REACT_APP_CDN_PATH + props.itemInShoppingCart.itemResponse.pictures : DefaultImage}
                     alt=""/>
            }
        </div>

    const itemPrice =
        <div className={styles.ItemPrice}
            style={{display:"flex", alignItems:"center"}}
        >
            {!props.itemInShoppingCart.itemResponse.jkl ?
                (props.itemInShoppingCart.priceWithDiscount && props.itemInShoppingCart.priceWithDiscount > 0 ?
                        props.itemInShoppingCart.priceWithDiscount.toLocaleString("de-DE", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                        })
                :
                props.itemInShoppingCart.price.toLocaleString("de-DE", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                })
                )
                :
                <div className={styles.ItemPriceQuestionMark}>
                    <QuestionMarkCircleIcon style={{width: "24px"}}/>
                    <TooltipComponent className={styles.TooltipText} text="Zbog zakonskih regulativa nije moguće prikazati cenu"/>
                </div>
            }
            &nbsp;RSD</div>
    // : <p style={{display:"flex", alignItems:"center"}}><QuestionMarkCircleIcon style={{width:"24px"}}/> RSD</p>)

    const quantitySet =
        <>
            <button className={styles.Button}
                    onClick={decrementQuantity}><MinusSmIcon
                className={styles.IconStyle}/>
            </button>
            <input className={styles.QuantityText} type="number" maxLength='3' minLength="1" min="1" max="999" value={quantityValue}
                   onFocus={(e)=> selectValue(e)}
                   onChange={(e) => handleChange(e)}
                   onBlur={(e) => checkIfZero(e)}
            />
            <button className={styles.Button}
                    onClick={incrementQuantity}><PlusSmIcon
                className={styles.IconStyle}/>
            </button>
        </>

    return (
        <React.Fragment>
            {window.location.pathname.includes('shoppingCart') ?
                //Prvi korak zavrsetka kupovine
                <Media queries={{
                    small: "(max-width: 699px)",
                    large: "(min-width: 700px)"
                }}>
                    {matches => (
                        <Fragment>
                            {matches.small &&
                                <div className={styles.ShoppingCartListItem} style={{justifyContent:"space-between", padding:"8px 0", position:"relative"}}>
                                    <div className={styles.LeftPartSideDrawer}>
                                        <div className={styles.Image} style={{padding: "0"}}>
                                            {itemImage}
                                            {itemAvailability}
                                        </div>
                                        <div className={styles.Quantity}>
                                            {quantitySet}
                                            {/*<input className={styles.QuantityText} readOnly type="number" maxLength='2' value={quantityValue}/>*/}
                                        </div>
                                    </div>
                                    <div className={styles.RightPartSideDrawer}>
                                        <p style={{fontWeight: "700"}}>{props.itemInShoppingCart.itemResponse.name}</p>

                                        <div className={styles.BottomPartSideDrawer}>
                                            {itemPrice}

                                            {loyaltyPointsIcon}
                                        </div>
                                    </div>
                                </div>
                            }
                            {matches.large &&
                                <div className={styles.ShoppingCartListItem}>
                                    <div className={styles.Image} style={{minWidth: "100px", minHeight: "100px", margin:"6px 0"}}>
                                        {itemImage}
                                        {itemAvailability}
                                    </div>
                                    {/*{window.location.pathname.includes('shoppingCart') ?*/}
                                    <div className={styles.RightPartShopPage} style={{height: "105px", position: "relative", width:"100%"}}>
                                        <p style={{width: "60%"}}>{props.itemInShoppingCart.itemResponse.name}</p>
                                        <div style={{display:"flex", justifyContent:"space-between", width:"100%"}}>
                                            <div style={{position:"absolute"}}>
                                                {loyaltyPointsIcon}
                                            </div>
                                            <div className={styles.Quantity} style={{marginLeft: "300px"}}>
                                                {quantitySet}
                                            </div>
                                            {itemPrice}
                                        </div>
                                    </div>
                                    <XIcon style={{
                                        color: "#4a4f54ff",
                                        width: "20px",
                                        cursor: "pointer",
                                        marginLeft: "2rem"
                                    }}
                                           onClick={() => props.removeItemFromShoppingCart()}/>
                                </div>
                            }
                        </Fragment>
                    )}
                </Media>
                :
                (window.location.pathname.includes('confirmation') ?
                    <div className={styles.ShoppingCartListItem}>
                        <div className={styles.Image} style={{minWidth: "100px", minHeight: "100px", marginTop:"16px"}}>
                            {itemImage}
                            {itemAvailability}
                        </div>
                        <div className={styles.RightPartShopPage} style={{position: "relative", width:"100%", marginBottom:"8px"}}>
                            {/*className={window.location.pathname.includes('shop') ? styles.SearchBarShopPage : null}*/}
                            <p style={{width: "60%"}}>{props.itemInShoppingCart.itemResponse.name}</p>
                            <div style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
                                <div style={{position: "absolute"}}>
                                    {loyaltyPointsIcon}
                                </div>
                                <p className={styles.QuantityText} style={{
                                    width: "20%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    marginLeft:"300px"
                                }}>Količina: {props.itemInShoppingCart.quantity}</p>
                                {itemPrice}
                            </div>
                        </div>
                    </div>
                    :
                    // korpa (SideDrawer)
                    <div className={styles.ShoppingCartListItem} style={{display:"flex", flexDirection:"row", padding:"8px 20px 8px 6px", position:"relative"}}>
                        <div className={styles.LeftPartSideDrawer}>
                            <div className={styles.Image} style={{padding: "0"}}>
                                {itemImage}
                                {itemAvailability}
                            </div>
                            <div className={styles.Quantity}>
                                {quantitySet}
                            </div>
                        </div>
                        <div className={styles.RightPartSideDrawer}>
                            <p style={{fontWeight: "700"}}>{props.itemInShoppingCart.itemResponse.name}</p>

                            <div className={styles.BottomPartSideDrawer}>
                                {itemPrice}
                                {loyaltyPointsIcon}
                            </div>
                        </div>
                        <XIcon style={{
                            position: "absolute",
                            right: "0",
                            top:"20px",
                            color: "#4a4f54ff",
                            height: "20px",
                            width: "20px",
                            cursor: "pointer"
                        }}
                               onClick={() => props.removeItemFromShoppingCart()}/>
                    </div>)
            }
        </React.Fragment>
    );
}

export default ShoppingCartListItem;