import React, {useEffect, useState} from 'react'
import {useForm} from "react-hook-form";
import styles from './EditNameAndSurname.module.css';
import authPageStyles from "../../../../AuthPage/AuthPage.module.css";
import * as actions from "../../../../../store/actions";
import {connect} from "react-redux";
import Input from "../../../../UI/Input/Input";

const EditNameAndSurname = (props) => {

    useEffect(()=>{
        if(props.triggerEnterNameAndSurnameComponent)
         onEditNameSubmit()
    }, [props.triggerEnterNameAndSurnameComponent])

    useEffect(() => {
        if (props.userProfile === null) {
            props.onFetchUserProfile(localStorage.getItem('email'));
        }
    }, [])

    const [inputState, inputStateChanged] = useState({
            user: {
                id: "",
                firstNameForOrder: "",
                secondNameForOrder: "",
            }
        }
    )

    const [saveNameToProfile, saveNameToProfileSetter] = useState(localStorage.getItem('saveNameToProfile') === 'true')

    useEffect(()=>{
        if(saveNameToProfile) {
            localStorage.setItem('saveNameToProfile', 'true')
        } else {
            localStorage.removeItem('saveNameToProfile')
        }
    }, [saveNameToProfile])
    
    // Na ucitavanje vuce firstName i secondName za order, ako ga ima. Ako nema, vuce sa profila, ako ga ima.
    useEffect(()=>{
        if (localStorage.getItem('firstNameForOrder') && localStorage.getItem('secondNameForOrder')) {
            setValue('firstNameForOrder', localStorage.getItem('firstNameForOrder'))
            setValue('secondNameForOrder', localStorage.getItem('secondNameForOrder'))
            inputStateChanged({
                user: {
                    id: localStorage.getItem('id'),
                    firstNameForOrder: localStorage.getItem('firstNameForOrder'),
                    secondNameForOrder: localStorage.getItem('secondNameForOrder')
                }
            })
        } else if(localStorage.getItem('firstName') && localStorage.getItem('secondName')){
            setValue('firstNameForOrder', localStorage.getItem('firstName'))
            setValue('secondNameForOrder', localStorage.getItem('secondName'))
            inputStateChanged({
                user: {
                    id: localStorage.getItem('id'),
                    firstNameForOrder: localStorage.getItem('firstName'),
                    secondNameForOrder: localStorage.getItem('secondName')
                }
            })
        }
    }, [])

    useEffect(() => {
        if(inputState.user.firstNameForOrder && inputState.user.firstNameForOrder.trim() !== '') {
            setValue('firstNameForOrder', inputState.user.firstNameForOrder)
            localStorage.setItem('firstNameForOrder', inputState.user.firstNameForOrder)
        } else {
            localStorage.removeItem('firstNameForOrder');
        }

        if(inputState.user.secondNameForOrder && inputState.user.secondNameForOrder.trim() !== '') {
            setValue('secondNameForOrder', inputState.user.secondNameForOrder)
            localStorage.setItem('secondNameForOrder', inputState.user.secondNameForOrder)
        } else {
            localStorage.removeItem('secondNameForOrder');
        }
    }, [inputState])

    const {register, handleSubmit, formState: {errors, isDirty}, getValues, setValue} = useForm({
        defaultValues: {
            firstNameForOrder: inputState.user.firstNameForOrder || localStorage.getItem('firstNameForOrder'),
            secondNameForOrder: inputState.user.secondNameForOrder || localStorage.getItem('secondNameForOrder')
        },
        checkbox: []
    });

    const onEditNameSubmit = () => {

        const values = getValues();

        if (isDirty) {
            inputStateChanged({
                user: {
                    // ...inputState.user,
                    id: props.userProfile.id,
                    firstNameForOrder: values.firstNameForOrder,
                    secondNameForOrder: values.secondNameForOrder
                }
            })

            if (localStorage.getItem('saveNameToProfile') === 'true') {
                const updatedUserProfile = {
                    ...props.userProfile,
                    id: props.userProfile.id,
                    firstName: values.firstNameForOrder && values.firstNameForOrder,
                    secondName: values.secondNameForOrder && values.secondNameForOrder
                }
                props.onUpdateUserProfile(updatedUserProfile);
            }
        }
    }

    return (
        <div className={styles.FormWrapper}>
        <div><strong>Ime i prezime</strong></div>
            <form onBlur={handleSubmit(onEditNameSubmit)} className={styles.Form}>
                <div style={{display: "flex", flexDirection: "column", width: "100%"}}>
                    <input className={styles.EditInputFiled} style={{marginRight: "1rem"}} type="text" placeholder="Ime"
                           {...register("firstNameForOrder", {
                               minLength: {
                                   value: 2,
                                   message: "Ime mora sadržati najmanje 2 karaktera"
                               },
                               maxLength: {
                                   value: 20,
                                   message: "Ime ne može sadržati više od 20 karaktera"
                               },
                               pattern: {
                                   value: /^[ a-zA-ZљњертжуиопшђасдфгхјклчћзџцвбнмšđžčćЉЊЕРТЖУИОПШЂАСДФГХЈКЛЧЋЗЏЦВБНМŠĐŽČĆ'`'\-]+$/,
                                   message: "Ime nije odgovarajućeg formata"
                               }
                           })} />
                    {errors.firstNameForOrder &&
                        <span className={authPageStyles.ErrorMessage}>{errors.firstNameForOrder.message}</span>}
                </div>
                <span style={{width: "2rem"}}></span>
                <div style={{display: "flex", flexDirection: "column", width: "100%"}}>
                    <input className={styles.EditInputFiled} type="text" placeholder="Prezime"
                           {...register("secondNameForOrder", {
                               minLength: {
                                   value: 2,
                                   message: "Prezime mora sadržati najmanje 2 karaktera"
                               },
                               maxLength: {
                                   value: 50,
                                   message: "Prezime ne može sadržati više od 50 karaktera"
                               },
                               pattern: {
                                   value: /^[ a-zA-ZљњертжуиопшђасдфгхјклчћзџцвбнмšđžčćЉЊЕРТЖУИОПШЂАСДФГХЈКЛЧЋЗЏЦВБНМŠĐŽČĆ'`'\-]+$/,
                                   message: "Prezime nije odgovarajućeg formata"
                               }
                           })}
                    />
                    {errors.secondNameForOrder &&
                        <span className={authPageStyles.ErrorMessage}>{errors.secondNameForOrder.message}</span>}
                </div>

        </form>
            {isDirty &&
                <>
                    <br/>
                    <div style={{display: "flex", alignItems: "center"}}>
                        <Input inputtype="checkbox" type="checkbox"
                               style={{opacity: "1", width: "fit-content", position: "relative"}}
                            checked={saveNameToProfile}
                            onChange={() => {
                                saveNameToProfileSetter(!saveNameToProfile)
                            }}
                        />
                        <div>Želim da sačuvam ime i prezime u svom profilu</div>
                    </div>
                </>
            }
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        userProfile: state.userProfile.userProfile,
        userProfileError: state.userProfile.userProfileError,
        loadingUserProfile: state.userProfile.loadingUserProfile,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onUpdateUserProfile: (userProfile) => dispatch(actions.updateUserProfile(userProfile)),
        onFetchUserProfile: (email) => dispatch(actions.fetchUserProfile(email)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditNameAndSurname);