import React, {Fragment, useEffect, useState} from 'react';
import styles from './NavigationItems.module.css'
import NavigationItem from './NavigationItem/NavigationItem'
import {connect} from "react-redux";
import * as actions from "../../../store/actions";
import UserMenu from './../UserMenu/UserMenu';
import Backdrop from "../../UI/Backdrop/Backdrop";
import {BellIcon, ChevronDownIcon} from "@heroicons/react/outline";
import {ReactComponent as ShoppingCartIcon} from "../../../assets/icons/cart_icon.svg"
import {withRouter} from "react-router-dom";
import TooltipComponent from "../../UI/TooltipComponent/TooltipComponent";
import {ReactComponent as LoginIcon} from "./../../../assets/icons/login_icon.svg";
import Media from "react-media";
import {ReactComponent as LocationIcon} from "../../../assets/icons/pin_icon.svg";
import RFZOButton from "../../UI/Buttons/RFZOButton/RFZOButton";
import RFZOMobileButton from "../../UI/Buttons/RFZOButton/RFZOMobileButton";
import RFZOTherapyModal from "../../UI/Modals/RFZOTherapyModal/RFZOTherapyModal";
import NoAddressModal from "../../SearchResultsPage/NoAddressModal/NoAddressModal";
import {textToUrl} from "../../GobalFunctions/GlobalFunctions";

const NavigationItems = (props) => {

    const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);

    const [shoppingCartClass, shoppingCartClassUpdater] = useState(styles.IconStyle);

    /**
     *  Deo koda koji enabluje ili disabluje link za aktivnu korpu
     * */
    const [goToActiveCartLinkEnabled, goToActiveCartLinkEnabledHandler] = useState(false);
    const [isRFZOTherapyModalVisible, setIsRFZOTherapyModalVisible] = useState(false)
    const [isNoAddressModalVisible, setIsNoAddressModalVisible] = useState(false)

    const [isAdmin, isAdminSetter] = useState(false)

    useEffect(() => {
        if (props.isLoggedIn) {
            props.userInfo.roles.map(role => {
                if (role === 'ADMIN') {
                    isAdminSetter(true);
                }
            });
        }
    }, [])

    useEffect(() => {
        // if(props.isLoggedIn === true && props.allShoppingCarts === null) {
        if (props.allShoppingCarts === null || props.allShoppingCarts === false) {
            props.onFetchAllShoppingCarts(localStorage.getItem('id'));
            goToActiveCartLinkEnabledHandler(false);
        } else {
            goToActiveCartLinkEnabledHandler(true);
            props.onReturnNumberOfItemsInShoppingCart(props.allShoppingCarts!== null ? props.allShoppingCarts[0].items.length : 0);
        }
    }, [])

    useEffect(()=>{
        if(!props.chooseLocationModalToggle) {
            let place;
            place = {
                formatted_address: localStorage.getItem('enteredAddress'),
                lat: localStorage.getItem('addressLatitude'),
                lng: localStorage.getItem('addressLongitude')
            }
            props.onEnteredAddressData(place);
        }
    },[])

    useEffect(()=>{
        if(!sessionStorage.getItem('onFirstLoad')) {
            if(!localStorage.getItem('enteredAddress')) {
                openEnterAddressModal();
            }
            sessionStorage.setItem('onFirstLoad', '1');
        }
    },[])

    const logoutHandler = () => {
        props.onReturnNumberOfItemsInShoppingCart(0);
        props.onAllShoppingCarts(null);
        setIsUserMenuOpen(false);
        props.onResetUserTherapies(null);
        props.onLogout();
        props.onResetUserInfo(null);
        props.onResetUserProfile(null);
    }

    const userMenuHandler = () => {
        setIsUserMenuOpen(!isUserMenuOpen);
    }

    const clickOnBackdropHandler = () => {
        setIsUserMenuOpen(false);
    }

    const shopCardSideDrawerHandler = () => {
        console.log("shoppingCardDrawer:" + props.shoppingCardDrawer);
        props.onShowShoppingCartDrawer();
    }

    useEffect(() => {
        if (props.allShoppingCarts !== null && props.allShoppingCarts !== false) {
            goToActiveCartLinkEnabledHandler(true);
            props.onReturnNumberOfItemsInShoppingCart(props.allShoppingCarts[0].items.length);
        } else {
            goToActiveCartLinkEnabledHandler(false);
        }
    }, [props.allShoppingCarts])

    useEffect(() => {
        if (props.shoppingCart !== null && props.shoppingCart.items.length > 0) {
            goToActiveCartLinkEnabledHandler(true);
            props.onReturnNumberOfItemsInShoppingCart(props.shoppingCart.items.length);
        } else {
            goToActiveCartLinkEnabledHandler(false);
        }
    }, [props.shoppingCart])

    /** Redirekcija ka aktivnoj korpi*/
    const goToActiveCart = () => {
        if (props.userInfo.id) {
            setTimeout(() => {
                if (props.allShoppingCarts !== null) {
                    let url = '/shop/' + props.allShoppingCarts[0].marketplaceResponse.branchMarketplaceResponse.id + '/' + props.allShoppingCarts[0].marketplaceResponse.marketplaceId + '/0/' + textToUrl(props.allShoppingCarts[0].marketplaceResponse.branchMarketplaceResponse.name) + '/' + textToUrl(props.allShoppingCarts[0].marketplaceResponse.name);
                    props.onResetSelectedStore(null);
                    // shoppingCartCheckHandler(false);
                    localStorage.setItem('beforeEnteringStore', window.location.pathname);
                    props.history.replace(url);
                }
            }, 500);
        }
    }

    const [number, numberUpdater] = useState(0)

    useEffect(() => {

        if (number < props.numberOfItemsInShoppingCart) {
            shoppingCartClassUpdater([styles.IconStyle, styles.AddToCartAnimation].join(' '));
            numberUpdater(props.numberOfItemsInShoppingCart);

            setTimeout(() => {
                shoppingCartClassUpdater(styles.IconStyle);
            }, 1000)
        } else if (number > props.numberOfItemsInShoppingCart) {
            numberUpdater(props.numberOfItemsInShoppingCart)
        }
    }, [props.numberOfItemsInShoppingCart]);

    const ShoppingCartClickHandler = () => {
        if (window.location.pathname.includes('shop')) {
            shopCardSideDrawerHandler();
        } else {
            if (goToActiveCartLinkEnabled)
                goToActiveCart();
        }
    }

    const openEnterAddressModal = () => {
        if(isNoAddressModalVisible) {
            setIsNoAddressModalVisible(false)
        }
        props.onChooseLocationModalToggle(true);
    }

    const openRFZOModal =()=> {
        setIsRFZOTherapyModalVisible(true)
    }

    const closeRFZOModal =()=> {
        setIsRFZOTherapyModalVisible(false)
    }

    const startRFZOModal = () =>{
        if(localStorage.getItem('enteredAddress')) {
            openRFZOModal()
        } else {
            setIsNoAddressModalVisible(true)
        }
    }

    const closeNoAddressModal =()=> {
        setIsNoAddressModalVisible(false);
    }

    const redirectToLogin = () => {
        const url = '/auth/login';
        props.history.replace(url);
    }

    return (
        <React.Fragment>
            <div className={styles.NavigationItems}>
                {props.isLoggedIn && isAdmin ?
                    <NavigationItem link="/cms">
                        <div className={styles.LoginText}>CMS</div>
                    </NavigationItem>
                    : null}


                {/*Dumici za RFZO i Lokaciju (obrnut je raspored za web i mobile)*/}
                {/*{props.isLoggedIn &&*/}
                    <Media
                        queries={{
                            small: "(max-width: 699px)",
                            large: "(min-width: 700px)"
                        }}>

                        {matches => (
                            <Fragment>
                                {matches.small &&
                                    <>
                                        <NavigationItem onClick={openEnterAddressModal}>
                                            <div style={{display: "flex", alignItems: "center"}}>
                                                <LocationIcon
                                                    style={props.place !== null && props.place.formatted_address !== null ? {stroke: "#00B2B1"} : {stroke: "#88959eff"}}/>
                                            </div>
                                        </NavigationItem>
                                        <RFZOMobileButton style={{marginRight:"0.25rem"}}
                                            onClick={props.isLoggedIn ? startRFZOModal : redirectToLogin}/>
                                    </>
                                }

                                {matches.large &&
                                    <>
                                        <RFZOButton onClick={props.isLoggedIn ? startRFZOModal : redirectToLogin}/>
                                        <NavigationItem onClick={openEnterAddressModal}>
                                            <div style={{display: "flex", alignItems: "center", marginLeft: "8px"}}>
                                                {
                                                    props.place !== null && props.place.formatted_address !== null
                                                        ?
                                                        (props.place.formatted_address.includes(',')
                                                            ? <div
                                                                className={styles.LoginText}> {props.place.formatted_address.split(',')[0] + ', ' + props.place.formatted_address.split(',')[1]}</div>
                                                            : <div
                                                                className={styles.LoginText}> {props.place.formatted_address}</div>)
                                                        : <div className={styles.LoginText}> Unesi adresu </div>
                                                }
                                                &nbsp;
                                                <ChevronDownIcon style={{width: "18px"}}/>
                                            </div>
                                        </NavigationItem>
                                    </>
                                }
                            </Fragment>
                        )}
                    </Media>
                {/*}*/}

                {!window.location.pathname.includes('auth/login') || window.location.pathname.includes('auth/registration') ?
                    (!props.isLoggedIn ?
                        <NavigationItem link="/auth/login" isActive>
                            {/*Postavlja se u localStorage trenutna putanja da bi se po login-u moglo vratatiti na istu stranicu odakle se otislo na stranicu za login*/}
                            <div className={styles.LoginText}
                                 onClick={() => {
                                localStorage.setItem("beforeLogin", window.location.pathname)
                            }}
                                 style={{border: "1px solid #4D5055", padding: "8px 6px", borderRadius: "6px", textDecoration: "none", display:"flex", alignItems:"center"}}
                            ><LoginIcon style={{width:"18px"}}/> &nbsp; Prijavi se
                            </div>
                        </NavigationItem> :
                        <div onClick={userMenuHandler}>
                            {/*TODO srediti sliku ako postoji, smanjiti je i centralizovati je*/}
                            <div
                                // style={props.userInfo.userPhoto !== null ? {backgroundImage: "url(" + props.userInfo.userPhoto + ")"} : {backgroundColor: localStorage.getItem('color')}}
                                style={{backgroundColor: localStorage.getItem('color')}}
                                className={styles.UserLoginBox}
                                /*style={{backgroundColor: localStorage.getItem('color')}}*/>
                                <div>
                                    {props.userInfo.username.charAt(0).toUpperCase()}
                                    {/*<img src={props.userInfo.userPhoto} alt=""/>*/}
                                </div>
                            </div>
                            <UserMenu loggedIn={isUserMenuOpen}
                                      logout={logoutHandler}/>
                        </div>)
                    : null
                }

                {/*{props.isLoggedIn &&*/}
                {/*    <NavigationItem link={"/"}>*/}
                {/*        <BellIcon className={styles.IconStyle}/>*/}
                {/*    </NavigationItem>*/}
                {/*}*/}

                {/*<div>*/}
                {/*    <NavigationItem link={!props.isLoggedIn ? "/auth/login" : props.location.replace(window.location.href)}*/}
                {/*                    onClick={props.isLoggedIn && (() => {*/}
                {/*                        userMenuHandler()*/}
                {/*                    })} isActive> <UserIcon*/}
                {/*        className={styles.IconStyle}/></NavigationItem>*/}
                {/*    <UserMenu loggedIn={isUserMenuOpen}*/}
                {/*              logout={logoutHandler}*/}
                {/*    />*/}
                {/*</div>*/}
                {/*<ShopCartDrawerToggle onClick={shopCardSideDrawerHandler}><NavigationItem link={"/"}}> <ShoppingCartIcon className = {styles.IconStyle}/> </NavigationItem></ShopCartDrawerToggle>*/}
                {props.isLoggedIn &&
                <div className={styles.MainPageShoppingCartIcon}
                     onClick={ShoppingCartClickHandler}
                     style={goToActiveCartLinkEnabled ? {cursor: "pointer"} : null}
                >
                    <div id='cart' style={{display:"flex", alignItems:"center"}}>
                        <ShoppingCartIcon className={shoppingCartClass}/>
                    </div>
                    <NavigationItem
                                    itemsInCartCounter={props.numberOfItemsInShoppingCart.toString()}
                                    disabled={!goToActiveCartLinkEnabled}
                                    isLoggedIn={props.isLoggedIn}
                                    // marginRight={'1rem'}
                    >
                    </NavigationItem>
                    {
                        (!window.location.pathname.includes('shop') && !window.location.pathname.includes('articles-info') && !window.location.pathname.includes('myprofile') && !window.location.pathname.includes('orderCompletion') && (props.numberOfItemsInShoppingCart > 0) && props.isLoggedIn) &&
                        <TooltipComponent className={styles.YouHaveActiveCartBalloon} text="Imate započetu rezervaciju!"/>
                        // <span className={styles.YouHaveActiveCartBalloon}>
                        //     Imate započetu kupovinu!
                        // </span>
                    }


                    {/*{showYouHaveActiveCatBaloon ?*/}
                    {/*    (!window.location.pathname.includes('shop') && !window.location.pathname.includes('articles-info') && !window.location.pathname.includes('myprofile') && !window.location.pathname.includes('orderCompletion') && (numberOfItemsInCart > 0) && props.isLoggedIn) &&*/}
                    {/*<div className={styles.YouHaveActiveCartBalloon} onClick={ShoppingCartClickHandler}>*/}
                    {/*    Imate započetu kupovinu!*/}
                    {/*    <div className={styles.CloseYouHaveActiveCartBalloon} >*/}
                    {/*        <XIcon style={{stroke: "white", width: "16px"}} onClick={e => {closeYouHaveActiveCartBalloon(e)}}/>*/}
                    {/*    </div>*/}
                    {/*</div> */}
                    {/*    :*/}
                    {/*    null*/}

                </div>
                }
                {/*<NavigationItem link={"/"} >*/}
                {/*    <MenuIcon className={styles.IconStyle}/>*/}
                {/*</NavigationItem>*/}
                {/*</ul>*/}
            </div>
            {isRFZOTherapyModalVisible &&
                <RFZOTherapyModal
                    show={isRFZOTherapyModalVisible}
                    onCloseClick={closeRFZOModal}
                    backdropClickHandler={closeRFZOModal}
                    onToolbar
                />
            }
            {isNoAddressModalVisible &&
                <NoAddressModal onCloseClick={closeNoAddressModal}
                                show={isNoAddressModalVisible}
                                backdropClickHandler={closeNoAddressModal}
                                cancel={closeNoAddressModal}
                                openEnterAddressModal={openEnterAddressModal}
                />
            }
            <Backdrop show={isUserMenuOpen}
                      backdropClickHandler={clickOnBackdropHandler}
                      zIndex={10}
                      backgroundColor="transparent"/>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => {
    return {
        shoppingCardDrawer: state.toolbar.shoppingCardDrawer,
        shoppingCart: state.shopPage.shoppingCart,
        isLoggedIn: state.authPage.token !== null,
        userInfo: state.authPage.userInfo,
        allShoppingCarts: state.shopPage.allShoppingCarts,
        numberOfItemsInShoppingCart: state.shopPage.numberOfItemsInShoppingCart,
        place: state.appPage.place,
        chooseLocationModalToggle: state.modal.chooseLocationModalToggle
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onShowShoppingCartDrawer: () => dispatch(actions.showShoppingCartDrawerChanged()),
        onLogout: () => dispatch(actions.logout()),
        onResetUserInfo: (userInfo) => dispatch(actions.userInfo(userInfo)),
        onResetUserProfile: (userProfile) => dispatch(actions.userProfile(userProfile)),
        onAllShoppingCarts: (allShoppingCarts) => dispatch(actions.allShoppingCarts(allShoppingCarts)),
        onFetchAllShoppingCarts: (userId) => dispatch(actions.fetchAllShoppingCarts(userId)),
        onResetSelectedStore: (value) => dispatch(actions.selectedStoreChanged(value)),
        onReturnNumberOfItemsInShoppingCart: (numberOfItems) => dispatch(actions.returnNumberOfItemsInShoppingCart(numberOfItems)),
        onChooseLocationModalToggle: (chooseLocationModalToggle) => dispatch(actions.chooseLocationModalToggle(chooseLocationModalToggle)),
        onEnteredAddressData: (place) => dispatch(actions.enteredAddressData(place)),
        onResetUserTherapies: (state) => dispatch(actions.userTherapies(state))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NavigationItems));