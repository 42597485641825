import React from 'react';
import ModalWithHeader from "../../ModalWithHeader/ModalWithHeader";
import Button from "../../../Buttons/Button/Button";

const IncompleteInfoErrorModal = (props) => {
    return (
        <ModalWithHeader
            show={props.show}
            backdropClickHandler={props.backdropClickHandler}
            onCloseClick={props.onCloseClick}
            style={{height:"300px", backgroundColor:"#00B2B1"}}
            buttons={
            <div style={{width:"100%", display:"flex", justifyContent:"center"}}>
                <Button buttontype="blue"
                        onClick={props.onCloseClick}
                >U redu</Button>
            </div>
            }
        >
            <div style={{color:"white", textAlign:"center", height:"100%", alignItems:"center"}}>
                <strong>Proverite da li LBO ima 11 cifara!</strong>
            </div>
        </ModalWithHeader>


    );
};

export default IncompleteInfoErrorModal;