import React from 'react';
import styles from './LoyaltyCatalogue.module.css';

const LoyaltyCatalogue = () => {
    return (
        <div className={styles.CatalogueWrapper}>
            <h3>Katalog pogodnosti</h3>
            <span>Katalog pogodnosti će biti dostupan uskoro</span>
        </div>
    );
};

export default LoyaltyCatalogue;
