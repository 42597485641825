import React from "react";
import styles from "./InfoBoxTemplate.module.css";

const InfoBoxTemplate = (props) => {
    return(
        <div className={styles.InfoBox} style={props.style}>
            {props.children}
        </div>
    );
}

export default InfoBoxTemplate;