import * as actionsTypes from './actionsTypes'
import axios from "../../axios/axios-apoteka";

export const gridListToggleView = (gridView) => {
    return {
        type: actionsTypes.GRID_LIST_TOGGLE_VIEW,
        gridView: gridView
    };
}

export const shopFilterSelection = (shopFilter) => {
    return {
        type: actionsTypes.SHOP_FILTER,
        shopFilter: shopFilter
    };
}

export const resetShopFilterSelection = () => {
    return {
        type: actionsTypes.RESET_SHOP_FILTER,
    };
}


export const storeFilterSelection = (storeFilter) => {
    return {
        type: actionsTypes.STORE_FILTER,
        storeFilter: storeFilter
    };
}

export const articleCategoryDetails = (articleCategoryDetails) => {
    return {
        type: actionsTypes.FETCH_ARTICLE_CATEGORY_DETAILS,
        articleCategoryDetails: articleCategoryDetails
    };
}

export const fetchCategoriesForArticlesStart = () => {
    return {
        type: actionsTypes.FETCH_CATEGORIES_FOR_ARTICLES_START
    };
}

export const fetchCategoriesForArticlesFailed = () => {
    return {
        type: actionsTypes.FETCH_CATEGORIES_FOR_ARTICLES_FAILED
    };
}

export const fetchCategoriesForArticles = () => {
    return dispatch => {
        dispatch(fetchCategoriesForArticlesStart());
        const URL = '/related_group';
        axios.get(URL)
            .then(response => {
                console.log(response.data)
                dispatch(articleCategoryDetails(response.data))
            })
            .catch(error => {
                console.log("Error se desio" + error);
                dispatch(fetchCategoriesForArticlesFailed())
            })
    };
}

export const filterBreadcrumbs = (filterBreadcrumbs) => {
    return {
        type: actionsTypes.FILTER_BREADCRUMBS,
        filterBreadcrumbs: filterBreadcrumbs
    }
}

export const loyaltyFilter = (loyaltyFilter) => {
    return {
        type: actionsTypes.LOYALTY_FILTER,
        loyaltyFilter: loyaltyFilter
    }
}

export const discountFilter = (discountFilter) => {
    return {
        type: actionsTypes.DISCOUNT_FILTER,
        discountFilter: discountFilter
    }
}

export const fetchAdvCategoryImageStart = () => {
    return {
        type: actionsTypes.FETCH_ADV_CATEGORY_IMAGE_START
    }
}

export const fetchAdvCategoryImageFailed = () => {
    return {
        type: actionsTypes.FETCH_ADV_CATEGORY_IMAGE_FAILED
    }
}

export const advCategoryImageFetched = (categoryImage) => {
    return {
        type: actionsTypes.ADV_CATEGORY_IMAGE,
        advCategoryImage: categoryImage
    }
}

export const fetchAdvCategoryImage = (categoryId) => {
    return dispatch => {
        dispatch(fetchAdvCategoryImageStart());
        const URL = '/advertisement/random/' + categoryId +'?type=web_group';
        axios.get(URL)
            .then(response => {
                console.log(response.data)
                dispatch(advCategoryImageFetched(response.data))
            })
            .catch(error => {
                console.log("Error se desio" + error);
                dispatch(fetchAdvCategoryImageFailed())
            })
    };
}
