import React from "react";
import ModalWithHeader from "../ModalWithHeader/ModalWithHeader";
import Button from "../../Buttons/Button/Button";

const AlertQuantityModal = (props) => {
    return(
        <ModalWithHeader noXIcon
                         buttons={
                             <div style={{width: "100%", display: "flex", justifyContent: "center"}}>
                                 <Button
                                     buttontype="blue"
                                     onClick={props.closeAlertQuantityModal}>Zatvori
                                 </Button>
                             </div>
                         }
                         show={props.show}
                         backdropClickHandler={props.backdropClickHandler}>
            <div style={{display: "flex", flexDirection: "column", alignItems: "center", marginTop:"2rem"}}>
                <p><strong>Dostigli ste maksimalan broj artikala</strong></p>
            </div>
        </ModalWithHeader>
    );
}

export default AlertQuantityModal;