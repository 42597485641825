import React, {Fragment, useEffect, useState} from "react";
import styles from './SearchResultsActionMenu.module.css';
import SquareBadge from "../../UI/SquareBadge/SquareBadge";
import Media from "react-media";
import SearchBar from "../../MainPage/SearchWrapper/SearchBar/SearchBar";
import ActionMenu from "../../UI/ActionMenu/ActionMenu";
import Wrapper from "../../UI/Wrapper/Wrapper";
import * as actions from "../../../store/actions";
import {connect} from "react-redux";
import {withRouter} from 'react-router-dom'
import StoreFilter from "./StoreFilter/StoreFilter";
import {ReactComponent as Icon24hIcon} from "../../../assets/icons/icon24hours.svg";
import {ReactComponent as DeliveryIcon} from "../../../assets/icons/delivery.svg";
import {ReactComponent as RFZOIcon} from "../../../assets/icons/rfzoIcon_bw.svg";
import {ReactComponent as CartIcon} from "../../../assets/icons/cart_icon.svg";
import {ReactComponent as FilterIcon} from "../../../assets/icons/filter_icon.svg";
import {ReactComponent as DiscountIcon} from "./../../../assets/icons/discount_icon.svg";
import ActionMenuRes from "../../../componentsRes/UIRes/ActionMenuRes/ActionMenuRes";
import HeaderWithTitleAndBackChevron
    from "../../../componentsRes/HeaderWithTitleAndBackChevron/HeaderWithTitleAndBackChevron";
import InfoBox from "../SearchResultsHeader/InfoBox/InfoBox";
import TooltipComponent from "../../UI/TooltipComponent/TooltipComponent";
import {ChevronDownIcon} from "@heroicons/react/outline";

const SearchResultsActionMenu = (props) => {

    const [isFilterModalVisible, isFilterModalVisibleHandler] = useState(false);
    const [isFilterSelected, isFilterSelectedHandler] = useState(false);

    useEffect(()=>{
        if(props.storeFilter) {
            if (props.storeFilter.filters.length === 0 &&  props.storeFilter.inMyRadius === false) {
                isFilterSelectedHandler(false)
            } else {
                isFilterSelectedHandler(true);
            }
        }
    }, [props.storeFilter])

    const filterAction = () => {
        (window.location.pathname.includes('searched-stores') || window.location.pathname.includes('articles-and-stores')) &&
        openFilterModal()
    }

    const openFilterModal = () => {
        isFilterModalVisibleHandler(true);
    }

    const closeFilterModal = () => {
        isFilterModalVisibleHandler(false);
    }

    //Kao parametar prihvata string za filtere, npr: 'isClickCollect' ili 'haveDelivery',...
    const storeFiltersArrayHandler = (filterName) => {
            let temp = [];
            if (props.storeFilter.filters) temp = props.storeFilter.filters;
            if (props.storeFilter.filters.includes(filterName)) {
                localStorage.removeItem('storeFilter_' + filterName);

                for (let i = temp.length - 1; i >= 0; i--) {
                    if (temp[i] === filterName) {
                        temp.splice(i, 1);
                    }
                }
            } else {
                localStorage.setItem('storeFilter_' + filterName, 'true')
                temp.push(filterName);
            }
            props.onStoreFilterSelection({...props.storeFilter, filters: temp})
        }

    useEffect(()=>{
        if (props.userLocationError) {
            alert("Greška prilikom učitavanje lokacije");
            // inMyRadiusButton = <p>Greška prilikom učitavanje lokacije</p>;
        }
    }, [props.userLocationError])
    //End of params for fetching user location

    let isClickAndCollectButton =
    <SquareBadge
        className={styles.QuickFilter}
        size={"32px"}
        marginLeft={"24px"}
        onClick={()=> { storeFiltersArrayHandler('isClickCollect')}}
        pointer
    >
        <CartIcon className={!props.storeFilter.filters.includes('isClickCollect') ? styles.IconStyle : styles.IconStyleClicked}/>
        <TooltipComponent className={styles.TooltipText} text="U sistemu poruči i preuzmi"/>
    </SquareBadge>

    let isRFZOButton =
        <SquareBadge
            className={styles.QuickFilter}
            size={"32px"}
            marginLeft={"12px"}
            onClick={()=>{storeFiltersArrayHandler("haveRFZO")}}
            pointer
        >
            <RFZOIcon className={!props.storeFilter.filters.includes('haveRFZO') ? styles.IconStyle : styles.IconStyleClicked}/>
            <TooltipComponent className={styles.TooltipText} text="Rezervacija RFZO terapije"/>
        </SquareBadge>;

    let haveDeliveryButton =
        <SquareBadge
            className={styles.QuickFilter}
            size={"32px"}
            marginLeft={"12px"}
            onClick={()=>{storeFiltersArrayHandler("haveDelivery")}}
            pointer
        >
            <DeliveryIcon className={!props.storeFilter.filters.includes('haveDelivery') ? styles.IconStyle : styles.IconStyleClicked}/>
            <TooltipComponent className={styles.TooltipText} text="Isporuka na adresu"/>
        </SquareBadge>

    let openingHoursButton =
        <SquareBadge
            className={styles.QuickFilter}
            size={"32px"}
            marginLeft={"12px"}
            onClick={()=>{storeFiltersArrayHandler("openingHours")}}
            pointer
        >
            <Icon24hIcon className={!props.storeFilter.filters.includes('openingHours') ? styles.IconStyle : styles.IconStyleClicked }/>
            <TooltipComponent className={styles.TooltipText} text="Otvoreno 00-24h"/>
        </SquareBadge>

    let storeWithDiscount =
        <SquareBadge
            className={styles.QuickFilter}
            size={"32px"}
            marginLeft={"12px"}
            onClick={()=>{storeFiltersArrayHandler("hasDiscount")}}
            pointer
        >
            <DiscountIcon className={!props.storeFilter.filters.includes('hasDiscount') ? styles.IconStyle : styles.IconStyleClicked }/>
            <TooltipComponent className={styles.TooltipText} text="Popusti na određene proizvode"/>
        </SquareBadge>

    const [showCityFilterState, showCityFilterStateChange] = useState({
        showCityFilter: false
    })

    /*Zatvaranje filter box-a na scroll*/
    // todo primeniti ovo zatvaranje na novi modal sa filterima
    useEffect(() => {
        if (showCityFilterState.showCityFilter) {

            window.onscroll = function () {
                if (window.pageYOffset !== 0) {
                    showCityFilterStateChange({
                        showCityFilter: false
                    })
                }
            };
        }
    }, [showCityFilterState.showCityFilter])


    const goBack =()=>{
        props.history.push('/');
    }

    return (
        <Wrapper>
            <Media
                queries={{
                    small: "(max-width: 699px)",
                    large: "(min-width: 700px)"
                }}>
                {matches => (
                    <Fragment>
                        {matches.small &&
                            <ActionMenuRes>
                                {/*todo*/}
                                {/*<div>Uneti rez pretrage (ispis iz infoboxa)</div>*/}
                                <div style={{marginBottom: "0.75rem"}}>
                                    <HeaderWithTitleAndBackChevron
                                        icon
                                        title="Dostupnost proizvoda"
                                        onIconClick={goBack}
                                    />
                                </div>
                                {!props.actionMenuSticky &&
                                <InfoBox/>
                                }
                                <div style={{display: "flex", justifyContent: "space-between"}}>
                                    <SearchBar/>
                                    <FilterIcon onClick={filterAction} className={styles.FilterIconMobile}/>
                                </div>

                                {isFilterModalVisible &&
                                <StoreFilter show={isFilterModalVisible}
                                             backdropClickHandler={closeFilterModal}>
                                </StoreFilter>}
                            </ActionMenuRes>
                        }

                        {matches.large &&
                        <ActionMenu holderStyle={{justifyContent: "flex-start", position: "relative"}}>
                            <div className={styles.SearchResultsAM}>
                                <button onClick={filterAction}
                                        className={isFilterSelected ? [styles.FilterButton, styles.FilterButtonSelected].join(' ') : [styles.FilterButton, styles.FilterButtonUnselected].join(' ')}
                                >
                                    Filteri
                                    <ChevronDownIcon style={{width:"16px", height:"16px"}}/>
                                </button>
                                {isClickAndCollectButton}
                                {isRFZOButton}
                                {haveDeliveryButton}
                                {openingHoursButton}
                                {!window.location.pathname.includes('articles-and-stores') && storeWithDiscount}
                            </div>
                            <div style={{margin: "auto"}}>
                                <SearchBar/>
                            </div>
                            {/*<p>Filter: Po gradovima</p>*/}
                            <p style={{width: "150px"}}>&nbsp;</p>

                            {isFilterModalVisible &&
                            <StoreFilter show={isFilterModalVisible}
                                         backdropClickHandler={closeFilterModal}>
                            </StoreFilter>}

                        </ActionMenu>
                        }
                    </Fragment>
                )}
            </Media>
        </Wrapper>
    );
}


const mapStateToProps = (state) => {
    return {

        // loadingUserLocation: state.appPage.loadingUserLocation,
        // userLocationError: state.appPage.userLocationError,

        // stores: state.appPage.stores,
        // matchedStores: state.storesSearchPage.stores,
        // matchedStoresNames: state.mainPage.matchedStores,

        storeFilter: state.filter.storeFilter,

        actionMenuSticky: state.actionMenu.actionMenuSticky
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        // onShowGooleMap: (showGoogleMap) => dispatch(actions.showGoogleMapChanged(showGoogleMap))

        // onFetchUserLocationStart: () => dispatch(actions.fetchUserLocationStart()),
        // onFetchUserLocationError: () => dispatch(actions.fetchUserLocationError()),
        // onFetchUserLocationFinished: () => dispatch(actions.fetchUserLocationFinished()),

        onStoreFilterSelection: (storeFilter) => dispatch(actions.storeFilterSelection(storeFilter)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SearchResultsActionMenu));