import React, {useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import styles from './DeliveryOptions.module.css'
import Button from '../../../UI/Buttons/Button/Button'
import * as actions from "../../../../store/actions";
import {connect} from "react-redux";
import OrderCompletionPageTemplate from "../OrderCompletionPageTemplate/OrderCompletionPageTemplate";
import PharmacyData from "../PharmacyData/PharmacyData";
import TotalAmount from "../TotalAmount/TotalAmount";
import {ChevronLeftIcon, ChevronRightIcon} from "@heroicons/react/outline";
import {ReactComponent as PinIcon} from "../../../../assets/icons/pin_icon.svg";
import {ReactComponent as DeliveryIcon} from "./../../../../assets/icons/delivery.svg";
import Actions from "../Actions/Actions";

const DeliveryOptions = (props) => {

    const [pharmacySelected, pharmacySelectedHandler] = useState(true);
    const [deliveryToAddressSelected, deliveryToAddressSelectedHandler] = useState(false);

    const [selectionMade, selectionMadeHandler] = useState(true);
    const [deliveryMethod, deliveryMethodHandler] = useState(localStorage.getItem('deliveryMethod') || '');

    const [haveDrugInCart, haveDrugInCartSetter] = useState(localStorage.getItem('haveDrugInCart') || false)
    const [unableToDeliver, unableToDeliverSetter] = useState("Ova apoteka ne vrši isporuku.");


    useEffect(() => {
        if (window.pageYOffset !== 0) {
            window.scrollTo(0, 0);
        }

        collectInPharmacy();
        props.shoppingCart !== null && props.shoppingCart.items.map(item => {
            if (item.itemResponse.jkl !== null) {
                haveDrugInCartSetter(true);
                localStorage.setItem('haveDrugInCart', 'true')
            }
        })

        return() =>{
            localStorage.removeItem('haveDrugInCart');
            localStorage.removeItem('selectedAddress');
            localStorage.removeItem('addressSelected');
        }
    }, [])

    useEffect(() => {
        if (haveDrugInCart) {
            collectInPharmacy()
        }

    }, [haveDrugInCart])

    useEffect(()=>{
        if(deliveryMethod !== '') {
            localStorage.setItem('deliveryMethod', deliveryMethod)
        }

        if (deliveryMethod === 'collectInPharmacy') {
            props.onCollectOrDeliveryChoice({collect: true, delivery: false});
            pharmacySelectedHandler(true);
            deliveryToAddressSelectedHandler(false);
        } else if (deliveryMethod === 'deliveryToAddress') {
            props.onCollectOrDeliveryChoice({collect: false, delivery: true});
            deliveryToAddressSelectedHandler(true);
            pharmacySelectedHandler(false);
        }
        selectionMadeHandler(true);

    }, [deliveryMethod])


    useEffect(()=> {
        //todo na reload menja poruku, ne bi trebalo da radi tako. Treba staiti haveDelivery u localstorage (mozda)
        if (props.selectedStore !== null && +props.selectedStore.marketplaceResponse.haveDelivery !== 1) {
            unableToDeliverSetter("Ova apoteka ne vrši isporuku.")
        } else if (haveDrugInCart || localStorage.getItem('haveDrugInCart') === 'true') {
            unableToDeliverSetter("Dostava nije moguća za lekove");
        }
    }, [haveDrugInCart])

    const redirectToAddressSelection = () => {
        // if(!props.collectOrDelivery.collect && deliveryMethod === 'deliveryToAddress') {
            const url = '/orderCompletion/' + props.match.params.branchMarketplaceId + "/" + props.match.params.marketplaceId + '/addresses'
                + '/' + props.match.params.branchMarketplaceName + '/' + props.match.params.marketplaceName;
            props.history.push(url);
        // } else {
        //     const url = '/orderCompletion/' + props.match.params.branchMarketplaceId + "/" + props.match.params.marketplaceId + '/confirmation';
        //     props.history.push(url);
        // }
    }

    const redirectToPreviousPage =()=> {
        const url = '/shoppingCart/' + props.match.params.branchMarketplaceId + "/" + props.match.params.marketplaceId
            + '/' + props.match.params.branchMarketplaceName + '/' + props.match.params.marketplaceName;
        props.history.push(url);
        localStorage.removeItem('haveDrugInCart');
    }

    const collectInPharmacy = () => {
        pharmacySelectedHandler(!pharmacySelected);
        deliveryToAddressSelectedHandler(false);
        props.onCollectOrDeliveryChoice({collect: true, delivery: false});
        deliveryMethodHandler('collectInPharmacy')
        selectionMadeHandler(true);
    }

    const deliveryToSelectedAddress =()=> {
        props.onCollectOrDeliveryChoice({collect: false, delivery: true});
        deliveryMethodHandler('deliveryToAddress')
        pharmacySelectedHandler(false);
        deliveryToAddressSelectedHandler(!deliveryToAddressSelected);
        selectionMadeHandler(true);
    }

    const isDisabled = () => {
        return !(selectionMade || (props.selectedStore !== null && +props.selectedStore.marketplaceResponse.haveDelivery !== 1));
    }

    return (
        <>
            <OrderCompletionPageTemplate
                topPart={
                    <Actions>
                        <Button onClick={redirectToPreviousPage}
                                style={{fontSize:"20px"}}
                        ><ChevronLeftIcon
                                style={{width: "20px", marginRight: "1rem"}}/> Nazad</Button>
                        <Button onClick={redirectToAddressSelection}
                                disabled={isDisabled()}
                                buttontype={isDisabled() ? 'disabled' : 'blue'}
                                style={{width:"170px", height:"60px", fontSize:"20px", padding: "0 2rem"}}
                        >Dalje <ChevronRightIcon style={{width: "20px", marginLeft: "1rem"}}/></Button>
                    </Actions>
                }

                leftPart={
                    <>
                        <div style={{display: "flex", flexDirection: "column", height: "100%"}}>
                            <div style={{fontSize: "24px"}}>Izaberi način preuzimanja</div>

                            <div className={styles.ChoiceWrapper}>
                                <div style={{
                                    width: "80%",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    height: "300px",
                                    marginTop:"3rem"
                                }}>

                                    <div className={styles.DeliveryChoiceButtonWrapper}>
                                        <div className={
                                            pharmacySelected ? [styles.DeliveryChoiceButton, styles.DeliverySelected].join(' ') : (!props.collectOrDelivery.collect ? styles.DeliveryChoiceButton : [styles.DeliveryChoiceButton, styles.DeliverySelected].join(' '))}>
                                            <div className={styles.DeliveryChoiceButtonHolder}
                                                 onClick={collectInPharmacy}>
                                                <h4>Preuzimanje u apoteci</h4>
                                                <PinIcon />
                                            </div>
                                        </div>
                                        {pharmacySelected &&
                                            <div style={{padding: "1rem 0", display: "flex", justifyContent: "center"}}>
                                                <div style={{color: "#00B2B1"}}>
                                                    <div>Izabrali ste preuzimanje u apoteci</div>
                                                </div>
                                            </div>
                                        }
                                    </div>

                                    {/* ISPORUKA NA ZELJENU ADRESU */}
                                    {props.selectedStore !== null && +props.selectedStore.marketplaceResponse.haveDelivery === 1 && (!haveDrugInCart || !localStorage.getItem('haveDrugInCart'))?
                                        <div className={styles.DeliveryChoiceButtonWrapper}>
                                            <div onClick={deliveryToSelectedAddress}
                                                 className={props.collectOrDelivery.delivery ?
                                                     [styles.DeliveryChoiceButton, styles.DeliverySelected].join(' ') : styles.DeliveryChoiceButton}>
                                                <div className={styles.DeliveryChoiceButtonHolder}>
                                                    <h4 style={{textAlign: "center"}}>Isporuka na adresu</h4>
                                                    <DeliveryIcon/>
                                                </div>
                                            </div>
                                            {deliveryToAddressSelected &&
                                            <div style={{
                                                padding: "1rem 0",
                                                display: "flex",
                                                justifyContent: "center"
                                            }}>
                                                <div style={{color: "#00B2B1"}}>
                                                    <div>Izabrali ste isporuku na adresu</div>
                                                </div>
                                            </div>
                                            }
                                        </div>
                                        :
                                        <div className={styles.DeliveryChoiceButtonWrapper}>
                                            <div className={styles.DeliveryChoiceButtonDisabled} style={{
                                                color: "#bdccd4ff",
                                                border: "1px solid #bdccd4ff",
                                                cursor: "auto"
                                            }}>
                                                <h4 style={{textAlign:"center"}}>Isporuka na adresu</h4>
                                            </div>

                                            <div style={{padding: "1rem 0", display: "flex", justifyContent: "center"}}>
                                                <div style={{color: "#bdccd4ff"}}>
                                                    <div>{unableToDeliver}</div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </>
                }

                rightPart = {
                    <div style={{display: "flex", flexDirection: "column", height: "100%", width: "100%"}}>
                        <TotalAmount
                            totalState={(+(Math.round(localStorage.getItem('totalPrice') + "e+2") + "e-2")).toFixed(2)}/>
                        <div>
                            <PharmacyData/>
                        </div>
                    </div>
                }
            />
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        selectedStore: state.appPage.selectedStore,
        collectOrDelivery: state.orderCompletion.collectOrDelivery,
        shoppingCart: state.shopPage.shoppingCart,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onCollectOrDeliveryChoice: (collectOrDelivery) => dispatch(actions.collectOrDeliveryChoice(collectOrDelivery))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DeliveryOptions));