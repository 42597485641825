import React, {Fragment} from 'react';
import styles from './StorePageActionMenu.module.css';
import ActionMenu from "../../UI/ActionMenu/ActionMenu";
import VerticalLine from "../../UI/VericalLine/VerticalLine";
import Media from "react-media";
import {withRouter} from 'react-router-dom'
import StorePageSubNavigation from "./StorePageSubNavigation/StorePageSubNavigation";


const StorePageActionMenu = () => {
    return (
        <Media
            queries={{
                small: "(max-width: 699px)",
                large: "(min-width: 700px)"
            }}>

            {matches => (
                <Fragment>
                    {matches.small &&
                    <div>
                        <StorePageSubNavigation/>
                    </div>
                    }

                    {matches.large &&
                    <ActionMenu holderStyle={{justifyContent: "space-between", position:"relative"}}>
                        <div className={styles.ActionsHolder}>
                            <span>Početna</span>
                            <VerticalLine style={{height:"16px", margin:"0 1rem", alignSelf:"center"}}/>
                            <span>O nama</span>
                            <VerticalLine style={{height:"16px", margin:"0 1rem", alignSelf:"center"}}/>
                            <span>Galerija</span>
                            <VerticalLine style={{height:"16px", margin:"0 1rem", alignSelf:"center"}}/>
                            <span>Ocene</span>
                        </div>
                        {/*<span style={{textDecoration:"underline"}}>Podeli</span>*/}
                    </ActionMenu>
                    }
                </Fragment>
            )}
        </Media>

    );
}

export default withRouter(StorePageActionMenu);