import React, {Fragment, useContext, useEffect, useRef, useState} from 'react'
import styles from './SummaryAndConfirmation.module.css'
import Button from "../../../UI/Buttons/Button/Button";
import {OrderSocketContext} from "../../../../store/context/OrderSocket/OrderSocket";
import ShoppingCartList from "../../../ShopPage/ShoppingCartList/ShoppingCartList";
import * as actions from "../../../../store/actions";
import {connect} from "react-redux";
import OrderCompletionPageTemplate from "../OrderCompletionPageTemplate/OrderCompletionPageTemplate";
import Backdrop from "../../../UI/Backdrop/Backdrop";
import PrescriptionImageUploader from "./PrescriptionImageUploader/PrescriptionImageUploader";
import 'react-inputs-validation/lib/react-inputs-validation.min.css';
import Input from "../../../UI/Input/Input";
import {withRouter} from 'react-router-dom'
import PrescriptionImagesThumbnails from "./PrescriptionImagesThumbnails/PrescriptionImagesThumbnails";
import TotalAmount from "../TotalAmount/TotalAmount";
import {ChevronLeftIcon} from "@heroicons/react/outline";
import Actions from "../Actions/Actions";
import PharmacyData from "../PharmacyData/PharmacyData";
import Media from "react-media";
import HeaderWithTitleAndBackChevron
    from "../../../../componentsRes/HeaderWithTitleAndBackChevron/HeaderWithTitleAndBackChevron";
import {ReactComponent as PharmacyCrossIcon} from "../../../../assets/icons/pharmacy_cross_icon.svg";
import {ReactComponent as DeliveryIcon} from "../../../../assets/icons/delivery.svg";
import OrderCompletionPageTemplateMobile from "../OrderCompletionPageTemplateMobile/OrderCompletionPageTemplateMobile";
import AlertModal from "./AlertModal/AlertModal";
import SocketModal from "../../../UI/Modals/SocketModal/SocketModal";
import Spinner from "../../../UI/Spinner/Spinner"
import ShopNotOnlineModal
    from "../../../SearchResultsPage/SearchResults/StoreItems/StoreItem/ShopUnavailableModal/ShopUnavailableModal";
import {isStoreOpenNow} from "../../../SearchResultsPage/SearchResults/StoreItems/StoreItem/StoreItem";

const Summary = props => {

    const orderSocket = useContext(OrderSocketContext);
    const closeUponExit = useRef(0);

    let deliveryMethod = localStorage.getItem('deliveryMethod') || '';
    const addressId = localStorage.getItem('selectedAddress') || null;

    const [isPrescriptionModalVisible, isPrescriptionModalVisibleHandler] = useState(false);
    const [userNote, userNoteSetter] = useState(localStorage.getItem('userNote') || '');

    const [agreement, agreementHandler] = useState(false);
    const [numberOfPrescImages, numberOfPrescImagesGetter] = useState(null);

    const [userAddress, userAddressSetter] = useState();
    const [countCharsInNote, setCountCharsInNote] = React.useState(300);

    const [tooltipFinishText, setTooltipFinishText] = useState();

    const [showAlertModal, showAlertModalSetter] = useState(false);

    const [showSocketModal, showSocketModalSetter] = useState(false);

    const [showShopIsOfflineModal, setShowShopIsOfflineModal] = useState(false)
    const [showShopIsClosedModal, setShowShopIsClosedModal] = useState(false);

    const [fromSummaryAndConfirmation, setFromSummaryAndConfirmation] = useState(false);

    useEffect(() => {
        if (props.selectedStore === null) {
            props.onFetchSelectedStore(props.match.params.marketplaceId, props.match.params.branchMarketplaceId);
        }
    }, [props.match.params.branchMarketplaceId, props.match.params.marketplaceId])

    useEffect(() => {
        if (window.pageYOffset !== 0) {
            window.scrollTo(0, 0);
        }
    }, [])

    useEffect(() => {
        if (props.userProfile === null) {
            props.onFetchUserProfile(localStorage.getItem('email'));
        }

        if (props.collectOrDelivery.collect === null || props.collectOrDelivery.delivery === null) {
            if (deliveryMethod) {
                if (deliveryMethod === 'collectInPharmacy') {
                    props.onCollectOrDeliveryChoice({collect: true, delivery: false});
                } else if (deliveryMethod === 'deliveryToAddress') {
                    props.onCollectOrDeliveryChoice({collect: false, delivery: true});
                }
            }
        }
    }, [])

    useEffect(() => {

        if (localStorage.getItem("token") === null) {
            console.log("Ne otvara web socket konekciju posto niste ulogovani")
        } else {
            orderSocket.connect();
        }
        return () => {
            if (closeUponExit.current === 0) {
                orderSocket.closeConnection();
            }
        }
    }, []);

    useEffect(() => {
        if (addressId !== null && props.userProfile !== null) {
            userAddressSetter(props.userProfile.userAddresses.map((address) => {
                if (+addressId === address.id) {
                    return (
                        <div key={address.id}>
                            <div>{localStorage.getItem('firstNameForOrder')} {localStorage.getItem('secondNameForOrder')}</div>
                            <div>{address.name} {address.number}{address.apartment !== "null" && address.apartment !== null ? '/' + address.apartment : null} {address.floor && address.floor !== 'null' ? 'sprat: ' + address.floor : null}</div>
                            <div>{address.postalCode} {address.region}, {address.city}</div>
                            <div>{localStorage.getItem('phoneNumber')}</div>
                            <div>{props.userProfile.username}</div>
                        </div>
                    )
                }
            }))
        }
    }, [props.userProfile])

    useEffect(()=>{
        if(props.shoppingCart && props.shoppingCart.prescriptionsLinks.length > 0) {
            agreementHandler(true)
        } else {
            agreementHandler(false)
        }
    }, [props.shoppingCart])

    // poziva se kada se udje u apoteku i proverava da li je apoteka otvorena i da li je online
    useEffect(() => {
        if (props.selectedStore && props.selectedStore.marketplaceResponse && props.selectedStore.marketplaceResponse.isClickAndCollect === 1 && props.selectedStore.marketplaceResponse.takesOrders === 0 && fromSummaryAndConfirmation) {
            setShowShopIsOfflineModal(true)
            setFromSummaryAndConfirmation(true);
        } else if (props.selectedStore && props.selectedStore.marketplaceResponse && props.selectedStore.marketplaceResponse.isClickAndCollect === 1 && props.selectedStore.marketplaceResponse.takesOrders === 1
        && fromSummaryAndConfirmation){
            redirectToSendOrder()
        }
    }, [props.selectedStore]);

    const connectAgain = () => {
        showSocketModalSetter(false);
        orderSocket.connect();
    }

    const sendOrderMessage = (msg) => {
        const message = {
            senderEmail: localStorage.getItem('email'),
            recipientEmail: props.selectedStore.marketplaceResponse.marketplaceUserEmail,
            senderName: localStorage.getItem('firstNameForOrder') + " " + localStorage.getItem('secondNameForOrder'),
            recipientName: props.selectedStore.branchMarketplaceResponse.name + ' ' + props.selectedStore.marketplaceResponse.name,
            content: msg,
            timestamp: new Date(),
            type: 'ORDER_REQUEST'
        };
        if(props.orderSocketConnected) {
            orderSocket.sendMessage(message);
        }
    };

    const clearLocalStorage = () => {
        localStorage.removeItem('deliveryMethod');
        localStorage.removeItem('addressSelected');
        localStorage.removeItem('selectedAddress');
        localStorage.removeItem('haveRp');
        localStorage.removeItem('totalPrice');
        localStorage.removeItem('userNote');
        localStorage.removeItem('haveDrugInCart');
        localStorage.removeItem('phoneNumber')
        localStorage.removeItem('firstNameForOrder');
        localStorage.removeItem('secondNameForOrder')
    }

    const redirectToSendOrder = () => {
        setShowShopIsClosedModal(false);
        setShowShopIsOfflineModal(false)

        if(props.orderSocketConnected) {
            if (props.collectOrDelivery !== null && !isFinishButtonEnabled()) {
                openAlertModal();
            } else {

                // props.onFinalCheckShoppingCart(props.userInfo.id, props.match.params.branchMarketplaceId, props.match.params.marketplaceId);
                const msg = {
                    userId: props.userInfo.id,
                    firstName: localStorage.getItem('firstNameForOrder'),
                    secondName: localStorage.getItem('secondNameForOrder'),
                    address: localStorage.getItem("selectedAddress") !== null ? {
                        id: localStorage.getItem("selectedAddress") !== null ? +localStorage.getItem("selectedAddress") : null,
                        // name: "adress name",
                        // number: "12",
                        // apartment: "12",
                        // floor: "5",
                        // region: "Region",
                        // city: "Boston",
                        // postalCode: "44444444"
                    } : null,
                    branchMarketplaceId: props.match.params.branchMarketplaceId,
                    marketplaceId: props.match.params.marketplaceId,
                    userPhoneNumberForOrder: localStorage.getItem('phoneNumber'),
                    note: userNote,
                }
                sendOrderMessage(msg);
                closeUponExit.current = 1;

                showSocketModalSetter(false)
                props.onEmptyShoppingCart();
                props.onAllShoppingCarts();
                clearLocalStorage();

                const url = '/orderCompletion/' + props.match.params.branchMarketplaceId + "/" + props.match.params.marketplaceId + '/confirmation-message/' + props.shoppingCart.totalLoyalityPoints
                    + '/' + props.match.params.branchMarketplaceName + '/' + props.match.params.marketplaceName;
                // props.history.replace('/')
                props.history.push(url);
            }
        } else {
            showSocketModalSetter(true)
        }
    }

    const openAlertModal = () => {
        showAlertModalSetter(true)
    }

    const closeAlertModal = () => {
        showAlertModalSetter(false)
    }

    const openModal = () => {
        isPrescriptionModalVisibleHandler(true);
    }

    const closeModal = () => {
        isPrescriptionModalVisibleHandler(false);
    }

    const redirectToPreviousPage = () => {
        //TODO promeniti naziv rute svuda gde treba iz /addresses u nesto drugo
        const url = '/orderCompletion/' + props.match.params.branchMarketplaceId + "/" + props.match.params.marketplaceId + '/addresses'
            + '/' + props.match.params.branchMarketplaceName + '/' + props.match.params.marketplaceName;
        props.history.push(url);
        // const url = '/orderCompletion/' + props.match.params.branchMarketplaceId + "/" + props.match.params.marketplaceId + '/delivery';
        // props.history.push(url);
    }

    const redirectToPreviousPageMobile = () => {
        //TODO promeniti naziv rute svuda gde treba iz /addresses u nesto drugo
        let url = '/orderCompletion/' + props.match.params.branchMarketplaceId + "/" + props.match.params.marketplaceId;
        props.collectOrDelivery.delivery ?
            url += '/addresses' + '/' + props.match.params.branchMarketplaceName + '/' + props.match.params.marketplaceName :
            url += '/userInfo' + '/' + props.match.params.branchMarketplaceName + '/' + props.match.params.marketplaceName;
        props.history.push(url);
    }

    const isAgreementChecked = () => {
        if (props.shoppingCart && props.shoppingCart.prescriptionsLinks.length === 0) {
            agreementHandler(!agreement);
        }
    }

    const isFinishButtonEnabled = () => {
        if (props.userProfile !== null && localStorage.getItem('phoneNumber')) {
            if (localStorage.getItem('haveRp') === 'true') {
                return agreement === true || +numberOfPrescImages > 0;
            } else {
                return true
            }
        }
    }

    const getSize = (size) => {
        numberOfPrescImagesGetter(size);
    }

    const closeShopNotOnlineModal = () => {
        setShowShopIsClosedModal(false);
        setShowShopIsOfflineModal(false);
    }

    const checkIfShopIsClosed = () => {
        setFromSummaryAndConfirmation(true);
        //ako apoteka ne radi ili nije online, otvara se modal
        if (!isStoreOpenNow(props.selectedStore.marketplaceResponse.openingHoursResponse)) {
            setShowShopIsClosedModal(true);
        } else {
            props.onFetchSelectedStore(props.selectedStore.marketplaceResponse.marketplaceId,props.selectedStore.marketplaceResponse.branchMarketplaceId)
        }
    }

    let prescriptionAcknowledge =
        localStorage.getItem('haveRp') === 'true' && (
            (props.collectOrDelivery.collect || deliveryMethod === 'collectInPharmacy') &&
            <div className={styles.PrescriptionPartCheckbox}>
                <Input inputtype="checkbox" type="checkbox"
                       style={{
                           opacity: "1",
                           position: "relative",
                           width: "20px",
                           float: "left",
                           padding: "0",
                           margin: "-.1rem 0 0 -.5rem"
                       }}
                       checked={agreement}
                       onChange={isAgreementChecked}
                />
                <div style={{marginLeft: "0"}}>Posedujem validne recepte za lekove koje sam rezervisao i
                    poneću ih sa sobom prilikom preuzimanja.<span style={{color: "red"}}>*</span></div>
            </div>)

    let userNoteObj =
        <div className={styles.UserNoteWrapper}>
            <textarea
                placeholder="Ukoliko želite, ovde možete uneti napomenu."
                maxLength={300}
                rows={10}
                value={localStorage.getItem('userNote') || ''}
                className={styles.Note}
                onChange={e => {
                    setCountCharsInNote(300 - e.target.value.length);
                    userNoteSetter(e.target.value);
                    localStorage.setItem('userNote', e.target.value);
                }}
            />
            <span
                className={styles.CharactersNumber}>{countCharsInNote}</span>
        </div>


    useEffect(() => {
        if(props.userProfile) {
                if (!agreement) {
                    setTooltipFinishText("Da bi porudžbina bila zaključena, moraš potvrditi da imaš recept.");
                }
            }
    }, [props.userProfile, agreement])

    return (
        <Media queries={{
            small: "(max-width: 699px)",
            large: "(min-width: 700px)"
        }}>
            {matches => (
                <Fragment>
                    {matches.small &&
                    <OrderCompletionPageTemplateMobile
                        header={<HeaderWithTitleAndBackChevron
                            icon
                            onIconClick={redirectToPreviousPageMobile}
                            title="Detalji preuzimanja"/>}
                    >
                        {props.collectOrDelivery.collect ?
                            <div className={styles.DeliveryOptionHeaderMobile}>
                                <PharmacyCrossIcon/>
                                <p>Izabrali ste preuzimanje u apoteci</p>
                            </div>
                            :
                            <div className={styles.DeliveryOptionHeaderMobile}>
                                <DeliveryIcon style={{stroke:"#013A63"}}/>
                                <p>Izabrali ste isporuku na adresu</p>
                                <div className={styles.UserAddressMobile}>
                                    {userAddress}
                                </div>
                            </div>
                        }
                        <PharmacyData/>
                        <div style={{marginTop: "2rem"}}>
                            <div style={{display: "flex", flexDirection: "column", height: "100%"}}>

                                {localStorage.getItem('haveRp') === 'true' &&
                                    // (props.collectOrDelivery.delivery || deliveryMethod === 'deliveryToAddress') &&
                                    <div className={styles.PrescriptionPart}>

                                        {props.shoppingCart !== null && props.shoppingCart.prescriptionsLinks &&
                                                <div style={{
                                                    width: "100%",
                                                    height: "auto",
                                                    display: "flex",
                                                    flexDirection: "column"
                                                }}>
                                                    <div style={{marginBottom: "10px"}}>Vaša porudžbina sadrži lekove za
                                                        koje je potreban
                                                        recept. Ukoliko želiš, možeš dodati najviše tri fotografije
                                                        recepata.
                                                    </div>
                                                    <div className={styles.PrescriptionImagesPreviewPlacer}>
                                                        <PrescriptionImagesThumbnails getSize={getSize}/>
                                                    </div>
                                                </div>
                                        }

                                        {props.shoppingCart && props.shoppingCart.prescriptionsLinks.length < 3 &&
                                            <Button buttontype='blue'
                                                    style={{cursor: "pointer", padding: "1rem"}}
                                                    onClick={openModal}>Dodaj fotografiju recepta</Button>
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                        {prescriptionAcknowledge}
                        {userNoteObj}
                        {props.orderSocketConnectionStart ? <Spinner/> : <Button buttontype='blue'
                                onClick={checkIfShopIsClosed}>Rezerviši</Button>}

                        {isPrescriptionModalVisible &&
                            <PrescriptionImageUploader closeModal={closeModal}
                            />
                        }

                        <Backdrop show={isPrescriptionModalVisible}
                            // backdropClickHandler={closeModal}
                                  zIndex={15}
                                  backgroundColor="rgba(0, 0, 0, .2)"/>

                    </OrderCompletionPageTemplateMobile>
                    }
                    {matches.large &&
                    <OrderCompletionPageTemplate
                        topPart={
                            <Actions>
                                <Button style={{fontSize:"20px"}} onClick={redirectToPreviousPage}>
                                    <ChevronLeftIcon style={{width: "20px", marginRight: "1rem"}}/> Nazad
                                </Button>
                                <div className={styles.TooltipWrapper}>
                                    <Button buttontype='blue'
                                            style={{height:"60px", fontSize:"20px", padding: "0 2rem", justifyContent:"center"}}
                                            onClick={checkIfShopIsClosed}>
                                        Rezerviši
                                    </Button>
                                </div>
                            </Actions>
                        }
                        leftPart={
                            <div style={{display: "flex", flexDirection: "column", height: "100%", width: "100%"}}>
                                <div style={{fontSize: "24px", marginBottom: "2rem"}}>Pregled porudžbine</div>
                                <ShoppingCartList/>
                            </div>
                        }

                        rightPart={
                            <div style={{display: "flex", flexDirection: "column", height: "100%", width: "100%"}}>

                                {/*<div>*/}
                                {/*    {pharmacyData}*/}
                                {/*</div>*/}

                                <TotalAmount
                                    totalState={(+(Math.round(localStorage.getItem('totalPrice') + "e+2") + "e-2")).toFixed(2)}/>

                                {props.collectOrDelivery.delivery ?
                                    <>
                                        <div style={{fontSize: "20px"}}>Isporuka na adresu</div>
                                        <br/>
                                    </>
                                    :
                                    <>
                                        <div style={{fontSize: "16px", fontWeight:"700"}}>Preuzimanje u apoteci:</div>
                                        <br/>
                                        <PharmacyData/>
                                    </>
                                }

                                <div>
                                    {props.collectOrDelivery !== null ? (props.collectOrDelivery.delivery ?
                                                <>
                                                    {userAddress}
                                                    <hr/>
                                                </>
                                                :
                                                <div>
                                                    <div style={{borderTop: "1px solid #e2e8ecff", paddingTop: "1rem"}}>
                                                        <div style={{fontWeight: "700", paddingBottom: "1rem"}}>
                                                            Vaš broj telefona:
                                                        </div>
                                                        <div>{localStorage.getItem('phoneNumber')}</div>
                                                    </div>
                                                </div>
                                        )
                                        :
                                        <p>Došlo je do greške. Pokušajte kasnije.</p>
                                    }
                                </div>

                                <div style={{marginTop: "2rem"}}>
                                    <div style={{display: "flex", flexDirection: "column", height: "100%"}}>

                                        {localStorage.getItem('haveRp') === 'true' &&
                                            // (props.collectOrDelivery.delivery || deliveryMethod === 'deliveryToAddress') &&
                                            <div className={styles.PrescriptionPart}>

                                                {localStorage.getItem('haveRp') === 'true' &&
                                                    (props.shoppingCart !== null && props.shoppingCart.prescriptionsLinks &&
                                                        <div style={{
                                                            width: "100%",
                                                            height: "auto",
                                                            display: "flex",
                                                            flexDirection: "column"
                                                        }}>
                                                            <div style={{marginBottom: "10px"}}>Vaša porudžbina sadrži lekove za koje je potreban
                                                                recept. Ukoliko želiš, možeš dodati najviše tri fotografije recepata.
                                                            </div>
                                                            <div className={styles.PrescriptionImagesPreviewPlacer}>
                                                                <PrescriptionImagesThumbnails getSize={getSize}/>
                                                            </div>
                                                        </div>)
                                                }

                                                {props.shoppingCart && props.shoppingCart.prescriptionsLinks.length < 3 && <Button buttontype='blue' style={{cursor: "pointer", padding: "1rem"}}
                                                        onClick={openModal}>Dodaj fotografiju recepta</Button>
                                                }
                                            </div>
                                        }

                                        {prescriptionAcknowledge}

                                    </div>
                                </div>
                                {userNoteObj}
                                {isPrescriptionModalVisible &&
                                <PrescriptionImageUploader closeModal={closeModal}
                                />
                                }
                                <Backdrop show={isPrescriptionModalVisible}
                                    // backdropClickHandler={closeModal}
                                          zIndex={15}
                                          backgroundColor="rgba(0, 0, 0, .2)"/>
                            </div>
                        }
                    />
                    }
                    {showAlertModal &&
                    <AlertModal onCloseClick={closeAlertModal}
                                backdropClickHandler={closeAlertModal}
                                show={showAlertModal}
                                messageText={tooltipFinishText}
                    />
                    }

                    {showSocketModal &&
                        <SocketModal
                            show = {showSocketModal}
                            connectAgain = {connectAgain}
                            redirectToSendOrder={redirectToSendOrder}
                            backdropClickHandler={()=>showSocketModalSetter(false)}
                        />
                    }

                    {(showShopIsClosedModal || showShopIsOfflineModal) && props.selectedStore &&
                        <ShopNotOnlineModal
                            show={showShopIsOfflineModal || showShopIsClosedModal}
                            onCloseClick={closeShopNotOnlineModal}
                            shop={props.selectedStore && props.selectedStore}
                            fromSummaryAndConfirmation={fromSummaryAndConfirmation}
                            shopIsClosed={showShopIsClosedModal}
                            shopIsOffline={showShopIsOfflineModal}
                            backdropClickHandler={closeShopNotOnlineModal}
                            proceedToNextStep={redirectToSendOrder}
                        />
                    }
                </Fragment>
            )}
        </Media>

    );
}

const mapStateToProps = (state) => {
    return {
        selectedStore: state.appPage.selectedStore,

        prescriptionsLinks: state.shopPage.prescriptionsLinks,
        shoppingCart: state.shopPage.shoppingCart,

        userProfile: state.userProfile.userProfile,
        userProfileError: state.userProfile.userProfileError,
        loadingUserProfile: state.userProfile.loadingUserProfile,

        userInfo: state.authPage.userInfo,

        collectOrDelivery: state.orderCompletion.collectOrDelivery,
        orderSocketConnected: state.orderCompletion.orderSocketConnected,
        orderSocketConnectionStart: state.orderCompletion.orderSocketConnectionStart
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onFetchSelectedStore: (branchMarketplaceId, marketplaceId) => dispatch(actions.fetchSelectedStore(branchMarketplaceId, marketplaceId)),
        onFetchUserProfile: (email) => dispatch(actions.fetchUserProfile(email)),
        // onFinalCheckShoppingCart: (userId, branchMarketplaceId, marketplaceId) => dispatch(actions.finalCheckShoppingCart(userId, branchMarketplaceId, marketplaceId)),
        onCollectOrDeliveryChoice: (collectOrDelivery) => dispatch(actions.collectOrDeliveryChoice(collectOrDelivery)),
        onEmptyShoppingCart: () => dispatch(actions.shoppingCart(null)),
        onAllShoppingCarts: () => dispatch(actions.allShoppingCarts(null)),
        onOrderSocketConnected: (connected) => dispatch(actions.orderSocketConnectionChanged(connected))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Summary))