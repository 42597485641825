import * as actionsTypes from '../actions/actionsTypes'

const initialState = {

    relatedArticles: null,
    relatedArticlesError: false,
    loadingRelatedArticles: false,

    relatedArticlesOnTerapijskaIndikacija: null,
    relatedArticlesOnTerapijskaIndikacijaError: false,
    loadingRelatedArticlesOnTerapijskaIndikacija: false,

    storeForInfoPageDetails: null,
    storeForInfoPageDetailsError: false,
    loadingStoreForInfoPageDetails: false,

    articleDetailsForInfoPage: null,
    articleDetailsForInfoPageError: false,
    loadingArticleDetailsForInfoPage: false,

    articleInStore: null,
    articleInStoreError: false,
    loadingArticleInStore: false,

    //article info
    articleInfo: null,
    articleInfoError: false,
    loadingArticleInfo: false,

    categoryRelatedArticles: null,
    loadingCategoryRelatedArticles: false,
    categoryRelatedArticlesError: false,

    categoryForArticle: null,
    loadingCategoryForArticle: false,
    categoryForArticleError: false

}

const articleInfoReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionsTypes.FETCH_RELATED_ARTICLES_FOR_ARTICLE:
            return {
                ...state,
                relatedArticles: action.relatedArticles,
                relatedArticlesError: false,
                loadingRelatedArticles: false
            }
        case actionsTypes.FETCH_RELATED_ARTICLES_FOR_ARTICLE_FAILED:
            return {
                ...state,
                relatedArticles: null,
                relatedArticlesError: true,
                loadingRelatedArticles: false
            }
        case actionsTypes.FETCH_RELATED_ARTICLES_FOR_ARTICLE_START:
            return {
                ...state,
                loadingRelatedArticles: true,
                relatedArticlesError: false
            }
        case actionsTypes.FETCH_RELATED_ARTICLES_ON_TERAPIJSKA_INDIKACIJA:
            return {
                ...state,
                relatedArticlesOnTerapijskaIndikacija: action.relatedArticlesOnTerapijskaIndikacija,
                relatedArticlesOnTerapijskaIndikacijaError: false,
                loadingRelatedArticlesOnTerapijskaIndikacija: false
            }
        case actionsTypes.FETCH_RELATED_ARTICLES_ON_TERAPIJSKA_INDIKACIJA_FAILED:
            return {
                ...state,
                relatedArticlesOnTerapijskaIndikacija: null,
                relatedArticlesOnTerapijskaIndikacijaError: true,
                loadingRelatedArticlesOnTerapijskaIndikacija: false

            }
        case actionsTypes.FETCH_RELATED_ARTICLES_ON_TERAPIJSKA_INDIKACIJA_START:
            return {
                ...state,
                loadingRelatedArticlesOnTerapijskaIndikacija: true,
                relatedArticlesOnTerapijskaIndikacijaError: false

            }

        case actionsTypes.FETCH_ARTICLE_INFO:
            return {
                ...state,
                articleInfo: action.articleInfo,
                articleInfoError: false,
                loadingArticleInfo: false
            }
        case actionsTypes.FETCH_ARTICLE_INFO_FAILED:
            return {
                ...state,
                articleInfoError: true
            }
        case actionsTypes.FETCH_ARTICLE_INFO_START:
            return {
                ...state,
                loadingArticleInfo: true
            }

        case actionsTypes.FETCH_DETAILS_FOR_STORE_FOR_INFO_PAGE:
            return {
                ...state,
                storeForInfoPageDetails: action.storeDetails,
                storeForInfoPageDetailsError: false,
                loadingStoreForInfoPageDetails: false
            }
        case actionsTypes.FETCH_DETAILS_FOR_STORE_FOR_INFO_PAGE_FAILED:
            return {
                ...state,
                storeForInfoPageDetails: null,
                storeForInfoPageDetailsError: true,
                loadingStoreForInfoPageDetails: false
            }
        case actionsTypes.FETCH_DETAILS_FOR_STORE_FOR_INFO_PAGE_START:
            return {
                ...state,
                storeForInfoPageDetails: null,
                storeForInfoPageDetailsError: false,
                loadingStoreForInfoPageDetails: true
            }

        case actionsTypes.FETCH_ARTICLE_DETAILS_FOR_INFO_PAGE:
            return {
                ...state,
                articleDetailsForInfoPage: action.articleDetails,
                articleDetailsForInfoPageError: false,
                loadingArticleDetailsForInfoPage: false,
            }
        case actionsTypes.FETCH_ARTICLE_DETAILS_FOR_INFO_PAGE_FAILED:
            return {
                ...state,
                articleDetailsForInfoPage: null,
                articleDetailsForInfoPageError: true,
                loadingArticleDetailsForInfoPage: false
            }
        case actionsTypes.FETCH_ARTICLE_DETAILS_FOR_INFO_PAGE_START:
            return {
                ...state,
                articleDetailsForInfoPage: null,
                articleDetailsForInfoPageError: false,
                loadingArticleDetailsForInfoPage: true
            }
        case actionsTypes.FETCH_ARTICLE_IN_STORE:
            return {
                ...state,
                articleInStore: action.articleInStore,
                articleDetailsForInfoPage: action.articleInStore.itemResponse,
                articleInStoreError: false,
                loadingArticleInStore: false
            }
        case actionsTypes.FETCH_ARTICLE_IN_STORE_FAILED:
            return {
                ...state,
                articleInStore: null,
                articleInStoreError: true,
                loadingArticleInStore: false
            }
        case actionsTypes.FETCH_ARTICLE_IN_STORE_START:
            return {
                ...state,
                articleInStore: null,
                articleInStoreError: false,
                loadingArticleInStore: true
            }
        case actionsTypes.FETCH_CATEGORY_RELATED_ARTICLES:
            return {
                ...state,
                categoryRelatedArticles: action.categoryRelatedArticles,
                loadingCategoryRelatedArticles: false,
                categoryRelatedArticlesError: false
            }
        case actionsTypes.FETCH_CATEGORY_RELATED_ARTICLES_FAILED:
            return {
                ...state,
                categoryRelatedArticles: null,
                loadingCategoryRelatedArticles: false,
                categoryRelatedArticlesError: true
            }
        case actionsTypes.FETCH_CATEGORY_RELATED_ARTICLES_START:
            return {
                ...state,
                loadingCategoryRelatedArticles: true,
                categoryRelatedArticlesError: false
            }
        case actionsTypes.CATEGORY_FOR_ARTICLE:
            return {
                ...state,
                categoryForArticle: action.categoryForArticle,
                loadingCategoryForArticle: false,
                categoryForArticleError: false
            }
        case actionsTypes.CATEGORY_FOR_ARTICLE_START:
            return {
                ...state,
                loadingCategoryRelatedArticles: true,
                categoryRelatedArticlesError: true
            }
        case actionsTypes.CATEGORY_FOR_ARTICLE_FAILED:
            return {
                ...state,
                loadingCategoryRelatedArticles: false,
                categoryRelatedArticlesError: true
            }
        default:
            return state;
    }

}
export default articleInfoReducer;
