import React, {useEffect, useState} from 'react';
import styles from "./AddAddressForm.module.css";
import Button from "../Buttons/Button/Button";
import {useForm} from "react-hook-form";

/**
 *
 * @param props
 * props.title - Title of the component
 * props.text - Text under the title that will be displayed in this component
 * props.onclick - forwards onClick for a button
 * @returns {JSX.Element}
 * @constructor
 */

const AddAddressForm = (props) => {

    const [inputState, inputStateChanged] = useState({
        addressData: {
            city: '',
            region: '',
            postalCode: '',
            streetName: '',
            number: '',
            floor: '',
            apartment: ''
        }
    })

    const [preloadedAddress, preloadedAddressSetter] = useState(
        {
            city: localStorage.getItem('city'),
            region: localStorage.getItem('region'),
            postalCode: localStorage.getItem('postalCode'),
            streetName: localStorage.getItem('streetName'),
            number: localStorage.getItem('number'),
            floor: localStorage.getItem('floor'),
            apartment: localStorage.getItem('apartment')
        }
    )

    const [readyToSendDataToParent, readyToSendDataToParentHandler] = useState(false)
    useEffect(()=>{
        if(readyToSendDataToParent=== true) {
            sendDataToParent();
            readyToSendDataToParentHandler(false)
        }
    }, [readyToSendDataToParent])

    const getDataFromForm = (data, e) => {
        e.preventDefault();
        const values = getValues();
        inputStateChanged({
            ...inputState,
            addressData: {
                city: values.city,
                region: values.region,
                postalCode: values.postalCode,
                streetName: values.streetName,
                number: values.number,
                floor: values.floor,
                apartment: values.apartment
            }
        })
        allFieldsFilled(e)
        readyToSendDataToParentHandler(true)
    }

    const allFieldsFilled = (e) => {
        e.preventDefault();
        if (inputState.addressData.city !== ""
            && inputState.addressData.postalCode !== ""
            && inputState.addressData.streetName !== ""
            && inputState.addressData.number !== ""
            && inputState.addressData.region !== "") {

            // provera da li su podaci promenjeni
            if (props.updateAddressId) {
                return inputState.addressData.city !== localStorage.getItem('city')
                    || inputState.addressData.postalCode !== localStorage.getItem('postalCode')
                    || inputState.addressData.streetName !== localStorage.getItem('streetName')
                    || inputState.addressData.number !== localStorage.getItem('number')
                    || inputState.addressData.region !== localStorage.getItem('region')
                    || inputState.addressData.floor !== localStorage.getItem('floor')
                    || inputState.addressData.apartment !== localStorage.getItem('apartment');
            }
            return true;
        }
        return false;
    }

    const sendDataToParent = () => {
        if (props.updateAddressId) {
            props.updateAddress(inputState);
        } else if (props.saveAddress) {
            props.saveAddress(inputState);
        }
    }

    const {register, handleSubmit ,formState: {errors, isValid}, getValues} = useForm({
        mode: "onBlur",
        // reValidateMode: "onChange",
        defaultValues: preloadedAddress
    })

    return (
        <>
            {/*<h3>{props.title}</h3>*/}
            <h3>{!props.updateAddressId ? 'Unos nove adrese' : 'Izmena adrese'}</h3>
            <p>{props.text}</p>
            {/*Linija ispod se stavi da se na view-u ispise state*/}
            <form style={{width:"100%"}} onSubmit={handleSubmit(getDataFromForm)}>
                <div className={styles.TextBoxStyle}>
                    <div className={styles.AddressFormRow}>
                        {/*<label htmlFor="streetName" className={styles.Label}>Ulica:</label>*/}
                        <div className={styles.inputVerificationHolder} style={{width:"100%"}}>
                            <input type="text" placeholder="Ulica" className={styles.Input} {...register("streetName", {
                                required: "Obavezno polje",
                                minLength: {
                                    value: 2,
                                    message: "Minimalni unos 2 karaktera"
                                },
                                maxLength: {
                                    value: 256,
                                    message: "Maksimalan unos 50 karaktera"
                                },
                            })}/>
                            {errors.streetName?.message &&
                                <span className={styles.ErrorMessage}>{errors.streetName.message}</span>}
                        </div>
                    </div>
                </div>
                <div className={styles.AddressFormRow}>
                    <div className={styles.TextBoxStyle}>
                        {/*<label htmlFor="number" className={styles.Label}>Broj:</label>*/}
                        <div className={styles.inputVerificationHolder} style={{paddingRight:"1rem"}}>
                            <input type="text" placeholder="Broj" className={styles.Input} {...register("number", {
                                required: "Obavezno polje",
                                maxLength: {
                                    value: 6,
                                    message: "Maksimalan unos 6 karaktera"
                                }
                            })}/>
                            {errors.number?.message &&
                                <span className={styles.ErrorMessage}>{errors.number.message}</span>}
                        </div>
                    </div>
                    <div className={styles.TextBoxStyle}>
                        {/*<label htmlFor="apartment" className={styles.Label}>Stan:</label>*/}
                        <div className={styles.inputVerificationHolder} style={{paddingLeft:"1rem"}}>
                            <input type="text" placeholder="Stan" className={styles.Input} maxLength='6' {...register("apartment", {})}/>
                        </div>
                    </div>
                </div>
                <div className={styles.AddressFormRow}>
                    <div className={styles.TextBoxStyle}>
                        {/*<label htmlFor="floor" className={styles.Label}>Sprat:</label>*/}
                        <div className={styles.inputVerificationHolder} style={{paddingRight:"1rem"}}>
                            <input type="text" placeholder="Sprat" className={styles.Input} maxLength='2' {...register("floor", {})}/>
                        </div>
                    </div>
                    {/*<div className={styles.TextBoxStyle}>*/}
                    {/*    <div className={styles.AddressFormRow}>*/}
                    {/*        /!*<label htmlFor="postalCode" className={styles.Label}>Poštanski broj:</label>*!/*/}
                    {/*        <div className={styles.inputVerificationHolder} style={{paddingLeft:"1rem"}}>*/}
                    {/*            <input type="number" placeholder="Poštanski broj" className={styles.Input} onInput={(e) => e.target.value = e.target.value.slice(0, 5)} {...register("postalCode", {*/}
                    {/*                required: "Obavezno polje",*/}
                    {/*                minLength: {*/}
                    {/*                    value: 5,*/}
                    {/*                    message: "Potrebno 5 cifara"*/}
                    {/*                },*/}
                    {/*                pattern: {*/}
                    {/*                    value: /^[ 0-9\-]+$/,*/}
                    {/*                    message: "Uneseni poštanski broj nije validnog formata"*/}
                    {/*                }*/}
                    {/*            })}/>*/}
                    {/*            {errors.postalCode?.message && <span className={styles.ErrorMessage}>{errors.postalCode.message}</span>}*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                </div>

                <div className={styles.TextBoxStyle}>
                    <div className={styles.AddressFormRow}>
                        {/*<label htmlFor="city" className={styles.Label}>Grad:</label>*/}
                        <div className={styles.inputVerificationHolder} style={{width:"100%"}}>
                            <input type="text" placeholder="Grad" className={styles.Input} maxLength="30" {...register("city", {
                                required: "Obavezno polje",
                                minLength: {
                                    value: 2,
                                    message: "Minimalni unos 2 karaktera"
                                },
                                // maxLength: {
                                //     value: 30,
                                //     message: "Grad ne može sadržati više od 50 karaktera"
                                // },
                                pattern: {
                                    value: /^[ a-zA-ZљњертжуиопшђасдфгхјклчћзџцвбнмšđžčćЉЊЕРТЖУИОПШЂАСДФГХЈКЛЧЋЗЏЦВБНМŠĐŽČĆ'`'\-]+$/,
                                    message: "Unesena adresa nije validnog formata"
                                }
                            })}/>
                            {errors.city?.message && <span className={styles.ErrorMessage}>{errors.city.message}</span>}
                        </div>
                    </div>
                </div>
                {/*    <div className={styles.TextBoxStyle}>*/}
                {/*            /!*<label htmlFor="region" className={styles.Label}>Opština: </label>*!/*/}
                {/*            <div className={styles.inputVerificationHolder} style={{width:"100%"}}>*/}
                {/*                <input type="text" placeholder="Opština" className={styles.Input} maxLength="30" {...register("region", {*/}
                {/*                    required: "Obavezno polje",*/}
                {/*                    minLength: {*/}
                {/*                        value: 2,*/}
                {/*                        message: "Minimalni unos 2 karaktera"*/}
                {/*                    },*/}
                {/*                    pattern: {*/}
                {/*                        value: /^[ a-zA-ZљњертжуиопшђасдфгхјклчћзџцвбнмšđžčćЉЊЕРТЖУИОПШЂАСДФГХЈКЛЧЋЗЏЦВБНМŠĐŽČĆ'`'\-]+$/,*/}
                {/*                        message: "Unos nije validnog formata"*/}
                {/*                    }*/}
                {/*                })}/>*/}
                {/*                {errors.region?.message && <span className={styles.ErrorMessage}>{errors.region.message}</span>}*/}
                {/*            </div>*/}
                {/*</div>*/}
                <div style={{width:"100%", display:"flex", justifyContent:"center", marginTop:"1rem"}}>
                    <Button buttontype={isValid ? 'blue' : 'disabled'}
                            type="submit"
                            disabled={!isValid}
                    >Sačuvaj adresu</Button>
                </div>
            </form>
        </>
    );
}

export default AddAddressForm;