import React from "react";
import styles from './ArticleImageHolder.module.css';
import Magnifier from "react-magnifier";
import DefaultImage from "../../../assets/PictureHolder.png";
import RegulativaImage from "../../../assets/icons/regulativa.svg";
import {ReactComponent as ArrowLeft} from "../../../assets/icons/arrow left.svg";
import Spinner from "../../UI/Spinner/Spinner";
import {withRouter} from 'react-router-dom'
import {connect} from "react-redux";
import ItemAvailabilityBadge from "../../UI/ItemAvailabilityBadge/ItemAvailabilityBadge";
import LoyaltyBadge from "../../UI/LoyaltyBadge/LoyaltyBadge";

const ArticleImageHolder = (props) => {

    const exitArticleInfoPage = () => {
        props.history.replace(localStorage.getItem('beforeEnteringInfoLek'));
        localStorage.removeItem('beforeEnteringInfoLek');
    }

    const itemAvailability = props.articleDetails &&
        props.articleDetails.itemAvailability ?
        <ItemAvailabilityBadge itemResponse={props.articleDetails} className={styles.ItemAvailability}/>
        : <div></div>

    const loyaltyPointsIcon =
        <LoyaltyBadge
            number={props.articleDetails && props.articleDetails.itemLoyalityPoints/10}
        />

    return (
        <div className={styles.ArticleInfoPictureHolder}>
            {props.image === DefaultImage || props.image === RegulativaImage ?
                <div className={styles.ArticleImageWrapper}>
                    <img className={styles.ArticleImage}
                         src={props.image}
                         alt="article image"
                    />
                    <div className={styles.BadgesWrapper}>
                        {itemAvailability}
                        {props.articleDetails && props.articleDetails.itemLoyalityPoints > 0 && loyaltyPointsIcon}
                    </div>
                </div>
                 :
                // <Magnifier src={props.image} imgAlt="article image" zoomFactor={1.6} mgWidth={250} mgHeight={250} height="100%" style={{objectFit:"contain"}}/>
                <Magnifier src={props.image} imgAlt="article image"
                           zoomFactor={0.075}
                           mgShowOverflow={false}
                           mgShape={"square"}
                           mgBorderWidth={0}
                           mgMouseOffsetX={0}
                           mgMouseOffsetY={0}
                           mgWidth={2000}
                           mgHeight={2000}
                           height="100%"
                           style={{objectFit: "contain"}}
                />
            }
            <div className={styles.BackWrapper}
                 onClick={exitArticleInfoPage}>
                <ArrowLeft style={{width: "2rem", height:"2rem"}}/> &nbsp;
                {props.match.params.branchMarketplaceId !== undefined ?
                    (props.storeForInfoPageDetails !== null ? <span>{props.storeForInfoPageDetails.name}</span> :
                        <Spinner/>) : null}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        storeForInfoPageDetails: state.articleInfoPage.storeForInfoPageDetails,
        storeForInfoPageDetailsError: state.articleInfoPage.storeForInfoPageDetailsError,
        loadingStoreForInfoPageDetails: state.articleInfoPage.loadingStoreForInfoPageDetails,
        articleDetails: state.articleInfoPage.articleDetailsForInfoPage
    }
}

export default connect(mapStateToProps)(withRouter(ArticleImageHolder));