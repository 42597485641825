import React, {useEffect, useState} from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import {PhoneIcon} from "@heroicons/react/outline";
import {Textbox} from "react-inputs-validation";
import ModalWithHeader from "../../UI/Modals/ModalWithHeader/ModalWithHeader";
import * as actions from "../../../store/actions";
import {connect} from "react-redux";
import Button from "../../UI/Buttons/Button/Button";
import styles from "./PhoneNumberVerification.module.css" // ne uklanjati, obavezna zbog input polja za kod, iako se nigde ne poziva, koristi se

firebase.initializeApp({
    apiKey: "AIzaSyDgpS6zEWrUld1-aIgqKgS6dJY8z1CT1s8",
    authDomain: "apotekars-338712.firebaseapp.com",
    projectId: "apotekars-338712",
    storageBucket: "apotekars-338712.appspot.com",
    messagingSenderId: "1010729304281",
    appId: "1:1010729304281:web:05221127f5ccfd5c46bec8",
    measurementId: "G-CBZSNM4J1E"
});


const PhoneNumberVerification = (props) => {

    const [userPhoneNumber, userPhoneNumberSetter] = useState('');
    const [isPhoneNumberValid, isPhoneNumberValidHandler] = useState(false);
    const [isMessageSent, isMessageSentHandler] = useState(false);
    const [verificationSuccessful, verificationSuccessfulSetter] = useState(false);
    const [verificationFailed, verificationFailedSetter] = useState(false);

    useEffect(() => {
        firebase.auth().languageCode = "sr";
        window.reCaptchaVerifier = new firebase.auth.RecaptchaVerifier('login-button', {
            size: 'invisible',
            callback: () => {
                console.log("prosao rekapcu")
            },
        });
    }, [])

    const saveToProfile = () => {
        if(props.userProfile !== null) {
            const updatedUserProfile = {
                ...props.userProfile,
                phoneNumber: userPhoneNumber,
            }
            console.log(updatedUserProfile);
            props.onUpdateUserProfile(updatedUserProfile);
            props.showPhoneNumber(updatedUserProfile.phoneNumber)
            props.closePhoneVerificationModal();
        }
    }

    const saveForOrder =()=>{
        props.showPhoneNumber(userPhoneNumber);
        props.closePhoneVerificationModal();
        localStorage.setItem('deletePhoneNumberAfterOrder', 'true')
    }

    const sendSmsWithVerificationCode = (phoneNumber) => {

        firebase
            .auth()
            .signInWithPhoneNumber(phoneNumber, window.reCaptchaVerifier)
            .then(confirmationResult => {
                console.log("Posalo sms")
                window.confirmationResult = confirmationResult;
                isMessageSentHandler(true);
                document.getElementById('verificationCodeField').focus();
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const verifyCode = (code) => {
        if(isValueCorrect) {
            window.confirmationResult.confirm(code)
                .then((result) => {
                // User signed in successfully.
                verificationSuccessfulSetter(true);
                console.log(result.user);
                //Save number
                !props.orderPhoneNumber && !props.phoneForRFZO && saveToProfile();
                localStorage.setItem('phoneNumber', userPhoneNumber)
                props.phoneForRFZO && props.sendRFZORequest(userPhoneNumber);
            }).catch((error) => {
                // User couldn't sign in (bad verification code?)
                verificationFailedSetter(true);
                // alert('Uneti kod nije validan 1111111111!');
                console.log("greska prilikom verifikacije code-a");
            });
        } else {
            alert('Uneti kod nije validan 222222222!');
        }
    }

    const [isValueCorrect, isValueCorrectSetter] = useState(false)
    const [value, setValue] = useState('');
    const [isValue6, isValue6Setter] = useState(false)
    const [isPhoneValueLength, isPhoneValueLengthSetter] = useState(false)

    return (

        <ModalWithHeader onCloseClick={props.closePhoneVerificationModal}
                         style={{height: "555px", width:"400px", display:"flex", justifyContent:"center", alignItems:"center"}}
                         icon={<PhoneIcon style={{width: "48px", height: "48px"}}/>}>

            {props.phoneForRFZO &&
                <>
                    <div>
                        Da biste poslali upit za RFZO terapiju, neophodno je da unesete svoj broj telefona.
                    </div>
                </>
            }

            {!verificationSuccessful ?
                <>
                    <div style={{padding: "1rem 0", marginTop: "2rem", width: "100%", textAlign:"center"}}>
                        <label htmlFor="userPhone" style={{fontSize:"14px"}}>Unesi broj telefona</label>
                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            paddingTop:"1rem"
                        }}>
                            <p style={{margin:"0"}}>+381&nbsp;</p>
                            <Textbox
                                attributesInput={{ // Optional.
                                    id: 'userPhone',
                                    name: 'userPhone',
                                    // placeholder: 'format broja telefona: +381XXXXXXXXX',
                                    type: 'number',
                                    maxLength: 9
                                }}

                                value={""} //Optional.[String].Default: "".
                                onChange={(phone, e) => {
                                    if(phone.length < 7) {
                                        isPhoneValueLengthSetter(false)
                                    } else (
                                        isPhoneValueLengthSetter(true)
                                    )
                                    const reg = /^$|^([0-9]{8,9})$/; //regex za telefon, obavaznih 7 ili 8 cifara
                                    if (reg.test(String(phone))) {
                                        userPhoneNumberSetter('+381' + phone);
                                        isPhoneNumberValidHandler(true);
                                        return true;
                                    } else {
                                        isPhoneNumberValidHandler(false);
                                        return "Uneti broj nema dovoljno cira";
                                    }
                                }}  //Required.[Func].Default: () => {}. Will return the value.
                                customStyleWrapper={{borderRadius:"3px", width:"100%"}}
                                customStyleInput={{color:"#5b6977ff", fontSize:"16px"}}
                                onBlur={() => {
                                }} //Optional.[Func].Default: none. In order to validate the value on blur, you MUST provide a function, even if it is an empty function. Missing this, the validation on blur will not work.
                                validationOption={{
                                    name: "phone", //Optional.[String].Default: "". To display in the Error message. i.e Please enter your {name}.
                                    check: true, //Optional.[Bool].Default: true. To determin if you need to validate.
                                    required: true, //Optional.[Bool].Default: true. To determin if it is a required field.
                                    msgOnError: "Polje za broj telefona ne može da bude prazno.",
                                    customFunc: (phone) => {
                                        const reg = /^$|^([0-9]{8,9})$/; //regex za telefon, empty string ili vise telefona odvojenih zarezom
                                        // const reg = /(^$|^(|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$)/;
                                        if (reg.test(String(phone).toLowerCase())) {
                                            userPhoneNumberSetter('+381' + phone);
                                            isPhoneNumberValidHandler(true);
                                            return true;
                                        } else {
                                            isPhoneNumberValidHandler(false);
                                            return "Uneti broj nema dovoljno cifara";
                                        }
                                    }
                                }}
                            />
                        </div>
                    </div>
                    {!isMessageSent ?
                        <Button
                            buttontype={isPhoneNumberValid && isPhoneValueLength ? 'blue' : 'disabled'}
                            id="login-button"
                            onClick={() => (isPhoneNumberValid && isPhoneValueLength) && sendSmsWithVerificationCode(userPhoneNumber)}>
                            Pošaljite mi verifikacioni kod
                        </Button>
                        :
                        <div style={{textAlign:"center", fontSize: "14px"}}>
                            Uskoro ćeš dobiti SMS sa verifikacionim kodom
                        </div>
                    }

                    <br/>
                    <label htmlFor="verificationCodeField" style={{fontSize: "14px"}}>
                        Unesi verifikacioni kod iz SMS poruke</label>
                    <Textbox
                        attributesInput={{ // Optional.
                            id: 'verificationCodeField',
                            name: "verificationCodeField",
                            type: 'number',
                            maxLength: 6,
                            // max: '6',
                        }}
                        value={value} //Optional.[String].Default: "".
                        onChange={(value, e) => {
                            if (value.length < 6) {
                                isValue6Setter(false)
                            } else (
                                isValue6Setter(true)
                            )
                            console.log('value');
                            console.log(value);
                            const reg = /^$|^([0-9]{6})$/; //regex za SMS kod, obavaznih 6 cifara
                            if (reg.test(String(value))) {
                                setValue(value);
                                isValueCorrectSetter(true);
                                return true;
                            } else {
                                isValueCorrectSetter(false);
                                return "Uneseni kod nije validan";
                            }
                        }}  //Required.[Func].Default: () => {}. Will return the value.
                        customStyleWrapper={{
                            borderRadius: "3px",
                            margin: "8px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center"
                        }}
                        customStyleContainer={{display: "flex", justifyContent: "center"}}
                        customStyleInput={{
                            color: "#5b6977ff",
                            fontSize: "28px",
                            width: "40%",
                            textAlign: "center",
                            padding: "6px 12px"
                        }}
                            onFocus={()=>{
                                verificationFailedSetter(false);
                                setValue("");
                            }}
                            onBlur={() => {
                        }} //Optional.[Func].Default: none. In order to validate the value on blur, you MUST provide a function, even if it is an empty function. Missing this, the validation on blur will not work.
                        validationOption={{
                            name: "value", //Optional.[String].Default: "". To display in the Error message. i.e Please enter your {name}.
                            check: false, //Optional.[Bool].Default: true. To determin if you need to validate.
                            required: true, //Optional.[Bool].Default: true. To determin if it is a required field.
                            msgOnError: "Unesi verifikacioni kod",
                            minLength: 6,
                            customFunc: (verificationCodeField) => {

                                const reg = /^$|^([0-9]{6})$/; //regex za SMS kod, obavaznih 6 cifara
                                if (reg.test(String(value))) {
                                    setValue(value);
                                    isValueCorrectSetter(true);
                                    return true;
                                } else {
                                    isValueCorrectSetter(false);
                                    return "Uneseni kod nije validan";
                                }
                            }
                        }}
                    />
                    {verificationFailed &&
                        <div style={{color: "red", fontSize:"12px", marginBottom:"5px"}}>Uneseni kod nije validan.</div>
                    }

                    <Button
                        buttontype={isMessageSent && isValue6 ? 'blue' : 'disabled'}
                        disabled={!isMessageSent && !isValue6}
                        id="login-button"
                        onClick={() => isMessageSent && isValue6 && verifyCode(value)}>
                        Potvrđujem uneseni kod
                    </Button>

                </>
                :
                <div style={{marginTop:"30%", width:"100%"}}>
                    Uspešna verifikacija!

                    {props.orderPhoneNumber &&
                        <div style={{display:"flex", width:"100%", justifyContent:"center"}}>
                            <br/><br/>
                            Uneseni broj će se koristiti za ovu porudžbinu.
                            <br/>
                            Da li želiš da sačuvaš ovaj broj na svom profilu?
                        </div>
                    }
                </div>
            }


            {!verificationSuccessful ? <Button onClick={props.closePhoneVerificationModal}
                                               style={{padding: "1rem 1rem 0 1rem"}}><span>Otkaži</span></Button> :
                (props.orderPhoneNumber ?
                    <div style={{display: "flex", justifyContent: "space-between", width: "100%"}}>
                        <Button buttontype="link" onClick={saveForOrder}>Ne</Button>
                        <Button buttontype="blue" onClick={saveToProfile}>Da</Button>
                    </div>
                    :
                    <Button onClick={props.closePhoneVerificationModal} style={{padding: "1rem 1rem 0 1rem"}}>
                        <span>Zatvori</span>
                    < /Button>)
            }
        </ModalWithHeader>
    );
}

const mapStateToProps = (state) => {
    return {
        userProfile: state.userProfile.userProfile,
        userProfileError: state.userProfile.userProfileError,
        loadingUserProfile: state.userProfile.loadingUserProfile,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onUpdateUserProfile: (userProfile) => dispatch(actions.updateUserProfile(userProfile)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PhoneNumberVerification);
