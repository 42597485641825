import React from "react";
import styles from "./TotalAmount.module.css";

const TotalAmount = (props) => {
    return(
        <div className={styles.TotalAmount} style={props.style}>
            <h2>Ukupno:</h2>
            <h2>{(parseFloat(props.totalState)).toLocaleString("de-DE", {minimumFractionDigits:2, maximumFractionDigits:2})} RSD</h2>
        </div>
    );
}

export default TotalAmount