import React, {Fragment} from "react";
import styles from './SideDrawer.module.css';
import Backdrop from '../UI/Backdrop/Backdrop';
import {connect} from "react-redux";
import {withRouter} from 'react-router-dom'

const sideDrawer = (props) => {

    let attachedClasses = [styles.SideDrawer, styles.Close];
    if (props.open) {
        attachedClasses = [styles.SideDrawer, styles.Open];
    }

    const redirectToShoppingCart = () => {
        const url = '/shoppingCart';
        // props.history.replace('/')
        props.history.push(url);
    }


    /*
    * props:
    * backdrop - vrednosti true i false, odredjuju da li se prikazuje backdrop pirlikom side drawer-a ili ne,
    * topDistance zahteva unos vrednosti top: u css-u za udaljenost od gornje ivice*/
    return (
        <Fragment>
            {props.backdrop && <Backdrop zIndex={30} show={props.shoppingCardDrawer} backdropClickHandler={props.closed}/>}
            <div className={attachedClasses.join(' ')} style={props.sideDrawerExtraStyle}>
                {props.children}
            </div>
        </Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        shoppingCardDrawer: state.toolbar.shoppingCardDrawer
    }
}

export default connect(mapStateToProps)(withRouter(sideDrawer));