import React, {useEffect, useState} from 'react';
import styles from './ValidateRegistrationPane.module.css'
import {withRouter} from "react-router-dom";
import axios from 'axios';
import {baseUrl} from "../../../axios/axios-apoteka";

const ValidateRegistrationPane = (props) => {

    const [inputState, inputStateChanged] = useState({
        error: false
    })


    useEffect(() => {
        const URL = baseUrl + '/authorization/validate_registration/' + props.match.params.email;
        axios.put(URL, null,{params: {access_token: props.match.params.token}})
            .then(response => {
                inputStateChanged({
                    error: false
                });
                redirectToLogin();

            })
            .catch(error => {
                console.log("Error se desio" + error);
                inputStateChanged({
                    error: true
                });
            })
        console.log(props.match.params);
    },[])



    const redirectToLogin = () => {
        const url = '/auth/login';
        props.history.replace(url);
    }


    return (
        <div className={styles.ValidateRegistrationPane}>
            {inputState.error && <p>Desila se greska, nismo uspeli da vas verifikujemo, molimo pokusajte da se registrujete ponovo.</p>}
        </div>
    );
}


export default withRouter(ValidateRegistrationPane);
