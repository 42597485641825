import React from "react";
import ModalWithHeader from "./../../../../../UI/Modals/ModalWithHeader/ModalWithHeader";
import Button from "./../../../../../UI/Buttons/Button/Button";
import {withRouter} from "react-router-dom";

const ShopUnavailableModal = (props) => {

    let modalContent

    if (props.shopIsOffline) {
        modalContent =
            <>
                <span>Apoteka</span>
                <span style={{fontSize: "16px", fontWeight: "600"}}>{props.shop.marketplaceResponse.name}</span>
                <span>trenutno nije dostupna za online poručivanje.</span>
                <span>Vaša porudžbina će biti obrađena čim apoteka </span>
                <span>ponovo bude aktivna u sistemu.</span>
            </>
    } else if (props.shopIsClosed) {
        modalContent =
            <>
                <span>Radno vreme apoteke</span>
                <span style={{fontSize: "16px", fontWeight: "600"}}>{props.shop.marketplaceResponse.name}</span>
                <span>je završeno</span>
                <span>Ukoliko nastaviš sa kreiranjem rezervacije</span>
                <span>ona će biti obrađena narednog radnog dana</span>
            </>
    }

    const proceed = () => {
        if (props.fromShoppingCart) {
            props.proceedToNextStep();
        } else if (props.fromSummaryAndConfirmation) {
            props.proceedToNextStep();
        } else {
            props.onCloseClick();
        }
    }

    const onCancelAction = () => {
        if(props.fromShoppingCart || props.fromSummaryAndConfirmation) {
            props.onCloseClick();
        } else {
            props.history.push(localStorage.getItem('beforeEnteringStore'))
        }
    }

    return (
        <ModalWithHeader onCloseClick={props.onCloseClick}
                         noXIcon
                         show={props.show}
                         backdropClickHandler={props.backdropClickHandler}
                         buttons={
                             <>
                                 <Button onClick={onCancelAction}>Odustani</Button>
                                 <Button buttontype="blue" onClick={proceed}>Nastavi</Button>
                             </>
                         }
        >
            {modalContent}
        </ModalWithHeader>
    );
}

export default (withRouter(ShopUnavailableModal));