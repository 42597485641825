import React from "react";
import styles from "./ArticleCategory.module.css"
import {ReactComponent as ChevronRightIcon} from "../../../../assets/icons/chevronRight_icon.svg";

const ArticleCategory = (props) => {

    return(
        // <div className={!props.flag ? styles.ArticleCategory : [styles.ArticleCategory, styles.ArticleCategorySelected].join(' ')} onMouseOver={props.onMouseOver}>
        <div className={!props.flag ? styles.ArticleCategory : [styles.ArticleCategory, styles.ArticleCategorySelected].join(' ')}
             onMouseOver={props.onMouseOver}
             onMouseLeave={props.onMouseLeave}
             onClick={props.onClick}
        >
            <span>{props.articleCategory.name}</span>
            {props.articleCategory.subcategories.length > 0 && <ChevronRightIcon style={{stroke:"inherit"}}/>}
        </div>
    );
}

export default ArticleCategory;