import React from "react";
import styles from './Pagination.module.css'
import {ChevronDoubleLeftIcon, ChevronDoubleRightIcon} from "@heroicons/react/outline";

const Pagination = (props) => {


    let pages = [];
    let key = 0;

    if (props.totalElements !== -1 && props.totalPages !== -1) {
        if (props.searchedParams === undefined || !(props.searchedParams.match(/^[0-9]+$/) === null)) {

            if (props.totalPages <= 5) {
                let i;
                if (+props.matchParams.page > 0)
                    pages.push(<button key={key++} className={styles.PaginationArrow}
                                       onClick={() => {
                                           props.onNextPageHandler(+props.matchParams.page - 1)
                                       }}>
                        <ChevronDoubleLeftIcon className={styles.ArrowIcon}/>
                    </button>)

                for (i = 0; i < props.totalPages; i++) {
                    const j = i;
                    pages.push(<button
                        key={key++}
                        className={+props.matchParams.page === i ? [styles.PaginationButton, styles.PaginationButtonPressed].join(' ') : styles.PaginationButton} // bilo: color: #db4437
                        onClick={() => {
                            props.onNextPageHandler(j)
                        }}>{i + 1}</button>);
                    if (i === props.totalPages - 1) break;
                }
                if (+props.matchParams.page < props.totalPages - 1)
                    pages.push(<button key={key++} className={styles.PaginationArrow}
                                       onClick={() => {
                                           props.onNextPageHandler(+props.matchParams.page + 1)
                                       }}>
                        <ChevronDoubleRightIcon className={styles.ArrowIcon}/>
                    </button>)


            } else {  // za vise od 6 stranica..

                const initPageStart = [];

                if (+props.matchParams.page > 0)  // samo u ovom slucaju ubacuje LeftArray ikonicu/button.
                    initPageStart.push(<button key={key++} className={styles.PaginationArrow}
                                               onClick={() => {
                                                   props.onNextPageHandler(+props.matchParams.page - 1)
                                               }}>
                        <ChevronDoubleLeftIcon className={styles.ArrowIcon}/>
                    </button>)

                let i;
                for (i = 0; i < 5; i++) {  //  za prvih 5 stranica "pakuje" button-e
                    const j = i;
                    initPageStart.push(<button
                        key={key++}
                        className={+props.matchParams.page === i ? [styles.PaginationButton, styles.PaginationButtonPressed].join(' ') : styles.PaginationButton} // ovde bilo: color: #DB4437  //  dodeljuje odredjeni stil, u zavisnosti od tekuce stranice.
                        onClick={() => {
                            props.onNextPageHandler(j)
                        }}>{i + 1}</button>);
                    if (i === 4) break;  //  NEPOTREBNO..!?
                }
                initPageStart.push(<p key={key++}>...</p>);  //  nakon 5. stranice postavlja separator "..." (3 tackice)
                initPageStart.push(<button key={key++} className={styles.PaginationButton}
                                           onClick={() => {
                                               props.onNextPageHandler(props.totalPages - 1)
                                           }}>{props.totalPages}</button>);
                if (+props.matchParams.page < props.totalPages - 1)
                    initPageStart.push(<button key={key++} className={styles.PaginationArrow}
                                               onClick={() => {
                                                   props.onNextPageHandler(+props.matchParams.page + 1)
                                               }}>
                        <ChevronDoubleRightIcon className={styles.ArrowIcon}/>
                    </button>)

                const initPageEnd = [];
                if (+props.matchParams.page > 0)
                    initPageEnd.push(<button key={key++} className={styles.PaginationArrow}
                                             onClick={() => {
                                                 props.onNextPageHandler(+props.matchParams.page - 1)
                                             }}>
                        <ChevronDoubleLeftIcon className={styles.ArrowIcon}/>
                    </button>)


                initPageEnd.push(<button key={key++} className={styles.PaginationButton}
                                         onClick={() => {
                                             props.onNextPageHandler(0)
                                         }}>{1}</button>);
                initPageEnd.push(<p key={key++}>...</p>);

                for (i = 5; i > 0; i--) {
                    const j = i;
                    initPageEnd.push(<button
                        key={key++}
                        className={+props.matchParams.page === +props.totalPages - i ? [styles.PaginationButton, styles.PaginationButtonPressed].join(' ') : styles.PaginationButton} // ovde bilo: color: #DB4437 ---problem u ovoj liniji!!!!
                        onClick={() => {
                            props.onNextPageHandler(props.totalPages - j)
                        }}>{props.totalPages - i + 1}</button>);
                    if (i === 1) break;
                }
                if (+props.matchParams.page < props.totalPages - 1)
                    initPageEnd.push(<button key={key++} className={styles.PaginationArrow}
                                             onClick={() => {
                                                 props.onNextPageHandler(+props.matchParams.page + 1)
                                             }}>
                        <ChevronDoubleRightIcon className={styles.ArrowIcon}/>
                    </button>)

                if (props.matchParams.page >= 4) {
                    if (props.matchParams.page >= props.totalPages - 4) {
                        pages = initPageEnd.slice();
                    } else {
                        if (+props.matchParams.page > 0)
                            pages.push(<button key={key++} className={[styles.Arrow, styles.PaginationArrow].join(' ')}
                                               onClick={() => {
                                                   props.onNextPageHandler(+props.matchParams.page - 1)
                                               }}>
                                <ChevronDoubleLeftIcon className={styles.ArrowIcon}/>
                            </button>)
                        pages.push(<button key={key++} className={styles.PaginationButton}
                                           onClick={() => {
                                               props.onNextPageHandler(0)
                                           }}>{1}</button>);
                        pages.push(<p key={key++}>...</p>);
                        pages.push(<button key={key++} className={styles.PaginationButton}
                                           onClick={() => {
                                               props.onNextPageHandler(+props.matchParams.page - 2)
                                           }}>{+props.matchParams.page - 1}</button>);
                        pages.push(<button key={key++} className={styles.PaginationButton}
                                           onClick={() => {
                                               props.onNextPageHandler(+props.matchParams.page - 1)
                                           }}>{+props.matchParams.page}</button>);
                        pages.push(<button
                            key={key++}
                            className={[styles.PaginationButton, styles.PaginationButtonPressed].join(' ')}
                            onClick={() => {
                                props.onNextPageHandler(+props.matchParams.page)
                            }}>{+props.matchParams.page + 1}</button>);
                        pages.push(<button key={key++} className={styles.PaginationButton}
                                           onClick={() => {
                                               props.onNextPageHandler(+props.matchParams.page + 1)
                                           }}>{+props.matchParams.page + 2}</button>);
                        pages.push(<button key={key++} className={styles.PaginationButton}
                                           onClick={() => {
                                               props.onNextPageHandler(+props.matchParams.page + 2)
                                           }}>{+props.matchParams.page + 3}</button>);
                        pages.push(<p key={key++}>...</p>);
                        pages.push(<button key={key++} className={styles.PaginationButton}
                                           onClick={() => {
                                               props.onNextPageHandler(props.totalPages - 1)
                                           }}>{props.totalPages}</button>);

                        if (+props.matchParams.page < props.totalPages - 1)
                            pages.push(<button key={key++} className={styles.PaginationArrow}
                                               onClick={() => {
                                                   props.onNextPageHandler(+props.matchParams.page + 1)
                                               }}>
                                <ChevronDoubleRightIcon className={styles.ArrowIcon}/>
                            </button>)

                    }

                } else {
                    pages = initPageStart.slice();

                }
            }
        }
    }

    return (

        <div className={styles.Pages}>
            {pages}
        </div>

    )
}

export default Pagination