import React, {useContext, useEffect, useRef, useState} from 'react';
import Toolbar from '../Navigation/Toolbar/Toolbar';
import Media from "react-media";
import * as actions from "../../store/actions";
import {connect} from "react-redux";
import {FuzzySortContext} from "../../store/context/FuzzySort/FuzzySort";
import Wrapper from "../UI/Wrapper/Wrapper";
import SearchResultsHeader from "../SearchResultsPage/SearchResultsHeader/SearchResultsHeader";
import ArticleItems from "../ShopPage/ArticleItems/ArticleItems";
import LiveChatButton from "../UI/LiveChatButton/LiveChatButton";
import SearchArticlesResultsPageActionMenu
    from "./SearchArticlesResultsPageActionMenu/SearchArticlesResultsPageActionMenu";
import UnknownItem from "./UnknownItem/UnknownItem";
import FilterBreadcrumbs from "../Filters/FilterBreadcrumbs/FilterBreadcrumbs";
import shopPageStyles from "../ShopPage/ShopPage.module.css";

/*Komponenta koja predstavlja stranicu sa proizvodima koji odgovaraju unesenom string-u u pretrazi*/

const SearchArticlesResultsPage = (props) => {

    const fuzzySort = useContext(FuzzySortContext);
    const isFirstRun = useRef(true);
    const [breadcrumbs, breadcrumbsSetter] = useState(null);

    useEffect(() => {
        if (props.match.params.page !== undefined && props.match.params.searchedString === undefined) {
            if (props.totalElements !== -1 && props.totalPages !== -1) {
                props.onFetchSliceOfArticles(props.match.params.page, localStorage.getItem('categoryFilter') || props.shopFilter.categoryId, null, props.loyaltyFilter);
            } else {
                props.onFetchAllArticles(props.match.params.page, localStorage.getItem('categoryFilter') || props.shopFilter.categoryId, null, props.loyaltyFilter);
            }
        }
    }, [props.match.params.page])


    // reaguje na promenu kategorije ili filtera i vraca na page = 0
    useEffect(() =>{
        if (props.match.params.page !== undefined && props.match.params.searchedString === undefined) {
            if (props.totalElements !== -1 && props.totalPages !== -1) {
                props.onFetchAllArticles(0, localStorage.getItem('categoryFilter') || props.shopFilter.categoryId, null, props.loyaltyFilter);
            }
        }
    }, [props.shopFilter, props.loyaltyFilter])

    useEffect(() => {
        if (props.match.params.page !== undefined && props.match.params.searchedString !== undefined) {
            if ((props.totalSearchedElements !== -1 && props.totalSearchedPages !== -1) || props.articles === null) {
                props.onFetchAllArticles(0, localStorage.getItem('categoryFilter') || props.shopFilter.categoryId, props.matchedItems, props.loyaltyFilter);
            } else {
                props.onFetchAllArticles(props.match.params.page, localStorage.getItem('categoryFilter') || props.shopFilter.categoryId, null, props.loyaltyFilter);
            }
        }
    }, [props.shopFilter, props.loyaltyFilter])


    //poziva se kad god se ucitava novi niz pretrazenih lekova
    //poziva se ako smo promenili searchString u putanji, tj. ako smo pretrazili novi string u search bar-u.
    // ako je stranica refresh-ovana onda je matchedArticlesNames = null i moramo ga ponovo ucitati sa back-a sve articlesNames
    // da bismo izracunali matchedArticlesNames
    useEffect(() => {
        if (props.match.params.page !== undefined && props.match.params.searchedString !== undefined) {
            if (props.matchedArticlesNames === null) {
                props.onFetchAllArticles(props.match.params.page, localStorage.getItem('categoryFilter') || props.shopFilter.categoryId, props.matchedItems, props.loyaltyFilter);
            } else {
                isFirstRun.current = false;
                props.onMatchedItemsChanged(props.matchedArticlesNames);
                if (props.totalSearchedElements !== -1 && props.totalSearchedPages !== -1) {
                    props.onFetchSliceOfArticles(props.match.params.page, localStorage.getItem('categoryFilter') || props.shopFilter.categoryId, props.matchedItems, props.loyaltyFilter)
                } else {
                    props.onFetchAllArticles(0, localStorage.getItem('categoryFilter') || props.shopFilter.categoryId, props.matchedItems, props.loyaltyFilter);
                }
            }
        }
    }, [props.match.params.searchedString, props.match.params.page])



    //izbegavamo pozivanje funcionalnosti ovog useEffect-a kada prvi put ulazimo na stranicu normalno preko main page-a (ne refresh-om iste),
    // ovo radimo preko isFirstRun ref promenjive
    // ako je korisnik refreshovao stranicu , gornji useEffect ce pozvati ponovno ucitavanje svih articlesNames-a.
    // Kada se ucitaju articlesNames, poziva se funkcionalnost ovog useEffect-a koji na osnovu putanje ili radi filter
    // apoteka po nazivu za string iz putanje ili vraca sve apoteke. Zatim poziva back kako bi dobili sve informacije o tim
    //filtriranim apotekama.
    useEffect(() => {
        if (props.articlesNames !== null && isFirstRun.current && props.match.params.searchedString !== undefined) {
            const searchStringWords = fuzzySort.separateSearchStringByWords(props.match.params.searchedString);
            const matchingResult = fuzzySort.sortResultsByStartWord(fuzzySort.filterByWords(props.articlesNames, searchStringWords, 0), searchStringWords[0]);
            props.onMatchedItemsChanged(matchingResult);
            if (props.totalSearchedElements !== -1 && props.totalSearchedPages !== -1) {
                props.onFetchSliceOfArticles(props.match.params.page, localStorage.getItem('categoryFilter') || props.shopFilter.categoryId, props.matchedItems, props.loyaltyFilter)
            } else {
                props.onFetchAllArticles(props.match.params.page, localStorage.getItem('categoryFilter') || props.shopFilter.categoryId, matchingResult, props.loyaltyFilter);
            }
        }
    }, [props.articlesNames]);



    useEffect(() => {

        return () => {
            localStorage.removeItem('selectedArticleFilter');
            localStorage.removeItem('articleFilter_loyalty');
            props.onResetShopFilter();
            props.onResetArticles();
        }
    }, [])

    useEffect(()=>{
        if(props.filterBreadcrumbs) {
            breadcrumbsSetter(
                <div className={shopPageStyles.Breadcrumbs}>
                    &nbsp;
                    <div onClick={onFilterBreadcrumbsCategoryClick} className={shopPageStyles.BreadcrumbText}>{props.filterBreadcrumbs.catName}</div>
                    <div onClick={onFilterBreadcrumbsSubcategoryClick}> {props.filterBreadcrumbs.scat1Name && <span>&nbsp;/ </span>} <span className={shopPageStyles.BreadcrumbText}>{props.filterBreadcrumbs.scat1Name}</span></div>
                    <div> {props.filterBreadcrumbs.scat2Name && <span>&nbsp;/ </span>} <span className={shopPageStyles.BreadcrumbText}>{props.filterBreadcrumbs.scat2Name}</span></div>
                </div>
            )
        }
    }, [props.filterBreadcrumbs, props.shopFilter])

    const onFilterBreadcrumbsCategoryClick = () => {
        props.onFilterBreadcrumbs(
            {
                ...props.filterBreadcrumbs,
                scat1: null,
                scat1Name: null,
                scat2: null,
                scat2Name: null
            });
        props.onShopFilterSelection({categoryId: props.filterBreadcrumbs.cat})
    }
    const onFilterBreadcrumbsSubcategoryClick = () => {
        props.onFilterBreadcrumbs(
            {
                ...props.filterBreadcrumbs,
                scat2: null,
                scat2Name: null
            });
        props.onShopFilterSelection({categoryId: props.filterBreadcrumbs.scat1})
    }

    const resetCategories = () => {
        props.onFilterBreadcrumbs(
            {
                cat: null,
                catName: null,
                scat1: null,
                scat1Name: null,
                scat2: null,
                scat2Name: null
            });
        props.onShopFilterSelection(null)
    }

    return (
        <React.Fragment>
            <Toolbar/>
            <LiveChatButton />
            <Media queries={{
                small: "(max-width: 699px)",
                large: "(min-width: 700px)"
            }}>
                {matches => (
                    <Wrapper>
                        {matches.small && (
                                <SearchArticlesResultsPageActionMenu/>
                        )}

                        {matches.large &&
                            <>
                                <SearchResultsHeader/>
                                <SearchArticlesResultsPageActionMenu/>
                                {/*<ShopPageActionMenu/>*/}
                                {/*<ArticleItems allArticles={props.articles}/>*/}
                                {props.filterBreadcrumbs.cat &&
                                    <FilterBreadcrumbs onResetClick={resetCategories}>
                                        <div>{breadcrumbs}</div>
                                    </FilterBreadcrumbs>
                                }
                            </>
                        }

                        {!window.location.pathname.includes('unknown-item') ?
                            <ArticleItems articles={props.allArticles}
                                          loadingArticles={props.loadingAllArticles}
                                          articlesFailed={props.allArticlesFailed}
                                          totalPages={props.match.params.searchedString !== undefined ? props.totalSearchedPages : props.totalPages}
                                          totalElements={props.match.params.searchedString !== undefined ? props.totalSearchedElements : props.totalElements}
                                          selectedArticles={props.articles}
                                          selectedArticlesFailed={props.articlesError}
                                          loadingSelectedArticles={props.loadingArticles}
                                          searchedParams={props.match.params.searchedString}/>
                            :
                            <UnknownItem />
                        }
                    </Wrapper>
                )}
            </Media>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => {
    return {
        matchedArticlesNames: state.mainPage.matchedItems,
        articlesNames: state.mainPage.articles,
        matchedItems: state.mainPage.matchedItems,

        allArticles: state.articlesSearchPage.allArticles,
        loadingAllArticles: state.articlesSearchPage.loadingAllArticles,
        allArticlesFailed: state.articlesSearchPage.allArticlesFailed,
        totalElements: state.articlesSearchPage.totalArticleElements,
        totalPages: state.articlesSearchPage.totalArticlePages,

        articles: state.articlesSearchPage.articles,
        articlesError: state.articlesSearchPage.articlesError,
        articlesServerError: state.articlesSearchPage.articlesServerError,
        loadingArticles: state.articlesSearchPage.loadingArticles,
        totalSearchedElements: state.articlesSearchPage.totalElements,
        totalSearchedPages: state.articlesSearchPage.totalPages,

        shopFilter: state.filter.shopFilter,
        filterBreadcrumbs: state.filter.filterBreadcrumbs,

        loyaltyFilter: state.filter.loyaltyFilter
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onMatchedItemsChanged: (matchedItems) => dispatch(actions.matchedItemsChanged(matchedItems)),
        onFetchAllArticles: (page, categoryId, targetArray, loyaltyFilter) => dispatch(actions.fetchAllArticles(page, categoryId, targetArray, loyaltyFilter)),
        onFetchSliceOfArticles: (page, categoryId, targetArray, loyaltyFilter) => dispatch(actions.fetchSliceOfArticles(page, categoryId, targetArray, loyaltyFilter)),
        onResetShopFilter: () => dispatch(actions.resetShopFilterSelection()),
        onResetArticles: () => dispatch(actions.resetArticles()),
        onFilterBreadcrumbs: (filterBreadcrumbs) => dispatch(actions.filterBreadcrumbs(filterBreadcrumbs)),
        onShopFilterSelection: (shopFilter) => dispatch(actions.shopFilterSelection(shopFilter)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(SearchArticlesResultsPage);
