import React, {useEffect, useState} from 'react';
import FilterModal from "../../../UI/Modals/FilterModal/FilterModal";
import styles from "./ShopFilter.module.css";
import * as actions from "../../../../store/actions";
import {connect} from "react-redux";
import {ArrowUpIcon} from "@heroicons/react/solid";
import Input from "../../../UI/Input/Input";
import Button from "../../../UI/Buttons/Button/Button";

const ShopFilter = (props) => {

    const [activeFilter, activeFilterSetter] = useState('');

    useEffect(() => {
        activeFilterSetter(localStorage.getItem('selectedArticleFilter') || 'OTHER_ITEM')
    }, [localStorage.getItem('selectedArticleFilter')])

    const categorySelection = (category) => {
        props.onShopFilterSelection(category);
        // props.backdropClickHandler();
        localStorage.setItem('selectedArticleFilter', category);
    }

    const rootCategoryList = <>
        <div className={styles.RootCategoryList}>
            <p>Tip proizvoda</p>
        </div>
    </>

    const subCategoryList = <>
        <span className={styles.SubCategoryTitle} style={{borderBottom:"1px solid #e2e8ecff"}}>Tip proizvoda</span> {/*izabrani root category*/}
        <div className={styles.SubCategoryList}>
        {/*    <div className={activeFilter !== 'DRUG_ITEM' ? styles.SubCategoryWrapper : [styles.SubCategoryWrapper, styles.ActiveFilter].join(' ')} onClick={() => {categorySelection('DRUG_ITEM')}}>Lekovi</div>*/}
        {/*    <div className={activeFilter !== 'PRESCRIPTION_DRUG_ITEM' ? styles.SubCategoryWrapper : [styles.SubCategoryWrapper, styles.ActiveFilter].join(' ')} onClick={() => {categorySelection('PRESCRIPTION_DRUG_ITEM')}}>Lekovi na recept</div>*/}
        {/*    <div className={activeFilter !== 'SUPPLEMENT_ITEM' ? styles.SubCategoryWrapper : [styles.SubCategoryWrapper, styles.ActiveFilter].join(' ')} onClick={() => {categorySelection('SUPPLEMENT_ITEM')}}>Nelekovi</div>*/}
        {/*    <div className={activeFilter !== 'OTHER_ITEM' ? styles.SubCategoryWrapper : [styles.SubCategoryWrapper, styles.ActiveFilter].join(' ')} onClick={() => {categorySelection('OTHER_ITEM')}}>Svi artikli</div>*/}
        {/*</div>*/}
            <div className={styles.RadioButtonDiv}
                 onClick={() => {categorySelection('DRUG_ITEM')}}>
                <Input inputtype="checkbox" type="radio"
                       style={{
                           opacity: "1",
                           position: "relative",
                           width: "20px",
                           transform: "scale(1.4)",
                           display: "block"
                       }}
                       checked={activeFilter === 'DRUG_ITEM'}
                       // onChange={() => filterHandler(filter.storageKey)}
                />
                <span>&nbsp;Lekovi</span>
            </div>
            <div className={styles.RadioButtonDiv}
                 onClick={() => {categorySelection('PRESCRIPTION_DRUG_ITEM')}}>
                <Input inputtype="checkbox" type="radio"
                       style={{
                           opacity: "1",
                           position: "relative",
                           width: "20px",
                           transform: "scale(1.4)",
                           display: "block"
                       }}
                       checked={activeFilter === 'PRESCRIPTION_DRUG_ITEM'}
                    // onChange={() => filterHandler(filter.storageKey)}
                />
                <span>&nbsp;Lekovi na recept</span>
            </div>
            <div className={styles.RadioButtonDiv}
                 onClick={() => {categorySelection('SUPPLEMENT_ITEM')}}>
                <Input inputtype="checkbox" type="radio"
                       style={{
                           opacity: "1",
                           position: "relative",
                           width: "20px",
                           transform: "scale(1.4)",
                           display: "block"
                       }}
                       checked={activeFilter === 'SUPPLEMENT_ITEM'}
                    // onChange={() => filterHandler(filter.storageKey)}
                />
                <span>&nbsp;Nelekovi</span>
            </div>
            <div className={styles.RadioButtonDiv} style={{borderTop:"1px solid #e2e8ecff", borderBottom:"1px solid #e2e8ecff"}}
                 onClick={() => {categorySelection('OTHER_ITEM')}}>
                <Input inputtype="checkbox" type="radio"
                       style={{
                           opacity: "1",
                           position: "relative",
                           width: "20px",
                           transform: "scale(1.4)",
                           display: "block"
                       }}
                       checked={activeFilter === 'OTHER_ITEM'}
                    // onChange={() => filterHandler(filter.storageKey)}
                />
                <span>&nbsp;Svi proizvodi</span>
            </div>
            <div style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "70px",
                boxSizing: "border-box"
            }}>
                <Button buttontype="whitedarkblue" style={{
                    width: "60%",
                    display: "flex",
                    justifyContent: "center",
                    padding: "1rem",
                    fontWeight: "800",
                    position: "relative"
                }}
                        onClick={props.backdropClickHandler}
                >Zatvori</Button>
            </div>
        </div>
    </>

    return (
        <FilterModal classNameContent={styles.ShopFilter}
                     show={props.show}
                     backdropClickHandler={props.backdropClickHandler}>
            <div style={{position:"absolute"}}><ArrowUpIcon/></div>
            {/*<div className={styles.RootCategoryWrapper}>{rootCategoryList}</div>*/}
            <div className={styles.SubCategoriesWrapper}>{subCategoryList}</div>
        </FilterModal>
    );
}

const mapStateToProps = (state) => {
    return {
        shopFilter: state.filter.shopFilter
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onShopFilterSelection: (shopFilter) => dispatch(actions.shopFilterSelection(shopFilter))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ShopFilter);