import * as actionsTypes from './actionsTypes'


export const modalResHandler = (modalResData) => {
    return {
        type: actionsTypes.SHOW_MODAL_RES,
        modalResData: modalResData
    };
}

export const CNCModalToggle = (cncModalToggle) => {
    return {
        type: actionsTypes.CNC_MODAL_TOGGLE,
        cncModalToggle: cncModalToggle
    };
}

export const chooseLocationModalToggle = (chooseLocationModalToggle) => {
    return {
        type: actionsTypes.CHOOSE_LOCATION_MODAL_TOGGLE,
        chooseLocationModalToggle: chooseLocationModalToggle
    };
}