import React from "react";
import styles from './ComingSoon.module.css'
import Wrapper from "../../UI/Wrapper/Wrapper";
import Toolbar from "../../Navigation/Toolbar/Toolbar";
// import UnderConstructionImage from "../../../assets/UnderConstruction.png";
import {ReactComponent as Retort} from './../../../assets/icons/retort.svg'

const ComingSoon = () => {
    return(
        <div>
            <Wrapper>
                <Toolbar/>
                <div className={styles.ComingSoonWrapper}>
                    {/*<img style={{width:"300px", marginTop:"6rem"}} src={UnderConstructionImage} alt=""/>*/}
                    <Retort style={{width:"200px", fill:"#03B2B1"}} />
                    <div style={{display:"flex", justifyContent:"center", flexDirection:"column"}}>
                        <br/>
                        <br/>
                        <br/>
                        <span style={{fontSize:"42px"}}>Stranica je u izradi!</span>
                        <span style={{fontSize:"36px"}}>Hvala na razumevanju</span>
                    </div>

                </div>
            </Wrapper>
        </div>
    );
}
export default ComingSoon;