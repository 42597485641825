import React from 'react';
import styles from "./AlertComponent.module.css";
import {ExclamationCircleIcon} from "@heroicons/react/outline";

const AlertComponent = (props) => {

    let alertComponent;
    switch (props.alerttype) {
        case ('warning'):
            alertComponent =
                <div className={`${styles.AlertWrapper} ${styles.WarningAlert}`} {...props}>
                    <ExclamationCircleIcon style={{width: "24px"}}/>
                    &nbsp;{props.children}
                </div>
            break;
        default:
            alertComponent = <div
                className={`${styles.AlertWrapper} ${styles.WarningAlert}`}
                {...props}><ExclamationCircleIcon style={{width: "24px"}}/>&nbsp;{props.children}</div>
    }

    return (
        <>
            {alertComponent}
        </>
    );
}

export default AlertComponent;
