import React, {useEffect} from 'react'
import styles from './PharmacyData.module.css'
import {ClockIcon} from "@heroicons/react/outline";
import Spinner from "../../../UI/Spinner/Spinner";
import {selectedStoreOpeningHours} from "../../../GobalFunctions/GlobalFunctions";
import * as actions from "../../../../store/actions";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {isStoreOpenNow} from "../../../SearchResultsPage/SearchResults/StoreItems/StoreItem/StoreItem";

const PharmacyData = (props) => {

    useEffect(() => {
        /**
         *  Moraju da se ucitaju podaci o apoteci ponovo jer se prilikom izlaska sa ShopPage.js brise selectedStore
         * */
        props.onFetchSelectedStore(props.match.params.marketplaceId, props.match.params.branchMarketplaceId);


    }, []);

    let storeWorkingHours = <Spinner />
    if(props.selectedStore !== null) {
        storeWorkingHours = selectedStoreOpeningHours(props.selectedStore.marketplaceResponse.openingHoursResponse);
    }

    const isOpen = props.selectedStore !== null && isStoreOpenNow(props.selectedStore.marketplaceResponse.openingHoursResponse);

    let phoneNumbers = <Spinner />
    if(props.selectedStore !== null ) {
        if(props.selectedStore.marketplaceResponse.phoneNumber !== null) {
            phoneNumbers = props.selectedStore.marketplaceResponse.phoneNumber.split(',');
            phoneNumbers = phoneNumbers.map((phoneNumber, index) => {
                return (
                    <p key={index} style={{lineHeight: "6px"}}><a href={`tel:${phoneNumber}`}>{phoneNumber}</a></p>
                )
            })
        } else {
            phoneNumbers = null;
        }
    }

    let pharmacyData;
    if(props.selectedStore !== null) {
        pharmacyData =
            <div className={styles.ShopInfo}>
                {/*<p>{props.selectedStore.branchMarketplaceResponse.name}</p>*/}
                <div className={styles.PharmacyName}>{props.selectedStore.marketplaceResponse.name}</div>
                <div className={styles.WorkingHours}>
                    <ClockIcon style={{width: "1.5rem"}}/>
                    {
                        <p style={{paddingLeft: "8px"}}>
                            &nbsp;<strong>{storeWorkingHours}</strong>
                            {isOpen !== null &&
                                !isOpen &&
                            <span style={{color:"#FC6D6D", fontWeight:"bold"}}>&nbsp;(Zatvoreno)</span>
                            }
                        </p>
                    }

                </div>
                {isOpen !== null &&
                !isOpen &&
                <div className={styles.PharmacyClosed}>
                    <span className={styles.PharmacyClosedText}>Vaša porudžbina će biti obrađena po otvaranju apoteke.</span>
                    <br/>
                </div>
                }
                <div className={styles.PharmacyAddress}>{props.selectedStore.marketplaceResponse.address}, {props.selectedStore.marketplaceResponse.location}</div>
                <div>{phoneNumbers}</div>
            </div>
    } else {
        pharmacyData =
            <div className={styles.ShopInfo}>
                <p>Greška pri učitavanju podataka</p>
            </div>
    }
    return (
        <>
            {pharmacyData}
        </>
    )

}


const mapStateToProps = (state) => {
    return {
        selectedStore: state.appPage.selectedStore,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onFetchSelectedStore: (branchMarketplaceId, marketplaceId) => dispatch(actions.fetchSelectedStore(branchMarketplaceId, marketplaceId)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PharmacyData))