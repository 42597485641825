import React, {useState} from 'react'
import {Document, Page, pdfjs} from "react-pdf";
import PDF from "./../../../assets/PDFfiles/Misljenje-Ministarstva-zdravlja.pdf";
import styles from './MisljenjeMinistarstva.module.css'
import Toolbar from "../../Navigation/Toolbar/Toolbar";
import Spinner from './../../../components/UI/Spinner/Spinner'
import Wrapper from "../../UI/Wrapper/Wrapper";

const options = {
    cMapUrl: 'https://unpkg.com/pdfjs-dist@${pdfjs.version}/cmaps/',
    cMapPacked: true,
    standardFontDataUrl: 'standard_fonts/',
};


pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const MisljenjeMinistarstva = () => {

    const [numPages, setNumPages] = useState(null);


    function onDocumentLoadSuccess({ numPages: nextNumPages }) {
        setNumPages(nextNumPages);
    }

    let width;
    if(window.screen.width >= 700) {
        width = window.screen.width * 50 / 100;
    } else {
        width = window.screen.width * 96 / 100;
    }

    return (
        <Wrapper>
            <Toolbar />
            <div className={styles.PdfContainer}>
            <Document file={PDF}
                      onLoadSuccess={onDocumentLoadSuccess}
                      onContextMenu={(e) => e.preventDefault()}
                      className={styles.PdfFile}
                      options={options}
                      loading={<Spinner />}

            >
                {Array.from(new Array(numPages), (el, index) => (
                    <Page key={`page_${index + 1}`} pageNumber={index + 1} width={width} />
                ))}
            </Document>
            </div>
        </Wrapper>
    );

}

export default MisljenjeMinistarstva;