import React, {useState, Fragment} from 'react';
import styles from './AuthPage.module.css'
import {Route, withRouter} from "react-router-dom";
import LogInPane from "./LogInPane/LogInPane";
import RegistrationPane from "./RegistrationPane.js/RegistrationPane";
// import {motion} from "framer-motion";
import configData from "../../configConstants.json";
import logo from '../../assets/logo_apoteka.svg';
import Image from '../../assets/authPageExample/welcome.jpg'
import {GoogleLogin} from "react-google-login";
import * as actions from "../../store/actions";
import Media from 'react-media';
import {connect} from "react-redux";

const AuthPage = (props) => {

    const redirectToRegistration = () => {
        const url = '/auth/registration';
        props.history.push(url);
    }

    const redirectToLogin = () => {
        const url = '/auth/login';
        props.history.replace(url);
    }

    const [isLoginState, isLoginStateChanged] = useState({
        isLogin: true
    });

    const isLoginHandler = (param) => {
        isLoginStateChanged({
            isLogin: param
        })
    }

    const switchToRegister = () => {
        setTimeout(() => {
            redirectToRegistration();
        }, 300);
    }

    const switchToLogin = () => {
        setTimeout(() => {
            redirectToLogin();
        }, 300);
    }

    const returnToMainPageHandler = () => {
        const url = '/';
        props.history.push(url)
    }

    const responseGoogle = (response) => {
        const userData = {
            username: response.profileObj.email,
            password: response.profileObj.googleId,
            firstName: response.profileObj.givenName,
            secondName: response.profileObj.familyName,
            // userPhoto: response.profileObj.imageUrl
        }
        props.onLoginGoogleUser(userData);
    }

    //google client secret 2lIvkS0OLQp-QUnYOsiIs5qI(stari)  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
    return (
        <div className={styles.AuthPageHolder}>
            <div className={styles.AuthPageContainer}>
                <div className={styles.AuthPageContent}>
                    <div className={styles.TopContainer}>
                        <img src={logo} alt="apotekaLogo" className={styles.AuthPageLogo} onClick={returnToMainPageHandler}/>
                        <br/>
                        { window.location.pathname.includes('login') ?
                        <GoogleLogin
                            clientId="1010729304281-khqrkt8h05oc63ff4jq3a27ertdivkc4.apps.googleusercontent.com"
                            // Ako hocemo custom google dugme
                            // render={renderProps => (
                            //     <button onClick={renderProps.onClick} disabled={renderProps.disabled}>This is my custom Google button</button>
                            // )}
                            className={styles.GoogleButton}
                            buttonText="Nastavi sa Google nalogom"
                            onSuccess={responseGoogle}
                            onFailure={responseGoogle}
                            cookiePolicy={'single_host_origin'}
                            isSignedIn={false}
                        /> : <><br/><br/></>}
                        {/*todo ukoliko se zadrzava ova verzija auth stranica potrebno je ili izbrisati ove tekstove ispod ili ih drugacije stilizovati*/}
                        {/*<div className={styles.HeaderContainer}>*/}
                        {/*    {!isLoginState.isLogin &&*/}
                        {/*    <div className={styles.Checkbox}>*/}
                        {/*        <Input inputtype="checkbox" type="checkbox" style={{opacity: "1", width: "fit-content", position: "relative"}}*/}
                        {/*            // checked={inputState.store.isApotekaRs}*/}
                        {/*            // onChange={isApotekaRsHandler}*/}
                        {/*        />*/}
                        {/*        <p className={styles.SmallText}>{configData.AuthPages.RegistrationSubtitle}*/}
                        {/*            <a className={styles.Legal}*/}
                        {/*                     onClick={() => props.onModalPPandTOSHandler({opened:true, type:"PP"})}>Politiku privatnosti </a>*/}
                        {/*            i*/}
                        {/*            <a className={styles.Legal}*/}
                        {/*                     onClick={() => props.onModalPPandTOSHandler({opened:true, type:"TOS"})}> Uslove korišćenja</a>*/}
                        {/*        </p>*/}
                        {/*    </div>}*/}
                        {/*</div>*/}
                    </div>
                    <div>
                        <Route path="/auth/login" exact render={(props) => (
                            <LogInPane {...props} isLogin1={isLoginHandler}/>
                        )}/>
                        <Route path="/auth/registration" exact render={(props) => (
                            <RegistrationPane {...props} isLogin1={isLoginHandler}/>
                        )}/>
                    </div>
                    <div className={styles.Links}>
                        {isLoginState.isLogin &&
                        <div>
                            <p>Nemaš nalog? - <a onClick={switchToRegister}>Registruj se</a></p>
                        </div>}
                        {!isLoginState.isLogin &&
                        <div>
                            <p>Već imaš nalog? - <a onClick={switchToLogin}>Prijavi se</a></p>
                        </div>}
                    </div>
                </div>
            </div>
            <Media
                queries={{
                    small: "(max-width: 699px)",
                    large: "(min-width: 700px)"
                }}>
                {matches => (
                    <Fragment>
                        {matches.large &&
                            <div className={styles.AuthPageCommercial}>
                                <div className={styles.Grad}></div>
                                <img src={Image} style={{height: "100%"}} alt='login image'/>
                            </div>
                        }

                    </Fragment>)}
            </Media>
        </div>
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        onLoginGoogleUser: (userData) => {dispatch(actions.authenticateGoogleUser(userData))}
    }
}

export default connect(null, mapDispatchToProps)(withRouter(AuthPage));
