import React, {useState} from "react";
import {UsersIcon} from "@heroicons/react/outline";
import styles from "../AuthPage.module.css";
import Input from "../../UI/Input/Input";
import configData from "../../../configConstants.json";
import Button from "../../UI/Buttons/Button/Button";
import ModalWithHeader from "../../UI/Modals/ModalWithHeader/ModalWithHeader";
import * as actions from "../../../store/actions";
import {connect} from "react-redux";
import {Link} from "react-router-dom";

const PPTOSModal = (props) => {

    const [isPPAccepted, isPPAcceptedHandler] = useState(false);
    const [isTOSAccepted, isTOSAcceptedHandler] = useState(false);
    const [isPPAndToSTextAccepted, isPPAndToSTextAcceptedHandler] = useState(false);
    const [is18plus, is18plusHandler] = useState(false);

    const closePPTOSModal = () => {
        props.onPPTOSModalOpenHandler(false);
    }

    const submitFormHandler = () => {
        if (isPPAccepted && isTOSAccepted && is18plus) {
            props.onSubmitModal();
            isPPAndToSTextAcceptedHandler(true);
        }
    }

    const ppAndTosText =
        <div className={styles.ModalContentWrapper}>
            <div style={{fontWeight: "bold"}}>Opšti uslovi korišćenja i izjava o privatnosti</div>
            <p style={{fontSize: "14px"}}>Neophodno je biti punoletan i prihvatiti opšte uslove korišćenja i politiku privatnosti kako biste koristili sajt.</p>
            <div className={styles.HeaderContainer}>
                <div style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
                    <div className={styles.Checkbox}>
                        <Input inputtype="checkbox" type="checkbox"
                               style={{opacity: "1", width: "fit-content", position: "relative", accentColor:"#00B2B1"}}
                               checked={isPPAccepted}
                               onChange={() => {
                                   isPPAcceptedHandler(!isPPAccepted)
                               }}
                        />
                        <p className={styles.SmallText}>{configData.AuthPages.RegistrationSubtitle}
                            <Link className={styles.Legal}
                                  to={'/TermsOfService'} target="_blank">Politiku
                                privatnosti </Link>
                        </p>
                    </div>
                    <div className={styles.Checkbox}>
                        <Input inputtype="checkbox" type="checkbox"
                               style={{opacity: "1", width: "fit-content", position: "relative", accentColor:"#00B2B1"}}
                               checked={isTOSAccepted}
                               onChange={() => {
                                   isTOSAcceptedHandler(!isTOSAccepted)
                               }}
                        />
                        <p className={styles.SmallText}>{configData.AuthPages.RegistrationSubtitle}
                            <Link className={styles.Legal}
                                  to={'/PrivacyPolicy'} target="_blank"> Uslove korišćenja</Link>
                        </p>
                    </div>
                    <div className={styles.Checkbox}>
                        <Input inputtype="checkbox" type="checkbox"
                               style={{opacity: "1", width: "fit-content", position: "relative", accentColor:"#00B2B1"}}
                               checked={is18plus}
                               onChange={() => {
                                   is18plusHandler(!is18plus)
                               }}
                        />
                        <p className={styles.SmallText}>
                           Imam više od 18 godina
                        </p>
                    </div>
                </div>
            </div>
        </div>

    const verificationMailSentText =
        <div className={styles.ModalContentWrapper}>
            <div className={styles.HeaderContainer}>
                <div style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
                    <p style={{fontSize: "14px"}}>Verifikacioni mail je poslat na Vašu adresu.</p>
                </div>
            </div>
        </div>

    return (
        <>
            {!props.registrationError && props.PPTOSModalOpened ?
                <ModalWithHeader onCloseClick={closePPTOSModal}
                                 icon={<UsersIcon style={{width: "48px", height: "48px"}}/>}
                                 show={props.PPTOSModalOpened}
                                 backdropClickHandler={closePPTOSModal}
                                 buttons={!isPPAndToSTextAccepted
                                     ?
                                     <div style={{width:"100%", display:"flex", justifyContent:"center"}}>
                                     <Button
                                         buttontype={isPPAccepted && isTOSAccepted && is18plus? "blue" : "disabled"}
                                         onClick={submitFormHandler}>Sačuvaj
                                     </Button>
                                     </div>
                                     :
                                     <div style={{width:"100%", display:"flex", justifyContent:"center"}}>
                                     <Button
                                         buttontype="blue"
                                         onClick={closePPTOSModal}
                                     > Zatvori </Button>
                                     </div>
                                 }
                >
                    {!isPPAndToSTextAccepted ?
                        ppAndTosText
                        :
                        verificationMailSentText
                    }
                </ModalWithHeader>
                : null
            }
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        PPTOSModalOpened: state.authPage.PPTOSModalOpened,
        registrationError: state.authPage.registrationError,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onPPTOSModalOpenHandler: (PPTOSModalOpened) => {
            dispatch(actions.PPTOSModalOpenHandler(PPTOSModalOpened))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PPTOSModal);
