import React from "react";
import styles from './ButtonRes.module.css'

const ButtonRes = (props) => {

    let button;
    switch (props.buttontype) {
        case ('big-button'):
            button = <button
                className={styles.BigButton}
                {...props}>
                {props.children}
            </button>
            break;
        default:
            button = <button
                className={styles.Link}
                {...props}/>
    }

    return (
        <>
        {button}
        </>
    );
}

export default ButtonRes;