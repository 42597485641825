import React, {useEffect, useState} from "react";
import ModalWithHeader from "../ModalWithHeader/ModalWithHeader";
import Button from "../../Buttons/Button/Button";
import {connect} from "react-redux";
import Spinner from "../../Spinner/Spinner";

const SocketModal = (props) => {

    const [modalContent, modalContentSetter] = useState(null)
    const [countDownTimer, countDownTimerSetter] = useState(3)

    const countDownFunc = () => {
        setInterval(() => {
            if (countDownTimer >= 0)
                countDownTimerSetter(prev => prev - 1)
        }, 1000)
    }

    useEffect(() => {
        if (countDownTimer === 0) {
            props.redirectToSendOrder();
        }
    }, [countDownTimer])

    useEffect(() => {
        if (props.orderSocketConnected === null && !props.orderSocketConnectionStart) {
            modalContentSetter(
                <>
                    <p><strong>Došlo je do gubitka koneckije.</strong></p>
                    <p><strong>Pokušaj ponovo.</strong></p>
                </>
            )
        } else if (props.orderSocketConnectionStart) {
            modalContentSetter(
                <>
                    <p>Uspostavljanje konekcije...</p>
                    <Spinner/>
                </>
            )
        } else if (props.orderSocketConnected === true) {
            modalContentSetter(
                <>
                    <p>Konekcija je uspostavljena.</p>
                    <p>Tvoja porudžbina će biti prosleđena apoteci.</p>
                    <p>{countDownTimer}</p>
                </>
            );
            countDownFunc();
        }
    }, [props.orderSocketConnected, props.orderSocketConnectionStart, countDownTimer])


    return (
        <ModalWithHeader noXIcon
                         buttons={
                             <div style={{width: "100%", display: "flex", justifyContent: "center"}}>
                                     <Button
                                         buttontype="blue"
                                         onClick={!props.orderSocketConnectionStart ? props.connectAgain : props.backdropClickHandler}>U redu
                                     </Button>
                             </div>
                         }
                         show={props.show}
                         backdropClickHandler={props.backdropClickHandler}>
            <div style={{display: "flex", flexDirection: "column", alignItems: "center", marginTop: "2rem"}}>
                {modalContent}
            </div>
        </ModalWithHeader>
    );
}

const mapStateToProps = (state) => {
    return {
        orderSocketConnected: state.orderCompletion.orderSocketConnected,
        orderSocketConnectionStart: state.orderCompletion.orderSocketConnectionStart
    }
}

export default connect(mapStateToProps)(SocketModal);