import React, {Fragment} from "react"
import RegulationImage from "../../../assets/icons/regulativa.svg";
import {ExclamationCircleIcon} from "@heroicons/react/outline";
import Media from "react-media";

const RegulativaNotice = (props) => {

    return (
        <div style={props.wrappingStyle}>


            <Media
                queries={{
                    small: "(max-width: 699px)",
                    large: "(min-width: 700px)"
                }}>

                {matches => (
                    <Fragment>
                        {matches.small &&
                        <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center"}}>
                            <img style={{width:"60%"}}
                                 src={RegulationImage}
                                 alt=""
                            />
                            <ExclamationCircleIcon style={{width:"24px", color:"#F58300", marginTop:".5rem"}}/> &nbsp;&nbsp;

                        </div>
                        }

                        {matches.large &&
                        <div style={props.wrappingStyle}>
                            <img style={props.imgStyle}
                                 src={RegulationImage}
                                 alt=""
                            />
                            <div style={props.style}>
                                <ExclamationCircleIcon style={props.iconStyle}/> &nbsp;&nbsp;
                                <span style={{fontSize: "12px"}}>Zbog zakonskih regulativa nije moguće prikazati fotografiju i cenu ovog leka</span>
                            </div>
                        </div>
                        }
                    </Fragment>
                )}
            </Media>

        </div>
    )

}

export default RegulativaNotice;