import React, {useEffect, useState} from "react";
import ShoppingCartListItem from './ShoppingCartListItem/ShoppingCartListItem';
import * as actions from "../../../store/actions";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import Spinner from "../../UI/Spinner/Spinner"
import styles from './ShoppingCartList.module.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from "react-perfect-scrollbar";
import AlertComponent from "../../UI/AlertComponent/AlertComponent";

const ShoppingCartList = (props) => {

    const [alertMessage, setAlertMessage] = useState({
        alertIndex: -1
    });

    useEffect(() => {
        if (props.userInfo !== null && props.shoppingCart === null) {
            props.onFetchShoppingCart(props.userInfo.id, props.match.params.branchMarketplaceId, props.match.params.marketplaceId);
        }
    }, [props.match.params.branchMarketplaceId, props.match.params.marketplaceId, props.userInfo]);

    useEffect(() => {
            if (alertMessage.alertIndex >= 0) {
                let setAlert = {...alertMessage}
                setAlert.alertIndex = -1;
                setTimeout(() => {
                    setAlertMessage(setAlert);
                }, 2000);
            }
    }, [alertMessage.alertIndex]);

    const removeFromShoppingCart = (itemInShoppingCart) => {
        props.onRemoveItemFromShoppingCart(props.userInfo.id, props.match.params.branchMarketplaceId, props.match.params.marketplaceId, itemInShoppingCart)
    }

    const increaseQuantityOfShoppingCartItemHandler = (itemInShoppingCart, index) => {
        console.log('item in shopping cart')
        console.log(itemInShoppingCart)
        if (props.userInfo !== null) {
            if (itemInShoppingCart.stockQuantity > itemInShoppingCart.quantity) {
                if (itemInShoppingCart.quantity < 999) {
                    const newQuantity = itemInShoppingCart.quantity + 1;
                    props.onChangeQuantityOfShoppingCartItem(newQuantity, itemInShoppingCart.itemResponse.id, props.userInfo.id, props.match.params.branchMarketplaceId, props.match.params.marketplaceId);
                }
            } else {
                let setAlert = {...alertMessage}
                setAlert = {...setAlert, alertIndex: index}
                setAlertMessage(setAlert)
            }
        }
    }

    const decreaseQuantityOfShoppingCartItemHandler = (itemInShoppingCart) => {
        if (props.userInfo !== null) {
            const newQuantity = itemInShoppingCart.quantity - 1;
            if (newQuantity <= 0) {
                props.onRemoveItemFromShoppingCart(props.userInfo.id, props.match.params.branchMarketplaceId, props.match.params.marketplaceId, itemInShoppingCart)

            }
            else {
                props.onChangeQuantityOfShoppingCartItem(newQuantity, itemInShoppingCart.itemResponse.id, props.userInfo.id, props.match.params.branchMarketplaceId, props.match.params.marketplaceId);
            }
        }
    }

    const editQuantityOfShoppingCartItemHandler = (newQuantity, itemInShoppingCart, index) => {
        if (props.userInfo !== null) {
            if (newQuantity > itemInShoppingCart.stockQuantity) {
                newQuantity = itemInShoppingCart.stockQuantity;
                let setAlert = {...alertMessage}
                setAlert = {...setAlert, alertIndex: index}
                setAlertMessage(setAlert)
            }
            // if (newQuantity <= 0) {
            //     // props.onRemoveItemFromShoppingCart(props.userInfo.id, props.match.params.branchMarketplaceId, props.match.params.marketplaceId, itemInShoppingCart)
            // } else {
                props.onChangeQuantityOfShoppingCartItem(newQuantity, itemInShoppingCart.itemResponse.id, props.userInfo.id, props.match.params.branchMarketplaceId, props.match.params.marketplaceId)
            // }
        }
    }

    let shoppingCartItems = <Spinner/>;
    if (!props.loadingSelectedArticle) {
        if (props.shoppingCartFailed) {
            //TODO ovde prikaz ako se desila greska prilikom ucitavanja shopping cart-a
        }

        shoppingCartItems = props.shoppingCart !== null && props.shoppingCart.items.map((itemInShoppingCart, index) => {
            return (
                <div key={index} style={{marginRight:"1rem", marginLeft:"8px"}}>
                    <ShoppingCartListItem itemInShoppingCart={itemInShoppingCart}
                                          removeItemFromShoppingCart={() => removeFromShoppingCart(itemInShoppingCart)}
                                          increaseQuantityOfShoppingCartItem={() => increaseQuantityOfShoppingCartItemHandler(itemInShoppingCart, index)}
                                          decreaseQuantityOfShoppingCartItem={() => decreaseQuantityOfShoppingCartItemHandler(itemInShoppingCart)}
                                          editQuantityOfShoppingCartItem={(newQuantity) => editQuantityOfShoppingCartItemHandler(newQuantity, itemInShoppingCart, index)}/>
                    {alertMessage.alertIndex === index &&
                        <AlertComponent key={alertMessage.alertIndex} alerttype="warning">Dostigli ste maksimalan broj artikala.</AlertComponent>}
                </div>
            );
        });
    }

    return (
        <div className={styles.ShoppingCartList} style={props.style}>
            <div
                className={(!window.location.pathname.includes('confirmation') && !window.location.pathname.includes('shoppingCart')) ? styles.ShoppingCartListScrollbar : styles.ConfirmationScrollBar}>
                {(window.location.pathname.includes('confirmation') || window.location.pathname.includes('shoppingCart'))
                    ? <>
                        {shoppingCartItems}
                    </>
                    : <PerfectScrollbar>
                        {shoppingCartItems}
                    </PerfectScrollbar>
                }
            </div>
            <div className={window.location.pathname.includes('shoppingCart') ? styles.TotalShoppingCartPage :
                (!window.location.pathname.includes('confirmation') ? styles.TotalSideDrawer : styles.TotalConfirmation)}>
                <p>Ukupno: </p>
                <p><span>{(+(Math.round(props.totalPrice + "e+2") + "e-2")).toLocaleString("de-DE", {minimumFractionDigits:2, maximumFractionDigits:2})}</span> RSD</p>
            </div>

            {/*Zakomentarisano dok se ne dobije dizajn*/}
            {/*<div className={window.location.pathname.includes('shoppingCart') ? styles.TotalShoppingCartPage :*/}
            {/*    (!window.location.pathname.includes('confirmation') ? styles.TotalSideDrawer : styles.TotalConfirmation)}>*/}
            {/*    <p>Minuta: </p>*/}
            {/*    <p><span>{(+(Math.round(props.totalLoyalityPoints + "e+2") + "e-2")).toLocaleString("de-DE", {minimumFractionDigits:2, maximumFractionDigits:2})}</span> min</p>*/}
            {/*</div>*/}
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        shoppingCart: state.shopPage.shoppingCart,
        totalPrice: state.shopPage.totalShoppingCartPrice,
        totalLoyalityPoints: state.shopPage.totalShoppingCartLoyalityPoints,
        loadingShoppingCart: state.shopPage.loadingShoppingCart,
        shoppingCartFailed: state.shopPage.shoppingCartFailed,
        userInfo: state.authPage.userInfo
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onShowShoppingCartDrawer: () => dispatch(actions.showShoppingCartDrawerChanged()),
        onFetchShoppingCart: (userId, branchMarketplaceId, marketplaceId) => dispatch(actions.fetchShoppingCart(userId, branchMarketplaceId, marketplaceId)),
        onRemoveItemFromShoppingCart: (userId, branchMarketplaceId, marketplaceId, item) => dispatch(actions.removeItemFromShoppingCartAction(userId, branchMarketplaceId, marketplaceId, item)),
        onChangeQuantityOfShoppingCartItem: (newQuantity, itemId, userId, branchMarketplaceId, marketplaceId) => dispatch(actions.changeQuantityOfShoppingCartAction(newQuantity, itemId, userId, branchMarketplaceId, marketplaceId))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ShoppingCartList));
