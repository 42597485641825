import React from 'react'
// import {DocumentTextIcon} from "@heroicons/react/outline";
import Button from "../../UI/Buttons/Button/Button";
import ModalWithHeader from "../../UI/Modals/ModalWithHeader/ModalWithHeader";
import {ReactComponent as RpIcon} from "./../../../assets/icons/rp_icon.svg";

const RpModal = (props) => {
    return(
        <ModalWithHeader onCloseClick={props.onCloseClick}
                         // icon={<DocumentTextIcon style={{width: "48px", height: "48px"}}/>}
                         show={props.show}
                         backdropClickHandler={props.backdropClickHandler}
                         buttons={
                             <>
                                 <Button onClick={props.dontHaveRp}>Nemam recept</Button>
                                 <Button buttontype="blue" onClick={props.haveRp}>Imam recept</Button>
                             </>
                         }
        >
            <RpIcon style={{width:"52px", height:"52px"}}/>
            <br/><br/>
            <p style={{margin: "0", fontSize: "20px", fontWeight: "bold", textAlign: "center"}}>Rezervišeš lek koji se izdaje na recept!</p>
            <p style={{textAlign: "center", fontSize: "14px", lineHeight: "1.7"}}>
                U korpi se nalaze proizvodi koji se mogu izdati isključivo na recept.
                Potrebno je da potvrdiš da poseduješ validne recepte za izabrane proizvode.
            </p>
        </ModalWithHeader>
    );
}

export default RpModal;