import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import styles from "./RelatedArticles.module.css";
import Spinner from "../../UI/Spinner/Spinner";
import RelatedArticle from "./RelatedArticle/RelatedArticle";
import {CarouselProvider, Slider, Slide, ButtonBack, ButtonNext} from "pure-react-carousel";
import 'pure-react-carousel/dist/react-carousel.es.css';
import {ChevronDoubleLeftIcon, ChevronDoubleRightIcon} from "@heroicons/react/outline";

const RelatedArticles = (props) => {

    const [articles, setArticles] = useState(<Spinner/>);
    const [relatedArticlesCarousel, setRelatedArticlesCarousel] = useState(<Spinner />);

    useEffect(()=>{
       return()=>{
           setArticles(null);
           setRelatedArticlesCarousel(null);
       }
    },[])

    useEffect(() => {
            if (props.mainSubstance) {
                if (!props.loadingRelatedArticles) {
                    if (props.relatedArticles !== null) {
                        setArticles(props.relatedArticles.map((article) => {
                            return (
                                <Slide index={article.id} className={styles.slide}>
                                    <RelatedArticle key={article.id} article={article}/>
                                </Slide>
                            )
                        }));
                    }
                }
            }
            if (props.therapyIndication) {
                if (!props.loadingRelatedArticlesOnTerapijskaIndikacija && props.relatedArticlesOnTerapijskaIndikacija) {
                    setArticles(props.relatedArticlesOnTerapijskaIndikacija && props.relatedArticlesOnTerapijskaIndikacija.map((article) => {
                        return (
                            <Slide index={article.id} className={styles.slide}>
                                <RelatedArticle key={article.id} article={article}/>
                            </Slide>
                        )
                    }));
                }
            }
            if (props.sameCategory) {
                if (props.categoryRelatedArticles) {
                    setArticles(props.categoryRelatedArticles && props.categoryRelatedArticles.map((article) => {
                        return (
                            <Slide index={article.id} className={styles.slide}>
                                <RelatedArticle key={article.id} article={article}/>
                            </Slide>
                        )
                    }));
                }
            }

    }, [props.mainSubstance, props.therapyIndication, props.sameCategory])

    useEffect(()=>{
        if(articles.length < 4) {
            setRelatedArticlesCarousel(
                <CarouselProvider
                    naturalSlideWidth={100}
                    naturalSlideHeight={135}
                    totalSlides={articles.length}
                    visibleSlides={4}
                    lockOnWindowScroll={"true"}
                    orientation={"horizontal"}
                    dragEnabled={false}
                >
                    <div className={styles.CarouselContainer}>
                        <ButtonBack className={styles.ButtonLeft}>
                            <ChevronDoubleLeftIcon className={styles.Arrows}/>
                        </ButtonBack>

                        <Slider className={styles.Slider}>
                            {articles}
                        </Slider>

                        <ButtonNext className={styles.ButtonRight}>
                            <ChevronDoubleRightIcon className={styles.Arrows}/>
                        </ButtonNext>
                    </div>
                </CarouselProvider>
            )
        } else {
            setRelatedArticlesCarousel(
                <CarouselProvider
                    naturalSlideWidth={100}
                    naturalSlideHeight={135}
                    totalSlides={articles.length}
                    visibleSlides={4}
                    step={3}
                    interval={10000}
                    isPlaying={"true"}
                    dragEnabled={"true"}
                    lockOnWindowScroll={"true"}
                    orientation={"horizontal"}
                >
                    <div className={styles.CarouselContainer}>
                        <ButtonBack className={styles.ButtonLeft}>
                            <ChevronDoubleLeftIcon className={styles.Arrows}/>
                        </ButtonBack>

                        <Slider className={styles.Slider}>
                            {articles}
                        </Slider>

                        <ButtonNext className={styles.ButtonRight}>
                            <ChevronDoubleRightIcon className={styles.Arrows}/>
                        </ButtonNext>
                    </div>
                </CarouselProvider>
            )
        }

    }, [articles])

    return(
        relatedArticlesCarousel
    )
}

const mapStateToProps = (state) => {
    return {
        articleDetails: state.appPage.articleDetails,

        relatedArticles: state.articleInfoPage.relatedArticles,
        relatedArticlesError: state.articleInfoPage.relatedArticlesError,
        loadingRelatedArticles: state.articleInfoPage.loadingRelatedArticles,

        relatedArticlesOnTerapijskaIndikacija: state.articleInfoPage.relatedArticlesOnTerapijskaIndikacija,
        relatedArticlesOnTerapijskaIndikacijaError: state.articleInfoPage.relatedArticlesOnTerapijskaIndikacijaError,
        loadingRelatedArticlesOnTerapijskaIndikacija: state.articleInfoPage.loadingRelatedArticlesOnTerapijskaIndikacija,

        categoryRelatedArticles: state.articleInfoPage.categoryRelatedArticles,
        loadingCategoryRelatedArticles: state.articleInfoPage.loadingCategoryRelatedArticles,
        categoryRelatedArticlesError: state.articleInfoPage.categoryRelatedArticlesError
    }
}

export default connect(mapStateToProps)(RelatedArticles);