import React from 'react';
import ModalWithHeader from "../../UI/Modals/ModalWithHeader/ModalWithHeader";
import EditUserNameForm from "./EditUserNameForm/EditUserNameForm";
import EditPasswordForm from "./EditPasswordForm/EditPasswordForm";

const EditProfileModal = (props) => {

    return (
        <>
            <ModalWithHeader onCloseClick={props.closeEditModal}
                             style={{paddingBottom: "1rem"}}
                             icon={props.icon}
                             show={props.show}
                             backdropClickHandler={props.closeEditModal}
            >

                {props.editModalState.editName &&
                    <EditUserNameForm
                        closeEditModal={props.closeEditModal}
                        editModalState={props.editModalState}
                        editModalStateChanged={props.editModalStateChanged}
                        inputState={props.inputState}
                        inputStateChanged={props.inputStateChanged}
                    />

                }
                {props.editModalState.editPassword && !props.changePasswordSuccess &&
                    <EditPasswordForm
                        closeEditModal={props.closeEditModal}
                        editModalState={props.editModalState}
                        editModalStateChanged={props.editModalStateChanged}
                        inputState={props.inputState}
                        inputStateChanged={props.inputStateChanged}
                    />
                }
            </ModalWithHeader>
        </>
    );
}

export default EditProfileModal;