import React, {useEffect, useState} from 'react';
import ArticleCategory from "./ArticleCategory/ArticleCategory";
import styles from './ArticleCategories.module.css'
import Backdrop from "../../UI/Backdrop/Backdrop";
import * as actions from "../../../store/actions";
import {connect} from "react-redux";
import ArticleSubcategories2 from "./ArticleSubcategories2/ArticleSubcategories2";


const ArticleCategories = (props) => {

    const [categoryArray, categoryArraySetter] = useState(null);
    const [subCategoryArray, subCategoryArraySetter] = useState(null)
    const [subCategory2Array, subCategory2ArraySetter] = useState(null)

    const [colorFlag, colorFlagSetter] = useState(false)
    const [colorFlagSub1, colorFlagSub1Setter] = useState(false)
    const [colorFlagSub2, colorFlagSub2Setter] = useState(false)

    const [articleCategory, articleCategorySetter] = useState(null)
    const [articleSubcategory1, articleSubcategory1Setter] = useState(null)

    const [breadcrumbs, setBreadcrumbs] = useState({
        cat: null,
        catName: null,
        scat1: null,
        scat1Name: null,
        scat2: null,
        scat2Name: null
    })

    const selectFilter = (category) => {
        props.onShopFilterSelection({
            categoryId: category.id
        });
        props.onFilterBreadcrumbs({
            ...breadcrumbs
        })
        props.onFetchAdvCategoryImage(category.id);
        // localStorage.setItem('categoryFilter', category.id);
        props.backdropClickHandler();
    }

    useEffect(() => {
        if (props.articleCategoryDetails === null) {
            props.onFetchCategoriesForArticles();
        }
    }, [])

    //---------------- handlovanje kategorija ---------------
    useEffect(() => {
        // deselekcija submenija 1 i 2 prilikom promene kategorije u meniju sa kategorijama
        colorFlagSub1Setter(null);
        colorFlagSub2Setter(null);

        if (props.articleCategoryDetails !== null) {
            categoryArraySetter(props.articleCategoryDetails.map((articleCategory, index) => {

                return (
                    <>
                        <ArticleCategory key={index} articleCategory={articleCategory}
                                         onMouseOver={() => fetchSubcategories1(articleCategory, index)}
                                         flag={colorFlag === index}
                                         onClick={()=>{selectFilter(articleCategory)}}
                        />
                    </>
                );
            }))
        }

    }, [props.articleCategoryDetails, colorFlag])
    //--------------------------------------------------------


    // ---------------- handlovanje sub categories 1--------
    const fetchSubcategories1 = (articleCategory, index) => {
        colorFlagSetter(index);
        articleCategorySetter(articleCategory);
        setBreadcrumbs(
            {
                ...breadcrumbs,
                cat: articleCategory.id,
                catName: articleCategory.name,
            }
        )
    }

    useEffect(()=>{
        subCategoryArraySetter([]);
        subCategory2ArraySetter([]);

        articleCategory !== null && articleCategory.subcategories.map((subCat, index) => {

            // subCategory2IdArraySetter(subCategory2IdArraySetter => [...subCategory2IdArray, subCat.id])

            return (
                subCategoryArraySetter(subCategoryArray => [
                    ...subCategoryArray,
                    <ArticleCategory key={'subCat1'+index} articleCategory={subCat}
                                     onMouseOver={() => fetchSubcategories2(subCat, index)}
                                     flag={colorFlagSub1 === index}
                                     onClick={()=>{selectFilter(subCat)}}
                    />
                ])
            )
        });
    }, [articleCategory, colorFlagSub1])
    // ------------------------------------------------------

    // ---------------- handlovanje sub categories 2 --------------
    const fetchSubcategories2 = (articleSubcategory1, index) => {
        colorFlagSub1Setter(index);
        articleSubcategory1Setter(articleSubcategory1);
        setBreadcrumbs(
            {
                ...breadcrumbs,
                scat1: articleSubcategory1.id,
                scat1Name: articleSubcategory1.name,
                scat2: null,
                scat2Name: null
            }
        )
    }

    const fetchSubcategory2 = (subCat2, index) => {
        colorFlagSub2Setter(index);
        setBreadcrumbs(
            {
                ...breadcrumbs,
                scat2: subCat2.id,
                scat2Name: subCat2.name
            }
        )
    }

    useEffect(()=>{
        subCategory2ArraySetter([]);

        articleSubcategory1 !== null && articleSubcategory1.subcategories.map((subCat2, index) => {
            if(index <= 13) {
                return (
                    subCategory2ArraySetter(subCategory2Array => [
                        ...subCategory2Array,
                        <ArticleSubcategories2 key={'subCat2'+index}
                                               articleCategory={subCat2}
                                               onMouseOver={() => fetchSubcategory2(subCat2, index)}
                                               flag={colorFlagSub2 === index}
                                               onClick={()=>{selectFilter(subCat2)}}
                        />
                    ])
                )
            }

        });
    }, [articleSubcategory1, colorFlagSub2])

    //-----------------------------------------

    return (
        <div>
            <div className={styles.ArticleCategories}>
                {categoryArray}
            </div>

            {subCategoryArray !== null && subCategoryArray.length !== 0 &&
                <div className={styles.ArticleSubcategories1}>
                    {subCategoryArray}
                </div>
            }

            {subCategory2Array !== null && subCategory2Array.length !== 0 &&
                <div className={styles.ArticleSubcategories2}>
                    <div style={{position: "relative"}}>
                            {subCategory2Array}
                    </div>
                </div>
            }

            <Backdrop show={props.show}
                      backdropClickHandler={props.backdropClickHandler}
                      zIndex={30}
                      backgroundColor="rgba(0, 0, 0, .05)"/>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        articleCategoryDetails: state.filter.articleCategoryDetails,
        filterBreadcrumbs: state.filter.filterBreadcrumbs
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onFetchCategoriesForArticles: () => dispatch(actions.fetchCategoriesForArticles()),
        onShopFilterSelection: (shopFilter) => dispatch(actions.shopFilterSelection(shopFilter)),
        onFilterBreadcrumbs: (filterBreadcrumbs) => dispatch(actions.filterBreadcrumbs(filterBreadcrumbs)),
        onFetchAdvCategoryImage: (categoryId) => dispatch(actions.fetchAdvCategoryImage(categoryId))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ArticleCategories);