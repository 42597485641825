import React from 'react'
import styles from './MainPageCounter.module.css'
import {ArrowRightIcon} from "@heroicons/react/solid";

const MainPageCounter = (props) => {

    return (
        <>
            {/*ispituje ako ima upisan parametar 'clickable' onda primenjuje i drugu css klasu*/}
            <div className={[styles.CounterWrapper, props.clickable && styles.Clickable].join(" ")} onClick={props.onClick} style={props.style}>
                <div className={styles.CounterSubtitle}>
                    {props.counterSubtitle}
                </div>
                {props.children}
                <ArrowRightIcon className={styles.ArrowRight}  />
            </div>
        </>
    );

}

export default MainPageCounter;