import React, {Fragment, useEffect, useState} from "react";
import {withRouter} from 'react-router-dom'
import {connect} from "react-redux";
import * as actions from "../../../../store/actions";
import styles from "./ShopInfoBox.module.css";
import Spinner from "../../../UI/Spinner/Spinner";
import {
    ChevronRightIcon,
    ClockIcon, LocationMarkerIcon
} from "@heroicons/react/outline";
import SquareBadge from "../../../UI/SquareBadge/SquareBadge";
import {selectedStoreOpeningHours} from "../../../GobalFunctions/GlobalFunctions";
import InfoBoxTemplate from "../../../UI/InfoBoxTemplate/InfoBoxTemplate";
import Media from "react-media";
import HeaderWithTitleAndBackChevron
    from "../../../../componentsRes/HeaderWithTitleAndBackChevron/HeaderWithTitleAndBackChevron";
import TooltipComponent from "../../../UI/TooltipComponent/TooltipComponent";
import {isStoreOpenNow} from "../../../SearchResultsPage/SearchResults/StoreItems/StoreItem/StoreItem";
import {ReactComponent as ArrowLeftIcon} from "./../../../../assets/icons/arrow left.svg";
import {ReactComponent as PinIcon} from "./../../../../assets/icons/pin_icon.svg";
import {ReactComponent as PhoneIcon} from "./../../../../assets/icons/phone_icon.svg"
import {ReactComponent as DeliveryIcon} from "./../../../../assets/icons/delivery.svg"
import {StarIcon} from "@heroicons/react/solid";
import RFZOButton from "../../../UI/Buttons/RFZOButton/RFZOButton";
import RFZOTherapyModal from "../../../UI/Modals/RFZOTherapyModal/RFZOTherapyModal";

const ShopInfoBox = (props) => {

    const [isRFZOTherapyModalVisible, isRFZOTherapyModalVisibleHandler] = useState(false);


    // dohvatanje radnog vremena apoteke
    let storeWorkingHours = <Spinner/>
    if (props.selectedStore !== null) {
        storeWorkingHours = selectedStoreOpeningHours(props.selectedStore.marketplaceResponse.openingHoursResponse);
    }

    const backToSearchResultsPage = () => {
        if (props.match.params.searchedArticleId === undefined) {
            props.onFetchSelectedStore(null, null);
        }
        const url = localStorage.getItem('beforeEnteringStore');
        if (url !== null) {
            localStorage.removeItem('beforeEnteringStore');
            props.history.push(url);
        } else {
            props.history.goBack()
        }
        // props.history.goBack();
    }

    let phoneNumbers = <Spinner/>
    if (props.selectedStore !== null) {
        if (props.selectedStore.marketplaceResponse.phoneNumber !== null) {
            phoneNumbers = props.selectedStore.marketplaceResponse.phoneNumber.split(',');
            phoneNumbers = phoneNumbers.map((phoneNumber, index) => {
                return (
                        <a key={index} style={{marginRight: "10px"}} href={`tel:${phoneNumber}`}>{phoneNumber}</a>
                )
            })
        } else {
            phoneNumbers = null;
        }
    }

    const isOpen = props.selectedStore !== null && isStoreOpenNow(props.selectedStore.marketplaceResponse.openingHoursResponse);

    const storePageEnterHandler = () => {
        localStorage.setItem('beforeEnteringStorePage', window.location.pathname);
        let url = "/storeinfo/" + props.match.params.branchMarketplaceId + '/' + props.match.params.marketplaceId + '/' + props.match.params.branchMarketplaceName + '/' + props.match.params.marketplaceName;
        props.history.push(url);
    }

    const backToShop = () => {
        let url = localStorage.getItem('beforeEnteringStorePage')
        localStorage.removeItem('beforeEnteringStorePage')
        if (url !== '') {
            props.history.push(url)
        }
    }

    const openRFZOModal = () => {
        isRFZOTherapyModalVisibleHandler(true)
    }

    const closeRFZOModal = () => {
        isRFZOTherapyModalVisibleHandler(false);
    }

    const [pharmacyDetailsMobile, setPharmacyDetailsMobile] = useState(true)

    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (window.scrollY > 0) {
                setPharmacyDetailsMobile(false)
            } else {
                setPharmacyDetailsMobile(true)
            }
        })
    }, []);

    const pharmacyWorkingHours =
            <div className={styles.WorkingHours}>
                <ClockIcon style={{width: "1.5rem"}}/>
                {props.selectedStore !== null &&
                <p style={{paddingLeft: "8px", margin: "0 2px"}}>&nbsp;{storeWorkingHours}
                    {
                        isOpen !== null &&
                        !isOpen && <span style={{color: "#FC6D6D"}}>&nbsp;(Zatvoreno)</span>
                    }
                </p>
                }
            </div>

    const shopBadges =
        <div className={styles.ShopBadges}>
            <div className={styles.SqBox1}>
                <SquareBadge
                    size="32px"
                    backgroundColor={"#FFAE00"}
                    marginLeft={"6px"}
                >
                    <span style={{color: "white", fontSize:"18px"}}>%</span>
                </SquareBadge>
                <TooltipComponent className={styles.BadgeTooltipText}
                                  text="Apoteka trenutno ima popuste na određene proizvode."/>
            </div>
            {props.selectedStore !== null && props.selectedStore.marketplaceResponse.haveDelivery === 1 &&
            <div className={styles.SqBox3}>
                <SquareBadge
                    size="32px"
                    backgroundColor={"#00B1B0"}
                    marginLeft={"6px"}
                >
                    <DeliveryIcon style={{width: "1.375rem", stroke: "white"}}/>
                    {/*<span style={{color:"white", fontWeight:"bold"}}>P</span>*/}
                </SquareBadge>
                <TooltipComponent className={styles.BadgeTooltipText} text="Apoteka vrši isporuku"/>
            </div>
            }

            {/*<div className={styles.SqBox5} style={{marginLeft: "12px"}}>*/}
            {/*    <SquareBadge*/}
            {/*        backgroundColor={"#013a63ff"}*/}
            {/*        marginLeft={"6px"}*/}
            {/*    >*/}
            {/*        <span style={{color: "white", fontWeight: "bold", fontSize: "14px"}}><ShoppingCartIcon*/}
            {/*            style={{width: "22px", height: "22px", stroke: "white"}}/></span>*/}
            {/*    </SquareBadge>*/}
            {/*<TooltipComponent className={styles.BadgeTooltipText} text="Apoteka je u sistemu "Poruči i preuzmi"/>*/}
            {/*</div>*/}
        </div>

    const ratingBadge =
        <div className={styles.SqBox4}>
            <SquareBadge
                size="32px"
                backgroundColor={"#013A63"}
                marginLeft={"6px"}
            >
                {props.selectedStore &&
                    (props.selectedStore.marketplaceResponse.numOfRates < 10
                        ? <>
                            <StarIcon style={{width: "1.375rem", color: "white"}}/>
                            <TooltipComponent className={styles.BadgeTooltipText}
                                              text="Apoteka ima manje od 10 ocenjenih rezervacija"/>
                        </>
                        : <>
                                <span className={styles.RateBadgeText}>
                                    {props.selectedStore.marketplaceResponse.averageRate && props.selectedStore.marketplaceResponse.averageRate.toFixed(1)}
                                </span>
                                <TooltipComponent className={styles.BadgeTooltipText}
                                                  text="Prosečna ocena apoteke"/>
                        </>
                    )
                }
            </SquareBadge>
        </div>

    return (
        <>
        <Media
            queries={{
                small: "(max-width: 699px)",
                large: "(min-width: 700px)"
            }}>
            {matches => (
                <Fragment>

                    {matches.small &&
                    <div className={styles.ShopInfoHeaderMobile}>
                        <HeaderWithTitleAndBackChevron
                            icon
                            onIconClick={window.location.pathname.includes('storeinfo') && props.selectedStore !== null && props.selectedStore.marketplaceResponse.isClickAndCollect ? backToShop : backToSearchResultsPage}
                            title={
                                <div className={styles.ShopName}>
                                    {props.selectedStore !== null ? (window.location.pathname.includes('shop') ?
                                                <p onClick={storePageEnterHandler}>{props.selectedStore.marketplaceResponse.name}</p>
                                                :
                                                <p style={{
                                                    textDecoration: "",
                                                    cursor: "grab"
                                                }}>{props.selectedStore.marketplaceResponse.name}</p>
                                        ) :
                                        <Spinner/>
                                    }
                                </div>
                            }
                        />
                        {pharmacyDetailsMobile &&
                        <>
                            <div className={styles.DetailsAndBadgesMobile}>
                                {pharmacyWorkingHours}
                                <div style={{display: "flex"}}>
                                    {shopBadges}
                                    {ratingBadge}
                                </div>
                            </div>
                            {props.selectedStore && props.selectedStore.marketplaceResponse.haveRFZO === 1 &&
                                <div style={{margin:"12px auto"}}>
                                    <RFZOButton onClick={openRFZOModal} />
                                </div>
                            }

                            <div className={styles.PharmacyInfoHolderMobile}>
                                {props.selectedStore !== null ?
                                    <div style={{display: "flex"}}>
                                        <LocationMarkerIcon className={styles.MobileIcon}/>&nbsp;
                                        <p className={styles.ShopAddress}>{props.selectedStore.marketplaceResponse.address}</p>
                                    </div>
                                    :
                                    <Spinner/>
                                }
                                {props.selectedStore !== null ?
                                    <div style={{display: "flex", padding: "0.275rem 0"}}>
                                        <PhoneIcon className={styles.MobileIcon}/>&nbsp;
                                        <p className={styles.ShopPhoneNumber}>{phoneNumbers}</p>
                                    </div>
                                    :
                                    <Spinner/>
                                }
                                {!props.storePage &&
                                <div onClick={storePageEnterHandler} className={styles.StoreLinkMobile}> VIŠE O APOTECI <ChevronRightIcon width={16}
                                                                                                                                          height={16}/></div>
                                }

                            </div>
                        </>
                        }
                        {/*<ChevronLeftIcon  style={{width:"32px", position:"absolute", left:"0"}}*/}
                        {/*                  onClick={window.location.pathname.includes('storeinfo') && props.selectedStore!==null && props.selectedStore.marketplaceResponse.isClickAndCollect ? backToShop : backToSearchResultsPage}*/}
                        {/*                  />*/}
                        {/*<div className={styles.ShopName}>*/}
                        {/*    {props.selectedStore !== null ? (window.location.pathname.includes('shop') ?*/}
                        {/*                <p onClick={storePageEnterHandler}>{props.selectedStore.marketplaceResponse.name}</p>*/}
                        {/*                :*/}
                        {/*                <p style={{*/}
                        {/*                    textDecoration: "",*/}
                        {/*                    cursor: "grab"*/}
                        {/*                }}>{props.selectedStore.marketplaceResponse.name}</p>*/}
                        {/*        ) :*/}
                        {/*        <Spinner/>*/}
                        {/*    }*/}
                        {/*</div>*/}
                    </div>
                    }

                    {matches.large &&
                    <InfoBoxTemplate style={{padding: "2rem 2rem"}}>
                        <div>
                            <div className={styles.ShopInfoBoxHeader}>
                                <div className={styles.FirstRow}>
                                    <div className={styles.ShopName}>
                                        <div className={styles.ShopExitIcon}
                                             onClick={window.location.pathname.includes('storeinfo') && props.selectedStore !== null && props.selectedStore.marketplaceResponse.isClickAndCollect ? backToShop : backToSearchResultsPage}>
                                            <ArrowLeftIcon style={{width: "1.7rem", color: "#4a4f54ff", marginRight: "24px"}}/>
                                            <TooltipComponent className={styles.BadgeTooltipText}
                                                              style={{marginLeft: "-7px"}}
                                                              text={props.onStorePage ? 'Povratak na prethonu stranu' : 'Izlaz iz prodavnice'}/>
                                        </div>
                                        {ratingBadge}
                                        &nbsp;
                                        &nbsp;
                                        {props.selectedStore !== null ? (window.location.pathname.includes('shop') ?
                                                    <p onClick={storePageEnterHandler}>{props.selectedStore.marketplaceResponse.name}</p>
                                                    :
                                                    <p style={{
                                                        textDecoration: "",
                                                        cursor: "grab"
                                                    }}>{props.selectedStore.marketplaceResponse.name}</p>
                                            ) :
                                            <Spinner/>
                                        }
                                    </div>
                                    {shopBadges}
                                </div>
                            </div>
                            <div style={{display:"flex", flexDirection:"column", paddingTop:"2rem", paddingLeft:"3rem", fontSize:"14px"}}>
                                {props.selectedStore !== null ?
                                    <div className={styles.InfoWithIconWrapper}>
                                        <PinIcon style={{stroke:"#4a4f54ff"}}/>
                                        <p className={styles.ShopAddress} style={{paddingLeft:"1rem"}}>{props.selectedStore.marketplaceResponse.address}</p>
                                    </div>:
                                    <Spinner/>
                                }

                                {props.selectedStore !== null ?
                                    <div className={styles.InfoWithIconWrapper}>
                                        <PhoneIcon />
                                        <p className={styles.ShopPhoneNumber} style={{paddingLeft:"1rem"}}>{phoneNumbers}</p>
                                    </div>
                                        :
                                    <Spinner/>
                                }
                                {pharmacyWorkingHours}
                                {props.selectedStore && props.selectedStore.marketplaceResponse.haveRFZO === 1 &&
                                    <div style={{position: "absolute", bottom: "32px", right: "32px"}}>
                                        <RFZOButton onClick={openRFZOModal}/>
                                    </div>
                                }
                            </div>
                        </div>
                    </InfoBoxTemplate>
                    }
                </Fragment>
            )}
        </Media>
            {isRFZOTherapyModalVisible &&
                <RFZOTherapyModal
                    show={isRFZOTherapyModalVisible}
                    onCloseClick={closeRFZOModal}
                    backdropClickHandler={closeRFZOModal}
                />
            }
            </>

    );
}

const mapStateToProps = (state) => {
    return {
        storeDetails: state.appPage.storeDetails,
        loadingStoreDetails: state.appPage.loadingStoreDetails,
        selectedStore: state.appPage.selectedStore,
        stores: state.appPage.stores,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onSelectedStoreChanged: (selectedStore) => dispatch(actions.selectedStoreChanged(selectedStore)),
        onFetchSelectedStore: (branchMarketplaceId, marketplaceId) => dispatch(actions.fetchSelectedStore(branchMarketplaceId, marketplaceId)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ShopInfoBox));

