import React, {Fragment, useEffect} from "react";
import Toolbar from "../Navigation/Toolbar/Toolbar";
import StorePageHeader from "./StorePageHeader/StorePageHeader";
import {connect} from "react-redux";
import * as actions from "../../store/actions";
import StorePageActionMenu from "./StorePageActionMenu/StorePageActionMenu";
import styles from './StorePage.module.css';
import InformationBox from "./InformationBox/InformationBox";
import InformationBoxRight from "./InformationBoxRight/InformationBoxRight";
import Wrapper from "../UI/Wrapper/Wrapper";
import Media from "react-media";
import StorePageMobileContent from "./StorePageMobileContent/StorePageMobileContent";

const StorePage = (props) => {

    useEffect(()=>{
        return()=>{
            props.onSelectedStoreChanged(null);
        }
    }, [])

    useEffect(() => {
        if (props.selectedStore === null) {
            props.onFetchSelectedStore(props.match.params.marketplaceId, props.match.params.branchMarketplaceId)
        }
    }, [props.match.params.branchMarketplaceId, props.match.params.marketplaceId])

    console.log(props.selectedStore);

    return (
        <>
            <Wrapper>
                <Toolbar/>
                <StorePageHeader/>
                <StorePageActionMenu/>

                <Media
                    queries={{
                        small: "(max-width: 699px)",
                        large: "(min-width: 700px)"
                    }}>
                    {matches => (
                        <Fragment>
                            {matches.small &&
                                <StorePageMobileContent/>
                            }

                            {matches.large &&
                            <div className={styles.PagePart}>
                                <div className={styles.LeftSide}>
                                    <InformationBox selectedStore={props.selectedStore}/>
                                </div>
                                <div className={styles.RightSide}>
                                    {/*<div>Ovde ide siri opis apoteke, za sada kreirati reusable komponentu jer ce se koristi za vise stvari osim za taj opis</div>*/}
                                    <InformationBoxRight selectedStore={props.selectedStore}/>
                                </div>
                            </div>
                            }

                        </Fragment>)}
                </Media>
            </Wrapper>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        selectedStore: state.appPage.selectedStore
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onFetchSelectedStore: (marketplaceId, branchMarketplaceId) => dispatch(actions.fetchSelectedStore(marketplaceId, branchMarketplaceId)),
        onSelectedStoreChanged: (selectedStore) => dispatch(actions.selectedStoreChanged(selectedStore)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(StorePage);