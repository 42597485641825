import * as actionsTypes from '../actions/actionsTypes'

const initialState = {
    toolbarChanged: false,

    articles: null,
    articlesNames: null,
    articlesError: false,
    loadingArticles: false,

    searchBarPlaceholder: '',
    searchString: '',
    selectedIndex: 0,
    isSelected: false,
    numberOfMatchedArticles: 0,
    matchedItems: null,
    matchedStores: null,

    apotekaRsMarketplaces: null,
    clickAndCollectMarketplaces: null,
    numOfArticles: null,

    storesNames: null,
    storesNamesError: false,
    loadingStoresNames: false,

    advertisement: null,
    advertisementError: false,
    loadingAdvertisement: false,

    rating: null,
    ratingError: false,
    loadingRating: false
}

const mainPageReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionsTypes.TOOLBAR_CHANGED:
            return {
                ...state,
               toolbarChanged: action.changed
            }
        case actionsTypes.ALL_ARTICLES:
            return {
                ...state,
                articles: action.articles,
                articlesNames: action.articles.map((articleName) => articleName.name),
                articlesError: false,
                loadingArticles: false
            }
        case actionsTypes.FETCH_ALL_ARTICLES_FAIlED:
            return {
                ...state,
                articles: null,
                articlesNames: null,
                articlesError: true,
                loadingArticles: false
            }
        case actionsTypes.FETCH_ALL_ARTICLES_START:
            return {
                ...state,
                articles: null,
                articlesNames: null,
                articlesError: false,
                loadingArticles: true
            }
        case actionsTypes.MATCHED_ITEMS_CHANGED:
            return {
                ...state,
                matchedItems: action.matchedItems
            }
        case actionsTypes.MATCHED_STORES_CHANGED:
            return {
                ...state,
                matchedStores: action.matchedStores
            }
        case actionsTypes.SEARCH_STRING_CHANGED:
            return {
                ...state,
                searchString: action.searchString
            }
        case actionsTypes.SEARCH_BAR_PLACEHOLDER_CHANGED:
            return {
                ...state,
                searchBarPlaceholder: action.searchBarPlaceholder
            }
        case actionsTypes.SELECTED_INDEX_CHANGED:
            return {
                ...state,
                selectedIndex: action.selectedIndex
            }
        case actionsTypes.SELECT_DETECTED:
            return {
                ...state,
                isSelected: action.isSelected
            }
        case actionsTypes.NUMBER_OF_MATCHED_ARTICLES_CHANGED:
            return {
                ...state,
                numberOfMatchedArticles: action.numberOfMatchedArticles
            }
        case actionsTypes.COUNT_MARKETPLACES:
            return {
                ...state,
                apotekaRsMarketplaces: action.countMarketplaces.apotekaRsMarketplaces,
                clickAndCollectMarketplaces: action.countMarketplaces.clickAndCollectMarketplaces,
                numOfArticles: action.countMarketplaces.numOfArticles
            }
        case actionsTypes.ALL_STORES_NAMES:
            return {
                ...state,
                storesNames: action.storesNames,
                storesNamesError: false,
                loadingStoresNames: false
            }
        case actionsTypes.FETCH_ALL_STORES_NAMES_FAIlED:
            return {
                ...state,
                storesNames: null,
                storesNamesError: true,
                loadingStoresNames: false
            }
        case actionsTypes.FETCH_ALL_STORES_NAMES_START:
            return {
                ...state,
                storesNames: null,
                storesNamesError: false,
                loadingStoresNames: true
            }
        case actionsTypes.FETCH_RANDOM_ADVERTISEMENT:
            return {
                ...state,
                advertisement: action.advertisement,
                advertisementError: false,
                loadingAdvertisement: false
            }
        case actionsTypes.FETCH_RANDOM_ADVERTISEMENT_FAIlED:
            return {
                ...state,
                advertisement: null,
                advertisementError: true,
                loadingAdvertisement: false
            }
        case actionsTypes.FETCH_RANDOM_ADVERTISEMENT_START:
            return {
                ...state,
                advertisement: null,
                advertisementError: false,
                loadingAdvertisement: true
            }

        //rating
        case actionsTypes.FETCH_RATING:
            return {
                ...state,
                rating: action.rating === ""? null : action.rating,
                ratingError: false,
                loadingRating: false
            }
        case actionsTypes.FETCH_RATING_FAIlED:
            return {
                ...state,
                rating: null,
                ratingError: true,
                loadingRating: false
            }
        case actionsTypes.FETCH_RATING_START:
            return {
                ...state,
                rating: null,
                ratingError: false,
                loadingRating: true
            }
        default:
            return state;
    }
}

export default mainPageReducer;
