import React from "react";
import {Link} from "react-router-dom";

const MailTo = (props) => {
    return (
        <Link className={props.className}
           onClick={(e) => {
               window.location = "mailto:support@apoteka.rs";
               e.preventDefault();
           }}>{props.label}</Link>
    );
}

export default MailTo;