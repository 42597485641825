import React, {useContext, useEffect, useState} from 'react';
import styles from '../SearchItems/SearchItems.module.css'
import * as actions from "../../../../store/actions";
import {connect} from 'react-redux';
import {withRouter} from "react-router-dom";
import NoMatchSearchItem from "../SearchItems/NoMatchSearchItem/NoMatchSearchItem";
import Spinner from "../../../UI/Spinner/Spinner"
import configData from "../../../../configConstants.json";
import {redirectToError} from "../../../../containers/App";
import SearchedStringSearchItem from "../SearchItems/SearchedStringSearchItem/SearchedStringSearchItem";
import SearchStore from "./SearchStore/SearchStore"
import {FuzzySortContext} from "../../../../store/context/FuzzySort/FuzzySort";

const SearchStores = React.memo(props => {

    const [matchedStores, matchedStoresChanged] = useState(<Spinner/>);
    const fuzzySort = useContext(FuzzySortContext)

    useEffect(() => {

        let index = 0;
        const searchStringWords = fuzzySort.separateSearchStringByWords(props.searchString);

        //filtrira u zavisnosti od unesenog stringa
        const filterStores = () => {

            let matchingResult = fuzzySort.filterByWords(props.storesNames, searchStringWords, 0);
            matchingResult = fuzzySort.sortResultsByStartWord(matchingResult, searchStringWords[0]);
            props.onMatchedStoresChanged(matchingResult);
            const firstItem = (<SearchedStringSearchItem key={0} index={index++} onMainPage={props.onMainPage} searchArticles={false}/>)
            matchedStoresChanged([firstItem , ...matchingResult.map(result => (<SearchStore key={result.id} store={result} index={index++} onMainPage={props.onMainPage}/>))]);
            if (index === 1 && !props.storesNamesError && !props.loadingStoresNames) {

                matchedStoresChanged([(<NoMatchSearchItem
                    message={configData.SearchItems.NotFindStoreText + props.searchString}
                    onMainPage={props.onMainPage}
                    key={index}/>)]);
            }
            props.onNumberOfMatchedArticlesChanged(index-1);
            props.onSelectedIndexChanged(0);
        }

        matchedStoresChanged(<Spinner/>);
        if (!props.loadingStoresNames){
            if (props.storesNamesError) {
                redirectToError(props);
                matchedStoresChanged(<p>Desila se greška prilikom učitavanja proizvoda!</p>)
            }
            else {
                filterStores();
            }
        }

    }, [props.searchString, props.storesNames])

    let searchStoresStyle;
    if (props.onMainPage) {
        searchStoresStyle = [styles.SearchItems, styles.SearchItemsMainPage].join(' ');
    } else {
        searchStoresStyle = [styles.SearchItems, styles.SearchItemsActionMenu].join(' ');
    }


    return (
        // <div className={props.onMainPage ? styles.SearchItemsMainPage : [styles.SearchItemsMainPage, styles.SearchItemsToolbar].join(' ') }>
            <div className={searchStoresStyle}>
                <div className={styles.Bottom} >
                    {props.searchString !=='' ? matchedStores: null}
                </div>
            </div>

        // </div>
    );
})

const mapStateToProps = (state) => {
    return {
        storesNames: state.mainPage.storesNames,
        storesNamesError: state.mainPage.storesNamesError,
        loadingStoresNames: state.mainPage.loadingStoresNames,

        searchString: state.mainPage.searchString,
        numberOfMatchedArticles: state.mainPage.numberOfMatchedArticles
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onNumberOfMatchedArticlesChanged: (numberOfMatchedArticles) => dispatch(actions.numberOfMatchedArticlesChanged(numberOfMatchedArticles)),
        onSelectedIndexChanged: (index) => dispatch(actions.selectedIndexChanged(index)),
        onMatchedStoresChanged: (matchedStores) => dispatch(actions.matchedStoresChanged(matchedStores))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SearchStores));
