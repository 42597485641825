import React, {useContext, useEffect, useState} from 'react';
import Backdrop from "../../../../../UI/Backdrop/Backdrop";
import {FuzzySortContext} from "../../../../../../store/context/FuzzySort/FuzzySort";
import styles from './SearchBarForTherapy.module.css';
import * as actions from "../../../../../../store/actions";
import {connect} from "react-redux";
import SearchItemsForTherapy from "../SearchItemsForTherapy/SearchItemsForTherapy";
import AddArticleToTherapyModal from "../../../../../UI/Modals/AddArticleToTherapyModal/AddArticleToTherapyModal";

const SearchBarForTherapy = (props) => {

    useEffect(() => {
        if (props.articles === null){
            props.onInitArticles();
        }
    }, [])

    const [searchStr, setSearchStr] = useState("");
    const [searchRes, setSearchRes] = useState(null);
    const [showSearchResults, setShowSearchResults] = useState(false);
    // const selectedItem = useSelector(state=> state.therapy.selectedItem)
    // const showModal = useSelector( state => state.therapy.showModal)

    const fuzzySort = useContext(FuzzySortContext);


    const handleChange = e => {
        setShowSearchResults(true)
        setSearchStr(e.target.value);
        // setSearchRes(e.target.value.length > 2 ? filterItems() : []);
        // if(e.target.value === '') {
        //     setShowSearchResults(false);
        // }
    };

    useEffect(() => {
        // setSearchStr(e.target.value);
        if(props.articles) {
            setSearchRes(searchStr.length > 2 ? filterItems() : []);
        }
        if(searchStr === '') {
            setShowSearchResults(false);
        }
    }, [searchStr])

    const resetSearch = () => {
        setShowSearchResults(false);
        setSearchStr("");
        setSearchRes(null)
    }

    const filterItems = () => {

        const searchStringWords = searchStr.split(' ').filter(string => {
            if (string.trim() !== '') {
                return string.trim();
            }
        });

        let results = fuzzySort.filterByWords(props.articles, searchStringWords, 0);
        results = fuzzySort.sortResultsByStartWord(results, searchStringWords[0]);
        return (results);
    }

    const backdropClickHandler = () => {
        setShowSearchResults(false);
        setSearchStr("");
    }

    const closeAddArticleToTherapyModalHandler = () => {
        props.onToggleTherapyArticleModal(false)
    }

    return (
        <div style={{position:"relative", zIndex:"1"}}>
            <input
                style={{position:"relative", borderRadius:"6px"}}
                placeholder={"Pretražite artikle"}
                type="text"
                name="search-box"
                value={searchStr}
                onChange={event => handleChange(event)}
                autoComplete="off"
                className={styles.TherapySearchBar}
            />
            {showSearchResults &&
                <>
                    <SearchItemsForTherapy className={styles.SearchItems}
                                 style={!showSearchResults || searchRes === null || searchRes === " "
                                     ? {display: "none"}
                                     : {zIndex: 1, backgroundColor: "white"}}
                                 results={searchRes}
                                 close={resetSearch}/>
                    <Backdrop zIndex="-1" show={showSearchResults} backdropClickHandler={backdropClickHandler} backgroundColor={"transparent"}/>
                </>
            }

            {props.toggleTherapyArticleModal
                &&
                <AddArticleToTherapyModal
                    show={props.toggleTherapyArticleModal}
                    item={props.selectedItem}
                    onCloseClick={closeAddArticleToTherapyModalHandler}
                    backdropClickHandler={closeAddArticleToTherapyModalHandler}
                />
            }
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        articles: state.mainPage.articles,
        error: state.mainPage.articlesError,
        loadingArticles: state.mainPage.loadingArticles,
        selectedItem: state.userProfile.selectedItem,
        toggleTherapyArticleModal: state.userProfile.toggleTherapyArticleModal
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onInitArticles: () => dispatch(actions.initArticles()),
        onToggleTherapyArticleModal: (value) => dispatch(actions.toggleTherapyArticleModal(value))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchBarForTherapy);
