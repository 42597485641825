import styles from "../Footer/Footer.module.css";
import React, {Fragment} from "react";
import {withRouter} from "react-router-dom";
import Media from 'react-media';
import Logo from "../Logo/Logo";
import Wrapper from "../UI/Wrapper/Wrapper";
import { Link } from 'react-router-dom';
import {ReactComponent as InstagramIcon} from "../../assets/icons/sm_icons/instagram.svg"
import {ReactComponent as FacebookIcon} from "../../assets/icons/sm_icons/facebook.svg"
import {ReactComponent as TwitterIcon} from "../../assets/icons/sm_icons/twitter.svg"
import {ReactComponent as LinkedinIcon} from "../../assets/icons/sm_icons/linkedin.svg"
import MailTo from "../Global/MailTo/MailTo";
import AppButton from "../UI/Buttons/AppButton/AppButton";
import {connect} from "react-redux";

const Footer = (props) => {
    return (
        <Media
            queries={{
                small: "(max-width: 699px)",
                large: "(min-width: 700px)"
            }}>
            {matches => (
                <Fragment>
                    {matches.small &&
                    <footer className={styles.FooterMobile}>
                        <div>
                            Copyright © 2022 by Itam <span>&#183; </span>
                            Sva prava zadržana <span>&#183;</span>
                            <Link to="/PrivacyPolicy" style={{paddingRight:"16px"}}>Izjava o privatnosti</Link>
                            <Link to="/TermsOfService">Opšti uslovi korišćenja</Link>
                        </div>
                    </footer>}

                    {matches.large &&
                    <footer className={styles.Footer}>
                        <Wrapper>
                            <div className={styles.Footer_Top}>
                                <div className={styles.Footer_LeftBox}>
                                    <div style={{paddingBottom:"2rem"}}>
                                        <Logo/>
                                    </div>

                                    <div style={{display:"flex", flexDirection:"column", paddingBottom:"0.4rem", paddingLeft:"0.5rem", textAlign:"left"}}>
                                        <p style={{fontSize:"20px", fontWeight:"bold"}}>Extra Care Group d.o.o.</p>
                                        <p>Dobanovačka 2, 11080 Zemun</p>
                                        <p>Beograd</p>
                                        <p>Srbija</p>
                                    </div>
                                </div>

                                <div className={styles.Footer_RightBox}>
                                    <div>
                                        <div className={styles.Footer_RightBox_Links}>
                                            <p className={styles.FooterSectionTitle}>Korisnički servis</p>
                                            {/*todo dodati uslov, ako je korisnik ulogovan da mu se prikazuju ovi linkovi, ako nije, onda nista*/}
                                            {props.isLogedin &&
                                                <>
                                                    <Link to="/myprofile">Moj nalog</Link>
                                                    <Link to="/myprofile/my-orders">Moje porudžbine</Link>
                                                </>
                                            }
                                            <MailTo label="Podrška"/>
                                        </div>
                                    </div>

                                    <div>
                                        <div className={styles.Footer_RightBox_Links}>
                                            <p className={styles.FooterSectionTitle}>Apoteka linkovi</p>
                                            <Link to="/AboutUs">O nama</Link>
                                            <Link to="/Marketing">Marketing</Link>
                                            <Link to="/BecomeAPartner">Postani partner</Link>
                                            <Link to="/misljenje-ministarstva-zdravlja">Mišljenje Ministarstva zdravlja</Link>
                                        </div>
                                    </div>

                                    <div>
                                        <div className={styles.Footer_RightBox_Links}>
                                            <p className={styles.FooterSectionTitle}>Aplikacije</p>
                                            <AppButton style={{width:"120px"}}/>
                                            <AppButton apple style={{width:"120px"}}/>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={styles.Footer_Bottom}>
                                {/*<div>*/}
                                Copyright © 2022 apoteka
                                {/*</div>*/}
                                <div style={{display:"flex"}}>
                                    <Link to="/PrivacyPolicy" style={{paddingRight:"16px"}}>Izjava o privatnosti</Link>
                                    <Link to="/TermsOfService">Opšti uslovi korišćenja</Link>
                                </div>
                                <div style={{padding:"0 1rem"}}>
                                    <a href="https://www.facebook.com/Apoteka-RS-108349861737873" target="_blank"><FacebookIcon className={styles.Footer_Bottom_SocialNetworks} /></a>
                                    <a href="https://www.instagram.com/uvekdezurna/" target="_blank"><InstagramIcon className={styles.Footer_Bottom_SocialNetworks} /></a>
                                    <a href="https://www.linkedin.com/company/78390085/admin/" target="_blank"><LinkedinIcon className={styles.Footer_Bottom_SocialNetworks} /></a>
                                    <a href="https://twitter.com/apoteka_rs" target="_blank"><TwitterIcon className={styles.Footer_Bottom_SocialNetworks} /></a>
                                </div>
                            </div>
                        </Wrapper>
                    </footer>}
                </Fragment>
            )}
        </Media>
    )
}


const mapStateToProps = () => {
    return {
        isLogedin: localStorage.getItem("token") !== null,
    }
}

export default connect(mapStateToProps)(withRouter(Footer));