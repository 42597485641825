import React from 'react';
import styles from './NavigationItem.module.css'
import {connect} from "react-redux";
import { Link } from "react-router-dom";

const NavigationItem = (props) => (
    <li className={ props.toolbarChanged ? [styles.NavigationItem, styles.NavigationItemDark].join(' ') : styles.NavigationItem}>
        <Link
            className={props.disabled ? styles.Disabled : null}
            to={props.link}
            style={{fontSize: props.fontSize, marginRight: props.marginRight}}
            onClick={props.onClick}
        >
            {props.children}
            {props.itemsInCartCounter && <span className={styles.ItemsInCartCounter} >{props.itemsInCartCounter}</span>}
        </Link>
    </li>
);

const mapStateToProps = (state) => {
    return {
        toolbarChanged: state.mainPage.toolbarChanged
    }
}

export default connect(mapStateToProps)(NavigationItem);
