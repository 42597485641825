import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './containers/App';
import * as serviceWorker from './serviceWorker';
import OrderSocketProvider from "./store/context/OrderSocket/OrderSocket";
import RFZOTherapySocketProvider from "./store/context/RFZOTherapySocket/RFZOTherapySocket";
import FuzzySortProvider from "./store/context/FuzzySort/FuzzySort"

import {BrowserRouter} from "react-router-dom";
import {Provider} from 'react-redux';
import {createStore, applyMiddleware, compose, combineReducers} from 'redux';
import thunk from 'redux-thunk';

import mainPageReducer from './store/reducers/mainPageReducer';
import appPageReducer from './store/reducers/appPage_Reducer';
import articleInfoPageReducer from './store/reducers/articleInfoPageReducer';
import cmsPageReducer from "./store/reducers/cmsPageReducer";
import authPageReducer from "./store/reducers/authPageReducer";
import shopPageReducer from "./store/reducers/shopPageReducer";
import toolbarReducer from "./store/reducers/toolbarReducer";
import filterReducer from './store/reducers/filterReducer';
import userProfileReducer from './store/reducers/userProfileReducer';
import modalReducer from './store/reducers/modalReducer';
import orderCompletionReducer from "./store/reducers/orderCompletionReducer";

import './fonts/Montserrat-Regular.ttf';
import storesSearchReducer from "./store/reducers/storesSearchReducer";
import articlesSearchReducer from "./store/reducers/articlesSearchReducer";
import orderAndTransactionsReducer from "./store/reducers/orderAndTransactionsReducer";

import actionMenuReducer from "./store/reducers/actionMenuReducer";
import rfzoTherapyReducer from "./store/reducers/rfzoTherapyReducer";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
    mainPage: mainPageReducer,
    appPage: appPageReducer,
    articleInfoPage: articleInfoPageReducer,
    cmsPage: cmsPageReducer,
    authPage: authPageReducer,
    shopPage: shopPageReducer,
    toolbar: toolbarReducer,
    filter: filterReducer,
    userProfile: userProfileReducer,
    modal: modalReducer,
    storesSearchPage: storesSearchReducer,
    articlesSearchPage: articlesSearchReducer,
    orderCompletion: orderCompletionReducer,
    ordersAndTransactions: orderAndTransactionsReducer,
    actionMenu: actionMenuReducer,
    rfzoTherapy: rfzoTherapyReducer
})

const store = createStore(rootReducer, composeEnhancers(
    applyMiddleware(thunk)
));


const app = (
    <Provider store={store}>

            <BrowserRouter>
                <React.StrictMode>
                    <OrderSocketProvider>
                        <FuzzySortProvider>
                            <RFZOTherapySocketProvider>
                                <App/>
                            </RFZOTherapySocketProvider>
                        </FuzzySortProvider>
                    </OrderSocketProvider>
                </React.StrictMode>
            </BrowserRouter>

    </Provider>

);

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
