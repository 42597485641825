import React, {Fragment, useEffect, useState} from 'react';
import styles from './ArticleInfoLek.module.css'
import {connect} from "react-redux";
import * as actions from "../../../store/actions";
import Spinner from "../../UI/Spinner/Spinner";
import {ChevronDownIcon, ChevronRightIcon, ChevronUpIcon} from "@heroicons/react/outline";
import Media from "react-media";
import SlideDown from "react-slidedown";

const ArticleInfoPage = (props) => {

    const [selectedCategoryInfo, selectedCategoryInfoHandler] = useState(0);
    const [activeTitle, setActiveTitle] = useState(0);
    const [contentExpanded, contentExpandedHandler] = useState(false);
    const [paragraphsTitleArray, paragraphsTitleArraySetter] = useState();
    const [articleName, articleNameSetter] = useState(<Spinner/>);
    const [articleInfos, articleInfosSetter] = useState(<Spinner/>)

    const [informationCategory, informationCategorySetter] = useState([])

    useEffect(() => {
        if (props.articleInfo !== null)
            articleNameSetter (<p>{props.articleInfo.registrationName}</p>)
        else if (props.articleDetails !== null)
            articleNameSetter(<p>{props.articleDetails.name}</p>)
    }, [props.articleInfo, props.articleDetails])

    useEffect(() => {
        if (props.articleInfo !== null) {
            informationCategorySetter( [props.articleInfo.indications,
                props.articleInfo.dosing,
                props.articleInfo.contraindications,
                props.articleInfo.warnings,
                props.articleInfo.interactions,
                props.articleInfo.pragnancy,
                props.articleInfo.driving,
                props.articleInfo.undesirable,
                props.articleInfo.overdose,
                props.articleInfo.pharmacodynamic,
                props.articleInfo.pharmacokinetic]);
        } else if (props.articleInfo === null && props.articleDetails !== null && props.articleDetails.itemInfoResponse !== null) {
            informationCategorySetter([
                props.articleDetails.itemInfoResponse.description,
                props.articleDetails.itemInfoResponse.effect,
                props.articleDetails.itemInfoResponse.note,
                props.articleDetails.itemInfoResponse.howToUse,
                props.articleDetails.itemInfoResponse.containedComponents,
                props.articleDetails.manufacturerName
            ])
        }
    }, [props.articleDetails, props.articleInfo])

    useEffect(() => {
        if (props.articleInfo !== null) {
            paragraphsTitleArraySetter (
                ['Terapijske indikacije', 'Doziranje i način primene', 'Kontraindikacije', 'Posebna upozorenja interakcije',
                    'Trudnoća i dojenje', 'Upravljanje vozilom', 'Neželjena dejstva', 'Predoziranje', 'Farmakodinamika', 'Farmakokinetika']);
        } else if (props.articleInfo === null && props.articleDetails !== null) {
            paragraphsTitleArraySetter(
                // [props.articleDetails.manufacturerName, props.articleDetails.name, props.articleDetails.itemInfoResponse.description, props.articleDetails.itemInfoResponse.effect, props.articleDetails.itemInfoResponse.note, props.articleDetails.itemInfoResponse.howToUse, props.articleDetails.itemInfoResponse.containedComponents] =
                    ['Opis/namena', 'Delovanje', 'Napomena', 'Način upotrebe', 'Sastav', 'Farmaceutski oblik', 'Unutrašnje pakovanje', "Proizvođač"]);
        }
    }, [props.articleInfo, props.articleDetails])

    useEffect(() => {
        if (!props.loadingArticleInfo && paragraphsTitleArray) {
            if (props.articleInfo !== null || props.articleDetails !== null) {
                // ako stoji props.articleInfo !== null, ne prikazuje se prazna tabela ako nema informacija
                // articleInfos = props.articleInfo !== null && paragraphsTitleArray.map((title, index) => {
                articleInfosSetter(paragraphsTitleArray.map((title, index) => {

                        return (
                            <Media key={index}
                                queries={{
                                    small: "(max-width: 699px)",
                                    large: "(min-width: 700px)"
                                }}>
                                {matches => (
                                    <Fragment>
                                        {matches.small &&
                                            <>
                                                <SlideDown>
                                                    {+activeTitle === index && contentExpanded ?

                                                        <>
                                                            <div key={index} className={styles.ParagraphTitleWrapper}
                                                                 onClick={() => showSelectedCategoryInfo(index)}>
                                                                <div
                                                                    className={styles.ParagraphTitleActive}>{title}</div>
                                                                <ChevronUpIcon
                                                                    style={{width: "2rem", stroke: "#88959E"}}/>
                                                            </div>
                                                            {selectedCategoryInfo ?
                                                                <div
                                                                    dangerouslySetInnerHTML={{__html: selectedCategoryInfo}}
                                                                    style={{padding: "1rem"}}></div>
                                                                : <p style={{paddingLeft:"8px"}}>Trenutno nema informacija</p>}
                                                        </>
                                                        :
                                                        <div key={index} className={styles.ParagraphTitleWrapper}
                                                             onClick={() => showSelectedCategoryInfo(index)}>
                                                            <div className={styles.ParagraphTitle}>{title}</div>
                                                            <ChevronDownIcon
                                                                style={{width: "2rem", stroke: "#88959E"}}/>
                                                        </div>

                                                    }
                                                </SlideDown>
                                            </>
                                        }

                                        {matches.large &&
                                            <>
                                                {+activeTitle === index ?
                                                    <div key={index} className={styles.ParagraphTitleWrapper}>
                                                        <div className={styles.ParagraphTitleActive}>{title}</div>
                                                        <ChevronRightIcon style={{width: "20px", stroke: "#00B2B1"}}/>
                                                    </div>
                                                    :
                                                    <div key={index} className={styles.ParagraphTitleWrapper}
                                                         onClick={() => showSelectedCategoryInfo(index)}>
                                                        <div className={styles.ParagraphTitle}>{title}</div>
                                                        <ChevronRightIcon style={{width: "20px"}}/>
                                                    </div>
                                                }
                                            </>
                                        }
                                    </Fragment>
                                )}
                            </Media>
                        );
                    })
                )
            }
        }
    }, [paragraphsTitleArray, activeTitle, contentExpanded])

    const showSelectedCategoryInfo = (index) => {
        if (+activeTitle === index && contentExpanded) {
            contentExpandedHandler(false)
        } else if (+activeTitle !== index && !contentExpanded) {
            contentExpandedHandler(true);
        } else if (+activeTitle === index && contentExpanded) {
            contentExpandedHandler(true)
        } else if (+activeTitle === index) {
            contentExpandedHandler(true);
        }
        selectedCategoryInfoHandler(informationCategory[+index]);
        setActiveTitle(+index);
    };

    return (
        <Media
            queries={{
                small: "(max-width: 699px)",
                large: "(min-width: 700px)"
            }}>
            {matches => (
                <Fragment>
                    {matches.small &&
                    <>
                        <div id="articleInfo" className={styles.BasicInfoContainer}>
                                <div style={{width: "100%"}}>
                                    <p style={{color: "#013A63", fontSize: "1.25rem", fontWeight: "bold", textAlign: "center"}}>Osnovne informacije</p>
                                    {articleInfos}
                                </div>
                        </div>
                    </>
                    }

                    {matches.large &&
                    <>
                        <div id="articleInfo" className={styles.BasicInfoContainer} style={{height: "fit-content"}}>
                            <div className={styles.BasicInfoLeftSide}>
                                <p style={{color: "#013a63", fontSize: "1.25rem", fontWeight: "bold"}}>Osnovne informacije</p>
                                {articleInfos}
                            </div>

                            <div className={styles.BasicInfoRightSide}>
                                <p style={{
                                    color: "#013a63",
                                    fontSize: "1.25rem",
                                    fontWeight: "bold",
                                    borderBottom: "1px solid #E2E8EC",
                                    paddingBottom: "2px"
                                }}>{articleName}</p>

                                {selectedCategoryInfo === 0 ?
                                    (props.articleInfo !== null ?
                                        <div dangerouslySetInnerHTML={{__html: props.articleInfo.indications}}
                                             style={{padding: "1rem"}}></div>
                                            : props.articleDetails !== null && props.articleDetails.itemInfoResponse && <div dangerouslySetInnerHTML={{__html: props.articleDetails.itemInfoResponse.description}}
                                                                                     style={{padding: "1rem"}}></div>
                                    )
                                    :
                                    selectedCategoryInfo ?
                                    <div dangerouslySetInnerHTML={{__html: selectedCategoryInfo}} style={{padding: "1rem"}}></div> :
                                        <p style={{padding: "1rem"}}>Trenutno nema informacija</p>
                                }
                            </div>
                        </div>
                    </>
                    }
                </Fragment>
            )}
        </Media>
    );
}

const mapStateToProps = (state) => {
    return {
        articleInfo: state.articleInfoPage.articleInfo,
        articleInfoError: state.articleInfoPage.articleInfoError,
        loadingArticleInfo: state.articleInfoPage.loadingArticleInfo,
        articleDetails: state.articleInfoPage.articleDetailsForInfoPage,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onFetchArticleInfo: (articleJKL) => dispatch(actions.fetchArticleInfo(articleJKL))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ArticleInfoPage);