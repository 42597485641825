import React from "react";
import styles from './ArticleActionsHolder.module.css'
import ArticleActions from "./ArtucleActions/ArticleActions";

const ArticleActionsHolder = (props) => {
    return(
        <div className={styles.ArticleActionsHolder}>
            <ArticleActions article={props.article}/>
            <div>
                {props.children}
            </div>
        </div>
    );
}

export default ArticleActionsHolder;