import React from 'react'
import Button from "../../UI/Buttons/Button/Button";
import ModalWithHeader from "../../UI/Modals/ModalWithHeader/ModalWithHeader";

const ActiveCart = (props) => {
    return(
        <ModalWithHeader onCloseClick={props.onCloseClick}
                         // icon={<ShoppingBagWithAIcon style={{width: "48px", height: "48px"}}/>}
                         show={props.show}
                         backdropClickHandler={props.backdropClickHandler}
                         buttons={
                             <>
                                 <Button buttontype="whiteblue" onClick={props.clearActiveCart}>Započni novu rezervaciju!</Button>
                                 <Button buttontype="blue" onClick={props.toActiveCart}>Aktivna korpa</Button>
                             </>
                         }
        >
            <p style={{margin: "0", fontSize: "20px", fontWeight: "bold"}}>Nova rezervacija!</p>
            <p style={{textAlign: "center", fontSize: "14px", lineHeight: "1.7"}}>
                Već imaš započetu rezervaciju.
                Da bi korisnik započeo rezervaciju u ovoj
                apoteci, mora završiti započetu rezervaciju.
                Ako se započne nova rezervacija svi proizvodi iz prethodno započete rezervacije biće obrisani!</p>
        </ModalWithHeader>
    );
}

export default ActiveCart;