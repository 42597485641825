import React from 'react'
import styles from './UnknownItem.module.css'
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import SearchInputRequest from "./SearchInputRequest/SearchInputRequest";

const UnknownItem = (props) => {

    return(
    <>
        <div className={styles.UnknownItem}>
            <div className={styles.UnknownItemSearchResult}>
                <p className={styles.SearchResult0}>Pretraga apoteka - 0 </p>
                <p className={styles.SearchResultUnavailable}>Nema na stanju</p>
            </div>
        <SearchInputRequest unknownItem inputText={props.searchString}/>
        </div>
    </>
    )
}

const mapStateToProps = (state) => {
    return {
        searchString: state.mainPage.searchString,
    }
}

export default connect(mapStateToProps)(withRouter(UnknownItem));