import React from 'react'
import styles from './OrderCompletionPageTemplate.module.css'

const OrderCompletionPageTemplate = (props) => {


    return (
        <>
            {/*Wrapper komponenta*/}
            <div className={styles.WrapperForOCPT}>
                <div className={styles.WrapperForOCPTTop}>
                    {props.topPart}
                </div>

                <div style={{display:"flex"}}>
                    {/* Left part    */}
                    <div className={styles.WrapperForOCPTLeftSide} style={{width: "892px"}}>
                        {props.leftPart}
                    </div>

                    <div style={{width: "0px", borderLeft: "1px solid #e2e8ecff", minHeight: "100%", margin: "0 1rem"}}/>

                    {/* Right part    */}
                    <div className={styles.WrapperForOCPTRightSide} style={{width: "28%"}}>
                        {props.rightPart}
                    </div>
                </div>
            </div>

        </>
    )


}

export default OrderCompletionPageTemplate;