import React, {useEffect, useState} from "react";
import ModalWithHeader from "../ModalWithHeader/ModalWithHeader";
import Button from "../../Buttons/Button/Button";
import {withRouter} from "react-router-dom";
import * as actions from "../../../../store/actions";
import {connect} from "react-redux";
import Spinner from "../../Spinner/Spinner";
import StoreInModal from "../../../SearchResultsPage/CNCModal/StoreInModal/StoreInModal";
import stylesCNCModal from "../../../SearchResultsPage/CNCModal/CNCModal.module.css";
import styles from './RFZOTherapyModal.module.css'
import PerfectScrollbar from "react-perfect-scrollbar";
import IncompleteInfoErrorModal from "./IncompleteInfoErrorModal/IncompleteInfoErrorModal";
import PhoneNumberVerificationModal from "../../../AuthPage/PhoneNumberVerification/PhoneNumberVerification";
import Backdrop from "../../Backdrop/Backdrop";
import LBOInputField from "../../../MyProfilePage/MyProfilePane/LBOInputField/LBOInputField";

const RFZOTherapyModal = (props) => {

    const [RFZOModalContent, setRFZOModalContent] = useState(<Spinner />)
    const [storesModal, setStoresModal] = useState(null);
    const [modalContentFlag, modalContentFlagHandler] = useState(false);
    const [selectedPharmacyData, selectedPharmacyDataSetter] = useState({
        data: '',
        index: ''
    })
    const [modalContent, modalContentSetter] = useState(<Spinner />)
    const [marketplace, setMarketPlace] = useState();

    const [lboNumber, setLboNumber] = useState({
        lbo: null,
        saveToProfile: false
    });

    const [isPhoneNumberModalVisible, isPhoneNumberModalVisibleHandler] = useState(false);

    const [openIncompleteInfoErrorModal, setOpenIncompleteInfoErrorModal] = useState(false)

    useEffect(()=>{
        // kada se otvori RFZO modal, brise se telefon sa local storage da ne bi moglo da se klikne Dalje
        if(localStorage.getItem('phoneNumber')) {
            localStorage.removeItem('phoneNumber')
        }

        return()=> {
            //Po napustanju modala, ako user ima upisan broj telefona u profilu, broj se upisuje na local storage
            setTimeout(()=> {
                if (props.userProfile && props.userProfile.phoneNumber) {
                    localStorage.setItem('phoneNumber', props.userProfile.phoneNumber);
                }
            }, 500)
        }
    },[])

    const closeIncompleteInfoErrorModal = () => {
        setOpenIncompleteInfoErrorModal(false)
    }

    const openPhoneVerificationModal = () => {
        if(lboNumber.lbo !== null) {
            isPhoneNumberModalVisibleHandler(true);
            if (lboNumber.saveToProfile) {
                saveLBOToProfile()
            }
        } else {
            setOpenIncompleteInfoErrorModal(true)
        }
    }

    const closePhoneVerificationModal = () => {
        isPhoneNumberModalVisibleHandler(false);
    }


    const sendRFZOTherapyRequest =(phoneNumber)=> {
            const rfzoDTO = {
                    senderEmail: localStorage.getItem('email'),
                    recipientEmail: marketplace.email,
                    type: "RFZO_THERAPY_REQUEST",
                    content: {
                         userId: localStorage.getItem('id'),
                         userLBO: lboNumber.lbo,
                         branchMarketplaceId: marketplace.branchMarketplaceId,
                         marketplaceId: marketplace.marketPlaceId,
                         // userPhone: localStorage.getItem('phoneNumber'),
                         userPhone: phoneNumber,
                         firstName: localStorage.getItem('firstName'),
                         secondName: localStorage.getItem('secondName'),
                         note: '',
                         // fcmToken: ''
                         }
                    }

            props.onCreateRFZOTherapy(rfzoDTO);

            if(localStorage.getItem('deletePhoneNumberAfterOrder') === 'true') {
                localStorage.removeItem('phoneNumber');
                localStorage.removeItem('deletePhoneNumberAfterOrder');
            }
    }

    const saveLBOToProfile =()=> {
        if(props.userProfile !== null) {
            const updatedUserProfile = {
                ...props.userProfile,
                lbo: lboNumber.lbo,
            }
            props.onUpdateUserProfile(updatedUserProfile);
        }
    }

    useEffect(() => {
        setMarketPlace({
            email: props.onToolbar ? selectedPharmacyData.data.marketplaceUserEmail : props.selectedStore.marketplaceResponse.marketplaceUserEmail,
            marketPlaceId: props.onToolbar ? selectedPharmacyData.data.marketplaceId : props.selectedStore.marketplaceResponse.marketplaceId,
            branchMarketplaceId: props.onToolbar ? selectedPharmacyData.data.branchMarketplaceId : props.selectedStore.branchMarketplaceResponse.id
        })
    }, [selectedPharmacyData, props.selectedStore ])

    useEffect(()=>{
        if(props.rfzoTherapyCreated ) {
            props.onResetRFZOTherapyCreated(); // da li ovo cistiti ovde ili na sledecoj strani?
            let url
            {
                !props.onToolbar ?
                    url = '/rfzo-therapy/' + props.match.params.branchMarketplaceId + '/' + props.match.params.marketplaceId
                    :
                    url = '/rfzo-therapy/' + selectedPharmacyData.data.branchMarketplaceId + '/' + selectedPharmacyData.data.marketplaceId
            }
            props.history.push(url)
        }
    }, [props.rfzoTherapyCreated])


    const passLBOToRFZOModal= (data)=> {
        setLboNumber({
            lbo: data.lbo,
            saveToProfile: data.saveToProfile
        })
    }

    // odredjuje sadrzaj modala u zavisnosti od toga da li je zahtev za kreiranje terapije poslat ili ne, ili je doslo do greske
    useEffect(()=>{
        if (!props.loadingCreateRfzoTherapy && !props.rfzoTherapyCreated && !props.createRfzoTherapyFailed && modalContentFlag) {
            setRFZOModalContent(
                <div style={{width: "100%"}}>
                    <LBOInputField passLBONumber={(data) => passLBOToRFZOModal(data)}/>
                </div>
            )
        }
        if(props.loadingCreateRfzoTherapy) {
            setRFZOModalContent(
                <>
                    <div>
                        <p>Šaljemo tvoje podatke apoteci.</p>
                        <p>Molimo, sačekaj...</p>
                        <Spinner />
                    </div>
                </>
            )
        }
        if(props.createRfzoTherapyFailed) { // ovde ce biti potrebno napraviti razliku izmedju terapije koja se obradjuje i greske u konekciji
            setRFZOModalContent(
                <>
                    <p>Potrebno je da apoteka stornira tvoj prethodni zahtev za proveru RFZO terapije.</p>
                    <p>Molimo, pokušaj malo kasnije.</p>
                </>
            )
        }
    }, [props.loadingCreateRfzoTherapy, props.rfzoTherapyCreated, props.createRfzoTherapyFailed, storesModal, modalContentFlag])

    const pharmacySelected = (data, index) => {
        selectedPharmacyDataSetter({data: data, index: index})
    }

    useEffect(() => {

        if (selectedPharmacyData.index === '')
            selectedPharmacyDataSetter({...selectedPharmacyData, index: 0})
        if (props.storesForModal) {
            setStoresModal(props.storesForModal && props.storesForModal.map((store, index) => {
                return (
                    <StoreInModal
                        store={store}
                        index={index}
                        pharmacySelectedCallback={() => pharmacySelected(store, index)}
                        selected={selectedPharmacyData.index === index}
                        rfzoModal
                    />
                )
            }))
        }

        setTimeout(() => {
            modalContentFlagHandler(true)
        }, 200)

    }, [props.storesForModal, selectedPharmacyData])

    useEffect(()=>{

            if (storesModal !== null && storesModal !== '' && storesModal.length > 0) {
                modalContentSetter(
                    <div style={{width: "100%"}}>
                        <div>
                            <p className={stylesCNCModal.Message}>Izaberite apoteku u kojoj želite da proverite RFZO terapiju
                            </p>
                            {props.loadingStoresForModal ?
                                <Spinner/>
                                :
                                <PerfectScrollbar style={{maxHeight:"190px", width: "calc(100% + 1rem)"}}>
                                    {storesModal}
                                </PerfectScrollbar>
                            }
                        </div>
                    </div>
                )
            }

    }, [storesModal])

    useEffect(() => {
        //prazni se zbog toga sto pri ulasku na CartSummary redirektuje u shop ako postoji ova promenljiva
        //CartSummary useEffect() sa metodom backToShop() - potrebno zbog uslova za (ne)izvrsavanje backToShop()
        localStorage.removeItem('beforeEnteringStore');

            if (localStorage.getItem("enteredAddress")) {
                props.onFetchStores({
                    filter:"&filter=haveRFZO:1 and isClickCollect:1 and takesOrders:1",
                    latitude: localStorage.getItem("addressLatitude"),
                    longitude: localStorage.getItem("addressLongitude"),
                    // distance: 6000000,
                    modal: true
                })
            }

            return()=>{
                props.onResetRFZOTherapyCreated();
                localStorage.removeItem('LBO');
                localStorage.removeItem('selectedPharmacyIndex');
            }
    }, [])

    const closeModalAndResetProps =()=> {
        props.onResetRFZOTherapyCreated();
        props.onCloseClick()
    }

    return (
        <>
        <ModalWithHeader
            show={props.show || props.rfzoModalToggle}
            backdropClickHandler={props.backdropClickHandler}
            onCloseClick={props.onCloseClick}
            className={props.onToolbar && styles.RFZOTherapyModalOnToolbar}
            contentStyle={{padding:"1rem"}}
        >
            <div className={styles.RFZOTherapyModalContainer}>
                <h3 className={styles.RFZOTherapyModalTitle}>RFZO Terapija</h3>
                {RFZOModalContent}
                {props.onToolbar && !props.createRfzoTherapyFailed && !props.loadingCreateRfzoTherapy &&
                    modalContent}
                {!props.loadingCreateRfzoTherapy && !props.loadingStoresForModalFailed && !props.loadingStoresForModal &&
                <div style={{width: "100%", display: "flex", justifyContent: "center", padding: "1rem 0"}}>
                    {!props.createRfzoTherapyFailed ?
                        <Button buttontype="blue"
                                onClick={openPhoneVerificationModal}

                        >{window.location.pathname.includes('shop') ?
                            "Dalje"
                            :
                            "Izaberi"
                        }
                        </Button>
                        :
                        <Button buttontype="blue"
                                onClick={closeModalAndResetProps}
                        >Zatvori
                        </Button>
                    }
                </div>}
            </div>
        </ModalWithHeader>

            {openIncompleteInfoErrorModal &&
                <IncompleteInfoErrorModal
                    show={openIncompleteInfoErrorModal}
                    backdropClickHandler={closeIncompleteInfoErrorModal}
                    onCloseClick={closeIncompleteInfoErrorModal}
                />
            }

            {isPhoneNumberModalVisible &&
                <PhoneNumberVerificationModal closePhoneVerificationModal={closePhoneVerificationModal}
                                              phoneForRFZO
                                              sendRFZORequest={sendRFZOTherapyRequest}

                />
            }
            <Backdrop show={isPhoneNumberModalVisible}
                      backdropClickHandler={closePhoneVerificationModal}
                      zIndex={15}
                      backgroundColor="rgba(0, 0, 0, .2)"/>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        selectedStore: state.appPage.selectedStore,
        rfzoTherapy: state.rfzoTherapy.rfzoTherapy,
        rfzoTherapyCreated: state.rfzoTherapy.rfzoTherapyCreated,
        rfzoTherapyFailed: state.rfzoTherapy.rfzoTherapyFailed,
        loadingCreateRfzoTherapy: state.rfzoTherapy.loadingCreateRfzoTherapy,
        createRfzoTherapyFailed: state.rfzoTherapy.createRfzoTherapyFailed,
        rfzoModalToggle: state.rfzoTherapy.rfzoModalToggle,
        storesForModal: state.storesSearchPage.storesForModal,
        loadingStoresForModal: state.storesSearchPage.loadingStoresForModal,
        loadingStoresForModalFailed: state.storesSearchPage.loadingStoresForModalFailed,
        userProfile: state.userProfile.userProfile,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onSelectedStoreChanged: (selectedStore) => dispatch(actions.selectedStoreChanged(selectedStore)),
        onFetchSelectedStore: (branchMarketplaceId, marketplaceId) => dispatch(actions.fetchSelectedStore(branchMarketplaceId, marketplaceId)),
        onCreateRFZOTherapy: (rfzoTherapyDto) => dispatch(actions.createRFZOTherapy(rfzoTherapyDto)),
        onResetRFZOTherapyCreated: ()=> dispatch(actions.resetRFZOTherapyCreated()),
        onFetchStores: ({page, filter, latitude, longitude, distance, modal}) => dispatch(actions.fetchStores({page, filter, latitude, longitude, distance, modal})),
        onUpdateUserProfile: (userProfile) => dispatch(actions.updateUserProfile(userProfile))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RFZOTherapyModal));