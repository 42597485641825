import React from "react";
import styles from './ArticleAction.module.css';

const ArticleAction = (props) => {
    return(
        <div className={styles.ArticleAction}>
            {props.children}
            <p>{props.text}</p>
        </div>
    );
}

export default ArticleAction;