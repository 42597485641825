import React, {Fragment, useEffect, useState} from 'react';
import styles from './NewTherapy.module.css';
import Button from "../../../UI/Buttons/Button/Button";
import SearchBarForTherapy from "./SearchWrapperForTherapy/SearchBarForTherapy/SearchBarForTherapy";
import * as actions from "../../../../store/actions";
import {connect} from "react-redux";
import TherapyArticle from "../TherapyArticles/TherapyArticle/TherapyArticle";
import Media from "react-media";
import PerfectScrollbar from "react-perfect-scrollbar";
import ModalWithHeader from "../../../UI/Modals/ModalWithHeader/ModalWithHeader";
import AddArticleToTherapyModal from "../../../UI/Modals/AddArticleToTherapyModal/AddArticleToTherapyModal";

const NewTherapy = (props) => {

    const [therapyName, setTherapyName] = useState('');
    const [alertNewTherapyModalVisible, setAlertNewTherapyModalVisible] = useState(false);
    const [editArticleModalVisible, setEditArticleModalVisible] = useState(false);

    useEffect(()=> {
        if (props.editTherapy.index !== '') {
            setTherapyName(props.editTherapy.therapy.name)
            props.editTherapy.therapy.items.map(item => {
                let itemToAdd = {
                    id: item.itemResponse ? item.itemResponse.id : item.item.id,
                    name: item.itemResponse ? item.itemResponse.name : item.item.name,
                    manufacturerName: item.itemResponse ? item.itemResponse.manufacturerName : item.item.manufacturerName,
                    nameHighlighted: '',
                    picture: item.itemResponse ? item.itemResponse.pictures : item.item.picture
                }

                let newItem = {
                    dosing: item.dosing,
                    duration: item.duration,
                    frequency: item.frequency,
                    item: itemToAdd,
                    quantity: item.quantity
                }
                props.onAddItemToTherapy(newItem)
            })
        }
    }, [props.editTherapy]);

    const saveTherapy = () => {

        if (therapyName !== '' && props.articles.length > 0) {
            const itemObj = {
                name: therapyName,
                description: '',
                therapyItemRequests: props.articlesForTherapyItemRequests
            }
            props.onCreateNewTherapy(localStorage.getItem('id'), itemObj);
            props.onCancel();
        } else {
            setAlertNewTherapyModalVisible(true);
        }
    }

    const saveEditTherapy = () => {
        if (therapyName !== '' && props.articles.length > 0) {
            const itemObj = {
                id: props.editTherapy.therapy.id,
                name: therapyName,
                description: '',
                therapyItemRequests: props.articlesForTherapyItemRequests
            }
            props.onCreateNewTherapy(localStorage.getItem('id'), itemObj);
            props.therapyDeselect();
            props.onCancel();
        } else {
            setAlertNewTherapyModalVisible(true);
        }
    }

    const removeItem = (itemToRemove) => {
        props.onRemoveArticleFromTherapy(itemToRemove.item.id)
    }

    const editItem = (itemToEdit) => {
        props.onSetSelectedItem(itemToEdit);
        setEditArticleModalVisible(true)
    }

    const closeAlertNewTherapyModal = () => {
        setAlertNewTherapyModalVisible(false)
    }

    const closeEditArticleHandler = () => {
        setEditArticleModalVisible(false);
    }

    return (
        <>
            <Media queries={{
                small: "(max-width: 699px)",
                large: "(min-width: 700px)"
            }}>
                {matches => (
                    <Fragment>
                        {matches.small &&
                            <PerfectScrollbar>
                                <div className={styles.NewTherapyMobileWrapper}>
                                    <div className={styles.TherapyNameMobile}>
                                        <label htmlFor="NewTherapyName">Naziv Terapije</label>
                                        <input className={styles.TherapyName} id="NewTherapyName"
                                               onChange={(event) => setTherapyName(event.target.value)}
                                               defaultValue={therapyName}/>
                                    </div>
                                    <br/>
                                    <label>Dodaj artikle</label>
                                    <SearchBarForTherapy/>
                                    <div className={styles.NewTherapyArticlesMobile}>
                                        {props.articles && props.articles.map((item, index) => {
                                            return (
                                                <TherapyArticle article={item} key={index} removeItem={() => removeItem(item)} editItem={() => editItem(item)}/>
                                            )
                                        })}
                                    </div>
                                    <br /><br /><br/><br /><br /><br />
                                    <div style={{
                                        width: "100%",
                                        position: "fixed",
                                        bottom: "0",
                                        left: "0",
                                        height: "5rem",
                                        backgroundColor: "white",
                                        // borderTop
                                    }}>
                                        <Button buttontype="blue" onClick={props.editTherapy ? saveEditTherapy: saveTherapy}
                                                style={{
                                                    width: "200px",
                                                    justifyContent: "center",
                                                    position: "fixed",
                                                    bottom: "1rem",
                                                    left: "50%",
                                                    transform: "translateX(-50%)"
                                                }}>Sačuvaj</Button>
                                    </div>
                                </div>
                            </PerfectScrollbar>
                        }

                        {matches.large &&
                            <div>
                                <div className={styles.NewTherapyHeader}>
                                    <span>Terapija:</span>
                                    <div className={styles.NewTherapyHeaderActions}>
                                        <Button onClick={props.onCancel}>Odustani</Button>
                                        <Button buttontype="blue" onClick={props.editTherapy ? saveEditTherapy: saveTherapy}>Sačuvaj</Button>
                                    </div>
                                </div>
                                <div className={styles.NewTherapyContent}>
                                    <div className={styles.ContentLeftSide}>
                                        <input className={styles.TherapyName} placeholder="Naziv terapije"
                                               defaultValue={therapyName}
                                               onChange={(event) => setTherapyName(event.target.value)}/>
                                        <br/>
                                        <SearchBarForTherapy/>
                                    </div>
                                    <div className={styles.ContentRightSide}>
                                        {props.articles && props.articles.map((item, index) => {
                                            return (
                                                <TherapyArticle article={item} key={index} removeItem={() => removeItem(item)} editItem={() => editItem(item)}/>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        }
                    </Fragment>
                )}
            </Media>

            {alertNewTherapyModalVisible &&
                <ModalWithHeader onCloseClick={closeAlertNewTherapyModal}
                                 style={{paddingBottom: "1rem", height:"300px"}}
                                 show={alertNewTherapyModalVisible}
                                 backdropClickHandler={closeAlertNewTherapyModal}
                                 buttons={
                                     <Button buttontype='blue' style={{margin:"auto"}} onClick={closeAlertNewTherapyModal}>Zatvori</Button>
                                 }
                >
                    <div style={{margin:"auto", textAlign:"center"}}>
                        <div>Da biste sačuvali terapiju neophodno je uneti</div>
                        <div><strong>naziv terapije</strong> i <strong>bar jedan artikal</strong>.</div>
                    </div>
                </ModalWithHeader>
            }

            {editArticleModalVisible &&
                <AddArticleToTherapyModal
                    editItem
                    show={editArticleModalVisible}
                    // item={props.selectedItem}
                    onCloseClick={closeEditArticleHandler}
                    backdropClickHandler={closeEditArticleHandler}
                />
            }
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        articles: state.userProfile.articles,
        articlesForTherapyItemRequests: state.userProfile.articlesForTherapyItemRequests,
        // newTherapyCreated: state.userProfile.newTherapyCreated,
        // createNewTherapyStart: state.userProfile.createNewTherapyStart,
        // createNewTherapyFailed: state.userProfile.createNewTherapyFailed,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onCreateNewTherapy: (userId, therapyObject) => dispatch(actions.createNewTherapy(userId, therapyObject)),
        onRemoveArticleFromTherapy: (itemId) => dispatch(actions.removeArticleFromTherapy(itemId)),
        onSetSelectedItem: (item) => dispatch(actions.setSelectedItem(item)),
        onAddItemToTherapy: (item) => dispatch(actions.addArticleToTherapy(item)),
        onRemoveAllArticlesFromTherapy: () => dispatch(actions.removeAllArticlesFromTherapy())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewTherapy);