import React from 'react';
import {ReactComponent as RFZOImage} from "../../../../assets/icons/rfzo_logo.svg";
import styles from "./RFZOButton.module.css";

const RFZOMobileButton = (props) => {
    return (
        <div className={styles.RFZOMobileButtonWrapper} onClick={props.onClick} style={props.style}>
            <RFZOImage style={{height: "24px", width: "24px"}}/>
        </div>
    );
};

export default RFZOMobileButton;
