import React from 'react';


export const selectedStoreOpeningHours = (storeOpeningHours) => {

    const weekdays = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
    const date = new Date();
    let today = date.getDay();
    let storeWorkingHours

    weekdays.map((day, index) => {
        if (index === +today) {
            if (storeOpeningHours !== null) {
                storeWorkingHours = storeOpeningHours[day];

                if (storeWorkingHours !== null && storeWorkingHours !== undefined) {
                    storeWorkingHours = storeWorkingHours.split(' do ');

                    let storeOpeningHours = storeWorkingHours[0];
                    if (storeOpeningHours) {
                        storeOpeningHours = storeOpeningHours.split(':');
                        if (storeOpeningHours[0].charAt(0) === '0') {
                            if (storeOpeningHours[1] === '00') {
                                storeOpeningHours = storeOpeningHours[0].charAt(1);
                            } else {
                                storeOpeningHours = storeOpeningHours[0].charAt(1) + '.' + storeOpeningHours[1];
                            }
                        } else {
                            if (storeOpeningHours[1] === '00') {
                                storeOpeningHours = storeOpeningHours[0];
                            } else {
                                storeOpeningHours = storeOpeningHours[0] + '.' + storeOpeningHours[1];
                            }
                        }
                    } else {
                        storeOpeningHours = ''
                    }


                    let storeClosingHours = storeWorkingHours[1];
                    if (storeClosingHours) {
                        storeClosingHours = storeClosingHours.split(':');
                        if (storeClosingHours[0].charAt(0) === '0') {
                            if (storeClosingHours[1] === '00') {
                                storeClosingHours = storeClosingHours[0].charAt(1);
                            } else {
                                storeClosingHours = storeClosingHours[0].charAt(1) + '.' + storeClosingHours[1];
                            }
                        } else {
                            if (storeClosingHours[1] === '00') {
                                storeClosingHours = storeClosingHours[0];
                            } else {
                                storeClosingHours = storeClosingHours[0] + '.' + storeClosingHours[1];
                            }
                        }
                    } else if (storeClosingHours === undefined) {
                        storeClosingHours = ''
                    } else {
                        storeClosingHours = ''
                    }


                    if (storeOpeningHours && storeClosingHours) {
                        storeWorkingHours = storeOpeningHours + '-' + storeClosingHours + 'h';
                    } else {
                        storeWorkingHours = 'Zatvoreno'
                    }
                } else {
                    storeWorkingHours = 'n/a';
                }
            } else if (storeOpeningHours !== undefined) {
                storeWorkingHours = ''
            }
        }
    })
    return storeWorkingHours;
}

export const randomIntFromInterval = (min, max) => { // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min)
}

export const redirectToCommercialLink = (commertialLink) => {
    const url = commertialLink;
    //todo promeniti articles-and-stores/ u apoteka.rs za produkciju, da nase stranice ne otvara u novom tabu
    // ako je link ka nasem sajtu, otvori u istom tabu, u suprotnom, otvori u novom
    if (url.includes("articles-and-stores/")) {
        window.open(url, "_self")
    } else {
        window.open(url)
    }
}

export const textToUrl = (inputText) => {
    return inputText.replace(/([, \/%]+)/g, '-').toLowerCase();
}
