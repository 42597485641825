import React, {useState} from 'react';
import styles from './ForgotPasswordPane.module.css';
import {Textbox} from "react-inputs-validation";
import * as actions from "../../../store/actions";
import {connect} from "react-redux";
import Button from "../../UI/Buttons/Button/Button"
import {withRouter} from 'react-router-dom'
import Toolbar from '../../Navigation/Toolbar/Toolbar'

const ForgotPasswordPane = (props) => {

    const [inputState, inputStateChanged] = useState({
        credentials: {
            email: ""
        },
        message: false
    })

    const inputChangedHandler = (event, inputIndentifier) => {
        const updatedInputForm = {
            ...inputState.credentials
        }
        updatedInputForm[inputIndentifier] = event.target.value;
        inputStateChanged({
            credentials: {...updatedInputForm}
        })
    }

    const forgetPasswordHandler = (e) => {
        props.onForgetPassword(inputState.credentials.email);
        inputStateChanged({
            credentials: {...inputState.credentials},
            message: true
        })
        setTimeout(() => {
            props.history.replace('/')
        }, 7000);
        e.preventDefault();
    }

    return (
        <>
        <Toolbar />
        <form className={styles.ForgotPasswordPane}>
            <div className={styles.ForgotPasswordPaneHolder}>
                <h2>Zaboravljena lozinka</h2>
                <p>Molimo unesite e-mail koji koristite za svoj Apoteka nalog.</p>
                <Textbox
                    attributesInput={{
                        id: "email",
                        name: "email",
                        placeholder: "Email",
                        type: "text"
                    }}
                    value={inputState.credentials.email} //Optional.[String].Default: "".
                    onChange={(value, event) => inputChangedHandler(event, "email")}  //Required.[Func].Default: () => {}. Will return the value.
                    onBlur={(e) => {
                    }} //Optional.[Func].Default: none. In order to validate the value on blur, you MUST provide a function, even if it is an empty function. Missing this, the validation on blur will not work.
                    validationOption={{
                        name: "email", //Optional.[String].Default: "". To display in the Error message. i.e Please enter your {name}.
                        check: true, //Optional.[Bool].Default: true. To determin if you need to validate.
                        required: true, //Optional.[Bool].Default: true. To determin if it is a required field.
                        msgOnError: "Email polje ne moze da bude prazno",
                        customFunc: (email) => {
                            const reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                            if (reg.test(String(email).toLowerCase())) {
                                return true;
                            } else {
                                return "Unesena email adresa nije validnog formata";
                            }
                        }
                    }}
                />
                <div style={{margin: "0 auto"}}>
                    <div className={styles.InfoMessageHolder}>
                        {inputState.message === true ?
                            <p>Ukoliko nalog postoji, email sa linkom za reset lozinke je poslat.</p> : null}
                    </div>
                    <Button buttontype='blue' onClick={forgetPasswordHandler}>Potvrdi</Button>
                </div>
            </div>
        </form>
        </>
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        onForgetPassword: (email) => {
            dispatch(actions.forgetPassword(email))
        }
    }
}

export default connect(null, mapDispatchToProps)(withRouter(ForgotPasswordPane));