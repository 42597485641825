import React, {Fragment, useEffect} from 'react';
import styles from './ShopPageHeader.module.css';
import {withRouter} from 'react-router-dom'
import {connect} from "react-redux";
import * as actions from "../../../store/actions";
import ShopInfoBox from "./ShopInfoBox/ShopInfoBox";
import Background from "../../UI/Background/Background";
import CommercialImagesLink from "../../../config.json";
import {redirectToCommercialLink} from "../../GobalFunctions/GlobalFunctions";
import Media from 'react-media';

const ShopPageHeader = (props) => {

    let backgroundHeaderColor = props.commercial !== null ? (props.advCategoryImage ? props.advCategoryImage.backgroundColor : props.commercial.backgroundColor) : "#F6F6F6";

    useEffect(() => {
        if (props.selectedStore === null) {

            if (props.match.params.searchedArticleId !== undefined && props.match.params.searchedArticleId.match(/^[0-9]+$/) !== null) {
                props.onFetchSelectedStore2(props.match.params.searchedArticleId, props.match.params.marketplaceId, props.match.params.branchMarketplaceId);
            } else {
                props.onFetchSelectedStore(props.match.params.marketplaceId, props.match.params.branchMarketplaceId);
            }
        }

    }, [props.match.params.branchMarketplaceId, props.match.params.marketplaceId, props.selectedStore])

    useEffect(() => {

        //uzima sa back-a random reklamu
        if(props.filterBreadcrumbs && props.commercial === null)
            props.onFetchRandomAdvertisement();

        return () => {
            if(props.filterBreadcrumbs && props.advCategoryImage === null)
                props.onResetAdvCategoryImageFetched(null);
        }

    },[props.filterBreadcrumbs, props.commercial]);


    return (
        <Media
            queries={{
                small: "(max-width: 699px)",
                large: "(min-width: 700px)"
            }}>
            {matches => (
                <Fragment>
                    {matches.small &&
                    <div>

                    </div>
                    }
                    {matches.large &&
                        <div className={styles.ShopPageHeader}>
                            <Background style={{height: "450px", background:backgroundHeaderColor}}/>
                            <div className={styles.Content}>
                                <div className={styles.ContentLeft}>
                                    <div className={styles.ExitAndShopBrandGroup}>
                                        <ShopInfoBox />
                                    </div>
                                </div>
                                <div className={styles.CommercialImage}
                                     onClick={()=> {props.commercial !== null && props.commercial.actionLink !== null && redirectToCommercialLink(props.commercial.actionLink)}}>
                                    <img
                                        src={props.commercial !== null && (props.advCategoryImage ? process.env.REACT_APP_CDN_PATH + "commecial_images/" + props.advCategoryImage.imageLink : process.env.REACT_APP_CDN_PATH + "commecial_images/" + props.commercial.imageLink)}
                                        alt="commercial image" style={{width: "620px"}}/>
                                </div>
                            </div>
                        </div>
                    }
                </Fragment>)}
        </Media>

    );

}

const mapStateToProps = (state) => {
    return {
        articleDetails: state.appPage.articleDetails,
        selectedStore: state.appPage.selectedStore,
        commercial: state.mainPage.advertisement,
        advCategoryImage: state.filter.advCategoryImage,
        filterBreadcrumbs: state.filter.filterBreadcrumbs,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onFetchSelectedStore: (branchMarketplaceId, marketplaceId) => dispatch(actions.fetchSelectedStore(branchMarketplaceId, marketplaceId)),
        onFetchSelectedStore2: (itemId, branchMarketplaceId, marketplaceId) => dispatch(actions.fetchSelectedStore2(itemId, branchMarketplaceId, marketplaceId)),
        onFetchRandomAdvertisement: () => dispatch(actions.fetchRandomAdvertisement()),
        onResetAdvCategoryImageFetched: ()=> dispatch(actions.advCategoryImageFetched())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ShopPageHeader));
