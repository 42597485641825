import React from 'react'
import Orders from './Orders/Orders'
import Transactions from './Transactions/Transactions'
import styles from './MyOrders.module.css';

const MyOrders = () => {

    return (
    <div className={styles.OrdersPane}>
        <h2>Moje porudžbine</h2>
        <Orders />
        <h2>Arhiva</h2>
        <Transactions />
    </div>
    )
}
export default MyOrders;