import React, {Fragment, useEffect, useState} from "react";
import styles from "./MyProfilePane.module.css";
import * as actions from "../../../store/actions";
import {connect} from "react-redux";
import Spinner from '../../UI/Spinner/Spinner'
import 'react-inputs-validation/lib/react-inputs-validation.min.css';
import {withRouter} from "react-router-dom";
import Backdrop from "../../UI/Backdrop/Backdrop";
import {LockClosedIcon, UserIcon} from "@heroicons/react/outline";
import MyAddresses from "../MyAddresses/MyAddresses";
import Button from "../../UI/Buttons/Button/Button";
import ModalWithHeader from "../../UI/Modals/ModalWithHeader/ModalWithHeader";
import UserInfoSection from "./UserInfoSection/UserInfoSection";
import EditProfileModal from "../EditProfileModal/EditProfileModal";
import PhoneNumberVerificationModal from "../../AuthPage/PhoneNumberVerification/PhoneNumberVerification";
import Media from "react-media";
import {ReactComponent as ProfileIcon} from "./../../../assets/icons/my-profile-icon.svg";
import Input from "../../UI/Input/Input";
import LBOModal from "../../UI/Modals/LBOModal/LBOModal";

const MyProfilePane = (props) => {

    const [editProfileState, editProfileStateChanged] = useState({
        editProfile: false
    });

    const [editModalState, editModalStateChanged] = useState({
        editName: false,
        editPassword: false
    });

    const [inputState, inputStateChanged] = useState({
        user: {
            id: "",
            firstName: "",
            secondName: "",
            email: "",
            phone: "",
            lbo: "",
            password: "",
            permitAdvertising: false
        }
    })

    const [isWantNewsletter, isWantNewsletterHandler] = useState(-1)

    useEffect(()=>{
        if (isWantNewsletter !== -1 && (isWantNewsletter !== inputState.user.permitAdvertising)) {
            props.onUpdateUserProfile(inputState.user)
        }
    }, [inputState.user.permitAdvertising])

    useEffect(()=> {
        props.userProfile &&
        isWantNewsletterHandler(props.userProfile.permitAdvertising)
    }, [props.userProfile])


    useEffect(() => {
        if (!props.loadingUserProfile && props.userProfile !== null) {
            inputStateChanged({
                user: {
                    ...props.userProfile,
                    id: props.userProfile.id === null ? '' : props.userProfile.id,
                    firstName: props.userProfile.firstName === null ? '' : props.userProfile.firstName,
                    secondName: props.userProfile.secondName === null ? '' : props.userProfile.secondName,
                    email: props.userProfile.username === null ? '' : props.userProfile.username,
                    phone: props.userProfile.phoneNumber === null ? '' : props.userProfile.phoneNumber,
                    lbo: props.userProfile.lbo === null ? '' : props.userProfile.lbo,
                    password: props.userProfile.password === null ? '' : props.userProfile.password,
                    permitAdvertising: props.userProfile.permitAdvertising === null ? false : props.userProfile.permitAdvertising
                }
            })
        }

    }, [props.userProfile])

    useEffect(() => {
        if (props.userProfile === null) {
            props.onFetchUserProfile(localStorage.getItem('email'));
        }
    }, [])

    const [isPhoneNumberModalVisible, isPhoneNumberModalVisibleHandler] = useState(false);
    const [isLBOModalVisible, isLBOModalVisibleHandler] = useState(false);

    //funkcije za pozivanje i promene broja telefona
    const enterPhoneNumber = () => {
        isPhoneNumberModalVisibleHandler(true);
    }

    const enterLBO = () => {
        isLBOModalVisibleHandler(true);
    }

    const closeLBOModal = () => {
        isLBOModalVisibleHandler(false)
    }

    const closePhoneVerificationModal = () => {
        isPhoneNumberModalVisibleHandler(false);
        setTimeout(() => {
            props.onFetchUserProfile(localStorage.getItem('email'));
        }, 500)
    }

    let myProfile = <Spinner/>
    if (!props.loadingUserProfile) {
        myProfile = props.userProfile !== null && (
            <React.Fragment>

                <UserInfoSection
                    label="Ime i prezime"
                    information={inputState.user.firstName + ' ' + inputState.user.secondName}
                    onClick={() => {
                        editModalStateChanged({...editModalState, editName: true})
                    }}
                    nameAndSurname={true}
                />

                <UserInfoSection
                    label="E-mail"
                    information={props.userProfile.username}
                    email={true}
                />

                <UserInfoSection
                    label="Broj telefona"
                    information={props.userProfile.phoneNumber}
                    onClick={enterPhoneNumber}
                    phoneNumber={true}
                />

                <UserInfoSection
                    label="LBO"
                    information={props.userProfile.lbo}
                    onClick={enterLBO}
                    lbo={true}
                />

                {/*uslovno prikazivanje buttona za promenu lozinke, u zavisnosti od rezolucije*/}
                <Media
                    queries={{
                        small: "(max-width: 699px)",
                        large: "(min-width: 700px)"
                    }}>
                    {matches => (
                        <Fragment>
                            {matches.small &&
                            <Fragment>
                                <div style={{display:"flex", alignItems:"center", justifyContent:"space-between", margin:"1rem 0", width: "100%"}}>
                                    <div style={{display:"flex", alignItems:"center", fontSize: "14", fontWeight:"bold", color:"#013A63"}}>
                                        <LockClosedIcon style={{width:"18px", height: "18px", paddingRight:"1rem"}} />Šifra
                                    </div>
                                    {localStorage.getItem('isGoogleLogin') === 'false' &&
                                    < Button buttontype='whiteblue' style={{backgroundColor: "transparent", width: "160px", height: "2rem", padding:"0"}}
                                             onClick={() => {
                                                 editModalStateChanged({...editModalState, editPassword: true})
                                             }}>PROMENI LOZINKU</Button>}
                                </div>
                                <hr style={{width: "100%", borderTop:"1px solid #E2E6EA"}}/>
                            </Fragment>
                            }
                            {matches.large &&
                            <Fragment>
                                {localStorage.getItem('isGoogleLogin') === 'false' &&
                                < Button buttontype='whiteblue' style={{backgroundColor: "transparent", width: "160px", height: "42px", marginTop:"2rem", padding:"0"}}
                                         onClick={() => {
                                             editModalStateChanged({...editModalState, editPassword: true})
                                         }}>PROMENI LOZINKU</Button>}
                            </Fragment>
                            }

                        </Fragment>)}
                </Media>

                {/*Modal za promenu imane(i prezimena) i lozinke*/}
                {(editModalState.editName || editModalState.editPassword) &&
                    <EditProfileModal
                        closeEditModal={() => {
                            !props.changePasswordSuccess &&
                            editModalStateChanged({editName: false, editPassword: false})
                        }}
                        show={true}
                        icon={<UserIcon style={{width: "48px", height: "48px"}}/>}
                        // hideSaveButton={props.changePasswordSuccess}
                        editModalState={editModalState}
                        editModalStateChanged={editProfileStateChanged}
                        inputState={inputState}
                        inputStateChanged={inputStateChanged}
                    />
                }

                {/*Modal za unos novog broja telefona*/}
                {isPhoneNumberModalVisible &&
                    <PhoneNumberVerificationModal closePhoneVerificationModal={closePhoneVerificationModal}/>
                }

                {isLBOModalVisible &&
                    <LBOModal show={isLBOModalVisible}
                              onCloseClick={closeLBOModal}
                              onButtonClick={closeLBOModal}
                              onProfilePage
                    />
                }

                <div style={{display:"flex", alignItems:"center", marginTop:"1rem"}}>
                    <Input inputtype="checkbox" type="checkbox"
                           style={{opacity: "1", width: "fit-content", position: "relative"}}
                           checked={inputState.user.permitAdvertising}
                           onChange={()=> {
                               console.log("props")
                               console.log(inputState)
                               inputStateChanged({
                                   user: {
                                       ...inputState.user,
                                       permitAdvertising: !inputState.user.permitAdvertising
                                   }
                               });
                           }}

                    />
                    <p style={{fontSize:"18px", color:"#535358"}}>
                        Želim da primam promotivne materijale
                    </p>
                </div>
                <Backdrop show={isPhoneNumberModalVisible}
                          backdropClickHandler={closePhoneVerificationModal}
                          zIndex={15}
                          backgroundColor="rgba(0, 0, 0, .2)"/>

            </React.Fragment>
        )
    }

    const [isModalVisible, isModalVisibleHandler] = useState(false);
    const [deleteUserProfileFailed, deleteUserProfileFailedHandler] = useState(false);

    const openModal = () => {
        isModalVisibleHandler(true);
    }

    const closeModal = () => {
        isModalVisibleHandler(false);
    }

    const deleteUserProfile = () => {
        props.onDeleteUserProfile(localStorage.getItem("id"));
        closeModal();
    }

    useEffect(() => {

        if (props.deleteUserProfileSuccess === true) {
            const url = '/goodbye';
            props.history.push(url);
        }
        if (props.deleteUserProfileFailed) {
            deleteUserProfileFailedHandler(true);
        }

    }, [props.deleteUserProfileSuccess, props.deleteUserProfileFailed]);

    let deleteProfile = <Spinner/>
    if (!props.loadingUserProfile) {
        deleteProfile = props.userProfile !== null && (
            <React.Fragment>
                {!editProfileState.editProfile &&
                    <div className={styles.DeleteProfile}>
                        <h2>Brisanje naloga</h2>
                        <p>
                            Brisanje naloga podrazumeva i brisanje ličnih podataka, kao i listi porudžbina. <br/>
                        </p>
                        <br/>

                        <Button buttontype="whitebrown"
                                style={{width:"160px", padding:"0"}}
                                onClick={openModal}>OBRIŠI NALOG</Button>

                        {isModalVisible ?
                            <ModalWithHeader onCloseClick={closeModal}
                                             icon={<UserIcon style={{width: "48px", height: "48px"}}/>}
                                             buttons={
                                                 <>
                                                     <Button onClick={closeModal}>Odustani</Button>
                                                     <Button buttontype="blue"
                                                             onClick={deleteUserProfile}
                                                     >Obriši</Button>
                                                 </>
                                             }
                            >

                                {!deleteUserProfileFailed ?
                                    <div style={{
                                        padding: "1rem",
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }}>
                                        <div>
                                            <p>Da li zaista želiš da obrišeš
                                                svoj nalog?</p>
                                        </div>

                                        <br/>
                                    </div>
                                    :
                                    <div style={{padding: "1rem"}}>
                                        <h4>Došlo je do greške prilikom brisanja profila. Baš nemate sreće.</h4>
                                        <br/>
                                        <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                            <Button buttontype="whitebrown" onClick={closeModal}>Zatvori</Button>
                                        </div>
                                    </div>
                                }
                            </ModalWithHeader>
                            :
                            null
                        }
                        <Backdrop show={isModalVisible}
                                  backdropClickHandler={closeModal}
                                  zIndex={15}
                                  backgroundColor="rgba(0, 0, 0, .2)"/>
                    </div>
                }
            </React.Fragment>
        )
    }

    return (
        <Media
            queries={{
                small: "(max-width: 699px)",
                large: "(min-width: 700px)"
            }}>
            {matches => (
                <Fragment>
                    {matches.small &&
                        <div className={styles.MyProfilePaneHeaderMobile}>
                            <ProfileIcon />
                            {myProfile}
                            <MyAddresses/>
                            <hr style={{width: "100%", borderTop:"1px solid #E2E6EA"}}/>
                            {deleteProfile}
                        </div>
                    }
                    {matches.large &&
                    <Fragment>
                        <div className={styles.MyProfilePaneHeader}>
                            <div className={styles.MyProfileInformation}>
                                <div className={styles.ProfileTitle}>
                                    <h2>Profilne informacije</h2>
                                </div>
                                {myProfile}
                                {deleteProfile}
                            </div>
                            <div className={styles.MyAddresses}>
                                <MyAddresses/>
                            </div>
                        </div>
                    </Fragment>
                    }

                </Fragment>)}
        </Media>

    );
}

const mapStateToProps = (state) => {
    return {
        userProfile: state.userProfile.userProfile,
        userProfileError: state.userProfile.userProfileError,
        loadingUserProfile: state.userProfile.loadingUserProfile,

        deleteUserProfileSuccess: state.userProfile.deleteUserProfileSuccess,
        deleteUserProfileFailed: state.userProfile.deleteUserProfileFailed,

        changePasswordSuccess: state.userProfile.changePasswordSuccess,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onFetchUserProfile: (email) => dispatch(actions.fetchUserProfile(email)),
        onDeleteUserProfile: (username) => dispatch(actions.deleteUserProfile(username)),
        onUpdateUserProfile: (userProfile) => dispatch(actions.updateUserProfile(userProfile))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MyProfilePane));
