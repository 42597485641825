import React, {useContext, useEffect, useState} from 'react';
import SearchItem from './SearchItem/SearchItem'
import styles from './SearchItems.module.css'
import * as actions from "../../../../store/actions";
import {connect} from 'react-redux';
import {withRouter} from "react-router-dom";
import NoMatchSearchItem from "./NoMatchSearchItem/NoMatchSearchItem";
import Spinner from "../../../UI/Spinner/Spinner"
import configData from "../../../../configConstants.json";
import {redirectToError} from "../../../../containers/App";
import SearchedStringSearchItem from "./SearchedStringSearchItem/SearchedStringSearchItem";


import {FuzzySortContext} from "../../../../store/context/FuzzySort/FuzzySort";

const fuzzysort = require('fuzzysort');

const SearchItems = React.memo(props => {

    const [matchedItems, matchedItemsChanged] = useState(<Spinner/>);
    const fuzzySort = useContext(FuzzySortContext)


    useEffect(() => {

        let index = 0;
        const searchStringWords = props.searchString.split(' ').filter(string => {
            if (string.trim() !== '') {
                return string.trim();
            }
        });

        //filtrira u zavisnosti od unesenog stringa
        const filterItems = (isShop) => {

            let matchingResult = fuzzySort.filterByWords(!isShop ? props.articles : props.namesOfSingleShopArticles, searchStringWords, 0);
            // let matchingResult = fuzzySort.filterByWords(!isShop ? fuzzySort.splitArrayOfSentencesIntoArrayOfWords(props.articles) : fuzzySort.splitArrayOfSentencesIntoArrayOfWords(props.namesOfSingleShopArticles), searchStringWords, 0);
            matchingResult = fuzzySort.sortResultsByStartWord(matchingResult, searchStringWords[0]);
            props.onMatchedItemsChanged(matchingResult);
            const firstItem = (<SearchedStringSearchItem key={0} index={index++} onMainPage={props.onMainPage} searchArticles={true} />)
                matchedItemsChanged([firstItem , ...matchingResult.map(result => (<SearchItem key={result.id}
                                                                                                    article={result}
                                                                                                    index={index++}
                                                                                                    onMainPage={props.onMainPage}
                                                                                        />
                ))]);
            if (index === 1 &&
                ((!isShop && index === 1 && !props.error && !props.loadingArticles) ||
                    (isShop && index === 1 && !props.loadingNamesOfSingleShopArticles && !props.namesOfsingleShopArticlesFailed))) {

                matchedItemsChanged([(<NoMatchSearchItem
                    // message={(props.history.location.pathname.includes("/shop") ? "U apoteci " + props.selectedStore.marketplaceResponse.name +  " nije pronađen ni jedan proizvod koji sadrži: " : configData.SearchItems.NotFindArticleText) + props.searchString}
                    searchString={props.searchString}
                    onMainPage={props.onMainPage}
                    key={index}/>)]);
            }
            props.onNumberOfMatchedArticlesChanged(index-1);
            props.onSelectedIndexChanged(0);
        }


        if (!props.history.location.pathname.includes("/shop/")) {
            if (!props.loadingArticles){
                if (props.error) {
                    redirectToError(props);
                    matchedItemsChanged( <p>Desila se greška prilikom učitavanja proizvoda!</p>);
                }
                else {
                    filterItems(false);
                }
            }

        }
        else {
            matchedItemsChanged(<Spinner/>);
            if (!props.loadingNamesOfSingleShopArticles){
                if (props.namesOfsingleShopArticlesFailed) {
                    redirectToError(props);
                    matchedItemsChanged(<p>Desila se greška prilikom učitavanja proizvoda!</p>)
                }
                else {
                    filterItems(true);
                }
            }
        }

    }, [props.searchString, props.articles, props.namesOfSingleShopArticles])

    let searchItemsStyle;
    if (props.onMainPage) {
        searchItemsStyle = [styles.SearchItems, styles.SearchItemsMainPage].join(' ');
    } else {
        searchItemsStyle = [styles.SearchItems, styles.SearchItemsActionMenu].join(' ');
    }

    return (
        <div className={searchItemsStyle}>
            <div className={styles.Bottom}>
                {props.searchString !== '' ? matchedItems : null}
            </div>
        </div>
    );
})

const mapStateToProps = (state) => {
    return {
        articles: state.mainPage.articles,
        error: state.mainPage.articlesError,
        loadingArticles: state.mainPage.loadingArticles,

        searchString: state.mainPage.searchString,
        numberOfMatchedArticles: state.mainPage.numberOfMatchedArticles,

        namesOfSingleShopArticles: state.shopPage.namesOfSingleShopArticles,
        loadingNamesOfSingleShopArticles: state.shopPage.loadingNamesOfSingleShopArticles,
        namesOfsingleShopArticlesFailed: state.shopPage.namesOfsingleShopArticlesFailed,

        selectedStore: state.appPage.selectedStore
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onNumberOfMatchedArticlesChanged: (numberOfMatchedArticles) => dispatch(actions.numberOfMatchedArticlesChanged(numberOfMatchedArticles)),
        onSelectedIndexChanged: (index) => dispatch(actions.selectedIndexChanged(index)),
        onMatchedItemsChanged: (matchedItems) => dispatch(actions.matchedItemsChanged(matchedItems))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SearchItems));
