import Button from "../../../../UI/Buttons/Button/Button";
import ModalWithHeader from "../../../../UI/Modals/ModalWithHeader/ModalWithHeader";
import React from "react";

const AlertModal = (props) => {

    return (
        <ModalWithHeader
            onCloseClick={props.onCloseClick}            
            show={props.show}
            backdropClickHandler={props.backdropClickHandler}
            buttons={
                <Button buttontype="blue"
                        onClick={props.onCloseClick}
                        style={{margin: "auto", cursor: "pointer"}}
                >Zatvori</Button>
            }>
            <div style={{padding: "1rem"}}>
                <div style={{display: "flex", justifyContent: "center"}}>
                    <p>{props.messageText}</p>
                </div>
            </div>
        </ModalWithHeader>);
}

export default AlertModal;