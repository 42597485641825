import React, {useEffect, useState} from "react";
import ModalWithHeader from "../ModalWithHeader/ModalWithHeader";
import Button from "../../Buttons/Button/Button";
import {connect} from "react-redux";
import Spinner from "../../Spinner/Spinner";

const SocketModal = (props) => {

    const [modalContent, modalContentSetter] = useState(null)
    const [countDownTimer, countDownTimerSetter] = useState(3)

    const countDownFunc = () => {
        setTimeout(() => {
            if (countDownTimer > -1)
                countDownTimerSetter(prev => prev - 1)
        }, 1000)
    }

    useEffect(() => {
        if (countDownTimer === -1) {
            props.backdropClickHandler()
        }
    }, [countDownTimer])

    useEffect(() => {
        if (props.RFZOSocketStatus === null && !props.RFZOSocketConnectionStart) {
            modalContentSetter(
                <div style={{width:"100%", display:"flex", flexDirection:"column", alignItems:"center"}}>
                    <p><strong>Veza sa apotekom je prekinuta.</strong></p>
                    <p style={{textAlign:"center"}}>Klikni na dugme "Poveži se ponovo" da bi se veza ponovo uspostavila.</p>
                </div>
            )
        } else if (props.RFZOSocketConnectionStart) {
            modalContentSetter(
                <div style={{width:"100%", display:"flex", flexDirection:"column", alignItems:"center"}}>
                    <p>Uspostavljanje veze...</p>
                    <Spinner/>
                </div>
            )
        } else if (props.RFZOSocketStatus === true) {
            modalContentSetter(
                <>
                    <p><strong>Veza je uspostavljena.</strong></p>
                    <br/>
                    <div>Modal će se sam zatvoriti nakon</div>
                    <div>{countDownTimer}</div>
                    <div>sekundi</div>
                </>
            );
            countDownFunc();
        }
    }, [props.RFZOSocketStatus, props.RFZOSocketConnectionStart, countDownTimer])


    return (
        <ModalWithHeader noXIcon
                         buttons={
                             <div style={{width: "100%", display: "flex", justifyContent: "center"}}>
                                 <Button
                                     buttontype="blue"
                                     onClick={!props.RFZOSocketConnectionStart ? props.connectAgain : props.backdropClickHandler}>
                                     {!props.RFZOSocketStatus === true ?
                                         "Poveži se ponovo"
                                         :
                                         "Zatvori"
                                     }
                                 </Button>
                             </div>
                         }
                         show={props.show}
                         backdropClickHandler={props.backdropClickHandler}>
            <div style={{display: "flex", flexDirection: "column", alignItems: "center", marginTop: "2rem"}}>
                {modalContent}
            </div>
        </ModalWithHeader>
    );
}

const mapStateToProps = (state) => {
    return {
        RFZOSocketStatus: state.rfzoTherapy.RFZOSocketStatus,
        RFZOSocketConnectionStart: state.rfzoTherapy.RFZOSocketConnectionStart

    }
}

export default connect(mapStateToProps)(SocketModal);